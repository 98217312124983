import React from 'react';
import { ApolloError } from '@apollo/client';
import {
  TemplateConfigurationSelectorOption,
  WorkspaceSelectorOption,
} from '@common/components';
import { RunNotificationType } from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert } from '@voleer/ui-kit';
import { Form, Formik } from 'formik';
import { Box } from 'grommet';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

/**
 * Field value types in the `NotificationSubscriptionForm`.
 */
export type NotificationSubscriptionFormValues = {
  deliverInApp: boolean;
  deliverViaEmail: boolean;
  runNotificationType?: RunNotificationType;
  templateConfiguration?: TemplateConfigurationSelectorOption;
  workspace?: WorkspaceSelectorOption;
};

type NotificationSubscriptionFormProps = Readonly<{
  error: ApolloError | undefined;

  initialValues?: NotificationSubscriptionFormValues;

  onSubmit: FormikSubmitFn<NotificationSubscriptionFormValues>;
}>;

/**
 * Renders a Formik container for `NotificationSubscriptionFormFields`.
 */
export const NotificationSubscriptionForm: React.FC<NotificationSubscriptionFormProps> =
  ({ children, error, initialValues, onSubmit }) => {
    const [t] = useTranslation('pages/NotificationsPage');

    const validationSchema = Yup.object().shape({
      runNotificationType: Yup.string().required(
        t(
          'settings-tab.notification-subscription-form.validation.event-type.required'
        )
      ),
    });

    initialValues = {
      deliverInApp: initialValues?.deliverInApp || false,
      deliverViaEmail: initialValues?.deliverViaEmail || false,
      runNotificationType: initialValues?.runNotificationType,
      templateConfiguration: initialValues?.templateConfiguration,
      workspace: initialValues?.workspace,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        <Form>
          <Box>
            {error && (
              <Alert margin={{ bottom: 'small' }} status="error">
                {t('settings-tab.notification-subscription-form.error', {
                  error,
                })}
              </Alert>
            )}
            {children}
          </Box>
        </Form>
      </Formik>
    );
  };
