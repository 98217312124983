import React, { useMemo } from 'react';
import { tryFormatDate, tryParseDate } from '@common/utils';
import { DatePicker, DatePickerProps, Icon } from '@voleer/ui-kit';
import { useField } from 'formik';
import { TextInput } from 'grommet';
import { FaCalendarDay } from 'react-icons/fa';
import { SCHEDULED_JOB_FORM_DATE_FORMAT } from '../../utils';

export type DateInputProps = Pick<DatePickerProps, 'disabled' | 'name'>;

/**
 * Renders a date input field that uses masking to assist in entering a valid
 * date.
 */
export const DateInput: React.FC<DateInputProps> = props => {
  const [field, , fieldHelpers] = useField(props.name || '');

  const onChange: DatePickerProps['onChange'] = date => {
    const formatted = tryFormatDate(date, SCHEDULED_JOB_FORM_DATE_FORMAT);
    fieldHelpers.setValue(formatted);
  };

  const date = useMemo(
    () => tryParseDate(field.value, SCHEDULED_JOB_FORM_DATE_FORMAT),
    [field.value]
  );

  return (
    <DatePicker
      customInput={
        <TextInput
          data-testid="form-date-input__text-input"
          icon={<Icon icon={FaCalendarDay} />}
          reverse={true}
        />
      }
      data-testid="form-date-input__date-picker"
      dateFormat={SCHEDULED_JOB_FORM_DATE_FORMAT}
      name={field.name}
      onChange={onChange}
      popperPlacement="bottom-end"
      selected={date}
      showPopperArrow={false}
    />
  );
};
