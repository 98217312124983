import React, { useMemo, useState } from 'react';
import { LoadingOverlay } from '@common/components';
import { useAccountStatus } from '@common/hooks';
import { useTypedFlags } from '@features/launch-darkly';
import {
  Integration,
  IntegrationType,
  useAddIntegrationModalQuery,
} from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { ensureUnreachable } from '@voleer/types';
import { Modal } from '@voleer/ui-kit';
import { Box, FormField, Heading, Select } from 'grommet';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IntegrationMetadataInfoRow, IntegrationModal } from '..';
import {
  AddCustomWorkflowIntegration,
  AddOAuth2Integration,
  TrialExpiredIntegrationDetails,
} from './components';

export type AddIntegrationModalProps = Readonly<{
  /**
   * The IntegrationMetadata IDs that the user could create.
   *
   * If only one ID is provided then the modal will only allow them to create an
   * integration of that type. Otherwise the user will be prompted to select
   * which type of integration to create.
   */
  integrationMetadataIds: string[];

  /**
   * Called when the modal is closed.
   */
  onClose: () => void;

  /**
   * Called after the integration has been fully created.
   */
  onCompleted?: (integration: Pick<Integration, 'id'>) => void;
}>;

/**
 * Renders a modal to add an Integration.
 */
export const AddIntegrationModal: React.FC<AddIntegrationModalProps> = ({
  integrationMetadataIds,
  onClose: originalOnClose,
  onCompleted: originalOnCompleted,
}) => {
  const [t] = useTranslation(
    'features/integrations/components/AddIntegrationModal'
  );

  const { data, ...query } = useAddIntegrationModalQuery({
    skip: !integrationMetadataIds.length,
    variables: {
      integrationMetadataIds,
    },
  });

  const { disableWriteOperation } = useAccountStatus();

  const { 'launch-control': launchControl } = useTypedFlags();

  const [createdIntegrationId, setCreatedIntegrationId] = useState<string>();

  const onCompleted = (integration: Pick<Integration, 'id'>) => {
    setCreatedIntegrationId(integration.id);
    originalOnCompleted?.(integration);
  };

  const loading = !data && query.loading;

  const integrationMetadatas = useMemo(() => {
    return compact(data?.integrationMetadatas?.items);
  }, [data?.integrationMetadatas?.items]);

  const [selectedIntegrationMetadataId, setSelectedIntegrationMetadataId] =
    useState(
      integrationMetadataIds.length === 1
        ? integrationMetadataIds[0]
        : undefined
    );

  const integrationMetadata = useMemo(() => {
    return integrationMetadatas.find(
      im => im.id === selectedIntegrationMetadataId
    );
  }, [integrationMetadatas, selectedIntegrationMetadataId]);

  const onClose = () => {
    setCreatedIntegrationId(undefined);
    originalOnClose();
  };

  // If an integration has been created, show the IntegrationModal instead where
  // they can complete the rest of the configuration if needed
  if (createdIntegrationId) {
    return (
      <IntegrationModal
        integrationId={createdIntegrationId}
        onClose={onClose}
      />
    );
  }

  const renderBody = () => {
    if (!integrationMetadata) {
      return (
        <Box align="center" fill={true} pad="medium">
          <Heading level="3">{t('select-integration-type')}</Heading>
        </Box>
      );
    }

    if (disableWriteOperation && launchControl) {
      return (
        <TrialExpiredIntegrationDetails
          integrationMetadata={integrationMetadata}
          onClose={onClose}
        />
      );
    }

    switch (integrationMetadata.type) {
      case IntegrationType.CustomWorkflow: {
        return (
          <AddCustomWorkflowIntegration
            integrationMetadata={integrationMetadata}
            onClose={onClose}
            onCompleted={onCompleted}
          />
        );
      }

      case IntegrationType.OAuth_2: {
        return (
          <AddOAuth2Integration
            integrationMetadata={integrationMetadata}
            onClose={onClose}
            onCompleted={onCompleted}
          />
        );
      }

      case IntegrationType.None: {
        return null;
      }

      default: {
        ensureUnreachable(integrationMetadata.type);
        return null;
      }
    }
  };

  const onChangeIntegrationMetadata = (
    event: GrommetSelectEvent<typeof integrationMetadata>
  ) => {
    setSelectedIntegrationMetadataId(event.value?.id);
  };

  return (
    <Modal
      data-testid="add-integration-modal"
      onClickOutside={onClose}
      onEsc={onClose}
      position="right"
      width="xlarge"
    >
      <LoadingOverlay label={t('loading.label')} loading={loading}>
        <Modal.Header
          border="bottom"
          pad={{ vertical: 'small', horizontal: 'medium' }}
        >
          <Heading level="3">{t('header')}</Heading>

          <Box margin={{ vertical: 'small' }}>
            {!!integrationMetadata && integrationMetadatas.length === 1 && (
              <IntegrationMetadataInfoRow
                integrationMetadataId={integrationMetadata.id}
                pad="none"
              />
            )}

            {integrationMetadatas.length > 1 && (
              <FormField label={t('form-fields.integration-metadata.label')}>
                <Select
                  data-testid="add-integration-modal__integration-metadata--select"
                  onChange={onChangeIntegrationMetadata}
                  options={integrationMetadatas.filter(
                    im => im !== integrationMetadata
                  )}
                  value={
                    integrationMetadata ? (
                      <IntegrationMetadataInfoRow
                        integrationMetadataId={integrationMetadata.id}
                        pad="small"
                      />
                    ) : undefined
                  }
                >
                  {(option: typeof integrationMetadatas[number]) => (
                    <IntegrationMetadataInfoRow
                      integrationMetadataId={option.id}
                      pad="small"
                    />
                  )}
                </Select>
              </FormField>
            )}
          </Box>
        </Modal.Header>
        <Modal.Body fill={true}>{renderBody()}</Modal.Body>
      </LoadingOverlay>
    </Modal>
  );
};
