import React from 'react';
import { PackageMarkdown } from '@common/components';
import { FormParagraphFragment } from '@generated/graphql-code-generator';
import { Box } from 'grommet';

type ParagraphProps = {
  definition: FormParagraphFragment;
};

/**
 * Renders a paragraph component for a workflow form.
 */
export const Paragraph: React.FC<ParagraphProps> = ({ definition }) => (
  <Box pad={{ vertical: 'small' }}>
    <PackageMarkdown content={definition.content} />
  </Box>
);
