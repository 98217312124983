import React, { useState } from 'react';
import { useAuthenticatedAxios } from '@common/hooks';
import { DataValidation } from '@generated/graphql-code-generator';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { downloadValidationLogs } from '../../utils/download-validation-logs';

type DownloadValidationLogsProps = {
  workspaceId: string;
  dataValidationId: DataValidation['id'];
};

/**
 * Renders a link for the user to download validation logs for the given form submission.
 */
export const DownloadValidationLogs: React.FC<DownloadValidationLogsProps> = ({
  workspaceId,
  dataValidationId,
}) => {
  const axiosInstance = useAuthenticatedAxios();
  const [t] = useTranslation(
    'features/workflows/components/FormDataRequestDetails'
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadLogs = () => {
    setIsDownloading(true);

    return downloadValidationLogs({
      axiosInstance,
      workspaceId,
      validationId: dataValidationId || '',
    }).finally(() => setIsDownloading(false));
  };

  return (
    <Button
      disabled={isDownloading}
      label={
        isDownloading
          ? t('download-logs.label-loading')
          : t('download-logs.label')
      }
      onClick={downloadLogs}
    />
  );
};
