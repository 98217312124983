import React from 'react';
import { ApolloError } from '@apollo/client';
import { LoadingOverlay } from '@common/components';
import { Alert, Area, ErrorButton, Modal, ModalProps } from '@voleer/ui-kit';
import { Button, FormField, Text, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';

export type DeleteWorkspaceModalProps = Pick<ModalProps, 'open'> & {
  /**
   * The value for the confirmation text input.
   */
  confirmationText: string;

  /**
   * Workspace display name.
   */
  displayName?: string | null;

  /**
   * Error to display in the modal.
   */
  error?: ApolloError | undefined;

  /**
   * Flag for if the modal should be in the loading state.
   */
  loading: boolean;

  /**
   * Callback called when user confirms.
   */
  onConfirm: () => void;

  /**
   * Handler for changes in the confirmation text input.
   */
  onChangeConfirmationText: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Callback called when the modal is closed.
   */
  onClose: () => void;

  /**
   * Flag for whether the modal is open or not.
   */
  open: boolean;

  /**
   * Flag for if the delete action is processing.
   */
  submitting: boolean;
};

/**
 * Modal to confirm deleting a workspace and its dependancies.
 */
export const DeleteWorkspaceModal: React.FC<DeleteWorkspaceModalProps> = ({
  confirmationText,
  displayName,
  error,
  loading,
  onChangeConfirmationText,
  onClose,
  onConfirm,
  open,
  submitting,
}) => {
  const [t] = useTranslation(
    'features/workflows/components/DeleteWorkspaceModal'
  );

  const renderBody = () => {
    const errorMessage = error instanceof Error ? error.message : error;

    return (
      <>
        {errorMessage && (
          <Alert
            data-testid="delete-workspace-modal__alert--error"
            icon={true}
            margin={{ bottom: 'medium' }}
            status="error"
          >
            {errorMessage}
          </Alert>
        )}
        <Area justify="between">
          <Text margin={{ top: '0' }}>
            {t('message', { workspace: displayName })}
          </Text>
          <FormField
            label={<Text size="small">{t('confirmation-label')}</Text>}
            margin={{ bottom: '0', top: 'small' }}
          >
            <TextInput
              autoFocus={true}
              data-testid="delete-workspace-modal__input--confirm"
              disabled={submitting}
              name="delete-workspace-modal__input--confirm"
              onChange={onChangeConfirmationText}
              value={confirmationText}
            />
          </FormField>
        </Area>
      </>
    );
  };

  return (
    <Modal
      onClickOutside={onClose}
      onEsc={onClose}
      onPressEnter={onConfirm}
      open={open}
      width="medium"
    >
      <Modal.Header>{t('title')}</Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>{renderBody()}</LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="delete-workspace-modal__btn--close"
          label={t('buttons.cancel.label')}
          onClick={onClose}
        />
        <ErrorButton
          data-testid="delete-workspace-modal__btn--confirm"
          disabled={loading || confirmationText !== t('confirmation-key')}
          label={t('buttons.confirm.label')}
          loading={submitting}
          onClick={onConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};
