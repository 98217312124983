import React from 'react';
import { DashboardStatus } from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';

const statusMapping: Record<DashboardStatus, BadgeStatus> = {
  [DashboardStatus.Deleted]: BadgeStatus.error,
  [DashboardStatus.Deleting]: BadgeStatus.info,
  [DashboardStatus.Pending]: BadgeStatus.info,
  [DashboardStatus.ProvisioningFailed]: BadgeStatus.error,
  [DashboardStatus.Provisioning]: BadgeStatus.info,
  [DashboardStatus.Ready]: BadgeStatus.ok,
  [DashboardStatus.Unknown]: BadgeStatus.unknown,
};

const i18nMapping: Record<DashboardStatus, string> = {
  [DashboardStatus.Deleted]: 'status-deleted',
  [DashboardStatus.Deleting]: 'status-deleting',
  [DashboardStatus.Pending]: 'status-pending',
  [DashboardStatus.ProvisioningFailed]: 'status-provisioning-failed',
  [DashboardStatus.Provisioning]: 'status-provisioning',
  [DashboardStatus.Ready]: 'status-ready',
  [DashboardStatus.Unknown]: 'status-unknown',
};

type DashboardStatusBadgeProps = Omit<BadgeProps, 'label' | 'status'> & {
  status: DashboardStatus;
};

/**
 * Renders a badge to display the given Dashboard status.
 */
export const DashboardStatusBadge: React.FC<DashboardStatusBadgeProps> = ({
  status,
  ...props
}) => {
  const [t] = useTranslation(
    'features/dashboards/components/DashboardStatusBadge'
  );
  const label = t(i18nMapping[status]);
  return <Badge {...props} label={label} status={statusMapping[status]} />;
};
