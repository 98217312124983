import React from 'react';
import { useFuseSearch } from '@common/hooks';
import { TenantMemberFragment } from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Box, BoxProps } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  EditTenantMemberFormValues,
  EditTenantMemberReturnCode,
} from '../EditTenantMemberForm';
import { TenantMemberListItem } from '../TenantMemberListItem';

/**
 * Props for the TenantMemberList component.
 */
export type TenantMemberListProps = {
  tenantMembers: TenantMemberFragment[];
  editingDisabled?: boolean;
  newlyAddedUserId: string;
  searchTerm?: string;
  selectedTenantMember: TenantMemberFragment | null;
  setSelectedTenantMember: (value: TenantMemberFragment | null) => void;
  disabled: boolean;
  onDeleteTenantMember: (id: string) => Promise<boolean>;
  onSendReminder: (id: string) => Promise<boolean>;
  onEditTenantMember: FormikSubmitFn<
    EditTenantMemberFormValues,
    EditTenantMemberReturnCode
  >;
};

const MessageContent: React.FC<BoxProps> = props => (
  <Box
    alignSelf="center"
    fill="vertical"
    justify="center"
    pad={{ vertical: 'small', horizontal: 'medium' }}
    {...props}
  />
);

/**
 * Component for displaying a list of tenant members.
 */
export const TenantMemberList: React.FC<TenantMemberListProps> = ({
  disabled,
  editingDisabled,
  onDeleteTenantMember,
  onSendReminder,
  onEditTenantMember,
  newlyAddedUserId,
  tenantMembers,
  searchTerm,
  selectedTenantMember,
  setSelectedTenantMember,
}) => {
  const [t] = useTranslation('features/tenant/components/TenantMemberList');
  const [search] = useFuseSearch(tenantMembers, {
    shouldSort: false,
    threshold: 0.3,
    tokenize: true,
    matchAllTokens: true,
    keys: ['firstName', 'lastName', 'emailAddress'],
  });

  if (tenantMembers.length === 0) {
    return <MessageContent>{t('no-data')}</MessageContent>;
  }

  const displayedTenantMembers = searchTerm
    ? search(searchTerm)
    : tenantMembers;
  if (searchTerm && !displayedTenantMembers.length) {
    return <MessageContent>{t('no-results')}</MessageContent>;
  }

  return (
    <>
      {displayedTenantMembers.map(tenantMember => {
        const isTenantMemberSelected = selectedTenantMember
          ? selectedTenantMember.id === tenantMember.id
          : false;
        const isDisabled =
          disabled || (!isTenantMemberSelected && !!selectedTenantMember);
        const isNew = newlyAddedUserId === tenantMember.id;
        const onEnterEditMode = () => setSelectedTenantMember(tenantMember);
        const onCancelEditTenantMember = () => setSelectedTenantMember(null);
        return (
          <TenantMemberListItem
            editingDisabled={editingDisabled}
            isDisabled={isDisabled}
            isEditMode={isTenantMemberSelected}
            isNew={isNew}
            key={`tenant-member-${tenantMember.id}`}
            onCancel={onCancelEditTenantMember}
            onDelete={onDeleteTenantMember}
            onEdit={onEditTenantMember}
            onEnterEditMode={onEnterEditMode}
            onSendReminder={onSendReminder}
            tenantMember={tenantMember}
          />
        );
      })}
    </>
  );
};
