import { useCallback, useEffect, useRef, useState } from 'react';
import { constant } from 'lodash';

const DEFAULT_DELAY = 500; // 500ms

export type UseDebouncedValueOptions<T = unknown> = {
  /**
   * How long to wait (in milliseconds) before providing updated values.
   */
  delay?: number;

  /**
   * Function to determine if a particular value should be debounced. If this
   * returns false then the updated value will be provided immediately.
   */
  shouldDebounce?: (value: T) => boolean;
};

type UseDebouncedReturnMeta = Readonly<{
  /**
   * Whether or not the value is currently being debounced.
   */
  debounced: boolean;
}>;

type UseDebouncedReturn<T> = readonly [T, UseDebouncedReturnMeta];

/**
 * Preserves a the previous value for an amount of time, then provides the
 * updated value only after that time has passed. Useful in cases where you
 * don't want to immediately respond to a changed prop value.
 *
 * For example, to avoid loading state flickering by only showing a loading
 * state if the loading process takes more than 250ms:
 *
 * ```javascript
 * const MyComponent = () => {
 *   const {data, loading} =  useSomeApiQuery();
 *   const [debouncedLoading] = useDebouncedValue(loading, {
 *     // Wait 250ms before providing the new value of `loading`
 *     delay: 250,
 *
 *     // Only delay returning the updated value when entering
 *     // loading state, not when exiting. That way we don't
 *     // force the user to wait an extra delay before showing
 *     // content after it's done loading.
 *     shouldDebounce: value => value === true,
 *   });
 *
 *   // Example on first render:
 *   console.log(loading); // starts true
 *   console.log(debouncedLoading); // also starts true
 *
 *   // Example on second render, after loading changed to `false`
 *   console.log(loading) ; // now false
 *   console.log(debouncedLoading); // still true until delay is finished
 *
 *   // Example on third render, after delay has passed:
 *   console.log(loading); // still false
 *   console.log(debouncedLoading); // now also false
 *
 *   if (debouncedLoading) {
 *     return "Loading...";
 *   }
 *
 *   return <RenderApiData data={data} />
 * }
 * ```
 *
 * @param value
 * @param options
 */
export const useDebouncedValue = <T>(
  value: T,
  options?: UseDebouncedValueOptions<T>
): UseDebouncedReturn<T> => {
  options = options || {};
  const delay = options.delay || DEFAULT_DELAY;
  const shouldDebounce = options.shouldDebounce || constant(true);

  const timeoutRef = useRef<number>();
  const [debounced, setDebounced] = useState(false);
  const [currentValue, setCurrentValue] = useState<T>(value);

  const provideValue = useCallback((newValue: T) => {
    setDebounced(false);
    setCurrentValue(newValue);
  }, []);

  useEffect(() => {
    if (currentValue === value) {
      clearTimeout(timeoutRef.current);
      return;
    }

    if (!shouldDebounce(value)) {
      clearTimeout(timeoutRef.current);
      provideValue(value);
      return;
    }

    setDebounced(true);
    timeoutRef.current = setTimeout(() => {
      provideValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [currentValue, delay, provideValue, shouldDebounce, value]);

  return [currentValue, { debounced }];
};
