import React, {
  Reducer,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { UnreachableCaseError } from '@voleer/types';
import { User, UserManager } from 'oidc-client';

export enum OidcStatus {
  /**
   * Not authenticated.
   */
  Unauthenticated = 'unauthenticated',

  /**
   * Authenticated.
   */
  Active = 'active',

  /**
   * Authenticated but token is expiring soon.
   */
  Expiring = 'expiring',

  /**
   * Token is expired.
   */
  Expired = 'expired',

  /**
   * User signed out.
   */
  SignedOut = 'signedOut',
}

/**
 * Configuration for the OIDC provider component.
 */
export type OidcProviderConfig = {
  /**
   * Default URL to redirect the user to after authentication, if no previous
   * return URL is present.
   *
   * Defaults to "/".
   */
  defaultReturnUrl: string;
};

export type OidcContextValue = Readonly<{
  /**
   * The current `oidc-client` User, or null if no User has been loaded yet.
   */
  user: User | null;

  /**
   * Overall OIDC authentication status.
   */
  status: OidcStatus;

  /**
   * UserManager instance.
   */
  manager: UserManager;

  /**
   * OidcProvider config.
   */
  config: OidcProviderConfig;
}>;

/**
 * Context to provide OIDC user information.
 */
export const OidcContext = createContext<OidcContextValue>({
  user: null,
  status: OidcStatus.Unauthenticated,
  manager: undefined as unknown as UserManager,
  config: undefined as unknown as OidcProviderConfig,
});

type ReducerState = Pick<OidcContextValue, 'status' | 'user'>;

type ReducerActions =
  | { type: 'expired' }
  | { type: 'expiring' }
  | { type: 'signedOut' }
  | { type: 'userLoaded'; user: User | null };

const reducer: Reducer<ReducerState, ReducerActions> = (state, action) => {
  switch (action.type) {
    case 'userLoaded':
      return {
        ...state,
        status:
          action.user && !action.user.expired
            ? OidcStatus.Active
            : OidcStatus.Unauthenticated,
        user: action.user,
      };
    case 'expiring':
      return {
        ...state,
        status: OidcStatus.Expiring,
      };
    case 'expired':
      return {
        ...state,
        status: OidcStatus.Expired,
      };
    case 'signedOut':
      return {
        ...state,
        status: OidcStatus.SignedOut,
      };
    default:
      throw new UnreachableCaseError(action);
  }
};

type OidcProviderProps = {
  /**
   * UserManager instance.
   */
  manager: UserManager;

  /**
   * Configuration.
   */
  config?: Partial<OidcProviderConfig>;
};

/**
 * Provides OIDC token information for the current user.
 *
 * Note: This component does not enforce authentication. To enforce
 * authentication use the `Authenticated` component.
 */
export const OidcProvider: React.FC<OidcProviderProps> = ({
  manager,
  config,
  children,
}) => {
  const { events } = manager;

  const [reducerState, dispatch] = useReducer(reducer, {
    status: OidcStatus.Unauthenticated,
    user: null,
  });

  // Initially retrieves the user when the provider is first rendered
  const initializeUser = useCallback(async () => {
    dispatch({
      type: 'userLoaded',
      user: await manager.getUser(),
    });
  }, [dispatch, manager]);

  // Handles the oidc-client `userLoaded` event.
  const handleUserLoaded = useCallback(
    (user: User) => {
      dispatch({
        type: 'userLoaded',
        user,
      });
    },
    [dispatch]
  );

  // Handles the oidc-client `accessTokenExpired` event.
  const handleAccessTokenExpired = useCallback(() => {
    dispatch({
      type: 'expired',
    });
  }, [dispatch]);

  // Handles the oidc-client `accessTokenExpiring` event.
  const handleAccessTokenExpiring = useCallback(() => {
    dispatch({
      type: 'expiring',
    });
  }, [dispatch]);

  // Handles the oidc-client `userSignedOut` event.
  const handleUserSignedOut = useCallback(() => {
    dispatch({
      type: 'signedOut',
    });
  }, [dispatch]);

  // Clear stale OIDC state entries from browser storage, this way the user's
  // localStorage doesn't continue to fill up with old entries over time. Could
  // be nice in the future if `oidc-client` provides a way to call this
  // automatically on its own, but currently it needs to be called by us.
  // See: https://github.com/IdentityModel/oidc-client-js/wiki#methods
  useEffect(() => {
    manager.clearStaleState();
  }, [manager]);

  useEffect(() => {
    // Load initial user
    initializeUser();

    // Listen for authentication lifecycle events
    events.addUserLoaded(handleUserLoaded);
    events.addAccessTokenExpired(handleAccessTokenExpired);
    events.addAccessTokenExpiring(handleAccessTokenExpiring);
    events.addUserSignedOut(handleUserSignedOut);

    // Cleanup
    return () => {
      events.removeUserLoaded(handleUserLoaded);
      events.removeAccessTokenExpired(handleAccessTokenExpired);
      events.removeAccessTokenExpiring(handleAccessTokenExpiring);
      events.removeUserSignedOut(handleUserSignedOut);
    };
  }, [
    events,
    initializeUser,
    handleUserLoaded,
    handleAccessTokenExpired,
    handleAccessTokenExpiring,
    handleUserSignedOut,
  ]);

  // Build the resulting context value
  const value = useMemo<OidcContextValue>(() => {
    return {
      ...reducerState,
      manager,
      config: {
        defaultReturnUrl: '/',
        ...config,
      },
    };
  }, [reducerState, manager, config]);

  return <OidcContext.Provider children={children} value={value} />;
};
