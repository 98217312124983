import { useState } from 'react';
import { MutationHookOptions } from '@apollo/client';
import {
  ScheduledJob,
  useChangeScheduledJobModalQuery,
  useChangeScheduledJobModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { ScheduledJobFormProps } from '..';
import { ChangeScheduledJobModalProps } from '.';

type UseChangeScheduledJobModalOptions = {
  /**
   * The ID of the Workspace where the scheduled job is being changed.
   */
  workspaceId: string;

  /**
   * Queries to refetch after cancelling the scheduled job.
   */
  refetchQueries?: MutationHookOptions['refetchQueries'];
};

export type UseChangeScheduledJobModalResult = {
  /**
   * Open the modal for the given scheduled job.
   */
  open: (scheduledJobId: ScheduledJob['id']) => void;

  /**
   * Closes the modal.
   */
  close: () => void;

  /**
   * Default props for passing to the modal component.
   */
  props: ChangeScheduledJobModalProps;
};

/**
 * Hook which provides default data, state, and props for the change scheduled job
 * modal.
 *
 * ```typescript
 * const { open, props: modalProps } = useChangeScheduledJobModal({ workspaceId });
 *
 * return <div>
 *   <Button label="Change Scheduled Job" onClick={() => open(scheduledJob.id)} />
 *   <ChangeScheduledJobModal {...modalProps} />
 * </div>
 * ```
 */
export const useChangeScheduledJobModal = ({
  workspaceId,
  refetchQueries,
}: UseChangeScheduledJobModalOptions): UseChangeScheduledJobModalResult => {
  const [scheduledJobId, setScheduledJobId] = useState<string>();

  const { data } = useChangeScheduledJobModalQuery({
    skip: !scheduledJobId,
    variables: {
      // Ignore potentially undefined scheduledJobId since the query will be
      // skipped if scheduledJobId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scheduledJobId: scheduledJobId!,
    },
  });

  const scheduledJob = data?.scheduledJob;

  const [updateSchedule, updateScheduleMutation] =
    useChangeScheduledJobModalSubmitMutation({
      refetchQueries,
      errorPolicy: 'all',
    });

  const onSubmit: ScheduledJobFormProps['onSubmit'] = async value => {
    if (!scheduledJobId) {
      return;
    }

    const { endTime, pattern, startTime, timezoneName, maxRecurrenceCount } =
      value;

    const response = await updateSchedule({
      variables: {
        input: {
          workspaceId,
          scheduledJobId,
          startTime,
          endTime,
          pattern,
          timezoneName,
          maxRecurrenceCount,
        },
      },
    });

    // Close the modal after successful mutation
    if (response.data && !response.errors?.length) {
      close();
    }
  };

  const onCancel = () => setScheduledJobId(undefined);

  const open: UseChangeScheduledJobModalResult['open'] = scheduledJobId => {
    setScheduledJobId(scheduledJobId);
  };

  const close: UseChangeScheduledJobModalResult['close'] = () => {
    setScheduledJobId(undefined);
  };

  const error = updateScheduleMutation.error;

  return {
    open,
    close,
    props: {
      scheduledJob,
      error,
      open: !!scheduledJobId,
      onCancel,
      onSubmit,
    },
  };
};
