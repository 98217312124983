import React from 'react';
import { AppLoading } from '@common/components';
import { useSignInCallback } from '@voleer/react-oidc-client';

/**
 * Page to perform the post sign-in callback from IdentityServer.
 */
export const SignInCallbackPage: React.FC = () => {
  useSignInCallback();

  return <AppLoading />;
};
