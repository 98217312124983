import React, { useRef, useState } from 'react';
import { TextAvatar } from '@common/components';
import { useAccountStatus, useTenantContext } from '@common/hooks';
import { TenantMemberFragment } from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import {
  Anchor,
  DropButton,
  DropButtonItem,
  FancyTable,
  Icon,
  RotatingIcon,
} from '@voleer/ui-kit';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Box, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaExclamation, FaSpinner } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';
import {
  EditTenantMemberForm,
  EditTenantMemberFormValues,
  EditTenantMemberReturnCode,
} from '../EditTenantMemberForm';
import { DeleteTenantMemberConfirmation } from './DeleteTenantMemberConfirmation';

type TenantMemberListItemProps = {
  tenantMember: TenantMemberFragment;
  /**
   * Disables editing functionality without disabling the whole item.
   */
  editingDisabled?: boolean;
  isEditMode: boolean;
  /**
   * isDisabled disables the whole item from being accessed.
   */
  isDisabled: boolean;
  isNew: boolean;
  onEnterEditMode: () => void;
  onCancel: () => void;
  onDelete: (id: string) => Promise<boolean>;
  onSendReminder: (id: string) => Promise<boolean>;
  onEdit: FormikSubmitFn<
    EditTenantMemberFormValues,
    EditTenantMemberReturnCode
  >;
};

/**
 * Component for displaying a tenant member list item on the Tenant Members tab.
 */
export const TenantMemberListItem: React.FC<TenantMemberListItemProps> = ({
  tenantMember,
  isDisabled,
  editingDisabled,
  isEditMode,
  isNew,
  onEdit,
  onCancel,
  onDelete,
  onSendReminder,
  onEnterEditMode,
}) => {
  // Hooks
  const [t] = useTranslation('features/tenant/components/TenantMemberListItem');
  const { disableWriteOperation } = useAccountStatus();
  const tenantContext = useTenantContext();
  const [notification, setNotification] = useState<React.ReactNode>(null);
  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const timeoutRef = useRef<number | undefined>();

  // Callbacks
  const pushNotification = (node: React.ReactNode) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setNotification(node);
    timeoutRef.current = setTimeout(() => setNotification(null), 5000);
  };

  const handleEditClick = () => {
    onEnterEditMode();
  };

  const handleSendReminderClick = async () => {
    const result = await onSendReminder(id);

    if (result) {
      pushNotification(
        <Box
          animation={{
            type: 'fadeIn',
            duration: 500,
          }}
        >
          <Text color="status-ok" size="small" textAlign="end">
            <FaCheck />
            &nbsp;
            {t('success.sending-reminder')}
          </Text>
        </Box>
      );
    } else {
      pushNotification(
        <Box
          animation={{
            type: 'fadeIn',
            duration: 500,
          }}
        >
          <Text color="status-error" size="small" textAlign="end">
            <FaExclamation />
            &nbsp;
            {t('error.sending-reminder')}
          </Text>
        </Box>
      );
    }
  };
  const handleConfirmDeleteClick = async () => {
    setDeleteMemberOpen(false);
    const isSuccessful = await onDelete(id);

    if (!isSuccessful) {
      pushNotification(
        <Box
          animation={{
            type: 'fadeIn',
            duration: 500,
          }}
        >
          <Text color="status-error" size="small" textAlign="end">
            <FaExclamation />
            &nbsp;
            {t('error.deleting-user')}
          </Text>
        </Box>
      );
    }
  };

  const handleCancelDeleteClick = () => setDeleteMemberOpen(false);

  const handleDeleteClick = () => {
    setDeleteMemberOpen(true);
  };
  const handleEditSubmit: FormikSubmitFn<
    EditTenantMemberFormValues,
    EditTenantMemberReturnCode
  > = async (values, actions) => {
    const result = await onEdit(values, actions);

    // Notify edit success
    if (result === EditTenantMemberReturnCode.Success) {
      pushNotification(
        <Box
          animation={{
            type: 'fadeIn',
            duration: 500,
          }}
        >
          <Text color="status-ok" size="small" textAlign="end">
            <FaCheck />
            &nbsp;
            {t('success.editing-user')}
          </Text>
        </Box>
      );
    }

    return result;
  };

  // Props
  const { id, firstName, lastName, emailAddress, lastSeenOn, role } =
    tenantMember;
  const userFullName = firstName ? `${firstName} ${lastName}` : emailAddress;
  const editTenantMemberValues: EditTenantMemberFormValues = {
    id,
    firstName,
    lastName,
    emailAddress,
    role,
  };
  const isCurrentUser = tenantContext.tenantMember?.id === tenantMember.id;
  const tenantDisplayName = tenantContext.tenant?.displayName ?? '';
  const lastSeenOnString = lastSeenOn
    ? formatDistanceToNow(parseISO(lastSeenOn))
    : t('never-seen');

  // Renders the tools dropdown section to the right of each list item
  const renderTools = () => {
    if (isCurrentUser) {
      return (
        <Anchor
          disabled={editingDisabled}
          onClick={handleEditClick}
          variation="primary"
        >
          {t('menu.edit')}
        </Anchor>
      );
    }

    const hasSignedIn = !!lastSeenOn;

    // Editing should always be enabled if the user has not signed
    // in yet, that way they can adjust the email address if there
    // is a typo, etc.
    const allowEditing = !(editingDisabled && hasSignedIn);

    return (
      <DropButton
        data-testid="tenant-member-list-item__actions-drop-btn"
        disabled={disableWriteOperation}
        icon={<Icon color="inherit" icon={MdMoreHoriz} />}
        title={t('menu.title')}
        variation="ghost"
      >
        {allowEditing && (
          <DropButtonItem label={t('menu.edit')} onClick={handleEditClick} />
        )}
        {!lastSeenOn && (
          <DropButtonItem
            label={t('menu.send-reminder')}
            onClick={handleSendReminderClick}
          />
        )}
        <DropButtonItem
          label={<Text color="status-error">{t('menu.delete')}</Text>}
          onClick={handleDeleteClick}
        />
      </DropButton>
    );
  };

  // Renders the user table row
  const userName = `${firstName} ${lastName}`;
  const userRole = t(`user-role.${role.toLowerCase()}`);
  const renderUserRow = () => (
    <FancyTable.Row
      background={isNew ? 'status-ok-light' : undefined}
      disabled={isDisabled}
    >
      <FancyTable.Cell>
        <Box align="center" direction="row" gap="small">
          <TextAvatar fontSize="medium" name={userName} size="xlarge" />
          <Box direction="column" gap="xsmall">
            <Box>
              <Text>{userName}</Text>
            </Box>
            <Box justify="center">
              <Text color="dark-2" size="small">
                {emailAddress}
              </Text>
            </Box>
          </Box>
        </Box>
      </FancyTable.Cell>
      <FancyTable.Cell>{userRole}</FancyTable.Cell>
      <FancyTable.Cell>{lastSeenOnString}</FancyTable.Cell>
      <FancyTable.Cell>{notification}</FancyTable.Cell>
      <FancyTable.Cell>
        <Box align="center">{renderTools()}</Box>
      </FancyTable.Cell>
    </FancyTable.Row>
  );

  // Renders the inline user editor
  const renderUserEditor = () => (
    <Box
      animation={{ type: 'fadeIn', delay: 100, duration: 250 }}
      background="white"
      elevation="medium"
      pad="medium"
      round="xsmall"
    >
      <Heading level="4">{t('edit-user', { userFullName })}</Heading>
      <EditTenantMemberForm
        onCancel={onCancel}
        onTenantMemberSave={handleEditSubmit}
        submitLabel={t('save')}
        submittingLabel={
          <Box align="center" direction="row" gap="xxsmall">
            <RotatingIcon icon={FaSpinner} size="16px" />{' '}
            <Text>{t('saving')}</Text>
          </Box>
        }
        values={editTenantMemberValues}
      />
    </Box>
  );

  return (
    <>
      {deleteMemberOpen && (
        <DeleteTenantMemberConfirmation
          confirmDeleteClick={handleConfirmDeleteClick}
          onClose={handleCancelDeleteClick}
          tenantDisplayName={tenantDisplayName}
          userFullName={userFullName}
        />
      )}
      {isEditMode ? renderUserEditor() : renderUserRow()}
    </>
  );
};
