import React from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert, LoadingButton, Modal, ModalProps } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormField, TextArea, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type AddDashboardModalFormValues = {
  displayName: string;
  description: string;
};

export type AddDashboardModalProps = Omit<ModalProps, 'onSubmit'> & {
  error?: Error | string;
  onCancel: () => void;
  onSubmit: (values: AddDashboardModalFormValues) => Promise<void> | void;
  workspaceId: string;
};

/**
 * Modal form for creating a Dashboard.
 */
export const AddDashboardModal: React.FC<AddDashboardModalProps> = ({
  error,
  onCancel,
  onSubmit: originalOnSubmit,
  workspaceId,
  ...modalProps
}) => {
  const [t] = useTranslation('pages/LibraryDashboardPage');

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(
      t('add-dashboard-modal.form.validation.name-required')
    ),
  });

  const initialValues: AddDashboardModalFormValues = {
    description: '',
    displayName: '',
  };

  const onSubmit: FormikSubmitFn<AddDashboardModalFormValues> = async (
    values,
    actions
  ) => {
    try {
      await originalOnSubmit(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal onClickOutside={onCancel} onEsc={onCancel} {...modalProps}>
      <Modal.Header>{t('add-dashboard-modal.heading')}</Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValidating }) => {
          const formProcessing = isSubmitting || isValidating;

          return (
            <Form>
              <Modal.Body>
                {error && (
                  <Alert margin={{ bottom: 'small' }} status="error">
                    {t('add-dashboard-modal.form.error', { error })}
                  </Alert>
                )}

                <Field name="displayName">
                  {(fieldProps: FieldProps<string>) => (
                    <FormField
                      error={fieldProps.meta.error}
                      label={t('add-dashboard-modal.form.labels.name')}
                    >
                      <TextInput
                        {...fieldProps.field}
                        autoFocus={true}
                        disabled={formProcessing}
                      />
                    </FormField>
                  )}
                </Field>

                <Field name="description">
                  {(fieldProps: FieldProps<string>) => (
                    <FormField
                      error={fieldProps.meta.error}
                      label={t('add-dashboard-modal.form.labels.description')}
                    >
                      <TextArea
                        {...fieldProps.field}
                        disabled={formProcessing}
                        fill={true}
                        resize={false}
                        style={{ maxWidth: 'none' }}
                      />
                    </FormField>
                  )}
                </Field>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  disabled={formProcessing}
                  label={t('add-dashboard-modal.form.buttons.cancel')}
                  onClick={onCancel}
                />

                <LoadingButton
                  disabled={formProcessing}
                  label={t('add-dashboard-modal.form.buttons.continue')}
                  loading={formProcessing}
                  primary={true}
                  type="submit"
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
