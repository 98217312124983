import React from 'react';
import { LibraryItemMetadataType } from '@generated/graphql-code-generator';
import { PropsOf, ensureUnreachable } from '@voleer/types';
import { Icon } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { FaChartBar, FaDatabase, FaRobot } from 'react-icons/fa';
import styled from 'styled-components';

/**
 * Prop types for the LibraryItemCardIcon.
 */
export type LibraryItemCardIconProps = Readonly<
  PropsOf<typeof Box> & {
    type: LibraryItemMetadataType;
  }
>;

const ICON_CONTAINER_DIMENSIONS = '38px';

const StyledIcon = styled(Icon)`
  margin: auto;
`;

/**
 * Renders the icon displayed in the LibraryItemCard.
 */
export const LibraryItemCardIcon: React.FC<LibraryItemCardIconProps> = ({
  type,
  ...props
}) => {
  let backgroundColor = 'dark-1';
  let icon = FaRobot;
  let size = 'medium';
  const color = 'white';
  switch (type) {
    case LibraryItemMetadataType.Automation:
      backgroundColor = 'neutral-3';
      icon = FaRobot;
      size = 'large';
      break;
    case LibraryItemMetadataType.DataSet:
      backgroundColor = 'neutral-2';
      icon = FaDatabase;
      size = 'medium';
      break;
    case LibraryItemMetadataType.Dashboard:
      backgroundColor = 'neutral-4';
      icon = FaChartBar;
      size = 'medium';
      break;
    case LibraryItemMetadataType.Unknown:
      break;
    default:
      ensureUnreachable(type);
      break;
  }

  return (
    <Box
      background={backgroundColor}
      height={ICON_CONTAINER_DIMENSIONS}
      round="xsmall"
      width={{ max: ICON_CONTAINER_DIMENSIONS, min: ICON_CONTAINER_DIMENSIONS }}
      {...props}
    >
      <StyledIcon color={color} icon={icon} size={size} />
    </Box>
  );
};
