import {
  DataResponseValue,
  FormSelectOption,
} from '@generated/graphql-code-generator';
import { UnreachableCaseError } from '@voleer/types';
import { InitialValues } from '../forms/components';

/**
 * Checks if the given value is a form select option.
 */
export const isFormSelectOption = (
  value: unknown
): value is FormSelectOption => {
  if (!value) {
    return false;
  }

  // For now we just check id, but if we add more value types we may need to be
  // more specific
  return typeof (value as FormSelectOption).id === 'string';
};

/**
 * Converts the values provided from form submission into an array of data
 * request values suitable for passing back to the API.
 */
export const toDataRequestValues = (values: InitialValues) =>
  Object.keys(values).map<DataResponseValue>(name => {
    const value = values[name];

    // Handle values that were not provided
    if (typeof value === 'undefined') {
      return {
        name,
        value: '',
      };
    }

    // Handle string inputs
    if (typeof value === 'string') {
      return {
        name,
        value,
      };
    }

    if (Array.isArray(value)) {
      const stringifiedValue = JSON.stringify(value);
      return {
        name,
        value: stringifiedValue,
      };
    }

    if (value instanceof Date) {
      const isoValue = value.toISOString();
      return {
        name,
        value: isoValue,
      };
    }

    if (typeof value === 'object') {
      const stringifiedValue = JSON.stringify(value);
      return {
        name,
        value: stringifiedValue,
      };
    }

    throw new UnreachableCaseError(value);
  });
