import React from 'react';
import { useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { UserBlade } from '@features/users';
import {
  IconIntegrations,
  IconLibrary,
  IconQuestion,
  IconWorkspace,
} from '@voleer/icons';
import { Box, Image } from 'grommet';
import { useTranslation } from 'react-i18next';
import { LeftNavigationLink } from './components';
import VOLEER_LOGO from './images/voleer-logo-light.svg';

const ICON_SIZE = '20px';

/**
 * Renders a left navigation bar component.
 */
export const LeftNavigation: React.FC = () => {
  const [t] = useTranslation('components/LeftNavigation');
  const { tenantMember } = useTenantContext();

  return (
    <Box
      background="neutral-5"
      fill="vertical"
      flex={{ grow: 0, shrink: 0 }}
      overflow={{ vertical: 'auto' }}
      style={{ zIndex: 0 }}
      width="224px"
    >
      <Box fill="vertical" flex={{ grow: 1, shrink: 0 }}>
        <Box direction="row" margin={{ vertical: 'medium' }}>
          <Box
            align="center"
            direction="row"
            gap="small"
            margin={{ left: '40px' }}
          >
            <Image
              a11yTitle="Voleer logo"
              alignSelf="center"
              src={VOLEER_LOGO}
              width="140px"
            />
          </Box>
        </Box>
        <Box direction="column" fill="vertical">
          <Box>
            <LeftNavigationLink
              icon={<IconLibrary size={ICON_SIZE} />}
              label={t('library')}
              to={urlFor('library')()}
            />
            <LeftNavigationLink
              icon={<IconWorkspace size={ICON_SIZE} />}
              label={t('workspaces')}
              to={urlFor('workspaces')()}
            />
            <LeftNavigationLink
              icon={<IconIntegrations size={ICON_SIZE} />}
              label={t('integrations')}
              to={urlFor('integrations')()}
            />
          </Box>
          <Box margin={{ bottom: 'small', top: 'auto' }}>
            <LeftNavigationLink
              icon={<IconQuestion size={ICON_SIZE} />}
              label={t('help')}
              openNewTab={true}
              to={urlFor('help')()}
              type="external"
            />
          </Box>
          <Box>{tenantMember && <UserBlade tenantMember={tenantMember} />}</Box>
        </Box>
      </Box>
    </Box>
  );
};
