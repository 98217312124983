import React from 'react';
import {
  PackageVersionRow,
  ReadmeElement,
  ShareLibraryItemButton,
} from '@common/components';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import { useLibraryAutomationPageQuery } from '@generated/graphql-code-generator';
import { Link } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import { LibraryItemPageRouteParams } from '..';
import { LibraryItemPageLayout, StickyContainer } from '../components';
import { LaunchTemplateSection } from './components';

type LibraryAutomationPageProps = Pick<
  LibraryItemPageRouteParams,
  'name' | 'packageName' | 'packageVersion' | 'publisherName'
>;

/**
 * Renders the view for an Automation item in the library.
 */
export const LibraryAutomationPage: React.FC<LibraryAutomationPageProps> = ({
  name,
  packageName,
  publisherName,
  packageVersion,
}) => {
  const [t] = useTranslation('pages/LibraryAutomationPage');

  const { 'tenant-ui-polling-configuration': pollingConfiguration } =
    useTypedFlags();

  const { error, data, ...query } = useLibraryAutomationPageQuery({
    pollInterval: pollingConfiguration?.publicLibraryList,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        names: [name],
        packageNames: [packageName],
        publisherNames: [publisherName],
        packageVersions:
          typeof packageVersion !== 'undefined' ? [packageVersion] : undefined,
      },
    },
  });

  const item = data?.automationMetadatas?.items?.[0];
  const loading = !data && query.loading;

  const automationDataSetDependencies = item?.dataSchemaDependencies?.items;

  // TODO: Handle more than one dataset
  const compatibleDataSet =
    automationDataSetDependencies?.[0]?.compatibleDataSetTemplate;

  const layout = (children?: React.ReactNode) => {
    return (
      <LibraryItemPageLayout
        children={children}
        error={error}
        libraryItem={item}
        loading={loading}
        subtitle={
          <>
            {item?.packageMetadata && (
              <PackageVersionRow
                color="dark-2"
                publishedOn={parseISO(item.packageMetadata.publishedOn)}
                size="small"
                tenantDisplayName={item.packageMetadata.publisher.displayName}
                tenantName={item.packageMetadata.publisher.name}
                version={item.packageMetadata.version}
              />
            )}

            {!!automationDataSetDependencies?.length && compatibleDataSet && (
              // TODO: Handle more than one dataset dependency
              <Text color="dark-2" size="small">
                <Trans i18nKey="subtitle.dataset-dependencies" t={t}>
                  <>Requires a </>

                  <Link
                    to={urlFor('libraryItemById')({
                      itemId: compatibleDataSet.id,
                    })}
                    variation="primary"
                  >
                    {{
                      dataSetDisplayName: compatibleDataSet.displayName,
                    }}
                  </Link>

                  <> Dataset</>
                </Trans>
              </Text>
            )}
          </>
        }
      />
    );
  };

  if (!item) {
    return layout();
  }

  return layout(
    <Box align="center" fill="vertical" pad="medium">
      <Box
        background="white"
        elevation="small"
        flex={{ shrink: 0 }}
        margin={{ bottom: 'medium' }}
        pad="small"
        round="xsmall"
        width="100%"
      >
        <Box
          alignSelf="center"
          direction="row"
          gap="small"
          justify="between"
          pad="small"
          width="xxlarge"
        >
          <Box pad="small" width="50%">
            <ReadmeElement
              description={item.description}
              readme={item.readme}
            />
          </Box>
          <Box pad="small" width="50%">
            <StickyContainer>
              <Box alignSelf="end" gap="small" width="400px">
                <LaunchTemplateSection template={item} />
                <Box alignSelf="end">
                  <ShareLibraryItemButton
                    itemDisplayName={item.displayName}
                    itemName={item.name}
                    packageName={item.packageMetadata.name}
                    publisherName={item.packageMetadata.publisher.name}
                    type={item.libraryItemType}
                  />
                </Box>
              </Box>
            </StickyContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
