import React from 'react';
import { PackageMarkdown, PackageMarkdownProps } from '@common/components';

type LabelMarkdownProps = Omit<PackageMarkdownProps, 'sanitizeSchema'>;

/**
 * Renders label content as markdown using custom/limited markdown
 * parsing rules.
 */
export const LabelMarkdown: React.FC<LabelMarkdownProps> = ({
  content,
  ...packageMarkdownProps
}) => {
  return (
    <PackageMarkdown
      Fragment={React.Fragment}
      content={content}
      {...packageMarkdownProps}
      sanitizeSchema={{
        // Only allow a limited set of inline HTML tags to be displayed in labels
        tagNames: ['a', 'strong', 'em', 'code'],
      }}
    />
  );
};
