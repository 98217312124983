import React from 'react';
import { InstanceStatus } from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';

export const statusMapping: Record<InstanceStatus, BadgeStatus> = {
  [InstanceStatus.Completed]: BadgeStatus.ok,
  [InstanceStatus.Failed]: BadgeStatus.error,
  [InstanceStatus.TimedOut]: BadgeStatus.error,
  [InstanceStatus.Canceled]: BadgeStatus.warning,
  [InstanceStatus.Cancelling]: BadgeStatus.warning,
  [InstanceStatus.Unknown]: BadgeStatus.unknown,
  [InstanceStatus.Pending]: BadgeStatus.processing,
  [InstanceStatus.Scheduled]: BadgeStatus.processing,
  [InstanceStatus.Running]: BadgeStatus.processing,
};

export const i18nMapping: Record<InstanceStatus, string> = {
  [InstanceStatus.Completed]: 'status-completed',
  [InstanceStatus.Failed]: 'status-failed',
  [InstanceStatus.TimedOut]: 'status-timed-out',
  [InstanceStatus.Canceled]: 'status-canceled',
  [InstanceStatus.Cancelling]: 'status-cancelling',
  [InstanceStatus.Unknown]: 'status-unknown',
  [InstanceStatus.Pending]: 'status-pending',
  [InstanceStatus.Scheduled]: 'status-scheduled',
  [InstanceStatus.Running]: 'status-running',
};

type InstanceStatusBadgeProps = Omit<BadgeProps, 'label' | 'status'> & {
  status: InstanceStatus;
};

/**
 * Renders a badge to display the given Instance status.
 */
export const InstanceStatusBadge: React.FC<InstanceStatusBadgeProps> = ({
  status,
  ...props
}) => {
  const [t] = useTranslation(
    'features/workflows/components/InstanceStatusBadge'
  );
  const label = t(i18nMapping[status]);
  return (
    <Badge
      {...props}
      label={label}
      status={statusMapping[status]}
      title={label}
    />
  );
};
