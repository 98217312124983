import {
  format as formatDate,
  isValid as isValidDate,
  parse as parseDate,
  set as setDate,
} from 'date-fns';

export const SCHEDULED_JOB_FORM_DATE_FORMAT = 'yyyy/MM/dd';

export const SCHEDULED_JOB_FORM_TIME_FORMAT = 'h:mm aa';

export type TimeValue = {
  hour: number;
  minute: number;
};

/**
 * Parses a string representing a time into it's parts (hour, minute).
 */
export const parseTimeValue = (
  value: string | undefined
): TimeValue | undefined => {
  if (typeof value !== 'string') {
    return undefined;
  }

  const parsed = parseDate(value, SCHEDULED_JOB_FORM_TIME_FORMAT, new Date());
  if (!isValidDate(parsed)) {
    return undefined;
  }

  const hour = parsed.getHours();
  const minute = parsed.getMinutes();

  return { hour, minute };
};

/**
 * Creates a string representing a time of day to use in the time-based fields
 * of the form.
 */
export const formatTimeValue = (value: TimeValue) => {
  const date = setDate(new Date(), {
    hours: value.hour,
    minutes: value.minute,
  });

  return formatDate(date, SCHEDULED_JOB_FORM_TIME_FORMAT);
};
