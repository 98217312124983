import {
  FieldFunctionOptions,
  FieldReadFunction,
  InMemoryCache,
  defaultDataIdFromObject,
  gql,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import introspectionQueryResultData from '@generated/fragmentTypes';
import { FormSelectOption } from '@generated/graphql-code-generator';
import { constant } from 'lodash';

const { possibleTypes } = introspectionQueryResultData;

/**
 * Represents a mapping of GraphQL typenames to functions that generate cache
 * IDs for the given type.
 */
type CacheIdOverrides<T extends { __typename: string }> = {
  [key in T['__typename']]: (obj: T) => string | null;
};

/**
 * Mapping of GraphQL types for which we need to implement custom cache ids.
 * Used to override the cache ID for particular records that can't be cached
 * using the default apollo-cache id
 */
const dataIdFromObjectOverrides: CacheIdOverrides<FormSelectOption> = {
  // Form select options should not be cached by id since they are unique to the
  // select component that contains them
  FormSelectOption: constant(null),
};

type FieldByIdArgOptions = {
  __typename: string;
  idFromArgs?: (args: NonNullable<FieldFunctionOptions['args']>) => string;
};

/**
 * Generates a field read function to tell the cache how to locate an item in a
 * cache based on the id in the field's arguments.
 *
 * See: https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
 */
const fieldByIdArg =
  ({
    __typename,
    idFromArgs = args => args.id,
  }: FieldByIdArgOptions): FieldReadFunction =>
  (value, { args, toReference, cache }) => {
    if (value) {
      return value;
    }

    if (!args) {
      return undefined;
    }

    // If the typename is not a union or interface then just return the reference
    if (!possibleTypes[__typename]) {
      return toReference({
        __typename,
        id: idFromArgs?.(args),
      });
    }

    // Determine the typename for union and interface types by checking if one of
    // any possible types exists in the cache with a matching ID
    const typename = possibleTypes[__typename].find(typename => {
      const id = idFromArgs?.(args);
      const ref = toReference({
        __typename: typename,
        id: idFromArgs?.(args),
      });

      if (typeof ref === 'undefined') {
        throw new Error(
          `Expected ref to be defined for ${typename} with id ${id} `
        );
      }

      // Check to see if a record with this id exists for the typename
      const result = cache.readFragment<{ __typename: string }>({
        fragment: gql`
        fragment _ on ${__typename} {
          __typename
        }
      `,
        id: cache.identify(ref),
      });

      return result?.__typename;
    });

    if (!typename) {
      return undefined;
    }

    return toReference({
      __typename: typename,
      id: idFromArgs?.(args),
    });
  };

/**
 * Creates the ApolloClient cache.
 */
export const createClientCache = () => {
  return new InMemoryCache({
    // Provide possible types for fragments
    // See: https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
    // See: https://graphql-code-generator.com/docs/plugins/fragment-matcher#usage-with-apollo-client-3
    possibleTypes,

    // Set type policies for the cache. Currently our main use-case for this is to
    // configure how the cache chooses to merge results for pagination on relay
    // connections.
    // See: https://www.apollographql.com/docs/react/pagination/core-api/
    // See: https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
    typePolicies: {
      Query: {
        fields: {
          dataSet: fieldByIdArg({ __typename: 'Dataset' }),

          instances: relayStylePagination(
            // Fixes #3905: By default `relayStylePagination` sets `keyArgs` to
            // `false`, which results in caching only by field name. Since we want
            // cache results separately for different workspaces we override
            // `keyArgs` accordingly.
            // See: https://www.apollographql.com/docs/react/pagination/key-args/#which-arguments-belong-in-keyargs
            // See: https://dev.azure.com/bittitan/Voleer/_workitems/edit/3905
            ['input', ['workspaceId']]
          ),

          integration: fieldByIdArg({ __typename: 'Integration' }),

          integrationMetadata: fieldByIdArg({
            __typename: 'IntegrationMetadata',
          }),

          libraryItems: relayStylePagination(),

          notificationRecipients: relayStylePagination(['input', ['statuses']]),

          step: fieldByIdArg({ __typename: 'Step' }),
        },
      },
      DataSet: {
        fields: {
          refreshRuns: relayStylePagination(),
        },
      },
    },

    // Add cache ID overrides for entities that do not use `id` as their cache key
    dataIdFromObject: obj => {
      if (
        obj.__typename &&
        typeof dataIdFromObjectOverrides[obj.__typename] === 'function'
      ) {
        return dataIdFromObjectOverrides[obj.__typename](obj);
      }
      return defaultDataIdFromObject(obj);
    },
  });
};
