import { useMemo } from 'react';
import { ensureUnreachable } from '@voleer/types';
import {
  ShapeValidatorSchema,
  composeValidators,
  createShapeValidator,
  createValidator,
  formikValidator,
  isGreaterThan,
  isLessThan,
  isNotNullOrUndefined,
  isPresent,
} from '@voleer/validators';
import { isValid as isValidDate, parse as parseDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ScheduleType, ScheduledJobFormValues } from './interface';
import {
  SCHEDULED_JOB_FORM_DATE_FORMAT,
  SCHEDULED_JOB_FORM_TIME_FORMAT,
} from './utils';

/**
 * Validator predicate for ensuring that there is at least one day of week value
 * selected in the form values.
 */
const hasDaysOfWeekSelected = (
  value?: ScheduledJobFormValues['daysOfWeek']
): value is ScheduledJobFormValues['daysOfWeek'] => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.length > 0;
};

/**
 * Validator predicate for ensuring that there is at least one month value
 * selected in the form values.
 */
const hasMonthsSelected = (
  value?: ScheduledJobFormValues['months']
): value is ScheduledJobFormValues['months'] => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.length > 0;
};

/**
 * Creates a validator predicate for ensuring that the given value is a string
 * representing a date that matches the given `date-fns` format string.
 *
 * See: https://date-fns.org/v2.14.0/docs/format
 */
const hasDateFormat = (format: string) => (value?: string) => {
  if (typeof value !== 'string') {
    return false;
  }
  const parsed = parseDate(value, format, new Date());
  return isValidDate(parsed);
};

/**
 * Hook that returns the validator function used by the form.
 */
export const useScheduledJobFormValidator = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  return useMemo(() => {
    // Validator for the "start date" field
    const startDateValidator = composeValidators([
      createValidator(isPresent, t('validation.start-date.required')),
      createValidator(
        hasDateFormat(SCHEDULED_JOB_FORM_DATE_FORMAT),
        t('validation.start-date.invalid')
      ),
    ]);

    // Validator for "at" time field
    const atTimeValidator = composeValidators([
      createValidator(isPresent, t('validation.at-time.required')),
      createValidator(
        hasDateFormat(SCHEDULED_JOB_FORM_TIME_FORMAT),
        t('validation.at-time.invalid')
      ),
    ]);

    // Validator for end date field
    const endDateValidator = composeValidators([
      createValidator(isPresent, t('validation.end-date.required')),
      createValidator(
        hasDateFormat(SCHEDULED_JOB_FORM_DATE_FORMAT),
        t('validation.end-date.invalid')
      ),
    ]);

    // Validator for day-of-week field
    const daysOfWeekValidator = composeValidators([
      createValidator(
        hasDaysOfWeekSelected,
        t('validation.days-of-week.required')
      ),
    ]);

    // Validator for day-of-week field
    const monthsValidator = composeValidators([
      createValidator(hasMonthsSelected, t('validation.months.required')),
    ]);

    // Validator for day-of-month field
    const dayOfMonthValidator = composeValidators([
      createValidator(isPresent, t('validation.day-of-month.required')),
      createValidator(isGreaterThan(0), t('validation.day-of-month.too-low')),
      createValidator(isLessThan(32), t('validation.day-of-month.too-high')),
    ]);

    // Validator for max recurrence count field
    const maxRecurrenceCountValidator = composeValidators([
      createValidator(isPresent, t('validation.max-recurrence-count.required')),
      createValidator(
        isGreaterThan(0),
        t('validation.max-recurrence-count.too-low')
      ),
    ]);

    // Validator for interval field
    const intervalValidator = composeValidators([
      createValidator(isPresent, t('validation.interval.required')),
      createValidator(isGreaterThan(0), t('validation.interval.too-low')),
    ]);

    // Validator for template configuration field
    const templateConfigurationValidator = createValidator(
      isNotNullOrUndefined,
      t('validation.template-configuration.required')
    );

    // Common validation schema for all form types
    const getCommonSchema = (
      values: ScheduledJobFormValues
    ): ShapeValidatorSchema<ScheduledJobFormValues> => {
      const schema: ShapeValidatorSchema<ScheduledJobFormValues> = {
        startDate: startDateValidator,
        templateConfiguration: templateConfigurationValidator,
      };

      if (values.endType === 'afterTimes') {
        schema.maxRecurrenceCount = maxRecurrenceCountValidator;
      }

      if (values.endType === 'afterDate') {
        schema.endDate = endDateValidator;
      }

      return schema;
    };

    // Validation schema for weekly schedules
    const getMonthlySchema = (values: ScheduledJobFormValues) => ({
      ...getCommonSchema(values),
      atTime: atTimeValidator,
      months: monthsValidator,
      dayOfMonth: dayOfMonthValidator,
    });

    // Validation schema for weekly schedules
    const getWeeklySchema = (values: ScheduledJobFormValues) => ({
      ...getCommonSchema(values),
      atTime: atTimeValidator,
      daysOfWeek: daysOfWeekValidator,
    });

    // Validation schema for daily schedules
    const getDailySchema = (values: ScheduledJobFormValues) => ({
      ...getCommonSchema(values),
      atTime: atTimeValidator,
    });

    // Validation schema for hourly schedules
    const getHourlySchema = (values: ScheduledJobFormValues) => ({
      ...getCommonSchema(values),
      hourlyInterval: intervalValidator,
    });

    // Validation schema for by-minute schedules
    const getMinutesSchema = (values: ScheduledJobFormValues) => ({
      ...getCommonSchema(values),
      minutelyInterval: intervalValidator,
    });

    return formikValidator(
      createShapeValidator<ScheduledJobFormValues>(values => {
        switch (values.type) {
          case ScheduleType.months: {
            return getMonthlySchema(values);
          }
          case ScheduleType.weeks: {
            return getWeeklySchema(values);
          }
          case ScheduleType.days: {
            return getDailySchema(values);
          }
          case ScheduleType.hours: {
            return getHourlySchema(values);
          }
          case ScheduleType.minutes: {
            return getMinutesSchema(values);
          }
          default: {
            ensureUnreachable(values.type);
            return {};
          }
        }
      })
    );
  }, [t]);
};
