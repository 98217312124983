import React, { useMemo } from 'react';
import { LoadingOverlay } from '@common/components';
import { useTypedFlags } from '@features/launch-darkly';
import {
  FormDataRequestDetails,
  InstanceEmptyState,
  StepDetails,
  isEndedInstanceStatus,
  isUnsuccessfulInstanceStatus,
  sortSteps,
} from '@features/workflows';
import {
  StepStatus,
  useConfigureCustomWorkflowIntegrationQuery,
} from '@generated/graphql-code-generator';

export type ConfigureCustomWorkflowIntegrationProps = {
  integrationId: string;
};

/**
 * Renders the relevant workflow step/status for a CustomWorkflow Integration
 * that has a workflow running.
 */
export const ConfigureCustomWorkflowIntegration: React.FC<ConfigureCustomWorkflowIntegrationProps> =
  ({ integrationId }) => {
    const { 'tenant-ui-polling-configuration': pollingConfig } =
      useTypedFlags();

    const { data, error, ...query } =
      useConfigureCustomWorkflowIntegrationQuery({
        pollInterval: pollingConfig?.instanceStepsAndDataRequests,
        variables: {
          integrationId,
        },
      });

    const loading = !data && query.loading;

    const integration = data?.integration;

    const candidateInstance =
      integration?.__typename === 'CustomWorkflowIntegration'
        ? integration?.candidateInstance
        : undefined;

    const steps = useMemo(() => {
      const stepItems = candidateInstance?.steps?.items;
      if (!stepItems) {
        return undefined;
      }

      return sortSteps(stepItems);
    }, [candidateInstance?.steps?.items]);

    const currentStep = steps?.[0];

    const dataRequest = currentStep?.dataRequest;

    const layout = (children?: React.ReactNode) => {
      return <LoadingOverlay loading={loading}>{children}</LoadingOverlay>;
    };

    if (!candidateInstance) {
      return layout();
    }

    if (isUnsuccessfulInstanceStatus(candidateInstance.status)) {
      const lastFailedStep = steps?.find(
        step => step.status === StepStatus.Failed
      );

      if (lastFailedStep) {
        return layout(<StepDetails stepId={lastFailedStep.id} />);
      }
    }

    if (!currentStep || isEndedInstanceStatus(candidateInstance.status)) {
      return layout(<InstanceEmptyState instance={candidateInstance} />);
    }

    if (dataRequest) {
      return (
        <FormDataRequestDetails
          onCompleted={() => {
            query.refetch();
          }}
          stepId={currentStep.id}
        />
      );
    }

    return layout(<StepDetails stepId={currentStep.id} />);
  };
