import React from 'react';
import { urlFor } from '@common/utils';
import { Redirect, RouteChildrenProps } from 'react-router';

export type HomePageProps = RouteChildrenProps & {
  // Nothing yet
};

/**
 * Renders the home page.
 */
export const HomePage: React.FC<HomePageProps> = () => {
  return <Redirect to={urlFor('library')()} />;
};
