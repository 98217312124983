import React, { FormEventHandler } from 'react';
import { PropsOf } from '@voleer/types';
import { GhostButton, Icon } from '@voleer/ui-kit';
import { Box, Button, Heading, TextInput } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaPencilAlt, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

/**
 * Prop types for the `TextInputHeading` component.
 */
export type TextInputHeadingProps = Readonly<
  PropsOf<typeof TextInput> & {
    /**
     * Whether or not the user is editing the text.
     */
    editing: boolean;

    /**
     * Callback for changing the value of the text input.
     */
    onChange: (
      event: Pick<React.ChangeEvent<HTMLInputElement>, 'target'>
    ) => void;

    /**
     * Callback for canceling out of the text input.
     */
    onClickCancel: () => void;

    /**
     * Callback for clicking the heading.
     */
    onClickHeading: () => void;

    /**
     * Callback for saving the new text.
     */
    onClickSave: (event: Pick<React.FormEvent, 'preventDefault'>) => void;

    /**
     * Whether or not the changes are being saved.
     */
    saving: boolean;
  }
>;

const StyledTextInputHeading = styled(TextInput)`
  ${props => {
    return `
      background: ${normalizeColor('white', props.theme)};
      border: 1px solid ${normalizeColor('border-default', props.theme)};
      display:flex;
      font-family: TT Norms Pro Demi Bold, sans-serif;
      font-height: 36px;
      font-size: 30px;
      font-weight: 600;
      padding: 2px 8px;
      width: 100%;
    `;
  }}
`;

/**
 * Renders an inline-editable heading.
 */
export const TextInputHeading: React.FC<TextInputHeadingProps> = props => {
  const [t] = useTranslation('components/TextInputHeading');

  const width =
    typeof props.value === 'string' ? props.value.length * 15 + 300 : 0;

  if (!props.value && !props.editing) {
    return null;
  }

  const onSubmit: FormEventHandler = e => {
    // Prevent the submit event from bubbling up to any containing form elements
    e.stopPropagation();
    props.onClickSave(e);
  };

  return (
    <Box data-testid="text-input-heading__container">
      {!props.editing && (
        <Box align="center" direction="row">
          <Heading
            data-testid="text-input-heading--plain"
            level="3"
            margin="none"
            onClick={props.onClickHeading}
            title={props.value?.toString()}
            truncate={true}
          >
            {props.value}
          </Heading>
          <Box margin={{ left: 'small' }}>
            <GhostButton
              icon={<Icon icon={FaPencilAlt} size="medium" />}
              onClick={props.onClickHeading}
              title={t('edit')}
            />
          </Box>
        </Box>
      )}

      {props.editing && (
        <form data-testid="text-input-heading__form" onSubmit={onSubmit}>
          <Box
            align="center"
            direction="row"
            width={{ max: '80%', min: '300px', width: `${width}px` }}
          >
            <StyledTextInputHeading
              autoFocus={true}
              data-testid="text-input-heading--text-input"
              onChange={props.onChange}
              value={props.value}
            />
            <Button
              data-testid="text-input-heading__action-btn--save"
              disabled={props.saving}
              icon={
                <Icon
                  color={normalizeColor('white', props)}
                  icon={FaCheck}
                  size="medium"
                />
              }
              margin={{ left: 'small' }}
              primary={true}
              title={t('save')}
              type="submit"
            />
            <Button
              data-testid="text-input-heading__action-btn--cancel"
              disabled={props.saving}
              icon={<Icon icon={FaTimes} size="medium" />}
              margin={{ left: 'small' }}
              onClick={props.onClickCancel}
              title={t('cancel')}
              type="button"
            />
          </Box>
        </form>
      )}
    </Box>
  );
};
