import React from 'react';
import { FormColumnsFragment } from '@generated/graphql-code-generator';
import { Box, Grid } from 'grommet';
import {
  RenderFormComponent,
  RenderFormComponentRefHandler,
} from '../components';

type ColumnsProps = {
  definition: FormColumnsFragment;
  disabled?: boolean;
  formFieldRefHandler: RenderFormComponentRefHandler;
};

/**
 * Renders workflow form columns.
 */
export const Columns: React.FC<ColumnsProps> = ({
  definition: { columns, name: columnsName },
  disabled,
  formFieldRefHandler,
}) => {
  return (
    <Grid
      columns={{ count: columns.length, size: 'auto' }}
      gap="medium"
      responsive={false}
    >
      {columns.map(column => (
        <Box key={`${columnsName}-${column.name}`}>
          {column.components.map(component => (
            <RenderFormComponent
              definition={component}
              disabled={disabled}
              formFieldRefHandler={formFieldRefHandler}
              key={`${columnsName}-${column.name}-${component.name}`}
            />
          ))}
        </Box>
      ))}
    </Grid>
  );
};
