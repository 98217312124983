import React from 'react';
import { useAccountStatus } from '@common/hooks';
import { ScheduledJobScheduleDetails } from '@features/scheduled-jobs';
import {
  ScheduledJob,
  ScheduledJobStatus,
  useToggleDataSetScheduleActivityMutation,
} from '@generated/graphql-code-generator';
import { Area, AreaContent, AreaHeading } from '@voleer/ui-kit';
import { Box, Button, CheckBox, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  ChangeRefreshScheduleModal,
  useChangeRefreshScheduleModal,
} from '../ChangeRefreshScheduleModal';

/**
 * Prop types for the `RefreshSchedule` component.
 */
export type RefreshScheduleProps =
  | Readonly<{
      /**
       * The dataset ID.
       */
      dataSetId: string;

      /**
       * The refresh schedule for the dataset.
       */
      schedule?: Pick<
        ScheduledJob,
        'id' | 'maxRecurrenceCount' | 'pattern' | 'status' | 'timezoneName'
      > | null;

      /**
       * The ID of the workspace that contains the dataset.
       */
      workspaceId: string;
    }>
  | null
  | undefined;

/**
 * Renders the "Refresh schedule" section in the "Summary" of a dataset
 * configuration.
 */
export const RefreshSchedule: React.FC<RefreshScheduleProps> = ({
  dataSetId,
  schedule,
  workspaceId,
}) => {
  const [t] = useTranslation('pages/DataSetPage');

  const { disableWriteOperation } = useAccountStatus();

  const { changeRefreshScheduleModalProps, openRefreshScheduleModal } =
    useChangeRefreshScheduleModal({ dataSetId, workspaceId });

  // TODO (cvalmonte): Abstract out the toggle switch concerns to a hook and component for testing/stories
  const [toggleRefreshSchedule] = useToggleDataSetScheduleActivityMutation();

  /**
   * On click handler for toggling the dataset refresh-schedule on/off.
   */
  const toggleSwitchOnClick = async () => {
    if (!schedule) return;

    await toggleRefreshSchedule({
      variables: { input: { scheduleId: schedule.id, workspaceId } },
    });
  };

  const isRefreshScheduleOn = schedule?.status === ScheduledJobStatus.Active;

  return (
    <>
      <Area fill="vertical" paper={true}>
        <AreaHeading label={t('metadata-headings.refresh-schedule')}>
          <CheckBox
            checked={isRefreshScheduleOn}
            data-testid="refresh-schedule__checkbox"
            disabled={disableWriteOperation}
            name="toggle-refresh-schedule"
            onClick={toggleSwitchOnClick}
            toggle={true}
          />
        </AreaHeading>

        <AreaContent height="100%" pad="none">
          {isRefreshScheduleOn && (
            <>
              <Box
                align="center"
                margin="auto 0"
                pad={{ horizontal: 'small', vertical: 'medium' }}
              >
                <Heading level="3" size="small">
                  {schedule && (
                    <ScheduledJobScheduleDetails scheduledJob={schedule} />
                  )}
                </Heading>
              </Box>

              <Box
                direction="row"
                justify="center"
                margin={{ bottom: 'medium' }}
              >
                <Button
                  disabled={disableWriteOperation}
                  label={t('action-buttons.change-schedule')}
                  onClick={openRefreshScheduleModal}
                />
              </Box>
            </>
          )}

          {!isRefreshScheduleOn && (
            <Box align="center" height="100%" justify="center" pad="medium">
              <Heading level="3" margin={{ top: 'none' }}>
                {t('refresh-schedule-disabled.heading')}
              </Heading>
              <Text textAlign="center">
                {t('refresh-schedule-disabled.subheading')}
              </Text>
            </Box>
          )}
        </AreaContent>
      </Area>

      {schedule && (
        <ChangeRefreshScheduleModal
          schedule={schedule}
          {...changeRefreshScheduleModalProps}
        />
      )}
    </>
  );
};
