import React from 'react';
import {
  AppLayout,
  ErrorOverlay,
  LoadingOverlay,
  PageContent,
} from '@common/components';
import { urlFor } from '@common/utils';
import {
  DashboardStatusBadge,
  DeleteDashboardModal,
  useDeleteDashboardModal,
} from '@features/dashboards';
import { useTypedFlags } from '@features/launch-darkly';
import { ConfigurationPageSubtitle } from '@features/workflows';
import {
  DashboardStatus,
  useDashboardPageQuery,
  useFindWorkspaceQuery,
} from '@generated/graphql-code-generator';
import { PropsOf, ensureUnreachable } from '@voleer/types';
import { LoadingButton, RotatingIcon } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { models } from 'powerbi-client';
import { EmbedType, PowerBIEmbed } from 'powerbi-client-react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';

/**
 * Route parameters for dashboard page
 */
export type DashboardPageRouteParams = {
  workspaceId: string;
  dashboardId: string;
};

const StyledPowerBIEmbedComponent: React.FC<
  PropsOf<typeof PowerBIEmbed> & { className?: string }
> = ({ className, ...props }) => {
  return <PowerBIEmbed cssClassName={className} {...props} />;
};

const StyledPowerBIEmbed = styled(StyledPowerBIEmbedComponent)`
  height: 100%;
  width: 100%;
  iframe {
    padding: 0;
    margin: 0;
    border: 0;
  }
`;

/**
 * Renders the page for viewing a Dashboard
 */
export const DashboardPage: React.FC = () => {
  const match = useRouteMatch<DashboardPageRouteParams>();
  const history = useHistory();

  const { workspaceId, dashboardId } = match.params;

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const [t] = useTranslation('pages/DashboardPage');

  const workspaceQuery = useFindWorkspaceQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: workspaceId },
  });

  const dashboardPageQuery = useDashboardPageQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfig?.dashboardPage,
    variables: {
      id: dashboardId,
    },
  });

  const { open: openDeleteDashboardModal, props: deleteDashboardModalProps } =
    useDeleteDashboardModal({
      onCompleted: () => {
        history.push(urlFor('dashboards')({ workspaceId }));
      },
    });

  const queries = [workspaceQuery, dashboardPageQuery];

  const loading = queries.some(query => !query.data && query.loading);
  const error = queries.find(query => query.error)?.error;

  const workspace = workspaceQuery.data?.workspace;

  const dashboard = dashboardPageQuery.data?.dashboard;
  const dashboardReport = dashboard?.dashboardReport;

  const subtitle = dashboard && (
    <ConfigurationPageSubtitle
      dataSet={dashboard.dataSet}
      metadataDisplayName={dashboard.dashboardMetadata?.displayName}
      statusBadge={<DashboardStatusBadge status={dashboard.status} />}
      version={dashboard.dashboardMetadata?.packageMetadata.version}
    />
  );

  const renderToolbar = () => {
    if (!dashboard) {
      return null;
    }

    return (
      <AppLayout.Toolbar>
        <LoadingButton
          data-testid="dashboard-page__delete-button"
          label={t('buttons.delete.label')}
          onClick={() => openDeleteDashboardModal(dashboard)}
          size="small"
        />
      </AppLayout.Toolbar>
    );
  };

  const layout = (children?: React.ReactNode) => (
    <AppLayout
      breadcrumbs={[
        {
          title: t('breadcrumbs.workspaces'),
          to: urlFor('workspaces')(),
        },
        {
          title: workspace?.displayName || '',
          to: urlFor('workspace')({ workspaceId }),
        },
        {
          title: t('breadcrumbs.dashboards'),
          to: urlFor('dashboards')({ workspaceId }),
        },
      ]}
      description={dashboard?.description || undefined}
      subtitle={subtitle}
      title={dashboard?.displayName}
      toolbar={renderToolbar()}
    >
      <PageContent
        empty={{
          body: t('empty.body'),
          empty: !dashboard,
          heading: t('empty.heading'),
        }}
        error={error}
        loading={loading}
      >
        <Box fill={true} pad={{ horizontal: 'medium', top: 'small' }}>
          {children}
        </Box>

        <DeleteDashboardModal {...deleteDashboardModalProps} />
      </PageContent>
    </AppLayout>
  );

  if (loading || error || !dashboard) {
    return layout();
  }

  switch (dashboard.status) {
    case DashboardStatus.Ready: {
      if (!dashboardReport) {
        throw new Error(
          `Missing dashboardReport in ${dashboard.status} status`
        );
      }

      const { reportId, embedUrl, embedToken } = dashboardReport;

      return layout(
        <StyledPowerBIEmbed
          embedConfig={{
            id: reportId,
            type: EmbedType.Report,
            accessToken: embedToken,
            embedUrl,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  position: models.PageNavigationPosition.Left,
                },
              },
            },
          }}
        />
      );
    }

    case DashboardStatus.Pending:
    case DashboardStatus.Provisioning: {
      return layout(
        <LoadingOverlay
          icon={<RotatingIcon icon={FaSpinner} size="xxlarge" />}
          label={t('provisioning.body')}
          loading={true}
        />
      );
    }

    case DashboardStatus.ProvisioningFailed: {
      return layout(<ErrorOverlay error={t('provisioning-failed.body')} />);
    }

    case DashboardStatus.Unknown:
    case DashboardStatus.Deleting:
    case DashboardStatus.Deleted: {
      return layout();
    }

    default: {
      ensureUnreachable(dashboard.status);
      return layout();
    }
  }
};
