import {
  format as formatDate,
  isValid as isValidDate,
  parse as parseDate,
  parseISO,
} from 'date-fns';
import { format as formatZonedDate, utcToZonedTime } from 'date-fns-tz';
import {
  convertDateToTime,
  convertTimeToDate,
  findTimeZone,
  setTimeZone,
} from 'timezone-support';

/**
 * Attempts to parse a Date from an ISO string. If successful, a `Date` is
 * returned, otherwise returns `undefined`.
 *
 * This function uses `date-fns` for parsing.
 * See: https://date-fns.org/docs/parseISO
 */
export const tryParseISO = (value?: string | null) => {
  if (typeof value !== 'string') {
    return undefined;
  }
  const parsed = parseISO(value);
  return isValidDate(parsed) ? parsed : undefined;
};

/**
 * Attempts to find timezone info for the specified timezone name. Returns
 * `undefined` if the specified timezone does not exist.
 *
 * The timezone name must be a valid tz database timezone.
 * See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
const tryFindTimeZone = (name: string) => {
  try {
    return findTimeZone(name);
  } catch (err) {
    if (err instanceof Error && err.message.startsWith('Unknown time zone')) {
      return undefined;
    }
    throw err;
  }
};

/**
 * Attempts to create a formatted string from a Date value.
 */
export const tryFormatDate = (
  date: Date | null | undefined,
  format: string
) => {
  if (!date) {
    return undefined;
  }
  return formatDate(date, format);
};

/**
 * Attempts to return a parsed Date from a string.
 */
export const tryParseDate = (
  value: string | null | undefined,
  format: string
) => {
  if (typeof value !== 'string') {
    return undefined;
  }
  const parsed = parseDate(value, format, new Date());
  if (!isValidDate(parsed)) {
    return undefined;
  }
  return parsed;
};

/**
 * Attempts to parse a Date from a string using the given format string.  If
 * successful, a `Date` is returned, otherwise returns `undefined`.
 *
 * The timezone must be a valid tz database timezone.
 * See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * The format string must be a valid `date-fns` format string.
 * See: https://date-fns.org/docs/parse
 *
 * @param timezone the timezone to convert to
 * @param formatString the format to use
 * @param dateString the string to parse
 */
export const tryParseZonedDate = (
  timezone: string,
  formatString: string,
  dateString: string | null | undefined
): Date | undefined => {
  if (typeof dateString !== 'string') {
    return undefined;
  }

  const date = parseDate(dateString, formatString, new Date());
  if (!isValidDate(date)) {
    return undefined;
  }

  const timezoneInfo = tryFindTimeZone(timezone);
  if (!timezoneInfo) {
    return undefined;
  }

  const zonedDate = convertTimeToDate(
    setTimeZone(convertDateToTime(date), timezoneInfo)
  );

  return zonedDate;
};

/**
 * Attempts to format a date using the specified format adjusted to the
 * specified timezone. If successful a string in the specified format is
 * returned, otherwise returns `undefined`.
 *
 * The timezone must be a valid tz database timezone.
 * See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * The format string must be a valid `date-fns` format string.
 * See: https://date-fns.org/docs/parse
 *
 * @param timezone the timezone to convert to
 * @param formatString the format to use
 * @param dateString the string to parse
 */
export const tryFormatZonedDate = (
  timezone: string,
  formatString: string,
  date?: Date | null
) => {
  if (!date) {
    return undefined;
  }

  try {
    return formatZonedDate(utcToZonedTime(date, timezone), formatString, {
      timeZone: timezone,
    });
  } catch (err) {
    if (
      err instanceof RangeError &&
      err.message.startsWith('Invalid time zone')
    ) {
      return undefined;
    }
    throw err;
  }
};
