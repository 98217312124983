import React from 'react';
import { useDateFormat } from '@common/hooks';
import { urlFor } from '@common/utils';
import { ScheduledJobStatusBadge } from '@features/scheduled-jobs';
import {
  DetailsSection,
  DetailsSectionDateInfo,
  DetailsSectionTitle,
  DetailsSectionValue,
  useWorkflowTranslations,
} from '@features/workflows';
import { Link } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  ScheduledJobProp,
  TemplateConfigurationProp,
  TemplateMetadataProp,
} from './interface';

export type ScheduledJobDetailsProps = {
  scheduledJob: ScheduledJobProp;
  templateConfiguration: TemplateConfigurationProp;
  templateMetadata?: TemplateMetadataProp;
  workspaceId: string;
};

/**
 * Renders the "Details" section in the scheduled job page.
 */
export const ScheduledJobDetails: React.FC<ScheduledJobDetailsProps> = ({
  templateConfiguration,
  templateMetadata,
  scheduledJob,
  workspaceId,
}) => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobDetails'
  );

  const { getTenantMemberDisplayName } = useWorkflowTranslations();

  const [format, { DateFormat }] = useDateFormat();

  const templateConfigurationUrl = urlFor('templateConfiguration')({
    workspaceId,
    templateConfigurationId: templateConfiguration.id,
  });

  return (
    <DetailsSection
      leftColumnContent={
        <>
          <DetailsSectionTitle>{t('template')}</DetailsSectionTitle>
          {templateMetadata ? (
            <Link
              data-testid="template-field--link"
              title={templateMetadata.displayName}
              to={urlFor('libraryItemById')({
                itemId: templateMetadata.id,
              })}
              variation="primary"
            >
              <DetailsSectionValue>
                {templateMetadata.displayName}
              </DetailsSectionValue>
            </Link>
          ) : (
            <Text data-testid="template-field--empty">-</Text>
          )}
          <DetailsSectionTitle>
            {t('template-configuration')}
          </DetailsSectionTitle>
          <Link
            title={templateConfiguration.displayName || '-'}
            to={templateConfigurationUrl}
            variation="primary"
          >
            <DetailsSectionValue>
              {templateConfiguration.displayName}
            </DetailsSectionValue>
          </Link>

          <DetailsSectionTitle>{t('created-by')}</DetailsSectionTitle>
          <DetailsSectionDateInfo
            date={format(parseISO(scheduledJob.createdOn), DateFormat.Long)}
            name={getTenantMemberDisplayName(scheduledJob.createdBy) ?? ''}
          />
        </>
      }
      rightColumnContent={
        <>
          <DetailsSectionTitle>{t('status')}</DetailsSectionTitle>
          {/* Using a div to prevent the badge from taking up the entire column width */}
          <div>
            <ScheduledJobStatusBadge status={scheduledJob.status} />
          </div>
        </>
      }
    />
  );
};
