import React, { useMemo } from 'react';
import {
  StepStatus,
  useStepDetailsQuery,
} from '@generated/graphql-code-generator';
import { ensureUnreachable, isNotNullOrUndefined } from '@voleer/types';
import { memoize, orderBy } from 'lodash';
import {
  CanceledHistoryElement,
  CompletedHistoryElement,
  FailedHistoryElement,
  HistoryElementsDiagram,
  RunningHistoryElement,
  SchedulingHistoryElement,
  StartedHistoryElement,
  StatusMessageHistoryElement,
  WaitingForInputHistoryElement,
} from '../../..';

export type StepHistoryProps = {
  stepId: string;
};

/**
 * Displays the status history for the given step.
 */
export const StepHistory: React.FC<StepHistoryProps> = ({ stepId }) => {
  const { data } = useStepDetailsQuery({
    variables: { stepId },
  });

  const step = data?.step;

  // Use a function for status messages since we can compute them lazily since
  // we don't always need to render status messages in every case
  const statusMessages = useMemo(() => {
    return memoize(() => {
      const items =
        step?.statusMessages?.items?.filter(isNotNullOrUndefined) || [];

      return orderBy(items, 'createdUtc', 'desc').map((message, index) => {
        return (
          <StatusMessageHistoryElement
            graphName={`status-message-${index}`}
            key={`status-message-${index}`}
            message={message}
          />
        );
      });
    });
  }, [step]);

  // Render nothing if no step is currently selected
  if (!step) {
    return null;
  }

  if (step.status === StepStatus.Scheduled) {
    return (
      <HistoryElementsDiagram pad={{ horizontal: 'medium' }}>
        <SchedulingHistoryElement graphName="scheduling" step={step} />
      </HistoryElementsDiagram>
    );
  }

  if (step.status === StepStatus.WaitingForInput) {
    return (
      <HistoryElementsDiagram pad={{ horizontal: 'medium' }}>
        <WaitingForInputHistoryElement graphName="waitingInput" />
        <StartedHistoryElement graphName="started" step={step} />
      </HistoryElementsDiagram>
    );
  }

  if (step.status === StepStatus.Running) {
    return (
      <HistoryElementsDiagram pad={{ horizontal: 'medium' }}>
        <RunningHistoryElement graphName="running" />
        {statusMessages()}
        <StartedHistoryElement graphName="started" step={step} />
      </HistoryElementsDiagram>
    );
  }

  if (step.status === StepStatus.Failed) {
    return (
      <HistoryElementsDiagram pad={{ horizontal: 'medium' }}>
        <FailedHistoryElement graphName="failed" step={step} />
        {statusMessages()}
        <StartedHistoryElement graphName="started" step={step} />
      </HistoryElementsDiagram>
    );
  }

  if (step.status === StepStatus.Canceled) {
    return (
      <HistoryElementsDiagram pad={{ horizontal: 'medium' }}>
        <CanceledHistoryElement graphName="canceled" step={step} />
        {statusMessages()}
        <StartedHistoryElement graphName="started" step={step} />
      </HistoryElementsDiagram>
    );
  }

  if (step.status === StepStatus.Completed) {
    return (
      <HistoryElementsDiagram pad={{ horizontal: 'medium' }}>
        <CompletedHistoryElement graphName="completed" step={step} />
        {statusMessages()}
        <StartedHistoryElement graphName="started" step={step} />
      </HistoryElementsDiagram>
    );
  }

  if (step.status === StepStatus.Unknown) {
    return <>Unknown Status</>;
  }

  ensureUnreachable(step.status);

  return null;
};
