import React from 'react';
import { Box, BoxProps } from 'grommet';

/**
 * Renders the container that wraps notification subscriptions and the
 * add-notification-subscription action in the `NotificationSubscriptionSection`.
 */
export const NotificationSubscriptionContainer: React.FC<BoxProps> = props => {
  return <Box border={{ color: 'light-4' }} round="xxsmall" {...props} />;
};
