import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  AppLayout,
  PageContent,
  TextInputHeading,
  useTextInputHeading,
} from '@common/components';
import { urlFor } from '@common/utils';
import {
  WorkflowInstanceView,
  useWorkflowInstanceView,
} from '@features/workflows';
import {
  WorkflowInstanceViewDocument,
  useFindWorkspaceQuery,
  useRenameWorkflowInstanceMutation,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect, useRouteMatch } from 'react-router';
import { Subheading, Subtitle, Toolbar } from './components';

/**
 * Page Params for the WorkflowInstance Page
 */
export type WorkflowInstancePageRouteParams = {
  workspaceId: string;
  workflowInstanceId: string;
};

/**
 * Renders the page for a workflow instance.
 */
export const WorkflowInstancePage: React.FC = () => {
  const [t] = useTranslation('pages/WorkflowInstancePage');

  const match = useRouteMatch<WorkflowInstancePageRouteParams>();

  const { workspaceId, workflowInstanceId } = match.params;

  const { data: workspaceQueryData, loading: workspaceQueryLoading } =
    useFindWorkspaceQuery({
      variables: {
        id: workspaceId,
      },
    });

  const workspace = workspaceQueryData?.workspace;

  const {
    error,
    instance,
    loading: workflowInstanceViewLoading,
    props: workflowInstanceViewProps,
  } = useWorkflowInstanceView({
    workflowInstanceId,
  });

  const [renameWorkflowInstance, renameWorkflowInstanceMutation] =
    useRenameWorkflowInstanceMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(WorkflowInstanceViewDocument)!],
    });

  const { textInputHeadingProps } = useTextInputHeading({
    initialValue: instance?.displayName,
    onSave: async (value: string) => {
      if (!workspace?.id) {
        return;
      }
      if (!workflowInstanceId) {
        return;
      }

      await renameWorkflowInstance({
        variables: {
          id: workflowInstanceId,
          workspaceId: workspace.id,
          displayName: value,
        },
      });
    },
  });

  const loading = workflowInstanceViewLoading || workspaceQueryLoading;

  const layout = (children?: React.ReactNode) => (
    <AppLayout
      breadcrumbs={[
        {
          title: t('breadcrumbs.workspaces'),
          to: urlFor('workspaces')(),
        },
        {
          title: workspace?.displayName || '',
          to: urlFor('workspace')({ workspaceId }),
        },
        {
          title: t('breadcrumbs.runs'),
          to: urlFor('workspaceRuns')({ workspaceId }),
        },
      ]}
      subheading={
        <Subheading
          instance={instance}
          templateConfiguration={instance?.templateConfiguration}
          workspaceId={workspaceId}
        />
      }
      subtitle={
        <Subtitle
          instance={instance}
          templateConfiguration={instance?.templateConfiguration}
        />
      }
      title={
        (workspace && (
          <TextInputHeading
            {...textInputHeadingProps}
            disabled={renameWorkflowInstanceMutation.loading}
          />
        )) ||
        undefined
      }
      toolbar={
        <Toolbar
          instance={instance}
          templateConfiguration={instance?.templateConfiguration}
          workflowInstanceId={workflowInstanceId}
          workspaceId={workspaceId}
        />
      }
    >
      <PageContent
        children={children}
        empty={{
          body: t('empty.body', { workflowInstanceId }),
          empty: !instance,
          heading: t('empty.heading'),
        }}
        error={error}
        loading={loading}
        loadingLabel={t('loading')}
      />
    </AppLayout>
  );

  // Display the error state if needed
  if (error) {
    return layout(
      <Box>
        {t('error')}: {error.message}
      </Box>
    );
  }

  // Display the loading state if needed
  if (loading) {
    return layout();
  }

  // Handle missing workspace
  if (!workspace) {
    return <Redirect to={urlFor('workspaces')()} />;
  }

  // Handle missing instance
  if (!instance) {
    return layout();
  }

  return layout(<WorkflowInstanceView {...workflowInstanceViewProps} />);
};
