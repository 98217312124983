import { useState } from 'react';
import { MutationHookOptions } from '@apollo/client';
import {
  Dashboard,
  DeleteDashboardModalSubmitMutation,
  useDeleteDashboardModalQuery,
  useDeleteDashboardModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { DeleteDashboardModalProps } from './DeleteDashboardModal';

type DeleteDashboardMutationHookOptions =
  MutationHookOptions<DeleteDashboardModalSubmitMutation>;

type DashboardParam = Pick<Dashboard, 'id' | 'workspaceId'>;

export type UseDeleteDashboardModalOptions = {
  deleteMutationRefetchQueries?: DeleteDashboardMutationHookOptions['refetchQueries'];
  onCompleted?: (dashboard: DashboardParam) => void;
};

export type UseDeleteDashboardModalResult = {
  open: (dashboard: Pick<Dashboard, 'id'>) => void;
  props: DeleteDashboardModalProps;
};

/**
 * Hook providing state and operations for the `DeleteDashboardModal`.
 */
export const useDeleteDashboardModal = ({
  deleteMutationRefetchQueries,
  onCompleted,
}: UseDeleteDashboardModalOptions = {}): UseDeleteDashboardModalResult => {
  const [dashboardId, setDashboardId] = useState<string>();

  const { data, ...query } = useDeleteDashboardModalQuery({
    skip: !dashboardId,
    variables: {
      // Ignore potentially undefined dashboardId since the query will be skipped if dashboardId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dashboardId: dashboardId!,
    },
  });

  const [deleteDashboard, { error }] = useDeleteDashboardModalSubmitMutation({
    awaitRefetchQueries: true,
    refetchQueries: deleteMutationRefetchQueries,
    onCompleted: result => {
      if (result.deleteDashboard) {
        onCompleted?.(result.deleteDashboard);
      }
    },
  });

  const loading = !data && query.loading;

  const dashboard = data?.dashboard;
  const workspaceId = dashboard?.workspaceId;

  const onConfirm = async () => {
    if (!dashboard?.id || !workspaceId) {
      return;
    }

    const response = await deleteDashboard({
      awaitRefetchQueries: true,
      variables: {
        dashboardId: dashboard.id,
        workspaceId,
      },
    });

    // Close the modal after deleting
    if (response.data && !response.errors?.length) {
      setDashboardId(undefined);
    }
  };

  const onClose = () => setDashboardId(undefined);

  return {
    open: ({ id }) => setDashboardId(id),
    props: {
      dashboard: dashboard ?? undefined,
      error,
      loading,
      onClose,
      onConfirm,
      open: !!dashboardId,
    },
  };
};
