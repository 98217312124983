import React, { useMemo } from 'react';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { PropsOf } from '@voleer/types';
import { Select, SelectProps } from 'grommet';
import { useTranslation } from 'react-i18next';
import { ScheduleType } from '../../interface';

type Option = {
  id: ScheduleType;
  label: string;
};

/**
 * Prop types for the `ScheduleTypeSelect` component.
 */
export type ScheduleTypeSelectProps = Omit<
  PropsOf<typeof Select>,
  'labelKey' | 'options' | 'value' | 'valueKey'
> & {
  value?: string;
};

/**
 * Renders a select box for selecting a schedule type.
 */
export const ScheduleTypeSelect: React.FC<ScheduleTypeSelectProps> = ({
  value: originalValue,
  onChange: originalOnChange,
  ...props
}) => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const options = useMemo<Option[]>(() => {
    return [
      {
        id: ScheduleType.months,
        label: t('fields.schedule-type.label-monthly'),
      },
      {
        id: ScheduleType.weeks,
        label: t('fields.schedule-type.label-weekly'),
      },
      {
        id: ScheduleType.days,
        label: t('fields.schedule-type.label-daily'),
      },
      {
        id: ScheduleType.hours,
        label: t('fields.schedule-type.label-hourly'),
      },
      {
        id: ScheduleType.minutes,
        label: t('fields.schedule-type.label-minutes'),
      },
    ];
  }, [t]);

  const value = useMemo(
    () => options.find(option => option.id === originalValue),
    [options, originalValue]
  );

  const onChange = (event: GrommetSelectEvent<Option>) => {
    // Formik expects event.target.value to contain the new value but Grommet
    // puts it in event.value
    event.target.value = event.value?.id || '';
    return originalOnChange?.(event);
  };

  return (
    <Select
      {...(props as SelectProps)}
      labelKey="label"
      onChange={onChange}
      options={options}
      value={value}
      valueKey="id"
    />
  );
};
