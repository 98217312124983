import React from 'react';
import { useFormikContext } from 'formik';
import { FormField } from 'grommet';
import { useTranslation } from 'react-i18next';
import { DateInput, FormLabel } from '../../components';
import { ScheduledJobFormValues } from '../../interface';

/**
 * Renders a form field for inputting a start date.
 */
export const StartDateField = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const formik = useFormikContext<ScheduledJobFormValues>();

  return (
    <>
      <FormLabel>{t('fields.start-date.label')}</FormLabel>
      <FormField error={formik.getFieldMeta('startDate').error} margin="none">
        <DateInput {...formik.getFieldProps('startDate')} />
      </FormField>
    </>
  );
};
