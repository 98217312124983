import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { LoadingOverlay } from '@common/components';
import {
  IntegrationsPageConfiguredTabDocument,
  useDeleteIntegrationModalDeleteMutation,
  useDeleteIntegrationModalQuery,
} from '@generated/graphql-code-generator';
import { Alert, ErrorButton, Modal } from '@voleer/ui-kit';
import { Button, Paragraph, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';

export type DeleteIntegrationModalProps = {
  integrationId: string;
  onClose: () => void;
  onCompleted?: (integrationId: string) => void;
};

/**
 * Renders the modal to delete an Integration.
 */
export const DeleteIntegrationModal: React.FC<DeleteIntegrationModalProps> = ({
  integrationId,
  onClose,
  onCompleted,
}) => {
  const [t] = useTranslation(
    'features/integrations/components/DeleteIntegrationModal'
  );

  const { data, ...query } = useDeleteIntegrationModalQuery({
    skip: !integrationId,
    variables: {
      // Query will be skipped if integrationId is not present
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      integrationId: integrationId!,
    },
  });

  const [deleteIntegration, deleteMutation] =
    useDeleteIntegrationModalDeleteMutation({
      awaitRefetchQueries: true,
      onCompleted: result => {
        if (!result) {
          // Could happen in the case of an error
          return;
        }
        const integrationId = result.deleteIntegration;
        onClose();
        onCompleted?.(integrationId);
      },
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getOperationName(IntegrationsPageConfiguredTabDocument)!,
      ],
    });

  const onDelete = async () => {
    if (!integrationId) {
      return;
    }

    await deleteIntegration({
      variables: { integrationId },
    });
  };

  const loading = !data && query.loading;

  const integration = data?.integration;

  return (
    <Modal
      onClickOutside={onClose}
      onEsc={onClose}
      onPressEnter={onDelete}
      open={!!integrationId}
      width={{ max: 'large' }}
    >
      <LoadingOverlay loading={loading}>
        <Modal.Header>{t('heading')}</Modal.Header>
        <Modal.Body margin={{ vertical: 'medium' }}>
          {deleteMutation.error && (
            <Alert status="error">{deleteMutation.error?.message}</Alert>
          )}
          <Paragraph>
            <Trans
              components={{
                'integration-name': <Text weight="bold" />,
              }}
              i18nKey="are-you-sure"
              t={t}
              values={{
                integrationName: integration?.displayName,
              }}
            />
          </Paragraph>
        </Modal.Body>
        <Modal.Footer>
          <Button label={t('buttons.close.label')} onClick={onClose} />
          <ErrorButton
            data-testid="delete-integration-modal__confirm-button"
            label={t('buttons.submit.label')}
            loading={deleteMutation.loading}
            onClick={onDelete}
          />
        </Modal.Footer>
      </LoadingOverlay>
    </Modal>
  );
};
