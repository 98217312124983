import React from 'react';
import { useFormikContext } from 'formik';
import { FormField } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  DayOfWeekSelect,
  FormLabel,
  ScheduleTypeSelect,
  TimeInput,
} from '../../components';
import { ScheduledJobFormValues } from '../../interface';

/**
 * Renders the "weekly" schedule form fields.
 */
export const WeeklyFields: React.FC = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const formik = useFormikContext<ScheduledJobFormValues>();

  const getField = (key: keyof ScheduledJobFormValues) => ({
    props: formik.getFieldProps(key),
    meta: formik.getFieldMeta(key),
    helpers: formik.getFieldHelpers(key),
  });

  return (
    <>
      <FormLabel>{t('fields.schedule-type.label')}</FormLabel>
      <ScheduleTypeSelect {...getField('type').props} />

      <FormLabel>{t('fields.day-of-week.label')}</FormLabel>
      <FormField error={getField('daysOfWeek').meta.error} margin="none">
        <DayOfWeekSelect
          {...getField('daysOfWeek').props}
          onChange={value => formik.setFieldValue('daysOfWeek', value)}
        />
      </FormField>

      <FormLabel>{t('fields.at-time.label')}</FormLabel>
      <FormField error={getField('atTime').meta.error} margin="none">
        <TimeInput {...getField('atTime').props} />
      </FormField>
    </>
  );
};
