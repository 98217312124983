import React from 'react';
import { useDateFormat } from '@common/hooks';
import { formatDuration, tryParseISO } from '@common/utils';
import {
  InstanceStatus,
  TemplateConfigurationExposureLevel,
  useWorkflowInstanceViewQuery,
} from '@generated/graphql-code-generator';
import { Icon } from '@voleer/ui-kit';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaFlagCheckered } from 'react-icons/fa';
import { StepLink } from '..';
import { useStepList } from '../../use-step-list';

/**
 * Props for the `StepListItemSummary` component.
 */
export type StepListItemSummaryProps = {
  instanceId: string;
};

/**
 * Renders the final step in the step list. This step is "virtual"
 * in the sense that it is an item in the step list that is not represented by
 * an actual workflow step record, and is only presented as a navigation item
 * allowing the user to view the final summary item.
 */
export const StepListItemSummary: React.FC<StepListItemSummaryProps> = ({
  instanceId,
}) => {
  const [format, { DateFormat }] = useDateFormat();
  const formatDate = (date: Date) => format(date, DateFormat.Long);

  const [t] = useTranslation(
    'features/workflows/components/WorkflowInstanceView'
  );

  const { data, ...query } = useWorkflowInstanceViewQuery({
    variables: { instanceId },
  });

  const { selectedStep } = useStepList({ instanceId });

  const loading = !data && query.loading;

  const instance = data?.instance;

  // Loading and error states are handled in WorkflowInstanceView
  if (loading || !instance) {
    return null;
  }

  const templateConfiguration = instance.templateConfiguration;
  const configurationInstance = templateConfiguration?.configurationInstance;

  // The summary step should be active when no other step has been selected
  const active = !selectedStep;

  let statusColor: string;
  let summaryText: string;

  if (instance.status === InstanceStatus.Completed) {
    statusColor = 'status-ok';
    summaryText = t('step-list.completed');
  } else if (instance.status === InstanceStatus.Canceled) {
    statusColor = 'status-warning';
    summaryText = t('step-list.canceled');
  } else if (instance.status === InstanceStatus.Cancelling) {
    statusColor = 'status-warning';
    summaryText = t('step-list.cancelling');
  } else if (instance.status === InstanceStatus.Failed) {
    statusColor = 'status-error';
    summaryText = t('step-list.failed');
  } else if (instance.status === InstanceStatus.TimedOut) {
    statusColor = 'status-error';
    summaryText = t('step-list.timed-out');
  } else {
    // Don't render anything if status is not supported
    return null;
  }

  const startedOn = tryParseISO(
    templateConfiguration?.exposureLevel ===
      TemplateConfigurationExposureLevel.OnDemand
      ? configurationInstance?.startedOn ?? instance.startedOn
      : instance.startedOn
  );

  const completedOn = tryParseISO(instance.completedOn);

  return (
    <StepLink
      data-testid="workflow-instance-view__step-list-item-summary"
      stepId={undefined}
      variation="unstyled"
    >
      <Box
        align="center"
        background={active ? 'white' : ''}
        direction="row"
        flex={{ shrink: 0 }}
        height="70px"
        hoverIndicator="light-5"
        onClick={() => null}
        pad={{ vertical: 'small', left: 'medium' }}
      >
        <Box pad={{ vertical: '15px' }}>
          <Icon
            color={statusColor}
            data-testid="workflow-instance-view__step-list-item-summary--icon"
            icon={FaFlagCheckered}
          />
        </Box>
        <Box
          data-testid="workflow-instance-view__step-list-item-summary--text"
          direction="column"
          pad={{ left: 'small' }}
        >
          <Text>{summaryText}</Text>
          {completedOn && (
            <Text
              color="dark-2"
              data-testid="workflow-instance-view__step-list-item-summary--duration"
              size="small"
            >
              {formatDate(completedOn)}
              {startedOn && (
                <>
                  &nbsp;&#8226;&nbsp;
                  {formatDuration(startedOn, completedOn)}
                </>
              )}
            </Text>
          )}
        </Box>
      </Box>
    </StepLink>
  );
};
