import React from 'react';
import { LoadingOverlay } from '@common/components';
import { useDateFormat } from '@common/hooks';
import { urlFor } from '@common/utils';
import {
  Instance,
  Maybe,
  ScheduledJob,
} from '@generated/graphql-code-generator';
import { Alert, ErrorButton, Link, Modal } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  FormField,
  Text,
  TextInput,
} from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ScheduledJobSchedule } from '../../../scheduled-jobs';

const List = styled.ul`
  margin: 0;
`;

const AccordionPanelExpandedContainer: React.FC = ({ children }) => (
  <Box height={{ max: '150px' }} overflow={{ vertical: 'auto' }}>
    {children}
  </Box>
);

/**
 * Props for the DeleteTemplateConfigurationModal component
 */
export type DeleteTemplateConfigurationModalProps = {
  /**
   * The value for the confirmation text input.
   */
  confirmationText: string;

  /**
   * Error to display in the modal.
   */
  error?: string;

  /**
   * The list of the template configuration's active runs.
   */
  instances: (Pick<Instance, 'id' | 'updatedOn'> | null)[] | null | undefined;

  /**
   * Flag for if the modal should be in the loading state.
   */
  loading: boolean;

  /**
   * The name of the template configuration
   */
  name?: string | null | undefined;

  /**
   * Handler for when the user clicks cancel.
   */
  onCancel: () => void;

  /**
   * Handler for changes in the confirmation text input.
   */
  onChangeConfirmationText: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Handler for when user confirms.
   */
  onConfirm: () => void;

  open: boolean;

  /**
   * The list of the template configuration's scheduled jobs.
   */
  scheduledJobs:
    | Maybe<Pick<ScheduledJob, 'id' | 'pattern'>>[]
    | null
    | undefined;

  /**
   * Flag for if the delete action is submitting.
   */
  submitting: boolean;

  workspaceId: string;
};

/**
 * Modal to confirm deletion of a template configuration
 */
export const DeleteTemplateConfigurationModal: React.FC<DeleteTemplateConfigurationModalProps> =
  ({
    confirmationText,
    submitting,
    error,
    instances,
    loading,
    name,
    onCancel,
    onChangeConfirmationText,
    onConfirm,
    open,
    scheduledJobs,
    workspaceId,
  }) => {
    const [t] = useTranslation(
      'features/workflows/components/DeleteTemplateConfigurationModal'
    );

    const [formatDate, { DateTimeFormat }] = useDateFormat();

    return (
      <Modal
        onClickOutside={onCancel}
        onEsc={onCancel}
        onPressEnter={onConfirm}
        open={open}
      >
        <Modal.Header>{t('title')}</Modal.Header>
        <Modal.Body>
          <LoadingOverlay loading={loading}>
            {error && (
              <Alert
                data-testid="delete-template-configuration-modal__error"
                icon={true}
                margin={{ bottom: 'medium' }}
                status="error"
              >
                {error}
              </Alert>
            )}

            <Text margin={{ bottom: 'medium', top: 'small' }} size="large">
              <Trans
                i18nKey="are-you-sure"
                ns="features/workflows/components/DeleteTemplateConfigurationModal"
              >
                <>Are you sure you want to permanently delete</>
                <strong> {{ name }}</strong>
                <>?</>
              </Trans>
            </Text>

            {(!!instances?.length || !!scheduledJobs?.length) && (
              <Text margin={{ bottom: 'large' }}>
                {t('deleting-this-will')}

                <Accordion multiple={true} pad={{ left: 'small' }}>
                  {!!instances?.length && (
                    <AccordionPanel
                      data-testid="delete-template-configuration-modal__active-runs"
                      label={
                        <Text margin={{ vertical: 'small' }}>
                          {t(
                            instances.length > 1
                              ? 'cancel-runs-in-progress-plural'
                              : 'cancel-runs-in-progress',
                            {
                              count: instances.length,
                            }
                          )}
                        </Text>
                      }
                    >
                      <AccordionPanelExpandedContainer>
                        <List>
                          {instances.map(instance => {
                            if (!instance) return null;

                            return (
                              <li key={instance.id}>
                                <Link
                                  to={urlFor('workflowInstance')({
                                    workflowInstanceId: instance.id,
                                    workspaceId,
                                  })}
                                  variation="primary"
                                >
                                  {formatDate(
                                    parseISO(instance.updatedOn),
                                    DateTimeFormat.Abbreviated
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </List>
                      </AccordionPanelExpandedContainer>
                    </AccordionPanel>
                  )}

                  {!!scheduledJobs?.length && (
                    <AccordionPanel
                      data-testid="delete-template-configuration-modal__scheduled-jobs"
                      label={
                        <Text margin={{ vertical: 'small' }}>
                          {t(
                            scheduledJobs.length > 1
                              ? 'scheduled-jobs-active-plural'
                              : 'scheduled-jobs-active',
                            {
                              count: scheduledJobs.length,
                            }
                          )}
                        </Text>
                      }
                    >
                      <AccordionPanelExpandedContainer>
                        <List>
                          {scheduledJobs.map(scheduledJob => {
                            if (!scheduledJob) return null;

                            return (
                              <li key={scheduledJob.id}>
                                <Box
                                  margin={{ bottom: 'xsmall' }}
                                  width="fit-content"
                                >
                                  <Link
                                    to={urlFor('scheduledJob')({
                                      scheduledJobId: scheduledJob.id,
                                      workspaceId,
                                    })}
                                    variation="unstyled"
                                  >
                                    <ScheduledJobSchedule
                                      scheduledJob={scheduledJob}
                                    />
                                  </Link>
                                </Box>
                              </li>
                            );
                          })}
                        </List>
                      </AccordionPanelExpandedContainer>
                    </AccordionPanel>
                  )}
                </Accordion>
              </Text>
            )}

            <Box>
              <FormField
                label={<Text size="small">{t('confirmation-label')}</Text>}
                margin={{ bottom: '0' }}
              >
                <TextInput
                  autoFocus={true}
                  data-testid="delete-template-configuration-modal__input--confirm"
                  disabled={submitting}
                  name="delete-template-configuration-modal__input--confirm"
                  onChange={onChangeConfirmationText}
                  value={confirmationText}
                />
              </FormField>
            </Box>
          </LoadingOverlay>
        </Modal.Body>

        <Modal.Footer>
          <Button
            data-testid="delete-template-configuration-modal__button--cancel"
            disabled={loading}
            label={t('cancel-button')}
            onClick={onCancel}
          />
          <ErrorButton
            data-testid="delete-template-configuration-modal__button--confirm"
            disabled={loading || confirmationText !== t('confirmation-key')}
            label={t('delete-button')}
            loading={submitting}
            onClick={onConfirm}
          />
        </Modal.Footer>
      </Modal>
    );
  };
