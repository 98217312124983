import React from 'react';
import { Box } from 'grommet';

/**
 * Styling for "label" content in the form grid.
 */
export const FormLabel: React.FC = ({ children }) => (
  <Box align="start" direction="row" justify="end" pad={{ vertical: 'xsmall' }}>
    {children}
  </Box>
);
