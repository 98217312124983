/**
 * Returns the tz database string representing the user's current timezone.
 *
 * For the full list of timezone strings see: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export const detectCurrentTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // The browser may return 'UTC' which is an alias for 'Etc/UTC'. Not
  // everything supports aliases so we normalize it here.
  return timezone === 'UTC' ? 'Etc/UTC' : timezone;
};
