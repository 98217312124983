import React, { useMemo } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useDateFormat, useFuseState, useLoadMoreQuery } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  NotificationRecipientStatus,
  NotificationsDropButtonDocument,
  NotificationsHistoryTabNotificationRecipientFragment,
  useNotificationsHistoryTabQuery,
  useNotificationsHistoryTabToggleNotificationStatusMutation,
} from '@generated/graphql-code-generator';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  Icon,
  Link,
  RotatingIcon,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Heading, InfiniteScroll, Paragraph, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';

/**
 * Renders the settings tab of the Notifications page.
 */
export const NotificationsHistoryTab: React.FC = () => {
  const [format, { DateTimeFormat }] = useDateFormat();

  const [t] = useTranslation('pages/NotificationsPage');

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const { data, fetchMore, loading } = useLoadMoreQuery(
    useNotificationsHistoryTabQuery,
    {
      fetchPolicy: 'cache-and-network',
      getPageInfo: data => data?.notificationRecipients?.pageInfo,
      pollInterval: pollingConfig?.notificationsPage,
      variables: {
        first: 1000,
      },
    }
  );
  const notificationRecipientsPageInfo = data?.notificationRecipients?.pageInfo;
  const loadMoreNotificationRecipients = useMemo(() => {
    if (!notificationRecipientsPageInfo?.hasNextPage) {
      return undefined;
    }

    return async () => {
      await fetchMore();
    };
  }, [fetchMore, notificationRecipientsPageInfo]);

  const [toggleNotificationStatus] =
    useNotificationsHistoryTabToggleNotificationStatusMutation({
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getOperationName(NotificationsDropButtonDocument)!,
      ],
    });
  const updateNotificationStatus = async (
    id: string,
    status: NotificationRecipientStatus
  ) => {
    await toggleNotificationStatus({
      variables: {
        input: {
          id,
          data: {
            status:
              status === NotificationRecipientStatus.Unread
                ? NotificationRecipientStatus.Read
                : NotificationRecipientStatus.Unread,
          },
        },
      },
    });
  };

  type SearchNotificationRecipient =
    NotificationsHistoryTabNotificationRecipientFragment & {
      instanceDisplayName: string;
      subject: string;
      workspaceDisplayName: string;
    };
  const notificationRecipients = useMemo(() => {
    const notificationRecipientsEdges =
      data?.notificationRecipients?.edges ?? [];
    return notificationRecipientsEdges.reduce((acc, edge) => {
      if (!edge || !edge.node) {
        return acc;
      }

      // Augment data with properties that can be used by search
      acc.push({
        ...edge.node,
        createdOn: format(
          parseISO(edge.node.createdOn),
          DateTimeFormat.AbbreviatedHumanized
        ),
        instanceDisplayName:
          edge.node.notification?.instance?.displayName ?? '-',
        subject: edge.node.notification.subject,
        workspaceDisplayName:
          edge.node.notification?.workspace?.displayName ?? '-',
      });

      return acc;
    }, [] as SearchNotificationRecipient[]);
  }, [DateTimeFormat, data, format]);
  const {
    searchTerm,
    setSearchTerm,
    searchResults: recipients,
  } = useFuseState({
    fuseOptions: {
      keys: [
        'createdOn',
        'instanceDisplayName',
        'subject',
        'workspaceDisplayName',
      ],
      matchAllTokens: true,
      threshold: 0.3,
      tokenize: true,
    },
    items: notificationRecipients,
  });

  return (
    <Box fill={true} pad="medium">
      <FancyTable
        columns={[
          { width: '30%' },
          undefined,
          undefined,
          undefined,
          { justify: 'center', width: '50px' },
        ]}
        fill="horizontal"
        loading={!data && loading}
        paper={true}
        rows={{ count: recipients.length }}
      >
        <FancyTable.Toolbar>
          <FancyTable.Search
            onChange={event => {
              setSearchTerm(event.target.value);
            }}
            placeholder={t('history-tab.search.input.placeholder')}
            value={searchTerm}
          />
        </FancyTable.Toolbar>

        <FancyTable.Header>
          <FancyTable.Row>
            <FancyTable.HeaderCell>
              {t('history-tab.table-headers.subject')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('history-tab.table-headers.created-on')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('history-tab.table-headers.workspace')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('history-tab.table-headers.instance')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('history-tab.table-headers.actions')}
            </FancyTable.HeaderCell>
          </FancyTable.Row>
        </FancyTable.Header>

        <FancyTable.Body>
          {!recipients.length && (
            <FancyTable.Empty
              data-testid="notifications-history-tab__empty-table"
              margin={{ vertical: 'xlarge' }}
            >
              <Heading
                level="3"
                margin={{ bottom: 'small', top: '0' }}
                textAlign="center"
              >
                {searchTerm
                  ? t('history-tab.search.no-results.heading')
                  : t('history-tab.no-history.heading')}
              </Heading>
              <Paragraph margin="0" textAlign="center">
                {searchTerm ? (
                  t('history-tab.search.no-results.help')
                ) : (
                  <Trans
                    components={{
                      'settings-link': (
                        <Link
                          data-testid="notifications-history-tab__settings-link"
                          to={urlFor('notificationsSettings')()}
                          variation="primary"
                        />
                      ),
                    }}
                    i18nKey="history-tab.no-history.help"
                    t={t}
                  />
                )}
              </Paragraph>
            </FancyTable.Empty>
          )}

          <InfiniteScroll
            items={recipients}
            onMore={loadMoreNotificationRecipients}
            renderMarker={() => {
              return (
                <Box
                  align="center"
                  direction="row"
                  justify="center"
                  pad={{ bottom: 'large', top: 'medium' }}
                >
                  <RotatingIcon icon={FaSpinner} size="14px" />
                  <Text margin={{ left: 'small' }}>
                    {t('history-tab.loading-more')}
                  </Text>
                </Box>
              );
            }}
          >
            {(recipient: SearchNotificationRecipient) => {
              const instanceId = recipient?.notification?.instance?.id;
              const workspaceId = recipient?.notification?.workspace?.id;
              const linkDisabled = !instanceId || !workspaceId;

              return (
                <FancyTable.Row key={recipient.id}>
                  <FancyTable.Cell data-testid="notifications-history-tab__table-cell--subject">
                    <Text
                      title={recipient.subject}
                      truncate={true}
                      weight={
                        recipient.status === NotificationRecipientStatus.Unread
                          ? 'bold'
                          : 'normal'
                      }
                    >
                      {recipient.subject}
                    </Text>
                  </FancyTable.Cell>

                  <FancyTable.Cell data-testid="notifications-history-tab__table-cell--created-on">
                    <Text title={recipient.createdOn} truncate={true}>
                      {recipient.createdOn}
                    </Text>
                  </FancyTable.Cell>

                  <FancyTable.Cell data-testid="notifications-history-tab__table-cell--workspace-name">
                    <Text
                      title={recipient.workspaceDisplayName}
                      truncate={true}
                    >
                      {recipient.workspaceDisplayName}
                    </Text>
                  </FancyTable.Cell>

                  <FancyTable.Cell data-testid="notifications-history-tab__table-cell--template-configuration-name">
                    <Text title={recipient.instanceDisplayName} truncate={true}>
                      {recipient.instanceDisplayName}
                    </Text>
                  </FancyTable.Cell>

                  <FancyTable.Cell>
                    <DropButton
                      data-testid="notifications-history-tab__actions-drop-button"
                      icon={<Icon icon={MdMoreHoriz} />}
                      title={t('history-tab.table-headers.actions')}
                      variation="ghost"
                    >
                      <Link
                        data-testid="notifications-history-tab__action--view"
                        disabled={!instanceId || !workspaceId}
                        onClick={() => {
                          if (
                            recipient.status ===
                            NotificationRecipientStatus.Unread
                          ) {
                            // Mark notification as read when the user views the instance
                            updateNotificationStatus(
                              recipient.id,
                              recipient.status
                            );
                          }
                        }}
                        to={
                          linkDisabled
                            ? urlFor('notificationsHistory')()
                            : urlFor('workflowInstance')({
                                workflowInstanceId: instanceId,
                                workspaceId,
                              })
                        }
                        variation="primary"
                      >
                        <DropButtonItem
                          disabled={linkDisabled}
                          label={t('history-tab.actions.view')}
                        />
                      </Link>

                      <DropButtonItem
                        data-testid={
                          recipient.status ===
                          NotificationRecipientStatus.Unread
                            ? 'notifications-history-tab__action--mark-read'
                            : 'notifications-history-tab__action--mark-unread'
                        }
                        label={
                          recipient.status ===
                          NotificationRecipientStatus.Unread
                            ? t('history-tab.actions.mark-read')
                            : t('history-tab.actions.mark-unread')
                        }
                        onClick={() => {
                          updateNotificationStatus(
                            recipient.id,
                            recipient.status
                          );
                        }}
                      />
                    </DropButton>
                  </FancyTable.Cell>
                </FancyTable.Row>
              );
            }}
          </InfiniteScroll>
        </FancyTable.Body>
      </FancyTable>
    </Box>
  );
};
