import React from 'react';
import { Icon } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { IconType } from 'react-icons/lib/cjs';
import styled, { keyframes } from 'styled-components';

type DotProps = {
  id: string;
  color?: string;
};

const pulse = keyframes`
  0%   { opacity: 0; transform: scale(0.75); }
  20%  { opacity: 1; transform: scale(1.25); }
  40%  { opacity: 0; transform: scale(0.75); }
  60%  { opacity: 1; transform: scale(1.25); }
  100% { opacity: 1; transform: scale(1.25); }
`;

/**
 * Renders a HistoryElement "dot".
 */
export const Dot: React.FC<DotProps> = ({ id, color }) => (
  <Box
    background={color || 'dark-2'}
    height="5px"
    id={id}
    margin="xsmall"
    pad="xxsmall"
    round="small"
    width="5px"
  />
);

type DotIconProps = DotProps & {
  icon: IconType;
};

/**
 * Renders a HistoryElement "dot" as an icon.
 */
export const DotIcon: React.FC<DotIconProps> = ({ id, color, icon }) => {
  return (
    <Box background="white" id={id} round={true}>
      <Icon color={color} icon={icon} size="medium" />
    </Box>
  );
};

/**
 * Renders a blinking dot
 */
const BlinkingDot = styled(props => (
  <Box
    background="accent-1"
    height="5px"
    pad="xxsmall"
    round="small"
    width="5px"
    {...props}
  />
))`
  animation: ${pulse} 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

/**
 * Renders a HistoryElement "dot" in active state.
 */
export const ActiveDot = ({ id }: DotProps) => (
  <div
    id={id}
    style={{
      background: '#D9DFF8',
      height: '6px',
      width: '6px',
      margin: '6px',
      borderRadius: '50%',
    }}
  >
    <BlinkingDot />
  </div>
);
