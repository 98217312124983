import React from 'react';
import { useTypedFlags } from '@features/launch-darkly';
import { PropsOf } from '@voleer/types';
import { Box, Heading, Paragraph } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled, { css } from 'styled-components';
import {
  Breadcrumbs,
  BreadcrumbsProps,
  LeftNavigation,
  NotificationsDropButton,
  TrialAlert,
} from './components';

const Main: React.FC<PropsOf<typeof Box>> = props => (
  <Box
    flex={{ grow: 1, shrink: 1 }}
    overflow={{ vertical: 'auto' }}
    style={{
      position: 'relative',
      zIndex: 0,
    }}
    {...props}
  />
);

/**
 * Box for styling the subtitle section.
 */
const SubtitleBox = styled(Box)`
  ${props => {
    const color = normalizeColor('dark-2', props.theme);
    const sizeData = props.theme.text['small'];
    return css`
      color: ${color};
      font-size: ${sizeData.size};
    `;
  }}
`;

type AppLayoutSubheadingProps = PropsOf<typeof Box>;

/**
 * Container for subheading content.
 */
const AppLayoutSubheading: React.FC<AppLayoutSubheadingProps> = props => {
  return (
    <Box
      background="neutral-2"
      data-testid="app-layout__subheading"
      direction="row"
      flex={{ shrink: 0 }}
      pad={{ horizontal: 'medium', vertical: 'small' }}
      {...props}
    />
  );
};

type AppLayoutToolbarProps = PropsOf<typeof Box>;

/**
 * Container for toolbar content.
 */
const AppLayoutToolbar: React.FC<AppLayoutToolbarProps> = props => {
  return (
    <Box
      data-testid="app-layout__toolbar"
      direction="column"
      flex={{ shrink: 0 }}
      gap="small"
      {...props}
    />
  );
};

type AppLayoutStaticProps = {
  /**
   * Wrapper for optional subheading content.
   */
  Subheading: typeof AppLayoutSubheading;

  /**
   * Wrapper for optional toolbar content.
   */
  Toolbar: typeof AppLayoutToolbar;
};

export type AppLayoutProps = {
  /**
   * The title to display in the heading.
   */
  title?: React.ReactElement | string;

  /**
   * The subtitle to display in the heading.
   */
  subtitle?: React.ReactNode;

  /**
   * The description to display in the heading.
   */
  description?: string;

  /**
   * Breadcrumbs to display in the heading.
   */
  breadcrumbs?: BreadcrumbsProps['breadcrumbs'];

  /**
   * Optional "toolbar" content for buttons, links, etc.
   */
  toolbar?: React.ReactNode;

  /**
   * Optional "subheading" content for other information.
   */
  subheading?: React.ReactNode;
};

const BREADCRUMBS_HEIGHT = '48px';

/**
 * Tenant UI application layout.
 */
export const AppLayout: AppLayoutStaticProps & React.FC<AppLayoutProps> = ({
  children,
  title,
  toolbar,
  subtitle,
  subheading,
  breadcrumbs,
  description,
}) => {
  const { notifications } = useTypedFlags();

  return (
    <Box height="100vh">
      <Box direction="row" fill={true}>
        <LeftNavigation />

        <Box fill={true}>
          <Main>
            <TrialAlert />

            <Box
              align="start"
              direction="row"
              flex={{ shrink: 0 }}
              gap="medium"
              justify="between"
              pad={{ top: 'medium', bottom: 'small', horizontal: 'medium' }}
            >
              <Box>
                {!!breadcrumbs && (
                  <Box flex={{ grow: 1 }} height={BREADCRUMBS_HEIGHT}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                  </Box>
                )}

                {typeof title === 'string' && (
                  <Box align="start" data-testid="app-layout__title--string">
                    <Heading
                      color="neutral-3"
                      level="2"
                      size="medium"
                      title={title}
                      truncate={true}
                    >
                      {title}
                    </Heading>
                  </Box>
                )}

                {React.isValidElement(title) && (
                  <Box data-testid="app-layout__title--component">{title}</Box>
                )}

                {!!subtitle && (
                  <SubtitleBox
                    data-testid="app-layout__subtitle"
                    pad={{ top: 'xsmall' }}
                  >
                    {subtitle}
                  </SubtitleBox>
                )}

                {!!description && (
                  <Paragraph data-testid="app-layout__description">
                    {description}
                  </Paragraph>
                )}
              </Box>

              <Box flex={{ shrink: 0 }}>
                {(!!notifications || !!breadcrumbs) && (
                  <Box
                    align="end"
                    flex={{ shrink: 0 }}
                    height={BREADCRUMBS_HEIGHT}
                  >
                    {notifications && <NotificationsDropButton />}
                  </Box>
                )}

                {!!toolbar && (
                  <Box align="end" flex={{ shrink: 0 }} gap="medium">
                    {toolbar}
                  </Box>
                )}
              </Box>
            </Box>

            {subheading}

            {children}
          </Main>
        </Box>
      </Box>
    </Box>
  );
};

AppLayout.Subheading = AppLayoutSubheading;
AppLayout.Toolbar = AppLayoutToolbar;
