import React from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert, LoadingButton, Modal, ModalProps } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormField, Text, TextInput } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type RenameFormValues = {
  name: string;
};

/**
 * Prop types for the `RenameModal` component.
 */
export type RenameModalProps = Readonly<
  Pick<ModalProps, 'open'> & {
    /**
     * Possible error message to display in the modal.
     */
    error: string | undefined;

    /**
     * The current name of the entity to rename.
     */
    name: string;

    /**
     * Callback for closing the modal.
     */
    onClose: () => void;

    /**
     * Callback for when the rename form is submitted.
     */
    onSubmit: FormikSubmitFn<RenameFormValues>;

    /**
     * The type display name of the entity (i.e. "Dataset").
     */
    typeName: string;
  }
>;

/**
 * Renders a modal for renaming an entity.
 */
export const RenameModal: React.FC<RenameModalProps> = ({
  error,
  name,
  onClose,
  onSubmit,
  open,
  typeName,
}) => {
  const [t] = useTranslation('features/workflows/components/RenameModal');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required', { typeName })),
  });

  const initialValues: RenameFormValues = {
    name,
  };

  return (
    <Modal onClickOutside={onClose} onEsc={onClose} open={open}>
      <Modal.Header>{t('title')}</Modal.Header>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValidating }) => {
          const formProcessing = isSubmitting || isValidating;

          return (
            <Form>
              <Modal.Body>
                <Text margin={{ bottom: 'medium' }}>
                  <Trans i18nKey="instructions" t={t}>
                    <>Rename the</>
                    <strong> {{ name }} </strong>
                    <>{{ typeName }}.</>
                  </Trans>
                </Text>

                {error && (
                  <Alert
                    data-testid="rename-modal__alert--error"
                    margin={{ bottom: 'small' }}
                    status="error"
                  >
                    {error}
                  </Alert>
                )}

                <Field name="name">
                  {(fieldProps: FieldProps<string>) => (
                    <FormField
                      error={fieldProps.form.errors.name}
                      label={t('input.name.label')}
                    >
                      <TextInput
                        {...fieldProps.field}
                        autoFocus={true}
                        data-testid="rename-modal__name-input"
                        disabled={formProcessing}
                      />
                    </FormField>
                  )}
                </Field>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  data-testid="rename-modal__button--cancel"
                  label={t('buttons.cancel.label')}
                  onClick={onClose}
                />
                <LoadingButton
                  data-testid="rename-modal__button--submit"
                  disabled={formProcessing}
                  label={t('buttons.submit.label')}
                  loading={formProcessing}
                  primary={true}
                  type="submit"
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
