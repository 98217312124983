import { useEffect, useState } from 'react';
import { TextAreaDescriptionProps } from './';

type UseTextAreaDescriptionOptions = {
  initialValue: string | null | undefined;
  onSave: (value: string) => Promise<void>;
};

type UseTextAreaDescriptionResult = {
  cancel: () => void;
  textAreaDescriptionProps: TextAreaDescriptionProps;
};

/**
 * Hook that provides functionality for the `TextInputHeading` component.
 */
export const useTextAreaDescription = ({
  initialValue,
  onSave,
}: UseTextAreaDescriptionOptions): UseTextAreaDescriptionResult => {
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue ?? '');

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onChange: TextAreaDescriptionProps['onChange'] = (
    event: Pick<React.ChangeEvent<HTMLTextAreaElement>, 'target'>
  ) => {
    setValue(event.target.value);
  };

  const onClickDescription: TextAreaDescriptionProps['onClickDescription'] =
    () => {
      setEditing(true);
    };

  const onClickSave: TextAreaDescriptionProps['onClickSave'] = async (
    event: Pick<React.FormEvent, 'preventDefault'>
  ) => {
    event.preventDefault();

    setSaving(true);
    await onSave(value);
    setEditing(false);
    setSaving(false);
  };

  const onClickCancel: TextAreaDescriptionProps['onClickCancel'] = () => {
    setValue('');
    setEditing(false);
  };

  return {
    cancel: onClickCancel,
    textAreaDescriptionProps: {
      editing,
      onChange,
      onClickCancel,
      onClickDescription,
      onClickSave,
      saving,
      value,
    },
  };
};
