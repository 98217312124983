import React, { useMemo } from 'react';
import { LoadingOverlay } from '@common/components';
import { useFuseState } from '@common/hooks';
import { IntegrationModal } from '@features/integrations';
import { useIntegrationsPageConfiguredTabQuery } from '@generated/graphql-code-generator';
import { Icon } from '@voleer/ui-kit';
import { Box, Grid, Heading, Paragraph, TextInput } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { compact, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';
import { IntegrationCard } from '../IntegrationCard';

const SearchInput = styled(TextInput)`
  background-color: ${props => normalizeColor('white', props.theme)};
`;
/**
 * Renders the "Configured" tab of the integrations page.
 */
export const ConfiguredTab: React.FC = () => {
  const [t] = useTranslation('pages/IntegrationsPage');

  const integrationInstancesQuery = useIntegrationsPageConfiguredTabQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {},
    },
  });

  const integrations = integrationInstancesQuery.data?.integrations?.items;

  const availableIntegrations = useMemo(() => {
    return orderBy(
      compact(integrations || []),
      integration => (integration.displayName || '').toLowerCase(),
      'asc'
    );
  }, [integrations]);

  const [modalIntegrationId, setModalIntegrationId] = useQueryParam(
    'integrationId',
    StringParam
  );

  const {
    searchResults: integrationInstances,
    searchTerm,
    setSearchTerm,
  } = useFuseState({
    fuseOptions: {
      keys: ['displayName', 'description'],
      matchAllTokens: true,
      shouldSort: false,
      threshold: 0.3,
      tokenize: true,
    },
    items: availableIntegrations,
  });

  const onChangeSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(event.target.value);

  return (
    <LoadingOverlay
      loading={
        !integrationInstancesQuery.data && integrationInstancesQuery.loading
      }
    >
      <Box pad={{ horizontal: 'small', top: 'medium' }}>
        <SearchInput
          icon={<Icon icon={FaSearch} />}
          onChange={onChangeSearchTerm}
          placeholder={t('configured-tab.search.input.placeholder')}
          value={searchTerm}
        />
      </Box>

      {!!integrationInstances?.length && (
        <Grid
          columns="360px"
          gap="small"
          pad={{ horizontal: 'small', vertical: 'medium' }}
        >
          {integrationInstances.map(integration => {
            if (!integration) return null;

            return (
              <IntegrationCard
                data-testid="integrations-page__configured-tab--integration-card"
                integrationId={integration.id}
                key={integration.id}
                onClick={() => {
                  setModalIntegrationId(integration.id);
                }}
              />
            );
          })}
        </Grid>
      )}

      {!integrationInstances?.length && (
        <>
          <Heading
            level="3"
            margin={{ bottom: 'small', top: 'xlarge' }}
            textAlign="center"
          >
            {t('configured-tab.no-configurations.heading')}
          </Heading>
          <Paragraph margin="0" textAlign="center">
            {t('configured-tab.no-configurations.help')}
          </Paragraph>
        </>
      )}

      {modalIntegrationId && (
        <IntegrationModal
          integrationId={modalIntegrationId}
          onClose={() => setModalIntegrationId(undefined)}
        />
      )}
    </LoadingOverlay>
  );
};
