import React from 'react';
import { urlFor } from '@common/utils';
import { useLibraryItemByIdPageQuery } from '@generated/graphql-code-generator';
import { Redirect, RouteComponentProps } from 'react-router';
import { LibraryItemPageLayout } from '../components';

export type LibraryItemByIdRouteParams = {
  itemId: string;
};

type LibraryItemByIdProps = Readonly<
  RouteComponentProps<LibraryItemByIdRouteParams>
>;

/**
 * Page for navigating to a given library item by its ID.
 */
export const LibraryItemByIdPage: React.FC<LibraryItemByIdProps> = props => {
  const params = props.match.params;

  const { loading, error, data } = useLibraryItemByIdPageQuery({
    variables: {
      id: params.itemId,
    },
  });

  const libraryItem = data?.libraryItems?.items?.[0];

  if (!libraryItem) {
    return <LibraryItemPageLayout error={error} loading={loading} />;
  }

  const type = libraryItem.libraryItemType;
  const name = libraryItem.name;
  const packageName = libraryItem.packageMetadata.name;
  const packageVersion = libraryItem.packageMetadata.version;
  const publisherName = libraryItem.packageMetadata.publisher.name;

  return (
    <Redirect
      to={urlFor('libraryItem')({
        name,
        packageName,
        packageVersion,
        publisherName,
        type,
      })}
    />
  );
};
