import React, { useState } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { urlFor } from '@common/utils';
import {
  CreateWorkspaceInput,
  CreateWorkspaceMutationVariables,
  FindWorkspacesDocument,
  useCreateWorkspaceMutation,
} from '@generated/graphql-code-generator';
import { Box, BoxProps, Heading } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { AddWorkspaceForm, AddWorkspaceFormValues } from '..';

type AddWorkspaceProps = BoxProps & {
  onCancel: () => void;
};

const CONTENT_WIDTH = '640px';

/**
 * Component for adding a new workspace.
 */
export const AddWorkspace: React.FC<AddWorkspaceProps> = ({
  onCancel,
  ...props
}) => {
  const [t] = useTranslation('pages/WorkspacesPage');
  const [workspaceId, setWorkspaceId] = useState('');
  const handleCancelClick = () => {
    onCancel();
  };

  const [createWorkspace] = useCreateWorkspaceMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(FindWorkspacesDocument)!],
  });
  const handleFormSubmit = async (values: AddWorkspaceFormValues) => {
    try {
      const workspaceInput: CreateWorkspaceInput = {
        displayName: values.displayName,
      };
      const variables: CreateWorkspaceMutationVariables = {
        workspace: workspaceInput,
      };
      const response = await createWorkspace({ variables });
      const responseError = response.errors?.[0];
      if (responseError) {
        return Promise.reject(
          t('add-workspace.error', {
            error: responseError,
          })
        );
      }

      // Update the state with the newly created workspace id
      const createdWorkspaceId = response.data?.createWorkspace?.id ?? '';
      setWorkspaceId(createdWorkspaceId);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        t('add-workspace.error', {
          error,
        })
      );
    }
  };
  if (workspaceId) {
    return <Redirect push={true} to={urlFor('workspace')({ workspaceId })} />;
  }
  return (
    <Box
      alignSelf="center"
      background="white"
      elevation="small"
      flex={{ shrink: 0 }}
      pad="medium"
      round="xsmall"
      width={CONTENT_WIDTH}
      {...props}
    >
      <Heading level="5">{t('add-workspace.heading')}</Heading>
      <AddWorkspaceForm
        onCancel={handleCancelClick}
        onSubmit={handleFormSubmit}
      />
    </Box>
  );
};
