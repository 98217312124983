import { useEffect, useState } from 'react';
import { TextInputHeadingProps } from './';

type UseTextInputHeadingOptions = {
  initialValue: string | null | undefined;
  onSave: (value: string) => Promise<void>;
};

type UseTextInputHeadingResult = {
  cancel: () => void;
  textInputHeadingProps: TextInputHeadingProps;
};

/**
 * Hook that provides functionality for the `TextInputHeading` component.
 */
export const useTextInputHeading = ({
  initialValue,
  onSave,
}: UseTextInputHeadingOptions): UseTextInputHeadingResult => {
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue ?? '');

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onChange = (
    event: Pick<React.ChangeEvent<HTMLInputElement>, 'target'>
  ) => {
    setValue(event.target.value);
  };

  const onClickHeading = () => {
    setEditing(true);
  };

  const onClickSave = async (
    event: Pick<React.FormEvent, 'preventDefault'>
  ) => {
    event.preventDefault();

    setSaving(true);
    await onSave(value);
    setEditing(false);
    setSaving(false);
  };

  const onClickCancel = () => {
    setValue(initialValue ?? '');
    setEditing(false);
  };

  return {
    cancel: onClickCancel,
    textInputHeadingProps: {
      editing,
      onChange,
      onClickCancel,
      onClickHeading,
      onClickSave,
      saving,
      value,
    },
  };
};
