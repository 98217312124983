import React from 'react';
import { TextAvatar } from '@common/components';
import { Box, BoxProps, Text } from 'grommet';

/**
 * Prop types for the `DetailsSectionDateInfo` component.
 */
type DetailsSectionDateInfoProps = Readonly<
  BoxProps & {
    // Formatted date string. (i.e. Jan 2, 2020)
    date: string;

    // Name of the tenant member.
    name: string;
  }
>;

/**
 * Renders date information in the "Details" section.
 *
 * Example:
 * John Smith
 * Jan 2, 2020
 */
export const DetailsSectionDateInfo: React.FC<DetailsSectionDateInfoProps> = ({
  date,
  name,
  ...props
}) => {
  return (
    <Box align="center" direction="row" {...props}>
      <TextAvatar name={name} size="xlarge" />

      <Box margin={{ left: 'small' }}>
        <Text
          className="test-date-info__name"
          margin={{ bottom: '-4px', top: 'none' }}
          size="medium"
          weight="bold"
        >
          {name}
        </Text>
        <Text
          className="test-date-info__date"
          margin={{ vertical: 'none' }}
          size="small"
          weight="bold"
        >
          {date}
        </Text>
      </Box>
    </Box>
  );
};
