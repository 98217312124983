import React from 'react';
import {
  AppLayout,
  PageContent,
  RouteTab,
  RouteTabs,
} from '@common/components';
import { urlFor } from '@common/utils';
import {
  CancelScheduledJobModal,
  isCancelableScheduledJobStatus,
  useCancelScheduledJobModal,
} from '@features/scheduled-jobs';
import {
  useFindWorkspaceQuery,
  useScheduledJobPageQuery,
} from '@generated/graphql-code-generator';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { SummaryTab } from './SummaryTab';

export type ScheduledJobRouteParams = {
  workspaceId: string;
  scheduledJobId: string;
};

type ScheduledJobPageProps = RouteComponentProps<ScheduledJobRouteParams>;

/**
 * Renders the page for viewing a Scheduled Job.
 */
export const ScheduledJobPage: React.FC<ScheduledJobPageProps> = ({
  match,
}) => {
  const { workspaceId, scheduledJobId } = match.params;

  const [t] = useTranslation('pages/ScheduledJobPage');

  const workspaceQuery = useFindWorkspaceQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: workspaceId },
  });

  const scheduledJobQuery = useScheduledJobPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: scheduledJobId,
    },
  });

  // Keep track of queries that impact the page's overall loading/error state
  const queries = [workspaceQuery, scheduledJobQuery];

  const loading = queries.some(query => !query.data && query.loading);
  const error = queries.find(query => query.error)?.error;

  const workspace = workspaceQuery.data?.workspace;
  const scheduledJob = scheduledJobQuery.data?.scheduledJob;

  const { props: cancelModalProps, open: setCancelModalOpen } =
    useCancelScheduledJobModal({
      workspaceId,
    });

  const isCancelable =
    !!scheduledJob && isCancelableScheduledJobStatus(scheduledJob.status);

  const onCancelClick = () => {
    setCancelModalOpen({ id: scheduledJobId });
  };

  const layout = (children?: React.ReactNode) => (
    <AppLayout
      breadcrumbs={[
        {
          title: t('breadcrumbs.workspaces'),
          to: urlFor('workspaces')(),
        },
        {
          title: workspace?.displayName || '',
          to: urlFor('workspace')({ workspaceId }),
        },
        {
          title: t('breadcrumbs.scheduledJobs'),
          to: urlFor('scheduledJobs')({ workspaceId }),
        },
      ]}
      title={scheduledJob?.templateConfiguration.displayName || ''}
      toolbar={
        <AppLayout.Toolbar>
          {isCancelable && (
            <Button
              disabled={!workspace}
              label={t('toolbar.buttons.cancel.label')}
              onClick={onCancelClick}
              size="small"
            />
          )}
        </AppLayout.Toolbar>
      }
    >
      <PageContent
        children={children}
        error={error}
        loading={loading}
        loadingLabel={t('loading')}
      />
    </AppLayout>
  );

  if (loading || error) {
    return layout();
  }

  // Redirect if we still don't have required records after we're done loading
  if (!workspace) {
    return <Redirect to={urlFor('workspaces')()} />;
  }
  if (!scheduledJob) {
    return (
      <Redirect to={urlFor('scheduledJobs')({ workspaceId: workspace.id })} />
    );
  }

  return layout(
    <>
      <RouteTabs justify="start">
        <RouteTab queryParamValue="summary" title={t('tabs.summary')}>
          <SummaryTab
            scheduledJobId={scheduledJob.id}
            workspaceId={workspaceId}
          />
        </RouteTab>
      </RouteTabs>
      <CancelScheduledJobModal {...cancelModalProps} />
    </>
  );
};
