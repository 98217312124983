import React, { useContext } from 'react';
import { LoadingOverlay } from '@common/components';
import { useTypedFlags } from '@features/launch-darkly';
import { useNotificationsSettingsTabQuery } from '@generated/graphql-code-generator';
import { VoleerTheme } from '@voleer/ui-kit';
import {
  Box,
  CheckBoxProps,
  Grid,
  GridProps,
  ResponsiveContext,
  ThemeContext,
} from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled, { css } from 'styled-components';
import {
  ActivitySummarySection,
  NotificationSubscriptionSection,
} from './components';

export const checkboxThemeOverrides = {
  checkBox: {
    toggle: {
      extend: (props: CheckBoxProps & { theme: VoleerTheme }) => css`
        background-color: ${props.checked
          ? normalizeColor('brand', props.theme)
          : normalizeColor('light-4', props.theme)};
        height: 36px;
        width: 60px;
      `,
      knob: {
        extend: css`
          left: ${(props: CheckBoxProps) =>
            props.checked ? '50% !important' : '5px'};
          height: 24px;
          top: 4px;
          width: 24px;
        `,
      },
    },
  },
};

const NotificationSubscriptionSectionContainer = styled(Box)`
  order: 1;

  ${props => {
    return `
      @media only screen and (min-width: ${props.theme.global.breakpoints.medium.value}px) {
        order: 0;
      }
    `;
  }}
`;

/**
 * Renders the settings tab of the Notifications page.
 */
export const NotificationsSettingsTab: React.FC = () => {
  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();
  const notificationSubscriptionSectionQuery = useNotificationsSettingsTabQuery(
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: pollingConfig?.notificationsPage,
      variables: {
        input: {
          ids: null,
          statuses: null,
          types: null,
        },
      },
    }
  );

  const viewportSize = useContext(ResponsiveContext);
  const defaultColumns = ['flex', 'flex'];
  const columns: Record<string, GridProps['columns']> = {
    small: ['auto'],
    medium: ['auto'],
  };

  return (
    <LoadingOverlay
      loading={
        !notificationSubscriptionSectionQuery?.data &&
        notificationSubscriptionSectionQuery.loading
      }
    >
      <ThemeContext.Extend value={checkboxThemeOverrides}>
        <Grid
          columns={columns[viewportSize] || defaultColumns}
          gap="small"
          pad="medium"
        >
          <NotificationSubscriptionSectionContainer data-testid="notifications-settings-tab__section--notification-subscription">
            <NotificationSubscriptionSection />
          </NotificationSubscriptionSectionContainer>

          <Box data-testid="notifications-settings-tab__section--activity-summary">
            <ActivitySummarySection />
          </Box>
        </Grid>
      </ThemeContext.Extend>
    </LoadingOverlay>
  );
};
