import { useMemo, useState } from 'react';
import {
  Workspace,
  useDataSetSelectorQuery,
} from '@generated/graphql-code-generator';
import { isNotNullOrUndefined } from '@voleer/types';
import { DataSetSelectorOption, DataSetSelectorProps } from '.';

export type UseDataSetSelectorOptions = Readonly<{
  /**
   * The `DataSetMetadata` that is compatible. The user is instructed to create
   * a DataSet of this type when no compatible DataSets are found.
   */
  compatibleDataSetMetadata: DataSetSelectorProps['compatibleDataSetMetadata'];

  /**
   * The ID of the required `DataSchemaMetadata`. DataSets that provide a
   * matching schema will be displayed in the options for the selector.
   */
  dataSchemaMetadataId?: string;

  /**
   * The workspace from which matching DataSet options should be retrieved.
   */
  workspace?: Pick<Workspace, 'id'>;
}>;

export type UseDataSetSelectorResult = {
  /**
   * The currently selected option, if any.
   */
  dataSet?: DataSetSelectorOption;

  /**
   * The props to pass to the `DataSetSelector`.
   */
  props: DataSetSelectorProps;
};

/**
 * Hook which provides the data for the `DataSetSelector` component.
 *
 * ```typescript
 * const dataSetSelectorProps = useDataSetSelector({ template, workspace })
 *
 * return <form>
 *   <DataSetSelector {...dataSetSelectorProps} />
 * </form>
 * ```
 */
export const useDataSetSelector = ({
  dataSchemaMetadataId,
  compatibleDataSetMetadata,
  workspace,
}: UseDataSetSelectorOptions): UseDataSetSelectorResult => {
  const [selectedDataSet, setSelectedDataSet] =
    useState<DataSetSelectorOption>();

  const { data, loading, ...query } = useDataSetSelectorQuery({
    fetchPolicy: 'cache-and-network',
    skip: !workspace?.id || !dataSchemaMetadataId,
    variables: {
      // Ignore potentially undefined DataSchemaMetadata ID since the query will be skipped if undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataSchemaMetadataId: dataSchemaMetadataId!,

      // Ignore potentially undefined workspace ID since the query will be skipped if undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      workspaceId: workspace?.id!,
    },
  });

  const dataSets = data?.dataSets?.items;
  const options = dataSets?.filter(isNotNullOrUndefined);

  // Calculate the selected dataset
  const dataSet = useMemo(() => {
    // If a dataset has been selected, return it
    if (selectedDataSet) {
      return selectedDataSet;
    }

    // If there is only one possible dataset that could be selected, use that
    if (dataSets?.length === 1) {
      return dataSets[0] || undefined;
    }

    return undefined;
  }, [selectedDataSet, dataSets]);

  const onChange: DataSetSelectorProps['onChange'] = dataSet => {
    setSelectedDataSet(dataSet);
  };

  return {
    dataSet,
    props: {
      compatibleDataSetMetadata,
      dataSet,
      disabled: !query.called || !data,
      loading,
      onChange,
      options,
    },
  };
};
