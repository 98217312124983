import React from 'react';
import { useAccountStatus, useDateFormat } from '@common/hooks';
import { urlFor } from '@common/utils';
import { isTemplateConfigurationDeleted } from '@features/workflows';
import { ScheduledJobStatus } from '@generated/graphql-code-generator';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  FancyTableProps,
  Icon,
  Link,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Button, Heading, Paragraph, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';
import { ScheduledJobStatusBadge } from '../../components';
import { isCancelableScheduledJobStatus } from '../../utils';
import { ScheduledJobSchedule } from '../ScheduledJobSchedule';
import { ScheduledJobProp } from './interface';

export type ScheduledJobsTableProps = FancyTableProps & {
  /**
   * Callback which is called when the user clicks the button to add a schedule.
   */
  onAdd?: () => void;

  /**
   * Callback which is called when the user cancels a scheduled job.
   */
  onCancel: (scheduledJob: ScheduledJobProp) => Promise<void> | void;

  /**
   * Callback which is called when the user types into the table's search input.
   */
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;

  /**
   * The scheduled jobs to display.
   */
  scheduledJobs: Array<ScheduledJobProp | null>;

  /**
   * The current search term.
   */
  searchTerm?: string;
};

/**
 * Renders the table for displaying Scheduled Jobs.
 */
export const ScheduledJobsTable: React.FC<ScheduledJobsTableProps> = ({
  onAdd,
  onCancel,
  onSearch,
  scheduledJobs,
  searchTerm,
  ...fancyTableProps
}) => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobsTable'
  );

  const { disableWriteOperation } = useAccountStatus();

  const [format, { DateTimeFormat }] = useDateFormat();

  const renderBody = () => {
    // Exclude canceled scheduled jobs
    const renderedScheduledJobs = scheduledJobs.filter(
      scheduledJob => scheduledJob?.status !== ScheduledJobStatus.Canceled
    );

    if (searchTerm && !renderedScheduledJobs.length) {
      return (
        <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
          <Heading
            level="3"
            margin={{ bottom: 'small', top: '0' }}
            textAlign="center"
          >
            {t('no-results')}
          </Heading>
        </FancyTable.Empty>
      );
    }

    if (!renderedScheduledJobs.length) {
      return (
        <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
          <Heading
            level="3"
            margin={{ bottom: 'small', top: '0' }}
            textAlign="center"
          >
            {t('empty')}
          </Heading>
          <Paragraph margin="0" textAlign="center">
            {t('empty-helper')}
          </Paragraph>
        </FancyTable.Empty>
      );
    }

    return renderedScheduledJobs.map(scheduledJob => {
      if (!scheduledJob) {
        return null;
      }

      const scheduledJobUrl = urlFor('scheduledJob')({
        scheduledJobId: scheduledJob.id,
        workspaceId: scheduledJob.templateConfiguration.workspaceId,
      });
      const lastStartedInstance = scheduledJob?.lastStartedInstance;
      const templateConfiguration = scheduledJob?.templateConfiguration;
      const templateMetadata =
        scheduledJob?.templateConfiguration?.templateMetadata;

      const onCancelClick = () => onCancel(scheduledJob);

      return (
        <FancyTable.Row data-testid="row" key={scheduledJob.id}>
          <FancyTable.Cell data-testid="cell__template">
            <Text truncate={true}>
              {!!templateMetadata?.displayName && !!templateMetadata?.id ? (
                <Link
                  title={templateMetadata.displayName}
                  to={urlFor('libraryItemById')({
                    itemId: templateMetadata.id,
                  })}
                  variation="primary"
                >
                  {templateMetadata.displayName}
                </Link>
              ) : (
                '-'
              )}
            </Text>
          </FancyTable.Cell>

          <FancyTable.Cell>
            <ScheduledJobStatusBadge status={scheduledJob.status} />
          </FancyTable.Cell>

          <FancyTable.Cell data-testid="cell__schedule">
            <Link to={scheduledJobUrl} variation="unstyled">
              <ScheduledJobSchedule scheduledJob={scheduledJob} />
            </Link>
          </FancyTable.Cell>

          <FancyTable.Cell data-testid="cell__template-configuration">
            <Text truncate={true}>
              {!!templateConfiguration?.displayName &&
              !!templateConfiguration?.id &&
              !!templateConfiguration?.workspaceId &&
              !isTemplateConfigurationDeleted(templateConfiguration.status)
                ? templateConfiguration.displayName
                : '-'}
            </Text>
          </FancyTable.Cell>

          <FancyTable.Cell data-testid="cell__last-run">
            <Text truncate={true}>
              {!!lastStartedInstance && !!lastStartedInstance.startedOn ? (
                <>
                  {format(
                    parseISO(lastStartedInstance.startedOn),
                    DateTimeFormat.AbbreviatedHumanized
                  )}
                </>
              ) : (
                '-'
              )}
            </Text>
          </FancyTable.Cell>

          <FancyTable.Cell data-testid="cell__actions">
            <DropButton
              data-testid="actions-button"
              icon={<Icon icon={MdMoreHoriz} />}
              title={t('actions.title')}
              variation="ghost"
            >
              <Link to={scheduledJobUrl} variation="primary">
                <DropButtonItem label={t('actions.items.view.label')} />
              </Link>
              {isCancelableScheduledJobStatus(scheduledJob.status) && (
                <DropButtonItem
                  label={t('actions.items.cancel.label')}
                  onClick={onCancelClick}
                />
              )}
            </DropButton>
          </FancyTable.Cell>
        </FancyTable.Row>
      );
    });
  };

  return (
    <FancyTable
      columns={[
        { width: '300px' },
        { width: '125px' },
        undefined,
        undefined,
        undefined,
        { width: '50px', justify: 'center' },
      ]}
      fill="horizontal"
      paper={true}
      rows={{ count: scheduledJobs.length }}
      {...fancyTableProps}
    >
      <FancyTable.Toolbar>
        <FancyTable.Search
          onChange={onSearch}
          placeholder={t('search.placeholder')}
          value={searchTerm}
        />
        {onAdd && (
          <Box>
            <Button
              data-testid="scheduled-jobs-table__add-btn"
              disabled={disableWriteOperation}
              label={t('add-button.label')}
              onClick={onAdd}
              primary={true}
            />
          </Box>
        )}
      </FancyTable.Toolbar>
      <FancyTable.Header>
        <FancyTable.Row>
          <FancyTable.HeaderCell>
            {t('headings.template')}
          </FancyTable.HeaderCell>
          <FancyTable.HeaderCell>{t('headings.status')}</FancyTable.HeaderCell>
          <FancyTable.HeaderCell>
            {t('headings.schedule')}
          </FancyTable.HeaderCell>
          <FancyTable.HeaderCell>
            {t('headings.template-configuration')}
          </FancyTable.HeaderCell>
          <FancyTable.HeaderCell>
            {t('headings.last-run')}
          </FancyTable.HeaderCell>
          <FancyTable.HeaderCell>{t('headings.actions')}</FancyTable.HeaderCell>
        </FancyTable.Row>
      </FancyTable.Header>
      <FancyTable.Body>{renderBody()}</FancyTable.Body>
    </FancyTable>
  );
};
