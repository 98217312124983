import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  AppLayout,
  EmptyStateImage,
  PageContent,
  RouteTab,
  RouteTabs,
  TextInputHeading,
  useTextInputHeading,
} from '@common/components';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  ConfigurationPageSubtitle,
  ConfigureWorkflow,
  TemplateConfigurationStatusBadge,
  isTemplateConfigurationDeleted,
} from '@features/workflows';
import {
  TemplateConfigurationPageDocument,
  TemplateConfigurationStatus,
  useFindWorkspaceQuery,
  useRenameTemplateConfigurationMutation,
  useTemplateConfigurationPageQuery,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import EMPTY_DATASET_IMAGE from '../DataSetPage/images/empty-dataset.svg';
import { SummaryTab, TemplateConfigurationPageToolbar } from './components';

export type TemplateConfigurationRouteParams = {
  workspaceId: string;
  templateConfigurationId: string;
};

type TemplateConfigurationPageProps =
  RouteComponentProps<TemplateConfigurationRouteParams>;

export const SUMMARY_TAB_QUERY_PARAM_VALUE = 'summary';

/**
 * Renders the page for viewing a Template Configuration.
 */
export const TemplateConfigurationPage: React.FC<TemplateConfigurationPageProps> =
  ({ match }) => {
    const { workspaceId, templateConfigurationId } = match.params;
    const [t] = useTranslation('pages/TemplateConfigurationPage');
    const { 'tenant-ui-polling-configuration': pollingConfig } =
      useTypedFlags();

    const workspaceQuery = useFindWorkspaceQuery({
      fetchPolicy: 'cache-and-network',
      variables: { id: workspaceId },
    });

    const templateConfigurationQuery = useTemplateConfigurationPageQuery({
      fetchPolicy: 'cache-and-network',
      pollInterval: pollingConfig?.workspaceInstancesLists,
      variables: {
        id: templateConfigurationId,
      },
    });

    const [renameTemplateConfiguration, renameTemplateConfigurationMutation] =
      useRenameTemplateConfigurationMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        refetchQueries: [getOperationName(TemplateConfigurationPageDocument)!],
      });

    // Keep track of queries that impact the page's overall loading/error state
    const queries = [workspaceQuery, templateConfigurationQuery];

    const loading = queries.some(query => !query.data && query.loading);
    const error = queries.find(query => query.error)?.error;

    const workspace = workspaceQuery.data?.workspace;
    const templateConfiguration =
      templateConfigurationQuery.data?.templateConfiguration;

    const templateMetadata = templateConfiguration?.templateMetadata;
    const candidateInstance = templateConfiguration?.candidateData?.instance;

    const hasConfiguration = !!templateConfiguration?.data;
    const isInEditMode = !!templateConfiguration?.candidateData;

    const { textInputHeadingProps } = useTextInputHeading({
      initialValue: templateConfiguration?.displayName,
      onSave: async (value: string) => {
        if (!templateConfiguration?.id || !workspace?.id) {
          return;
        }

        await renameTemplateConfiguration({
          variables: {
            displayName: value,
            id: templateConfiguration.id,
            workspaceId: workspace.id,
          },
        });
      },
    });

    const title =
      templateConfiguration &&
      !isTemplateConfigurationDeleted(templateConfiguration.status) ? (
        <TextInputHeading
          {...textInputHeadingProps}
          disabled={renameTemplateConfigurationMutation.loading}
        />
      ) : (
        templateConfiguration?.displayName
      );
    const subtitle = templateConfiguration && templateMetadata && (
      <ConfigurationPageSubtitle
        dataSet={templateConfiguration.dataSet}
        metadataDisplayName={templateMetadata.displayName}
        statusBadge={
          <TemplateConfigurationStatusBadge
            status={templateConfiguration.status}
          />
        }
        version={templateMetadata.packageMetadata.version}
      />
    );

    const layout = (children?: React.ReactNode) => (
      <AppLayout
        breadcrumbs={[
          {
            title: t('breadcrumbs.workspaces'),
            to: urlFor('workspaces')(),
          },
          {
            title: workspace?.displayName || '',
            to: urlFor('workspace')({ workspaceId }),
          },
          {
            title: t('breadcrumbs.template-configurations'),
            to: urlFor('templateConfigurations')({ workspaceId }),
          },
        ]}
        subtitle={subtitle}
        title={title ?? ''}
        toolbar={
          templateConfiguration?.status ===
            TemplateConfigurationStatus.Ready && (
            <TemplateConfigurationPageToolbar
              templateConfiguration={templateConfiguration}
              workspace={workspace}
              workspaceId={workspaceId}
            />
          )
        }
      >
        <PageContent
          children={children}
          error={error}
          loading={loading}
          loadingLabel={t('loading')}
        />
      </AppLayout>
    );

    if (loading || error) {
      return layout();
    }

    // Redirect if we still don't have a workspace after loading
    if (!workspace) {
      return <Redirect to={urlFor('workspaces')()} />;
    }

    // Render layout with empty state
    if (!templateConfiguration) {
      return layout();
    }

    if (isInEditMode && candidateInstance) {
      const { workspaceId, id: workflowInstanceId } = candidateInstance;
      if (workspaceId && workflowInstanceId) {
        return layout(
          <ConfigureWorkflow workflowInstanceId={workflowInstanceId} />
        );
      }
    }

    switch (templateConfiguration.status) {
      case TemplateConfigurationStatus.Ready:
        return layout(
          <RouteTabs justify="start">
            <RouteTab
              queryParamValue={SUMMARY_TAB_QUERY_PARAM_VALUE}
              title={t('tabs.summary.label')}
            >
              <SummaryTab
                templateConfigurationId={templateConfigurationId}
                workspaceId={workspaceId}
              />
            </RouteTab>

            {hasConfiguration && (
              <RouteTab
                queryParamValue="configuration"
                title={t('tabs.configuration.label')}
              >
                {/* Using negative top margin to overlap the borders */}
                <Box margin={{ top: '-1px' }}>
                  <ConfigureWorkflow
                    workflowInstanceId={
                      templateConfiguration.configurationInstance?.id
                    }
                  />
                </Box>
              </RouteTab>
            )}
          </RouteTabs>
        );

      case TemplateConfigurationStatus.Deleted:
      case TemplateConfigurationStatus.Deleting:
        return layout(
          <EmptyStateImage src={EMPTY_DATASET_IMAGE} text={t('deleted.text')} />
        );

      default:
        return layout();
    }
  };
