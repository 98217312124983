import React from 'react';
import { InstanceStatus } from '@generated/graphql-code-generator';
import { Alert, LoadingButton, Modal, ModalProps } from '@voleer/ui-kit';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Params passed to openCancelRunModal function
 */
export type OpenCancelRunModalProps = {
  workspaceId: string;
  instanceId: string;
};

/**
 * Params for function openCancelRunModal, used to open cancel run modal
 */
export type OpenCancelRunModalFn = ({
  workspaceId,
  instanceId,
}: OpenCancelRunModalProps) => void;

/**
 * Props passed to 'CancelRunModal' component
 */
export type CancelRunModalProps = Pick<ModalProps, 'open'> & {
  /**
   * Sets loading state
   */
  loading?: boolean;
  /**
   * Error to display in the modal.
   */
  error?: Error | string;
  /**
   * Callback called when cancel run is clicked
   */
  onConfirm: () => void;
  /**
   * Callback called when cancel is clicked
   */
  onCancel: () => void;
};

/**
 * Test if the provided instance status can be canceled
 * @param instanceStatus status of instance to test
 */
export const canCancelRun = (instanceStatus?: InstanceStatus): boolean => {
  return !!(
    instanceStatus &&
    [
      InstanceStatus.Pending,
      InstanceStatus.Running,
      InstanceStatus.Scheduled,
    ].includes(instanceStatus)
  );
};

/**
 * Renders cancel run modal component
 */
export const CancelRunModal = ({
  open = false,
  loading = false,
  error,
  onConfirm,
  onCancel,
}: CancelRunModalProps) => {
  const [t] = useTranslation('features/workflows/components/CancelRunModal');

  const errorMessage = error instanceof Error ? error.message : error;

  return (
    <Modal
      onClickOutside={onCancel}
      onEsc={onCancel}
      onPressEnter={onConfirm}
      open={open}
    >
      <Modal.Header>{t('header-text')}</Modal.Header>
      <Modal.Body>
        <>
          {errorMessage && (
            <Alert icon={true} status="error">
              {errorMessage}
            </Alert>
          )}
          {t('body-text')}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('cancel-button')} onClick={onCancel} />
        <LoadingButton
          label={t('cancel-run-button')}
          loading={loading}
          onClick={onConfirm}
          primary={true}
        />
      </Modal.Footer>
    </Modal>
  );
};
