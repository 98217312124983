import React from 'react';
import { PackageVersionRow } from '@common/components';
import { useIntegrationMetadataInfoRowQuery } from '@generated/graphql-code-generator';
import { PropsOf } from '@voleer/types';
import { Box, Heading } from 'grommet';
import { IntegrationIcon } from '..';

export type IntegrationMetadataInfoRowProps = Pick<
  PropsOf<typeof Box>,
  'margin' | 'pad'
> & {
  integrationMetadataId: string;
};

/**
 * Renders a row of information representing an IntegrationMetadata.
 */
export const IntegrationMetadataInfoRow: React.FC<IntegrationMetadataInfoRowProps> =
  ({ integrationMetadataId, ...boxProps }) => {
    const { data } = useIntegrationMetadataInfoRowQuery({
      variables: { integrationMetadataId },
    });

    const integrationMetadata = data?.integrationMetadata;

    if (!integrationMetadata) {
      return null;
    }

    const { displayName, packageMetadata, type } = integrationMetadata;

    return (
      <Box align="center" direction="row" gap="small" {...boxProps}>
        <Box flex={{ shrink: 0 }}>
          <IntegrationIcon size="36px" type={type} />
        </Box>
        <Box>
          <Box>
            <Heading
              level="5"
              margin="none"
              title={displayName}
              truncate={true}
            >
              {displayName}
            </Heading>
          </Box>
          <PackageVersionRow
            size="small"
            tenantDisplayName={packageMetadata.publisher.displayName}
            tenantName={packageMetadata.publisher.name}
            version={packageMetadata.version}
          />
        </Box>
      </Box>
    );
  };
