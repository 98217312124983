import React from 'react';
import { FormCheckboxFragment } from '@generated/graphql-code-generator';
import { PropsOf } from '@voleer/types';
import { useField, useFormikContext } from 'formik';
import { CheckBox, FormField } from 'grommet';
import { InitialValues, LabelMarkdown } from '.';

type CheckboxFieldProps = {
  definition: FormCheckboxFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form checkbox field.
 */
export const CheckboxField = React.forwardRef<
  HTMLInputElement,
  CheckboxFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta, fieldHelpers] = useField<string>(definition.name);

  const onChange: PropsOf<typeof CheckBox>['onChange'] = e => {
    const value = e.target.checked ? 'true' : 'false';
    fieldHelpers.setValue(value);
  };

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  return (
    <FormField error={error}>
      <CheckBox
        {...field}
        checked={field.value === 'true' ? true : false}
        disabled={disabled || formik.isSubmitting}
        label={
          <LabelMarkdown
            // This override prevents the label styling from
            // being tied to the checkBox styling in the theme.
            components={{ div: ({ children }) => <>{children}</> }}
            content={definition.label}
          />
        }
        onChange={onChange}
        ref={ref}
      />
    </FormField>
  );
});
