import React from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { PropsOf, ensureUnreachable } from '@voleer/types';
import { LoadingButton } from '@voleer/ui-kit';
import { Form, Formik, useFormikContext } from 'formik';
import { Grid } from 'grommet';
import {
  DailyFields,
  EndTypeField,
  HourlyFields,
  MinutesFields,
  MonthlyFields,
  StartDateField,
  TemplateConfigurationField,
  TimezoneField,
  WeeklyFields,
} from './components';
import { convertFormValues } from './convert-form-values';
import { initialValuesFor } from './initial-values';
import {
  ScheduleType,
  ScheduledJobFormValues,
  ScheduledJobProp,
} from './interface';
import { useScheduledJobFormValidator } from './use-scheduled-job-form-validator';

export type ScheduledJobFormValue = ScheduledJobProp;

/**
 * Grid layout for the form fields.
 */
const FieldsContainer: React.FC = props => (
  <Grid columns={['auto', '1fr']} gap="small" {...props} />
);

/**
 * Renders the schedule-related fields of the form.
 */
const ScheduleFields: React.FC = () => {
  const formik = useFormikContext<ScheduledJobFormValues>();

  const scheduleType = formik.values.type;

  switch (scheduleType) {
    case ScheduleType.months: {
      return <MonthlyFields />;
    }
    case ScheduleType.weeks: {
      return <WeeklyFields />;
    }
    case ScheduleType.days: {
      return <DailyFields />;
    }
    case ScheduleType.hours: {
      return <HourlyFields />;
    }
    case ScheduleType.minutes: {
      return <MinutesFields />;
    }
    default: {
      ensureUnreachable(scheduleType);
      return null;
    }
  }
};

type SubmitButtonProps = PropsOf<typeof LoadingButton>;

/**
 * Formik-aware submit button for the scheduled job form.
 */
const SubmitButton: React.FC<SubmitButtonProps> = props => {
  const formik = useFormikContext();
  const loading = formik.isSubmitting || formik.isValidating;
  return (
    <LoadingButton loading={loading} primary={true} type="submit" {...props} />
  );
};

export type ScheduledJobFormProps = {
  value?: ScheduledJobProp;
  onSubmit: (value: ScheduledJobProp) => Promise<void> | void;
};

type ScheduledJobFormStaticProps = {
  EndTypeField: typeof EndTypeField;
  Fields: typeof FieldsContainer;
  ScheduleFields: typeof ScheduleFields;
  StartDateField: typeof StartDateField;
  SubmitButton: typeof SubmitButton;
  TemplateConfigurationField: typeof TemplateConfigurationField;
  TimezoneField: typeof TimezoneField;
};

/**
 * Formik container for scheduled job form fields.
 *
 * Example:
 *
 * ```typescript
 * <ScheduledJobForm value={value} onSubmit={onSubmit}>
 *   <ScheduledJobForm.Fields>
 *     <ScheduledJobForm.TemplateConfigurationField
 *       options={templateConfigurationOptions}
 *     />
 *     <ScheduledJobForm.TimezoneField />
 *     <ScheduledJobForm.StartDateField />
 *     <ScheduledJobForm.ScheduleFields />
 *     <ScheduledJobForm.EndTypeField />
 *   </ScheduledJobForm.Fields>
 *   <Box align="end">
 *     <ScheduledJobForm.SubmitButton label="Submit" />
 *   </Box>
 * </ScheduledJobForm>
 * ```
 */
export const ScheduledJobForm: React.FC<ScheduledJobFormProps> &
  ScheduledJobFormStaticProps = ({
  value,
  onSubmit: originalOnSubmit,
  children,
}) => {
  const validator = useScheduledJobFormValidator();

  const onSubmit: FormikSubmitFn<ScheduledJobFormValues> = async values => {
    const updated = convertFormValues(values);
    if (!updated) {
      throw new Error(
        `Unable to convert form values: ${JSON.stringify(values)}`
      );
    }

    await originalOnSubmit(updated);
  };

  return (
    <Formik
      initialValues={initialValuesFor(value)}
      onSubmit={onSubmit}
      validate={validator}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

ScheduledJobForm.EndTypeField = EndTypeField;
ScheduledJobForm.Fields = FieldsContainer;
ScheduledJobForm.ScheduleFields = ScheduleFields;
ScheduledJobForm.StartDateField = StartDateField;
ScheduledJobForm.SubmitButton = SubmitButton;
ScheduledJobForm.TemplateConfigurationField = TemplateConfigurationField;
ScheduledJobForm.TimezoneField = TimezoneField;
