import React from 'react';
import { AccountStatus } from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';

const statusMapping: Record<AccountStatus, BadgeStatus> = {
  [AccountStatus.Active]: BadgeStatus.ok,
  [AccountStatus.Canceled]: BadgeStatus.error,
  [AccountStatus.Deactivated]: BadgeStatus.error,
  [AccountStatus.Deprovisioned]: BadgeStatus.error,
  [AccountStatus.Paused]: BadgeStatus.error,
  [AccountStatus.TrialExpired]: BadgeStatus.warning,
  [AccountStatus.Trial]: BadgeStatus.info,
  [AccountStatus.Unknown]: BadgeStatus.unknown,
};

const i18nMapping: Record<AccountStatus, string> = {
  [AccountStatus.Active]: 'subscription-tab.account-status-badge.status-active',
  [AccountStatus.Canceled]:
    'subscription-tab.account-status-badge.status-canceled',
  [AccountStatus.Deactivated]:
    'subscription-tab.account-status-badge.status-deactivated',
  [AccountStatus.Deprovisioned]:
    'subscription-tab.account-status-badge.status-deprovisioned',
  [AccountStatus.Paused]: 'subscription-tab.account-status-badge.status-paused',
  [AccountStatus.TrialExpired]:
    'subscription-tab.account-status-badge.status-trial-expired',
  [AccountStatus.Trial]: 'subscription-tab.account-status-badge.status-trial',
  [AccountStatus.Unknown]:
    'subscription-tab.account-status-badge.status-unknown',
};

type AccountStatusBadgeProps = Omit<BadgeProps, 'label' | 'status'> & {
  status: AccountStatus;
};

/**
 * Renders a badge to display the given tenant account status.
 */
export const AccountStatusBadge: React.FC<AccountStatusBadgeProps> = ({
  status,
  ...props
}) => {
  const [t] = useTranslation('pages/TenantSettingsPage');
  const label = t(i18nMapping[status]);
  return (
    <Badge
      {...props}
      icon={false}
      label={label}
      status={statusMapping[status]}
    />
  );
};
