import { useState } from 'react';
import { urlFor } from '@common/utils';
import {
  DeleteWorkspaceModalSubmitMutationOptions,
  Workspace,
  useDeleteWorkspaceModalQuery,
  useDeleteWorkspaceModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DeleteWorkspaceModalProps } from './DeleteWorkspaceModal';

type WorkspaceParam = Pick<Workspace, 'id'>;

export type UseDeleteWorkspaceModalOptions = {
  deleteMutationRefetchQueries?: DeleteWorkspaceModalSubmitMutationOptions['refetchQueries'];
};

export type UseDeleteWorkspaceModalResult = {
  /**
   * Props passed to the `DeleteWorkspaceModal` component.
   */
  deleteWorkspaceModalProps: DeleteWorkspaceModalProps;

  /**
   * Handler for opening the modal.
   */
  openDeleteWorkspaceModal: (workspace: WorkspaceParam) => void;
};

/**
 * Hook for handling the workspace deletion modal
 */
export const useDeleteWorkspaceModal = ({
  deleteMutationRefetchQueries,
}: UseDeleteWorkspaceModalOptions = {}): UseDeleteWorkspaceModalResult => {
  const [t] = useTranslation(
    'features/workflows/components/DeleteWorkspaceModal'
  );

  const [workspaceId, setWorkspaceId] = useState<string | undefined>();

  const [confirmationText, setConfirmationText] = useState<string>('');

  const routerHistory = useHistory();

  const { data, loading } = useDeleteWorkspaceModalQuery({
    skip: !workspaceId,
    variables: {
      // Ignore potentially undefined workspaceId since the query will be skipped if workspaceId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      workspaceId: workspaceId!,
    },
  });

  const workspace = data?.workspace;

  const [deleteWorkspace, { error, loading: submitting }] =
    useDeleteWorkspaceModalSubmitMutation({
      awaitRefetchQueries: true,
      refetchQueries: deleteMutationRefetchQueries,
      errorPolicy: 'all',
    });

  const onChangeConfirmationText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmationText(e.target.value);

  const onConfirm: DeleteWorkspaceModalProps['onConfirm'] = async () => {
    if (!workspaceId || confirmationText !== t('confirmation-key')) {
      return;
    }

    const result = await deleteWorkspace({
      variables: {
        workspaceId,
      },
    });

    if (!result.errors?.length) {
      closeDeleteWorkspaceModal();
      routerHistory.push(urlFor('workspaces')());
    }
  };

  const openDeleteWorkspaceModal: UseDeleteWorkspaceModalResult['openDeleteWorkspaceModal'] =
    (workspace: WorkspaceParam) => {
      setWorkspaceId(workspace.id);
    };

  const closeDeleteWorkspaceModal: DeleteWorkspaceModalProps['onClose'] =
    () => {
      setWorkspaceId(undefined);
      setConfirmationText('');
    };

  return {
    deleteWorkspaceModalProps: {
      confirmationText,
      displayName: workspace?.displayName,
      error,
      loading,
      open: !!workspaceId,
      onChangeConfirmationText,
      onClose: closeDeleteWorkspaceModal,
      onConfirm,
      submitting,
    },
    openDeleteWorkspaceModal,
  };
};
