import {
  DataRequestStatus,
  DataSetStatus,
  InstanceStatus,
  Step,
  StepStatus,
  TemplateConfiguration,
  TemplateConfigurationExposureLevel,
  TemplateConfigurationStatus,
} from '@generated/graphql-code-generator';
import { compact, orderBy } from 'lodash';
import { Overwrite } from 'utility-types';

/**
 * Dictionary for ordering statuses to determine which one we should display in
 * `getRunStatus`.
 */
const RUN_STATUS_ORDER = [
  InstanceStatus.Failed,
  InstanceStatus.TimedOut,
  InstanceStatus.Cancelling,
  InstanceStatus.Canceled,
  InstanceStatus.Running,
  InstanceStatus.Scheduled,
  InstanceStatus.Pending,
  InstanceStatus.Completed,
  InstanceStatus.Unknown,
].reduce((acc, next, index) => {
  acc[next] = index;
  return acc;
}, {} as Record<InstanceStatus, number>);

export const WORKFLOW_FORM_DATE_FORMAT = 'yyyy/MM/dd';

export const WORKFLOW_FORM_DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';

type SortableStep = Pick<Step, 'completedOn' | 'createdOn' | 'status'>;

/**
 * Sorts steps to be used for a workflow instance.
 */
export const sortSteps = <TStep extends SortableStep>(
  steps: Array<TStep | null>
): TStep[] => {
  const getStepStatusOrder = (step: typeof steps[number]) => {
    switch (step?.status) {
      case StepStatus.WaitingForInput:
      case StepStatus.Scheduled:
        return 0;
      default:
        return 1;
    }
  };

  return orderBy(
    compact(steps),
    [getStepStatusOrder, 'completedOn', 'createdOn'],
    ['asc', 'desc', 'desc']
  );
};

/**
 * Determines the most relevant InstanceStatus to display out of the given
 * statuses.
 */
export const getRunStatus = (
  status: InstanceStatus,
  ...statuses: Array<InstanceStatus | undefined>
) => {
  const ordered = orderBy(
    compact([status, ...statuses]),
    status => RUN_STATUS_ORDER[status]
  );
  return ordered[0];
};

/**
 * Determines whether configuration steps should be displayed depending on the
 * Template Configuration exposure level.
 */
export const shouldShowConfigurationSteps = (
  exposureLevel?: TemplateConfigurationExposureLevel
): boolean => {
  if (!exposureLevel) {
    return true;
  }

  const resultMap: Record<TemplateConfigurationExposureLevel, boolean> = {
    [TemplateConfigurationExposureLevel.Standalone]: false,
    [TemplateConfigurationExposureLevel.OnDemand]: true,
    [TemplateConfigurationExposureLevel.Unknown]: true,
  };

  return resultMap[exposureLevel];
};

type SaveableTemplateConfiguration = Overwrite<
  TemplateConfiguration,
  { exposureLevel: TemplateConfigurationExposureLevel.OnDemand }
>;

/**
 * Helper function to test if the given template configuration can be saved.
 */
export const isSaveableTemplateConfiguration = (
  templateConfiguration?: Pick<TemplateConfiguration, 'exposureLevel'> | null
): templateConfiguration is SaveableTemplateConfiguration => {
  if (!templateConfiguration) {
    return false;
  }

  // Configuration can be saved only if the configuration is present and
  // has not already been saved.
  return (
    templateConfiguration?.exposureLevel ===
    TemplateConfigurationExposureLevel.OnDemand
  );
};

/**
 * Array of `InstanceStatus`es that are considered "unstarted".
 */
const UNSTARTED_STATUSES = [
  InstanceStatus.Scheduled,
  InstanceStatus.Pending,
  InstanceStatus.Unknown,
] as const;

/**
 * Represents `InstanceStatus`es excluding `InstanceStatus.Unknown`.
 */
export type KnownInstanceStatus = Exclude<
  InstanceStatus,
  InstanceStatus.Unknown
>;

/**
 * Represents `InstanceStatus`es we consider "unstarted".
 */
export type UnstartedInstanceStatus = typeof UNSTARTED_STATUSES[number];

/**
 * Represents `InstanceStatus`es we consider "started".
 */
export type StartedInstanceStatus = Exclude<
  InstanceStatus,
  UnstartedInstanceStatus
>;

const ENDED_INSTANCE_STATUSES = [
  InstanceStatus.Canceled,
  InstanceStatus.Cancelling,
  InstanceStatus.Completed,
  InstanceStatus.Failed,
  InstanceStatus.TimedOut,
] as const;

/**
 * Represents `InstanceStatus`es where the instance workflow has ended and will
 * not generate any further steps.
 */
export type EndedInstanceStatus = typeof ENDED_INSTANCE_STATUSES[number];

/**
 * Type guard to check if the given `InstanceStatus` is an `EndedInstanceStatus`.
 */
export const isEndedInstanceStatus = (
  status: InstanceStatus
): status is EndedInstanceStatus => {
  return ENDED_INSTANCE_STATUSES.includes(status as EndedInstanceStatus);
};

const UNSUCCESSFUL_INSTANCE_STATUSES = [
  InstanceStatus.Failed,
  InstanceStatus.TimedOut,
] as const;

/**
 * Represents `InstanceStatus`es where the instance workflow was unsuccessful
 * (failed, timed out, etc.).
 *
 * Canceled statuses are not considered to be unsuccessful.
 */
export type UnsuccessfulInstanceStatus =
  typeof UNSUCCESSFUL_INSTANCE_STATUSES[number];

/**
 * Type guard to check if the given `InstanceStatus` is an `UnsuccessfulInstanceStatus`.
 */
export const isUnsuccessfulInstanceStatus = (
  status: InstanceStatus
): status is UnsuccessfulInstanceStatus => {
  return UNSUCCESSFUL_INSTANCE_STATUSES.includes(
    status as UnsuccessfulInstanceStatus
  );
};

/**
 * Determines if a template configuration has been deleted.
 */
export const isTemplateConfigurationDeleted = (
  status: TemplateConfigurationStatus
): status is
  | TemplateConfigurationStatus.Deleted
  | TemplateConfigurationStatus.Deleting =>
  [
    TemplateConfigurationStatus.Deleted,
    TemplateConfigurationStatus.Deleting,
  ].includes(status);

/**
 * Determines if a dataset has been deleted.
 */
export const isDataSetDeleted = (
  status: DataSetStatus
): status is DataSetStatus.Deleted | DataSetStatus.Deleting =>
  [DataSetStatus.Deleted, DataSetStatus.Deleting].includes(status);

/**
 * Type guard to check that the given `InstanceStatus` is one of the statuses
 * we consider "started".
 *
 * @param status the status to check
 */
export const isInstanceStatusStarted = (
  status: InstanceStatus
): status is StartedInstanceStatus => {
  return !UNSTARTED_STATUSES.includes(status as UnstartedInstanceStatus);
};

/**
 * Type guard to check that the given `InstanceStatus` is one of the statuses
 * we consider "completed".
 *
 * @param status the status to check
 */
export const isInstanceStatusCompleted = (
  status: InstanceStatus
): status is InstanceStatus.Completed => status === InstanceStatus.Completed;

/**
 * Type guard to check that the given `InstanceStatus` is not
 * `InstanceStatus.Unknown`.
 *
 * @param status the status to check
 */
export const isKnownInstanceStatus = (
  status: InstanceStatus
): status is KnownInstanceStatus => status !== InstanceStatus.Unknown;

/**
 * Array of `StepStatus`es that are considered terminal statuses.
 */
const TERMINAL_STEP_STATUSES = [
  StepStatus.Canceled,
  StepStatus.Completed,
  StepStatus.Failed,
] as const;

/**
 * Represents `StepStatus`es that are considered terminal statuses, where the
 * status has reached an end state and will not progress further.
 */
export type TerminalStepStatus = typeof TERMINAL_STEP_STATUSES[number];

/**
 * Type guard to check if the given `StepStatus` is a `TerminalStepStatus`.
 */
export const isTerminalStepStatus = (
  status: StepStatus
): status is TerminalStepStatus => {
  return TERMINAL_STEP_STATUSES.includes(status as TerminalStepStatus);
};

/**
 * Represents `StepStatus`es excluding `StepStatus.Unknown`.
 */
export type KnownStepStatus = Exclude<StepStatus, StepStatus.Unknown>;

/**
 * Type guard to check that the given `InstanceStatus` is not
 * `InstanceStatus.Unknown`.
 *
 * @param status the status to check
 */
export const isKnownStepStatus = (
  status: StepStatus
): status is KnownStepStatus => status !== StepStatus.Unknown;

/**
 * Array of `DataRequestStatus`es that are considered terminal statuses.
 */
const TERMINAL_DATA_REQUEST_STATUSES = [
  DataRequestStatus.Canceled,
  DataRequestStatus.Completed,
  DataRequestStatus.Failed,
] as const;

/**
 * Represents `DataRequestStatus`es that are considered terminal statuses, where
 * the status has reached an end state and will not progress further.
 */
export type TerminalDataRequestStatus =
  typeof TERMINAL_DATA_REQUEST_STATUSES[number];

/**
 * Type guard to check if the given `DataRequestStatus` is a `TerminalDataRequestStatus`.
 */
export const isTerminalDataRequestStatus = (
  status: DataRequestStatus
): status is TerminalDataRequestStatus => {
  return TERMINAL_DATA_REQUEST_STATUSES.includes(
    status as TerminalDataRequestStatus
  );
};
