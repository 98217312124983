import React, { useEffect } from 'react';
import { AuthenticationHandler, renewOrLogin, useOidc } from '../..';
import { OidcStatus } from '../OidcProvider';

export type AuthenticatedProps = {
  /**
   * Content to render when the user is unauthenticated.
   */
  unauthenticated?: React.ReactNode;

  /**
   * Handler that is called when authentication is needed, either because the
   * user is unauthenticated or the token is expired/expiring.
   *
   * By default this will attempt to silently renew the token, or redirect to
   * the login page if unable to renew.
   */
  onAuthenticationRequired?: AuthenticationHandler;
};

/**
 * Prevents contents from being accessed without authentication, and
 * authenticates the user if they are not already authenticated.
 */
export const Authenticated: React.FC<AuthenticatedProps> = ({
  unauthenticated,
  onAuthenticationRequired = renewOrLogin,
  children,
}) => {
  const oidcContext = useOidc();
  const { user, status } = oidcContext;

  useEffect(() => {
    if (
      status !== OidcStatus.Active &&
      typeof onAuthenticationRequired === 'function'
    ) {
      onAuthenticationRequired(oidcContext);
    }
  }, [oidcContext, onAuthenticationRequired, status]);

  // Careful not to prevent rendering for expired status since the token is
  // supposed to be renewed silently in the background
  if (!user || status === OidcStatus.Unauthenticated) {
    // Render unauthenticated content if present
    return unauthenticated ? <>{unauthenticated}</> : <></>;
  }

  return <>{children}</>;
};
