import React, { useMemo, useState } from 'react';
import { AppLayout, LibraryItemCard, PageContent } from '@common/components';
import { useFuseState, useLoadMoreQuery } from '@common/hooks';
import { useTypedFlags } from '@features/launch-darkly';
import {
  LibraryItemMetadataType,
  LibraryPageLibraryItemFragment,
  useLibraryPageQuery,
} from '@generated/graphql-code-generator';
import { Icon } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import {
  Box,
  CheckBox,
  Grid,
  Heading,
  InfiniteScroll,
  Select,
  Text,
  TextInput,
} from 'grommet';
import { compact, intersection, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

type FilterSelectOptionsProps = Readonly<{
  checked: boolean;
  option: string;
}>;

const FilterSelectOption: React.FC<FilterSelectOptionsProps> = ({
  checked,
  option,
}) => {
  return (
    <Box
      align="center"
      data-testid="library-page_filter-option"
      direction="row"
      gap="small"
    >
      <CheckBox
        checked={checked}
        data-testid={`library-page_${option}-checkbox`}
      />
      {option}
    </Box>
  );
};

/**
 * Renders the library page.
 */
export const LibraryPage: React.FC = () => {
  const [t] = useTranslation('pages/LibraryPage');
  const [selectedSolutions, setSelectedSolutions] = useState([] as string[]);
  const [selectedTechnologies, setSelectedTechnologies] = useState(
    [] as string[]
  );

  const {
    'tenant-ui-polling-configuration': pollingConfig,
    'library-filtering': libraryFilteringEnabled,
  } = useTypedFlags();

  // Determine template types that should be loaded
  const types: LibraryItemMetadataType[] = [
    LibraryItemMetadataType.Automation,
    LibraryItemMetadataType.Dashboard,
    LibraryItemMetadataType.DataSet,
  ];

  type SearchItemWithPublisher = LibraryPageLibraryItemFragment & {
    publisherName: string;
    publisherDisplayName: string;
  };

  const { loading, error, fetchMore, data } = useLoadMoreQuery(
    useLibraryPageQuery,
    {
      getPageInfo: data => data?.libraryItems?.pageInfo,
      pollInterval: pollingConfig?.publicLibraryList,
      fetchPolicy: 'cache-and-network',
      variables: {
        first: 1000,
        input: { types },
      },
    }
  );

  const libraryItemsPageInfo = data?.libraryItems?.pageInfo;
  const loadMoreLibraryItems = useMemo(() => {
    if (!libraryItemsPageInfo?.hasNextPage) {
      return undefined;
    }

    return async () => {
      await fetchMore();
    };
  }, [fetchMore, libraryItemsPageInfo]);

  const libraryItems = useMemo(() => {
    return orderBy(
      compact(data?.libraryItems?.edges?.map(edge => edge?.node) ?? []),
      libraryItem => (libraryItem.displayName ?? '').toLowerCase(),
      'asc'
    ).reduce((acc, item) => {
      const itemAndPublisher = {
        ...item,
        // Augment data with properties that can be used by search
        publisherName: item.packageMetadata.publisher.name,
        publisherDisplayName: item.packageMetadata.publisher.displayName,
      };

      if (selectedTechnologies.length === 0 && selectedSolutions.length === 0) {
        acc.push(itemAndPublisher);
      }

      if (
        intersection(item.technologies, selectedTechnologies).length > 0 ||
        intersection(item.solutions, selectedSolutions).length > 0
      ) {
        acc.push(itemAndPublisher);
      }
      return acc;
    }, [] as SearchItemWithPublisher[]);
  }, [data, selectedSolutions, selectedTechnologies]);

  const {
    searchResults: displayedItems,
    searchTerm,
    setSearchTerm,
  } = useFuseState({
    fuseOptions: {
      keys: [
        'displayName',
        'description',
        'publisherName',
        'publisherDisplayName',
      ],
      matchAllTokens: true,
      threshold: 0.3,
      shouldSort: false,
      tokenize: true,
    },
    items: libraryItems,
  });

  /**
   * Callback for searching for items in the Library.
   */
  const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(event.target.value);

  const layout = (children: React.ReactNode) => (
    <AppLayout title={t('library')}>
      <PageContent
        children={children}
        error={error}
        loading={loading && !data}
        loadingLabel={t('loading')}
      />
    </AppLayout>
  );

  if (
    !libraryItems.length &&
    !selectedSolutions.length &&
    !selectedTechnologies.length
  ) {
    return layout(<Box>{t('no-data')}</Box>);
  }

  const renderFilterLabel = (selectedValues: string[], filterType: string) => {
    return (
      <Box fill={true} pad="8px">
        <Text truncate={true}>
          {selectedValues.length > 0
            ? selectedValues.join(', ')
            : t('filter-placeholder', { filterType })}
        </Text>
      </Box>
    );
  };

  return layout(
    <Box align="center" fill={true} gap="small">
      <Box flex={{ grow: 0 }} pad={{ horizontal: 'small' }}>
        <Box
          align="center"
          direction="row"
          gap="small"
          justify="between"
          width="xxlarge"
        >
          {libraryFilteringEnabled && (
            <Box direction="row" gap="small" justify="around">
              <Box width="225px">
                <Select
                  closeOnChange={false}
                  data-testid="library-page_tech-filter-select"
                  multiple={true}
                  onChange={({ value: nextValue }) =>
                    setSelectedTechnologies(nextValue)
                  }
                  options={
                    data?.libraryFilteringMetadata?.availableTechnologies || []
                  }
                  value={selectedTechnologies}
                  valueLabel={renderFilterLabel(
                    selectedTechnologies,
                    t('technology')
                  )}
                >
                  {(option: string) => (
                    <FilterSelectOption
                      checked={selectedTechnologies.indexOf(option) !== -1}
                      option={option}
                    />
                  )}
                </Select>
              </Box>
              <Box width="225px">
                <Select
                  closeOnChange={false}
                  data-testid="library-page_solution-filter-select"
                  multiple={true}
                  onChange={({ value: nextValue }) =>
                    setSelectedSolutions(nextValue)
                  }
                  options={
                    data?.libraryFilteringMetadata?.availableSolutions || []
                  }
                  value={selectedSolutions}
                  valueLabel={renderFilterLabel(
                    selectedSolutions,
                    t('solution')
                  )}
                >
                  {(option: string) => (
                    <FilterSelectOption
                      checked={selectedSolutions.indexOf(option) !== -1}
                      option={option}
                    />
                  )}
                </Select>
              </Box>
            </Box>
          )}

          <Box width="375px">
            <TextInput
              data-testid="library-page_text-input-filter"
              icon={<Icon icon={FaSearch} />}
              onChange={onSearchTermChange}
              placeholder={t('search-placeholder')}
              style={{
                backgroundColor: 'white',
              }}
              value={searchTerm ?? ''}
            />
          </Box>
        </Box>
      </Box>

      <Box align="center" overflow="auto" pad="small" width="100%">
        <Box
          flex={{
            shrink: 0,
          }}
          width="xxlarge"
        >
          {!displayedItems.length && (
            <Box align="center" fill={true} justify="center">
              <Heading level="4">{t('no-results')}</Heading>
              <Text color="dark-2">{t('try-different-filter')}</Text>
            </Box>
          )}

          {!!displayedItems.length && (
            <Grid columns="360px" gap="small">
              {/* TODO (5185): add replace={true} to InfiniteScroll after Grommet issue has been addressed */}
              {/* https://github.com/grommet/grommet/issues/5739 */}
              <InfiniteScroll
                items={displayedItems}
                onMore={loadMoreLibraryItems}
                step={24}
              >
                {(libraryItem: SearchItemWithPublisher) => {
                  const {
                    description,
                    displayName,
                    id,
                    libraryItemType,
                    name,
                    packageMetadata,
                    publisherName,
                    solutionName,
                    hasTenantAccess,
                  } = libraryItem;

                  const {
                    name: packageName,
                    version,
                    publishedOn,
                  } = packageMetadata;

                  return (
                    <Box data-testid="library-page_library-item" key={id}>
                      <LibraryItemCard
                        description={description ?? undefined}
                        displayName={displayName ?? name}
                        hasTenantAccess={hasTenantAccess}
                        name={name}
                        packageName={packageName}
                        publishedOn={parseISO(publishedOn)}
                        publisherDisplayName={
                          packageMetadata.publisher.displayName
                        }
                        publisherName={publisherName}
                        solutionName={solutionName}
                        type={libraryItemType}
                        version={version}
                      />
                    </Box>
                  );
                }}
              </InfiniteScroll>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};
