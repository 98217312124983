import React, { useMemo } from 'react';
import { useAccountStatus, useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { AccountStatus } from '@generated/graphql-code-generator';
import { PropsOf } from '@voleer/types';
import { Alert, Anchor } from '@voleer/ui-kit';
import { Box, Paragraph, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Renders an alert for the free trial.
 *  - Displays day(s) remaining in free trial
 *  - Notifies the user that their free trial expired
 */
export const TrialAlert: React.FC<PropsOf<typeof Alert>> = props => {
  const { remainingTrialDays, trialExpired } = useAccountStatus();
  const { tenant } = useTenantContext();
  const [t] = useTranslation('components/AppLayout');

  const daysRemainingI18nKey = useMemo(() => {
    if (remainingTrialDays === 0) {
      return 'free-trial-alert.days-remaining.last';
    }

    if (remainingTrialDays === 1) {
      return 'free-trial-alert.days-remaining.singular';
    }

    return 'free-trial-alert.days-remaining.plural';
  }, [remainingTrialDays]);

  const isTrial = tenant?.accountStatus === AccountStatus.Trial;
  const isTrialExpired =
    tenant?.accountStatus === AccountStatus.TrialExpired &&
    remainingTrialDays < 0;

  if (tenant === null || (!isTrial && !isTrialExpired)) {
    return null;
  }

  return (
    <Box
      flex={{ shrink: 0 }}
      pad={{ top: 'small', bottom: '0', horizontal: 'small' }}
    >
      <Alert icon={true} status={trialExpired ? 'error' : 'info'} {...props}>
        <Paragraph>
          {trialExpired && (
            <>
              <Trans
                components={{
                  error: <Text color="status-error" />,
                }}
                i18nKey="free-trial-alert.expired"
                t={t}
              />

              <br />

              <Trans
                components={{
                  'contact-link': (
                    <Anchor
                      href={urlFor('contactSales')()}
                      variation="primary"
                    />
                  ),
                }}
                i18nKey="free-trial-alert.upgrade"
                t={t}
              />
            </>
          )}

          {!trialExpired && (
            <Trans
              components={{
                bold: <strong />,
                'contact-link': (
                  <Anchor href={urlFor('contactSales')()} variation="primary" />
                ),
              }}
              i18nKey={daysRemainingI18nKey}
              t={t}
              values={{ remainingTrialDays }}
            />
          )}
        </Paragraph>
      </Alert>
    </Box>
  );
};
