import React from 'react';
import { TemplateConfiguration } from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { LoadingButton, Modal, ModalProps } from '@voleer/ui-kit';
import {
  createShapeValidator,
  createValidator,
  formikValidator,
  isPresent,
} from '@voleer/validators';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormField, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';

type TemplateConfigurationProp = Pick<TemplateConfiguration, 'id'>;

type SaveTemplateConfigurationFormValues = {
  /**
   * The display name for the configuration.
   */
  displayName: string;
};

type SaveTemplateConfigurationOnSubmitValue =
  SaveTemplateConfigurationFormValues & {
    /**
     * The Instance from which the configuration should be saved.
     */
    templateConfiguration: TemplateConfigurationProp;
  };

type SaveTemplateConfigurationOnSubmitFn = (
  value: SaveTemplateConfigurationOnSubmitValue
) => Promise<void> | void;

export type SaveTemplateConfigurationModalProps = Pick<ModalProps, 'open'> & {
  /**
   * The Instance from which the configuration will be saved.
   */
  templateConfiguration: TemplateConfigurationProp;

  /**
   * Function that will be called when the form is submitted.
   */
  onSubmit: SaveTemplateConfigurationOnSubmitFn;

  /**
   * Callback to handle when the user clicks cancel.
   */
  onCancel: () => Promise<void> | void;
};

/**
 * Renders the "Save Configuration" modal form.
 */
export const SaveTemplateConfigurationModal: React.FC<SaveTemplateConfigurationModalProps> =
  ({
    templateConfiguration,
    onCancel,
    onSubmit: originalOnSubmit,
    ...modalProps
  }) => {
    const [t] = useTranslation(
      'features/workflows/components/SaveTemplateConfigurationModal'
    );
    const initialValues: SaveTemplateConfigurationFormValues = {
      displayName: '',
    };

    const validator = formikValidator(
      createShapeValidator<SaveTemplateConfigurationFormValues>({
        displayName: createValidator(
          isPresent,
          t('fields.display-name.error-required')
        ),
      })
    );

    const onSubmit: FormikSubmitFn<SaveTemplateConfigurationFormValues> =
      async ({ displayName }) => {
        await originalOnSubmit({ displayName, templateConfiguration });
      };

    return (
      <Modal
        className="test_save-template-configuration-modal"
        onClickOutside={onCancel}
        onEsc={onCancel}
        {...modalProps}
      >
        <Modal.Header>{t('heading')}</Modal.Header>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validator}
          validateOnBlur={false}
        >
          {({ isSubmitting, isValidating }) => (
            <Form>
              <Modal.Body>
                <Field name="displayName">
                  {(fieldProps: FieldProps<string>) => (
                    <FormField
                      error={fieldProps.meta.error}
                      label={t('fields.display-name.label')}
                    >
                      <TextInput
                        {...fieldProps.field}
                        autoFocus={true}
                        disabled={isSubmitting || isValidating}
                      />
                    </FormField>
                  )}
                </Field>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  disabled={isSubmitting || isValidating}
                  label={t('buttons.cancel.label')}
                  onClick={onCancel}
                />
                <LoadingButton
                  disabled={isSubmitting || isValidating}
                  label={t('buttons.submit.label')}
                  loading={isSubmitting || isValidating}
                  primary={true}
                  type="submit"
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };
