import React from 'react';
import { PageContent } from '@common/components';
import { useDateFormat } from '@common/hooks';
import {
  StepStatus,
  useStepDetailsQuery,
} from '@generated/graphql-code-generator';
import { UnreachableCaseError } from '@voleer/types';
import { formatDistance, parseISO } from 'date-fns';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { StatusMessageMarkdown } from '../StatusMessageMarkdown';
import { DownloadStepLogs, StepHistory } from './components';

export type StepDetailsProps = {
  stepId: string;
};

/**
 * Displays the details for the given step.
 */
export const StepDetails: React.FC<StepDetailsProps> = ({ stepId }) => {
  const [format, { DateFormat }] = useDateFormat();
  const formatDate = (date: Date) => format(date, DateFormat.Long);

  const [t] = useTranslation('features/workflows/components/StepDetails');

  const { data, error, ...query } = useStepDetailsQuery({
    variables: { stepId },
  });

  const loading = !data && query.loading;

  const step = data?.step;

  const layout = (children?: React.ReactNode) => {
    return (
      <PageContent
        empty={!step}
        error={error}
        loading={loading}
        loadingLabel={t('loading.label')}
      >
        {children}
      </PageContent>
    );
  };

  if (loading) {
    return layout();
  }

  if (!step) {
    return layout();
  }

  const workspaceId = step.instance.workspaceId;
  const stepName = step.displayName || step.name;

  return layout(
    <Box flex={{ shrink: 0 }}>
      <Box
        border={{
          side: 'bottom',
          style: 'solid',
          color: 'neutral-1',
        }}
        direction="row"
        pad="medium"
      >
        <Box
          align="center"
          direction="row"
          fill="horizontal"
          flex={{ grow: 1 }}
          justify="between"
        >
          <Box direction="column">
            <Text
              data-voleer-id="workflow-instance__step-details--name"
              size="large"
              weight="bold"
            >
              {stepName}
            </Text>
            {(() => {
              switch (step.status) {
                case StepStatus.Running:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.in-progress')}
                    </Text>
                  );
                case StepStatus.Failed:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.failed')}
                      {step.startedOn && step.completedOn && (
                        <>
                          &nbsp;&#8226;&nbsp;
                          {t('heading.ran-duration', {
                            duration: formatDistance(
                              parseISO(step.startedOn),
                              parseISO(step.completedOn)
                            ),
                          })}
                        </>
                      )}
                      {step.completedOn && (
                        <>
                          &nbsp;&#8226;&nbsp;
                          {formatDate(parseISO(step.completedOn))}
                        </>
                      )}
                    </Text>
                  );
                case StepStatus.Canceled:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.canceled')}
                    </Text>
                  );
                case StepStatus.WaitingForInput:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.waiting-for-input')}
                    </Text>
                  );
                case StepStatus.Scheduled:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.scheduling')}
                    </Text>
                  );
                case StepStatus.Completed:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.completed')}
                      {step.startedOn && step.completedOn && (
                        <>
                          &nbsp;&#8226;&nbsp;
                          {t('heading.ran-duration', {
                            duration: formatDistance(
                              parseISO(step.startedOn),
                              parseISO(step.completedOn)
                            ),
                          })}
                        </>
                      )}
                      {step.completedOn && (
                        <>
                          &nbsp;&#8226;&nbsp;
                          {formatDate(parseISO(step.completedOn))}
                        </>
                      )}
                    </Text>
                  );
                case StepStatus.Unknown:
                  return (
                    <Text color="dark-2" size="small">
                      {t('heading.status.unknown')}
                    </Text>
                  );
                default:
                  throw new UnreachableCaseError(step.status);
              }
            })()}
          </Box>
          {step.hasLogs && (
            <Box flex={false}>
              <DownloadStepLogs step={step} workspaceId={workspaceId} />
            </Box>
          )}
        </Box>
      </Box>
      {!!step.taskSummary && (
        <Box
          border={{ side: 'bottom', style: 'solid', color: 'neutral-1' }}
          data-testid="step-details__task_summary"
          justify="stretch"
          overflow="auto"
          pad={{ horizontal: 'large', vertical: 'medium' }}
        >
          <StatusMessageMarkdown content={step.taskSummary} />
        </Box>
      )}
      <Box overflow="auto" pad={{ horizontal: 'small', vertical: 'medium' }}>
        <StepHistory stepId={stepId} />
      </Box>
    </Box>
  );
};
