import React, { useEffect, useRef } from 'react';
import { usePingMutation } from '@generated/graphql-code-generator';

const PING_INTERVAL = 30000;

/**
 * Component that setup a polling on the Ping mutation.
 */
export const PingPolling: React.FC = () => {
  const [pingMutation] = usePingMutation();
  const timeoutRef = useRef<number | undefined>();

  // Setup the polling of the ping mutation
  useEffect(() => {
    // Schedule a recurring ping call
    const startPolling = async () => {
      await pingMutation().catch(() => {
        // Ignore failures so we can try again in the next polling interval
      });
      timeoutRef.current = setTimeout(startPolling, PING_INTERVAL);
    };

    startPolling();

    // Return a cleanup function
    return () => clearTimeout(timeoutRef.current);
  }, [pingMutation]);

  return <></>;
};
