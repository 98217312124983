import React from 'react';
import { LoadingOverlay } from '@common/components';
import { Dashboard } from '@generated/graphql-code-generator';
import { Alert, ErrorButton, Modal, ModalProps } from '@voleer/ui-kit';
import { Button, Paragraph } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';

export type DeleteDashboardModalProps = Pick<ModalProps, 'open'> & {
  dashboard?: Pick<Dashboard, 'displayName' | 'id'>;
  error?: Error | string;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

/**
 * Modal to confirm deleting a Dashboard.
 */
export const DeleteDashboardModal: React.FC<DeleteDashboardModalProps> = ({
  dashboard,
  error,
  loading,
  onClose,
  onConfirm,
  open,
}) => {
  const [t] = useTranslation(
    'features/dashboards/components/DeleteDashboardModal'
  );

  const errorMessage = error instanceof Error ? error.message : error;

  return (
    <Modal
      data-testid="delete-dashboard-modal"
      onClickOutside={onClose}
      onEsc={onClose}
      onPressEnter={onConfirm}
      open={open}
    >
      <LoadingOverlay loading={loading}>
        <Modal.Header>{t('title')}</Modal.Header>
        <Modal.Body>
          <>
            {errorMessage && (
              <Alert
                data-testid="delete-dashboard-modal__error-alert"
                icon={true}
                margin={{ top: 'small' }}
                status="error"
              >
                {errorMessage}
              </Alert>
            )}
            <Paragraph>
              <Trans
                components={{ 'dashboard-name': <strong /> }}
                i18nKey="are-you-sure"
                t={t}
                values={{ dashboardName: dashboard?.displayName }}
              />
            </Paragraph>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="delete-dashboard-modal__close-button"
            label={t('buttons.cancel.label')}
            onClick={onClose}
          />
          <ErrorButton
            data-testid="delete-dashboard-modal__confirm-button"
            label={t('buttons.confirm.label')}
            onClick={onConfirm}
          />
        </Modal.Footer>
      </LoadingOverlay>
    </Modal>
  );
};
