import React, { useMemo } from 'react';
import { PropsOf, isElementOf } from '@voleer/types';
import { Tab, TabProps, Tabs, TabsProps } from 'grommet';
import { StringParam, useQueryParam } from 'use-query-params';

type RouteTabsProps = Omit<PropsOf<typeof Tabs>, 'activeIndex' | 'onActive'> & {
  /**
   * The name of the query param to track which tab is active.
   *
   * Defaults to "tab".
   */
  queryParam?: string;
};

/**
 * `RouteTabs` is an extension of Grommet's `Tabs` component which tracks the
 * active tab selection in a query parameter in the URL.
 *
 * Example with default settings:
 *
 * ```typescript
 * <RouteTabs queryParam="tab">
 *   <RouteTab
 *     queryParamValue="0"
 *     title="Tab #0"
 *   >
 *     Tab Content
 *   </RouteTab>
 *   <RouteTab
 *     queryParamValue="1"
 *     title="Tab #1"
 *   >
 *     Tab Content
 *   </RouteTab>
 * </RouteTabs>
 * ```
 */
export const RouteTabs: React.FC<RouteTabsProps> = ({
  children,
  queryParam,
  ...tabsProps
}) => {
  const [tab, setTab] = useQueryParam(queryParam || 'tab', StringParam);

  const { namesToIndexes, indexesToNames } = useMemo(() => {
    const namesMap = new Map<string, number>();
    const indexesMap = new Map<number, string>();
    React.Children.forEach(children, (child, index) => {
      if (isElementOf(RouteTab, child)) {
        indexesMap.set(index, child.props.queryParamValue ?? index.toString());
        namesMap.set(child.props.queryParamValue ?? index.toString(), index);
      }
    });
    return { namesToIndexes: namesMap, indexesToNames: indexesMap };
  }, [children]);

  const onActive: TabsProps['onActive'] = index => {
    const name = indexesToNames.get(index);
    if (name && tab !== name) {
      setTab(name);
    }
  };

  const tabIndexes = [...namesToIndexes.values()];
  const activeIndex = (tab && namesToIndexes.get(tab)) || tabIndexes[0];

  return (
    <Tabs {...tabsProps} activeIndex={activeIndex} onActive={onActive}>
      {children}
    </Tabs>
  );
};

type RouteTabProps = PropsOf<typeof Tab> & {
  /**
   * The query param value that will be in the URL when the tab is active.
   *
   * Defaults to the numeric index of the tab.
   */
  queryParamValue?: string;
};

/**
 * Renders a tab in the `RouteTabs` component.
 */
export const RouteTab: React.FC<RouteTabProps> = ({
  queryParamValue,
  ...tabProps
}) => {
  return <Tab {...(tabProps as TabProps)} />;
};
