import React from 'react';
import { FormLinkFragment } from '@generated/graphql-code-generator';
import { Anchor } from '@voleer/ui-kit';
import { Box } from 'grommet';

type LinkProps = {
  definition: FormLinkFragment;
};

/**
 * Renders a link component for a workflow form.
 */
export const Link: React.FC<LinkProps> = ({ definition }) => (
  <Box pad="small">
    <Anchor
      href={definition.href}
      label={definition.label}
      target="__blank"
      variation="primary"
    />
  </Box>
);
