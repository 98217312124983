import React from 'react';
import { Area, AreaContent, AreaHeading } from '@voleer/ui-kit';
import { Box, Grid } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Prop types for the `DetailsSection` component.
 */
export type DetailsSectionProps = Readonly<{
  /**
   * Content to be placed in the left column of the grid.
   */
  leftColumnContent: React.ReactElement;

  /**
   * Content to be placed in the right column of the grid.
   */
  rightColumnContent: React.ReactElement;
}>;

/**
 * Renders a "Details" section with a 2-column grid layout.
 *
 * Example:
 *
 * ```typescript
 * <DetailsSection
 *    leftColumnContent={
 *      <React.Fragment>
 *        <DetailsSectionTitle>Name</DetailsSectionTitle>
 *        <DetailsSectionValue>Import from Zendesk</DetailsSectionValue>
 *
 *        <DetailsSectionTitle>Created by</DetailsSectionTitle>
 *        <DetailsSectionDateInfo date={dateCreated} name={createdBy} />
 *      </React.Fragment>
 *    }
 *    rightColumnContent={
 *      <React.Fragment>
 *       <DetailsSectionTitle>Type</DetailsSectionTitle>
 *       <DetailsSectionValue>Dataset</DetailsSectionValue>
 *
 *        <DetailsSectionTitle>Version</DetailsSectionTitle>
 *        <DetailsSectionValue>0.1.22</DetailsSectionValue>
 *      </React.Fragment>
 *    }
 * />
 * ```
 */
export const DetailsSection: React.FC<DetailsSectionProps> = ({
  leftColumnContent,
  rightColumnContent,
}) => {
  const [t] = useTranslation('features/workflows/components/DetailsSection');

  return (
    <Area fill="vertical" paper={true}>
      <AreaHeading label={t('heading')} />

      <AreaContent pad="medium">
        <Grid columns={['60%', 'auto']}>
          <Box pad={{ right: 'small' }}>{leftColumnContent}</Box>
          <Box>{rightColumnContent}</Box>
        </Grid>
      </AreaContent>
    </Area>
  );
};
