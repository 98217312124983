import React, { useContext } from 'react';
import { useDateFormat } from '@common/hooks';
import { urlFor } from '@common/utils';
import {
  DetailsSection,
  DetailsSectionDateInfo,
  DetailsSectionTitle,
  DetailsSectionValue,
  InstanceListItem,
  useWorkflowTranslations,
} from '@features/workflows';
import { useTemplateConfigurationPageQuery } from '@generated/graphql-code-generator';
import { Area, AreaContent, AreaHeading, Link } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Grid, GridProps, ResponsiveContext } from 'grommet';
import { useTranslation } from 'react-i18next';

const GridLayout: React.FC = props => {
  const size = useContext(ResponsiveContext);

  // Two-column layout by default
  const defaultColumns = ['40%', 'auto'];

  // Single column layout for smaller breakpoints
  const columns: Record<string, GridProps['columns']> = {
    small: ['auto'],
    medium: ['auto'],
  };

  return (
    <Grid
      columns={columns[size] || defaultColumns}
      gap="medium"
      pad="medium"
      {...props}
    />
  );
};

type SummaryTabProps = {
  workspaceId: string;
  templateConfigurationId: string;
};

/**
 * Renders the summary tab of the Template Configuration page.
 */
export const SummaryTab: React.FC<SummaryTabProps> = ({
  workspaceId,
  templateConfigurationId,
}) => {
  const [t] = useTranslation('pages/TemplateConfigurationPage');

  const { getLaunchedByName } = useWorkflowTranslations();

  const [format, { DateFormat }] = useDateFormat();

  const templateConfigurationQuery = useTemplateConfigurationPageQuery({
    variables: {
      id: templateConfigurationId,
    },
  });

  const templateConfiguration =
    templateConfigurationQuery.data?.templateConfiguration;
  const templateMetadata = templateConfiguration?.templateMetadata;
  const instances = templateConfiguration?.recentInstances?.items;

  if (!templateConfiguration) {
    return null;
  }

  return (
    <Box fill={true} overflow="auto">
      <GridLayout>
        <DetailsSection
          leftColumnContent={
            <>
              <DetailsSectionTitle>{t('details.name')}</DetailsSectionTitle>
              <DetailsSectionValue>
                {templateConfiguration.displayName}
              </DetailsSectionValue>

              <DetailsSectionTitle>{t('details.template')}</DetailsSectionTitle>
              {templateMetadata ? (
                <Link
                  to={urlFor('libraryItemById')({
                    itemId: templateMetadata.id,
                  })}
                  variation="primary"
                >
                  <DetailsSectionValue>
                    {templateMetadata.displayName}
                  </DetailsSectionValue>
                </Link>
              ) : (
                '-'
              )}

              <DetailsSectionTitle>
                {t('details.created-by')}
              </DetailsSectionTitle>
              <DetailsSectionDateInfo
                date={format(
                  parseISO(templateConfiguration.createdOn),
                  DateFormat.Long
                )}
                name={getLaunchedByName(templateConfiguration.createdBy) ?? ''}
              />
            </>
          }
          rightColumnContent={
            <>
              <DetailsSectionTitle>{t('details.type')}</DetailsSectionTitle>
              <DetailsSectionValue>
                {t('details.automation')}
              </DetailsSectionValue>

              <DetailsSectionTitle>{t('details.version')}</DetailsSectionTitle>
              <DetailsSectionValue>
                {templateMetadata?.packageMetadata.version}
              </DetailsSectionValue>
            </>
          }
        />

        <Box>
          <Area paper={true}>
            <AreaHeading label={t('recent-runs.heading')} />
            <AreaContent pad="none">
              <Box flex="grow">
                {instances?.map(
                  instance =>
                    instance && (
                      <InstanceListItem
                        instance={instance}
                        key={instance.id}
                        workspaceId={workspaceId}
                      />
                    )
                )}
              </Box>
            </AreaContent>
          </Area>
        </Box>
      </GridLayout>
    </Box>
  );
};
