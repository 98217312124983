import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  AppLayout,
  PageContent,
  RouteTab,
  RouteTabs,
  TextInputHeading,
  useTextInputHeading,
} from '@common/components';
import { urlFor } from '@common/utils';
import {
  FindWorkspacesDocument,
  useEditWorkspaceMutation,
  useFindWorkspaceQuery,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import {
  WorkspaceDashboardsTab,
  WorkspaceDataSetsTab,
  WorkspaceFilesTab,
  WorkspaceRunsTab,
  WorkspaceScheduledJobsTab,
  WorkspaceTemplateConfigurationsTab,
} from './components';

export type WorkspaceRouteParams = {
  workspaceId: string;
};

type WorkspacePageProps = RouteComponentProps<WorkspaceRouteParams> & {
  // Nothing here yet
};

/**
 * Renders the page for a workspace.
 */
export const WorkspacePage: React.FC<WorkspacePageProps> = ({ match }) => {
  const [t] = useTranslation('pages/WorkspacePage');
  const { workspaceId } = match.params;

  const workspaceResponse = useFindWorkspaceQuery({
    variables: { id: workspaceId },
  });

  const [renameWorkspace, renameWorkspaceMutation] = useEditWorkspaceMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(FindWorkspacesDocument)!],
  });

  const workspace = workspaceResponse.data?.workspace;

  const { textInputHeadingProps } = useTextInputHeading({
    initialValue: workspace?.displayName,
    onSave: async (value: string) => {
      if (!workspace?.id) {
        return;
      }

      await renameWorkspace({
        variables: {
          input: {
            displayName: value,
            workspaceId: workspace.id,
          },
        },
      });
    },
  });

  const title = workspace ? (
    <TextInputHeading
      {...textInputHeadingProps}
      disabled={renameWorkspaceMutation.loading}
    />
  ) : undefined;

  const layout = (children?: React.ReactNode) => (
    <AppLayout
      breadcrumbs={[
        {
          title: t('workspaces'),
          to: urlFor('workspaces')(),
        },
      ]}
      title={title}
    >
      <PageContent
        children={children}
        error={workspaceResponse.error?.message}
        loading={workspaceResponse.loading}
        loadingLabel={t('loading')}
      />
    </AppLayout>
  );

  if (workspaceResponse.loading) {
    return layout();
  }

  if (!workspace) {
    return layout(<Box>{t('no-data')}</Box>);
  }

  return layout(
    <Box fill={true} gap="medium" pad={{ vertical: 'medium' }}>
      <RouteTabs justify="start">
        <RouteTab queryParamValue="runs" title={t('tabs.runs')}>
          <WorkspaceRunsTab workspace={workspace} />
        </RouteTab>
        <RouteTab
          queryParamValue="template-configurations"
          title={t('tabs.template-configurations')}
        >
          <WorkspaceTemplateConfigurationsTab workspace={workspace} />
        </RouteTab>
        <RouteTab
          queryParamValue="scheduled-jobs"
          title={t('tabs.scheduled-jobs')}
        >
          <WorkspaceScheduledJobsTab workspace={workspace} />
        </RouteTab>
        <RouteTab queryParamValue="files" title={t('tabs.files')}>
          <WorkspaceFilesTab workspace={workspace} />
        </RouteTab>
        <RouteTab queryParamValue="datasets" title={t('tabs.datasets')}>
          <WorkspaceDataSetsTab workspace={workspace} />
        </RouteTab>
        <RouteTab queryParamValue="dashboards" title={t('tabs.dashboards')}>
          <WorkspaceDashboardsTab workspace={workspace} />
        </RouteTab>
      </RouteTabs>
    </Box>
  );
};
