import React from 'react';
import { useAccountStatus } from '@common/hooks';
import { Workspace } from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { FormField, FormFieldProps, Select, SelectProps } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Represents a Workspace option in the `WorkspaceSelector` component.
 */
export type WorkspaceSelectorOption = Pick<Workspace, 'displayName' | 'id'>;

/**
 * Prop types for the `WorkspaceSelector` component.
 */
export type WorkspaceSelectorProps = Readonly<
  SelectProps & {
    /**
     * Whether or not the rendered Select is in a disabled state
     */
    disabled?: boolean;

    formFieldProps?: FormFieldProps & { 'data-testid'?: string };

    /**
     * Whether or not the rendered Select is in a loading state
     */
    loading: boolean;

    /**
     * Callback that is invoked at the end of the Select onChange handler
     */
    onAfterChange?: (
      event: GrommetSelectEvent<WorkspaceSelectorOption>
    ) => void;

    /**
     * The options to be displayed in the rendered Select
     */
    options: WorkspaceSelectorOption[];

    /**
     * Callback for setting the selected workspace
     */
    setWorkspace: (workspace?: WorkspaceSelectorOption) => void;

    /**
     * The selected workspace
     */
    workspace?: WorkspaceSelectorOption;
  }
>;

/**
 * Renders a select dropdown with the user's workspaces as options.
 */
export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({
  disabled,
  formFieldProps,
  loading,
  onAfterChange,
  options = [],
  setWorkspace,
  workspace,
  ...props
}) => {
  const [t] = useTranslation('pages/LibraryItemPage/WorkspaceSelector');

  const { disableWriteOperation } = useAccountStatus();

  const onChange = (event: GrommetSelectEvent<WorkspaceSelectorOption>) => {
    setWorkspace(event.value);
    onAfterChange && onAfterChange(event);
  };

  return (
    <FormField
      data-testid="workspace-selector__form-field"
      data-voleer-id="workspace-selector"
      label={t('field-label')}
      {...formFieldProps}
    >
      <Select
        data-testid="workspace-selector__select"
        data-voleer-id="workspace-selector__select"
        disabled={disableWriteOperation || loading || disabled}
        labelKey="displayName"
        onChange={onChange}
        options={options}
        placeholder={loading ? t('loading-workspaces') : t('placeholder')}
        value={loading ? undefined : workspace}
        valueKey="id"
        {...props}
      />
    </FormField>
  );
};
