import React from 'react';
import { useFormikContext } from 'formik';
import { Box, FormField } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  FormLabel,
  MonthsSelect,
  NumericInput,
  ScheduleTypeSelect,
  TimeInput,
} from '../../components';
import { ScheduledJobFormValues } from '../../interface';

/**
 * Renders the "weekly" schedule form fields.
 */
export const MonthlyFields: React.FC = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const formik = useFormikContext<ScheduledJobFormValues>();

  const getField = (key: keyof ScheduledJobFormValues) => ({
    props: formik.getFieldProps(key),
    meta: formik.getFieldMeta(key),
    helpers: formik.getFieldHelpers(key),
  });

  return (
    <>
      <FormLabel>{t('fields.schedule-type.label')}</FormLabel>
      <ScheduleTypeSelect {...getField('type').props} />

      <FormLabel>{t('fields.day-of-month.label')}</FormLabel>
      <FormField error={getField('dayOfMonth').meta.error} margin="none">
        <Box width={{ min: '100px', max: '16%' }}>
          <NumericInput maxLength={2} {...getField('dayOfMonth').props} />
        </Box>
      </FormField>

      <FormLabel>{t('fields.month.label')}</FormLabel>
      <FormField error={getField('months').meta.error} margin="none">
        <MonthsSelect
          {...getField('months').props}
          onChange={value => formik.setFieldValue('months', value)}
        />
      </FormField>

      <FormLabel>{t('fields.at-time.label')}</FormLabel>
      <FormField error={getField('atTime').meta.error} margin="none">
        <TimeInput {...getField('atTime').props} />
      </FormField>
    </>
  );
};
