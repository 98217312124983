import React from 'react';
import { useDateFormat } from '@common/hooks';
import { PropsOf } from '@voleer/types';
import { Box, Text } from 'grommet';

export type PackageVersionRowProps = PropsOf<typeof Text> & {
  publishedOn?: Date;
  version?: string;
  tenantDisplayName?: string;
  tenantName?: string;
};

/**
 * A bullet that is displayed between each of the package's metadata.
 */
const BulletDelimiter: React.FC<PropsOf<typeof Text>> = props => (
  <Box flex={{ shrink: 0 }}>
    <Text {...props} margin={{ horizontal: 'xsmall' }}>
      &#8226;
    </Text>
  </Box>
);

/**
 * Row for displaying package version information.
 */
export const PackageVersionRow: React.FC<PackageVersionRowProps> = ({
  publishedOn,
  tenantDisplayName,
  tenantName,
  version,
  ...props
}) => {
  const [format, { DateFormat }] = useDateFormat();

  const name =
    tenantDisplayName !== tenantName
      ? `${tenantDisplayName} (${tenantName})`
      : tenantDisplayName;

  return (
    <Box direction="row" justify="start">
      <Box>
        <Text {...props} title={name} truncate={true}>
          {name}
        </Text>
      </Box>
      {version && (
        <Box direction="row" flex={{ shrink: 0 }}>
          <BulletDelimiter {...props} />
          <Text {...props}>{version}</Text>
        </Box>
      )}
      {publishedOn && (
        <>
          <Box direction="row" flex={{ shrink: 0 }}>
            <BulletDelimiter {...props} />
            <Text {...props}>
              {format(publishedOn, DateFormat.AbbreviatedHumanized)}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};
