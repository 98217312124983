import { Box } from 'grommet';
import styled from 'styled-components';

/**
 * Renders a container that "sticks" to the top of the viewport when the user
 * scrolls down.
 */
export const StickyContainer = styled(Box)`
  position: sticky;
  top: 24px;
`;
