import React, { useMemo } from 'react';
import { Box, CheckBox } from 'grommet';
import { useTranslation } from 'react-i18next';

type DayOfWeekOption = {
  /**
   * Number representing the day of the week, numbered 0-6.
   */
  value: number;

  /**
   * The i18n key for the option label.
   */
  i18nKey: string;
};

const DAY_OF_WEEK_OPTIONS: DayOfWeekOption[] = [
  { value: 0, i18nKey: 'sunday' },
  { value: 1, i18nKey: 'monday' },
  { value: 2, i18nKey: 'tuesday' },
  { value: 3, i18nKey: 'wednesday' },
  { value: 4, i18nKey: 'thursday' },
  { value: 5, i18nKey: 'friday' },
  { value: 6, i18nKey: 'saturday' },
];

export type DayOfWeekSelectProps = {
  /**
   * Array containing the days that are selected.
   */
  value?: number[];

  /**
   * Called with an array of selected days of week when the selected days
   * change.
   */
  onChange: (value: number[]) => void;
};

/**
 * Renders checkboxes for selecting the day(s) of the week when a schedule
 * should run.
 */
export const DayOfWeekSelect: React.FC<DayOfWeekSelectProps> = props => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const onChange =
    (option: DayOfWeekOption) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const selected = new Set(props.value);

      if (checked) {
        selected.add(option.value);
      } else {
        selected.delete(option.value);
      }

      props.onChange([...selected].sort());
    };

  const selectedValues = useMemo(() => {
    return (props.value ?? []).reduce((acc, value) => {
      acc[value] = true;
      return acc;
    }, {} as Record<number, boolean>);
  }, [props.value]);

  return (
    <Box direction="row" gap="small" pad="xsmall" wrap={true}>
      {DAY_OF_WEEK_OPTIONS.map(option => (
        <Box key={option.i18nKey}>
          <CheckBox
            checked={!!selectedValues[option.value]}
            label={t(`fields.day-of-week.value-labels.${option.i18nKey}`)}
            name={`day-of-week_${option.value}`}
            onChange={onChange(option)}
          />
        </Box>
      ))}
    </Box>
  );
};
