import React from 'react';
import {
  DataSetStatus,
  InstanceStatus,
} from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';
import * as instanceStatusBadge from '../InstanceStatusBadge';

const statusMapping: Record<DataSetStatus, BadgeStatus> = {
  [DataSetStatus.Ready]: BadgeStatus.ok,
  [DataSetStatus.Pending]: BadgeStatus.info,
  [DataSetStatus.Unknown]: BadgeStatus.unknown,
  [DataSetStatus.Provisioning]: BadgeStatus.info,
  [DataSetStatus.Invalid]: BadgeStatus.error,
  [DataSetStatus.Deleting]: BadgeStatus.info,
  [DataSetStatus.Deleted]: BadgeStatus.error,
};

const i18nMapping: Record<DataSetStatus, string> = {
  [DataSetStatus.Ready]: 'status-ready',
  [DataSetStatus.Pending]: 'status-pending',
  [DataSetStatus.Unknown]: 'status-unknown',
  [DataSetStatus.Provisioning]: 'status-provisioning',
  [DataSetStatus.Invalid]: 'status-invalid',
  [DataSetStatus.Deleting]: 'status-deleting',
  [DataSetStatus.Deleted]: 'status-deleted',
};

type DataSetStatusBadgeProps = Omit<BadgeProps, 'label' | 'status'> & {
  status: DataSetStatus;
  instanceStatus?: InstanceStatus;
};

/**
 * Renders a badge to display the given Dataset status.
 */
export const DataSetStatusBadge: React.FC<DataSetStatusBadgeProps> = ({
  instanceStatus,
  status,
  ...props
}) => {
  const [tDataSet] = useTranslation(
    'features/workflows/components/DataSetStatusBadge'
  );
  const [tInstance] = useTranslation(
    'features/workflows/components/InstanceStatusBadge'
  );

  const useInstanceBadge = status === DataSetStatus.Pending && !!instanceStatus;
  const label = useInstanceBadge
    ? tInstance(
        instanceStatusBadge.i18nMapping[instanceStatus as InstanceStatus]
      )
    : tDataSet(i18nMapping[status]);
  const badgeStatus = useInstanceBadge
    ? instanceStatusBadge.statusMapping[instanceStatus as InstanceStatus]
    : statusMapping[status];

  return <Badge {...props} label={label} status={badgeStatus} />;
};
