import React from 'react';
import { urlFor } from '@common/utils';
import { DataSet } from '@generated/graphql-code-generator';
import { Link } from '@voleer/ui-kit';
import { Box, BoxProps, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';

export type ConfigurationPageSubtitleProps = Readonly<
  BoxProps & {
    dataSet?:
      | Pick<DataSet, 'displayName' | 'id' | 'workspaceId'>
      | null
      | undefined;
    metadataDisplayName?: string;
    statusBadge: React.ReactNode;
    version?: string;
  }
>;

/**
 * Renders the subtitle for a configuration page.
 *
 * Example:
 * Ready  |  Import from Zendesk @ 0.1.23
 *
 * Example for an Automation Template that uses a Dataset:
 * Ready  |  Import from Zendesk @ 0.1.23
 *           Using the {dataSetDisplayName} Dataset
 */
export const ConfigurationPageSubtitle: React.FC<ConfigurationPageSubtitleProps> =
  ({ dataSet, metadataDisplayName, statusBadge, version, ...boxProps }) => {
    const [t] = useTranslation(
      'features/workflows/components/ConfigurationPageSubtitle'
    );

    return (
      <Box
        data-testid="configuration-page-subtitle"
        direction="row"
        gap="small"
        {...boxProps}
      >
        {!!statusBadge && (
          <Box margin={{ vertical: 'auto' }}>{statusBadge}</Box>
        )}

        {(!!version || !!dataSet) && (
          <Box direction="column">
            {!!version && !!metadataDisplayName && (
              <Text
                data-testid="configuration-page-subtitle__metadata-display-name-at-version"
                size="small"
              >
                {t('metadata-display-name-at-version', {
                  metadataDisplayName,
                  version,
                })}
              </Text>
            )}

            {!!dataSet && (
              <Text margin={{ top: version ? '-4px' : 'none' }} size="small">
                <Trans
                  components={{
                    'dataset-link': (
                      <Link
                        data-testid="configuration-page-subtitle__dataset-link"
                        to={urlFor('dataSet')({
                          dataSetId: dataSet.id,
                          workspaceId: dataSet.workspaceId,
                        })}
                        variation="primary"
                      />
                    ),
                  }}
                  i18nKey="using-dataset"
                  t={t}
                  values={{
                    dataSetDisplayName: dataSet.displayName,
                  }}
                />
              </Text>
            )}
          </Box>
        )}
      </Box>
    );
  };
