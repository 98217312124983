import React from 'react';
import { PropsOf } from '@voleer/types';
import { MaskedInput, MaskedInputProps } from 'grommet';

export type NumericInputProps = Omit<PropsOf<typeof MaskedInput>, 'mask'>;

/**
 * Renders a masked input that only allows numbers to be entered.
 */
export const NumericInput: React.FC<NumericInputProps> = ({ ...props }) => (
  <MaskedInput
    mask={[{ regexp: /^[0-9]+$/ }]}
    {...(props as MaskedInputProps)}
  />
);
