import { useMemo, useState } from 'react';
import { useTemplateConfigurationSelectorQuery } from '@generated/graphql-code-generator';
import { compact, sortBy } from 'lodash';
import {
  TemplateConfigurationSelectorOption,
  TemplateConfigurationSelectorProps,
} from '.';

type UseTemplateConfigurationSelectorOptions = {
  /**
   * The selected workspace id
   */
  workspaceId?: string;

  /**
   * The TemplateMetadata id of the options to display.
   */
  templateMetadataId?: string;
};

type UseTemplateConfigurationSelectorResult = {
  /**
   * The options available.
   */
  options: TemplateConfigurationSelectorOption[];

  /**
   * Props to pass to the `TemplateConfigurationSelector`.
   */
  props: TemplateConfigurationSelectorProps;

  /**
   * The currently selected option, if any.
   */
  templateConfiguration?: TemplateConfigurationSelectorOption;
};

/**
 * Hook for adding TemplateConfigurationSelector logic to parent components.
 * Provides logic for loading TemplateConfigurations, tracking the selected
 * TemplateConfiguration, and setting the selected TemplateConfiguration.
 */
export const useTemplateConfigurationSelector = ({
  workspaceId,
  templateMetadataId,
}: UseTemplateConfigurationSelectorOptions): UseTemplateConfigurationSelectorResult => {
  const [selectedTemplateConfiguration, setSelectedTemplateConfiguration] =
    useState<TemplateConfigurationSelectorOption>();

  const { data, loading } = useTemplateConfigurationSelectorQuery({
    skip: !workspaceId,
    variables: {
      // Allow non-null assertion since the query is skipped if workspaceId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      workspaceId: workspaceId!,
      templateMetadataId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const options = useMemo(() => {
    return sortBy(compact(data?.templateConfigurations?.items), tc =>
      tc.displayName?.toLocaleLowerCase()
    );
  }, [data?.templateConfigurations?.items]);

  const templateConfiguration = selectedTemplateConfiguration;

  return {
    options,
    props: {
      loading,
      options,
      setTemplateConfiguration: setSelectedTemplateConfiguration,
      templateConfiguration,
    },
    templateConfiguration,
  };
};
