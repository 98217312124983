import React from 'react';
import { useAccountStatus } from '@common/hooks';
import { LoadingButton, LoadingButtonProps } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';

type LaunchButtonProps = LoadingButtonProps;

/**
 * Renders a button for launching an automation template in a workspace in the `LibraryAutomationPage`.
 */
export const LaunchButton: React.FC<LaunchButtonProps> = ({
  disabled,
  ...props
}) => {
  const [t] = useTranslation('pages/LibraryAutomationPage');

  const { disableWriteOperation } = useAccountStatus();

  return (
    <LoadingButton
      {...props}
      data-testid="launch-button"
      disabled={disableWriteOperation || disabled}
      label={t('launch-template.launch-button.label')}
      loadingLabel={t('launch-template.launch-button.label-launching')}
      primary={true}
    />
  );
};
