import React, { useMemo } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useFuseState } from '@common/hooks';
import { useTypedFlags } from '@features/launch-darkly';
import {
  AddScheduledJobModal,
  CancelScheduledJobModal,
  ScheduledJobProp,
  ScheduledJobsTable,
  ScheduledJobsTableProps,
  useAddScheduledJobModal,
  useCancelScheduledJobModal,
} from '@features/scheduled-jobs';
import { isTemplateConfigurationDeleted } from '@features/workflows';
import {
  Workspace,
  WorkspacePageScheduledJobsDocument,
  useWorkspacePageScheduledJobsQuery,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';

type WorkspaceScheduledJobTabProps = {
  workspace: Pick<Workspace, 'id'>;
};

/**
 * Renders the Scheduled Jobs tab content on the Workspace page.
 */
export const WorkspaceScheduledJobsTab: React.FC<WorkspaceScheduledJobTabProps> =
  ({ workspace }) => {
    const { 'tenant-ui-polling-configuration': pollingConfig } =
      useTypedFlags();

    const { data, loading: queryLoading } = useWorkspacePageScheduledJobsQuery({
      fetchPolicy: 'cache-and-network',
      pollInterval: pollingConfig?.workspaceInstancesLists,
      variables: {
        workspaceId: workspace.id,
      },
    });

    const { props: addModalProps, setOpen: setAddModalOpen } =
      useAddScheduledJobModal({
        workspaceId: workspace.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        refetchQueries: [getOperationName(WorkspacePageScheduledJobsDocument)!],
      });

    const { props: cancelModalProps, open: setCancelModalOpen } =
      useCancelScheduledJobModal({
        workspaceId: workspace.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        refetchQueries: [getOperationName(WorkspacePageScheduledJobsDocument)!],
      });

    const onAdd: ScheduledJobsTableProps['onAdd'] = () => setAddModalOpen(true);

    const onCancel: ScheduledJobsTableProps['onCancel'] = scheduledJob => {
      setCancelModalOpen(scheduledJob);
    };

    const scheduledJobItems = data?.scheduledJobs?.items;
    const scheduledJobs = useMemo(() => {
      if (!scheduledJobItems) return [];

      type EnhancedScheduledJob = ScheduledJobProp & {
        templateMetadataName: string;
        templateConfigurationDisplayName?: string | null;
      };

      return scheduledJobItems.reduce((acc, item) => {
        if (
          !!item &&
          !!item.templateConfiguration.templateMetadata?.displayName &&
          !isTemplateConfigurationDeleted(item.templateConfiguration?.status)
        ) {
          // Augment data with properties that can be used by search
          acc.push({
            ...item,
            templateMetadataName:
              item.templateConfiguration.templateMetadata.displayName,
            templateConfigurationDisplayName:
              item.templateConfiguration.displayName,
          });
        }

        return acc;
      }, [] as EnhancedScheduledJob[]);
    }, [scheduledJobItems]);

    const { searchTerm, setSearchTerm, searchResults } = useFuseState({
      items: scheduledJobs,
      fuseOptions: {
        threshold: 0.3,
        tokenize: true,
        matchAllTokens: true,
        keys: ['templateMetadataName', 'templateConfigurationDisplayName'],
      },
    });

    const onSearch: ScheduledJobsTableProps['onSearch'] = event => {
      setSearchTerm(event.target.value);
    };

    const loading = !data && queryLoading;

    return (
      <Box fill={true} pad={{ horizontal: 'medium', top: 'medium' }}>
        <ScheduledJobsTable
          loading={loading}
          onAdd={onAdd}
          onCancel={onCancel}
          onSearch={onSearch}
          scheduledJobs={searchResults}
          searchTerm={searchTerm}
        />
        <AddScheduledJobModal {...addModalProps} />
        <CancelScheduledJobModal {...cancelModalProps} />
      </Box>
    );
  };
