// Note: The values of these enums are referring to entries in the
// `date-formats` i18n namespace. At the time of this writing this namespace is
// defined in `date-formats.json`.

/**
 * Enum representing available i18n formats for displaying dates as strings.
 */
export enum DateFormat {
  Short = 'date.short',
  Long = 'date.long',
  LongHumanized = 'date.long-humanized',
  Abbreviated = 'date.abbreviated',
  AbbreviatedHumanized = 'date.abbreviated-humanized',
}

/**
 * Enum representing available i18n formats for displaying dates as strings
 * with both date and time.
 */
export enum DateTimeFormat {
  Short = 'datetime.short',
  Long = 'datetime.long',
  Abbreviated = 'datetime.abbreviated',
  AbbreviatedHumanized = 'datetime.abbreviated-humanized',
  RelativeAbbreviated = 'datetime.relative-abbreviated',
}
