import React, { isValidElement } from 'react';
import { useDateFormat } from '@common/hooks';
import {
  StatusMessage,
  StatusMessageLevel,
  Step,
} from '@generated/graphql-code-generator';
import { PropsOf, ensureUnreachable } from '@voleer/types';
import { Box, Diagram, Stack, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from 'react-icons/fa';
import styled from 'styled-components';
import { StatusMessageMarkdown } from '..';
import { ActiveDot, Dot, DotIcon } from './Dot';

const FormattedDate: React.FC<{ dateString: string }> = ({ dateString }) => {
  const [format, { DateTimeFormat }] = useDateFormat();
  const date = new Date(dateString);
  return (
    <Text color="dark-2" size="small">
      {format(date, DateTimeFormat.Long)}
    </Text>
  );
};

type ConnectionProps = {
  color?: string;
  thickness?: string;
  round?: boolean;
  type?: 'curved' | 'direct' | 'rectilinear';
};

const buildConnection = (
  fromTarget: string,
  toTarget: string,
  props: ConnectionProps
) => {
  const defaults = {
    fromTarget,
    toTarget,
    color: 'brand',
    thickness: '1px',
    round: true,
    type: 'rectilinear',
  };

  return Object.assign(defaults, props);
};

type HistoryElementsDiagramProps = PropsOf<typeof Box>;

/**
 * Renders the child history elements in a timeline diagram.
 *
 * Note that each history element must have a unique graph name in order to be
 * connected in the diagram.
 *
 * ```typescript
 * <HistoryElementsDiagram>
 *   <WaitingForInputHistoryElement graphName="waitingInput" />
 *   <StartedHistoryElement graphName="started" step={step} />
 * </HistoryElementsDiagram>
 * ```
 */
export const HistoryElementsDiagram: React.FC<HistoryElementsDiagramProps> = ({
  children,
  ...boxProps
}) => {
  const connectionNames: string[] = [];

  React.Children.forEach(children, child => {
    if (!isValidElement(child)) {
      return;
    }

    if (typeof child.props.graphName !== 'string') {
      return;
    }

    connectionNames.push(child.props.graphName);
  });

  // Build a graph connection between each child history element
  const connections = connectionNames.slice(1).map((connectionName, index) => {
    const fromTarget = connectionNames[index];
    const toTarget = connectionName;
    return buildConnection(fromTarget, toTarget, {
      color: 'neutral-1',
    });
  });

  return (
    <Stack guidingChild="last">
      <Diagram connections={connections} />
      <Box {...boxProps}>{children}</Box>
    </Stack>
  );
};

export type HistoryElementProps = {
  graphName: string;
};

export type StepHistoryElementProps = HistoryElementProps & {
  step: Pick<Step, 'completedOn' | 'createdOn' | 'startedOn'>;
};

/**
 * Displays the "Scheduling" history element for the given step.
 */
export const SchedulingHistoryElement: React.FC<StepHistoryElementProps> = ({
  graphName,
  step,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <ActiveDot id={graphName} key={graphName} />
      </Box>
      <Box direction="column" gap="xsmall" pad={{ left: 'small' }}>
        <FormattedDate dateString={step.createdOn} />
        <Text>{t('step-is-scheduled')}</Text>
      </Box>
    </Box>
  );
};

/**
 * Displays the "WaitingForInput" history element for the given step.
 */
export const WaitingForInputHistoryElement: React.FC<HistoryElementProps> = ({
  graphName,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <ActiveDot id={graphName} key={graphName} />
      </Box>
      <Box direction="column" pad={{ left: 'small' }}>
        <Text>{t('step-is-waiting-for-input')}</Text>
      </Box>
    </Box>
  );
};

/**
 * Displays the "Started" history element for the given step.
 */
export const StartedHistoryElement: React.FC<StepHistoryElementProps> = ({
  step,
  graphName,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <Dot color="neutral-1" id={graphName} key={graphName} />
      </Box>
      <Box direction="column" gap="xsmall" pad={{ left: 'small' }}>
        {step.startedOn && <FormattedDate dateString={step.startedOn} />}
        <Text>{t('step-was-started')}</Text>
      </Box>
    </Box>
  );
};

/**
 * Displays the "Running" history element for the given step.
 */
export const RunningHistoryElement: React.FC<HistoryElementProps> = ({
  graphName,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <ActiveDot id={graphName} key={graphName} />
      </Box>
      <Box direction="column" pad={{ left: 'small' }}>
        <Text>{t('step-is-running')}</Text>
      </Box>
    </Box>
  );
};

/**
 * Displays the "Failed" history element for the given step.
 */
export const FailedHistoryElement: React.FC<StepHistoryElementProps> = ({
  step,
  graphName,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <Dot color="status-error" id={graphName} key={graphName} />
      </Box>
      <Box direction="column" gap="xsmall" pad={{ left: 'small' }}>
        {step.completedOn && <FormattedDate dateString={step.completedOn} />}
        <Text color="status-error">{t('step-has-failed')}</Text>
      </Box>
    </Box>
  );
};

/**
 * Displays the "Completed" history element for the given step.
 */
export const CompletedHistoryElement: React.FC<StepHistoryElementProps> = ({
  step,
  graphName,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <Dot color="green" id={graphName} key={graphName} />
      </Box>
      <Box direction="column" gap="xsmall" pad={{ left: 'small' }}>
        {step.completedOn && <FormattedDate dateString={step.completedOn} />}
        <Text>{t('step-was-completed')}</Text>
      </Box>
    </Box>
  );
};

/**
 * Displays the "Canceled" history element for the given step.
 */
export const CanceledHistoryElement: React.FC<StepHistoryElementProps> = ({
  step,
  graphName,
}) => {
  const [t] = useTranslation('features/workflows/components/HistoryElements');
  return (
    <Box direction="row" pad={{ bottom: 'medium' }}>
      <Box align="center" flex={false} width="16px">
        <Dot color="green" id={graphName} key={graphName} />
      </Box>
      <Box direction="column" gap="xsmall" pad={{ left: 'small' }}>
        {step.completedOn && <FormattedDate dateString={step.completedOn} />}
        <Text>{t('step-was-canceled')}</Text>
      </Box>
    </Box>
  );
};

type StatusMessageDotProps = {
  id: string;
  message: Pick<StatusMessage, 'content' | 'createdUtc' | 'level'>;
};

/**
 * Customized "dot" element for displaying status messages as history items.
 */
const StatusMessageDot: React.FC<StatusMessageDotProps> = ({
  message,
  ...props
}) => {
  switch (message.level) {
    case StatusMessageLevel.Error: {
      return (
        <DotIcon color="status-error" icon={FaExclamationCircle} {...props} />
      );
    }
    case StatusMessageLevel.Warning: {
      return (
        <DotIcon
          color="status-warning"
          icon={FaExclamationTriangle}
          {...props}
        />
      );
    }
    case StatusMessageLevel.Information: {
      return <DotIcon color="status-info" icon={FaInfoCircle} {...props} />;
    }
    case StatusMessageLevel.Unknown: {
      return <Dot {...props} />;
    }
    default: {
      ensureUnreachable(message.level);
      return <Dot {...props} />;
    }
  }
};

/**
 * Styled box to customize the styling for the status message markdown content.
 */
export const StatusMessageContent = styled(Box)`
  // Remove extra padding from first and last elements in markdown content, this
  // way we don't inherit extra padding from, for example, <p> elements in the
  // markdown HTML
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`;

type StatusMessageHistoryElementProps = HistoryElementProps & {
  /**
   * The status message to display.
   */
  message: Pick<StatusMessage, 'content' | 'createdUtc' | 'level'>;

  /**
   * Content to display for the step name.
   */
  stepName?: React.ReactNode | string;
};

/**
 * Renders a `StatusMessage` as a history element.
 */
export const StatusMessageHistoryElement: React.FC<StatusMessageHistoryElementProps> =
  ({ graphName, message, stepName }) => {
    return (
      <Box direction="row" pad={{ bottom: 'medium' }}>
        <Box align="center" flex={false} width="16px">
          <StatusMessageDot id={graphName} key={graphName} message={message} />
        </Box>
        <Box direction="column" gap="xsmall" pad={{ left: 'small' }}>
          {stepName && <Text color="dark-2">{stepName}</Text>}
          <FormattedDate dateString={message.createdUtc} />
          <StatusMessageContent>
            <StatusMessageMarkdown content={message.content} />
          </StatusMessageContent>
        </Box>
      </Box>
    );
  };
