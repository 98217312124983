import React from 'react';
import { AppLayout, PageContent } from '@common/components';
import { useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { TenantMemberRole } from '@generated/graphql-code-generator';
import { Error404, Link } from '@voleer/ui-kit';
import { Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

/**
 * Renders a generic 404 page.
 */
export const Error404Page: React.FC = () => {
  const [t] = useTranslation('pages/Error404Page');

  return (
    <AppLayout>
      <PageContent>
        <Error404 helpContent={<HelpContent />} subheading={t('subheading')} />
      </PageContent>
    </AppLayout>
  );
};

const HelpContentLink = styled(Link)`
  display: block;
  margin: 8px 0;
  text-decoration: underline;
`;

const HelpContent: React.FC = () => {
  const [t] = useTranslation('pages/Error404Page');
  const { tenantMember } = useTenantContext();

  const links = [
    { text: t('help.links.library'), url: urlFor('library')() },
    { text: t('help.links.workspaces'), url: urlFor('workspaces')() },
    { text: t('help.links.integrations'), url: urlFor('integrations')() },
    { text: t('help.links.user-settings'), url: urlFor('userSettings')() },
  ];

  // Show the administration link if the user is an admin
  let renderedLinks = links;
  if (tenantMember?.role === TenantMemberRole.Admin) {
    renderedLinks = [
      ...links,
      {
        text: t('help.links.tenant-settings'),
        url: urlFor('tenantSettings')(),
      },
    ];
  }

  return (
    <Text margin={{ top: 'small' }} size="large">
      {t('help.instructions')}
      <br />
      {renderedLinks.map(link => {
        const { text, url } = link;
        return (
          <HelpContentLink key={url} to={url} variation="secondary">
            {text}
          </HelpContentLink>
        );
      })}
    </Text>
  );
};
