import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  NotificationChannelTypes,
  NotificationSubscriptionSectionDocument,
  NotificationSubscriptionStatus,
  useAddNotificationSubscriptionModalCreateSubscriptionMutation,
} from '@generated/graphql-code-generator';
import { LoadingButton, Modal } from '@voleer/ui-kit';
import { useFormikContext } from 'formik';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  NotificationSubscriptionForm,
  NotificationSubscriptionFormFields,
  NotificationSubscriptionFormValues,
} from '../../components';

type AddNotificationSubscriptionModalProps = Readonly<{
  /**
   * Handler for closing the modal.
   */
  onClose: () => void;
}>;

type AddNotificationSubscriptionModalFooterProps = Readonly<{
  onClose: AddNotificationSubscriptionModalProps['onClose'];
}>;

const AddNotificationSubscriptionModalFooter: React.FC<AddNotificationSubscriptionModalFooterProps> =
  ({ onClose }) => {
    const [t] = useTranslation('pages/NotificationsPage');

    // Get access to the Formik React Context that's rendered in `NotificationSubscriptionForm`
    // https://formik.org/docs/api/useFormikContext#useformikcontext-formikpropsvalues
    const { isSubmitting, isValidating } = useFormikContext();
    const formProcessing = isSubmitting || isValidating;

    return (
      <Modal.Footer>
        <Button
          data-testid="add-notification-subscription-modal__button--close"
          disabled={formProcessing}
          label={t(
            'settings-tab.add-notification-subscription-modal.buttons.cancel'
          )}
          onClick={onClose}
        />
        <LoadingButton
          data-testid="add-notification-subscription-modal__button--submit"
          disabled={formProcessing}
          label={t(
            'settings-tab.add-notification-subscription-modal.buttons.submit'
          )}
          loading={formProcessing}
          primary={true}
          type="submit"
        />
      </Modal.Footer>
    );
  };

/**
 * Renders a Modal form for adding a notification subscription.
 */
export const AddNotificationSubscriptionModal: React.FC<AddNotificationSubscriptionModalProps> =
  ({ onClose }) => {
    const [t] = useTranslation('pages/NotificationsPage');

    const [createNotificationSubscription, { error }] =
      useAddNotificationSubscriptionModalCreateSubscriptionMutation({
        refetchQueries: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getOperationName(NotificationSubscriptionSectionDocument)!,
        ],
      });

    const onSubmit = async (values: NotificationSubscriptionFormValues) => {
      if (!values.runNotificationType) {
        return;
      }

      const channelTypes = [];
      if (values.deliverInApp) {
        channelTypes.push(NotificationChannelTypes.VoleerUi);
      }
      if (values.deliverViaEmail) {
        channelTypes.push(NotificationChannelTypes.Email);
      }

      await createNotificationSubscription({
        variables: {
          input: {
            channelTypes,
            runNotificationType: values.runNotificationType,
            status: NotificationSubscriptionStatus.Active,
            templateConfigurationId: values.templateConfiguration?.id,
            workspaceId: values.workspace?.id,
          },
        },
      });

      onClose();
    };

    return (
      <Modal onClickOutside={onClose} onEsc={onClose} width={{ min: '550px' }}>
        <Modal.Header>
          {t('settings-tab.add-notification-subscription-modal.heading')}
        </Modal.Header>

        <NotificationSubscriptionForm error={error} onSubmit={onSubmit}>
          <NotificationSubscriptionFormFields />
          <AddNotificationSubscriptionModalFooter onClose={onClose} />
        </NotificationSubscriptionForm>
      </Modal>
    );
  };
