import React from 'react';
import { FormDateTimeInputFragment } from '@generated/graphql-code-generator';
import { Anchor, Area, DatePicker, Icon } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import { FormField, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaCalendarDay } from 'react-icons/fa';
import { WORKFLOW_FORM_DATETIME_FORMAT } from '../../utils';
import { InitialValues, LabelMarkdown } from '.';

type DateTimeInputFieldProps = {
  definition: FormDateTimeInputFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form DateTime input field.
 */
export const DateTimeInputField = React.forwardRef<
  HTMLInputElement,
  DateTimeInputFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta, fieldHelpers] = useField<Date | null | undefined>(
    definition.name
  );
  const [t] = useTranslation('features/workflows/forms');

  const setDateInputFieldValue = (inputValue: Date | null | undefined) => {
    fieldHelpers.setTouched(true);
    fieldHelpers.setValue(inputValue);
  };

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  return (
    <FormField
      error={error}
      label={<LabelMarkdown content={definition.label} />}
    >
      <Area ref={ref}>
        <DatePicker
          customInput={
            <TextInput
              data-testid="form-date-time-input__text-input"
              icon={<Icon icon={FaCalendarDay} />}
              name={field.name}
              reverse={true}
            />
          }
          data-testid="form-date-time-input__date-picker"
          dateFormat={WORKFLOW_FORM_DATETIME_FORMAT}
          disabled={disabled || formik.isSubmitting}
          onBlur={() => fieldHelpers.setTouched(true)}
          onChange={date => (date ? setDateInputFieldValue(date) : null)}
          placeholderText={t('date-input-field.text-placeholder')}
          selected={field.value}
          shouldCloseOnSelect={false}
          showPopperArrow={false}
          showTimeSelect={true}
          timeFormat="HH:mm"
          timeIntervals={15}
        />
        {!definition.required && (
          <Area disabled={disabled || formik.isSubmitting}>
            <Anchor
              onClick={() => setDateInputFieldValue(undefined)}
              variation="primary"
            >
              {t('multi-select-field.clear')}
            </Anchor>
          </Area>
        )}
      </Area>
    </FormField>
  );
});
