import React from 'react';
import {
  TemplateConfigurationSelector,
  WorkspaceSelector,
  useTemplateConfigurationSelector,
  useWorkspaceSelector,
} from '@common/components';
import { RunNotificationType } from '@generated/graphql-code-generator';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Box, CheckBox, FormField, Heading, Select } from 'grommet';
import { useTranslation } from 'react-i18next';
import { NotificationSubscriptionFormValues } from '../../components';

/**
 * A t9n mapping of display names in the event type selector rendered by `NotificationSubscriptionFormFields`
 */
export const eventTypeSelectorDisplayNameMapping: Record<
  RunNotificationType,
  string
> = {
  [RunNotificationType.Completed]:
    'features/notifications/components/NotificationSubscriptionFormFields:event-type-display-names.completed',
  [RunNotificationType.Failed]:
    'features/notifications/components/NotificationSubscriptionFormFields:event-type-display-names.failed',
  [RunNotificationType.WaitingForInput]:
    'features/notifications/components/NotificationSubscriptionFormFields:event-type-display-names.waiting',
  [RunNotificationType.None]: '',
};

/**
 * Renders the form fields of the `NotificationSubscriptionForm`.
 */
export const NotificationSubscriptionFormFields: React.FC = () => {
  // https://react.i18next.com/guides/multiple-translation-files
  const [t] = useTranslation([
    'pages/NotificationsPage',
    'features/notifications/components/NotificationSubscriptionFormFields',
  ]);

  const { props: workspaceSelectorProps } = useWorkspaceSelector({
    preSelectOnlyWorkspace: false,
  });

  // Get access to the Formik React Context that's rendered by `NotificationSubscriptionForm`,
  // so that we can pre-populate the selected value in `TemplateConfigurationSelector`.
  // https://formik.org/docs/api/useFormikContext#useformikcontext-formikpropsvalues
  const { values }: { values: NotificationSubscriptionFormValues } =
    useFormikContext();

  const { props: templateConfigurationSelectorProps } =
    useTemplateConfigurationSelector({
      workspaceId: values?.workspace?.id,
    });

  return (
    <>
      <Field name="runNotificationType">
        {(fieldProps: FieldProps) => {
          return (
            <FormField
              data-testid="notification-subscription-form-fields__form-field--run-notification-type"
              error={fieldProps.form.errors.runNotificationType}
              label={t(
                'settings-tab.notification-subscription-form.labels.event-type'
              )}
            >
              <Select
                {...fieldProps.field}
                data-testid="notification-subscription-form-fields__event-type-selector"
                disabled={
                  fieldProps.form.isSubmitting || fieldProps.form.isValidating
                }
                labelKey="label"
                onChange={event => {
                  // Set the formik state
                  fieldProps.form.setFieldValue(
                    'runNotificationType',
                    event.value
                  );
                }}
                options={[
                  {
                    label: t(
                      eventTypeSelectorDisplayNameMapping[
                        RunNotificationType.Completed
                      ]
                    ),
                    value: RunNotificationType.Completed,
                  },
                  {
                    label: t(
                      eventTypeSelectorDisplayNameMapping[
                        RunNotificationType.Failed
                      ]
                    ),
                    value: RunNotificationType.Failed,
                  },
                  {
                    label: t(
                      eventTypeSelectorDisplayNameMapping[
                        RunNotificationType.WaitingForInput
                      ]
                    ),
                    value: RunNotificationType.WaitingForInput,
                  },
                ]}
                placeholder={t(
                  'settings-tab.notification-subscription-form.event-type-selector.placeholder'
                )}
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          );
        }}
      </Field>
      <Heading level="4" margin={{ bottom: 'none', top: 'medium' }}>
        {t(
          'settings-tab.notification-subscription-form.headings.template-filter'
        )}
      </Heading>
      <Field name="workspace">
        {(fieldProps: FieldProps) => {
          return (
            <WorkspaceSelector
              {...workspaceSelectorProps}
              clear={{
                label: t(
                  'settings-tab.notification-subscription-form.workspace-selector.placeholder'
                ),
                position: 'top',
              }}
              data-testid="notification-subscription-form-fields__workspace-selector"
              defaultValue={fieldProps.form.initialValues.workspace}
              disabled={
                fieldProps.form.isSubmitting || fieldProps.form.isValidating
              }
              formFieldProps={{
                'data-testid':
                  'notification-subscription-form-fields__form-field--workspace-selector',
              }}
              onAfterChange={({ value }) => {
                // Set the formik state
                fieldProps.form.setFieldValue('workspace', value);
              }}
              placeholder={t(
                'settings-tab.notification-subscription-form.workspace-selector.placeholder'
              )}
            />
          );
        }}
      </Field>
      <Field name="templateConfiguration">
        {(fieldProps: FieldProps) => {
          return (
            <TemplateConfigurationSelector
              {...templateConfigurationSelectorProps}
              clear={{
                label: t(
                  'settings-tab.notification-subscription-form.template-configuration-selector.placeholder'
                ),
                position: 'top',
              }}
              data-testid="notification-subscription-form-fields__template-configuration-selector"
              defaultValue={
                // Only pre-select the template configuration if a workspace is initially selected and there
                // is an initial template configuration available
                fieldProps.form.initialValues.workspace &&
                fieldProps.form.initialValues.templateConfiguration
                  ? fieldProps.form.initialValues.templateConfiguration
                  : undefined
              }
              disabled={
                fieldProps.form.isSubmitting ||
                fieldProps.form.isValidating ||
                !fieldProps.form.values.workspace
              }
              formFieldProps={{
                'data-testid':
                  'notification-subscription-form-fields__form-field--template-configuration-selector',
                label: t(
                  'settings-tab.notification-subscription-form.template-configuration-selector.label'
                ),
              }}
              onAfterChange={({ value }) => {
                // Set the formik state
                fieldProps.form.setFieldValue('templateConfiguration', value);
              }}
              placeholder={t(
                'settings-tab.notification-subscription-form.template-configuration-selector.placeholder'
              )}
            />
          );
        }}
      </Field>

      <Heading level="4" margin={{ bottom: 'small', top: 'medium' }}>
        {t('settings-tab.notification-subscription-form.headings.delivery')}
      </Heading>
      <Box direction="row" gap="medium">
        <Field name="deliverInApp">
          {(fieldProps: FieldProps) => {
            return (
              <CheckBox
                {...fieldProps.field}
                data-testid="notification-subscription-form-fields__form-field--deliver-in-app"
                defaultChecked={fieldProps.form.initialValues.deliverInApp}
                disabled={
                  fieldProps.form.isSubmitting || fieldProps.form.isValidating
                }
                label={t(
                  'settings-tab.notification-subscription-form.labels.in-app'
                )}
              />
            );
          }}
        </Field>
        <Field name="deliverViaEmail">
          {(fieldProps: FieldProps) => {
            return (
              <CheckBox
                {...fieldProps.field}
                data-testid="notification-subscription-form-fields__form-field--deliver-via-email"
                defaultChecked={fieldProps.form.initialValues.deliverViaEmail}
                disabled={
                  fieldProps.form.isSubmitting || fieldProps.form.isValidating
                }
                label={t(
                  'settings-tab.notification-subscription-form.labels.email'
                )}
              />
            );
          }}
        </Field>
      </Box>
    </>
  );
};
