import React from 'react';
import {
  AppLayout,
  AppLayoutProps,
  PageContent,
  PageContentProps,
} from '@common/components';
import { urlFor } from '@common/utils';
import { LibraryItem } from '@generated/graphql-code-generator';
import { useTranslation } from 'react-i18next';

export type LibraryItemPageLayoutProps = Pick<AppLayoutProps, 'subtitle'> &
  Pick<PageContentProps, 'error' | 'loading'> & {
    libraryItem?: Pick<LibraryItem, 'displayName'> | null;
  };

/**
 * Provides common layout for the library item pages.
 */
export const LibraryItemPageLayout: React.FC<LibraryItemPageLayoutProps> = ({
  children,
  error,
  libraryItem,
  loading,
  subtitle,
}) => {
  const [t] = useTranslation('pages/LibraryItemPage');

  return (
    <AppLayout
      breadcrumbs={[
        {
          title: t('library'),
          to: urlFor('library')(),
        },
      ]}
      subtitle={subtitle}
      title={libraryItem?.displayName}
    >
      <PageContent
        empty={{
          empty: !libraryItem,
          body: t('no-data'),
        }}
        error={error}
        loading={loading}
        loadingLabel={t('loading')}
      >
        {children}
      </PageContent>
    </AppLayout>
  );
};
