import { memoize } from 'lodash';

/**
 * Memoized RegExp creator.
 */
export const regexpCache = memoize(
  (pattern: string, flags?: string) => new RegExp(pattern, flags),

  // Custom cache key to account for the flags argument
  (...args) => args.join('')
);
