import React, { useState } from 'react';
import { useAuthenticatedAxios } from '@common/hooks';
import { StepDetailsQuery } from '@generated/graphql-code-generator';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { downloadStepLogs } from '../../../../utils/download-step-logs';

type StepDetailsQueryStep = NonNullable<StepDetailsQuery['step']>;

type DownloadLogsProps = {
  workspaceId: string;
  step: StepDetailsQueryStep;
};

/**
 * Renders a link for the user to download logs for the given step.
 */
export const DownloadStepLogs: React.FC<DownloadLogsProps> = ({
  step,
  workspaceId,
}) => {
  const [t] = useTranslation('features/workflows/components/StepDetails');

  const axiosInstance = useAuthenticatedAxios();

  const [isDownloading, setIsDownloading] = useState(false);

  const stepName = step.displayName || step.name;

  const downloadLogs = () => {
    setIsDownloading(true);

    return downloadStepLogs({
      axiosInstance,
      workspaceId,
      instanceId: step.instance.id,
      stepId: step.id,
      filename: stepName,
    }).finally(() => setIsDownloading(false));
  };

  return (
    <Button
      disabled={isDownloading}
      label={
        isDownloading
          ? t('download-logs.label-loading')
          : t('download-logs.label')
      }
      onClick={downloadLogs}
    />
  );
};
