import React, { useMemo, useState } from 'react';
import {
  StatusMessage,
  WorkflowInstanceViewStepFragment,
} from '@generated/graphql-code-generator';
import { Box, Button, Text } from 'grommet';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StepLink } from '..';
import { HistoryElementsDiagram, StatusMessageHistoryElement } from '../../..';
import { useStepList } from '../../use-step-list';

type InstanceStatusMessagesProps = {
  instanceId: string;
};

type MessageTuple = {
  message: Pick<StatusMessage, 'content' | 'createdUtc' | 'level'>;
  step: WorkflowInstanceViewStepFragment;
};

/**
 * Generates a unique key for each status message.
 */
const getStatusMessageKey = (tuple: MessageTuple, index: number) => {
  const { step, message } = tuple;
  const keyValues = [index, step.id, message.createdUtc, message.level];
  return keyValues.join('');
};

/**
 * Component used to render aggregated instance steps status messages.
 */
export const InstanceStatusMessages: React.FC<InstanceStatusMessagesProps> = ({
  instanceId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [t] = useTranslation(
    'features/workflows/components/WorkflowInstanceView'
  );

  const { steps } = useStepList({ instanceId });

  // Get the messages to display from each step in the instance
  const instanceMessages = useMemo(() => {
    const messages = steps.reduce((acc, step) => {
      if (!step) {
        return acc;
      }

      const items = step.statusMessages?.items || [];
      const tuples = items.map(message => ({ message, step }));
      return acc.concat(tuples as MessageTuple[]);
    }, [] as MessageTuple[]);

    // Order the messages
    return orderBy(messages, 'message.createdUtc', 'desc');
  }, [steps]);

  if (!instanceMessages.length) {
    return (
      <Box align="center" pad={{ bottom: 'small' }}>
        {t('instance-summary.status-messages.no-messages')}
      </Box>
    );
  }

  const toggleShowMore = () => setIsExpanded(!isExpanded);

  // Display all messages if expanded, otherwise just show the top 3
  const messagesToDisplay = isExpanded
    ? instanceMessages
    : instanceMessages.slice(0, 3);

  return (
    <>
      <HistoryElementsDiagram>
        {messagesToDisplay.map((tuple, index) => (
          <StatusMessageHistoryElement
            graphName={`instance-status-message-${index}`}
            key={getStatusMessageKey(tuple, index)}
            message={tuple.message}
            stepName={
              <StepLink stepId={tuple.step.id} variation="unstyled">
                {tuple.step.displayName}
              </StepLink>
            }
          />
        ))}
      </HistoryElementsDiagram>
      <Box align="center" pad={{ vertical: 'small' }}>
        <Button
          fill="horizontal"
          label={
            <Text weight="bold">
              {isExpanded
                ? t('instance-summary.status-messages.show-less')
                : t('instance-summary.status-messages.show-more')}
            </Text>
          }
          onClick={toggleShowMore}
        />
      </Box>
    </>
  );
};
