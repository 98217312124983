import React from 'react';
import { IconBase, IconBaseProps } from 'react-icons';

const createIcon = (el: JSX.Element): React.ComponentType<IconBaseProps> => {
  const attr = el.props;
  const children = el.props.children;

  return props => (
    <IconBase attr={attr} {...props}>
      {children}
    </IconBase>
  );
};

const xmlnsUrl = 'http://www.w3.org/2000/svg';
const viewBoxValue = '0 0 100 100';

export const IconLibrary = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M79.82,26a5,5,0,0,0-4.95-5H25.33a5,5,0,0,0-4.95,5V75.53a5,5,0,0,0,4.95,5H74.87a5,5,0,0,0,4.95-5ZM69.53,68.1a.61.61,0,0,1-.61-.62.59.59,0,0,1,0-.23l3.26-8.14a2.48,2.48,0,0,0-1.38-3.22,2.67,2.67,0,0,0-.92-.17H66.2a1.23,1.23,0,0,0-1.15.77L60.56,67.71a.61.61,0,0,1-.57.39H59a.62.62,0,0,1-.62-.62.59.59,0,0,1,0-.23l3.94-9.83a1.24,1.24,0,0,0-1.15-1.7h-5a1.24,1.24,0,0,0-1.15.77L50.66,67.71a.63.63,0,0,1-.58.39h-.95a.62.62,0,0,1-.62-.62.59.59,0,0,1,.05-.23l3.93-9.83a1.23,1.23,0,0,0-.69-1.61,1.22,1.22,0,0,0-.46-.09H47.22a2.45,2.45,0,0,0-2.29,1.55L40.75,67.71a.61.61,0,0,1-.58.39H26.57a1.24,1.24,0,0,1-1.24-1.24V50.76a1.24,1.24,0,0,1,1.24-1.23H73.63a1.24,1.24,0,0,1,1.24,1.23v16.1a1.24,1.24,0,0,1-1.24,1.24ZM73.63,26a1.24,1.24,0,0,1,1.24,1.23V44a.62.62,0,0,1-.62.62H70.53a.61.61,0,0,1-.61-.62V33.43a1.24,1.24,0,0,0-1.24-1.24h-5a1.23,1.23,0,0,0-1.23,1.24V44a.62.62,0,0,1-.62.62H45.77a.62.62,0,0,1-.62-.62V33.43a1.24,1.24,0,0,0-1.24-1.24H39a1.23,1.23,0,0,0-1.24,1.24V44a.62.62,0,0,1-.62.62H35.86a.62.62,0,0,1-.62-.62V33.43A1.23,1.23,0,0,0,34,32.19H29.05a1.24,1.24,0,0,0-1.24,1.24V44a.62.62,0,0,1-.62.62H26a.62.62,0,0,1-.62-.62V27.23A1.24,1.24,0,0,1,26.57,26Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconBell = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <path
      d="M57.43,70.22a.64.64,0,0,1,.64.63.13.13,0,0,1,0,.06A8.09,8.09,0,0,1,42,71.38a1,1,0,0,1,.87-1.15H57.43Z"
      style={{ fill: '#9baded' }}
    />
    <path
      d="M78.32,62.13a2,2,0,0,0-2-2c-2.4,0-6.93-4-6.93-19.08a19.37,19.37,0,0,0-38.74,0c0,15.09-4.53,19.08-6.93,19.08a2,2,0,1,0,0,4H76.3A2,2,0,0,0,78.32,62.13Z"
      style={{ fill: '#9baded' }}
    />
  </svg>
);

export const IconWorkspace = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M28.71,77.68a2.46,2.46,0,1,0,4.91,0V61.47H28.71Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M67.44,77.68a2.46,2.46,0,1,0,4.91,0V61.47H67.44Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M79,55.59l-6.64-15.5a.7.7,0,0,1,0-.25v-8.4a10.05,10.05,0,0,0-10.05-10H46a3.14,3.14,0,0,0-3.14,3.14v1.26a4.4,4.4,0,0,0,4.4,4.4H59.77a4.41,4.41,0,0,0,4.06-2.73.7.7,0,0,1,.91-.38l.15.08a5,5,0,0,1,2.42,4.28v6.91a.63.63,0,0,1-.63.63H28.36a1.26,1.26,0,0,0-1.16.76L20.41,55.57a2.52,2.52,0,0,0,1.3,3.31L22,59H77.46a2.51,2.51,0,0,0,1.77-2.39A2.57,2.57,0,0,0,79,55.59ZM59.68,52.74a1,1,0,0,1-.39.07H40.15a1.27,1.27,0,0,1-1.26-1.26,1.4,1.4,0,0,1,.07-.4l1.68-5a1.25,1.25,0,0,1,1.19-.86H57.61a1.25,1.25,0,0,1,1.19.86l1.68,5A1.26,1.26,0,0,1,59.68,52.74Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconIntegrations = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#fff' }} width="100" />
    <path
      d="M84.23,0H15.77A15.77,15.77,0,0,0,0,15.77V84.23A15.77,15.77,0,0,0,15.77,100H84.23A15.77,15.77,0,0,0,100,84.23V15.77A15.77,15.77,0,0,0,84.23,0Zm-46,60.63L31.42,48.79H21.36A3.38,3.38,0,0,1,18,45.4V31.92a3.38,3.38,0,0,1,3.38-3.38H34.84a3.38,3.38,0,0,1,3.39,3.38V35.8H59.12v5.72H38.23V45.4a3.38,3.38,0,0,1-.87,2.24l6,10.33A6.35,6.35,0,0,0,38.25,60.63Zm22,17.2a3.38,3.38,0,0,1-3.39,3.38H43.43a3.38,3.38,0,0,1-3.38-3.38V64.35A3.41,3.41,0,0,1,43.43,61H56.91a3.39,3.39,0,0,1,3.39,3.39ZM82.37,45.4A3.39,3.39,0,0,1,79,48.79H65.5a3.38,3.38,0,0,1-3.38-3.39V31.92a3.38,3.38,0,0,1,3.38-3.38H79a3.38,3.38,0,0,1,3.39,3.38Z"
      style={{ fill: '#6b84e5' }}
    />
  </svg>
);

export const IconQuestion = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M43.41,54.21a15,15,0,0,1,4-4.64q2.32-1.74,4.63-3.38a17.79,17.79,0,0,0,4-3.77,7.76,7.76,0,0,0,1.63-4.86,6.31,6.31,0,0,0-2.32-4.9,8.77,8.77,0,0,0-6-2,11.79,11.79,0,0,0-6.7,1.86,9.88,9.88,0,0,0-3.66,3.72L38.05,38,26.43,32.93c.12-.42.32-1,.59-1.72a21.78,21.78,0,0,1,2.22-3.77,25.73,25.73,0,0,1,4.2-4.79,22.79,22.79,0,0,1,6.7-3.82,25.91,25.91,0,0,1,9.54-1.7q9.85,0,16.07,5.47A16.89,16.89,0,0,1,72,35.7a19.82,19.82,0,0,1-1.71,8.42,18.42,18.42,0,0,1-4.18,6q-2.48,2.26-4.92,4.26A19.61,19.61,0,0,0,57,59.07,10.77,10.77,0,0,0,55.26,65H42.69a11.5,11.5,0,0,1-.91-4.18A13.13,13.13,0,0,1,43.41,54.21ZM43,72.67a7.65,7.65,0,0,1,10.51,0,6.55,6.55,0,0,1,0,9.76,7.65,7.65,0,0,1-10.51,0,6.49,6.49,0,0,1,0-9.76Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconAlert = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M80.5,70.75,54.77,21.5a5.37,5.37,0,0,0-9.52,0L19.52,70.75a5.37,5.37,0,0,0,4.76,7.86H75.74A5.37,5.37,0,0,0,80.5,70.75ZM46.25,34.31a1.59,1.59,0,0,1,1.56-1.56h4.4a1.51,1.51,0,0,1,1.1.47,1.48,1.48,0,0,1,.46,1.09V60a1.49,1.49,0,0,1-.46,1.1,1.51,1.51,0,0,1-1.1.47h-4.4A1.59,1.59,0,0,1,46.25,60ZM54.06,71a1.25,1.25,0,0,1-.46,1,1.59,1.59,0,0,1-1.1.42h-5a1.59,1.59,0,0,1-1.1-.42A1.29,1.29,0,0,1,46,71V66.44a1.32,1.32,0,0,1,.46-1,1.63,1.63,0,0,1,1.1-.41h5a1.63,1.63,0,0,1,1.1.41,1.29,1.29,0,0,1,.46,1Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconCheck = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M70.41,21.16H29.59a8.75,8.75,0,0,0-8.75,8.75V70.74a8.74,8.74,0,0,0,8.75,8.74H70.41a8.74,8.74,0,0,0,8.75-8.74V29.91A8.75,8.75,0,0,0,70.41,21.16Zm-2,17.56L52.7,61.31l-3.32,4.78a2.47,2.47,0,0,1-3.66.44c-1.25-1.08-2.25-2-4.38-3.83l-9.26-8.1a1.79,1.79,0,0,1-.17-2.5l3.29-3.75a1.78,1.78,0,0,1,2.5-.17l8.54,7.47L61.38,33.87a1.78,1.78,0,0,1,2.46-.45L68,36.25A1.8,1.8,0,0,1,68.4,38.72Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconClock = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M50,20.6A29.73,29.73,0,1,0,79.73,50.32,29.73,29.73,0,0,0,50,20.6ZM69.49,37.55l2.18-1.26a1.32,1.32,0,0,1,1.8.48,1.36,1.36,0,0,1-.56,1.85l-2.17,1.25a1.32,1.32,0,0,1-1.81-.48A1.34,1.34,0,0,1,69.49,37.55Zm-9-8,1.26-2.17a1.31,1.31,0,0,1,1.8-.48A1.34,1.34,0,0,1,64,28.74l-1.26,2.17a1.32,1.32,0,0,1-1.8.48A1.34,1.34,0,0,1,60.49,29.51Zm-11.81-5A1.32,1.32,0,0,1,50,23.23a1.34,1.34,0,0,1,1.32,1.4v2.51A1.32,1.32,0,0,1,50,28.46a1.34,1.34,0,0,1-1.32-1.41ZM22.9,50.32A1.35,1.35,0,0,1,24.31,49h2.51a1.33,1.33,0,0,1,1.32,1.32,1.34,1.34,0,0,1-1.41,1.32H24.22A1.32,1.32,0,0,1,22.9,50.32ZM30.51,63.1l-2.18,1.25a1.31,1.31,0,0,1-1.8-.48A1.34,1.34,0,0,1,27.09,62l2.17-1.26a1.33,1.33,0,0,1,1.81.48A1.36,1.36,0,0,1,30.51,63.1Zm.56-23.71a1.35,1.35,0,0,1-1.88.44L27,38.58a1.32,1.32,0,1,1,1.39-2.24l2.17,1.25A1.32,1.32,0,0,1,31.07,39.39Zm8.44,31.75-1.26,2.17a1.32,1.32,0,0,1-1.8.48A1.34,1.34,0,0,1,36,71.91l1.26-2.17a1.31,1.31,0,0,1,1.8-.48A1.34,1.34,0,0,1,39.51,71.14Zm-.44-39.75a1.35,1.35,0,0,1-1.85-.56L36,28.66a1.31,1.31,0,0,1,.48-1.8,1.34,1.34,0,0,1,1.85.56l1.25,2.17A1.31,1.31,0,0,1,39.07,31.39ZM51.32,76.1A1.32,1.32,0,0,1,50,77.42,1.34,1.34,0,0,1,48.68,76V73.5A1.32,1.32,0,0,1,50,72.19a1.34,1.34,0,0,1,1.32,1.4ZM47.1,52.15a2.5,2.5,0,0,1-.82-1.83V38.64a2.48,2.48,0,1,1,5,0V49.23L62.72,59.76a2.48,2.48,0,0,1,.16,3.5h0a2.53,2.53,0,0,1-3.5.15ZM63.55,73.79a1.35,1.35,0,0,1-1.85-.56l-1.25-2.17a1.31,1.31,0,0,1,.48-1.8,1.34,1.34,0,0,1,1.85.56L64,72A1.31,1.31,0,0,1,63.55,73.79Zm9.92-9.92a1.34,1.34,0,0,1-1.88.44l-2.17-1.25a1.32,1.32,0,1,1,1.39-2.24L73,62.07A1.32,1.32,0,0,1,73.47,63.87Zm2.22-12.23H73.18a1.32,1.32,0,0,1-1.32-1.32A1.35,1.35,0,0,1,73.27,49h2.51a1.33,1.33,0,0,1,1.32,1.32A1.34,1.34,0,0,1,75.69,51.64Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconClose = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M50.25,18.34A31.86,31.86,0,1,0,82.11,50.2,31.85,31.85,0,0,0,50.25,18.34ZM66.06,58.43a1.88,1.88,0,0,1,0,2.65L61.14,66a1.89,1.89,0,0,1-2.66,0l-8.23-8.23L42,66a1.88,1.88,0,0,1-2.65,0l-4.93-4.93a1.88,1.88,0,0,1,0-2.65l8.23-8.23L34.44,42a1.88,1.88,0,0,1,0-2.65l4.93-4.92a1.88,1.88,0,0,1,2.65,0l8.23,8.23,8.23-8.23a1.89,1.89,0,0,1,2.66,0l4.92,4.92a1.88,1.88,0,0,1,0,2.65L57.83,50.2Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconX = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <path
      d="M30.14,27a4.68,4.68,0,0,1,6.61,0L49.12,39.4a1.16,1.16,0,0,0,1.65,0L63.15,27a4.67,4.67,0,0,1,6.6,0l3.3,3.31a4.67,4.67,0,0,1,0,6.6L60.67,49.3a1.18,1.18,0,0,0,0,1.65L73.05,63.33a4.67,4.67,0,0,1,0,6.6l-3.3,3.3a4.65,4.65,0,0,1-6.6,0L50.77,60.86a1.16,1.16,0,0,0-1.65,0L36.75,73.23a4.67,4.67,0,0,1-6.61,0l-3.3-3.3a4.67,4.67,0,0,1,0-6.6L39.22,51a1.16,1.16,0,0,0,0-1.65L26.84,36.93a4.67,4.67,0,0,1,0-6.6Z"
      style={{ fill: '#6b84e5' }}
    />
  </svg>
);

export const IconFlag = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M79.54,25.69a4,4,0,0,0-4.05-.25,30.69,30.69,0,0,1-10.35,2.21c-8.06.44-27.21-10-32.15-2.64a5.09,5.09,0,0,0-.79,2.88V53.33A3.76,3.76,0,0,0,36,57.09c14.34-2,25.67,9.77,41.92,1.85,1.68-.64,3.21-1.5,3.21-3.83V28.76A3.76,3.76,0,0,0,79.54,25.69Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M24.67,20.24A3.76,3.76,0,0,0,20.91,24V76.65a3.76,3.76,0,0,0,7.52,0V24A3.76,3.76,0,0,0,24.67,20.24Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconKeyboard = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#6b84e5' }} width="100" />
    <path
      d="M35.11,41.84H68c7.34,0,12.47-4.56,12.47-11.09S75.38,19.66,68,19.66H55.9a2.77,2.77,0,0,1-2.77-2.77,2.78,2.78,0,0,0-5.55,0,8.33,8.33,0,0,0,8.32,8.32H68c3.35,0,6.93,1.45,6.93,5.54S71.39,36.29,68,36.29H35.11C28.76,36.29,24,42.88,24,48.77v2.08a.69.69,0,0,1-.69.69h-.69a2.78,2.78,0,0,0-2.78,2.77V76.49a2.78,2.78,0,0,0,2.78,2.77H78.07a2.77,2.77,0,0,0,2.78-2.77V54.31a2.77,2.77,0,0,0-2.78-2.77H30.26a.69.69,0,0,1-.69-.69V48.77C29.57,45.65,32.12,41.84,35.11,41.84ZM65.6,75.1H35.11a1.39,1.39,0,1,1,0-2.77H65.6a1.39,1.39,0,1,1,0,2.77Zm7.88-18.58h1.59a2.09,2.09,0,1,1,0,4.17H73.48a2.09,2.09,0,0,1,0-4.17Zm0,8h1.59a2.09,2.09,0,1,1,0,4.17H73.48a2.09,2.09,0,0,1,0-4.17Zm-9.52-8h1.59a2.09,2.09,0,0,1,0,4.17H64a2.09,2.09,0,1,1,0-4.17Zm0,7.93h1.59a2.09,2.09,0,0,1,0,4.18H64a2.09,2.09,0,0,1,0-4.18Zm-9.53-7.93H56a2.09,2.09,0,1,1,0,4.17H54.43a2.09,2.09,0,0,1,0-4.17Zm0,7.93H56a2.09,2.09,0,0,1,0,4.18H54.43a2.09,2.09,0,0,1,0-4.18Zm-9.52-7.93H46.5a2.09,2.09,0,1,1,0,4.17H44.91a2.09,2.09,0,1,1,0-4.17Zm0,7.93H46.5a2.09,2.09,0,0,1,0,4.18H44.91a2.09,2.09,0,0,1,0-4.18Zm-9.52-7.93H37a2.09,2.09,0,1,1,0,4.17H35.39a2.09,2.09,0,1,1,0-4.17Zm0,7.93H37a2.09,2.09,0,0,1,0,4.18H35.39a2.09,2.09,0,0,1,0-4.18Zm-7.94,4.18H25.86a2.09,2.09,0,0,1,0-4.18h1.59a2.09,2.09,0,0,1,0,4.18Zm0-7.94H25.86a2.09,2.09,0,1,1,0-4.17h1.59a2.09,2.09,0,1,1,0,4.17Z"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const IconOAuth2 = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <rect height="100" rx="15.77" style={{ fill: '#fff' }} width="100" />
    <polygon
      points="64.46 27.48 61.26 36.35 67.81 36.35 64.61 27.48 64.46 27.48"
      style={{ fill: '#6983e0' }}
    />
    <path
      d="M84.23,0H15.77A15.77,15.77,0,0,0,0,15.77V84.23A15.77,15.77,0,0,0,15.77,100H84.23A15.77,15.77,0,0,0,100,84.23V15.77A15.77,15.77,0,0,0,84.23,0ZM35.06,20.16A14.28,14.28,0,0,1,49.7,34.23,14.28,14.28,0,0,1,35.06,48.3c-8.29,0-14.65-6.36-14.65-14.07S26.77,20.16,35.06,20.16Zm6.86,53.7v5.4H22.84c0-12.72,13.1-12.72,13.1-18.69a3.56,3.56,0,0,0-3.85-3.47c-3.28,0-4.63,3.27-4.63,3.27l-4.62-2.31a10,10,0,0,1,9.63-6.36c5.79,0,9.45,3.66,9.45,8.48,0,7.71-8.48,8.87-11.57,13.68Zm6.55,5.78a3.08,3.08,0,1,1,3.08-3.08A3,3,0,0,1,48.47,79.64Zm16.94.2c-6.17,0-11.57-5.4-11.57-14.07S59.24,51.7,65.41,51.7,77,57.1,77,65.77,71.57,79.84,65.41,79.84Zm6.64-32.12-2.31-6.17H59.34L57,47.72H50.86l10.4-27h6.55l10.41,27Z"
      style={{ fill: '#6983e0' }}
    />
    <path
      d="M35.06,42.9a8.67,8.67,0,1,0-8.68-8.67A8.64,8.64,0,0,0,35.06,42.9Z"
      style={{ fill: '#6983e0' }}
    />
    <path
      d="M65.41,57.1c-2.9,0-5.79,2.89-5.79,8.67s2.89,8.67,5.79,8.67,5.78-2.89,5.78-8.67S68.3,57.1,65.41,57.1Z"
      style={{ fill: '#6983e0' }}
    />
  </svg>
);

export const IconPlus = createIcon(
  <svg viewBox={viewBoxValue} xmlns={xmlnsUrl}>
    <path
      d="M22.23,47.86a4.28,4.28,0,0,1,4.28-4.27h16a1.07,1.07,0,0,0,1.07-1.07v-16a4.28,4.28,0,0,1,4.27-4.28h4.28a4.28,4.28,0,0,1,4.27,4.28v16a1.07,1.07,0,0,0,1.07,1.07h16a4.28,4.28,0,0,1,4.28,4.27v4.28a4.28,4.28,0,0,1-4.28,4.27h-16a1.07,1.07,0,0,0-1.07,1.07v16a4.28,4.28,0,0,1-4.27,4.28H47.86a4.28,4.28,0,0,1-4.27-4.28v-16a1.07,1.07,0,0,0-1.07-1.07h-16a4.28,4.28,0,0,1-4.28-4.27Z"
      style={{ fill: '#86858e' }}
    />
  </svg>
);
