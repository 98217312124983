import { useState } from 'react';
import {
  WorkspacePageRenameDataSetMutationFn,
  WorkspacePageRenameTemplateConfigurationMutationFn,
} from '@generated/graphql-code-generator';
import { RenameModalProps } from './RenameModal';

type UseRenameModalOptions = {
  error: RenameModalProps['error'];

  /**
   * API callback for renaming the entity.
   */
  renameMutation:
    | WorkspacePageRenameDataSetMutationFn
    | WorkspacePageRenameTemplateConfigurationMutationFn;

  typeName: RenameModalProps['typeName'];

  /**
   * The ID of the workspace that the entity is contained in.
   */
  workspaceId: string;
};

type UseRenameModalResult = Readonly<{
  /**
   * Callback for opening the `RenameModal` component.
   */
  openRenameModal: (id: string, name: string) => void;

  /**
   * Props to pass to the `RenameModal` component.
   */
  renameModalProps: RenameModalProps;
}>;

/**
 * Hook that provides functionality for the `RenameModal` component.
 */
export const useRenameModal = ({
  error,
  renameMutation,
  typeName,
  workspaceId,
}: UseRenameModalOptions): UseRenameModalResult => {
  const [open, setOpen] = useState<boolean>(false);
  const [entity, setEntity] = useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });

  const closeRenameModal: RenameModalProps['onClose'] = () => {
    setOpen(false);
  };

  const onSubmit: RenameModalProps['onSubmit'] = async values => {
    if (!entity.id || !workspaceId) {
      return;
    }

    await renameMutation({
      variables: {
        displayName: values.name,
        id: entity.id,
        workspaceId,
      },
    });

    setOpen(false);
  };

  const openRenameModal = (id: string, name: string) => {
    setEntity({ id, name });
    setOpen(true);
  };

  return {
    openRenameModal,
    renameModalProps: {
      error,
      name: entity.name,
      onClose: closeRenameModal,
      onSubmit,
      open,
      typeName,
    },
  };
};
