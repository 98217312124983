import React, { useEffect, useRef } from 'react';
import { detectCurrentTimezone, tryParseISO } from '@common/utils';
import { ValidationMessage } from '@generated/graphql-code-generator';
import {
  FormikSubmitFn,
  useAfterInvalidSubmitEffect,
  useAfterSubmitEffect,
} from '@voleer/form-utils';
import { useUpdateEffect } from '@voleer/react-hooks';
import { PropsOf, ensureUnreachable } from '@voleer/types';
import { Alert, AlertGroup, LoadingButton } from '@voleer/ui-kit';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { Box } from 'grommet';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { isJSON } from 'validator';
import { FormFragment, fieldsOf, isMaskableField } from '..';
import {
  RenderFormComponent,
  RenderFormComponentRefHandler,
} from '../components';
import { DateInputValue, TimeInputValue } from '../interface';
import { useValidatorFor } from '../validation';

/**
 * Type of initial values allowed for a form.
 */
export type InitialValues = Record<
  string,
  Date | DateInputValue | string[] | string | undefined
>;

/**
 * Builds a set of initial values for the given form.
 */
const initialValuesFor = (form: FormFragment): InitialValues => {
  return fieldsOf(form).reduce((values, field) => {
    if (isMaskableField(field) && field.mask) {
      // Masked fields should only be populated with their default value if no
      // value has been saved in the backend. For security reasons the backend
      // will never return the actual saved value, which is why we set to empty
      // string if the value is present.
      values[field.name] = field.value ? '' : field.defaultValue ?? '';
      return values;
    }

    switch (field.__typename) {
      case 'FormCheckbox': {
        // The backend currently requires the value as a string 'true' or
        // 'false' rather than a boolean
        values[field.name] = field.value || String(!!field.defaultChecked);
        return values;
      }

      case 'FormTextArea':
      case 'FormEmailInput':
      case 'FormUrlInput':
      case 'FormTextInput': {
        values[field.name] = field.value || field.defaultValue || '';
        return values;
      }

      case 'FormFileInput': {
        values[field.name] = field.value || field.defaultValue || '';
        return values;
      }

      case 'FormIntegration': {
        values[field.name] = field.value || '';
        return values;
      }

      case 'FormNumericInput': {
        values[field.name] =
          field.value?.toString() ||
          field.defaultNumericValue?.toString() ||
          '';
        return values;
      }

      case 'FormDateInput': {
        const defaultValue: DateInputValue | undefined =
          field.defaultValue && isJSON(field.defaultValue)
            ? JSON.parse(field.defaultValue)
            : undefined;

        const existingFieldValue: DateInputValue | undefined =
          field.value && isJSON(field.value)
            ? JSON.parse(field.value)
            : undefined;

        const currentTimezone = detectCurrentTimezone();

        values[field.name] = {
          value: existingFieldValue?.value || defaultValue?.value || '',
          timezone:
            existingFieldValue?.timezone ||
            defaultValue?.timezone ||
            currentTimezone,
          userTimezone: existingFieldValue?.userTimezone || currentTimezone,
        };

        return values;
      }

      case 'FormTimeInput': {
        const defaultValue: TimeInputValue | undefined =
          field.defaultValue && isJSON(field.defaultValue)
            ? JSON.parse(field.defaultValue)
            : undefined;

        const existingFieldValue: TimeInputValue | undefined =
          field.value && isJSON(field.value)
            ? JSON.parse(field.value)
            : undefined;

        const currentTimezone = detectCurrentTimezone();

        values[field.name] = {
          value: existingFieldValue?.value || defaultValue?.value || '',
          userTimezone: existingFieldValue?.userTimezone || currentTimezone,
        };

        return values;
      }

      case 'FormDateTimeInput': {
        const defaultValue: Date | undefined = tryParseISO(field.defaultValue);
        const existingFieldValue: Date | undefined = tryParseISO(field.value);
        values[field.name] = existingFieldValue || defaultValue || undefined;
        return values;
      }

      case 'FormSelect': {
        // If there is already a value just set it
        if (field.value) {
          values[field.name] = field.value;
          return values;
        }

        // If there is no default value to fill then the value should just be
        // empty string
        if (!field.defaultValue) {
          values[field.name] = '';
          return values;
        }

        // Find the option matching the id from defaultValue to ensure that the
        // option actually exists before we default to it
        const defaultOption = field.options.find(
          option => option.id === field.defaultValue
        );

        // Fill the default value
        values[field.name] = defaultOption?.id || '';

        return values;
      }

      case 'FormMultiSelect': {
        // If there is already a value just set it
        if (field.value) {
          values[field.name] = JSON.parse(field.value);
          return values;
        }

        // If there are no default values to fill then the current value should
        // be set an to empty array
        if (!field.defaultValues) {
          values[field.name] = [];
          return values;
        }

        // Keep a dictionary of options by their ID for easy lookup
        const optionsById = field.options.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {} as Record<string, typeof field.options[number]>);

        // Fill the default value array
        values[field.name] = field.defaultValues.reduce((acc, next) => {
          if (next && optionsById[next]) {
            acc.push(optionsById[next].id);
          }
          return acc;
        }, [] as string[]);

        return values;
      }

      default: {
        ensureUnreachable(field);
        return values;
      }
    }
  }, {} as InitialValues);
};

type ValidationMessagesAlertProps = PropsOf<typeof AlertGroup> & {
  validationMessages: Array<Pick<ValidationMessage, 'content'>>;
};

/**
 * Renders the Alert for form-level validation messages.
 */
const ValidationMessagesAlert = React.forwardRef<
  HTMLDivElement,
  ValidationMessagesAlertProps
>(({ validationMessages, ...props }, ref) => (
  <AlertGroup margin={{ bottom: 'medium' }} ref={ref} status="error" {...props}>
    {validationMessages.map((message, index) => (
      <Alert icon={true} key={index} status="error">
        {message.content}
      </Alert>
    ))}
  </AlertGroup>
));

export type RenderFormProps = {
  /**
   * The ID of of the data request.
   */
  dataRequestId?: string;

  /**
   * Whether or not the entire form should be in disabled state.
   */
  disabled?: boolean;

  /**
   * The workflow form definition to render.
   */
  form: FormFragment;

  /**
   * The ID of the workflow instance that renders the form.
   */
  instanceId?: string;

  /**
   * Submit handler.
   */
  onSubmit: FormikSubmitFn<InitialValues>;

  /**
   * Text to display on the submit button. If not provided then the
   * `form.submitLabel` will be used if present, otherwise a default label will
   * be rendered.
   */
  submitLabel?: React.ReactNode;

  /**
   * Whether or not the form is currently submitting. If omitted Formik's
   * internal submit tracking will be used instead.
   */
  submitting?: boolean;

  /**
   * Form-level validation messages to display on the form.
   */
  validationMessages?: Array<Pick<ValidationMessage, 'content'>>;

  /**
   * The ID of the workspace, which contains the workflow instance that renders the form.
   */
  workspaceId?: string;
};

/**
 * A collection of inputs used to have the browser autocomplete saved credential
 * values in a hidden manner, as to not impact the user experience.
 */
const AutoCompletePrevention: React.FC = () => {
  return (
    <div>
      <input id="username" style={{ display: 'none' }} type="text" />
      <input id="password" style={{ display: 'none' }} type="password" />
    </div>
  );
};

/**
 * Inner form component.
 */
const Form: React.FC<RenderFormProps> = ({
  dataRequestId,
  disabled,
  form,
  instanceId,
  submitLabel,
  submitting,
  validationMessages,
  workspaceId,
}) => {
  const [t] = useTranslation('features/workflows/components/RenderForm');

  const formik = useFormikContext<InitialValues>();

  const { components } = form;

  // Updates the formik submitting state based on current prop values
  const updateSubmitting = () => {
    if (typeof submitting !== 'undefined') {
      formik.setSubmitting(submitting);
    }
  };

  // Update Formik submitting state when `submitting` prop changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateSubmitting, [submitting]);

  // Reset values when form name changes (this relies on form name since it must
  // be unique within a workflow)
  useUpdateEffect(() => {
    formik.setValues(initialValuesFor(form));
    updateSubmitting();
  }, [form.name]);

  const validationMessagesRef = useRef<HTMLDivElement | null>(null);

  // Scroll to validation messages after the form has been submitted
  useAfterSubmitEffect(() => {
    // Run in a timeout to make sure the validation messages have rendered to
    // the DOM before we try to scroll to them
    const timeout = setTimeout(() => {
      if (validationMessages?.length) {
        validationMessagesRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [validationMessages]);

  // Create and aggregate refs for each of the form fields
  const formFieldRefsMap = useRef<Record<string, HTMLElement>>({});
  const formFieldRefHandler: RenderFormComponentRefHandler =
    definition => element => {
      if (!element) {
        return;
      }
      formFieldRefsMap.current[definition.name] = element;
    };

  useAfterInvalidSubmitEffect(() => {
    const errors = formik.errors;
    const fieldName = Object.keys(errors)[0];
    if (typeof fieldName !== 'string') {
      return;
    }
    setTimeout(() => {
      const firstErrorFieldRef = formFieldRefsMap.current[fieldName];
      firstErrorFieldRef.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      firstErrorFieldRef.focus({ preventScroll: true });
    }, 0);
  });

  return (
    <FormikForm autoComplete="off" name={form.name}>
      <AutoCompletePrevention />
      {!!validationMessages?.length && (
        <ValidationMessagesAlert
          ref={validationMessagesRef}
          validationMessages={validationMessages}
        />
      )}
      <Box gap="medium">
        <Box>
          {components.map(component => (
            <RenderFormComponent
              dataRequestId={dataRequestId}
              definition={component}
              disabled={disabled}
              formFieldRefHandler={formFieldRefHandler}
              instanceId={instanceId}
              key={`${form.name}-${component.name}`}
              workspaceId={workspaceId}
            />
          ))}
        </Box>
        <Box align="end">
          <LoadingButton
            data-voleer-id="workflow-form__submit"
            disabled={disabled}
            label={submitLabel || form.submitLabel || t('submit')}
            loading={formik.isSubmitting}
            primary={true}
            type="submit"
          />
        </Box>
      </Box>
    </FormikForm>
  );
};

/**
 * Renders a workflow form.
 */
export const RenderForm: React.FC<RenderFormProps> = props => {
  const { form, disabled, onSubmit } = props;
  const initialValues = initialValuesFor(form);
  const validator = useValidatorFor(form);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={disabled ? noop : onSubmit}
      validate={validator}
    >
      <Form {...props} />
    </Formik>
  );
};
