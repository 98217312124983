import { useState } from 'react';
import {
  AccountStatus,
  TenantFragment,
} from '@generated/graphql-code-generator';
import { usePolling } from '@voleer/react-hooks';
import { differenceInDays, set } from 'date-fns';
import { useTenantContext } from '../use-tenant-context';

export const VOLEER_BASE_PLAN = 'Voleer Base Plan';

export type UseAccountStatusResult = Readonly<{
  /**
   * The tenant's account status
   */
  accountStatus: AccountStatus | null | undefined;

  /**
   * Whether or not app features are disabled, based on the account status
   */
  disableWriteOperation: boolean;

  /**
   * Number of days remaining in the tenant's free trial
   */
  remainingTrialDays: number;

  /**
   *  Whether or not the tenant's free trial expired
   */
  trialExpired: boolean;
}>;

/**
 * Calculates the number of remaining days that a tenant has in their free trial.
 */
const calculateRemainingTrialDays = (tenant: TenantFragment | null): number => {
  const nextStatusDate = tenant?.nextStatusDate;
  const todayDate = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const expirationDate = nextStatusDate
    ? set(new Date(nextStatusDate), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
    : todayDate;

  return differenceInDays(expirationDate, todayDate);
};

/**
 * Gets the tenant's free trial information.
 */
export const useAccountStatus = (): UseAccountStatusResult => {
  const { tenant } = useTenantContext();

  const [, setLastUpdate] = useState<Date>();

  const interval = 1000 * 60 * 60; // 1000ms * 60s * 60m = 1h

  // Trigger a periodic re-render to provide the most recent status based on
  // current date
  usePolling(interval, () => {
    setLastUpdate(new Date());
  });

  const remainingTrialDays = calculateRemainingTrialDays(tenant);

  const trialExpired = tenant?.accountStatus === AccountStatus.TrialExpired;

  return {
    accountStatus: tenant?.accountStatus,
    disableWriteOperation:
      trialExpired || tenant?.accountStatus === AccountStatus.Canceled,
    remainingTrialDays,
    trialExpired,
  };
};
