import React from 'react';
import {
  IntegrationMetadataInfoRow,
  IntegrationStatusBadge,
} from '@features/integrations';
import { useIntegrationCardQuery } from '@generated/graphql-code-generator';
import { Box, Heading, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';

export type IntegrationCardProps = {
  integrationId: string;
  onClick: () => void;
};

/**
 * Renders a card representing the Integration with the given ID.
 */
export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  integrationId,
  onClick,
  ...props
}) => {
  const [t] = useTranslation('pages/IntegrationsPage');

  const { data } = useIntegrationCardQuery({
    variables: { integrationId },
  });

  if (!data) {
    return null;
  }

  const { displayName, description, integrationMetadata, status } =
    data.integration;

  return (
    <Box
      background="white"
      elevation="small"
      flex={{ shrink: 0 }}
      height="252px"
      margin="2px"
      onClick={onClick}
      pad="medium"
      role="button"
      round="xsmall"
      {...props}
    >
      <Box flex={{ grow: 1 }}>
        <Box direction="row" gap="small">
          <Box flex={{ grow: 1, shrink: 1 }}>
            <Heading
              level="4"
              margin={{ vertical: '0' }}
              title={displayName}
              truncate={true}
            >
              {displayName}
            </Heading>
          </Box>
        </Box>

        <Box flex={{ shrink: 0 }} margin={{ top: 'xsmall' }}>
          <IntegrationStatusBadge status={status} />
        </Box>

        {!!description && (
          <Paragraph margin={{ bottom: '0', top: 'small' }}>
            <Truncate ellipsis="..." lines={3}>
              {description}
            </Truncate>
          </Paragraph>
        )}

        {!description && (
          <Paragraph color="text-xweak" margin={{ bottom: '0', top: 'small' }}>
            <em>{t('configured-tab.integration-card.no-description')}</em>
          </Paragraph>
        )}
      </Box>

      {!!integrationMetadata && (
        <Box>
          <IntegrationMetadataInfoRow
            integrationMetadataId={integrationMetadata.id}
          />
        </Box>
      )}
    </Box>
  );
};
