import React from 'react';
import {
  TemplateConfigurationSelector,
  WorkspaceSelector,
  useTemplateConfigurationSelector,
  useWorkspaceSelector,
} from '@common/components';
import { useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  LibraryAutomationPageItemFragment,
  useLibraryAutomationPageLaunchMutation,
} from '@generated/graphql-code-generator';
import { Anchor } from '@voleer/ui-kit';
import { Box, Button, Heading, Paragraph } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import {
  DataSetSelector,
  LaunchButton,
  useDataSetSelector,
} from '../../../components';

/**
 * Prop types for the `LaunchTemplateSection` component.
 */
type LaunchTemplateSectionProps = {
  template: Pick<
    LibraryAutomationPageItemFragment,
    | 'dataSchemaDependencies'
    | 'displayName'
    | 'hasTenantAccess'
    | 'id'
    | 'solutionName'
  >;
};

/**
 * Renders the "Launch Template" section in the automation template page.
 */
export const LaunchTemplateSection: React.FC<LaunchTemplateSectionProps> = ({
  template,
}) => {
  const [t] = useTranslation('pages/LibraryAutomationPage');

  const { 'launch-control': launchControl } = useTypedFlags();

  const { tenantMember } = useTenantContext();

  const { id: templateMetadataId } = template;

  const { workspace, props: workspaceSelectorProps } = useWorkspaceSelector();

  const dataSchemaMetadata = template.dataSchemaDependencies?.items?.[0];
  const compatibleDataSetMetadata =
    dataSchemaMetadata?.compatibleDataSetTemplate || undefined;

  const { dataSet, props: dataSetSelectorProps } = useDataSetSelector({
    compatibleDataSetMetadata,
    dataSchemaMetadataId: dataSchemaMetadata?.id,
    workspace,
  });

  const hasDataSetDependencies =
    !!template.dataSchemaDependencies?.items?.length;

  const [
    launchTemplate,
    { loading: launchTemplateLoading, data: launchTemplateData },
  ] = useLibraryAutomationPageLaunchMutation();

  const {
    templateConfiguration,
    options: templateConfigurations,
    props: templateConfigurationSelectorProps,
  } = useTemplateConfigurationSelector({
    workspaceId: workspace?.id,
    templateMetadataId,
  });

  const instanceId = launchTemplateData?.executeTemplateConfiguration?.id;

  // If a Workspace has been selected and an instance has been created, redirect
  // the user to the instance view
  if (workspace && instanceId) {
    return (
      <Redirect
        push={true}
        to={urlFor('workflowInstance')({
          workspaceId: workspace.id,
          workflowInstanceId: instanceId,
        })}
      />
    );
  }

  const onLaunchClick = async () => {
    if (!workspace || !tenantMember) {
      return;
    }

    await launchTemplate({
      variables: {
        dataSetId: dataSet ? dataSet.id : null,
        launchedById: tenantMember.id,
        templateConfigurationId: templateConfiguration?.id,
        templateMetadataId,
        workspaceId: workspace.id,
      },
    });
  };

  const renderContactSales = () => {
    return (
      <>
        <Heading level="4" margin="none">
          {t(
            template.solutionName
              ? 'launch-template.try-heading'
              : 'launch-template.default-try-heading',
            {
              displayName: template.solutionName,
            }
          )}
        </Heading>

        <Paragraph margin={{ top: 'medium', bottom: 'medium' }} size="medium">
          <Trans
            components={{
              'learn-more-link': (
                <Anchor
                  // TODO (lle): href link final decision still being decided by design
                  href={urlFor('pricing')()}
                  rel="noopener noreferrer"
                  target="_blank"
                  variation="primary"
                />
              ),
            }}
            i18nKey={
              template.solutionName
                ? 'launch-template.try-description'
                : 'launch-template.default-try-description'
            }
            t={t}
            values={{ displayName: template.solutionName }}
          />
        </Paragraph>

        <Box direction="row" flex={{ grow: 0 }}>
          <Button
            data-testid="launch-template-section__button--contact-sales"
            href={urlFor('contactSales')()}
            label={t('launch-template.button.contact-sales')}
            primary={true}
            target="_blank"
          />
        </Box>
      </>
    );
  };

  const renderLaunch = () => (
    <>
      <Heading level="4" margin={{ bottom: 'small', top: 'none' }}>
        {t('launch-template.heading')}
      </Heading>

      <WorkspaceSelector
        data-testid="workspace-selector"
        {...workspaceSelectorProps}
        disabled={launchTemplateLoading || !template.hasTenantAccess}
      />

      {!!templateConfigurations.length && (
        <Box>
          <TemplateConfigurationSelector
            {...templateConfigurationSelectorProps}
            disabled={
              launchTemplateLoading || !workspace || !template.hasTenantAccess
            }
          />
        </Box>
      )}

      {hasDataSetDependencies && (
        <DataSetSelector
          {...dataSetSelectorProps}
          disabled={
            dataSetSelectorProps.disabled ||
            launchTemplateLoading ||
            !template.hasTenantAccess
          }
        />
      )}

      <LaunchButton
        data-voleer-id="launch-template__launch-button"
        disabled={
          !workspace ||
          (hasDataSetDependencies && !dataSet) ||
          !template.hasTenantAccess
        }
        loading={launchTemplateLoading}
        onClick={onLaunchClick}
      />
    </>
  );

  return (
    <Box
      border={{ color: 'light-4' }}
      data-voleer-id="launch-template"
      flex={{ shrink: 0 }}
      gap="small"
      pad="medium"
      round="small"
    >
      {(() => {
        if (!launchControl) {
          return renderLaunch();
        }
        return template.hasTenantAccess ? renderLaunch() : renderContactSales();
      })()}
    </Box>
  );
};
