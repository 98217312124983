import { useMemo } from 'react';
import { reportToErrors } from '@voleer/io-ts';
import * as Either from 'fp-ts/lib/Either';
import { constant } from 'fp-ts/lib/function';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LaunchDarklyFlags, LaunchDarklyFlagsT } from '.';

const logErrors = (errors: t.Errors) => {
  const message = [
    'Unable to read Feature Flags:',
    ...reportToErrors(errors).map(e => e.message),
  ].join('\n');

  // eslint-disable-next-line no-console
  console.error(message);

  return errors;
};

/**
 * Type-safe version of LaunchDarkly's `useFlags` hook.
 */
export const useTypedFlags = (): LaunchDarklyFlags => {
  const flags = useFlags();

  const parsedFlags = useMemo(() => {
    return pipe(
      LaunchDarklyFlagsT.decode(flags),
      Either.mapLeft(logErrors),

      // To be safe we return the flags as-is even if parsing fails, that way if
      // one of the flag variations gets messed up in LaunchDarkly we'll just
      // log a message about it rather than crashing the application
      Either.getOrElse(constant(flags))
    );
  }, [flags]);

  return parsedFlags;
};
