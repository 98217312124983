import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Specialization of the `useEffect` hook that does not call its effect on the
 * first render.
 *
 * Useful when you have effects that should be triggered when props are updated,
 * but not when the component is first rendered.
 */
export const useUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
