import React from 'react';
import { IntegrationType } from '@generated/graphql-code-generator';
import { IconIntegrations, IconOAuth2 } from '@voleer/icons';
import { ensureUnreachable } from '@voleer/types';
import { Icon, IconProps } from '@voleer/ui-kit';

/**
 * Prop types for the `IntegrationIcon` component.
 */
type IntegrationIconProps = Pick<IconProps, 'size'> & {
  type: IntegrationType;
};

/**
 * Renders an icon for an integration.
 */
export const IntegrationIcon: React.FC<IntegrationIconProps> = ({
  type,
  ...iconProps
}) => {
  switch (type) {
    case IntegrationType.OAuth_2: {
      return <Icon {...iconProps} icon={IconOAuth2} />;
    }

    case IntegrationType.None:
    case IntegrationType.CustomWorkflow: {
      return <Icon {...iconProps} icon={IconIntegrations} />;
    }

    default: {
      ensureUnreachable(type);
      return null;
    }
  }
};
