import * as t from 'io-ts';

/**
 * io-ts codec to parse Feature Flag data being loaded from LaunchDarkly.
 */
export const LaunchDarklyFlagsT = t.partial(
  {
    'launch-control': t.boolean,

    'library-filtering': t.boolean,

    notifications: t.boolean,

    'subscription-management': t.boolean,

    'tenant-member-roles': t.boolean,

    'tenant-ui-polling-configuration': t.type(
      {
        availableIntegrationsList: t.number,
        dashboardPage: t.number,
        dashboardsList: t.number,
        instanceStepsAndDataRequests: t.number,
        integrationFormComponent: t.number,
        notificationsTray: t.number,
        notificationsPage: t.number,
        oauth2IntegrationSessionStatus: t.number,
        publicLibraryList: t.number,
        tenantContext: t.number,
        tenantSettingsAuthentication: t.number,
        tenantSettingsTenantMembersList: t.number,
        workspaceDataSets: t.number,
        workspaceFiles: t.number,
        workspaceInstancesLists: t.number,
      },
      'tenant-ui-polling-configuration'
    ),
  },
  'LaunchDarklyFlags'
);

/**
 * Represents the values of all flags we use from LaunchDarkly.
 */
export type LaunchDarklyFlags = t.TypeOf<typeof LaunchDarklyFlagsT>;
