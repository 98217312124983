import React from 'react';
import { Box, Image } from 'grommet';
import styled, { keyframes } from 'styled-components';
import VOLEER_ICON_IMG_SRC from './images/voleer-icon.svg';

const blink = keyframes`
  from {opacity: 1.0;}
  to {opacity: 0.3;}
`;

const BlinkingImage = styled<typeof Image>(props => (
  <Image src={VOLEER_ICON_IMG_SRC} width="150px" {...props} />
))`
  animation: ${blink} 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

/**
 * Renders the application loading indicator.
 */
export const AppLoading: React.FC = () => (
  <Box
    align="center"
    fill={true}
    flex={{ grow: 1, shrink: 1 }}
    height="100vh"
    justify="center"
  >
    <BlinkingImage />
  </Box>
);
