import { useState } from 'react';
import { urlFor } from '@common/utils';
import {
  useDeleteTemplateConfigurationModalQuery,
  useDeleteTemplateConfigurationModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DeleteTemplateConfigurationModalProps } from './DeleteTemplateConfigurationModal';

type UseDeleteTemplateConfigurationModalOptions = {
  workspaceId: string;
};

export type UseDeleteTemplateConfigurationModalResult = {
  /**
   * Handler for opening the modal.
   */
  open: (templateConfigurationId: string) => void;

  props: DeleteTemplateConfigurationModalProps;
};

/**
 * Hook for use with the DeleteTemplateConfigurationModal component
 */
export const useDeleteTemplateConfigurationModal = ({
  workspaceId,
}: UseDeleteTemplateConfigurationModalOptions): UseDeleteTemplateConfigurationModalResult => {
  const [t] = useTranslation(
    'features/workflows/components/DeleteTemplateConfigurationModal'
  );

  const [templateConfigurationId, setTemplateConfigurationId] = useState<
    string | undefined
  >();

  const routerHistory = useHistory();

  const [confirmationText, setConfirmationText] = useState<string>('');

  const {
    data,
    error: queryError,
    loading,
  } = useDeleteTemplateConfigurationModalQuery({
    skip: !templateConfigurationId,
    variables: {
      // Ignore potentially undefined templateConfigurationId since the
      // query will be skipped if templateConfigurationId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      templateConfigurationId: templateConfigurationId!,
    },
  });

  const [
    deleteTemplateConfiguration,
    { error: mutationError, loading: submitting },
  ] = useDeleteTemplateConfigurationModalSubmitMutation({
    errorPolicy: 'all',
  });

  const onConfirm = async () => {
    if (
      !templateConfigurationId ||
      confirmationText !== t('confirmation-key')
    ) {
      return;
    }

    const result = await deleteTemplateConfiguration({
      variables: {
        input: {
          workspaceId,
          templateConfigurationId,
        },
      },
    });

    // Don't automatically close the modal if there were errors
    // so the user can see the error message in the modal
    if (!result.errors?.length) {
      onClose();
      routerHistory.push(urlFor('templateConfigurations')({ workspaceId }));
    }
  };

  const error = queryError || mutationError;
  const errorMessage = error instanceof Error ? error.message : error;

  const onClose = () => {
    setTemplateConfigurationId(undefined);
    setConfirmationText('');
  };

  const onChangeConfirmationText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmationText(e.target.value);

  const onOpen: UseDeleteTemplateConfigurationModalResult['open'] =
    templateConfigurationId => {
      setTemplateConfigurationId(templateConfigurationId);
    };

  return {
    open: onOpen,
    props: {
      confirmationText,
      error: errorMessage,
      instances: data?.templateConfiguration?.instances?.items,
      loading,
      name: data?.templateConfiguration?.displayName,
      onCancel: onClose,
      onChangeConfirmationText,
      onConfirm,
      open: !!templateConfigurationId,
      scheduledJobs: data?.templateConfiguration?.scheduledJobs?.items,
      submitting,
      workspaceId,
    },
  };
};
