import React from 'react';
import {
  PackageVersionRow,
  ReadmeElement,
  ShareLibraryItemButton,
  WorkspaceSelector,
  useWorkspaceSelector,
} from '@common/components';
import { useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  useLibraryDataSetPageCreateDataSetMutation,
  useLibraryDataSetPageQuery,
} from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Anchor, useModalState } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Button, Heading, Paragraph } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { LibraryItemPageRouteParams } from '..';
import {
  LaunchButton,
  LibraryItemPageLayout,
  StickyContainer,
} from '../components';
import { AddDataSetFormValues, AddDataSetModal } from './components';

type LibraryDataSetPageProps = Pick<
  LibraryItemPageRouteParams,
  'name' | 'packageName' | 'packageVersion' | 'publisherName'
>;

/**
 * Renders the view for a Dataset item in the library.
 */
export const LibraryDataSetPage: React.FC<LibraryDataSetPageProps> = ({
  name,
  packageName,
  packageVersion,
  publisherName,
}) => {
  const [t] = useTranslation('pages/LibraryDataSetPage');

  const {
    'tenant-ui-polling-configuration': pollingConfiguration,
    'launch-control': launchControl,
  } = useTypedFlags();

  const { tenantMember } = useTenantContext();

  const { error, data, ...query } = useLibraryDataSetPageQuery({
    pollInterval: pollingConfiguration?.publicLibraryList,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        names: [name],
        packageNames: [packageName],
        publisherNames: [publisherName],
        packageVersions:
          typeof packageVersion !== 'undefined' ? [packageVersion] : undefined,
      },
    },
  });

  const item = data?.dataSetMetadatas?.items?.[0];
  const loading = !data && query.loading;

  const { workspace, props: workspaceSelectorProps } = useWorkspaceSelector();

  const [createDataSet, { data: dataSet, error: createDataSetError }] =
    useLibraryDataSetPageCreateDataSetMutation();

  const {
    isOpen: addModalOpen,
    open: openAddModal,
    close: closeAddModal,
  } = useModalState();

  const addFormOnSubmit: FormikSubmitFn<AddDataSetFormValues> = async (
    values,
    _actions
  ) => {
    if (!workspace || !tenantMember || !item) {
      return;
    }

    await createDataSet({
      variables: {
        input: {
          createdById: tenantMember.id,
          description: values.description,
          displayName: values.displayName,
          templateMetadataId: item.id,
          workspaceId: workspace.id,
        },
      },
    });
  };

  const dataSetId = dataSet?.createDataSet?.id;
  if (workspace && dataSetId) {
    return (
      <Redirect
        push={true}
        to={urlFor('dataSet')({
          dataSetId,
          workspaceId: workspace.id,
        })}
      />
    );
  }

  const layout = (children?: React.ReactNode) => {
    return (
      <LibraryItemPageLayout
        children={children}
        error={error}
        libraryItem={item}
        loading={loading}
        subtitle={
          item?.packageMetadata && (
            <PackageVersionRow
              color="dark-2"
              publishedOn={parseISO(item.packageMetadata.publishedOn)}
              size="small"
              tenantDisplayName={item.packageMetadata.publisher.displayName}
              tenantName={item.packageMetadata.publisher.name}
              version={item.packageMetadata.version}
            />
          )
        }
      />
    );
  };

  if (!item) {
    return layout();
  }

  const renderLaunch = () => (
    <>
      <Heading level="4" margin={{ bottom: 'small', top: 'none' }}>
        {t('right-column.add-dataset-section.heading')}
      </Heading>

      <WorkspaceSelector
        data-testid="library-data-set-page__selector--workspace"
        {...workspaceSelectorProps}
      />

      <LaunchButton
        disabled={!item.hasTenantAccess || !workspace}
        onClick={openAddModal}
      />
    </>
  );

  const renderContactSales = () => {
    return (
      <>
        <Heading level="4" margin="none">
          {t(item.solutionName ? 'try-heading' : 'default-try-heading', {
            displayName: item.solutionName,
          })}
        </Heading>

        <Paragraph margin={{ top: 'medium', bottom: 'medium' }} size="medium">
          <Trans
            components={{
              'learn-more-link': (
                <Anchor
                  // TODO (lle): href link final decision still being decided by design
                  href={urlFor('pricing')()}
                  rel="noopener noreferrer"
                  target="_blank"
                  variation="primary"
                />
              ),
            }}
            i18nKey={
              item.solutionName ? 'try-description' : 'default-try-description'
            }
            t={t}
            values={{ displayName: item.solutionName }}
          />
        </Paragraph>

        <Box direction="row" flex={{ grow: 0 }}>
          <Button
            data-testid="library-data-set-page__button--contact-sales"
            href={urlFor('contactSales')()}
            label={t('button.contact-sales')}
            primary={true}
            target="_blank"
          />
        </Box>
      </>
    );
  };

  return layout(
    <Box align="center" fill="vertical" pad="medium">
      <Box
        background="white"
        elevation="small"
        flex={{ shrink: 0 }}
        margin={{ bottom: 'medium' }}
        pad="small"
        round="xsmall"
        width="100%"
      >
        <Box
          alignSelf="center"
          direction="row"
          gap="small"
          justify="between"
          pad="small"
          width="xxlarge"
        >
          <Box pad="small" width="50%">
            <ReadmeElement
              description={item.description}
              readme={item.readme}
            />
          </Box>

          <Box gap="small" pad="small" width="50%">
            <StickyContainer>
              <Box
                alignSelf="end"
                background="white"
                border={{ color: 'light-4' }}
                flex={{ shrink: 0 }}
                gap="small"
                pad="medium"
                round="xsmall"
                width="400px"
              >
                {(() => {
                  if (!launchControl) {
                    return renderLaunch();
                  }
                  return item.hasTenantAccess
                    ? renderLaunch()
                    : renderContactSales();
                })()}

                <Box direction="row" justify="end">
                  <ShareLibraryItemButton
                    itemDisplayName={item.displayName}
                    itemName={item.name}
                    packageName={item.packageMetadata.name}
                    publisherName={item.packageMetadata.publisher.name}
                    type={item.libraryItemType}
                  />
                </Box>
              </Box>
            </StickyContainer>
          </Box>
        </Box>
        {/* Modal placed here to prevent whitespace from showing up inside the workspace selector container */}
        <AddDataSetModal
          closeModal={closeAddModal}
          data-testid="add-dataset-modal"
          error={createDataSetError?.message}
          onSubmit={addFormOnSubmit}
          open={addModalOpen}
          workspaceName={workspace?.displayName}
        />
      </Box>
    </Box>
  );
};
