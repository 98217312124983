import React from 'react';
import {
  AppLayout,
  PageContent,
  RouteTab,
  RouteTabs,
} from '@common/components';
import { useTypedFlags } from '@features/launch-darkly';
import { useTranslation } from 'react-i18next';
import { NotificationTab, UserProfileTab } from '..';

/**
 * Renders the User settings page.
 */
export const UserSettingsPage: React.FC = () => {
  const [t] = useTranslation('pages/UserSettingsPage');
  const { notifications } = useTypedFlags();

  return (
    <AppLayout title={t('user-settings')}>
      <PageContent>
        <RouteTabs flex="grow" justify="start">
          <RouteTab
            queryParamValue="profile-management"
            title={t('profile-management')}
          >
            <UserProfileTab />
          </RouteTab>
          {!notifications && (
            <RouteTab
              queryParamValue="notifications"
              title={t('notifications')}
            >
              <NotificationTab />
            </RouteTab>
          )}
        </RouteTabs>
      </PageContent>
    </AppLayout>
  );
};
