import React, { useContext } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useAccountStatus } from '@common/hooks';
import {
  ChangeScheduledJobModal,
  ScheduledJobScheduleDetails,
  ScheduledJobScheduleToggle,
  useChangeScheduledJobModal,
} from '@features/scheduled-jobs';
import { InstanceListItem } from '@features/workflows';
import {
  ScheduledJobPageDocument,
  ScheduledJobStatus,
  useScheduledJobPageQuery,
} from '@generated/graphql-code-generator';
import { Area, AreaContent, AreaHeading } from '@voleer/ui-kit';
import {
  Box,
  Button,
  Grid,
  GridProps,
  Heading,
  ResponsiveContext,
  Text,
} from 'grommet';
import { useTranslation } from 'react-i18next';
import { ScheduledJobDetails } from './components';

const GridLayout: React.FC = props => {
  const size = useContext(ResponsiveContext);

  // Two-column layout by default
  const defaultColumns = ['45%', 'auto'];

  // Single column layout for smaller breakpoints
  const columns: Record<string, GridProps['columns']> = {
    small: ['auto'],
    medium: ['auto'],
  };

  return (
    <Grid
      columns={columns[size] || defaultColumns}
      gap="medium"
      pad="medium"
      {...props}
    />
  );
};

type SummaryTabProps = {
  workspaceId: string;
  scheduledJobId: string;
};

/**
 * Renders the summary tab of the Scheduled Job page.
 */
export const SummaryTab: React.FC<SummaryTabProps> = ({
  workspaceId,
  scheduledJobId,
}) => {
  const [t] = useTranslation('pages/ScheduledJobPage');

  const { data } = useScheduledJobPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: scheduledJobId,
    },
  });

  const {
    props: changeScheduledJobModalProps,
    open: openChangeScheduledJobModal,
  } = useChangeScheduledJobModal({
    workspaceId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(ScheduledJobPageDocument)!],
  });

  const { disableWriteOperation } = useAccountStatus();
  const scheduledJob = data?.scheduledJob;
  const templateMetadata = scheduledJob?.templateConfiguration.templateMetadata;
  const recentInstances = scheduledJob?.recentInstances?.items;

  if (!scheduledJob) {
    return null;
  }

  const renderRecentRuns = () => {
    if (!recentInstances?.length) {
      return <AreaContent>{t('recent-runs.empty')}</AreaContent>;
    }

    return (
      <AreaContent pad="none">
        {recentInstances.map(
          instance =>
            instance && (
              <InstanceListItem
                instance={instance}
                key={instance.id}
                workspaceId={instance.workspaceId}
              />
            )
        )}
      </AreaContent>
    );
  };

  return (
    <Box fill={true} overflow="auto">
      <GridLayout>
        <Box>
          <ScheduledJobDetails
            scheduledJob={scheduledJob}
            templateConfiguration={scheduledJob?.templateConfiguration}
            templateMetadata={templateMetadata || undefined}
            workspaceId={workspaceId}
          />
        </Box>
        <Box>
          <Area paper={true}>
            <AreaHeading label={t('schedule.heading')}>
              <ScheduledJobScheduleToggle
                scheduledJobId={scheduledJob.id}
                scheduledJobStatus={scheduledJob.status}
                workspaceId={workspaceId}
              />
            </AreaHeading>
            <AreaContent>
              {scheduledJob.status !== ScheduledJobStatus.Paused && (
                <Box align="center" pad="small">
                  <Text size="large">
                    <ScheduledJobScheduleDetails scheduledJob={scheduledJob} />
                  </Text>
                  <Button
                    disabled={disableWriteOperation}
                    label={t('schedule.action-buttons.change-schedule')}
                    margin={{ top: 'medium' }}
                    onClick={() => openChangeScheduledJobModal(scheduledJob.id)}
                  />
                </Box>
              )}
              {scheduledJob.status === ScheduledJobStatus.Paused && (
                <Box align="center" pad="medium">
                  <Heading level="3" margin={{ top: 'none' }}>
                    {t('schedule.disabled.heading')}
                  </Heading>
                  <Text>{t('schedule.disabled.subheading')}</Text>
                </Box>
              )}
            </AreaContent>
          </Area>
        </Box>
        <Box>
          <Area paper={true}>
            <AreaHeading label={t('recent-runs.heading')} />
            {renderRecentRuns()}
          </Area>
        </Box>
      </GridLayout>

      <ChangeScheduledJobModal {...changeScheduledJobModalProps} />
    </Box>
  );
};
