import React from 'react';
import { StepStatus } from '@generated/graphql-code-generator';
import { ensureUnreachable } from '@voleer/types';
import { Icon, RotatingIcon } from '@voleer/ui-kit';
import { Box, Text } from 'grommet';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  FaCheck,
  FaClock,
  FaExclamationCircle,
  FaKeyboard,
  FaQuestionCircle,
  FaSpinner,
  FaTimesCircle,
} from 'react-icons/fa';
import { StepLink, StepListItemSummary } from '..';
import { useStepList } from '../../use-step-list';

export type StepListProps = {
  instanceId: string;
};

export const StepList: React.FC<StepListProps> = ({ instanceId }) => {
  const [t] = useTranslation(
    'features/workflows/components/WorkflowInstanceView'
  );

  const { selectedStep, steps, loading } = useStepList({ instanceId });

  // Loading state is handled in WorkflowInstanceView
  if (loading) {
    return null;
  }

  const renderStep = (step: typeof steps[number]) => {
    if (!step) {
      return null;
    }

    const name = step.displayName || step.name;
    const active = step.id === selectedStep?.id;

    return (
      <StepLink
        data-testid="workflow-instance-view__step-list--step"
        key={step.id}
        stepId={step.id}
        variation="unstyled"
      >
        <Box
          background={active ? 'white' : ''}
          direction="row"
          flex={{ shrink: 0 }}
          height="70px"
          hoverIndicator="light-5"
          onClick={noop}
          pad={{ vertical: 'small', horizontal: 'medium' }}
        >
          <Box flex={{ shrink: 0, grow: 0 }} pad={{ vertical: '15px' }}>
            <StepStatusIcon status={step.status} />
          </Box>
          <Box
            direction="column"
            flex={{ shrink: 1, grow: 0 }}
            pad={{ left: 'small', vertical: 'small' }}
          >
            <Text truncate={true}>{name}</Text>
          </Box>
        </Box>
      </StepLink>
    );
  };

  return (
    <Box flex={{ shrink: 0 }} overflow={{ vertical: 'auto' }}>
      <StepListItemSummary instanceId={instanceId} />
      {steps.length ? (
        steps.map(step => renderStep(step))
      ) : (
        <Box pad="medium">
          <Text color="dark-2">{t('step-list.empty')}</Text>
        </Box>
      )}
    </Box>
  );
};

type StepStatusIconProps = {
  status: StepStatus;
};

/**
 * Component that will display the icon associated with the given StepStatus.
 */
const StepStatusIcon: React.FC<StepStatusIconProps> = props => {
  switch (props.status) {
    case StepStatus.Completed:
      return <Icon color="green" icon={FaCheck} />;
    case StepStatus.Failed:
      return <Icon color="status-error" icon={FaExclamationCircle} />;
    case StepStatus.Canceled:
      return <Icon color="dark-2" icon={FaTimesCircle} />;
    case StepStatus.Running:
      return <RotatingIcon color="accent-1" icon={FaSpinner} />;
    case StepStatus.Scheduled:
      return <Icon color="dark-2" icon={FaClock} />;
    case StepStatus.WaitingForInput:
      return <Icon color="dark-2" icon={FaKeyboard} />;
    case StepStatus.Unknown:
      return <Icon color="status-error" icon={FaQuestionCircle} />;
    default:
      ensureUnreachable(props.status);
      return null;
  }
};
