import React from 'react';
import { parseCronExpression } from '@voleer/cron-parse';
import { format, parseISO } from 'date-fns';
import { format as formatTimeZone, utcToZonedTime } from 'date-fns-tz';
import { Box, Grid, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  DailySchedule,
  MonthlySchedule,
  toDailySchedule,
  toHourlySchedule,
  toMinutesSchedule,
  toMonthlySchedule,
  toWeeklySchedule,
} from '../../utils';
import { WeekSchedule } from '../ScheduledJobSchedule/components/WeekSchedule';
import { ScheduledJobProp } from './interface';

/**
 * Prop types for the `ScheduledJobScheduleDetails` component
 */
type ScheduledJobScheduleDetailsProps = Readonly<{
  scheduledJob: ScheduledJobProp;
}>;

/**
 * Renders scheduled job schedule details component
 */
export const ScheduledJobScheduleDetails: React.FC<ScheduledJobScheduleDetailsProps> =
  ({ scheduledJob }) => {
    const [t] = useTranslation(
      'features/scheduled-jobs/components/ScheduledJobScheduleDetails'
    );

    /**
     * Renders time portion of scheduled job schedule
     */
    const renderTime = ({ hour, minute }: DailySchedule) => {
      const date = new Date(2020, 1, 1, hour, minute);
      return <>{format(date, 'p')}</>;
    };

    const renderMonths = ({ month }: MonthlySchedule) => {
      const i18nMonths = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ];

      return month.map(m => t(`month-names.${i18nMonths[m - 1]}`)).join(', ');
    };

    if (!scheduledJob.pattern) {
      return <div>-</div>;
    }

    const parsed = parseCronExpression(scheduledJob.pattern);

    if (!parsed) {
      return null;
    }

    const formatDateStringToDate = (
      dateString: string,
      formatString: string
    ) => {
      return format(parseISO(dateString), formatString);
    };

    const zonedTime = utcToZonedTime(new Date(), scheduledJob.timezoneName);
    const timeZoneName = formatTimeZone(zonedTime, 'zzzz', {
      timeZone: scheduledJob.timezoneName,
    }); // Pacific Standard Time

    const minutesSchedule = toMinutesSchedule(parsed);
    const hourlySchedule = toHourlySchedule(parsed);
    const dailySchedule = toDailySchedule(parsed);
    const weeklySchedule = toWeeklySchedule(parsed);
    const monthlySchedule = toMonthlySchedule(parsed);

    const canParseSchedule =
      !!minutesSchedule ||
      !!hourlySchedule ||
      !!dailySchedule ||
      !!weeklySchedule ||
      !!monthlySchedule;

    return (
      <>
        {canParseSchedule && (
          <Grid
            alignContent="start"
            columns={['8em', 'auto']}
            gap={{ column: 'medium' }}
          >
            {scheduledJob.startTime && (
              <>
                <Box>{t('start-time')}</Box>
                <Box data-testid="start-time">
                  {formatDateStringToDate(scheduledJob.startTime, 'P')}
                </Box>
              </>
            )}
            {minutesSchedule && (
              <>
                <Box>{t('repeat-every')}</Box>
                <Box data-testid="minutes-schedule">
                  {t('minute', { count: minutesSchedule.minutes })}
                </Box>
              </>
            )}
            {hourlySchedule && (
              <>
                <Box>{t('repeat-every')}</Box>
                <Box data-testid="hourly-schedule">
                  {t('hour', { count: hourlySchedule.hours })}
                </Box>
              </>
            )}
            {dailySchedule && (
              <>
                <Box>{t('repeat-every')}</Box>
                <Box>{t('day')}</Box>
                <Box>{t('at')}</Box>
                <Box data-testid="daily-time">{renderTime(dailySchedule)}</Box>
              </>
            )}
            {weeklySchedule && (
              <>
                <Box>{t('repeat-every')}</Box>
                <Box>{t('week')}</Box>
                <Box>{t('at')}</Box>
                <Box data-testid="weekly-time">
                  {renderTime(weeklySchedule)}
                </Box>
                <Box>{t('on')}</Box>
                <Box data-testid="weekly-days">
                  {WeekSchedule({ activeDays: weeklySchedule.dayOfWeek })}
                </Box>
              </>
            )}
            {monthlySchedule && (
              <>
                <Box>{t('repeat-every')}</Box>
                <Box>{t('month')}</Box>
                <Box>{t('at')}</Box>
                <Box data-testid="monthly-time">
                  {renderTime(monthlySchedule)}
                </Box>
                <Box>{t('on-day')}</Box>
                <Box data-testid="monthly-day-of-month">
                  {monthlySchedule.dayOfMonth}
                </Box>
                <Box>{t('of')}</Box>
                <Box data-testid="monthly-months">
                  {renderMonths(monthlySchedule)}
                </Box>
              </>
            )}
            {scheduledJob.endTime && (
              <>
                <Box>{t('end')}</Box>
                <Box data-testid="end-time">
                  {formatDateStringToDate(scheduledJob.endTime, 'P')}
                </Box>
              </>
            )}
            {!!scheduledJob.maxRecurrenceCount && (
              <>
                <Box>{t('end')}</Box>
                <Box data-testid="end-recurrences">
                  {t('recurrences', {
                    recurrences: scheduledJob.maxRecurrenceCount,
                  })}
                </Box>
              </>
            )}
            <Box>{t('timezone')}</Box>
            <Box data-testid="timezone" width={{ max: '25ch' }}>
              {timeZoneName}
            </Box>
          </Grid>
        )}

        {/* Fallback to the cron expression when the schedule cannot be parsed */}
        {!canParseSchedule && (
          <Box data-testid="cron-expression-fallback">
            <Text size="xxlarge" textAlign="center">
              {scheduledJob.pattern}
            </Text>

            <Box direction="row" margin={{ horizontal: 'auto', top: 'medium' }}>
              <Box margin={{ right: 'medium' }}>{t('timezone')}</Box>
              <Box data-testid="timezone" width={{ max: '25ch' }}>
                {timeZoneName}
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };
