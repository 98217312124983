import React from 'react';
import { PackageMarkdown, PackageMarkdownProps } from '@common/components';

type StatusMessageMarkdownProps = PackageMarkdownProps;

/**
 * Renders a StatusMessage's content as markdown.
 */
export const StatusMessageMarkdown: React.FC<StatusMessageMarkdownProps> =
  props => {
    return <PackageMarkdown Fragment={React.Fragment} {...props} />;
  };
