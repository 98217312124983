import React from 'react';
import { IntegrationStatus } from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';

const statusMapping: Record<IntegrationStatus, BadgeStatus> = {
  [IntegrationStatus.Active]: BadgeStatus.ok,
  [IntegrationStatus.Pending]: BadgeStatus.info,
  [IntegrationStatus.Unknown]: BadgeStatus.unknown,
  [IntegrationStatus.Failed]: BadgeStatus.error,
};

const i18nMapping: Record<IntegrationStatus, string> = {
  [IntegrationStatus.Active]: 'status-active',
  [IntegrationStatus.Pending]: 'status-pending',
  [IntegrationStatus.Unknown]: 'status-unknown',
  [IntegrationStatus.Failed]: 'status-failed',
};

export type IntegrationStatusBadgeProps = Omit<
  BadgeProps,
  'label' | 'status'
> & {
  status: IntegrationStatus;
};

/**
 * Renders a badge to display the given Dataset Configuration status.
 */
export const IntegrationStatusBadge: React.FC<IntegrationStatusBadgeProps> = ({
  status,
  ...props
}) => {
  const [t] = useTranslation(
    'features/integrations/components/IntegrationStatusBadge'
  );
  const label = t(i18nMapping[status]);
  return <Badge {...props} label={label} status={statusMapping[status]} />;
};
