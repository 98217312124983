import React from 'react';
import { FormTimeInputFragment } from '@generated/graphql-code-generator';
import { Icon } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import { FormField, TextInput } from 'grommet';
import { FaClock } from 'react-icons/fa';
import { TimeInputValue } from '../interface';
import { InitialValues, LabelMarkdown } from '.';

type TimeInputFieldProps = {
  definition: FormTimeInputFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form time input field.
 */
export const TimeInputField = React.forwardRef<
  HTMLInputElement,
  TimeInputFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta, fieldHelpers] = useField<TimeInputValue>(
    definition.name
  );

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentFieldValue = field.value;
    currentFieldValue['value'] = event.target.value;
    fieldHelpers.setValue(currentFieldValue);
  };

  return (
    <FormField
      error={error}
      label={<LabelMarkdown content={definition.label} />}
    >
      <TextInput
        {...field}
        autoComplete="off"
        disabled={disabled || formik.isSubmitting}
        icon={<Icon icon={FaClock} />}
        onChange={handleInputChange}
        placeholder=""
        ref={ref}
        reverse={true}
        value={field.value.value || ''}
      />
    </FormField>
  );
});
