import React from 'react';
import { useFormikContext } from 'formik';
import { Box, FormField, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import {
  FormLabel,
  NumericInput,
  NumericInputProps,
  ScheduleTypeSelect,
} from '../../components';
import { ScheduledJobFormValues } from '../../interface';

// Field extracted to help with making `Trans` interpolation work below
const IntervalField: React.FC<NumericInputProps> = props => (
  <Box width="56px">
    <NumericInput maxLength={3} {...props} />
  </Box>
);

/**
 * Renders the "hourly" schedule form fields.
 */
export const HourlyFields: React.FC = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const formik = useFormikContext<ScheduledJobFormValues>();

  const getField = (key: keyof ScheduledJobFormValues) => ({
    props: formik.getFieldProps(key),
    meta: formik.getFieldMeta(key),
    helpers: formik.getFieldHelpers(key),
  });

  return (
    <>
      <FormLabel>{t('fields.schedule-type.label')}</FormLabel>
      <FormField error={getField('type').meta.error} margin="none">
        <ScheduleTypeSelect {...getField('type').props} />
      </FormField>

      <FormLabel>{t('fields.interval.label')}</FormLabel>
      <FormField error={getField('hourlyInterval').meta.error} margin="none">
        <Box align="center" direction="row">
          <Trans i18nKey="fields.interval.field-hours" t={t}>
            <IntervalField {...getField('hourlyInterval').props} />
            <Text margin={{ left: 'xsmall' }}>hour(s)</Text>
          </Trans>
        </Box>
      </FormField>
    </>
  );
};
