import React from 'react';
import { useAccountStatus } from '@common/hooks';
import { PropsOf } from '@voleer/types/src';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';

type AddWorkspaceButtonProps = PropsOf<typeof Button>;

/**
 * Renders a button for adding a new workspace.
 */
export const AddWorkspaceButton: React.FC<AddWorkspaceButtonProps> = props => {
  const [t] = useTranslation('pages/WorkspacesPage');
  const { disableWriteOperation } = useAccountStatus();

  return (
    <Button
      {...props}
      data-testid="add-workspace-button"
      disabled={disableWriteOperation}
      label={t('new-workspace')}
      primary={true}
    />
  );
};
