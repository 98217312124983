import { useEffect, useRef } from 'react';
import { isPromise } from '@voleer/types';
import { noop } from 'lodash';

/**
 * Hook which takes a callback which will be invoked each time `intervalInMs`
 * has elapsed.
 *
 * If `intervalInMs` is `undefined` or `<= 0` then polling will be disabled.
 *
 * If `onPoll` returns a Promise then the next poll will not be scheduled until
 * after the promise resolves.
 *
 * ```typescript
 * const SomeComponent = () => {
 *   const pollInterval = 1000;
 *
 *   usePolling(pollInterval, ()=> {
 *     console.log(`Polling every ${pollInterval} ms`);
 *   });
 *
 *   return <div>Some Content</div>;
 * }
 * ```
 *
 * @param intervalInMs the number of milliseconds to wait between polls
 * @param onPoll the callback to invoke on each poll
 */
export const usePolling = (
  intervalInMs: number | undefined,
  onPoll: () => Promise<void> | void
) => {
  const timeout = useRef<number>();

  // Track the latest onPoll handler in a ref so that the polling will always
  // use the latest handler passed to the hook
  const onPollRef = useRef<typeof onPoll>(noop);
  onPollRef.current = onPoll;

  useEffect(
    () => {
      if (!intervalInMs || intervalInMs <= 0) {
        clearTimeout(timeout.current);
        return;
      }

      const poll = () => {
        timeout.current = setTimeout(() => {
          const result = onPollRef.current();

          if (isPromise(result)) {
            result.then(() => {
              poll();
            });
          } else {
            poll();
          }
        }, intervalInMs);
      };

      poll();

      return () => clearTimeout(timeout.current);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervalInMs]
  );
};
