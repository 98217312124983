import React from 'react';
import {
  AppLayout,
  PageContent,
  RouteTab,
  RouteTabs,
} from '@common/components';
import { useTypedFlags } from '@features/launch-darkly';
import { useFindTenantsQuery } from '@generated/graphql-code-generator';
import { useTranslation } from 'react-i18next';
import {
  TenantAuthTab,
  TenantMembersTab,
  TenantSubscriptionTab,
} from './components';

/**
 * Component to display the tenant settings page.
 */
export const TenantSettingsPage: React.FC = () => {
  const [t] = useTranslation('pages/TenantSettingsPage');
  const tenantsResponse = useFindTenantsQuery();
  const { 'subscription-management': subscriptionManagement } = useTypedFlags();

  return (
    <AppLayout title={t('header')}>
      <PageContent
        error={tenantsResponse.error?.message}
        loading={tenantsResponse.loading}
        loadingLabel={t('loading')}
      >
        <RouteTabs flex="grow" justify="start">
          <RouteTab
            queryParamValue="user-management"
            title={t('tab-names.user-management')}
          >
            <TenantMembersTab />
          </RouteTab>
          <RouteTab
            queryParamValue="authentication"
            title={t('tab-names.authentication')}
          >
            <TenantAuthTab />
          </RouteTab>
          {subscriptionManagement && (
            <RouteTab
              queryParamValue="subscription"
              title={t('tab-names.subscription')}
            >
              <TenantSubscriptionTab />
            </RouteTab>
          )}
        </RouteTabs>
      </PageContent>
    </AppLayout>
  );
};
