import React, { useContext } from 'react';
import { urlFor } from '@common/utils';
import {
  DetailsSection,
  DetailsSectionDateInfo,
  DetailsSectionTitle,
  DetailsSectionValue,
} from '@features/workflows';
import { Link } from '@voleer/ui-kit';
import { Box, Grid, GridProps, ResponsiveContext, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  RecentRefreshesTable,
  RecentRefreshesTableProps,
  RefreshSchedule,
  RefreshScheduleProps,
} from './components';

type SummaryTabProps = Readonly<
  RecentRefreshesTableProps &
    RefreshScheduleProps & {
      /**
       * The tenant member that created the instance.
       */
      createdBy: string;

      /**
       * The date that the instance was created.
       */
      dateCreated: string;

      /**
       * The name of the instance.
       */
      instanceName: string;

      /**
       * The name of the template that the instance uses.
       */
      templateDisplayName: string;

      /**
       * The ID of the template that the instance uses (for linking to the template page).
       */
      templateId?: string;

      /**
       * The version of the template that the instance uses.
       */
      version: string;
    }
>;

/**
 * Grid layout for the `Details` and `RefreshSchedule` components.
 */
const MetadataGridLayout: React.FC<GridProps> = props => {
  const size = useContext(ResponsiveContext);

  // Two-column layout by default
  const defaultColumns = ['55%', 'auto'];

  // Single column layout for smaller breakpoints
  const columns: Record<string, GridProps['columns']> = {
    small: ['auto'],
    medium: ['auto'],
  };

  return (
    <Grid
      columns={columns[size] || defaultColumns}
      gap="large"
      pad="medium"
      {...props}
    />
  );
};

/**
 * Renders the "Summary" tab in the dataset configuration page.
 */
export const SummaryTab: React.FC<SummaryTabProps> = ({
  createdBy,
  dataSetId,
  dateCreated,
  instanceName,
  onLoadMoreRecentRefreshes,
  recentRefreshes,
  schedule,
  templateDisplayName,
  templateId,
  version,
  workspaceId,
}) => {
  const [t] = useTranslation('pages/DataSetPage');

  return (
    <Box pad={{ top: 'small' }}>
      <Grid>
        <MetadataGridLayout>
          <DetailsSection
            leftColumnContent={
              <>
                <DetailsSectionTitle>{t('details.name')}</DetailsSectionTitle>
                <DetailsSectionValue>{instanceName}</DetailsSectionValue>

                <DetailsSectionTitle>
                  {t('details.template')}
                </DetailsSectionTitle>
                {templateId ? (
                  <Link
                    to={urlFor('libraryItemById')({
                      itemId: templateId,
                    })}
                    variation="primary"
                  >
                    <DetailsSectionValue>
                      {templateDisplayName}
                    </DetailsSectionValue>
                  </Link>
                ) : (
                  <Text>-</Text>
                )}

                <DetailsSectionTitle>
                  {t('details.created-by')}
                </DetailsSectionTitle>
                <DetailsSectionDateInfo date={dateCreated} name={createdBy} />
              </>
            }
            rightColumnContent={
              <>
                <DetailsSectionTitle>{t('details.type')}</DetailsSectionTitle>
                <DetailsSectionValue>
                  {t('details.dataset')}
                </DetailsSectionValue>

                <DetailsSectionTitle>
                  {t('details.version')}
                </DetailsSectionTitle>
                <DetailsSectionValue>{version}</DetailsSectionValue>
              </>
            }
          />

          <RefreshSchedule
            dataSetId={dataSetId}
            schedule={schedule}
            workspaceId={workspaceId}
          />
        </MetadataGridLayout>

        <Grid columns={['auto']} gap="large" pad="medium">
          <RecentRefreshesTable
            onLoadMoreRecentRefreshes={onLoadMoreRecentRefreshes}
            recentRefreshes={recentRefreshes}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
