import React from 'react';
import { EmptyStateImage } from '@common/components';
import { Instance, InstanceStatus } from '@generated/graphql-code-generator';
import { UnreachableCaseError } from '@voleer/types';
import { Box } from 'grommet';
import { useTranslation } from 'react-i18next';
import RUBIX_CUBE_LAUNCHING_IMG_SRC from './images/rubix-cube-launching.gif';
import VEE_RUNNING_IMG_SRC from './images/vee-running.gif';
import FAILED_INSTANCE_IMAGE_SRC from './images/workflow-failure.svg';

type InstanceEmptyStateProps = {
  instance: Pick<Instance, 'status'>;
};

/**
 * Renders the empty state associated with the given instance state.
 */
export const InstanceEmptyState: React.FC<InstanceEmptyStateProps> = ({
  instance,
}) => {
  const [t] = useTranslation(
    'features/workflows/components/InstanceEmptyState'
  );

  return (
    <Box alignContent="center" direction="column" fill={true}>
      {(() => {
        switch (instance.status) {
          case InstanceStatus.Failed:
            return (
              <EmptyStateImage
                src={FAILED_INSTANCE_IMAGE_SRC}
                subText={t('instance-failed.sub-text')}
                text={t('instance-failed.text')}
              />
            );
          case InstanceStatus.TimedOut:
            return (
              <EmptyStateImage
                src={FAILED_INSTANCE_IMAGE_SRC}
                subText={t('instance-timed-out.sub-text')}
                text={t('instance-timed-out.text')}
              />
            );
          case InstanceStatus.Canceled:
          case InstanceStatus.Cancelling:
            return (
              <EmptyStateImage
                data-testid="instance-canceled-img"
                src={VEE_RUNNING_IMG_SRC}
                subText={t('instance-canceled.sub-text')}
                text={t('instance-canceled.text')}
              />
            );
          case InstanceStatus.Running:
            return (
              <EmptyStateImage
                data-testid="instance-running-img"
                src={RUBIX_CUBE_LAUNCHING_IMG_SRC}
                subText={t('instance-running.sub-text')}
                text={t('instance-running.text')}
              />
            );
          case InstanceStatus.Scheduled:
          case InstanceStatus.Pending:
            return (
              <EmptyStateImage
                data-testid="instance-scheduled-img"
                src={VEE_RUNNING_IMG_SRC}
                subText={t('instance-scheduled.sub-text')}
                text={t('instance-scheduled.text')}
              />
            );
          case InstanceStatus.Completed:
            {
              /*
               * Completed state is handled by another component, so this is ust
               * a backup in case this component is used by mistake.
               */
            }
            return <Box>Instance is completed.</Box>;
          case InstanceStatus.Unknown:
            return <Box>Instance is in an Unknown state.</Box>;
          default:
            throw new UnreachableCaseError(instance.status);
        }
      })()}
    </Box>
  );
};
