import React, { useMemo, useState } from 'react';
import { FormSelectFragment } from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { useField, useFormikContext } from 'formik';
import { Box, FormField, Select } from 'grommet';
import { useTranslation } from 'react-i18next';
import { InitialValues, LabelMarkdown } from '.';

type SelectFieldProps = {
  definition: FormSelectFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form select field.
 */
export const SelectField = React.forwardRef<HTMLInputElement, SelectFieldProps>(
  ({ definition, disabled }, ref) => {
    const formik = useFormikContext<InitialValues>();
    const [field, fieldMeta, fieldHelpers] = useField<string>(definition.name);

    const [t] = useTranslation('features/workflows/forms');
    const initialSearchTerm = '';
    const initialOptions = definition.options;
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const emptyOption: typeof options[number] = {
      id: '',
      label: '',
    };

    const selectedOption = initialOptions.find(
      option => option.id === field.value
    );

    const onChange = (event: GrommetSelectEvent<typeof options[number]>) => {
      fieldHelpers.setValue(event.value?.id || '');
    };

    // Errors should only be displayed if the field has been touched
    const error = fieldMeta.touched ? fieldMeta.error : undefined;

    const handleOnSearch = (newSearchTerm: string) => {
      setSearchTerm(newSearchTerm);
    };

    const options = useMemo(() => {
      if (!searchTerm) {
        return initialOptions;
      }
      return initialOptions.filter(
        option =>
          option.label &&
          option.label
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
      );
    }, [searchTerm, initialOptions]);

    const resetSearchTerm = () => {
      setSearchTerm(initialSearchTerm);
    };

    return (
      <FormField
        error={error}
        label={<LabelMarkdown content={definition.label} />}
      >
        <Select
          {...field}
          clear={
            definition.required
              ? false
              : { position: 'top', label: t('select-field.clear') }
          }
          disabled={disabled || formik.isSubmitting}
          dropHeight="medium"
          labelKey="label"
          onChange={onChange}
          onClose={resetSearchTerm}
          onSearch={handleOnSearch}
          options={options}
          placeholder={definition.placeholder}
          ref={ref}
          value={selectedOption || emptyOption}
          valueKey="id"
        >
          {option => (
            <Box
              align="start"
              background={selectedOption?.id === option.id ? 'brand' : ''}
              pad="small"
            >
              <span title={option.label}>{option.label}</span>
            </Box>
          )}
        </Select>
      </FormField>
    );
  }
);
