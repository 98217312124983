import React from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, FormField, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const defaultValues = {
  displayName: '',
};

export type AddWorkspaceFormValues = typeof defaultValues;

type AddWorkspaceFormProps = {
  onSubmit: FormikSubmitFn<AddWorkspaceFormValues>;
  onCancel: () => void;
};

const createSubmitHandler =
  (onSubmit: FormikSubmitFn<AddWorkspaceFormValues>) =>
  async (
    values: AddWorkspaceFormValues,
    formikActions: FormikHelpers<AddWorkspaceFormValues>
  ) => {
    try {
      await onSubmit(values, formikActions);
      formikActions.setSubmitting(false);
    } catch (error) {
      formikActions.setSubmitting(false);
      formikActions.setStatus({
        serverError: error.toString(),
      });
    }
  };

/**
 * Component for displaying the form for adding a workspace.
 */
export const AddWorkspaceForm: React.FC<AddWorkspaceFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [t] = useTranslation('pages/WorkspacesPage');
  const addWorkspaceSchema = Yup.object().shape({
    displayName: Yup.string().required(
      t('add-workspace.workspace-name-required')
    ),
  });

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={createSubmitHandler(onSubmit)}
      validateOnBlur={false}
      validationSchema={addWorkspaceSchema}
    >
      {formProps => (
        <Form>
          {formProps.status && formProps.status.serverError && (
            <Alert margin={{ bottom: 'medium' }} status="error">
              {t('error')}: {formProps.status.serverError}
            </Alert>
          )}
          <Box>
            <Field name="displayName">
              {(fieldProps: FieldProps<string>) => (
                <FormField
                  error={fieldProps.form.errors.displayName}
                  label={t('add-workspace.workspace-name-label')}
                >
                  <TextInput
                    {...fieldProps.field}
                    autoFocus={true}
                    disabled={formProps.isSubmitting}
                    placeholder={t('add-workspace.workspace-name-placeholder')}
                  />
                </FormField>
              )}
            </Field>
          </Box>
          <Box
            direction="row"
            gap="small"
            justify="end"
            margin={{ top: 'small' }}
          >
            <Box>
              <Button
                disabled={formProps.isSubmitting}
                label={t('add-workspace.cancel')}
                onClick={onCancel}
              />
            </Box>
            <Box flex={{ grow: 0 }}>
              <Button
                disabled={formProps.isSubmitting}
                label={
                  formProps.isSubmitting
                    ? t('add-workspace.submitting')
                    : t('add-workspace.save')
                }
                primary={true}
                type="submit"
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
