import React, { useMemo } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useAccountStatus, useDateFormat, useFuseState } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  ConnectionStringModal,
  DataSetStatusBadge,
  DeleteDataSetModal,
  InstanceStatusBadge,
  RenameModal,
  useConnectionStringModal,
  useDeleteDataSetModal,
  useRenameModal,
} from '@features/workflows';
import {
  DataSetStatus,
  InstanceStatus,
  Workspace,
  WorkspaceDataSetsTabDocument,
  useRefreshDataSetMutation,
  useWorkspaceDataSetsTabQuery,
  useWorkspacePageRenameDataSetMutation,
} from '@generated/graphql-code-generator';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  Icon,
  Link,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Heading, Paragraph, Text } from 'grommet';
import { compact, without } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';

/**
 * Prop types for the `WorkspaceDataSetsTab` component.
 */
export type WorkspaceDataSetsTabProps = Readonly<{
  workspace: Pick<Workspace, 'id'>;
}>;

/**
 * Renders the Datasets tab content on the Workspace page.
 */
export const WorkspaceDataSetsTab: React.FC<WorkspaceDataSetsTabProps> = ({
  workspace,
}) => {
  const [t] = useTranslation('pages/WorkspacePage');

  const { disableWriteOperation } = useAccountStatus();

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const [format, { DateTimeFormat }] = useDateFormat();

  const { data, loading } = useWorkspaceDataSetsTabQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfig?.workspaceDataSets,
    variables: {
      workspaceId: workspace.id,
      statuses: without(
        Object.values(DataSetStatus),
        DataSetStatus.Deleted,
        DataSetStatus.Deleting
      ),
    },
  });

  const { openConnectionStringModal, connectionStringModalProps } =
    useConnectionStringModal();

  const [refreshDataset, refreshDatasetMutation] = useRefreshDataSetMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(WorkspaceDataSetsTabDocument)!],
  });

  const { deleteDataSetModalProps, openDeleteDataSetModal } =
    useDeleteDataSetModal();

  const [renameDataSet, renameDataSetMutation] =
    useWorkspacePageRenameDataSetMutation();
  const { openRenameModal, renameModalProps } = useRenameModal({
    error: renameDataSetMutation?.error?.message,
    renameMutation: renameDataSet,
    typeName: t('datasets-tab.rename-modal.type-name'),
    workspaceId: workspace.id,
  });

  const dataSetItems = data?.dataSets?.items;
  // Add `templateMetadataName` to each dataSetItem to be used by search
  const formattedDataSetItems = useMemo(() => {
    return compact(dataSetItems).map(item => {
      const templateMetadataName = item.templateMetadata?.displayName;

      return {
        ...item,
        templateMetadataName,
      };
    });
  }, [dataSetItems]);

  const {
    searchTerm,
    setSearchTerm,
    searchResults: dataSets,
  } = useFuseState({
    fuseOptions: {
      keys: ['displayName', 'templateMetadataName'],
      matchAllTokens: true,
      threshold: 0.3,
      tokenize: true,
    },
    items: formattedDataSetItems,
  });

  /**
   * Callback for searching within the datasets table.
   */
  const onSearch: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void> | void = event => {
    setSearchTerm(event.target.value);
  };

  const onRefreshClick = async (workspaceId: string, dataSetId: string) => {
    await refreshDataset({
      variables: {
        workspaceId,
        dataSetId,
      },
    });
  };

  const renderEmptyState = () => {
    return (
      <>
        <Heading
          level="3"
          margin={{ bottom: 'small', top: '0' }}
          textAlign="center"
        >
          {t('datasets-tab.empty-table.no-data')}
        </Heading>
        <Paragraph margin="0" textAlign="center">
          <Trans
            components={{
              'library-link': (
                <Link to={urlFor('library')()} variation="primary" />
              ),
            }}
            i18nKey="datasets-tab.empty-table.create-dataset-helper-text"
            t={t}
          />
        </Paragraph>
      </>
    );
  };

  return (
    <>
      <Box fill={true} pad={{ horizontal: 'medium', top: 'medium' }}>
        <FancyTable
          columns={[
            { width: '30%' },
            undefined,
            undefined,
            undefined,
            { justify: 'center', width: '50px' },
          ]}
          fill="horizontal"
          loading={!dataSetItems && loading}
          paper={true}
          rows={{ count: dataSets.length }}
        >
          <FancyTable.Toolbar>
            <FancyTable.Search
              onChange={onSearch}
              placeholder={t('datasets-tab.search-placeholder')}
              value={searchTerm}
            />
          </FancyTable.Toolbar>

          <FancyTable.Header>
            <FancyTable.Row>
              <FancyTable.HeaderCell>
                {t('datasets-tab.table-headers.name')}
              </FancyTable.HeaderCell>

              <FancyTable.HeaderCell>
                {t('datasets-tab.table-headers.status')}
              </FancyTable.HeaderCell>

              <FancyTable.HeaderCell>
                {t('datasets-tab.table-headers.metadata')}
              </FancyTable.HeaderCell>

              <FancyTable.HeaderCell>
                {t('datasets-tab.table-headers.last-refresh')}
              </FancyTable.HeaderCell>

              <FancyTable.HeaderCell>
                {t('datasets-tab.table-headers.actions')}
              </FancyTable.HeaderCell>
            </FancyTable.Row>
          </FancyTable.Header>

          <FancyTable.Body>
            {!dataSets.length && (
              <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
                {searchTerm ? (
                  <Heading
                    level="3"
                    margin={{ bottom: 'small', top: '0' }}
                    textAlign="center"
                  >
                    {t('datasets-tab.empty-table.no-search-results')}
                  </Heading>
                ) : (
                  renderEmptyState()
                )}
              </FancyTable.Empty>
            )}

            {!!dataSets.length &&
              dataSets.map(dataSet => {
                const {
                  configurationInstance,
                  displayName,
                  id,
                  lastRefreshRun,
                  status,
                  templateMetadataName,
                } = dataSet;

                const templateId = dataSet.templateMetadata?.id;

                return (
                  <FancyTable.Row
                    data-testid="workspace-datasets-tab__table-row"
                    key={id}
                  >
                    <FancyTable.Cell>
                      <Text title={displayName} truncate={true}>
                        <Link
                          to={urlFor('dataSet')({
                            dataSetId: id,
                            workspaceId: workspace.id,
                          })}
                          variation="primary"
                        >
                          {displayName}
                        </Link>
                      </Text>
                    </FancyTable.Cell>

                    <FancyTable.Cell>
                      <DataSetStatusBadge
                        data-testid="workspace-datasets-tab__table-cell--data-set-status-badge"
                        instanceStatus={configurationInstance?.status}
                        status={status}
                      />
                    </FancyTable.Cell>

                    <FancyTable.Cell>
                      {templateId ? (
                        <Text title={templateMetadataName} truncate={true}>
                          {templateMetadataName}
                        </Text>
                      ) : (
                        <Text>-</Text>
                      )}
                    </FancyTable.Cell>

                    <FancyTable.Cell data-testid="workspace-datasets-tab__table-cell--last-refresh">
                      {lastRefreshRun ? (
                        <Text title={lastRefreshRun.status} truncate={true}>
                          {lastRefreshRun.status === InstanceStatus.Completed &&
                          lastRefreshRun.completedOn ? (
                            format(
                              parseISO(lastRefreshRun.completedOn),
                              DateTimeFormat.AbbreviatedHumanized
                            )
                          ) : (
                            <InstanceStatusBadge
                              status={lastRefreshRun.status}
                            />
                          )}
                        </Text>
                      ) : (
                        <Text>-</Text>
                      )}
                    </FancyTable.Cell>

                    <FancyTable.Cell>
                      <DropButton
                        data-testid="workspace-datasets-tab__table-actions"
                        icon={<Icon icon={MdMoreHoriz} />}
                        title={t('datasets-tab.actions.title')}
                        variation="ghost"
                      >
                        <Link
                          to={urlFor('dataSet')({
                            dataSetId: id,
                            workspaceId: workspace.id,
                          })}
                          variation="primary"
                        >
                          <DropButtonItem
                            label={t('datasets-tab.actions.view')}
                          />
                        </Link>

                        <DropButtonItem
                          label={t('datasets-tab.actions.rename')}
                          onClick={() => {
                            openRenameModal(id, displayName);
                          }}
                        />

                        <DropButtonItem
                          data-testid="workspace-datasets-tab__table-actions--connection-string"
                          disabled={
                            status === DataSetStatus.Pending ||
                            disableWriteOperation
                          }
                          label={t('datasets-tab.actions.connection-string')}
                          onClick={() => {
                            openConnectionStringModal(dataSet);
                          }}
                        />

                        <DropButtonItem
                          data-testid="workspace-datasets-tab__table-actions--refresh"
                          disabled={
                            status === DataSetStatus.Pending ||
                            disableWriteOperation ||
                            refreshDatasetMutation.loading
                          }
                          label={t('datasets-tab.actions.refresh')}
                          onClick={() => {
                            onRefreshClick(workspace.id, dataSet.id);
                          }}
                        />

                        <DropButtonItem
                          data-testid="workspace-datasets-tab__table-actions--delete"
                          label={t('datasets-tab.actions.delete')}
                          onClick={() => {
                            openDeleteDataSetModal(dataSet);
                          }}
                        />
                      </DropButton>
                    </FancyTable.Cell>
                  </FancyTable.Row>
                );
              })}
          </FancyTable.Body>
        </FancyTable>
      </Box>
      <ConnectionStringModal {...connectionStringModalProps} />
      <DeleteDataSetModal {...deleteDataSetModalProps} />
      <RenameModal {...renameModalProps} />
    </>
  );
};
