import { memoize } from 'lodash';
import {
  ShapeValidatorResult,
  ShapeValidatorSchema,
  ValidatableShape,
} from '.';

/**
 * Creates a special type of validator used to validate object properties
 * against a schema.
 *
 * ```javascript
 * const userValidator = createShapeValidator<User>({
 *   email: createValidator(isEmail, 'must be a valid email address'),
 *   address: {
 *     zipcode: createValidator(isPresent, 'must be present')
 *   }
 * });
 *
 * const bob: User = { ... }
 * await userValidator(bob); // => { email: [...], address: { zipcode: [...] }}
 * ```
 *
 * @param schema schema to use for validation
 */
export const createShapeValidator = <TShape extends ValidatableShape>(
  schema:
    | ShapeValidatorSchema<TShape>
    | ((shape: TShape) => ShapeValidatorSchema<TShape>)
) => {
  return async (shape: TShape): Promise<ShapeValidatorResult<TShape>> => {
    const theSchema = typeof schema === 'function' ? schema(shape) : schema;
    return await validateShape(shape, theSchema);
  };
};

/**
 * Validates a shape against the provided schema.
 *
 * @param shape shape to validate
 * @param schema schema to use for validation
 */
const validateShape = async <TShape extends ValidatableShape>(
  shape: TShape,
  schema: ShapeValidatorSchema<TShape>
): Promise<ShapeValidatorResult<TShape>> => {
  // Run all the validators in parallel rather than one at a time
  const entries = await Promise.all(
    Object.keys(schema).map(async key => {
      const validator = schema[key];
      const value = shape[key];

      // Handle validators
      if (typeof validator === 'function') {
        return [key, await validator(value)] as const;
      }

      // Recurse in the case of a nested schema
      if (validator !== null && typeof validator === 'object') {
        return [key, await validateShape(value, validator)] as const;
      }

      // If we get here it means something other than a validator or a nested
      // schema was present in the parent schema, which wouldn't make any sense
      throw new Error(
        `Expected a validator to be present for ${key} but it was ${typeof validator}`
      );
    })
  );

  return entries.reduce((acc, [key, value]) => {
    acc[key as keyof ShapeValidatorResult<TShape>] = value;
    return acc;
  }, {} as ShapeValidatorResult<TShape>);
};

/**
 * Checks if the given shape validation result is valid.
 *
 * @param result
 */
export const isValid = <TShape>(result: ShapeValidatorResult<TShape>) => {
  return getMessages(result).length === 0;
};

/**
 * Checks if the given shape validation result is invalid.
 *
 * @param result
 */
export const isInvalid = <TShape>(result: ShapeValidatorResult<TShape>) => {
  return !isValid(result);
};

/**
 * Gets all messages contained within a shape validation result as an array.
 *
 * @param result the shape validation result
 */
export const getMessages = memoize(
  <TShape extends ValidatableShape>(
    result: ShapeValidatorResult<TShape>
  ): string[] => {
    return Object.keys(result).reduce((acc, key) => {
      const value = result[key];
      if (Array.isArray(value)) {
        acc = acc.concat(value);
        return acc;
      }
      if (value !== null && typeof value === 'object') {
        acc = acc.concat(getMessages(value));
        return acc;
      }
      return acc;
    }, [] as string[]);
  }
);
