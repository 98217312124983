import React from 'react';
import { useFormikContext } from 'formik';
import { Box, FormField, RadioButton, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import {
  DateInput,
  DateInputProps,
  FormLabel,
  NumericInput,
  NumericInputProps,
} from '../../components';
import { EndTypeValue, ScheduledJobFormValues } from '../../interface';

// Field extracted to help with making `Trans` interpolation work below
const MaxRecurrenceField: React.FC<NumericInputProps> = props => (
  <Box margin={{ horizontal: 'small' }} width="56px">
    <NumericInput {...props} />
  </Box>
);

// Field extracted to help with making `Trans` interpolation work below
const AfterDateField: React.FC<DateInputProps> = props => (
  <Box margin={{ horizontal: 'small' }}>
    <DateInput {...props} />
  </Box>
);

/**
 * Renders the set of radio buttons used to select end type and criteria for the
 * schedule.
 */
export const EndTypeField: React.FC = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const formik = useFormikContext<ScheduledJobFormValues>();

  const getField = (key: keyof ScheduledJobFormValues) => ({
    props: formik.getFieldProps(key),
    meta: formik.getFieldMeta(key),
    helpers: formik.getFieldHelpers(key),
  });

  const getEndTypeFieldProps = (endType: EndTypeValue) => {
    const value = formik.values.endType;

    const labels: Record<EndTypeValue, React.ReactNode> = {
      never: t('fields.end-type-never.label'),
      afterTimes: (
        <Box align="center" direction="row" flex={{ shrink: 0 }} gap="small">
          <Trans i18nKey="fields.end-type-after-times.label" t={t}>
            <Text>After</Text>
            <MaxRecurrenceField
              {...getField('maxRecurrenceCount').props}
              disabled={value !== 'afterTimes'}
              maxLength={3}
              width="10px"
            />
            <Text>times</Text>
          </Trans>
        </Box>
      ),
      afterDate: (
        <Box align="center" direction="row" flex={{ shrink: 0 }} gap="small">
          <Trans i18nKey="fields.end-type-after-date.label" t={t}>
            <Text>On</Text>
            <AfterDateField
              disabled={value !== 'afterDate'}
              {...getField('endDate').props}
            />
          </Trans>
        </Box>
      ),
    };

    return {
      ...getField('endType').props,
      value: endType,
      checked: formik.values.endType === endType,
      label: labels[endType],
    };
  };

  return (
    <>
      <FormLabel>{t('fields.end.label')}</FormLabel>

      <Box gap="small" pad="xsmall">
        <Box>
          <FormField margin="none">
            <RadioButton {...getEndTypeFieldProps('never')} />
          </FormField>
        </Box>
        <Box>
          <FormField
            error={getField('maxRecurrenceCount').meta.error}
            margin="none"
          >
            <RadioButton {...getEndTypeFieldProps('afterTimes')} />
          </FormField>
        </Box>
        <Box>
          <FormField error={getField('endDate').meta.error} margin="none">
            <RadioButton {...getEndTypeFieldProps('afterDate')} />
          </FormField>
        </Box>
      </Box>
    </>
  );
};
