import React, { useContext } from 'react';
import { PropsOf } from '@voleer/types';
import { Link, VoleerTheme } from '@voleer/ui-kit';
import { Box, Text, ThemeContext } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export type BreadcrumbValue =
  | string
  | {
      title: string;
      to?: PropsOf<typeof Link>['to'];
    };

type BreadcrumbProps = {
  breadcrumb: BreadcrumbValue;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  return (
    <Text color="dark-2" size="small">
      {typeof breadcrumb === 'object' && breadcrumb.title && breadcrumb.to && (
        <Link
          children={breadcrumb.title}
          key={breadcrumb.title}
          to={breadcrumb.to}
          variation="secondary"
        />
      )}

      {typeof breadcrumb === 'object' &&
        breadcrumb.title &&
        !breadcrumb.to &&
        breadcrumb.title}

      {typeof breadcrumb === 'string' && breadcrumb}
    </Text>
  );
};

export type BreadcrumbsProps = { breadcrumbs: BreadcrumbValue[] };

/**
 * Renders Voleer breadcrumb navigation.
 */
export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const theme = useContext<VoleerTheme>(ThemeContext);

  const loading = !!breadcrumbs.filter(
    breadcrumb =>
      (typeof breadcrumb === 'string' && !breadcrumb) ||
      (typeof breadcrumb === 'object' && !breadcrumb.title)
  ).length;

  return (
    <Box align="center" direction="row">
      {breadcrumbs.map((breadcrumb, index) => {
        const key =
          typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.title;

        return (
          <React.Fragment key={key}>
            {index !== 0 && <Box pad="small">/</Box>}

            {!loading && <Breadcrumb breadcrumb={breadcrumb} />}
            {loading && (
              <SkeletonTheme
                color={normalizeColor('light-4', theme)}
                highlightColor={normalizeColor('light-3', theme)}
              >
                <Skeleton width="10ch" />
              </SkeletonTheme>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
