import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

/**
 * Format the duration between the two given dates with the format: "Xd Yh Wm Zs"
 *
 * @param date1
 * @param date2
 */
export const formatDuration = (date1: Date, date2: Date): string => {
  const duration = [];

  // Compute days
  const daysDifference = differenceInDays(date2, date1);
  const hoursOffset = daysDifference * 24;
  if (daysDifference > 0) {
    duration.push(`${daysDifference}d`);
  }

  // Compute hours
  const hoursDifference = differenceInHours(date2, date1) - hoursOffset;
  const minutesOffset = (hoursOffset + hoursDifference) * 60;
  if (hoursDifference > 0) {
    duration.push(`${hoursDifference}h`);
  }

  // Compute minutes
  const minutesDifference = differenceInMinutes(date2, date1) - minutesOffset;
  const secondsOffset = (minutesOffset + minutesDifference) * 60;
  if (minutesDifference > 0) {
    duration.push(`${minutesDifference}m`);
  }

  // Compute seconds
  const secondsDifference = differenceInSeconds(date2, date1) - secondsOffset;
  if (secondsDifference > 0) {
    duration.push(`${secondsDifference}s`);
  }

  if (!duration.length) {
    return '0s';
  }

  return duration.join(' ');
};
