import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import { ErrorOverlay, ErrorOverlayProps, LoadingOverlay } from '..';

type EmptyContentValue = {
  empty: boolean;
  heading?: string;
  body?: string;
};

/**
 * Type guard to check if the given value is an `EmptyContentValue`.
 */
const isEmptyContentValue = (value: unknown): value is EmptyContentValue => {
  if (!value) {
    return false;
  }
  return typeof (value as EmptyContentValue).empty === 'boolean';
};

type EmptyOverlayProps = {
  empty?: EmptyContentValue | React.ReactNode | boolean;
};

/**
 * Component that conditionally renders an "empty" state instead of its
 * children.
 */
const EmptyOverlay: React.FC<EmptyOverlayProps> = ({ children, empty }) => {
  const [t] = useTranslation('components/PageContent');

  const layout = (children: React.ReactNode) => (
    <Box align="center" fill={true}>
      <Box basis="65%" justify="center" pad="medium">
        {children}
      </Box>
    </Box>
  );

  if (empty === true) {
    return layout(
      <Box width={{ max: 'medium' }}>
        <Heading>{t('empty.heading')}</Heading>
        <Paragraph>{t('empty.body')}</Paragraph>
      </Box>
    );
  }

  if (isEmptyContentValue(empty) && empty.empty) {
    return layout(
      <Box width={{ max: 'medium' }}>
        <Heading>{empty.heading || t('empty.heading')}</Heading>
        <Paragraph>{empty.body || t('empty.body')}</Paragraph>
      </Box>
    );
  }

  if (!isEmptyContentValue(empty) && empty) {
    return <>{empty}</>;
  }

  return <>{children}</>;
};

export type PageContentProps = {
  /**
   * Whether the loading screen should be displayed.
   */
  loading?: boolean;

  /**
   * Label to display with the loading spinner.
   */
  loadingLabel?: React.ReactNode;

  /**
   * Error to be displayed instead of content.
   */
  error?: ErrorOverlayProps['error'];

  /**
   * Empty content to display.
   */
  empty?: EmptyContentValue | React.ReactNode | boolean;
};

/**
 * Layout component for displaying page content with loading state, empty state,
 * and error message handling.
 *
 * ```typescript
 * <PageContent
 *   loading={loading}
 *   error={error}>
 *   The page content
 * </PageContent>
 * ```
 *
 * See the Storybook on this component for more examples.
 */
export const PageContent: React.FC<PageContentProps> = ({
  children,
  empty,
  error,
  loading,
  loadingLabel,
}) => {
  return (
    <Box fill={true}>
      <LoadingOverlay label={loadingLabel} loading={loading}>
        <ErrorOverlay error={error}>
          <EmptyOverlay empty={empty}>{children}</EmptyOverlay>
        </ErrorOverlay>
      </LoadingOverlay>
    </Box>
  );
};
