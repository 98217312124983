import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  FormMultiSelectFragment,
  FormSelectOption,
} from '@generated/graphql-code-generator';
import { Area, DropButton, Icon } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import {
  Box,
  Button,
  FormField,
  InfiniteScroll,
  Paragraph,
  Text,
  TextInput,
} from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { InitialValues, LabelMarkdown } from '.';

type MultiSelectFieldProps = {
  definition: FormMultiSelectFragment;
  disabled?: boolean;
};

type Option = Pick<FormSelectOption, 'id' | 'label'>;

/**
 * Renders a workflow form multi select field.
 */
export const MultiSelectField = React.forwardRef<
  HTMLButtonElement,
  MultiSelectFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta, fieldHelpers] = useField<string[] | undefined>(
    definition.name
  );

  const [t] = useTranslation('features/workflows/forms');
  const initialSearchTerm = '';
  const allOptions = definition.options;
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [selectionBinOpen, setSelectionBinOpen] = useState(true);
  const [selectionDropOpen, setSelectionDropOpen] = useState(false);
  const isDisabled = disabled || formik.isSubmitting;

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  const selectedOptions = field.value ?? [];

  // Available options are filtered out with the following conditions:
  // 1. Option has already been selected by user.
  // 2. Option does not contains search term (if user is searching).
  const options: Option[] = useMemo(() => {
    const availableOptions = allOptions.reduce((acc, option) => {
      // Filter out option that has been selected by user
      if (field.value?.includes(option.id)) {
        return acc;
      }

      if (searchTerm) {
        const containsSearchTerm =
          !!option.label &&
          option.label
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase());

        if (containsSearchTerm) {
          acc.push(option);
        }
      } else {
        acc.push(option);
      }

      return acc;
    }, [] as Option[]);

    return availableOptions;
  }, [field.value, allOptions, searchTerm]);

  const toggleSelectionBinOpen = () => {
    setSelectionBinOpen(!selectionBinOpen);
  };

  const handleOnClose = () => {
    setSearchTerm(initialSearchTerm);
    setSelectionDropOpen(false);
    fieldHelpers.setValue(selectedOptions);
  };

  const handleSelectOption = (option: Option) => {
    fieldHelpers.setValue([option.id, ...selectedOptions]);
  };

  const handleSelectAll = () => {
    fieldHelpers.setValue([...allOptions.map(option => option.id)]);
  };

  const clearAllSelected = () => {
    fieldHelpers.setValue([]);
  };

  const handleClearOption = (selection: Option) => {
    const filteredSelectedOptions = selectedOptions.filter(selectedOptionId => {
      return selection.id !== selectedOptionId;
    });
    fieldHelpers.setValue(filteredSelectedOptions);
  };

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const renderOption = (option: Option, selected: boolean) => {
    const label = option.label || option.id;
    return (
      <Area
        align="center"
        data-testid={
          selected
            ? 'form-multi-select__selected-row'
            : 'form-multi-select__option-row'
        }
        direction="row"
        disabled={isDisabled}
        fill="horizontal"
        flex={false}
        hoverIndicator={true}
        key={option.id}
        onClick={() => {
          selected ? handleClearOption(option) : handleSelectOption(option);
        }}
        pad="xsmall"
        wrap={false}
      >
        {selected && (
          <Box flex={false} pad={{ right: 'xsmall' }}>
            <Icon color="brand" icon={MdClear} />
          </Box>
        )}

        <Text data-testid={option.id} title={label} truncate={true}>
          {label}
        </Text>
      </Area>
    );
  };

  const renderSelectionOptions = () => {
    return (
      <Box direction="row" justify="between">
        <Box align="start" direction="column" height="15em" width="20em">
          <Box fill="horizontal" flex={false} pad="xxsmall">
            <TextInput
              onChange={handleOnSearch}
              type="search"
              value={searchTerm}
            />
          </Box>
          <Box fill="horizontal" overflow={{ vertical: 'auto' }}>
            {options.length > 0 ? (
              // TODO (5216): add replace={true} after it has been fixed.
              // https://github.com/grommet/grommet/issues/5786
              <InfiniteScroll items={options}>
                {(option: Option) => renderOption(option, false)}
              </InfiniteScroll>
            ) : (
              <Box align="center" fill="horizontal" justify="center">
                <Paragraph color="grey">
                  {t('multi-select-field.no-options')}
                </Paragraph>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          align="start"
          border={{ color: 'border-default', side: 'all' }}
          direction="column"
          height="15em"
          margin="xxsmall"
          pad="xsmall"
          round="xsmall"
          width="20em"
        >
          <Box fill="horizontal" overflow={{ vertical: 'auto' }}>
            {selectedOptions.length !== 0 ? (
              selectedOptions.map((id: string) => {
                const item = allOptions.find(option => option?.id === id);
                return !!item && renderOption(item, true);
              })
            ) : (
              <Box align="center" fill="horizontal" justify="center">
                <Paragraph color="grey">
                  {t('multi-select-field.no-selected-options')}
                </Paragraph>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderDropOptions = () => {
    return (
      <Box
        align="center"
        direction="row-reverse"
        fill="horizontal"
        gap="xxsmall"
        margin="xxsmall"
        pad="xsmall"
      >
        <Button
          data-testid="form-multi-select__close"
          data-voleer-id="form-multi-select__close"
          disabled={isDisabled}
          label={t('multi-select-field.close')}
          onClick={handleOnClose}
          size="small"
        />
        <Button
          data-testid="form-multi-select__clear-all"
          data-voleer-id="form-multi-select__clear-all"
          disabled={isDisabled || selectedOptions.length === 0}
          label={t('multi-select-field.clear')}
          onClick={clearAllSelected}
          size="small"
        />
        <Button
          data-testid="form-multi-select__select-all"
          data-voleer-id="form-multi-select__select-all"
          disabled={isDisabled || options.length === 0}
          label={t('multi-select-field.select-all')}
          onClick={handleSelectAll}
          size="small"
        />
      </Box>
    );
  };

  const renderMultiSelectDrop = () => {
    return (
      <Box
        direction="column"
        pad={{
          top: 'small',
          left: 'small',
          right: 'small',
          bottom: 'xsmall',
        }}
      >
        {renderSelectionOptions()}
        {renderDropOptions()}
      </Box>
    );
  };

  return (
    <FormField
      error={error}
      label={<LabelMarkdown content={definition.label} />}
    >
      <DropButton
        data-testid="form-multi-select__drop-button"
        disabled={isDisabled}
        dropProps={{
          stretch: false,
          onClickOutside: () => handleOnClose(),
        }}
        label={
          <Box align="center" direction="row" justify="between">
            <Text color="grey">
              {definition.placeholder
                ? definition.placeholder
                : t('multi-select-field.default-placeholder')}
            </Text>
            <Icon icon={FaAngleDown} />
          </Box>
        }
        name={definition.name}
        onClick={() => setSelectionDropOpen(true)}
        onOpen={() => setSelectionBinOpen(true)}
        open={selectionDropOpen}
        ref={ref}
      >
        {renderMultiSelectDrop()}
      </DropButton>
      {selectionBinOpen && (
        <Area
          align="start"
          border={{ side: 'all', color: 'border-default' }}
          disabled={isDisabled}
          height="15em"
          margin={{ top: 'small' }}
          overflow="hidden"
          pad="xsmall"
          round="xsmall"
        >
          <Box fill="horizontal" overflow={{ vertical: 'auto' }}>
            {selectedOptions.map((id: string) => {
              const item = allOptions.find(option => option?.id === id);
              return !!item && renderOption(item, true);
            })}
          </Box>
        </Area>
      )}
      <Box
        align="center"
        direction="row"
        gap="xxsmall"
        margin={{ top: 'xsmall' }}
      >
        <Button
          disabled={isDisabled}
          label={
            selectionBinOpen
              ? t('multi-select-field.selection-bin-toggle.open', {
                  selectedCount: field.value?.length || 0,
                })
              : t('multi-select-field.selection-bin-toggle.close', {
                  selectedCount: field.value?.length || 0,
                })
          }
          onClick={() => toggleSelectionBinOpen()}
          size="small"
        />

        <Button
          disabled={isDisabled}
          label={t('multi-select-field.clear')}
          onClick={() => clearAllSelected()}
          size="small"
        />
      </Box>
    </FormField>
  );
});
