import React, { useContext, useMemo } from 'react';
import { PropsOf } from '@voleer/types';
import { Box, CheckBox, Grid, GridProps, ResponsiveContext } from 'grommet';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type MonthOption = {
  /**
   * Number representing the month, numbered 1-12.
   */
  value: number;

  /**
   * The i18n key for the option label.
   */
  i18nKey: string;
};

const MONTH_OPTIONS: MonthOption[] = [
  { value: 1, i18nKey: 'january' },
  { value: 2, i18nKey: 'february' },
  { value: 3, i18nKey: 'march' },
  { value: 4, i18nKey: 'april' },
  { value: 5, i18nKey: 'may' },
  { value: 6, i18nKey: 'june' },
  { value: 7, i18nKey: 'july' },
  { value: 8, i18nKey: 'august' },
  { value: 9, i18nKey: 'september' },
  { value: 10, i18nKey: 'october' },
  { value: 11, i18nKey: 'november' },
  { value: 12, i18nKey: 'december' },
];

const OptionsGrid = styled<React.FC<PropsOf<typeof Grid>>>(props => {
  const size = useContext(ResponsiveContext);

  // 3-column layout by default
  const defaultRows = new Array(MONTH_OPTIONS.length / 3).fill('auto');

  // Single column layout for smaller breakpoints
  const rows: Record<string, GridProps['rows']> = {
    small: new Array(MONTH_OPTIONS.length).fill('auto'),
  };

  return (
    <Grid
      gap="small"
      pad="xsmall"
      rows={rows[size] || defaultRows}
      {...props}
    />
  );
})`
  // Make the column contents flow vertically
  grid-auto-flow: column;
`;

export type MonthsSelectProps = {
  /**
   * Array containing the months that are currently selected.
   */
  value?: number[];

  /**
   * Called with an array of selected months when the selected months change.
   */
  onChange: (value: number[]) => void;
};

/**
 * Renders checkboxes for selecting the months when a schedule should run.
 */
export const MonthsSelect: React.FC<MonthsSelectProps> = props => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const onChange =
    (option: MonthOption) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const selected = new Set(props.value);

      if (checked) {
        selected.add(option.value);
      } else {
        selected.delete(option.value);
      }

      // Provide selected numbers in ascending order
      props.onChange(orderBy([...selected]));
    };

  const selectedValues = useMemo(() => {
    return (props.value ?? []).reduce((acc, value) => {
      acc[value] = true;
      return acc;
    }, {} as Record<number, boolean>);
  }, [props.value]);

  return (
    <OptionsGrid>
      {MONTH_OPTIONS.map(option => (
        <Box key={option.i18nKey}>
          <CheckBox
            checked={!!selectedValues[option.value]}
            label={t(`fields.month.value-labels.${option.i18nKey}`)}
            name={`month_${option.value}`}
            onChange={onChange(option)}
          />
        </Box>
      ))}
    </OptionsGrid>
  );
};
