import React from 'react';
import {
  TextAreaDescription,
  TextInputHeading,
  useTextAreaDescription,
  useTextInputHeading,
} from '@common/components';
import {
  EditIntegration,
  useIntegrationModalEditIntegrationInstanceMutation,
  useIntegrationModalQuery,
} from '@generated/graphql-code-generator';
import { Modal } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { IntegrationModalToolbar } from '..';
import {
  IntegrationMetadataInfoRow,
  IntegrationModalProps,
  IntegrationStatusBadge,
} from '../../..';

export type IntegrationModalHeaderProps = Pick<
  IntegrationModalProps,
  'integrationId' | 'onClose'
>;

/**
 * Renders the header for the IntegrationModal.
 */
export const IntegrationModalHeader: React.FC<IntegrationModalHeaderProps> = ({
  integrationId,
  onClose,
}) => {
  const { data } = useIntegrationModalQuery({
    fetchPolicy: 'cache-only',
    variables: { integrationId },
  });

  const integration = data?.integration;
  const integrationMetadata = integration?.integrationMetadata;

  const onFieldSave =
    (field: keyof EditIntegration) => async (value: string) => {
      await editIntegrationInstance({
        variables: {
          input: {
            id: integrationId,
            data: {
              [field]: value,
            },
          },
        },
      });
    };

  const [editIntegrationInstance] =
    useIntegrationModalEditIntegrationInstanceMutation();

  const { textInputHeadingProps } = useTextInputHeading({
    initialValue: integration?.displayName,
    onSave: onFieldSave('displayName'),
  });

  const { textAreaDescriptionProps } = useTextAreaDescription({
    initialValue: integration?.description,
    onSave: onFieldSave('description'),
  });

  // Data is expected to be loaded by the IntegrationModal component
  if (!integration) {
    return null;
  }

  return (
    <Modal.Header border="bottom" pad="medium">
      <Box align="start" direction="row" gap="small" justify="between">
        <Box>
          <TextInputHeading {...textInputHeadingProps} />

          <TextAreaDescription {...textAreaDescriptionProps} />

          <Box margin={{ vertical: 'small' }}>
            <IntegrationStatusBadge status={integration.status} />
          </Box>
        </Box>
        <Box flex={{ shrink: 0 }}>
          <IntegrationModalToolbar
            integrationId={integrationId}
            onClose={onClose}
          />
        </Box>
      </Box>

      {integrationMetadata && (
        <IntegrationMetadataInfoRow
          integrationMetadataId={integrationMetadata.id}
          margin={{ top: 'small' }}
        />
      )}
    </Modal.Header>
  );
};
