import React from 'react';
import { ScheduledJobStatus } from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';
import { IconBaseProps } from 'react-icons';
import {
  FaCheck,
  FaExclamationCircle,
  FaPauseCircle,
  FaPlayCircle,
} from 'react-icons/fa';

const statusMapping: Record<ScheduledJobStatus, BadgeStatus> = {
  [ScheduledJobStatus.Unknown]: BadgeStatus.unknown,
  [ScheduledJobStatus.Active]: BadgeStatus.ok,
  [ScheduledJobStatus.Paused]: BadgeStatus.info,
  [ScheduledJobStatus.Completed]: BadgeStatus.ok,
  [ScheduledJobStatus.Terminated]: BadgeStatus.error,
  [ScheduledJobStatus.Canceled]: BadgeStatus.error,
};

const iconMapping: Record<
  ScheduledJobStatus,
  React.ComponentType<IconBaseProps> | undefined
> = {
  [ScheduledJobStatus.Unknown]: undefined,
  [ScheduledJobStatus.Active]: FaPlayCircle,
  [ScheduledJobStatus.Paused]: FaPauseCircle,
  [ScheduledJobStatus.Completed]: FaCheck,
  [ScheduledJobStatus.Terminated]: FaExclamationCircle,
  [ScheduledJobStatus.Canceled]: undefined,
};

const i18nMapping: Record<ScheduledJobStatus, string> = {
  [ScheduledJobStatus.Unknown]: 'status-unknown',
  [ScheduledJobStatus.Active]: 'status-active',
  [ScheduledJobStatus.Paused]: 'status-paused',
  [ScheduledJobStatus.Completed]: 'status-completed',
  [ScheduledJobStatus.Terminated]: 'status-terminated',
  [ScheduledJobStatus.Canceled]: 'status-canceled',
};

type ScheduledJobStatusBadgeProps = Omit<BadgeProps, 'label' | 'status'> & {
  status: ScheduledJobStatus;
};

/**
 * Renders a badge to display the given Scheduled Job status.
 */
export const ScheduledJobStatusBadge: React.FC<ScheduledJobStatusBadgeProps> =
  ({ status, ...props }) => {
    const [t] = useTranslation(
      'features/scheduled-jobs/components/ScheduledJobStatusBadge'
    );
    const label = t(i18nMapping[status]);
    return (
      <Badge
        {...props}
        icon={iconMapping[status]}
        label={label}
        status={statusMapping[status]}
      />
    );
  };
