import React from 'react';
import { useAccountStatus } from '@common/hooks';
import { PropsOf } from '@voleer/types';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';

type AddUserButtonProps = PropsOf<typeof Button>;

/**
 * Renders a button for adding a new tenant member.
 */
export const AddUserButton: React.FC<AddUserButtonProps> = props => {
  const [t] = useTranslation('pages/TenantSettingsPage');
  const { disableWriteOperation } = useAccountStatus();

  return (
    <Button
      {...props}
      data-testid="add-user-button"
      disabled={props.disabled || disableWriteOperation}
      label={t('members-tab.new-user')}
      primary={true}
    />
  );
};
