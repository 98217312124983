import React, { Context, createContext } from 'react';
import { useTypedFlags } from '@features/launch-darkly';
import {
  TenantFragment,
  TenantMemberFragment,
  useGetCurrentUserTenantMemberQuery,
} from '@generated/graphql-code-generator';

export type TenantContextDataValue = Readonly<{
  /**
   * The current Tenant.
   */
  tenant: TenantFragment | null;

  /**
   * The current TenantMember.
   */
  tenantMember: TenantMemberFragment | null;
}>;

/**
 * Context to provide current Tenant and TenantMember information.
 */
export const TenantContextData: Context<TenantContextDataValue> =
  createContext<TenantContextDataValue>({
    tenant: null,
    tenantMember: null,
  });

/**
 * Load the current tenant and tenant member information and put them in a context.
 * Suspend the rendering of its content while the data is being loaded.
 */
export const TenantContextProvider: React.FC = ({ children }) => {
  const { 'tenant-ui-polling-configuration': pollingConfiguration } =
    useTypedFlags();

  // Load the current tenant and tenantMember information and suspend rendering
  const getCurrentUserTenantMemberQueryResult =
    useGetCurrentUserTenantMemberQuery({
      fetchPolicy: 'cache-and-network',
      pollInterval: pollingConfiguration?.tenantContext,
    });

  // Extract information
  const tenant = getCurrentUserTenantMemberQueryResult.data?.me?.tenant ?? null;
  const tenantMember = getCurrentUserTenantMemberQueryResult.data?.me ?? null;

  return (
    <TenantContextData.Provider
      children={children}
      value={{ tenantMember, tenant }}
    />
  );
};
