import React, { isValidElement } from 'react';
import { RotatingIcon } from '@voleer/ui-kit';
import { Box, Text } from 'grommet';
import { FaSpinner } from 'react-icons/fa';
import { IconBaseProps } from 'react-icons/lib/cjs';

export type LoadingOverlayProps = {
  /**
   * Whether or not the loading overlay should be active.
   */
  loading?: boolean;

  /**
   * Custom icon to display with the loading indicator.
   */
  icon?: React.ComponentType<IconBaseProps> | React.ReactElement;

  /**
   * Label to display with the loading indicator.
   */
  label?: React.ReactNode;
};

/**
 * Renders a loading overlay.
 */
export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  children,
  label,
  icon,
  ...props
}) => {
  const hasChildren = !!children;

  const loading =
    (!hasChildren && typeof props.loading === 'undefined') || !!props.loading;

  if (!loading) {
    return <>{children}</>;
  }

  const theIcon = (() => {
    if (isValidElement(icon)) {
      return icon;
    }

    return (
      <RotatingIcon
        color="dark-2"
        icon={icon || FaSpinner}
        size={label ? 'large' : 'xxlarge'}
      />
    );
  })();

  return (
    <Box align="center" fill={true}>
      <Box basis="65%" justify="center" pad="medium">
        <Box align="center" flex={{ shrink: 0 }} gap="small">
          {theIcon}
          <Text color="dark-2" size="large">
            {label}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
