import React, { useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useFuseState, useLoadMoreQuery } from '@common/hooks';
import { urlFor } from '@common/utils';
import {
  CancelRunModal,
  WorkflowRunsTable,
  WorkflowRunsTableProps,
  useCancelRunModal,
} from '@features/workflows';
import {
  LaunchedByType,
  Workspace,
  WorkspacePageRunsDocument,
  useWorkspacePageRunsQuery,
  useWorkspacePageSaveTemplateConfigurationMutation,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';
import { compact } from 'lodash';
import { useTypedFlags } from '../../../../features/launch-darkly';

type WorkspaceRunsTabProps = {
  workspace: Pick<Workspace, 'id'>;
};

/**
 * Renders the tab for a Workspace's Runs.
 */
export const WorkspaceRunsTab: React.FC<WorkspaceRunsTabProps> = ({
  workspace,
}) => {
  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();
  const [selectedLaunchTypeFilters, setSelectedLaunchTypeFilters] = useState<
    LaunchedByType[] | undefined
  >();

  const { data, fetchMore, poll, variables, networkStatus, ...query } =
    useLoadMoreQuery(useWorkspacePageRunsQuery, {
      variables: {
        workspaceId: workspace.id,
        first: 1000,
        launchedByTypes: selectedLaunchTypeFilters,
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: pollingConfig?.workspaceInstancesLists,
      getPageInfo: data => data?.instances?.pageInfo,
    });

  const [saveTemplateConfiguration] =
    useWorkspacePageSaveTemplateConfigurationMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(WorkspacePageRunsDocument)!],
    });

  const { openCancelRunModal, cancelRunModalProps } = useCancelRunModal({
    onCompleted: () => {
      // Poll the list immediately after cancel is completed so that the sort
      // order is updated
      poll();
    },
  });

  const instancesItems = useMemo(
    () => data?.instances?.edges?.map(edge => edge?.node),
    [data?.instances?.edges]
  );
  const searchItems = useMemo(() => {
    return compact(instancesItems).map(item => {
      const templateMetadataName = item.templateMetadata?.displayName;
      const templateConfigurationName = item.templateConfiguration?.displayName;

      return {
        ...item,

        // Augment data with properties that can be used by search
        templateMetadataName,
        templateConfigurationName,
      };
    });
  }, [instancesItems]);

  const {
    searchTerm,
    setSearchTerm,
    searchResults: instances,
  } = useFuseState({
    items: searchItems,
    fuseOptions: {
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true,
      keys: ['templateMetadataName', 'templateConfigurationName'],
    },
  });

  const onSearch: WorkflowRunsTableProps['onSearch'] = event => {
    setSearchTerm(event.target.value);
  };

  const loading =
    (!data && query.loading) || networkStatus === NetworkStatus.setVariables;

  const onSaveTemplateConfiguration: WorkflowRunsTableProps['onSaveTemplateConfiguration'] =
    async ({ templateConfiguration, displayName }) => {
      const templateConfigurationId = templateConfiguration.id;
      if (!templateConfigurationId) {
        return;
      }

      await saveTemplateConfiguration({
        variables: {
          input: {
            workspaceId: workspace.id,
            templateConfigurationId,
            displayName,
          },
        },
      });
    };

  const urlForTemplate: WorkflowRunsTableProps['urlForTemplate'] = ({ id }) =>
    urlFor('libraryItemById')({ itemId: id });

  const urlForInstance: WorkflowRunsTableProps['urlForInstance'] = ({ id }) =>
    urlFor('workflowInstance')({
      workspaceId: workspace.id,
      workflowInstanceId: id,
    });

  const urlForDataSet: WorkflowRunsTableProps['urlForDataSet'] = ({ id }) =>
    urlFor('dataSet')({
      workspaceId: workspace.id,
      dataSetId: id,
    });

  const urlForTemplateConfiguration: WorkflowRunsTableProps['urlForTemplateConfiguration'] =
    ({ id }) =>
      urlFor('templateConfiguration')({
        workspaceId: workspace.id,
        templateConfigurationId: id,
      });

  const urlForScheduledJob: WorkflowRunsTableProps['urlForScheduledJob'] = ({
    id,
  }) =>
    urlFor('scheduledJob')({
      workspaceId: workspace.id,
      scheduledJobId: id,
    });

  const pageInfo = data?.instances?.pageInfo;

  const onLoadMore = useMemo(() => {
    if (!pageInfo?.hasNextPage) {
      return undefined;
    }

    return async () => {
      await fetchMore();
    };
  }, [fetchMore, pageInfo]);

  return (
    <Box fill={true} pad={{ horizontal: 'medium', top: 'medium' }}>
      <WorkflowRunsTable
        instances={instances}
        loading={loading}
        onLaunchTypeFiltersChange={setSelectedLaunchTypeFilters}
        onLoadMore={onLoadMore}
        onSaveTemplateConfiguration={onSaveTemplateConfiguration}
        onSearch={onSearch}
        openCancelRunModal={openCancelRunModal}
        searchTerm={searchTerm}
        selectedLaunchTypeFilters={selectedLaunchTypeFilters}
        urlForDataSet={urlForDataSet}
        urlForInstance={urlForInstance}
        urlForScheduledJob={urlForScheduledJob}
        urlForTemplate={urlForTemplate}
        urlForTemplateConfiguration={urlForTemplateConfiguration}
        workspaceId={workspace.id}
      />
      <CancelRunModal {...cancelRunModalProps} />
    </Box>
  );
};
