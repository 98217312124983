import React, { useMemo } from 'react';
import { PropsOf } from '@voleer/types';
import { Link } from '@voleer/ui-kit';
import { useHistory, useLocation } from 'react-router';
import { CURRENT_STEP_PARAM } from '../use-step-list';

type StepLinkProps = Omit<PropsOf<typeof Link>, 'to'> & {
  stepId: string | undefined;
};

/**
 * Renders a link to the given step ID in the `WorkflowInstanceView`.
 *
 * If `stepId` is `undefined` then the link will un-select any currently
 * selected step.
 */
export const StepLink: React.FC<StepLinkProps> = ({ stepId, ...props }) => {
  const history = useHistory();
  const location = useLocation();

  const urlForStep = useMemo(() => {
    const params = new URLSearchParams(location.search);

    if (stepId) {
      params.set(CURRENT_STEP_PARAM, stepId);
    } else {
      params.delete(CURRENT_STEP_PARAM);
    }

    return history.createHref({
      ...location,
      search: params.toString(),
    });
  }, [history, location, stepId]);

  return <Link to={urlForStep} {...props} />;
};
