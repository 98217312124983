import { useState } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  WorkflowInstanceViewDocument,
  useCancelRunModalMutation,
} from '@generated/graphql-code-generator';
import {
  CancelRunModalProps,
  OpenCancelRunModalFn,
  OpenCancelRunModalProps,
} from './CancelRunModal';

type UseCancelRunModalResult = {
  /**
   * Opens the modal for the given instance.
   */
  openCancelRunModal: OpenCancelRunModalFn;

  /**
   * Props to pass to the `CancelRunModal` component.
   */
  cancelRunModalProps: CancelRunModalProps;
};

type UseCancelRunModalOptions = {
  /**
   * Callback to be executed when the cancel mutation is completed.
   */
  onCompleted?: () => void;
};

type UseCancelRunModalHook = (
  options?: UseCancelRunModalOptions
) => UseCancelRunModalResult;

/**
 * Hook used to set up cancel run modal.
 * Returns function to call to open modal and props to be passed to modal component.
 */
export const useCancelRunModal: UseCancelRunModalHook = ({
  onCompleted,
} = {}) => {
  const [open, setOpen] = useState(false);
  const [cancelVariables, setCancelVariables] =
    useState<OpenCancelRunModalProps>();

  const [cancelRunModalMutation, { loading, error }] =
    useCancelRunModalMutation({
      onCompleted: () => {
        if (typeof onCompleted === 'function') {
          onCompleted();
        }
      },
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getOperationName(WorkflowInstanceViewDocument)!,
      ],
    });

  const openCancelRunModal = ({
    workspaceId,
    instanceId,
  }: OpenCancelRunModalProps) => {
    setCancelVariables({ workspaceId, instanceId });
    setOpen(true);
  };

  const onConfirm = async () => {
    if (cancelVariables) {
      await cancelRunModalMutation({
        variables: { ...cancelVariables },
      });
      setOpen(false);
    }
  };

  const onCancel = () => setOpen(false);

  return {
    openCancelRunModal,
    cancelRunModalProps: {
      open,
      loading,
      error,
      onConfirm,
      onCancel,
    },
  };
};
