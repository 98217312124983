import React, { useState } from 'react';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaShareAlt } from 'react-icons/fa';
import {
  ShareLibraryItemModal,
  ShareLibraryItemModalProps,
} from './components';

/**
 * ShareLibraryItemButton component props
 */
export type ShareLibraryItemButtonProps = Pick<
  ShareLibraryItemModalProps,
  'itemDisplayName' | 'itemName' | 'packageName' | 'publisherName' | 'type'
>;

/**
 * Share button that opens the ShareLibraryItemModal
 */
export const ShareLibraryItemButton = ({
  itemDisplayName,
  itemName,
  packageName,
  publisherName,
  type,
}: ShareLibraryItemButtonProps) => {
  const [t] = useTranslation('components/ShareLibraryItemButton');
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        icon={<FaShareAlt />}
        label={t('share-button-label')}
        onClick={() => setOpen(true)}
      />
      <ShareLibraryItemModal
        itemDisplayName={itemDisplayName}
        itemName={itemName}
        onClose={() => setOpen(false)}
        open={isOpen}
        packageName={packageName}
        publisherName={publisherName}
        type={type}
      />
    </>
  );
};
