import { useState } from 'react';
import { urlFor } from '@common/utils';
import {
  DataSet,
  useDeleteDataSetModalQuery,
  useDeleteDataSetModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DeleteDataSetModalProps } from './DeleteDataSetModal';

type DataSetParam = Pick<DataSet, 'id'>;

export type UseDeleteDataSetModalResult = {
  /**
   * Props passed to the `DeleteDataSetModal` component.
   */
  deleteDataSetModalProps: DeleteDataSetModalProps;

  /**
   * Handler for opening the modal.
   */
  openDeleteDataSetModal: (dataSet: DataSetParam) => void;
};

/**
 * Hook providing state and operations for the `DeleteDataSetModal`.
 */
export const useDeleteDataSetModal = (): UseDeleteDataSetModalResult => {
  const [t] = useTranslation(
    'features/workflows/components/DeleteDataSetModal'
  );

  const [dataSetId, setDataSetId] = useState<string | undefined>();

  const [confirmationText, setConfirmationText] = useState<string>('');

  const routerHistory = useHistory();

  const { data, loading } = useDeleteDataSetModalQuery({
    skip: !dataSetId,
    variables: {
      // Ignore potentially undefined dataSetId since the query will be skipped if dataSetId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataSetId: dataSetId!,
    },
  });

  const dataSet = data?.dataSet;
  const dataSetDependentTemplates =
    dataSet?.dependentTemplateConfigurations?.items;

  const [deleteDataSet, { error, loading: submitting }] =
    useDeleteDataSetModalSubmitMutation({
      errorPolicy: 'all',
    });

  const onChangeConfirmationText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmationText(e.target.value);

  const onConfirm: DeleteDataSetModalProps['onConfirm'] = async () => {
    if (
      !dataSetId ||
      !dataSet?.workspaceId ||
      confirmationText !== t('confirmation-key')
    ) {
      return;
    }

    const result = await deleteDataSet({
      variables: {
        workspaceId: dataSet.workspaceId,
        dataSetId,
      },
    });

    if (!result.errors?.length) {
      closeDeleteDataSetModal();
      routerHistory.push(
        urlFor('workspaceDataSets')({ workspaceId: dataSet.workspaceId })
      );
    }
  };

  const openDeleteDataSetModal: UseDeleteDataSetModalResult['openDeleteDataSetModal'] =
    dataSet => {
      setDataSetId(dataSet.id);
    };

  const closeDeleteDataSetModal: DeleteDataSetModalProps['onClose'] = () => {
    setDataSetId(undefined);
    setConfirmationText('');
  };

  return {
    deleteDataSetModalProps: {
      confirmationText,
      createdBy: dataSet?.createdBy,
      createdOn: dataSet?.createdOn,
      dependentTemplates: dataSetDependentTemplates,
      description: dataSet?.description,
      displayName: dataSet?.displayName,
      error,
      loading,
      open: !!dataSetId,
      onChangeConfirmationText,
      onClose: closeDeleteDataSetModal,
      onConfirm,
      status: dataSet?.status,
      submitting,
    },
    openDeleteDataSetModal,
  };
};
