import React, { Reducer, useReducer } from 'react';
import { LoadingOverlay } from '@common/components';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  TenantMemberRole,
  useTenantHasExternalIdpQuery,
} from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { UnreachableCaseError } from '@voleer/types';
import { Alert, Anchor, Link } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, FormField, Heading, Text, TextInput } from 'grommet';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaExclamation } from 'react-icons/fa';
import * as Yup from 'yup';

const PROFILE_BOX_WIDTH = '480px';
const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
const defaultValues = {
  currentPassword: '',
  newPassword: '',
};

export enum ChangePasswordReturnCode {
  PasswordMismatch = 'PasswordMismatch',
  UnknownError = 'UnknownError',
  Success = 'Success',
}

export type ChangePasswordFormValues = typeof defaultValues;

export type UserProfileProps = {
  onChangePassword: FormikSubmitFn<
    ChangePasswordFormValues,
    ChangePasswordReturnCode
  >;
  userName: string;
  userEmailAddress: string;
  userRole: TenantMemberRole;
};

type LabelProps = {
  label: string;
  unmasked: boolean;
  toggleMask: () => void;
};

const validationSchema = ({ t }: { t: typeof i18next.t }) => {
  return Yup.object().shape({
    currentPassword: Yup.string().required(t('validation.password-required')),
    newPassword: Yup.string()
      .required(t('validation.password-required'))
      .matches(passwordRegExp, t('validation.password-invalid')),
  });
};

/**
 * Renders the label for the input field.
 */
const TextInputFieldLabel: React.FC<LabelProps> = ({
  label,
  unmasked,
  toggleMask,
}) => {
  const [t] = useTranslation('features/users/components/UserProfile');

  // Render a label with show/hide buttons for masked inputs
  return (
    <Box direction="row">
      <Box flex={{ grow: 1 }}>{label}</Box>
      <Box>
        <Anchor onClick={toggleMask} variation="primary">
          <Text size="small">
            {unmasked ? t('password-toggle.hide') : t('password-toggle.show')}
          </Text>
        </Anchor>
      </Box>
    </Box>
  );
};

type UserProfileState = {
  currentPasswordUnmasked: boolean;
  newPasswordUnmasked: boolean;
  changePassword: boolean;
  displaySuccess: boolean;
};

type UserProfileAction =
  | { type: 'setChangePassword'; value: boolean }
  | { type: 'setCurrentPasswordMask'; value: boolean }
  | { type: 'setDisplaySuccess'; value: boolean }
  | { type: 'setNewPasswordMask'; value: boolean };

const reducer: Reducer<UserProfileState, UserProfileAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'setDisplaySuccess':
      return { ...state, displaySuccess: action.value };
    case 'setChangePassword':
      return { ...state, changePassword: action.value };
    case 'setCurrentPasswordMask':
      return {
        ...state,
        currentPasswordUnmasked: action.value,
      };
    case 'setNewPasswordMask':
      return { ...state, newPasswordUnmasked: action.value };
    default:
      throw new UnreachableCaseError(action);
  }
};

/**
 * Renders a component used to edit the user profile.
 */
export const UserProfile: React.FC<UserProfileProps> = ({
  onChangePassword,
  userName,
  userEmailAddress,
  userRole: role,
}) => {
  const [t] = useTranslation('features/users/components/UserProfile');

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const tenantHasExternalIdpQuery = useTenantHasExternalIdpQuery({
    pollInterval: pollingConfig?.tenantSettingsAuthentication,
    fetchPolicy: 'cache-and-network',
  });

  const initialState: UserProfileState = {
    currentPasswordUnmasked: false,
    newPasswordUnmasked: false,
    changePassword: false,
    displaySuccess: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const onSubmitChangePassword =
    () =>
    async (
      values: ChangePasswordFormValues,
      formikActions: FormikHelpers<ChangePasswordFormValues>
    ) => {
      dispatch({ type: 'setDisplaySuccess', value: false });
      const code = await onChangePassword(values, formikActions);
      formikActions.setSubmitting(false);
      switch (code) {
        case ChangePasswordReturnCode.Success: {
          formikActions.setStatus({
            serverError: '',
          });
          dispatch({ type: 'setDisplaySuccess', value: true });
          dispatch({ type: 'setChangePassword', value: false });
          return;
        }
        case ChangePasswordReturnCode.PasswordMismatch: {
          formikActions.setFieldError(
            'currentPassword',
            t('errors.current-password-incorrect')
          );
          return;
        }
        case ChangePasswordReturnCode.UnknownError: {
          formikActions.setStatus({
            serverError: t('errors.unknown-error'),
          });
          return;
        }
        default:
          throw new UnreachableCaseError(code);
      }
    };

  const toggleCurrentPasswordMask = () =>
    dispatch({
      type: 'setCurrentPasswordMask',
      value: !state.currentPasswordUnmasked,
    });
  const toggleNewPasswordMask = () =>
    dispatch({ type: 'setNewPasswordMask', value: !state.newPasswordUnmasked });
  const toggleChangePassword = () =>
    dispatch({ type: 'setChangePassword', value: !state.changePassword });

  const isAdmin = role === TenantMemberRole.Admin;

  return (
    <LoadingOverlay
      loading={
        !tenantHasExternalIdpQuery.data && tenantHasExternalIdpQuery.loading
      }
    >
      <Box
        background="white"
        elevation="small"
        flex={{ shrink: 0 }}
        gap="medium"
        pad={{
          top: 'small',
          bottom: 'medium',
          horizontal: 'medium',
        }}
        round="xsmall"
        width={PROFILE_BOX_WIDTH}
      >
        <Box>
          <Heading level="4">{t('my-profile.header')}</Heading>
          <Alert icon={true} margin={{ bottom: 'small' }} status="info">
            {t('my-profile.change-name-info')}
            {isAdmin && (
              <Link
                data-testid="user-profile__tenant-settings-link"
                to={urlFor('userManagement')}
                variation="primary"
              >
                {t('my-profile.tenant-settings-link')}
              </Link>
            )}
          </Alert>
          <Box direction="row">
            <Box basis="50%" flex={{ grow: 1 }}>
              <Text color="dark-2" size="small">
                {t('my-profile.name')}
              </Text>
              <Text size="medium">{userName}</Text>
            </Box>
            <Box basis="50%" flex={{ grow: 1 }}>
              <Text color="dark-2" size="small">
                {t('my-profile.email-address')}
              </Text>
              <Text size="medium">{userEmailAddress}</Text>
            </Box>
          </Box>
        </Box>

        {!tenantHasExternalIdpQuery.data?.tenantIdp
          ?.externalProviderEnabled && (
          <>
            <Box
              data-testid="user-profile__password-section"
              direction="row"
              gap="small"
            >
              <Heading level="4">{t('password.header')}</Heading>
              {state.displaySuccess && (
                <Box
                  animation={{ type: 'fadeIn', duration: 300, delay: 200 }}
                  direction="row"
                >
                  <Text alignSelf="center" color="status-ok" size="small">
                    <FaCheck size="14px" />
                    &nbsp; {t('password-change-success')}
                  </Text>
                </Box>
              )}
            </Box>

            {!state.changePassword && (
              <Box direction="row" gap="small">
                <Text alignSelf="center" size="large">
                  ··········
                </Text>
                <Button
                  data-testid="user-profile__change-password-btn"
                  label={t('change-password')}
                  onClick={toggleChangePassword}
                />
              </Box>
            )}

            {state.changePassword && (
              <Formik
                initialValues={defaultValues}
                onSubmit={onSubmitChangePassword()}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema({ t })}
              >
                {formProps => {
                  const onCancel = () => {
                    formProps.resetForm();
                    toggleChangePassword();
                    dispatch({ type: 'setDisplaySuccess', value: false });
                  };

                  return (
                    <Form data-testid="user-profile__change-password-form">
                      {formProps.status?.serverError && (
                        <Alert
                          icon={FaExclamation}
                          margin={{ bottom: 'medium' }}
                          status="error"
                        >
                          {formProps.status.serverError}
                        </Alert>
                      )}
                      <Field name="currentPassword">
                        {(fieldProps: FieldProps<string>) => {
                          const fieldError =
                            fieldProps.form.touched.currentPassword &&
                            fieldProps.form.errors.currentPassword;
                          return (
                            <FormField
                              error={fieldError}
                              label={
                                <TextInputFieldLabel
                                  label={t('form.current-password.label')}
                                  toggleMask={toggleCurrentPasswordMask}
                                  unmasked={state.currentPasswordUnmasked}
                                />
                              }
                            >
                              <TextInput
                                {...fieldProps.field}
                                type={
                                  !state.currentPasswordUnmasked
                                    ? 'password'
                                    : undefined
                                }
                              />
                            </FormField>
                          );
                        }}
                      </Field>
                      <Field name="newPassword">
                        {(fieldProps: FieldProps<string>) => {
                          const fieldError =
                            fieldProps.form.touched.newPassword &&
                            fieldProps.form.errors.newPassword;
                          return (
                            <FormField
                              error={fieldError}
                              label={
                                <TextInputFieldLabel
                                  label={t('form.new-password.label')}
                                  toggleMask={toggleNewPasswordMask}
                                  unmasked={state.newPasswordUnmasked}
                                />
                              }
                            >
                              <TextInput
                                {...fieldProps.field}
                                type={
                                  !state.newPasswordUnmasked
                                    ? 'password'
                                    : undefined
                                }
                              />
                              {!fieldProps.form.errors.newPassword && (
                                <Text
                                  color="dark-2"
                                  margin={{ left: 'small', top: 'xsmall' }}
                                  size="small"
                                >
                                  {t('password-rules')}
                                </Text>
                              )}
                            </FormField>
                          );
                        }}
                      </Field>
                      <Box direction="row" gap="xsmall" justify="end">
                        <Button
                          label={t('form.cancel.label')}
                          onClick={onCancel}
                        />
                        <Button
                          data-testid="user-profile__submit-new-password-btn"
                          disabled={
                            !formProps.dirty ||
                            formProps.isValidating ||
                            formProps.isSubmitting
                          }
                          label={t('form.submit.label')}
                          primary={true}
                          type="submit"
                        />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </>
        )}
      </Box>
    </LoadingOverlay>
  );
};
