import { User, UserManager } from 'oidc-client';
import { OidcStatus } from '.';

type SignInRedirectState = {
  state: {
    returnUrl?: string;
  };
};

export type AuthenticationHandler = (ctx: {
  manager: UserManager;
  status: OidcStatus;
}) => Promise<User> | User;

/**
 * Attempts to silently renew the token. If the token cannot be renewed then
 * triggers the sign-in redirect flow.
 */
export const renewOrLogin: AuthenticationHandler = async ({
  manager,
  status,
}): Promise<User> => {
  const user = await manager.getUser();

  // Build the redirect state
  const state: SignInRedirectState = {
    state: {
      returnUrl: window ? window.location.href : undefined,
    },
  };

  // Handle unauthenticated
  if (!user) {
    await manager.signinRedirect(state);
    return manager.getUser();
  }

  // Handle renewal or signed out
  // Signed out uses the same behavior as "expired" because it's possible that
  // the user logged out and logged back in both on another tab, if it happens,
  // a new session will be retrieved on silent renew and no redirect to login
  // will be required.
  if (
    status === OidcStatus.Expiring ||
    user.expired ||
    status === OidcStatus.SignedOut
  ) {
    try {
      // Try to renew the token silently
      return await manager.signinSilent();
    } catch (err) {
      // Log any errors except expected ones like "login_required"
      if (err.message !== 'login_required') {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      // If silent renewal fails, send the user through the redirect flow instead
      await manager.signinRedirect(state);
      return manager.getUser();
    }
  }

  return user;
};
