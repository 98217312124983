import React, { useMemo } from 'react';
import {
  TimezoneOption,
  useFuseState,
  useTimezoneOptions,
} from '@common/hooks';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { useFormikContext } from 'formik';
import { Select, SelectProps } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../../components';
import { ScheduledJobFormValues } from '../../interface';

/**
 * Renders a form field that contains a select box for selecting a timezone from
 * a list of timezone values.
 */
export const TimezoneField: React.FC = () => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobForm'
  );

  const { timezoneOptions, timezoneOptionsMap } = useTimezoneOptions();

  const formik = useFormikContext<ScheduledJobFormValues>();

  const originalOnChange = formik.getFieldProps('timezoneName').onChange;
  const value = formik.values.timezoneName;

  const selectedOption = useMemo(
    () => (typeof value === 'string' ? timezoneOptionsMap[value] : undefined),
    [timezoneOptionsMap, value]
  );

  const options = useMemo(() => {
    const filteredOptions = timezoneOptions.filter(
      option => option.id !== selectedOption?.id
    );

    // Shift the currently selected option to the top of the list
    return selectedOption
      ? [selectedOption, ...filteredOptions]
      : filteredOptions;
  }, [selectedOption, timezoneOptions]);

  const { setSearchTerm, searchResults } = useFuseState({
    items: options,
    fuseOptions: {
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true,
      keys: ['label'],
    },
  });

  const onChange = (event: GrommetSelectEvent<TimezoneOption>) => {
    if (!event.value?.id) {
      return;
    }

    // Formik expects event.target.value to contain the new value but Grommet
    // puts it in event.value: https://v2.grommet.io/select#onChange
    event.target.value = event.value.id;

    // Clear the search term to reset search results
    setSearchTerm('');

    return originalOnChange(event);
  };

  const onSearch: SelectProps['onSearch'] = search => {
    setSearchTerm(search);
  };

  return (
    <>
      <FormLabel>{t('fields.timezone-name.label')}</FormLabel>
      <Select
        {...formik.getFieldProps('timezoneName')}
        data-testid="timezone-field__select"
        dropHeight="medium"
        labelKey="label"
        onChange={onChange}
        onSearch={onSearch}
        options={searchResults}
        value={selectedOption}
        valueKey="id"
      />
    </>
  );
};
