import { Validator, ValidatorPredicate } from '.';

/**
 * Creates a validator function from the given predicate and message.
 *
 * When applying the validator: If validation fails, an array containing a
 * single validation message is returned. If validation passes, an empty array
 * is returned.
 *
 * As a convenience for predicates that take additional arguments, those
 * additional arguments can be passed as the last arguments to
 * `createValidator`.
 *
 * Using existing predicates:
 *
 * ```javascript
 * const emailValidator = createValidator(validatorJs.isEmail, 'must be an email address');
 * await emailValidator('notanemail'); // => ['must be an email address']
 * await emailValidator('bob@bob.com'); // => []
 * ```
 *
 * Predicates that take additional arguments:
 *
 * ```javascript
 * const minLengthValidator = createValidator(
 *   validatorJs.isLength,
 *   'must be at least 5 characters',
 *   5
 * );
 * await minLengthValidator('1234'); // => ['must be at least 5 characters']
 * ```
 *
 * Inline predicate:
 *
 * ```javascript
 * const ageValidator = createValidator(
 *   (value: number) => value >= 0,
 *   'must be greater or equal to 0'
 * );
 * await ageValidator(-1); // => ['must be greater or equal to 0']
 * ```
 *
 * @param predicate predicate function
 * @param message validation error message
 * @param args predicate function arguments, if any
 */
export const createValidator = <TValue, TArgs extends unknown[]>(
  predicate: ValidatorPredicate<TValue, TArgs>,
  message: ValidatorMessageFn<TValue, TArgs> | string,
  ...args: TArgs
): Validator<TValue> => {
  return async value => {
    const valid: boolean = await predicate(value, ...args);
    if (valid) {
      return [];
    }
    if (typeof message === 'function') {
      return [
        message({
          valid,
          value,
          predicate,
        }),
      ];
    }
    return [message];
  };
};

/**
 * Composes multiple validators into a single validator.
 *
 * When applying the returned validator function: If validation fails, an array
 * containing the message from the first failed validator will be returned. If
 * validation passes, an empty array is returned.
 *
 * ```javascript
 * const lowercaseEmailValidator = composeValidators(
 *   createValidator(validatorJs.isEmail, 'must be an email address'),
 *   createValidator(validatorJs.isLowercase, 'must be lowercase'),
 * );
 * await lowercaseEmailValidator('notanemail'); // => ['must be an email address']
 * await lowercaseEmailValidator('EMAILBUTNOT@LOWERCASE.COM'); // => ['must be lowercase']
 * await lowercaseEmailValidator('bob@bob.com'); // => []
 * ```
 *
 * @param validators
 */
export const composeValidators = <TValue>(
  validators: Array<Validator<TValue>>
): Validator<TValue> => {
  return async (value: TValue): Promise<string[]> => {
    const results = await validators.reduce(async (acc, validator) => {
      const current = await acc;
      if (current.length > 0) {
        return current;
      }
      return current.concat(await validator(value));
    }, Promise.resolve([] as string[]));
    return results;
  };
};

/**
 * Represents information about a validator to pass to callbacks during
 * validation.
 */
type ValidatorState<TValue, TArgs extends unknown[]> = {
  /**
   * Whether or not the value is valid.
   */
  readonly valid: boolean;

  /**
   * The value being validated.
   */
  readonly value: TValue;

  /**
   * The predicate function of the validator.
   */
  readonly predicate: ValidatorPredicate<TValue, TArgs>;
};

/**
 * Represents a callback function that can be used to generate the validation
 * message of a validator, rather than passing a hard-coded message.
 */
type ValidatorMessageFn<TValue, TArgs extends unknown[]> = (
  state: ValidatorState<TValue, TArgs>
) => string;
