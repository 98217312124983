import React, { useMemo } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useDateFormat, useFuseState } from '@common/hooks';
import { urlFor } from '@common/utils';
import {
  DashboardStatusBadge,
  DeleteDashboardModal,
  useDeleteDashboardModal,
} from '@features/dashboards';
import { useTypedFlags } from '@features/launch-darkly';
import { InstanceStatusBadge } from '@features/workflows';
import {
  DashboardStatus,
  InstanceStatus,
  Workspace,
  WorkspaceDashboardsTabDocument,
  useWorkspaceDashboardsTabQuery,
} from '@generated/graphql-code-generator';
import { PropsOf } from '@voleer/types';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  Icon,
  Link,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Heading, Paragraph, Text } from 'grommet';
import { compact, without } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';

export type WorkspaceDashboardsTabProps = {
  workspace: Pick<Workspace, 'id'>;
};

/**
 * Renders the content for the workspace Dashboards tab.
 */
export const WorkspaceDashboardsTab: React.FC<WorkspaceDashboardsTabProps> = ({
  workspace,
}) => {
  const { 'tenant-ui-polling-configuration': pollingConfiguration } =
    useTypedFlags();

  const [format, { DateTimeFormat }] = useDateFormat();

  const [t] = useTranslation('pages/WorkspacePage');

  const { data, ...query } = useWorkspaceDashboardsTabQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfiguration?.dashboardsList,
    variables: {
      input: {
        statuses: without(
          Object.values(DashboardStatus),
          DashboardStatus.Deleting,
          DashboardStatus.Deleted
        ),
        workspaceId: workspace.id,
      },
    },
  });

  const { props: deleteDashboardModalProps, open: openDeleteDashboardModal } =
    useDeleteDashboardModal({
      deleteMutationRefetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getOperationName(WorkspaceDashboardsTabDocument)!,
      ],
    });

  const searchItems = useMemo(() => {
    return compact(data?.dashboards?.items).map(item => {
      const dataSetDisplayName = item.dataSet?.displayName;
      const dashboardMetadataName = item.dashboardMetadata?.displayName;

      return {
        ...item,
        dashboardMetadataName,
        dataSetDisplayName,
      };
    });
  }, [data?.dashboards?.items]);

  const {
    searchTerm,
    setSearchTerm,
    searchResults: dashboards,
  } = useFuseState({
    items: searchItems,
    fuseOptions: {
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true,
      keys: ['dashboardMetadataName', 'dataSetDisplayName', 'displayName'],
    },
  });

  const onSearch: PropsOf<typeof FancyTable.Search>['onChange'] = event => {
    setSearchTerm(event.target.value);
  };

  const loading = !data && query.loading;

  const renderTableBody = () => {
    if (searchTerm && !dashboards.length) {
      return (
        <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
          <Heading
            level="3"
            margin={{ bottom: 'small', top: '0' }}
            textAlign="center"
          >
            {t('dashboards-tab.empty-table.no-search-results')}
          </Heading>
        </FancyTable.Empty>
      );
    }

    if (!dashboards.length) {
      return (
        <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
          <Heading
            level="3"
            margin={{ bottom: 'small', top: '0' }}
            textAlign="center"
          >
            {t('dashboards-tab.empty-table.no-data')}
          </Heading>
          <Paragraph margin="0" textAlign="center">
            <Trans
              components={{
                'library-link': (
                  <Link to={urlFor('library')()} variation="primary" />
                ),
              }}
              i18nKey="dashboards-tab.empty-table.create-dashboard-helper-text"
              t={t}
            />
          </Paragraph>
        </FancyTable.Empty>
      );
    }

    return (
      <>
        {dashboards.map(dashboard => {
          const dataSet = dashboard.dataSet;
          const dashboardMetadata = dashboard.dashboardMetadata;
          const lastRefreshRun = dataSet?.lastRefreshRun;

          return (
            <FancyTable.Row
              data-testid="workspace-dashboards-tab__table-row"
              key={dashboard.id}
            >
              <FancyTable.Cell>
                <Text title={dashboard.displayName} truncate={true}>
                  <Link
                    to={urlFor('dashboard')({
                      dashboardId: dashboard.id,
                      workspaceId: workspace.id,
                    })}
                    variation="primary"
                  >
                    {dashboard.displayName}
                  </Link>
                </Text>
              </FancyTable.Cell>

              <FancyTable.Cell>
                <DashboardStatusBadge status={dashboard.status} />
              </FancyTable.Cell>

              <FancyTable.Cell>
                {dataSet ? (
                  <Text title={dataSet.displayName} truncate={true}>
                    {dataSet.displayName}
                  </Text>
                ) : (
                  <Text>-</Text>
                )}
              </FancyTable.Cell>

              <FancyTable.Cell>
                {dashboardMetadata ? (
                  <Text title={dashboardMetadata.displayName} truncate={true}>
                    {dashboardMetadata.displayName}
                  </Text>
                ) : (
                  <Text>-</Text>
                )}
              </FancyTable.Cell>

              <FancyTable.Cell data-testid="workspace-dashboards-tab__table-cell--last-refresh">
                {lastRefreshRun ? (
                  <Text truncate={true}>
                    {lastRefreshRun.status === InstanceStatus.Completed &&
                    lastRefreshRun.completedOn ? (
                      format(
                        parseISO(lastRefreshRun.completedOn),
                        DateTimeFormat.AbbreviatedHumanized
                      )
                    ) : (
                      <InstanceStatusBadge status={lastRefreshRun.status} />
                    )}
                  </Text>
                ) : (
                  <Text>-</Text>
                )}
              </FancyTable.Cell>

              <FancyTable.Cell>
                <DropButton
                  data-testid="workspace-dashboards-tab__table-actions"
                  icon={<Icon icon={MdMoreHoriz} />}
                  title={t('dashboards-tab.actions.title')}
                  variation="ghost"
                >
                  <Link
                    to={urlFor('dashboard')({
                      dashboardId: dashboard.id,
                      workspaceId: workspace.id,
                    })}
                    variation="primary"
                  >
                    <DropButtonItem label={t('dashboards-tab.actions.view')} />
                  </Link>

                  <DropButtonItem
                    data-testid="workspace-dashboards-tab__table-actions--delete"
                    label={t('dashboards-tab.actions.delete')}
                    onClick={() => openDeleteDashboardModal(dashboard)}
                  />
                </DropButton>
              </FancyTable.Cell>
            </FancyTable.Row>
          );
        })}
      </>
    );
  };

  return (
    <Box fill={true} pad={{ horizontal: 'medium', top: 'medium' }}>
      <FancyTable
        columns={[
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          { justify: 'center', width: '50px' },
        ]}
        fill="horizontal"
        loading={loading}
        paper={true}
        rows={{ count: dashboards.length }}
      >
        <FancyTable.Toolbar>
          <FancyTable.Search
            data-testid="dashboards-tab__search-input"
            onChange={onSearch}
            placeholder={t('dashboards-tab.search-placeholder')}
            value={searchTerm}
          />
        </FancyTable.Toolbar>
        <FancyTable.Header>
          <FancyTable.Row>
            <FancyTable.HeaderCell>
              {t('dashboards-tab.table-headers.name')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('dashboards-tab.table-headers.status')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('dashboards-tab.table-headers.dataset')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('dashboards-tab.table-headers.metadata')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('dashboards-tab.table-headers.last-refresh')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('dashboards-tab.table-headers.actions')}
            </FancyTable.HeaderCell>
          </FancyTable.Row>
        </FancyTable.Header>

        <FancyTable.Body>{renderTableBody()}</FancyTable.Body>
      </FancyTable>

      <DeleteDashboardModal {...deleteDashboardModalProps} />
    </Box>
  );
};
