import React from 'react';
import { ensureUnreachable } from '@voleer/types';
import { RouteComponentProps } from 'react-router';
import { LibraryAutomationPage } from './LibraryAutomationPage';
import { LibraryDashboardPage } from './LibraryDashboardPage';
import { LibraryDataSetPage } from './LibraryDataSetPage';
import { LibraryItemRedirectPage } from './LibraryItemRedirectPage';

/**
 * Represents the "type" URL segment of the LibraryItem pages.
 */
export type LibraryItemPageTypeUrlSegment =
  | 'automation'
  | 'dashboard'
  | 'dataset';

export type LibraryItemPageRouteParams = {
  name: string;
  packageName: string;
  packageVersion?: string;
  publisherName: string;
  type?: LibraryItemPageTypeUrlSegment;
};

type LibraryItemPageProps = Readonly<
  RouteComponentProps<LibraryItemPageRouteParams>
>;

/**
 * Renders the appropriate page for a given item in the library.
 */
export const LibraryItemPage: React.FC<LibraryItemPageProps> = props => {
  const { type } = props.match.params;

  // Support backward compatibility for old library item URLs
  if (!type) {
    return <LibraryItemRedirectPage {...props.match.params} />;
  }

  switch (type) {
    case 'automation':
      return <LibraryAutomationPage {...props.match.params} />;

    case 'dataset':
      return <LibraryDataSetPage {...props.match.params} />;

    case 'dashboard':
      return <LibraryDashboardPage {...props.match.params} />;

    default:
      ensureUnreachable(type);
      return null;
  }
};
