import React from 'react';
import { useAccountStatus } from '@common/hooks';
import {
  ScheduledJobStatus,
  useScheduledJobScheduleToggleMutation,
} from '@generated/graphql-code-generator';
import { CheckBox } from 'grommet';

/**
 * Props for ScheduledJobScheduleToggle component
 */
export type ScheduledJobScheduleToggleProps = {
  /**
   * The workspace id
   */
  workspaceId: string;
  /**
   * The scheduled job id
   */
  scheduledJobId: string;
  /**
   * The scheduled job status
   */
  scheduledJobStatus: ScheduledJobStatus;
};

/**
 * Allows user to toggle the schedule for a scheduled job between active and paused
 */
export const ScheduledJobScheduleToggle = ({
  workspaceId,
  scheduledJobId,
  scheduledJobStatus,
}: ScheduledJobScheduleToggleProps) => {
  const { disableWriteOperation } = useAccountStatus();

  const [scheduledJobScheduleToggleMutation, { loading }] =
    useScheduledJobScheduleToggleMutation();

  const onClick = () => {
    scheduledJobScheduleToggleMutation({
      variables: {
        workspaceId,
        scheduleId: scheduledJobId,
      },
    });
  };

  const isChecked = scheduledJobStatus === ScheduledJobStatus.Active;
  const isEnabled =
    [ScheduledJobStatus.Active, ScheduledJobStatus.Paused].includes(
      scheduledJobStatus
    ) && !loading;

  return (
    <CheckBox
      checked={isChecked}
      data-testid="scheduled-job-schedule-toggle"
      disabled={!isEnabled || disableWriteOperation}
      name="scheduled-job-schedule-toggle"
      onClick={onClick}
      toggle={true}
    />
  );
};
