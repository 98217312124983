import React from 'react';
import { useAccountStatus } from '@common/hooks';
import { urlFor } from '@common/utils';
import { DataSet, DataSetMetadata } from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { Link } from '@voleer/ui-kit';
import { FormField, Select, Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Select options in the `DataSetSelector` component.
 */
export type DataSetSelectorOption = Pick<DataSet, 'displayName' | 'id'>;

/**
 * Prop types for the `DataSetSelector` component.
 */
export type DataSetSelectorProps = Readonly<{
  compatibleDataSetMetadata:
    | Pick<DataSetMetadata, 'displayName' | 'id'>
    | undefined;

  /**
   * The selected dataset.
   */
  dataSet?: DataSetSelectorOption;

  /**
   * Whether or not the dataset select dropdown is disabled.
   */
  disabled: boolean;

  /**
   * Whether or not the datasets are loading in.
   */
  loading: boolean;

  /**
   * Handler for selecting a dataset.
   */
  onChange: (dataSet?: DataSetSelectorOption) => void;

  /**
   * The list of dataset select options.
   */
  options?: DataSetSelectorOption[];
}>;

/**
 * Renders the dataset select dropdown in the launch-template section of the automation template page.
 */
export const DataSetSelector: React.FC<DataSetSelectorProps> = ({
  compatibleDataSetMetadata,
  dataSet,
  disabled,
  loading,
  onChange: originalOnChange,
  options,
}) => {
  const [t] = useTranslation('pages/LibraryItemPage/DataSetSelector');

  const { disableWriteOperation } = useAccountStatus();

  const hasNoCompatibleDataSets =
    !loading && Array.isArray(options) && !options.length;

  if (hasNoCompatibleDataSets && compatibleDataSetMetadata) {
    const createDataSetUrl = urlFor('libraryItemById')({
      itemId: compatibleDataSetMetadata.id,
    });

    return (
      <Text margin={{ bottom: 'medium', top: 'small' }}>
        <Trans i18nKey="no-datasets" t={t}>
          <>No compatible </>
          <strong>
            {{
              dataSetDisplayName: compatibleDataSetMetadata?.displayName,
            }}
          </strong>
          <> datasets are available in the selected workspace. </>
          <Link
            data-testid="data-set-selector__dependency-link"
            to={createDataSetUrl}
            variation="primary"
          >
            Create one
          </Link>
        </Trans>
      </Text>
    );
  }

  const onChange = (event: GrommetSelectEvent<DataSetSelectorOption>) => {
    originalOnChange(event.value);
  };

  return (
    <FormField label={t('label')}>
      <Select
        data-testid="data-set-selector__select-dropdown"
        disabled={disableWriteOperation || loading || disabled}
        labelKey="displayName"
        onChange={onChange}
        options={options || []}
        placeholder={loading ? t('loading-datasets') : t('placeholder')}
        value={loading ? undefined : dataSet}
      />
    </FormField>
  );
};
