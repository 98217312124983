import React, { useMemo } from 'react';
import { useFuseState, useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  TemplateConfigurationsTable,
  TemplateConfigurationsTableProps,
} from '@features/workflows';
import {
  Workspace,
  useLaunchTemplateConfigurationMutation,
  useWorkspacePageTemplateConfigurationsQuery,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';
import { compact } from 'lodash';
import { useHistory } from 'react-router';

type WorkspaceTemplateConfigurationsTabProps = {
  workspace: Pick<Workspace, 'id'>;
};

/**
 * Renders the Template Configurations tab content on the Workspace page.
 */
export const WorkspaceTemplateConfigurationsTab: React.FC<WorkspaceTemplateConfigurationsTabProps> =
  ({ workspace }) => {
    const { 'tenant-ui-polling-configuration': pollingConfig } =
      useTypedFlags();

    const { tenantMember } = useTenantContext();

    const history = useHistory();

    const { data, loading: queryLoading } =
      useWorkspacePageTemplateConfigurationsQuery({
        fetchPolicy: 'cache-and-network',
        pollInterval: pollingConfig?.workspaceInstancesLists,
        variables: {
          workspaceId: workspace.id,
        },
      });

    const [launchTemplateConfiguration] =
      useLaunchTemplateConfigurationMutation();

    const templateConfigurationsItems = data?.templateConfigurations?.items;
    const templateConfigurations = useMemo(() => {
      return compact(templateConfigurationsItems).map(item => {
        const templateMetadataName = item.templateMetadata?.displayName;

        return {
          ...item,

          // Augment data with properties that can be used by search
          templateMetadataName,
        };
      });
    }, [templateConfigurationsItems]);

    const { searchTerm, setSearchTerm, searchResults } = useFuseState({
      items: templateConfigurations,
      fuseOptions: {
        threshold: 0.3,
        tokenize: true,
        matchAllTokens: true,
        keys: ['displayName', 'templateMetadataName'],
      },
    });

    const onSearch: TemplateConfigurationsTableProps['onSearch'] = event => {
      setSearchTerm(event.target.value);
    };

    const onLaunch: TemplateConfigurationsTableProps['onLaunch'] =
      async templateConfiguration => {
        if (!tenantMember) {
          return;
        }

        const workspaceId = workspace.id;
        const launchedById = tenantMember.id;
        const templateConfigurationId = templateConfiguration.id;

        await launchTemplateConfiguration({
          variables: {
            workspaceId,
            templateConfigurationId,
            launchedById,
          },
        });

        history.push(urlFor('workspaceRuns')({ workspaceId }));
      };

    const loading = !data && queryLoading;

    return (
      <Box fill={true} pad={{ horizontal: 'medium', top: 'medium' }}>
        <TemplateConfigurationsTable
          loading={loading}
          onLaunch={onLaunch}
          onSearch={onSearch}
          searchTerm={searchTerm}
          templateConfigurations={searchResults}
          workspace={workspace}
        />
      </Box>
    );
  };
