import React, { useEffect } from 'react';
import { LoadingOverlay } from '@common/components';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  AddIntegrationModalQuery,
  Integration,
  IntegrationStatus,
  useAddIntegrationModalConnectOAuth2IntegrationMutation,
  useAddIntegrationModalOAuth2ConnectionStatusQuery,
} from '@generated/graphql-code-generator';
import { Box, Button, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import { AddIntegrationModalForm, AddIntegrationModalFormProps } from '..';

type QueryIntegrationMetadata = NonNullable<
  NonNullable<
    NonNullable<AddIntegrationModalQuery['integrationMetadatas']>['items']
  >[number]
>;

/**
 * Opens the session window to authorize an OAuth2 integration.
 */
const openSessionWindow = (sessionId?: string) => {
  if (!sessionId) {
    throw new Error('Expected sessionId to be present');
  }

  window.open(
    urlFor('authorizeOAuth2Session')({ sessionId }),
    `OAuth2SessionWindow - ${sessionId}`,
    `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=-100,top=-100`
  );
};

export type AddOAuth2IntegrationProps = {
  integrationMetadata: QueryIntegrationMetadata;
  onClose: () => void;
  onCompleted: (integration: Pick<Integration, 'id'>) => void;
};

/**
 * Handles adding a custom workflow integration in the AddIntegrationModal.
 */
export const AddOAuth2Integration: React.FC<AddOAuth2IntegrationProps> = ({
  integrationMetadata,
  onClose,
  onCompleted,
}) => {
  const { 'tenant-ui-polling-configuration': pollingConfiguration } =
    useTypedFlags();

  const [t] = useTranslation(
    'features/integrations/components/AddIntegrationModal'
  );

  const [connectOAuth2Integration, connectOAuth2IntegrationResult] =
    useAddIntegrationModalConnectOAuth2IntegrationMutation();

  const createOAuth2IntegrationData =
    connectOAuth2IntegrationResult?.data?.createOAuth2Integration;

  const sessionId = createOAuth2IntegrationData?.sessionId;
  const integrationId = createOAuth2IntegrationData?.integrationId;

  const { data: connectionStatusData } =
    useAddIntegrationModalOAuth2ConnectionStatusQuery({
      pollInterval: pollingConfiguration?.oauth2IntegrationSessionStatus,
      skip: !integrationId || !sessionId,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        integrationId: integrationId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sessionId: sessionId!,
      },
    });

  // Open the authorize session window for the user to log in once we have a sessionId
  useEffect(() => {
    if (!sessionId) {
      return;
    }

    openSessionWindow(sessionId);
  }, [sessionId]);

  // Call the onCompleted callback after the session has been authorized
  useEffect(
    () => {
      const oAuth2Integration = connectionStatusData?.oAuth2Integration;
      if (!oAuth2Integration) {
        return;
      }

      if (oAuth2Integration.status !== IntegrationStatus.Active) {
        return;
      }

      onCompleted?.(oAuth2Integration);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectionStatusData?.oAuth2Integration?.status]
  );

  const onSubmit: AddIntegrationModalFormProps['onSubmit'] = async ({
    description,
    displayName,
  }) => {
    await connectOAuth2Integration({
      variables: {
        description,
        displayName,
        integrationMetadataId: integrationMetadata.id,
      },
    });
  };

  if (createOAuth2IntegrationData) {
    return (
      <Box align="center" fill={true}>
        <LoadingOverlay
          label={
            <Box align="center">
              <Paragraph>{t('configure-oauth2.instructions')}</Paragraph>

              <Button
                label={t('configure-oauth2.buttons.sign-in.label')}
                onClick={() => {
                  openSessionWindow(sessionId);
                }}
                primary={true}
              />
            </Box>
          }
          loading={true}
        />
      </Box>
    );
  }

  return (
    <AddIntegrationModalForm
      integrationMetadata={integrationMetadata}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
