import {
  ScheduledJob,
  useReplaceDataSetScheduleMutation,
} from '@generated/graphql-code-generator';
import { useModalState } from '@voleer/ui-kit';
import { ChangeRefreshScheduleModalProps } from './ChangeRefreshScheduleModal';

type UseChangeRefreshScheduleModalOptions = {
  /**
   * The ID of the Dataset where the refresh schedule is being changed.
   */
  dataSetId: string;

  /**
   * The ID of the Workspace where the dataset refresh schedule is being changed.
   */
  workspaceId: string;
};

type UseChangeRefreshScheduleModalResult = {
  /**
   * Props to be passed to the `ChangeRefreshScheduleModal` component.
   */
  changeRefreshScheduleModalProps: Pick<
    ChangeRefreshScheduleModalProps,
    'close' | 'dataSetId' | 'onSubmit' | 'open'
  >;

  /**
   * Whether or not the mutation is loading.
   */
  isRefreshScheduleMutationLoading: boolean;

  /**
   * Callback for opening the `ChangeRefreshScheduleModal` component.
   */
  openRefreshScheduleModal: () => void;
};

/**
 * Hook which provides the data for the `ChangeRefreshScheduleModal` component.
 *
 * ```typescript
 * const {
 *   changeRefreshScheduleModalProps,
 *   openRefreshScheduleModal
 * } = useChangeRefreshScheduleModal();
 *
 * return <div>
 *   <Button label="Get Connection String" onClick={openRefreshScheduleModal} />
 *   <ChangeRefreshScheduleModal {...changeRefreshScheduleModalProps} />
 * </div>
 * ```
 */
export const useChangeRefreshScheduleModal = ({
  dataSetId,
  workspaceId,
}: UseChangeRefreshScheduleModalOptions): UseChangeRefreshScheduleModalResult => {
  const { close, isOpen, open: openRefreshScheduleModal } = useModalState();

  const [changeRefreshSchedule, changeRefreshScheduleMutation] =
    useReplaceDataSetScheduleMutation({
      errorPolicy: 'all',
    });

  /**
   * Form submission handler for changing the dataset refresh schedule.
   */
  const onSubmit = async (
    value: Pick<ScheduledJob, 'pattern' | 'timezoneName'>
  ) => {
    const { pattern, timezoneName } = value;

    if (!pattern) return;

    await changeRefreshSchedule({
      variables: {
        input: {
          dataSetId,
          pattern,
          timezoneName,
          workspaceId,
        },
      },
    });

    close();
  };

  return {
    changeRefreshScheduleModalProps: {
      close,
      dataSetId,
      onSubmit,
      open: isOpen,
    },
    isRefreshScheduleMutationLoading: changeRefreshScheduleMutation.loading,
    openRefreshScheduleModal,
  };
};
