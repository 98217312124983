import React, { useEffect } from 'react';
import { useTenantContext } from '@common/hooks';

/**
 * Gets the user info and initializes the pendo script.
 */
export const PendoInitialization: React.FC = () => {
  const { tenant, tenantMember } = useTenantContext();

  useEffect(() => {
    if (!window || !window.pendo || !tenantMember || !tenant) {
      return;
    }

    // Call this whenever information about your visitors becomes available
    // Please use Strings, Numbers, or Bools for value types.

    window.pendo.initialize({
      visitor: {
        id: tenantMember.emailAddress, // Required if user is logged in
        firstName: tenantMember.firstName,
        lastName: tenantMember.lastName,
        email: tenantMember.emailAddress,
        userId: tenantMember.systemUserId,
      },

      account: {
        id: tenant.id, // Tenant metadata Highly recommended
        name: tenant.name,
        planName: tenant.planName,
        companyName: tenant.displayName,
        accountStatus: tenant.accountStatus,
      },
    });
  }, [tenant, tenantMember]);

  return null;
};
