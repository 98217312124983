import React, { useReducer } from 'react';
import { AppLayout, PageContent } from '@common/components';
import {
  FindWorkspacesQuery,
  WorkspaceFragment,
  useFindWorkspacesQuery,
} from '@generated/graphql-code-generator';
import { UnreachableCaseError, isNotNullOrUndefined } from '@voleer/types';
import { Box, BoxProps, Text, TextInput } from 'grommet';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  FaCaretDown,
  FaCaretUp,
  FaSearch,
  FaTimesCircle,
} from 'react-icons/fa';
import { RouteChildrenProps } from 'react-router';
import { AddWorkspace, AddWorkspaceButton, WorkspaceList } from './components';

export type WorkspacesPageProps = RouteChildrenProps & {
  // Nothing yet
};

type WorkspacesPageParams = {
  newWorkspace?: 'true';
};

type WorkspacesOrderByProperty = 'name';

type WorkspacesOrderByProps = {
  property: WorkspacesOrderByProperty;
  direction: 'asc' | 'desc';
};

interface WorkspacesPageState {
  orderBy: WorkspacesOrderByProps;
  searchTerm?: string;
}

type WorkspacesPageReducerAction =
  | { type: 'setOrderBy'; orderBy: WorkspacesOrderByProps }
  | { type: 'setSearchTerm'; searchTerm?: string };

type WorkspacesPageReducer = (
  state: WorkspacesPageState,
  action: WorkspacesPageReducerAction
) => WorkspacesPageState;

const reducer: WorkspacesPageReducer = (state, action) => {
  switch (action.type) {
    case 'setOrderBy':
      return { ...state, orderBy: action.orderBy };
    case 'setSearchTerm':
      return { ...state, searchTerm: action.searchTerm };
    default:
      throw new UnreachableCaseError(action);
  }
};

const initialOrderBy: WorkspacesOrderByProps = {
  property: 'name',
  direction: 'asc',
};

const initialWorkspacesPageState: WorkspacesPageState = {
  searchTerm: '',
  orderBy: initialOrderBy,
};

const SEARCH_ICON_SIZE = '16px';
const CONTENT_WIDTH = '640px';
const SORT_BAR_HEIGHT = '36px';
const NEW_WORKSPACE_PARAM = 'newWorkspace';

/**
 * Removes null or undefined items from the workspaces query data.
 */
const getWorkspacesQueryItems = (
  data?: FindWorkspacesQuery
): WorkspaceFragment[] => {
  return data?.workspaces?.items?.filter(isNotNullOrUndefined) ?? [];
};

/**
 * Updates the route url search based on the params.
 *
 * @param props
 * @param params
 */
const updateSearch = (
  props: WorkspacesPageProps,
  params: WorkspacesPageParams
) => {
  const currentParams = new URLSearchParams(props.location.search);
  Object.keys(params).forEach(paramKey => {
    if (!params[paramKey]) {
      currentParams.delete(paramKey);
    } else {
      currentParams.set(paramKey, params[paramKey]);
    }
  });
  props.history.push({ search: currentParams.toString() });
};

const MessageContent: React.FC<BoxProps> = props => (
  <Box
    alignSelf="center"
    fill="vertical"
    justify="center"
    pad={{ vertical: 'small', horizontal: 'medium' }}
    {...props}
  />
);

/**
 * Renders the page for workspaces.
 */
export const WorkspacesPage: React.FC<WorkspacesPageProps> = props => {
  const [t] = useTranslation('pages/WorkspacesPage');
  const params = new URLSearchParams(props.location.search);
  const newWorkspaceParamValue = params.get(NEW_WORKSPACE_PARAM);
  const displayAddWorkspace = newWorkspaceParamValue === 'true';
  const { loading, error, data } = useFindWorkspacesQuery({
    // Utilize the `cache-and-network` policy first to ensure that newly added
    // workspaces are reflected. If we find inconsistencies with refreshing the
    // list, then there's opportunity to utilize the `network-only` strategy:
    // https://www.apollographql.com/docs/react/api/react-apollo/#optionsfetchpolicy
    fetchPolicy: 'cache-and-network',
  });
  const [state, dispatch] = useReducer(reducer, initialWorkspacesPageState);
  const handleNewWorkspaceClick = () =>
    updateSearch(props, { [NEW_WORKSPACE_PARAM]: 'true' });
  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'setSearchTerm', searchTerm: event.target.value });
  const closeAddWorkspace = () =>
    updateSearch(props, { [NEW_WORKSPACE_PARAM]: undefined });
  const toggleSortOrder = (property: WorkspacesOrderByProperty) => () => {
    const newOrderBy: WorkspacesOrderByProps = {
      property,
      direction: state.orderBy.direction === 'asc' ? 'desc' : 'asc',
    };
    dispatch({ type: 'setOrderBy', orderBy: newOrderBy });
  };

  return (
    <AppLayout title={t('workspaces')}>
      <PageContent>
        <Box align="center" pad="medium">
          {displayAddWorkspace && <AddWorkspace onCancel={closeAddWorkspace} />}
          {!displayAddWorkspace && (
            <Box
              background="white"
              elevation="small"
              flex={{ shrink: 0 }}
              pad={{ vertical: 'small' }}
              round="xsmall"
              width={CONTENT_WIDTH}
            >
              <Box
                direction="row"
                flex={{ shrink: 0 }}
                gap="small"
                pad={{ horizontal: 'small', bottom: 'xsmall' }}
              >
                <Box
                  align="center"
                  alignContent="center"
                  direction="row"
                  flex={{ grow: 1 }}
                  justify="center"
                >
                  <Box flex={{ grow: 1 }} margin={{ right: 'small' }}>
                    <TextInput
                      onChange={handleSearchTermChange}
                      placeholder={
                        <Box align="center" direction="row" fill={true}>
                          <FaSearch size={SEARCH_ICON_SIZE} />
                          <Text margin={{ left: 'small' }}>
                            {t('search-workspaces-placeholder')}
                          </Text>
                        </Box>
                      }
                      value={state.searchTerm}
                    />
                  </Box>
                  <AddWorkspaceButton onClick={handleNewWorkspaceClick} />
                </Box>
              </Box>
              <Box
                background="light-2"
                flex={{ shrink: 0 }}
                height={SORT_BAR_HEIGHT}
                justify="center"
                pad={{ horizontal: 'medium' }}
              >
                <Box
                  direction="row"
                  onClick={toggleSortOrder('name')}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Text color="dark-2">
                    {t('sort-bar.name')}&nbsp;
                    {state.orderBy.direction === 'asc' ? (
                      <FaCaretDown />
                    ) : (
                      <FaCaretUp />
                    )}
                  </Text>
                </Box>
              </Box>
              {(() => {
                if (loading) {
                  return <MessageContent>{t('loading')}</MessageContent>;
                }
                if (error) {
                  return (
                    <MessageContent direction="row" gap="xsmall">
                      <Text color="status-error">
                        <FaTimesCircle />
                      </Text>
                      <Text>
                        {t('error')}: {error.message}
                      </Text>
                    </MessageContent>
                  );
                }

                const workspaces = orderBy(
                  getWorkspacesQueryItems(data),
                  (workspace: WorkspaceFragment) =>
                    workspace.displayName.toLowerCase(),
                  state.orderBy.direction
                );
                return (
                  <WorkspaceList
                    searchTerm={state.searchTerm}
                    workspaces={workspaces}
                  />
                );
              })()}
            </Box>
          )}
        </Box>
      </PageContent>
    </AppLayout>
  );
};
