// Polyfill `fetch`
import 'cross-fetch/polyfill';

import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { getBaseUrl, getUserManager, isBrowser } from '@common/utils';
import { createClientCache } from './cache';

// When running in the browser we need to use `<current-base-url>/graphql`, but
// on the server during SSR we access the API on localhost
const uri = isBrowser()
  ? `${getBaseUrl()}/graphql`
  : 'http://localhost:3002/graphql';

const httpLink: ApolloLink = new HttpLink({
  uri,
  fetch,
});

// Authentication link used to insert JWT into the request headers
const authLink = setContext(async (_, { headers }) => {
  const userManager = getUserManager();
  const user = await userManager.getUser();

  return {
    headers: {
      ...headers,
      authorization: user ? `Bearer ${user.access_token}` : undefined,
    },
  };
});

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = createClientCache();

let client: ApolloClient<NormalizedCacheObject> | undefined;

/**
 * Gets the application's instance of ApolloClient.
 */
export const getApolloClient = () => {
  // Return memoized client instance if it has already been created
  if (client) {
    return client;
  }

  const links = [errorLink, authLink, httpLink];

  // Otherwise create the client and memoize it
  client = new ApolloClient({
    cache,
    link: ApolloLink.from(links),
  });

  return client;
};
