/**
 * Returns a promise that resolves after a number of milliseconds have passed.
 *
 * @param ms number of milliseconds to wait
 */
export const wait = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

/**
 * Options for waitUntil function.
 */
export type WaitUntilOptions = {
  /**
   * The duration to wait between invocation of the function.
   */
  intervalInMs: number;

  /**
   * The timeout duration.
   */
  timeoutInMs?: number;

  /**
   * Whether to stop the wait on error produced by the function.
   */
  exitOnError?: boolean;
};

/**
 * A typed error indicating that the wait has timed out.
 */
export class TimeoutError extends Error {
  constructor(message?: string) {
    super(message);

    // Set the prototype explicitly as recommended by
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

/**
 * A typed error indicating that a terminal condition has been reached. This will exit the wait and rethrow the error
 * even when exitOnError options is set to false.
 */
export class TerminalError extends Error {
  constructor(message?: string) {
    super(message);

    // Set the prototype explicitly as recommended by
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, TerminalError.prototype);
  }
}

type CancellationToken = {
  isCancelled: boolean;
};

type TimeoutReturnType = ReturnType<typeof setTimeout>;

/**
 * Returns a promise that resolves when the given function returns true.
 *
 * @param fn The function that will be executed for every interval. Returns true to stop; false otherwise.
 * @param options The options.
 */
export const waitUntil = (
  fn: () => Promise<boolean>,
  { timeoutInMs, intervalInMs, exitOnError }: WaitUntilOptions
): Promise<void> => {
  if (intervalInMs <= 0) {
    throw new TypeError('intervalInMs must be positive');
  }

  // Create a wrapped promise that resolves when the fn returns true
  const fnPromise = async (cancellationToken?: CancellationToken) => {
    while (!cancellationToken?.isCancelled) {
      try {
        // Only exit when the function returns true, implying it does not want to continue polling
        if (await fn()) {
          return;
        }
      } catch (err) {
        if (exitOnError || err instanceof TerminalError) {
          throw err;
        }
      }

      // Wait for the next interval
      await wait(intervalInMs);
    }
  };

  // Set up timeout promise if specified
  if (timeoutInMs && timeoutInMs > 0) {
    const cancellationToken: CancellationToken = {
      isCancelled: false,
    };

    // Cancel the next iteration of function if timeout is reached
    // $BUG: Clear setTimeout timer after the function has completed, otherwise
    // this will force Node to keep the process alive until the setTimeout timer expires even if it's a no-op.
    // Ref: https://stackoverflow.com/questions/66895155/why-doesnt-node-js-exit-after-promise-race-is-finished
    let timeout: TimeoutReturnType | undefined;
    const timeoutPromise = new Promise<void>((_, reject) => {
      timeout = setTimeout(() => {
        cancellationToken.isCancelled = true;
        reject(
          new TimeoutError(`Timed out after ${timeoutInMs} milliseconds.`)
        );
      }, timeoutInMs);
    });

    return Promise.race([
      timeoutPromise,
      fnPromise(cancellationToken).finally(
        () => timeout && clearTimeout(timeout)
      ),
    ]);
  }

  return fnPromise();
};
