import React, { createContext, useEffect, useRef } from 'react';
import { ChargeBeePortalSessionObject } from '@generated/graphql-code-generator';
import { getAppConfig } from '../../../../app-config';

type SetPortalSessionCallback = () => Promise<
  Pick<
    ChargeBeePortalSessionObject,
    | 'access_url'
    | 'created_at'
    | 'customer_id'
    | 'expires_at'
    | 'id'
    | 'object'
    | 'status'
    | 'token'
  >
>;

type ChargebeeInstance = {
  createChargebeePortal: () => {
    open: (options: { close: () => void }) => void;
  };
  logout: () => void;
  setPortalSession: (cb: SetPortalSessionCallback) => void;
};

export type ChargeBeeInstanceContextValue = Readonly<{
  chargeBeeInstance?: ChargebeeInstance;
}>;

// Extend the window interface to include the Chargebee object
// https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
  interface Window {
    Chargebee?: {
      init: (options: { site: string }) => ChargebeeInstance;
      getInstance: () => ChargebeeInstance;
    };
  }
}

/**
 * Context to provide the ChargeBee instance object.
 */
export const ChargeBeeInstanceContext =
  createContext<ChargeBeeInstanceContextValue>({
    chargeBeeInstance: undefined,
  });
/**
 * Provides the ChargeBee instance object. The ChargeBee instance is initialized on first
 * render. Once initialized, the instance is used to open the self-serve portal, at
 * `src/packages/tenant-ui/src/pages/TenantSettingsPage/components/TenantSubscriptionTab/TenantSubscriptionTab.tsx`
 *
 * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-portal-instance-object
 * https://www.chargebee.com/checkout-portal-docs/api-portal.html#call-flow
 */
export const ChargeBeeInstanceContextProvider: React.FC = ({ children }) => {
  const { CHARGE_BEE_SITE_ID } = getAppConfig();
  const chargeBeeInstance = useRef<ChargebeeInstance>();

  useEffect(() => {
    if (!window?.Chargebee || !CHARGE_BEE_SITE_ID) {
      return;
    }

    // Create the Chargebee instance object
    // https://www.chargebee.com/checkout-portal-docs/api-portal.html#intializing-a-chargebee-instance
    // https://www.chargebee.com/checkout-portal-docs/api.html#init
    window.Chargebee.init({
      site: CHARGE_BEE_SITE_ID,
    });

    chargeBeeInstance.current = window.Chargebee.getInstance();
  }, [CHARGE_BEE_SITE_ID]);

  return (
    <ChargeBeeInstanceContext.Provider
      children={children}
      value={{
        chargeBeeInstance: chargeBeeInstance.current,
      }}
    />
  );
};
