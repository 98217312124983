import React, { useEffect } from 'react';
import { useGetLaunchDarklyUserInfoQuery } from '@generated/graphql-code-generator';
import { useLDClient } from 'launchdarkly-react-client-sdk';

/**
 * Identifies the current user with LaunchDarkly.
 */
export const LaunchDarklyUserIdentifier: React.FC = () => {
  const client = useLDClient();
  const { data } = useGetLaunchDarklyUserInfoQuery();

  const tenantMember = data?.tenantMember;

  useEffect(() => {
    if (!client || !tenantMember) {
      return;
    }

    client.identify({
      key: tenantMember.id,
      email: tenantMember.emailAddress,
      firstName: tenantMember.firstName,
      lastName: tenantMember.lastName,
      custom: {
        tenantId: tenantMember.tenant.id,
        tenantName: tenantMember.tenant.name,
      },
    });
  }, [client, tenantMember]);

  return <></>;
};
