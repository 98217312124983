import { TenantMember } from '@generated/graphql-code-generator';
import { ensureUnreachable } from '@voleer/types';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GetLaunchedByNameArg } from './interface';

type UseWorkflowTranslationsResult = {
  /**
   * Gets the display name for a given LaunchedBy value.
   */
  getLaunchedByName: (
    launchedBy?: GetLaunchedByNameArg | null
  ) => string | undefined;

  /**
   * Gets the first and last name for a given TenantMember value.
   *
   * Example:
   * 'John Doe'
   */
  getTenantMemberDisplayName: (
    tenantMember?: Pick<TenantMember, 'firstName' | 'lastName'> | null
  ) => string | undefined;
};

/**
 * Hook exposing common translation-related functions for workflows.
 */
export const useWorkflowTranslations = (): UseWorkflowTranslationsResult => {
  const [t] = useTranslation(
    'features/workflows/hooks/use-workflow-translations'
  );

  /**
   * Gets the name of a TenantMember from a `TenantMember` record.
   *
   * Returns `undefined` if given `null` or `undefined`;
   */
  const getTenantMemberDisplayName = (
    tenantMember?: Pick<TenantMember, 'firstName' | 'lastName'> | null
  ): string | undefined => {
    if (!tenantMember) {
      return undefined;
    }

    return compact([tenantMember.firstName, tenantMember.lastName]).join(' ');
  };

  const getLaunchedByName: UseWorkflowTranslationsResult['getLaunchedByName'] =
    launchedBy => {
      if (!launchedBy) {
        return undefined;
      }

      switch (launchedBy.__typename) {
        case 'TenantMember': {
          return getTenantMemberDisplayName(launchedBy);
        }
        case 'ScheduledJob': {
          return t('launched-by.scheduled-job');
        }
        case 'DataSet': {
          return t('launched-by.scheduled-job');
        }
        default: {
          ensureUnreachable(launchedBy);
          return undefined;
        }
      }
    };

  return {
    getLaunchedByName,
    getTenantMemberDisplayName,
  };
};
