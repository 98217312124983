import React, { useState } from 'react';
import { ClipBox, LoadingOverlay } from '@common/components';
import { Alert, Modal } from '@voleer/ui-kit';
import { Button, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Prop types for the `ConnectionStringModal` component.
 */
export type ConnectionStringModalProps = {
  /**
   * The read-write connection string displayed in the modal.
   */
  connectionString?: string | null;

  /**
   * The display name of the dataset. Used in the instructions in the modal.
   */
  dataSetDisplayName?: string;

  /**
   * Possible error message when querying for the dataset.
   */
  error?: Error;

  /**
   * Whether or not the dataset data is loading.
   */
  isLoading: boolean;

  /**
   * Whether or not the modal is open.
   */
  open: boolean;

  /**
   * Callback for closing the modal.
   */
  onClose: () => void;

  /**
   * The read-only connection string displayed in the modal.
   */
  readOnlyConnectionString?: string | null;
};

/**
 * Renders a modal for accessing connection strings.
 */
export const ConnectionStringModal: React.FC<ConnectionStringModalProps> = ({
  connectionString,
  dataSetDisplayName,
  error,
  isLoading,
  open,
  onClose,
  readOnlyConnectionString,
}) => {
  const [t] = useTranslation(
    'features/workflows/components/ConnectionStringModal'
  );

  const [areConnectionStringsHidden, setAreConnectionStringsHidden] =
    useState<boolean>(true);

  return (
    <Modal onClickOutside={onClose} onEsc={onClose} open={open}>
      <Modal.Header>{t('title')}</Modal.Header>

      <Modal.Body>
        <LoadingOverlay loading={isLoading}>
          {!error && (
            <>
              {dataSetDisplayName && (
                <Text>{t('instructions', { dataSetDisplayName })}</Text>
              )}

              {/* Empty div required to prevent button from taking up entire width of modal. */}
              <div>
                <Button
                  data-testid="connection-string-modal__btn--hide-show"
                  label={
                    areConnectionStringsHidden
                      ? t('button.show.label')
                      : t('button.hide.label')
                  }
                  margin={{ vertical: 'medium' }}
                  onClick={() => {
                    setAreConnectionStringsHidden(!areConnectionStringsHidden);
                  }}
                  primary={true}
                />
              </div>

              <Text>{t('connection-string-label.read-write')}</Text>
              <ClipBox
                data-testid="connection-string-modal__connection-string--read-write"
                password={areConnectionStringsHidden}
                text={connectionString || ''}
              />

              <Text margin={{ top: 'medium' }}>
                {t('connection-string-label.read-only')}
              </Text>
              <ClipBox
                data-testid="connection-string-modal__connection-string--read-only"
                password={areConnectionStringsHidden}
                text={readOnlyConnectionString || ''}
              />
            </>
          )}

          {!!error && (
            <Alert
              data-testid="connection-string-modal__alert--error"
              icon={true}
              margin={{ vertical: 'xlarge' }}
              status="error"
            >
              {t('error', { error: error.message })}
            </Alert>
          )}
        </LoadingOverlay>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="connection-string-modal__btn--close"
          label={t('button.close.label')}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
