import { ScheduledJobStatus } from '@generated/graphql-code-generator';

/**
 * Array of `ScheduledJobStatus`es that can be canceled.
 */
export const CANCELABLE_SCHEDULE_STATUSES = [
  ScheduledJobStatus.Active,
  ScheduledJobStatus.Completed,
  ScheduledJobStatus.Paused,
] as const;

/**
 * Represents a `ScheduledJobStatus` of a job that can be canceled.
 */
export type CancelableScheduledJobStatus =
  typeof CANCELABLE_SCHEDULE_STATUSES[number];

/**
 * Type guard to check that the given `ScheduledJobStatus` is one that can be
 * canceled.
 */
export const isCancelableScheduledJobStatus = (
  status: ScheduledJobStatus
): status is CancelableScheduledJobStatus => {
  return CANCELABLE_SCHEDULE_STATUSES.includes(
    status as CancelableScheduledJobStatus
  );
};
