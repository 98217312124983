import React, { useContext } from 'react';
import {
  VOLEER_BASE_PLAN,
  useDateFormat,
  useTenantContext,
} from '@common/hooks';
import { ChargeBeePortalSessionButton } from '@features/chargebee';
import { useTypedFlags } from '@features/launch-darkly';
import { AccountStatus, SaleItemType } from '@generated/graphql-code-generator';
import { Area, AreaContent, AreaHeading } from '@voleer/ui-kit';
import {
  Box,
  Grid,
  GridProps,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import { AccountStatusBadge } from './components';

/**
 * Renders the "Subscription" tab in the tenant settings page.
 */
export const TenantSubscriptionTab = () => {
  const [t] = useTranslation('pages/TenantSettingsPage');

  const { 'launch-control': enableLaunchControl } = useTypedFlags();
  const { tenant } = useTenantContext();

  const [format, { DateFormat }] = useDateFormat();

  const viewportSize = useContext(ResponsiveContext);
  const defaultColumns = ['flex', 'flex'];
  const columns: Record<string, GridProps['columns']> = {
    small: ['auto'],
    medium: ['auto'],
  };

  const renderSubscriptions = () => {
    if (tenant?.accountStatus !== AccountStatus.Active || !tenant?.planName) {
      return null;
    }

    if (!enableLaunchControl) {
      return (
        <Paragraph
          data-testid="tenant-subscription-tab__status--active"
          margin={{ bottom: 'large', top: 'xsmall' }}
          size="large"
        >
          <Trans
            components={{
              plan: <Text size="large" weight="bold" />,
            }}
            i18nKey="subscription-tab.plan-details.plan"
            t={t}
            values={{ planName: tenant?.planName }}
          />
        </Paragraph>
      );
    }

    // TODO (lle): Entitlement for Voleer Base Plan is hardcoded for now. It will be replaced by entitlements feature.
    const renderVoleerBasePlanEntitlement = () =>
      tenant?.planName === VOLEER_BASE_PLAN && (
        <Box margin={{ left: '22px', bottom: '10px' }}>
          <Text>IT Essentials Solution</Text>
          <Text>Users: 1</Text>
          <Text>Workspaces: 1</Text>
        </Box>
      );

    return (
      <Box margin={{ bottom: '30px' }}>
        <Text>{tenant?.planName}</Text>
        {renderVoleerBasePlanEntitlement()}
        {tenant?.tenantSaleItems
          .filter(saleItem => saleItem.itemType === SaleItemType.Addon)
          .map(addon => (
            <Box key={addon.chargeBeeItemId}>
              <Text>
                {addon.displayName}
                {addon.quantity ? `: ${addon.quantity}` : ''}
              </Text>
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Grid
      columns={columns[viewportSize] || defaultColumns}
      gap="medium"
      pad="medium"
    >
      <Area flex={{ shrink: 0 }} paper={true}>
        <AreaHeading label={t('subscription-tab.heading')} />
        <AreaContent>
          <Text weight="normal">
            {t('subscription-tab.tenant-details.tenant-name')}
          </Text>
          <Text margin={{ bottom: 'medium' }} size="large" weight="bold">
            {tenant?.displayName ?? '-'}
          </Text>

          <Text weight="normal">
            {t('subscription-tab.tenant-details.subdomain')}
          </Text>
          <Text margin={{ bottom: 'medium' }} size="large" weight="bold">
            {tenant?.name ?? '-'}
          </Text>

          <Text weight="normal">
            {t('subscription-tab.tenant-details.status')}
          </Text>
          {tenant?.accountStatus ? (
            <AccountStatusBadge
              data-testid="tenant-subscription-tab__status-badge"
              status={tenant.accountStatus}
            />
          ) : (
            <Text size="large" weight="bold">
              -
            </Text>
          )}
        </AreaContent>
      </Area>

      {tenant?.planName !== 'grandfathered' && (
        <Area
          data-testid="tenant-subscription-tab__plan-details"
          flex={{ shrink: 0 }}
          paper={true}
        >
          <AreaHeading label={t('subscription-tab.plan-details.heading')} />

          <AreaContent>
            {renderSubscriptions()}

            {tenant?.accountStatus === AccountStatus.Trial && (
              <Paragraph
                data-testid="tenant-subscription-tab__status--trial"
                margin={{ bottom: 'large', top: 'xsmall' }}
                size="large"
              >
                {t('subscription-tab.plan-details.free-trial')}
              </Paragraph>
            )}

            {tenant?.accountStatus === AccountStatus.Canceled && (
              <Paragraph
                data-testid="tenant-subscription-tab__status--canceled"
                margin={{ bottom: 'large', top: 'xsmall' }}
                size="large"
              >
                {t('subscription-tab.plan-details.canceled')}
              </Paragraph>
            )}

            {(tenant?.accountStatus === AccountStatus.Trial ||
              tenant?.accountStatus === AccountStatus.Active) && (
              <>
                <Text
                  data-testid="tenant-subscription-tab__status-date-heading"
                  margin={{ top: '-8px' }}
                  weight="normal"
                >
                  {tenant?.accountStatus === AccountStatus.Trial &&
                    t('subscription-tab.plan-details.trial-end-date')}
                  {tenant?.accountStatus === AccountStatus.Active &&
                    t('subscription-tab.plan-details.next-billing-date')}
                </Text>
                <Text
                  data-testid="tenant-subscription-tab__status-date"
                  margin={{ bottom: 'medium' }}
                  size="large"
                  weight="bold"
                >
                  {tenant?.nextStatusDate
                    ? format(
                        new Date(tenant.nextStatusDate),
                        DateFormat.AbbreviatedHumanized
                      )
                    : '-'}
                </Text>
              </>
            )}

            {/* Using a div to prevent the Button from taking up the entire width of the Area */}
            <div>
              <ChargeBeePortalSessionButton />
            </div>
          </AreaContent>
        </Area>
      )}
    </Grid>
  );
};
