
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DataRequest": [
      "FormDataRequest"
    ],
    "FileSystemDescriptorInterface": [
      "FileDescriptor",
      "FolderDescriptor"
    ],
    "FormComponentInterface": [
      "FormCheckbox",
      "FormColumns",
      "FormDateInput",
      "FormDateTimeInput",
      "FormEmailInput",
      "FormFileInput",
      "FormIntegration",
      "FormLink",
      "FormMultiSelect",
      "FormNumericInput",
      "FormOAuth2Connection",
      "FormParagraph",
      "FormSelect",
      "FormTextArea",
      "FormTextInput",
      "FormTimeInput",
      "FormUrlInput"
    ],
    "InstanceLaunchedBy": [
      "DataSet",
      "ScheduledJob",
      "TenantMember"
    ],
    "Integration": [
      "CustomWorkflowIntegration",
      "OAuth2Integration"
    ],
    "IntegrationConfigurationInterface": [
      "OAuth2Configuration"
    ],
    "LibraryItem": [
      "AutomationMetadata",
      "DashboardMetadata",
      "DataSetMetadata"
    ],
    "Notification": [
      "RunNotification"
    ],
    "NotificationSubscription": [
      "RunNotificationSubscription"
    ],
    "TemplateMetadata": [
      "AutomationTemplate",
      "DataSetTemplate"
    ]
  }
};
      export default result;
    