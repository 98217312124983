import React from 'react';
import { Text } from 'grommet';

/**
 * Renders a styled value for the associated detail.
 *
 * Implementation Example:
 * <DetailsSectionTitle>Name</DetailsSectionTitle>
 * <DetailsSectionValue>Import from Zendesk</DetailsSectionValue>
 *
 * Rendered Example:
 * Name
 * Import from Zendesk
 */
export const DetailsSectionValue: React.FC = ({ children }) => {
  return (
    <Text margin={{ top: 'none' }} size="large" weight="bold">
      {children}
    </Text>
  );
};
