import { useState } from 'react';
import {
  DataSet,
  useConnectionStringModalQuery,
} from '@generated/graphql-code-generator';
import { ConnectionStringModalProps } from './ConnectionStringModal';

type DataSetParam = Pick<DataSet, 'id'>;

type UseConnectionStringModalResult = {
  /**
   * Props passed to the `ConnectionStringModal` component.
   */
  connectionStringModalProps: ConnectionStringModalProps;

  /**
   * Handler for opening the modal.
   */
  openConnectionStringModal: (dataSet: DataSetParam) => void;
};

/**
 * Hook which provides the data for the `ConnectionStringModal` component.
 *
 * ```typescript
 * const { connectionStringModalProps, openConnectionStringModal } = useConnectionStringModal();
 *
 * return <div>
 *   <Button label="Get Connection String" onClick={() => openConnectionStringModal(dataSet)} />
 *   <ConnectionStringModal {...connectionStringModalProps} />
 * </div>
 * ```
 */
export const useConnectionStringModal = (): UseConnectionStringModalResult => {
  const [dataSetId, setDataSetId] = useState<string | undefined>();

  const { data, error, loading } = useConnectionStringModalQuery({
    fetchPolicy: 'cache-and-network',
    skip: !dataSetId,
    variables: {
      // Ignore potentially undefined dataSetId since the query will be skipped if dataSetId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataSetId: dataSetId!,
    },
  });

  const dataSet = data?.dataSet;

  const openConnectionStringModal: UseConnectionStringModalResult['openConnectionStringModal'] =
    dataSet => {
      setDataSetId(dataSet.id);
    };

  const closeConnectionStringModal: ConnectionStringModalProps['onClose'] =
    () => {
      setDataSetId(undefined);
    };

  return {
    connectionStringModalProps: {
      connectionString: dataSet?.connectionString,
      dataSetDisplayName: dataSet?.displayName,
      error,
      isLoading: loading,
      open: !!dataSetId,
      onClose: closeConnectionStringModal,
      readOnlyConnectionString: dataSet?.readOnlyConnectionString,
    },
    openConnectionStringModal,
  };
};
