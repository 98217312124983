import React from 'react';
import { PageContent } from '@common/components';
import {
  useActivitySummarySectionToggleRunSummaryViaEmailMutation,
  useGetCurrentUserTenantMemberQuery,
} from '@generated/graphql-code-generator';
import { Box, CheckBox, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Renders the options associated with the users notification settings.
 */
export const NotificationTab: React.FC = () => {
  const [t] = useTranslation('pages/UserSettingsPage');
  const currentUserQuery = useGetCurrentUserTenantMemberQuery();
  const tenantMember = currentUserQuery.data?.me;
  const [changeSendRunSummaryViaEmail] =
    useActivitySummarySectionToggleRunSummaryViaEmailMutation();
  const onChangeSendRunSummaryViaEmail = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (tenantMember) {
      await changeSendRunSummaryViaEmail({
        variables: {
          input: {
            id: tenantMember.id,
            sendRunSummaryViaEmail: !!event.target.checked,
          },
        },
      });
      currentUserQuery.refetch();
    }
  };

  return (
    <PageContent
      error={currentUserQuery.error}
      loading={currentUserQuery.loading}
    >
      <Box align="center" pad="medium">
        {tenantMember && (
          <Box
            background="white"
            elevation="small"
            gap="medium"
            pad="small"
            round="xsmall"
            width="medium"
          >
            <Box align="center" direction="row" gap="small">
              <CheckBox
                checked={tenantMember.sendRunSummaryViaEmail}
                onChange={onChangeSendRunSummaryViaEmail}
              />
              <Box>
                <Text weight="bold">
                  {t('notification-settings.send-run-summary-title')}
                </Text>
                <Text color="grey">
                  {t('notification-settings.send-run-summary-description')}
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </PageContent>
  );
};
