import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { AppLayout } from '@common/components';
import { useAccountStatus, useTenantContext } from '@common/hooks';
import {
  ConnectionStringModal,
  DeleteDataSetModal,
  useConnectionStringModal,
  useDeleteDataSetModal,
} from '@features/workflows';
import {
  ConfigurationData,
  DataSet,
  DataSetPageDocument,
  DataSetRefreshRunsDocument,
  DataSetStatus,
  TenantMemberRole,
  Workspace,
  useCancelCandidateDataSetConfigurationDataMutation,
  useRefreshDataSetMutation,
  useResetDataSetConfigurationDataMutation,
} from '@generated/graphql-code-generator';
import { LoadingButton } from '@voleer/ui-kit';
import { Box, Button } from 'grommet';
import { useTranslation } from 'react-i18next';

type DataSetPageToolbarProps = Readonly<{
  dataSet?:
    | (Pick<
        DataSet,
        'createdOn' | 'description' | 'displayName' | 'id' | 'status'
      > & {
        // TODO(2411): Refactor TemplateConfigurationsQuery/Mutation and DataSetsQuery/Mutation to reduce code duplication
        candidateData?: Pick<ConfigurationData, 'id'> | null;
        data?: Pick<ConfigurationData, 'id'> | null;
      })
    | null;
  workspace?: Pick<Workspace, 'displayName' | 'id'> | null;
}>;

/**
 * Renders the page content toolbar for the `DataSetPage`.
 */
export const DataSetPageToolbar: React.FC<DataSetPageToolbarProps> = ({
  dataSet,
  workspace,
}) => {
  const [t] = useTranslation('pages/DataSetPage');

  const { tenantMember } = useTenantContext();
  const { disableWriteOperation } = useAccountStatus();

  const { connectionStringModalProps, openConnectionStringModal } =
    useConnectionStringModal();

  const { deleteDataSetModalProps, openDeleteDataSetModal } =
    useDeleteDataSetModal();

  const [resetDataSetConfigurationData, resetDataSetConfigurationDataMutation] =
    useResetDataSetConfigurationDataMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(DataSetPageDocument)!],
    });

  const [refreshDataset, refreshDatasetMutation] = useRefreshDataSetMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(DataSetRefreshRunsDocument)!],
  });

  const [
    cancelCandidateDataSetConfigurationData,
    cancelCandidateDataSetConfigurationMutation,
  ] = useCancelCandidateDataSetConfigurationDataMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(DataSetPageDocument)!],
  });

  const isDeleting = dataSet?.status === DataSetStatus.Deleting;
  const isInEditMode = !!dataSet?.candidateData;
  const hasConfiguration = !!dataSet?.data;

  const onReconfigureClick = async () => {
    if (!tenantMember || !workspace || !dataSet) {
      return;
    }

    await resetDataSetConfigurationData({
      variables: {
        workspaceId: workspace.id,
        dataSetId: dataSet.id,
        resetById: tenantMember.id,
      },
    });
  };

  const onRefreshClick = async () => {
    if (!workspace || !dataSet) {
      return;
    }

    await refreshDataset({
      variables: {
        workspaceId: workspace.id,
        dataSetId: dataSet.id,
      },
    });
  };

  const onCancelReconfigureClick = async () => {
    if (
      !workspace ||
      !dataSet ||
      !dataSet?.candidateData?.id ||
      !isInEditMode
    ) {
      return;
    }

    await cancelCandidateDataSetConfigurationData({
      variables: {
        workspaceId: workspace.id,
        dataSetId: dataSet.id,
        candidateConfigurationDataId: dataSet.candidateData.id,
      },
    });
  };

  if (dataSet?.status !== DataSetStatus.Ready) return null;

  return (
    <>
      <AppLayout.Toolbar>
        {!isInEditMode && hasConfiguration && (
          <LoadingButton
            data-testid="dataset-page-toolbar__btn--reconfigure"
            disabled={
              !tenantMember || !workspace || !dataSet || disableWriteOperation
            }
            label={t('action-buttons.reconfigure')}
            loading={resetDataSetConfigurationDataMutation.loading}
            onClick={onReconfigureClick}
            size="small"
          />
        )}

        {!isInEditMode && !isDeleting && (
          <Box gap="small">
            <Button
              data-testid="dataset-page-toolbar__btn--connection-string"
              disabled={disableWriteOperation}
              label={t('action-buttons.connection-string')}
              onClick={() => {
                openConnectionStringModal(dataSet);
              }}
              size="small"
            />
            <Button
              data-testid="dataset-page-toolbar__btn--refresh"
              disabled={disableWriteOperation || refreshDatasetMutation.loading}
              label={t('action-buttons.refresh')}
              onClick={() => {
                onRefreshClick();
              }}
              size="small"
            />
            {tenantMember?.role === TenantMemberRole.Admin && (
              <Button
                label={t('action-buttons.delete')}
                onClick={() => openDeleteDataSetModal(dataSet)}
                size="small"
              />
            )}
          </Box>
        )}

        {isInEditMode && hasConfiguration && (
          <LoadingButton
            alignSelf="start"
            label={t('action-buttons.cancel-reconfiguration')}
            loading={cancelCandidateDataSetConfigurationMutation.loading}
            onClick={onCancelReconfigureClick}
            primary={true}
            size="small"
          />
        )}
      </AppLayout.Toolbar>

      <ConnectionStringModal {...connectionStringModalProps} />
      <DeleteDataSetModal {...deleteDataSetModalProps} />
    </>
  );
};
