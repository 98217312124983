import React from 'react';
import { Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { PackageMarkdown } from '../PackageMarkdown';

export type ReadmeElementProps = Readonly<{
  readme?: string | null;
  description?: string | null;
}>;

/**
 * Renders the README markdown for a package.
 */
export const ReadmeElement: React.FC<ReadmeElementProps> = ({
  readme,
  description,
}) => {
  const [t] = useTranslation('components/ReadmeElement');

  if (readme) return <PackageMarkdown content={readme} />;

  if (description) return <Text>{description}</Text>;

  return (
    <Text className="test-empty-description" margin="medium" textAlign="center">
      {t('empty-description')}
    </Text>
  );
};
