import React from 'react';
import { PropsOf } from '@voleer/types';
import { Box, Text } from 'grommet';

const DayBubble: React.FC<PropsOf<typeof Box>> = props => (
  <Box
    align="center"
    border={{ side: 'all', color: 'status-disabled' }}
    margin={{ right: '.16em' }}
    round="large"
    width={{ min: '1.15em', max: '1.15em' }}
    {...props}
  />
);

/**
 * Weekdays props containing active numeric days of the week
 */
export type WeekScheduleProps = {
  activeDays: number[];
};

/**
 * Renders days of week bubbles
 */
export const WeekSchedule: React.FC<WeekScheduleProps> = ({ activeDays }) => {
  return (
    <Box direction="row">
      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((weekday, index) => {
        const isSelected = activeDays.includes(index);
        return (
          <DayBubble
            background={isSelected ? 'graph-1' : 'inherit'}
            className={isSelected ? 'selected' : undefined}
            key={index}
          >
            <Text color={isSelected ? 'inherit' : 'status-disabled'}>
              {weekday}
            </Text>
          </DayBubble>
        );
      })}
    </Box>
  );
};
