import React, { useState } from 'react';
import { urlFor } from '@common/utils';
import {
  useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation,
  useIntegrationModalQuery,
  useIntegrationModalResetCustomWorkflowIntegrationMutation,
  useIntegrationModalResetOAuth2IntegrationMutation,
} from '@generated/graphql-code-generator';
import { LoadingButton } from '@voleer/ui-kit';
import { Box, Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { IntegrationModalProps } from '../..';
import { DeleteIntegrationModal } from '../../..';

export type IntegrationModalToolbarProps = Pick<
  IntegrationModalProps,
  'integrationId' | 'onClose'
>;

/**
 * Renders the toolbar of actions for the IntegrationModal.
 */
export const IntegrationModalToolbar: React.FC<IntegrationModalToolbarProps> =
  ({ integrationId, onClose }) => {
    const [t] = useTranslation(
      'features/integrations/components/IntegrationModal'
    );

    const { data } = useIntegrationModalQuery({
      fetchPolicy: 'cache-only',
      variables: { integrationId },
    });

    const integration = data?.integration;

    const [deleting, setDeleting] = useState(false);

    const [resetOAuth2Integration] =
      useIntegrationModalResetOAuth2IntegrationMutation();

    const [
      resetCustomWorkflowIntegration,
      resetCustomWorkflowIntegrationMutation,
    ] = useIntegrationModalResetCustomWorkflowIntegrationMutation();

    const [
      cancelCustomWorkflowReconfiguration,
      cancelCustomWorkflowReconfigurationMutation,
    ] =
      useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation();

    const onRefreshSession = async () => {
      const { data } = await resetOAuth2Integration({
        variables: { input: { integrationId } },
      });

      const sessionId = data?.resetOAuth2Integration.sessionId;
      if (!sessionId) {
        throw new Error('Expected sessionId in response');
      }

      window.open(
        urlFor('authorizeOAuth2Session')({ sessionId }),
        `OAuth2SessionWindow - ${sessionId}`,
        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=-100,top=-100`
      );
    };

    // Data is expected to be loaded by the IntegrationModal component
    if (!integration) {
      return null;
    }

    // Having both a candidateInstance and an existing instance indicates that the
    // Integration is being re-configured rather than being configured for the
    // first time
    const isReconfiguring =
      integration.__typename === 'CustomWorkflowIntegration' &&
      !!integration.candidateInstance &&
      !!integration.instance;

    const isConfigured =
      integration.__typename === 'CustomWorkflowIntegration' &&
      !isReconfiguring &&
      !!integration.instance;

    return (
      <>
        <Box flex={{ shrink: 0 }} gap="small">
          {integration.__typename === 'OAuth2Integration' && (
            <Button
              label={t('buttons.reset-oauth2.label')}
              onClick={onRefreshSession}
              size="small"
            />
          )}

          {isReconfiguring && (
            <LoadingButton
              label={t('buttons.cancel-reconfigure.label')}
              loading={cancelCustomWorkflowReconfigurationMutation.loading}
              onClick={() => {
                cancelCustomWorkflowReconfiguration({
                  variables: { integrationId },
                });
              }}
              size="small"
            />
          )}

          {isConfigured && (
            <LoadingButton
              label={t('buttons.reset-custom-workflow.label')}
              loading={resetCustomWorkflowIntegrationMutation.loading}
              onClick={() => {
                resetCustomWorkflowIntegration({
                  variables: { integrationId },
                });
              }}
              size="small"
            />
          )}

          <Button
            data-testid="integration-modal__toolbar--delete-button"
            label={t('buttons.delete.label')}
            onClick={() => setDeleting(true)}
            size="small"
          />
        </Box>

        {deleting && (
          <DeleteIntegrationModal
            integrationId={integrationId}
            onClose={() => setDeleting(false)}
            onCompleted={onClose}
          />
        )}
      </>
    );
  };
