import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';

type ErrorContentValue = {
  error: boolean;
  heading: string;
  body: string;
};

/**
 * Type guard to check if the given value is an `ErrorContentValue`.
 */
const isErrorContentValue = (value: unknown): value is ErrorContentValue => {
  if (!value) {
    return false;
  }
  return typeof (value as ErrorContentValue).error === 'boolean';
};

export type ErrorOverlayProps = {
  error?: Error | ErrorContentValue | React.ReactNode | boolean;
};

/**
 * Component that conditionally renders an error state instead of its children.
 */
export const ErrorOverlay: React.FC<ErrorOverlayProps> = ({
  children,
  error,
}) => {
  const [t] = useTranslation('components/ErrorOverlay');

  const layout = (children: React.ReactNode) => (
    <Box align="center" fill={true}>
      <Box basis="65%" justify="center" pad="medium">
        {children}
      </Box>
    </Box>
  );

  if (isErrorContentValue(error) && error.error) {
    return layout(
      <Box width={{ max: 'medium' }}>
        <Heading level="2">{error.heading || t('heading')}</Heading>
        {error.body && <Paragraph>{error.body}</Paragraph>}
      </Box>
    );
  }

  if (typeof error === 'string') {
    return layout(
      <Box width={{ max: 'medium' }}>
        <Heading level="2">{t('heading')}</Heading>
        <Paragraph>{error}</Paragraph>
      </Box>
    );
  }

  if (error instanceof Error) {
    return layout(
      <Box width={{ max: 'medium' }}>
        <Heading level="2">{t('heading')}</Heading>
        <Paragraph>{error.message}</Paragraph>
      </Box>
    );
  }

  if (error === true) {
    return layout(
      <Box width={{ max: 'medium' }}>
        <Heading level="2">{t('heading')}</Heading>
      </Box>
    );
  }

  return <>{children}</>;
};
