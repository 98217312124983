import React, { useState } from 'react';
import { useAuthenticatedAxios } from '@common/hooks';
import { urlFor } from '@common/utils';
import { FormFileInputFragment } from '@generated/graphql-code-generator';
import { PropsOf } from '@voleer/types';
import { GhostButton, Icon } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import { Box, FileInput, FormField, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaFileAlt, FaTimes } from 'react-icons/fa';
import { InitialValues, LabelMarkdown } from '.';

type FileInputFieldProps = {
  dataRequestId?: string;
  definition: FormFileInputFragment;
  disabled?: boolean;
  instanceId?: string;
  workspaceId?: string;
};

/**
 * Renders a workflow form file input field.
 */
export const FileInputField = React.forwardRef<
  HTMLInputElement,
  FileInputFieldProps
>(({ dataRequestId, definition, disabled, instanceId, workspaceId }, ref) => {
  const [t] = useTranslation('features/workflows/components/FileInputField');

  const axiosInstance = useAuthenticatedAxios();

  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta, fieldHelpers] = useField<string | undefined>(
    definition.name
  );

  // Grab a previously uploaded file name from the Voleer filepath, i.e.
  // voleer://workspace.instance/filename.ext => filename.ext
  const initialFilePathArr = fieldMeta.initialValue?.split('/');
  const initialFileName = initialFilePathArr?.length
    ? initialFilePathArr[initialFilePathArr.length - 1]
    : '';
  const [isExistingFile, setIsExistingFile] = useState<boolean>(
    !!initialFileName
  );

  const onChange: PropsOf<typeof FileInput>['onChange'] = event => {
    const files = event.target.files;
    if (!files?.length) {
      // Clear the field value if the user previously uploaded a file
      if (field.value) {
        fieldHelpers.setValue('');
      }

      return;
    }

    if (!dataRequestId || !instanceId || !workspaceId) {
      fieldHelpers.setError(t('error.failed'));
      return;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader
    const reader = new FileReader();
    const file = files[0];

    if (file.size > definition.maxFileSizeInBytes) {
      fieldHelpers.setError(t('error.too-large'));
      return;
    }

    // Add an event listener to the FileReader's "loadend" event so that we can process
    // the uploaded file contents.
    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/loadend_event
    reader.addEventListener('loadend', async () => {
      try {
        const response = await axiosInstance({
          data: reader.result,
          headers: { 'Content-Type': file.type },
          method: 'post',
          params: {
            componentName: definition.name,
            dataRequestId,
            fileName: file.name,
            instanceId,
            maxFileSizeInBytes: definition.maxFileSizeInBytes,
            workspaceId,
          },
          url: urlFor('fileFormComponentUpload')(),
        });

        // Set the value of the field programmatically via Formik so that the form field
        // can be validated, i.e. the user tries to submit the form but did not upload
        // a required file.
        fieldHelpers.setValue(response.data.resultUri);
      } catch (error) {
        fieldHelpers.setError(error.message);
      }
    });

    // Trigger the FileReader's "loadend" event; convert the file contents to a raw
    // binary data buffer for the back-end.
    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer
    reader.readAsArrayBuffer(file);
  };

  const removeExistingFile = () => {
    setIsExistingFile(false);
    fieldHelpers.setValue('');
  };

  return (
    <FormField
      error={fieldMeta.error}
      label={<LabelMarkdown content={definition.label} />}
    >
      {isExistingFile ? (
        <Box
          align="center"
          background="light-2"
          border={{ color: 'dark-4' }}
          data-testid="file-input-field__existing-file"
          direction="row"
          round="xsmall"
        >
          <Box
            align="center"
            direction="row"
            margin={{ left: 'small', right: 'auto' }}
          >
            <Icon color="dark-4" icon={FaFileAlt} size="large" />
            <Text margin={{ left: 'xsmall' }} weight="bold">
              {initialFileName}
            </Text>
          </Box>

          <GhostButton
            icon={<Icon icon={FaTimes} />}
            onClick={removeExistingFile}
            title={t('upload-new-file')}
          />
        </Box>
      ) : (
        <FileInput
          {...field}
          data-testid="file-input-field__uploader"
          disabled={disabled || formik.isSubmitting}
          messages={{
            browse: t('browse'),
            dropPrompt: t('drag-n-drop'),
          }}
          multiple={false}
          onChange={onChange}
          ref={ref}
          // Do not set the file contents as the value of the file input for security. Instead, we
          // set the value of this form field programmatically via Formik.
          // https://stackoverflow.com/questions/1696877/how-to-set-a-value-to-a-file-input-in-html
          value={undefined}
        />
      )}
    </FormField>
  );
});
