import React from 'react';
import { urlFor } from '@common/utils';
import { useLibraryItemRedirectPageQuery } from '@generated/graphql-code-generator';
import { Redirect } from 'react-router';
import { LibraryItemPageRouteParams } from '..';
import { LibraryItemPageLayout } from '../components';

type LibraryItemRedirectPageProps = Pick<
  LibraryItemPageRouteParams,
  'name' | 'packageName' | 'publisherName'
>;

/**
 * Provides backward compatibility for old library item URLs that did not
 * provide "type" by redirecting to the new library item URL after looking up
 * the type.
 */
export const LibraryItemRedirectPage: React.FC<LibraryItemRedirectPageProps> =
  ({ name, packageName, publisherName }) => {
    const { loading, error, data } = useLibraryItemRedirectPageQuery({
      variables: {
        input: {
          itemNames: [name],
          packageNames: [packageName],
          publisherNames: [publisherName],
        },
      },
    });

    const libraryItem = data?.libraryItems?.items?.[0];
    const libraryItemType = libraryItem?.libraryItemType;

    if (!libraryItem || !libraryItemType) {
      return <LibraryItemPageLayout error={error} loading={loading} />;
    }

    return (
      <Redirect
        to={urlFor('libraryItem')({
          name,
          packageName,
          publisherName,
          type: libraryItemType,
        })}
      />
    );
  };
