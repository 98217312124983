import React, { Reducer, useReducer } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { PageContent } from '@common/components';
import { useTypedFlags } from '@features/launch-darkly';
import {
  EditTenantMemberForm,
  EditTenantMemberFormProps,
  EditTenantMemberReturnCode,
  TenantMemberList,
  TenantMemberListProps,
} from '@features/tenant';
import {
  FindTenantMembersDocument,
  TenantMemberFragment,
  useCreateUserMutation,
  useDeleteTenantMemberMutation,
  useEditUserMutation,
  useFindTenantMembersQuery,
  useResendInvitationEmailMutation,
  useTenantHasExternalIdpQuery,
} from '@generated/graphql-code-generator';
import { UnreachableCaseError } from '@voleer/types';
import { FancyTable } from '@voleer/ui-kit';
import { Box, Heading } from 'grommet';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AddUserButton } from './components';

type TenantMembersOrderByProperty = 'name';

type TenantMembersOrderByProps = {
  property: TenantMembersOrderByProperty;
  direction: 'asc' | 'desc';
};

const DUPLICATE_USER_NAME_CODE = 'DuplicateUserName';

type TenantMembersTabState = {
  orderByProps: TenantMembersOrderByProps;
  searchTerm: string;
  selectedTenantMember: TenantMemberFragment | null;
  isAddingUser: boolean;
  isPendingState: boolean;
  newlyAddedUserId: string;
};

type TenantMembersTabReducerAction =
  | {
      type: 'setSelectedTenantMember';
      tenantMember: TenantMemberFragment | null;
    }
  | { type: 'setAddingUser'; isAddingUser: boolean }
  | { type: 'setNewlyAddedUserId'; userId: string }
  | { type: 'setOrderBy'; orderBy: TenantMembersOrderByProps }
  | { type: 'setPendingState'; isPendingState: boolean }
  | { type: 'setSearchTerm'; searchTerm: string };

const reducer: Reducer<TenantMembersTabState, TenantMembersTabReducerAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'setAddingUser':
      return { ...state, isAddingUser: action.isAddingUser };
    case 'setNewlyAddedUserId':
      return { ...state, newlyAddedUserId: action.userId };
    case 'setOrderBy':
      return { ...state, orderByProps: action.orderBy };
    case 'setPendingState':
      return { ...state, isPendingState: action.isPendingState };
    case 'setSearchTerm':
      return { ...state, searchTerm: action.searchTerm };
    case 'setSelectedTenantMember':
      return { ...state, selectedTenantMember: action.tenantMember };
    default:
      throw new UnreachableCaseError(action);
  }
};

const initialTenantMembersTabState: TenantMembersTabState = {
  selectedTenantMember: null,
  searchTerm: '',
  isPendingState: false,
  orderByProps: {
    property: 'name',
    direction: 'asc',
  },
  newlyAddedUserId: '',
  isAddingUser: false,
};

/**
 * Component for showing the tenant member management tab.
 */
export const TenantMembersTab: React.FC = () => {
  const [t] = useTranslation('pages/TenantSettingsPage');
  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();
  const [
    {
      isAddingUser,
      newlyAddedUserId,
      isPendingState,
      searchTerm,
      orderByProps,
      selectedTenantMember,
    },
    dispatch,
  ] = useReducer(reducer, initialTenantMembersTabState);
  const tenantHasExternalIDP = useTenantHasExternalIdpQuery({
    fetchPolicy: 'cache-and-network',
  });
  const findTenantMembersResult = useFindTenantMembersQuery({
    pollInterval: pollingConfig?.tenantSettingsTenantMembersList,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        isActive: true,
      },
    },
  });
  const [deleteUser] = useDeleteTenantMemberMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(FindTenantMembersDocument)!],
  });
  const [createUser] = useCreateUserMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(FindTenantMembersDocument)!],
  });
  const [editUser] = useEditUserMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(FindTenantMembersDocument)!],
  });
  const [sendReminder] = useResendInvitationEmailMutation();

  // Loading and error states
  const queries = [findTenantMembersResult, tenantHasExternalIDP];
  const loading = queries.some(query => !query.data && query.loading);
  const error = queries.find(query => query.error)?.error;

  // Callbacks
  const toggleAddUser = (value: boolean) => () =>
    dispatch({ type: 'setAddingUser', isAddingUser: value });
  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'setSearchTerm', searchTerm: event.target.value });
  const handleSortOrderChange =
    (property: TenantMembersOrderByProperty) => () =>
      dispatch({
        type: 'setOrderBy',
        orderBy: {
          property,
          direction: orderByProps.direction === 'asc' ? 'desc' : 'asc',
        },
      });
  const handleSetSelectedTenantMember = (
    tenantMember: TenantMemberFragment | null
  ) => dispatch({ type: 'setSelectedTenantMember', tenantMember });

  const handleOnEditUserSubmit: TenantMemberListProps['onEditTenantMember'] =
    async values => {
      try {
        const { data } = await editUser({
          variables: {
            input: {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              emailAddress: values.emailAddress,
              role: values.role,
            },
          },
        });

        // Handle success
        const isSuccessful = !!data?.editTenantMember.isSuccessful;
        if (isSuccessful) {
          // Reset edit mode and forward success
          dispatch({ type: 'setSelectedTenantMember', tenantMember: null });
          return EditTenantMemberReturnCode.Success;
        }

        // Catch email already taken error
        const error = data?.editTenantMember.errors?.[0];
        if (error?.code === DUPLICATE_USER_NAME_CODE) {
          return EditTenantMemberReturnCode.EmailAlreadyTaken;
        }

        return EditTenantMemberReturnCode.UnknownError;
      } catch {
        return EditTenantMemberReturnCode.UnknownError;
      }
    };

  const handleOnAddUserSubmit: EditTenantMemberFormProps['onTenantMemberSave'] =
    async values => {
      try {
        const { data } = await createUser({
          variables: {
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              emailAddress: values.emailAddress,
              role: values.role,
            },
          },
        });

        // Handle success
        const isSuccessful = !!data?.addTenantMember.isSuccessful;
        if (isSuccessful) {
          // Extract the created tenant member and return success
          const createMember = data?.addTenantMember.tenantMember;
          if (createMember) {
            dispatch({ type: 'setAddingUser', isAddingUser: false });
            dispatch({ type: 'setNewlyAddedUserId', userId: createMember.id });
            return EditTenantMemberReturnCode.Success;
          }
        }

        // Catch email already taken error
        const error = data?.addTenantMember.errors?.[0];
        if (error?.code === DUPLICATE_USER_NAME_CODE) {
          return EditTenantMemberReturnCode.EmailAlreadyTaken;
        }

        // Handle unknown error
        return EditTenantMemberReturnCode.UnknownError;
      } catch (e) {
        return EditTenantMemberReturnCode.UnknownError;
      }
    };

  const tenantMembers = findTenantMembersResult.data?.tenantMembers ?? [];
  const sortedTenantMembers = orderBy(
    tenantMembers,
    (tenantMember: TenantMemberFragment) => [
      tenantMember.firstName.toLowerCase(),
      tenantMember.lastName.toLowerCase(),
    ],
    [orderByProps.direction, orderByProps.direction]
  );

  const isTenantMemberInEditMode =
    !!selectedTenantMember &&
    !!tenantMembers.find(tm => tm.id === selectedTenantMember.id);

  const disableLayout =
    isTenantMemberInEditMode || isAddingUser || isPendingState;

  const handleOnDeleteUser = async (id: string) => {
    try {
      dispatch({ type: 'setPendingState', isPendingState: true });
      const deleteUserResult = await deleteUser({
        variables: {
          input: {
            id,
          },
        },
      });

      const isSuccessful =
        !!deleteUserResult.data?.deleteTenantMember.isSuccessful;
      return isSuccessful;
    } catch (e) {
      return false;
    } finally {
      dispatch({ type: 'setPendingState', isPendingState: false });
    }
  };

  const handleOnSendReminder = async (id: string) => {
    try {
      dispatch({ type: 'setPendingState', isPendingState: true });
      const sendReminderResult = await sendReminder({
        variables: {
          input: {
            tenantMemberId: id,
          },
        },
      });

      return !!sendReminderResult.data?.resendEmailInvitation.isSuccessful;
    } catch (e) {
      return false;
    } finally {
      dispatch({ type: 'setPendingState', isPendingState: false });
    }
  };

  return (
    <PageContent
      error={error?.message}
      loading={loading}
      loadingLabel={t('members-tab.loading')}
    >
      <Box align="center" pad="medium">
        <FancyTable
          columns={[
            { width: '27%' },
            undefined,
            undefined,
            { width: '200px' },
            { width: '36px', justify: 'center' },
          ]}
          disabled={disableLayout}
          paper={true}
          width="large"
        >
          <FancyTable.Toolbar>
            <FancyTable.Search
              onChange={handleSearchTermChange}
              placeholder={t('members-tab.search-users-placeholder')}
              value={searchTerm}
            />
            <AddUserButton
              disabled={disableLayout}
              onClick={toggleAddUser(true)}
            />
          </FancyTable.Toolbar>
          <FancyTable.Header>
            <FancyTable.Row>
              <FancyTable.HeaderCell
                onClick={handleSortOrderChange('name')}
                sortDirection="asc"
              >
                {t('members-tab.sort-bar.name')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('members-tab.sort-bar.role')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('members-tab.sort-bar.last-signed-in')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell />
              <FancyTable.HeaderCell />
            </FancyTable.Row>
          </FancyTable.Header>
          {isAddingUser && (
            <Box
              animation={{ type: 'fadeIn', delay: 100, duration: 250 }}
              background="white"
              elevation="medium"
              pad="medium"
              round="xsmall"
            >
              <Heading level="4">{t('members-tab.add-user')}</Heading>
              <EditTenantMemberForm
                onCancel={toggleAddUser(false)}
                onTenantMemberSave={handleOnAddUserSubmit}
                submitLabel={t('members-tab.add-user')}
              />
            </Box>
          )}
          <FancyTable.Body>
            <TenantMemberList
              disabled={isAddingUser || isPendingState}
              editingDisabled={
                tenantHasExternalIDP.data?.tenantIdp?.externalProviderEnabled
              }
              newlyAddedUserId={newlyAddedUserId}
              onDeleteTenantMember={handleOnDeleteUser}
              onEditTenantMember={handleOnEditUserSubmit}
              onSendReminder={handleOnSendReminder}
              searchTerm={searchTerm}
              selectedTenantMember={selectedTenantMember}
              setSelectedTenantMember={handleSetSelectedTenantMember}
              tenantMembers={sortedTenantMembers}
            />
          </FancyTable.Body>
        </FancyTable>
      </Box>
    </PageContent>
  );
};
