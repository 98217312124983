import React, { useContext, useState } from 'react';
import { TextAvatar } from '@common/components';
import { useDateFormat } from '@common/hooks';
import {
  getRunStatus,
  isSaveableTemplateConfiguration,
  useWorkflowTranslations,
} from '@features/workflows';
import {
  DataSet,
  InstanceStatus,
  LaunchedByType,
  ScheduledJob,
  TemplateConfiguration,
} from '@generated/graphql-code-generator';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  FancyTableProps,
  Icon,
  Link,
  RotatingIcon,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import {
  Box,
  CheckBox,
  InfiniteScroll,
  ResponsiveContext,
  Select,
  Text,
} from 'grommet';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';
import {
  InstanceStatusBadge,
  SaveTemplateConfigurationModal,
  SaveTemplateConfigurationModalProps,
} from '..';
import { OpenCancelRunModalFn, canCancelRun } from '../CancelRunModal';
import {
  InstanceProp,
  LaunchedByProp,
  TemplateConfigurationProp,
  TemplateMetadataProp,
} from './interface';

type OnSaveTemplateConfigurationValue = {
  templateConfiguration: Pick<TemplateConfiguration, 'id'>;
  displayName: string;
};

export type WorkflowRunsTableProps = FancyTableProps & {
  /**
   * The instances to display in the table.
   */
  instances: InstanceProp[];

  /**
   * Callback to provide the URL for a given DataSet.
   */
  urlForDataSet: (dataSet: Pick<DataSet, 'id'>) => string;

  /**
   * Callback to provide the URL for a given Instance.
   */
  urlForInstance: (instance: InstanceProp) => string;

  /**
   * Callback to provide the URL for a given Template.
   */
  urlForTemplate: (template: TemplateMetadataProp) => string;

  /**
   * Callback to provide the URL for a given Template Configuration.
   */
  urlForTemplateConfiguration: (template: TemplateConfigurationProp) => string;

  /**
   * Callback to provide the URL for a given Scheduled Job.
   */
  urlForScheduledJob: (scheduledJob: Pick<ScheduledJob, 'id'>) => string;

  /**
   * The current search term.
   */
  searchTerm?: string;

  /**
   * Callback which is called when the user types into the table's search input.
   */
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;

  /**
   * Callback to handle saving the configuration for the given Instance.
   */
  onSaveTemplateConfiguration: (
    value: OnSaveTemplateConfigurationValue
  ) => Promise<void> | void;

  /**
   * Callback to handle loading more runs.
   */
  onLoadMore: (() => Promise<void>) | undefined;

  /**
   * Callback to open the cancel run modal for provided instance id
   */
  openCancelRunModal: OpenCancelRunModalFn;

  /**
   * The current workspace id
   */
  workspaceId: string;

  /**
   * Selected launched by filters
   */
  selectedLaunchTypeFilters: LaunchedByType[] | undefined;

  /**
   * Callback to set launched by filters
   */
  onLaunchTypeFiltersChange: (
    selectedLaunchTypeFilters: LaunchedByType[] | undefined
  ) => void;
};

type SaveConfigurationState = {
  templateConfiguration: SaveTemplateConfigurationModalProps['templateConfiguration'];
};

/**
 * Renders the table for displaying Runs in Workspace.
 */
export const WorkflowRunsTable: React.FC<WorkflowRunsTableProps> = ({
  instances,
  selectedLaunchTypeFilters,
  onLaunchTypeFiltersChange,
  urlForDataSet,
  urlForInstance,
  urlForTemplate,
  urlForTemplateConfiguration,
  urlForScheduledJob,
  onSaveTemplateConfiguration,
  onSearch,
  onLoadMore,
  openCancelRunModal,
  searchTerm,
  workspaceId,
  ...fancyTableProps
}) => {
  const [t] = useTranslation('features/workflows/components/WorkflowRunsTable');
  const { getLaunchedByName } = useWorkflowTranslations();
  const [formatDate, { DateTimeFormat }] = useDateFormat();
  const viewportSize = useContext(ResponsiveContext);

  // Tracks the current state of the "Save Configuration" modal. If `undefined`
  // then the modal is closed.
  const [saveConfigurationState, setSaveConfigurationState] = useState<
    SaveConfigurationState | undefined
  >();

  const onSaveConfigurationClick = (instance: InstanceProp) => () => {
    const templateConfiguration = instance.templateConfiguration;
    if (!templateConfiguration) {
      return;
    }

    // Open the modal
    setSaveConfigurationState({ templateConfiguration });
  };

  const onCancelSaveConfiguration: SaveTemplateConfigurationModalProps['onCancel'] =
    () => {
      // Close the modal
      setSaveConfigurationState(undefined);
    };

  const onSubmitSaveConfiguration: SaveTemplateConfigurationModalProps['onSubmit'] =
    async ({ templateConfiguration, displayName }) => {
      await onSaveTemplateConfiguration({ templateConfiguration, displayName });
      setSaveConfigurationState(undefined);
    };

  const getLaunchedByLabel = (id?: LaunchedByType): string => {
    switch (id) {
      case LaunchedByType.TenantMember: {
        return t('filter.options.user');
      }
      case LaunchedByType.ScheduledJob: {
        return t('filter.options.scheduled-job');
      }
      case LaunchedByType.DataSetConfiguration: {
        return t('filter.options.dataset');
      }
      default: {
        return '-';
      }
    }
  };

  const renderLaunchedByUserContent = (launchedBy?: LaunchedByProp | null) => {
    const launchedByName =
      launchedBy?.__typename === 'TenantMember'
        ? getLaunchedByName(launchedBy)
        : compact([
            launchedBy?.createdBy?.firstName,
            launchedBy?.createdBy?.lastName,
          ]).join(' ');

    if (!launchedBy || !launchedByName) {
      return <Text>-</Text>;
    }

    return (
      <Box align="center" direction="row" gap="xsmall">
        <TextAvatar name={launchedByName} size="large" />
        <Text title={launchedByName} truncate={true}>
          {launchedByName}
        </Text>
      </Box>
    );
  };

  const renderFilterLabel = () => (
    <Box pad="8px" width="small">
      <Text
        color={!selectedLaunchTypeFilters?.length ? 'dark-3' : undefined}
        truncate={true}
      >
        {!selectedLaunchTypeFilters
          ? t('filter.launched-by.placeholder')
          : selectedLaunchTypeFilters
              .map(filter => getLaunchedByLabel(filter))
              .join(', ')}
      </Text>
    </Box>
  );

  const renderRow = (instance: InstanceProp) => {
    if (!instance) {
      return null;
    }

    const templateConfiguration = instance.templateConfiguration;
    const configurationInstance = templateConfiguration?.configurationInstance;

    const instanceUrl = urlForInstance(instance);
    const scheduledJobUrl = instance.launchedBy
      ? urlForScheduledJob(instance.launchedBy)
      : null;
    const datasetUrl = instance.templateConfiguration
      ? urlForDataSet(instance.templateConfiguration)
      : null;
    const instanceName = instance.displayName ?? undefined;
    const launchedByLabel = getLaunchedByLabel(instance.launchedByType);

    const runStatus = getRunStatus(
      instance.status,
      configurationInstance?.status
    );

    // If the template instance is running, tenant should not be able to Save the Template configuration.
    const canBeSaved =
      runStatus !== InstanceStatus.Running &&
      isSaveableTemplateConfiguration(templateConfiguration);

    const updatedOn = formatDate(
      parseISO(instance.updatedOn),
      DateTimeFormat.Abbreviated
    );

    return (
      <FancyTable.Row className="test_row" key={instance.id}>
        <FancyTable.Cell className="test_cell_instance">
          <Text title={instanceName} truncate={true}>
            {instanceUrl ? (
              <Link to={instanceUrl} variation="primary">
                {instanceName ?? '-'}
              </Link>
            ) : (
              instanceName ?? '-'
            )}
          </Text>
        </FancyTable.Cell>
        <FancyTable.Cell className="test_cell_status">
          <InstanceStatusBadge status={runStatus} truncate={true} />
        </FancyTable.Cell>
        <FancyTable.Cell className="test_cell_launched-by">
          <Text title={launchedByLabel} truncate={true}>
            {launchedByLabel}
          </Text>
        </FancyTable.Cell>
        <FancyTable.Cell className="test_cell_user">
          {renderLaunchedByUserContent(instance.launchedBy)}
        </FancyTable.Cell>
        <FancyTable.Cell className="test_cell_updated-on">
          <Text title={updatedOn} truncate={true}>
            {updatedOn}
          </Text>
        </FancyTable.Cell>
        <FancyTable.Cell className="test_cell_actions">
          <DropButton
            className="test_actions-button"
            icon={<Icon icon={MdMoreHoriz} />}
            title={t('actions.title')}
            variation="ghost"
          >
            <Box width={{ min: 'xsmall' }}>
              <Link
                className="test_actions_view-instance"
                to={instanceUrl}
                variation="unstyled"
              >
                <DropButtonItem label={t('actions.items.view.label')} />
              </Link>
              {instance.launchedByType === LaunchedByType.ScheduledJob &&
                scheduledJobUrl && (
                  <Link
                    className="test_actions_scheduled_job-instance"
                    to={scheduledJobUrl}
                    variation="unstyled"
                  >
                    <DropButtonItem
                      label={t('actions.items.scheduled-job.label')}
                    />
                  </Link>
                )}
              {instance.launchedByType ===
                LaunchedByType.DataSetConfiguration &&
                datasetUrl && (
                  <Link
                    className="test_actions_dataset-instance"
                    to={datasetUrl}
                    variation="unstyled"
                  >
                    <DropButtonItem label={t('actions.items.dataset.label')} />
                  </Link>
                )}
              {canBeSaved && (
                <DropButtonItem
                  className="test_actions_save-configuration"
                  label={t('actions.items.save-configuration.label')}
                  onClick={onSaveConfigurationClick(instance)}
                />
              )}
              {canCancelRun(instance?.status) && (
                <DropButtonItem
                  data-testid="cancel-run-action"
                  label={t('actions.items.cancel-run.label')}
                  onClick={() =>
                    openCancelRunModal({
                      workspaceId,
                      instanceId: instance?.id,
                    })
                  }
                />
              )}
            </Box>
          </DropButton>
        </FancyTable.Cell>
      </FancyTable.Row>
    );
  };

  const renderBody = () => {
    if (searchTerm && !instances.length) {
      return <FancyTable.Empty>{t('no-results')}</FancyTable.Empty>;
    }

    if (!instances.length) {
      return <FancyTable.Empty>{t('empty')}</FancyTable.Empty>;
    }

    return (
      <InfiniteScroll
        items={instances}
        onMore={onLoadMore}
        renderMarker={() => {
          return (
            <Box
              align="center"
              direction="row"
              justify="center"
              pad={{ bottom: 'large', top: 'medium' }}
            >
              <RotatingIcon icon={FaSpinner} size="14px" />
              <Text margin={{ left: 'small' }}>{t('loading-more')}</Text>
            </Box>
          );
        }}
      >
        {(instance: InstanceProp) => renderRow(instance)}
      </InfiniteScroll>
    );
  };

  return (
    <>
      {saveConfigurationState && (
        <SaveTemplateConfigurationModal
          onCancel={onCancelSaveConfiguration}
          onSubmit={onSubmitSaveConfiguration}
          templateConfiguration={saveConfigurationState.templateConfiguration}
        />
      )}
      <FancyTable
        columns={[
          { width: '1/4' },
          undefined,
          { hidden: viewportSize === 'small' }, // Hide Launched by column on small screen
          { hidden: viewportSize === 'small' }, // Hide User column on small screen
          undefined,
          { width: '50px', justify: 'center' },
        ]}
        paper={true}
        rows={{ count: instances.length }}
        {...fancyTableProps}
      >
        <FancyTable.Toolbar>
          <FancyTable.Search
            onChange={onSearch}
            placeholder={t('search.placeholder')}
            value={searchTerm}
          />
          <Select
            closeOnChange={false}
            data-testid="workflow-runs-table__filter--select"
            dropHeight="medium"
            multiple={true}
            onChange={e => {
              onLaunchTypeFiltersChange(e.value.length ? e.value : undefined);
            }}
            options={[
              LaunchedByType.TenantMember,
              LaunchedByType.ScheduledJob,
              LaunchedByType.DataSetConfiguration,
            ]}
            value={selectedLaunchTypeFilters}
            valueLabel={renderFilterLabel()}
          >
            {option => (
              <Box align="center" direction="row" gap="small" pad="small">
                <CheckBox
                  checked={selectedLaunchTypeFilters?.includes(option)}
                  data-testid={`workflow-runs-table_${option}-checkbox`}
                />
                {getLaunchedByLabel(option)}
              </Box>
            )}
          </Select>
        </FancyTable.Toolbar>
        <FancyTable.Header>
          <FancyTable.Row>
            <FancyTable.HeaderCell>
              {t('headings.instance')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('headings.status')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('headings.launched-by')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>{t('headings.user')}</FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('headings.updated-on')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('headings.actions')}
            </FancyTable.HeaderCell>
          </FancyTable.Row>
        </FancyTable.Header>
        <FancyTable.Body>{renderBody()}</FancyTable.Body>
      </FancyTable>
    </>
  );
};
