import React, { useEffect, useMemo, useState } from 'react';
import { useDateFormat, useFuseState, useTenantContext } from '@common/hooks';
import { useTypedFlags } from '@features/launch-darkly';
import {
  TenantMemberRole,
  WorkspaceFilesBrowserFileDescriptorFragment,
  WorkspaceFilesBrowserFolderDescriptorFragment,
  useWorkspaceFilesBrowserQuery,
} from '@generated/graphql-code-generator';
import { useDebouncedValue, usePreviousUntil } from '@voleer/react-hooks';
import {
  UnreachableCaseError,
  ensureUnreachable,
  isNotNullOrUndefined,
} from '@voleer/types';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  Icon,
  Link,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import filesize from 'filesize';
import { Box, Text } from 'grommet';
import { identity } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaFile, FaFolder } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';
import { useLocation } from 'react-router';

const dataVoleerId = 'workspace-file-browser';

const PATH_PARAM = 'path';

export type FileSystemDescriptorFragment =
  | WorkspaceFilesBrowserFileDescriptorFragment
  | WorkspaceFilesBrowserFolderDescriptorFragment;

type WorkspaceFilesBrowserProps = Readonly<{
  /**
   * Function to handle deleting files.
   */
  onDeleteFile: (file: WorkspaceFilesBrowserFileDescriptorFragment) => void;

  /**
   * The ID of the workspace that this file browser is contained in.
   */
  workspaceId: string;
}>;

/**
 * Renders the file browser UI for workspace files.
 */
export const WorkspaceFilesBrowser: React.FC<WorkspaceFilesBrowserProps> = ({
  onDeleteFile,
  workspaceId,
}) => {
  const [t] = useTranslation('pages/WorkspacePage');

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  // Getting the user's tenant information to determine if they are an admin.
  // If the user is an admin, they can delete files.
  const { tenantMember } = useTenantContext();
  const isAdmin = tenantMember?.role === TenantMemberRole.Admin;

  const [format, { DateTimeFormat }] = useDateFormat();

  const [fileDownloaded, setFileDownloaded] = useState<boolean>(false);

  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const path = params.get(PATH_PARAM) || '';

  const { data, ...query } = useWorkspaceFilesBrowserQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfig?.workspaceFiles,
    variables: {
      workspaceId,
      path,
    },
  });

  // Only consider ourselves in loading state when data is also missing. This
  // way we avoid triggering loading state while the "network" part of
  // cache-and-network happens when we already have data loaded from cache.
  const loading = !data && query.loading;

  const fileDescriptor = data?.fileDescriptor;

  const folder = useMemo(() => {
    if (!fileDescriptor) {
      return undefined;
    }

    switch (fileDescriptor.__typename) {
      case 'FileDescriptor': {
        return fileDescriptor.parentFolder;
      }
      case 'FolderDescriptor': {
        return fileDescriptor;
      }
      default: {
        ensureUnreachable(fileDescriptor);
        return undefined;
      }
    }
  }, [fileDescriptor]);

  // Use previous items while data is missing in order to avoid UI jiggling by
  // re-rendering the list with no items while we're loading new data
  const [items] = usePreviousUntil(
    folder?.items?.items?.filter(isNotNullOrUndefined) ?? [],
    { condition: !!data }
  );

  // Avoid loading state flickering when loading takes less than 500ms
  const [debouncedLoading] = useDebouncedValue(loading, {
    delay: 500,

    // Don't debounce if loading is done
    shouldDebounce: identity,
  });

  const {
    setSearchTerm,
    searchResults: renderedItems,
    searchTerm,
  } = useFuseState({
    items,
    fuseOptions: {
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true,
      keys: ['label'],
    },
  });

  const urlForPath = (path: string) => {
    const newParams = new URLSearchParams(location.search);
    if (path) {
      newParams.set(PATH_PARAM, path);
    } else {
      newParams.delete(PATH_PARAM);
    }

    return `?${newParams.toString()}`;
  };

  useEffect(() => {
    if (fileDownloaded) {
      return;
    }

    if (!fileDescriptor) {
      return;
    }

    if (fileDescriptor.__typename !== 'FileDescriptor') {
      return;
    }

    // Use window.location.assign instead of window.location.href for testing
    // https://stackoverflow.com/questions/46169824/intercept-navigation-change-with-jest-js-or-how-to-override-and-restore-locatio
    // https://developer.mozilla.org/en-US/docs/Web/API/Location/assign
    window.location.assign(fileDescriptor.downloadUri);
    setFileDownloaded(true); // Prevent multiple files from being downloaded
  }, [fileDescriptor, fileDownloaded]);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box background="white" elevation="small" overflow="hidden" round="xsmall">
      <FancyTable
        columns={[
          undefined,
          { width: '15%' },
          { width: '20%' },
          { width: '100px', justify: 'end' },
        ]}
        loading={debouncedLoading}
        rows={{ count: items.length }}
      >
        <FancyTable.Toolbar gap="small">
          {!!folder?.parentFolder && (
            <Link
              data-testid="workspace-files-browser__parent-link"
              to={urlForPath(folder.parentFolder.fullPath)}
              variation="unstyled"
            >
              <Box
                align="center"
                border="right"
                direction="row"
                flex={{ grow: 0, shrink: 0 }}
                justify="center"
              >
                <Icon icon={FaArrowLeft} />
                <Text margin={{ horizontal: 'small' }} truncate={true}>
                  {folder.name}
                </Text>
              </Box>
            </Link>
          )}

          <FancyTable.Search
            onChange={onChangeSearch}
            placeholder={t('files-tab.search.placeholder')}
            value={searchTerm}
          />
        </FancyTable.Toolbar>

        <FancyTable.Header>
          <FancyTable.Row>
            <FancyTable.HeaderCell>
              {t('files-tab.header.name')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('files-tab.header.size')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('files-tab.header.last-modified')}
            </FancyTable.HeaderCell>
            <FancyTable.HeaderCell>
              {t('files-tab.header.actions')}
            </FancyTable.HeaderCell>
          </FancyTable.Row>
        </FancyTable.Header>

        <FancyTable.Body>
          {!renderedItems.length && (
            <FancyTable.Empty align="center">
              {searchTerm
                ? t('files-tab.empty.search')
                : t('files-tab.empty.items')}
            </FancyTable.Empty>
          )}

          {renderedItems.map(item => {
            switch (item.__typename) {
              case 'FolderDescriptor':
                return (
                  <FancyTable.Row
                    data-voleer-id="workspace-files-browser__row"
                    data-voleer-type="folder"
                    key={item.id}
                  >
                    <FancyTable.Cell data-voleer-id="workspace-files-browser__cell--name">
                      <Link to={urlForPath(item.fullPath)} variation="unstyled">
                        <Box align="center" direction="row" gap="small">
                          <Icon
                            color="accent-3"
                            icon={FaFolder}
                            size="medium"
                          />
                          <Box>
                            <Text truncate={true}>{item.name}</Text>
                          </Box>
                        </Box>
                      </Link>
                    </FancyTable.Cell>
                    <FancyTable.Cell>-</FancyTable.Cell>
                    <FancyTable.Cell />
                    <FancyTable.Cell />
                  </FancyTable.Row>
                );

              case 'FileDescriptor':
                return (
                  <FancyTable.Row
                    data-voleer-id="workspace-files-browser__row"
                    data-voleer-type="file"
                    key={item.id}
                  >
                    <FancyTable.Cell data-voleer-id="workspace-files-browser__cell--name">
                      <Box align="center" direction="row" gap="small">
                        <Icon color="accent-4" icon={FaFile} size="medium" />
                        <Box>
                          <Text
                            data-voleer-id={`${dataVoleerId}__file-name`}
                            truncate={true}
                          >
                            {item.name}
                          </Text>
                        </Box>
                      </Box>
                    </FancyTable.Cell>
                    <FancyTable.Cell data-voleer-id="workspace-files-browser__cell--file-size">
                      <Text
                        data-voleer-id={`${dataVoleerId}__file-size`}
                        truncate={true}
                      >
                        {filesize(item.size)}
                      </Text>
                    </FancyTable.Cell>
                    <FancyTable.Cell data-voleer-id="workspace-files-browser__cell--last-modified">
                      <Text
                        data-voleer-id={`${dataVoleerId}__last-modified`}
                        truncate={true}
                      >
                        {format(
                          parseISO(item.updatedOn),
                          DateTimeFormat.RelativeAbbreviated
                        )}
                      </Text>
                    </FancyTable.Cell>
                    <FancyTable.Cell data-voleer-id="workspace-files-browser__cell--actions">
                      <DropButton
                        data-testid="workspace-files-browser__actions"
                        data-voleer-id={`${dataVoleerId}__actions`}
                        icon={<Icon icon={MdMoreHoriz} />}
                        title={t('files-tab.actions.menu-label')}
                        variation="ghost"
                      >
                        <DropButtonItem
                          data-testid="workspace-files-browser__actions--download"
                          data-voleer-id={`${dataVoleerId}__actions--download`}
                          label={t('files-tab.actions.download')}
                          onClick={() => {
                            // Use window.location.assign instead of window.location.href for testing
                            // https://stackoverflow.com/questions/46169824/intercept-navigation-change-with-jest-js-or-how-to-override-and-restore-locatio
                            // https://developer.mozilla.org/en-US/docs/Web/API/Location/assign
                            window.location.assign(item.downloadUri);
                          }}
                        />
                        {isAdmin && (
                          <DropButtonItem
                            data-testid="workspace-files-browser__actions--delete"
                            label={t('files-tab.actions.delete')}
                            onClick={() => onDeleteFile(item)}
                          />
                        )}
                      </DropButton>
                    </FancyTable.Cell>
                  </FancyTable.Row>
                );

              default:
                throw new UnreachableCaseError(item);
            }
          })}
        </FancyTable.Body>
      </FancyTable>
    </Box>
  );
};
