import { useRef } from 'react';
import { constant } from 'lodash';

type UsePreviousUntilOptions<T> = {
  /**
   * Condition to check to see if the new value should replace the previous
   * one. If false, the previous value will continue being used.
   */
  condition: boolean | ((value: T) => boolean);
};

/**
 * Caches the previously provided value until condition is met.
 *
 * The first time this hook is called the value passed in will be set regardless
 * of whether it passes the condition, acting as a "default" or "initial" value
 * for the hook to use.
 *
 * ```javascript
 * const MyComponent = ({ value }) => {
 *   const current = usePreviousUntil(value, {
 *     condition: value => value === 'good value';
 *   });
 *
 *   // First render with value === "initial value"
 *   console.log(current); // "initial value", the initial value is set
 *
 *   // Second render with value === "bad value"
 *   console.log(current); // "initial value", did not pass condition
 *
 *   // Third render with value === "good value"
 *   console.log(current); // "good value", passed condition
 *
 *   // Fourth render with value === "another value"
 *   console.log(current); // "good value", did not pass condition
 * }
 * ```
 *
 * @param value the new value
 * @param options
 */
export const usePreviousUntil = <T>(
  value: T,
  { condition }: UsePreviousUntilOptions<T>
) => {
  const ref = useRef<T>(value);

  const conditionFn =
    typeof condition === 'function' ? condition : constant(condition);

  if (conditionFn(value) && ref.current !== value) {
    ref.current = value;
  }

  return [ref.current];
};
