import React from 'react';
import { PageContent } from '@common/components';
import { useTranslation } from 'react-i18next';
import { WorkflowInstanceView, useWorkflowInstanceView } from '..';

/**
 * Props for ConfigureWorkflow component
 */
export type ConfigureWorkflowProps = {
  workflowInstanceId?: string;
};

/**
 * Renders the ConfigureWorkflow component which allows a user to enter configuration details
 * and handles loading state and displaying errors for the WorkflowInstanceView.
 */
export const ConfigureWorkflow: React.FC<ConfigureWorkflowProps> = ({
  workflowInstanceId,
}) => {
  const [t] = useTranslation('components/ConfigureWorkflow');

  const {
    loading,
    error,
    props: workflowInstanceViewProps,
  } = useWorkflowInstanceView({
    workflowInstanceId,
  });

  return (
    <PageContent error={error} loading={loading} loadingLabel={t('loading')}>
      <WorkflowInstanceView {...workflowInstanceViewProps} />
    </PageContent>
  );
};
