import { useMemo } from 'react';
import { useOidc } from '@voleer/react-oidc-client';
import axios from 'axios';

/**
 * Hook to provide an authenticated Axios client.
 */
export const useAuthenticatedAxios = () => {
  const { user } = useOidc();

  return useMemo(() => {
    const client = axios.create();

    // Add the authentication token for each request
    client.interceptors.request.use(config => {
      config.headers = {
        ...config.headers,
        authorization: user ? `Bearer ${user.access_token}` : undefined,
      };
      return config;
    });

    return client;
  }, [user]);
};
