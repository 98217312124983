import React, { useState } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { ErrorOverlay, LoadingOverlay } from '@common/components';
import {
  NotificationChannelTypes,
  NotificationSubscriptionSectionDocument,
  NotificationSubscriptionStatus,
  useNotificationSubscriptionModalDeleteSubscriptionMutation,
  useNotificationSubscriptionModalEditSubscriptionMutation,
  useNotificationSubscriptionModalQuery,
} from '@generated/graphql-code-generator';
import { ErrorButton, LoadingButton, Modal } from '@voleer/ui-kit';
import { useFormikContext } from 'formik';
import { Button, Heading, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  NotificationSubscriptionForm,
  NotificationSubscriptionFormFields,
  NotificationSubscriptionFormValues,
  eventTypeSelectorDisplayNameMapping,
} from '../../components';

type NotificationSubscriptionModalProps = Readonly<{
  /**
   * The ID of the notification subscription to view and/or edit.
   */
  id: string;

  /**
   * Handler for closing the modal.
   */
  onClose: () => void;
}>;

type NotificationSubscriptionModalFooterProps = Readonly<{
  onClose: NotificationSubscriptionModalProps['onClose'];

  /**
   * Handler for a click on the 'Delete' button.
   */
  onDelete: () => void;
}>;

const NotificationSubscriptionModalFooter: React.FC<NotificationSubscriptionModalFooterProps> =
  ({ onClose, onDelete }) => {
    const [t] = useTranslation('pages/NotificationsPage');

    // Get access to the Formik React Context that's rendered by `NotificationSubscriptionForm`
    // https://formik.org/docs/api/useFormikContext#useformikcontext-formikpropsvalues
    const { dirty, isSubmitting, isValidating } = useFormikContext();
    const formProcessing = isSubmitting || isValidating;

    return (
      <Modal.Footer>
        <Button
          data-testid="notification-subscription-modal__button--delete"
          disabled={formProcessing}
          label={t(
            'settings-tab.notification-subscription-modal.buttons.delete'
          )}
          onClick={onDelete}
        />
        <Button
          data-testid="notification-subscription-modal__button--close"
          disabled={formProcessing}
          label={t(
            'settings-tab.notification-subscription-modal.buttons.cancel'
          )}
          onClick={onClose}
        />
        <LoadingButton
          data-testid="notification-subscription-modal__button--submit"
          disabled={formProcessing || !dirty}
          label={t(
            'settings-tab.notification-subscription-modal.buttons.submit'
          )}
          loading={formProcessing}
          primary={true}
          type="submit"
        />
      </Modal.Footer>
    );
  };

/**
 * Renders a Modal form for viewing and/or editing a notification subscription.
 */
export const NotificationSubscriptionModal: React.FC<NotificationSubscriptionModalProps> =
  ({ id, onClose }) => {
    const [t] = useTranslation('pages/NotificationsPage');

    const [showDeleteView, setShowDeleteView] = useState<boolean>(false);

    const {
      data,
      error: queryError,
      loading,
    } = useNotificationSubscriptionModalQuery({
      variables: {
        id,
      },
    });

    const notificationSubscription = data?.notificationSubscription;

    // Populate the `NotificationSubscriptionForm` field values
    const initialValues: NotificationSubscriptionFormValues = {
      deliverInApp:
        notificationSubscription?.channelTypes.includes(
          NotificationChannelTypes.VoleerUi
        ) || false,
      deliverViaEmail:
        notificationSubscription?.channelTypes.includes(
          NotificationChannelTypes.Email
        ) || false,
      runNotificationType: notificationSubscription?.runNotificationType,
      templateConfiguration:
        notificationSubscription?.templateConfiguration ?? undefined,
      workspace: notificationSubscription?.workspace ?? undefined,
    };

    const [editNotificationSubscription, { error: editError }] =
      useNotificationSubscriptionModalEditSubscriptionMutation({
        refetchQueries: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getOperationName(NotificationSubscriptionSectionDocument)!,
        ],
      });

    const [deleteNotificationSubscription] =
      useNotificationSubscriptionModalDeleteSubscriptionMutation({
        refetchQueries: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getOperationName(NotificationSubscriptionSectionDocument)!,
        ],
      });

    return (
      <Modal
        data-testid={
          showDeleteView
            ? 'notification-subscription-modal--delete'
            : 'notification-subscription-modal'
        }
        height={{ min: '540px' }}
        onClickOutside={onClose}
        onEsc={onClose}
        width={{ min: '550px' }}
      >
        {!showDeleteView && (
          <LoadingOverlay loading={!notificationSubscription && loading}>
            <ErrorOverlay error={queryError}>
              <Modal.Header margin={{ bottom: 'small' }}>
                {t('settings-tab.notification-subscription-modal.heading')}
              </Modal.Header>

              <NotificationSubscriptionForm
                error={editError}
                initialValues={initialValues}
                onSubmit={async (
                  values: NotificationSubscriptionFormValues
                ) => {
                  if (!values.runNotificationType) {
                    return;
                  }

                  const notificationChannels = [];
                  if (values.deliverInApp) {
                    notificationChannels.push(
                      NotificationChannelTypes.VoleerUi
                    );
                  }
                  if (values.deliverViaEmail) {
                    notificationChannels.push(NotificationChannelTypes.Email);
                  }

                  await editNotificationSubscription({
                    variables: {
                      input: {
                        id,
                        data: {
                          notificationChannels,
                          runNotificationType: values.runNotificationType,
                          status: NotificationSubscriptionStatus.Active,
                          templateConfigurationId:
                            values.templateConfiguration?.id,
                          workspace: values.workspace?.id
                            ? { id: values.workspace.id }
                            : null,
                        },
                      },
                    },
                  });

                  onClose();
                }}
              >
                <NotificationSubscriptionFormFields />
                <NotificationSubscriptionModalFooter
                  onClose={onClose}
                  onDelete={() => {
                    setShowDeleteView(true);
                  }}
                />
              </NotificationSubscriptionForm>
            </ErrorOverlay>
          </LoadingOverlay>
        )}

        {showDeleteView && (
          <>
            <Modal.Header margin={{ bottom: 'small' }}>
              {t(
                'settings-tab.notification-subscription-modal.delete-view.heading'
              )}
            </Modal.Header>

            <Modal.Body>
              <Paragraph>
                {t(
                  'settings-tab.notification-subscription-modal.delete-view.instructions'
                )}
              </Paragraph>

              {!notificationSubscription?.workspace &&
                notificationSubscription?.runNotificationType && (
                  <Heading level="4">
                    {`${t(
                      eventTypeSelectorDisplayNameMapping[
                        notificationSubscription.runNotificationType
                      ]
                    )}, ${t(
                      'settings-tab.notification-subscriptions.all-workspaces'
                    )}`}
                  </Heading>
                )}

              {!!notificationSubscription?.workspace &&
                !notificationSubscription?.templateConfiguration && (
                  <Heading level="4">
                    {notificationSubscription.workspace.displayName}
                  </Heading>
                )}

              {!!notificationSubscription?.workspace &&
                !!notificationSubscription?.templateConfiguration && (
                  <Heading level="4">
                    {`${notificationSubscription.workspace.displayName}, ${notificationSubscription.templateConfiguration.displayName}`}
                  </Heading>
                )}
            </Modal.Body>

            <Modal.Footer margin={{ top: 'auto' }}>
              <Button
                data-testid="notification-subscription-modal--delete__button--cancel"
                label={t(
                  'settings-tab.notification-subscription-modal.delete-view.buttons.cancel'
                )}
                onClick={() => {
                  setShowDeleteView(false);
                }}
              />
              <ErrorButton
                data-testid="notification-subscription-modal--delete__button--delete"
                label={t(
                  'settings-tab.notification-subscription-modal.delete-view.buttons.delete'
                )}
                onClick={async () => {
                  await deleteNotificationSubscription({
                    variables: {
                      input: {
                        id,
                      },
                    },
                  });

                  onClose();
                }}
              />
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  };
