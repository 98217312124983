import React, { useEffect, useMemo, useState } from 'react';
import { PageContent } from '@common/components';
import { useAccountStatus } from '@common/hooks';
import { useTypedFlags } from '@features/launch-darkly';
import {
  DataRequestStatus,
  InstanceStatus,
  useFormDataRequestDetailsCompleteDataRequestMutation,
  useFormDataRequestDetailsQuery,
} from '@generated/graphql-code-generator';
import { useFirst, usePrevious } from '@voleer/react-hooks';
import { isNotNullOrUndefined } from '@voleer/types';
import { Alert } from '@voleer/ui-kit';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaExclamation } from 'react-icons/fa';
import { RenderForm, RenderFormProps } from '../../forms';
import { isTerminalDataRequestStatus, isTerminalStepStatus } from '../../utils';
import { toDataRequestValues } from '../../utils/forms';
import { DownloadValidationLogs } from './DownloadValidationLogs';

export type FormDataRequestDetailsProps = {
  stepId: string;

  /**
   * Called when the DataRequest is successfully completed.
   */
  onCompleted?: () => void;
};

const FORM_WIDTH = '760px';

export enum CompleteDataRequestReturnCode {
  FailedToStartWorkflow = 'FailedToStartWorkflow',
  CouldNotFindDataRequest = 'CouldNotFindDataRequest',
  Unauthorized = 'Unauthorized',
}

/**
 * Displays the form data request details.
 */
export const FormDataRequestDetails: React.FC<FormDataRequestDetailsProps> = ({
  onCompleted,
  stepId,
}) => {
  const [t] = useTranslation(
    'features/workflows/components/FormDataRequestDetails'
  );

  const { data, ...query } = useFormDataRequestDetailsQuery({
    variables: { stepId },
  });

  const { disableWriteOperation } = useAccountStatus();

  const { 'launch-control': launchControl } = useTypedFlags();

  const loading = !data && query.loading;

  const step = data?.step;

  // Get the data request mutation
  const [completeDataRequest, { error: completeDataRequestError }] =
    useFormDataRequestDetailsCompleteDataRequestMutation();

  const instance = step?.instance;
  const workspaceId = instance?.workspaceId;
  const dataRequest = step?.dataRequest;

  // Track the status that the DataRequest was in when it was initially loaded
  const originalStatus = useFirst(dataRequest?.status, {
    // Only consider the first value where status was loaded/present
    condition: !!dataRequest?.status,
  });

  // Track the status that the DataRequest was in on the previous render
  const previousStatus = usePrevious(dataRequest?.status);

  // Call the onCompleted callback when the DataRequest enters Completed status
  useEffect(() => {
    const currentStatus = dataRequest?.status;

    // Don't call if the DataRequest already reached an end state in a prior render
    if (previousStatus && isTerminalDataRequestStatus(previousStatus)) {
      return;
    }

    // Don't call if the DataRequest was already in a terminal state
    if (originalStatus && isTerminalDataRequestStatus(originalStatus)) {
      return;
    }

    if (currentStatus === DataRequestStatus.Completed) {
      onCompleted?.();
    }
  }, [dataRequest?.status, onCompleted, originalStatus, previousStatus]);

  const validating = dataRequest?.status === DataRequestStatus.Validating;

  // Track whether the DataRequest was in validating state when it was first loaded
  const wasValidating = originalStatus === DataRequestStatus.Validating;

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const stepName = step?.displayName || step?.name;

  // Validation messages to display
  const validationMessages = useMemo(() => {
    // Ignore validation messages if currently validating or submitting, this
    // way we don't show validation messages until after the submission and
    // validation is done
    if (validating || submitting) {
      return undefined;
    }

    const messages =
      dataRequest?.dataValidation?.validationMessages?.items?.filter(
        isNotNullOrUndefined
      );

    // Show validation messages if the component originally rendered while
    // backend validations were processing
    if (wasValidating) {
      return messages;
    }

    // Avoid showing previous validation messages until the user submits
    if (!submitted) {
      return undefined;
    }

    return messages;
  }, [
    dataRequest?.dataValidation?.validationMessages?.items,
    submitted,
    submitting,
    validating,
    wasValidating,
  ]);

  // Compute submitLabel
  const submitLabel = useMemo(() => {
    if (validating) {
      return t('submitLabel.validating');
    }

    // Fall back to default label if not currently validating
    return undefined;
  }, [t, validating]);

  const onSubmit: RenderFormProps['onSubmit'] = values => {
    // Use an inner function so that we can pass a synchronous `onSubmit` to
    // Formik. By default Formik will automatically set `isSubmitting` to
    // `false` as soon as `onSubmit` resolves, so passing it a synchronous
    // callback prevents it from exiting submitting state too early while we are
    // still doing backend validations.
    //
    // See the note about async `onSubmit` and `isSubmitting`:
    // https://jaredpalmer.com/formik/docs/api/formik#onsubmit-values-values-formikbag-formikbag--void--promiseany
    const processSubmit = async () => {
      setSubmitted(true);
      setSubmitting(true);

      if (!dataRequest) {
        throw new Error('Missing dataRequest');
      }

      await completeDataRequest({
        awaitRefetchQueries: true,
        variables: {
          input: {
            dataRequestId: dataRequest.id,
            values: toDataRequestValues(values),
          },
        },
      });

      setSubmitting(false);
    };

    processSubmit();
  };

  const layout = (children?: React.ReactNode) => {
    return (
      <PageContent
        empty={!instance || !dataRequest || !instance || !workspaceId}
        error={query.error}
        loading={loading}
        loadingLabel={t('loading.label')}
      >
        {children}
      </PageContent>
    );
  };

  if (loading) {
    return layout();
  }

  if (!step || !dataRequest || !instance || !workspaceId) {
    return layout();
  }

  // Track whether or not the entire form should be disabled
  const disabled =
    (launchControl && disableWriteOperation) ||
    instance.status === InstanceStatus.Cancelling ||
    isTerminalStepStatus(step.status) ||
    dataRequest.status !== DataRequestStatus.Pending;

  return (
    <Box flex={{ shrink: 0 }}>
      <Box
        align="center"
        border="bottom"
        direction="row"
        fill="horizontal"
        flex={{ grow: 1 }}
        justify="between"
        pad="medium"
      >
        <Box>
          <Text
            data-voleer-id="workflow-instance__step-details--name"
            size="large"
            weight="bold"
          >
            {stepName}
          </Text>
          <Box direction="row">
            <Text color="dark-2" size="small">
              {t('form')}
            </Text>
          </Box>
        </Box>
        {dataRequest.dataValidation?.hasLogs && (
          <Box flex={false}>
            <DownloadValidationLogs
              dataValidationId={dataRequest.dataValidation?.id}
              workspaceId={workspaceId}
            />
          </Box>
        )}
      </Box>
      <Box
        align="center"
        alignSelf="center"
        margin={{ bottom: 'medium' }}
        overflow="auto"
        pad="small"
      >
        <Box pad="small" width={FORM_WIDTH}>
          {!!completeDataRequestError && (
            <Alert
              icon={FaExclamation}
              margin={{ bottom: 'medium' }}
              status="error"
            >
              {completeDataRequestError.message}
            </Alert>
          )}
          <RenderForm
            dataRequestId={dataRequest?.id}
            disabled={disabled}
            form={dataRequest.form}
            instanceId={instance?.id}
            onSubmit={onSubmit}
            submitLabel={submitLabel}
            submitting={submitting || validating}
            validationMessages={validationMessages}
            workspaceId={workspaceId}
          />
        </Box>
      </Box>
    </Box>
  );
};
