import React from 'react';
import { PropsOf } from '@voleer/types';
import { MaskedInput, MaskedInputProps } from 'grommet';

const TIME_MASK: MaskedInputProps['mask'] = [
  {
    length: [1, 2],
    regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
    placeholder: 'hh',
  },
  { fixed: ':' },
  {
    length: 2,
    regexp: /^[0-5][0-9]$|^[0-9]$/,
    placeholder: 'mm',
  },
  { fixed: ' ' },
  {
    length: 2,
    regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
    placeholder: 'ap',
  },
];

export type TimeInputProps = Omit<PropsOf<typeof MaskedInput>, 'mask'>;

/**
 * Renders a masked input field that only allows entering time values in
 * "hh:mm aa" format.
 */
export const TimeInput: React.FC<TimeInputProps> = props => {
  return <MaskedInput mask={TIME_MASK} {...(props as MaskedInputProps)} />;
};
