import { useEffect } from 'react';
import { User } from 'oidc-client';
import { redirectTo } from '../utils';
import { useOidc } from '.';

const getReturnUrl = (user: User): string | undefined => {
  const returnUrl: string = user && user.state && user.state.returnUrl;
  return typeof returnUrl === 'string' ? returnUrl : undefined;
};

/**
 * OIDC sign-in callback handler.
 */
export const useSignInCallback = () => {
  const { manager, config } = useOidc();

  useEffect(() => {
    manager
      .signinRedirectCallback()
      .then(user => {
        const returnUrlFromState = getReturnUrl(user);
        const defaultReturnUrl = config.defaultReturnUrl;

        const returnUrl =
          typeof returnUrlFromState === 'string'
            ? returnUrlFromState
            : defaultReturnUrl;

        redirectTo(returnUrl);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        redirectTo(config.defaultReturnUrl);
      });
  }, [manager, config.defaultReturnUrl]);
};
