import React from 'react';
import { ScheduledJobForm } from '@features/scheduled-jobs';
import { ScheduledJob } from '@generated/graphql-code-generator';
import { Modal, ModalProps } from '@voleer/ui-kit';
import { Box, Button } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Prop types for the `ChangeRefreshScheduleModal` component.
 */
export type ChangeRefreshScheduleModalProps = Readonly<
  Pick<ModalProps, 'open'> & {
    /**
     * Callback for closing the modal.
     */
    close: () => void;

    /**
     * The dataset ID.
     */
    dataSetId: string;

    onSubmit: (
      scheduledJob: Pick<ScheduledJob, 'pattern' | 'timezoneName'>
    ) => void;

    /**
     * The refresh schedule for the dataset.
     */
    schedule: Pick<
      ScheduledJob,
      'maxRecurrenceCount' | 'pattern' | 'timezoneName'
    >;
  }
>;

/**
 * Modal form for changing a dataset's refresh schedule
 */
export const ChangeRefreshScheduleModal: React.FC<ChangeRefreshScheduleModalProps> =
  ({ close, dataSetId, onSubmit, open, schedule }) => {
    const [t] = useTranslation('pages/DataSetPage');

    // Populate the `ScheduledJobForm`s form values w/ the refresh-schedule data.
    const initialFormValues = {
      maxRecurrenceCount: schedule.maxRecurrenceCount,
      pattern: schedule.pattern,
      templateConfiguration: {
        id: dataSetId,
      },
      timezoneName:
        schedule.timezoneName === 'UTC' ? 'Etc/UTC' : schedule.timezoneName,
    };

    return (
      <Modal onClickOutside={close} onEsc={close} open={open}>
        <Modal.Header>
          {t('change-refresh-schedule-modal.heading')}
        </Modal.Header>

        <Box fill={true} overflow="auto">
          <ScheduledJobForm onSubmit={onSubmit} value={initialFormValues}>
            <Modal.Body pad={{ top: 'medium' }}>
              <ScheduledJobForm.Fields>
                <ScheduledJobForm.TimezoneField />
                <ScheduledJobForm.ScheduleFields />
              </ScheduledJobForm.Fields>
            </Modal.Body>

            <Modal.Footer margin={{ top: 'large' }}>
              <Button
                data-testid="change-refresh-schedule-modal__btn--close"
                label={t('change-refresh-schedule-modal.buttons.cancel')}
                onClick={close}
              />
              <ScheduledJobForm.SubmitButton
                data-testid="change-refresh-schedule-modal__btn--submit"
                label={t('change-refresh-schedule-modal.buttons.submit')}
              />
            </Modal.Footer>
          </ScheduledJobForm>
        </Box>
      </Modal>
    );
  };
