import React from 'react';
import {
  NotificationChannelTypes,
  NotificationSubscriptionStatus,
  useNotificationSubscriptionSectionQuery,
  useNotificationSubscriptionSectionToggleSubscriptionStatusMutation,
} from '@generated/graphql-code-generator';
import { IconPlus } from '@voleer/icons';
import { Icon, useModalState } from '@voleer/ui-kit';
import { Box, CheckBox, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  AddNotificationSubscriptionModal,
  NotificationSubscriptionContainer,
  NotificationSubscriptionModal,
  eventTypeSelectorDisplayNameMapping,
} from '../../components';

const NotificationSubscription = styled(NotificationSubscriptionContainer)`
  &:hover,
  &:focus {
    .notification-subscription-section__name {
      text-decoration: underline;
    }
  }
`;

/**
 * Renders the section of the `NotificationsSettingsTab` component where the
 * user can view and manage notification subscriptions.
 */
export const NotificationSubscriptionSection: React.FC = () => {
  // https://react.i18next.com/guides/multiple-translation-files
  const [t] = useTranslation([
    'pages/NotificationsPage',
    'features/notifications/components/NotificationSubscriptionFormFields',
  ]);

  const { data, loading } = useNotificationSubscriptionSectionQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ids: null,
        statuses: null,
        types: null,
      },
    },
  });
  const [toggleSubscriptionStatus] =
    useNotificationSubscriptionSectionToggleSubscriptionStatusMutation();

  const [
    currentNotificationSubscriptionId,
    setCurrentNotificationSubscriptionId,
  ] = useQueryParam('notificationId', StringParam);

  const addNotificationSubscriptionModalState = useModalState();

  const channelMapping: Record<NotificationChannelTypes, string> = {
    [NotificationChannelTypes.Email]:
      'settings-tab.notification-subscriptions.channels.email',
    [NotificationChannelTypes.VoleerUi]:
      'settings-tab.notification-subscriptions.channels.in-app',
    [NotificationChannelTypes.None]: '',
  };

  const notificationSubscriptionMetaColor = 'dark-3';

  const notificationSubscriptions = data?.notificationSubscriptions;
  if (!notificationSubscriptions && loading) {
    return null;
  }

  return (
    <>
      <Box
        background="white"
        elevation="xsmall"
        gap="small"
        pad="medium"
        round="xsmall"
      >
        {notificationSubscriptions?.items?.map(subscription => {
          return (
            <NotificationSubscription
              data-testid="notification-subscription-section__subscription"
              direction="row"
              key={subscription.id}
              onClick={() => {
                setCurrentNotificationSubscriptionId(subscription.id);
              }}
              pad="small"
            >
              <Box margin={{ right: 'auto' }}>
                <Heading
                  className="notification-subscription-section__name"
                  data-testid="notification-subscription-section__subscription-meta--name"
                  level="4"
                  margin="0"
                >
                  {t(
                    eventTypeSelectorDisplayNameMapping[
                      subscription.runNotificationType
                    ]
                  )}
                </Heading>

                <Box direction="row">
                  {!subscription?.workspace && (
                    <Text
                      color={notificationSubscriptionMetaColor}
                      data-testid="notification-subscription-section__subscription-meta--all-workspaces"
                    >
                      {t(
                        'settings-tab.notification-subscriptions.all-workspaces'
                      )}
                    </Text>
                  )}

                  {!!subscription?.workspace &&
                    !subscription?.templateConfiguration && (
                      <Text
                        color={notificationSubscriptionMetaColor}
                        data-testid="notification-subscription-section__subscription-meta--workspace"
                      >
                        {subscription.workspace.displayName}
                      </Text>
                    )}

                  {!!subscription?.workspace &&
                    !!subscription?.templateConfiguration && (
                      <Text
                        color={notificationSubscriptionMetaColor}
                        data-testid="notification-subscription-section__subscription-meta--template-configuration"
                      >
                        {`${subscription.workspace.displayName}, ${subscription.templateConfiguration.displayName}`}
                      </Text>
                    )}

                  {!!subscription.channelTypes.length && (
                    <Box direction="row">
                      <Text
                        color={notificationSubscriptionMetaColor}
                        margin={{ horizontal: 'xsmall' }}
                      >
                        &#8226;
                      </Text>

                      <Text
                        color={notificationSubscriptionMetaColor}
                        data-testid="notification-subscription-section__subscription-meta--channel-types"
                      >
                        {subscription.channelTypes
                          .map(channel => t(channelMapping[channel]))
                          .join(', ')}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>

              <CheckBox
                checked={
                  subscription.status === NotificationSubscriptionStatus.Active
                }
                data-testid="notification-subscription-section__checkbox--status"
                onChange={async event => {
                  await toggleSubscriptionStatus({
                    variables: {
                      input: {
                        id: subscription.id,
                        data: {
                          // Toggle the notification subscription status
                          status: event.target.checked
                            ? NotificationSubscriptionStatus.Active
                            : NotificationSubscriptionStatus.Paused,
                        },
                      },
                    },
                  });
                }}
                onClick={event => {
                  // Prevent a click on this `CheckBox` from opening the `NotificationSubscriptionModal`
                  // https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation
                  event.stopPropagation();
                }}
                toggle={true}
              />
            </NotificationSubscription>
          );
        })}

        <NotificationSubscriptionContainer
          alignContent="center"
          data-testid="notification-subscription-section__subscription--add"
          direction="row"
          onClick={addNotificationSubscriptionModalState.open}
          pad={{ horizontal: 'small', vertical: 'medium' }}
        >
          <Text color="dark-3" margin={{ right: 'xsmall' }}>
            {t('settings-tab.notification-subscriptions.add-new')}
          </Text>
          <Icon icon={IconPlus} size="large" />
        </NotificationSubscriptionContainer>
      </Box>

      {addNotificationSubscriptionModalState.isOpen && (
        <AddNotificationSubscriptionModal
          onClose={addNotificationSubscriptionModalState.close}
        />
      )}

      {!!currentNotificationSubscriptionId && (
        <NotificationSubscriptionModal
          id={currentNotificationSubscriptionId}
          onClose={() => {
            setCurrentNotificationSubscriptionId('');
          }}
        />
      )}
    </>
  );
};
