import React from 'react';
import { urlFor } from '@common/utils';
import { WorkspaceFragment } from '@generated/graphql-code-generator';
import { Link } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { noop } from 'lodash';

type WorkspaceListItemProps = {
  id: WorkspaceFragment['id'];
  displayName: WorkspaceFragment['displayName'];
};

/**
 * Component for displaying a workspace list item on the Workspaces page.
 */
export const WorkspaceListItem: React.FC<WorkspaceListItemProps> = ({
  id,
  displayName,
}) => {
  return (
    <Link to={urlFor('workspace')({ workspaceId: id })} variation="unstyled">
      <Box
        height="50px"
        hoverIndicator="active"
        onClick={noop}
        pad={{ vertical: 'small', horizontal: 'medium' }}
      >
        {displayName}
      </Box>
    </Link>
  );
};
