/**
 * Parses an integer from a string and returns `undefined` (instead of `NaN`) if
 * the input is not a number.
 */
export const tryParseInt = (
  input?: number | string,
  radix = 10
): number | undefined => {
  if (typeof input !== 'string' && typeof input !== 'number') {
    return undefined;
  }

  const number = parseInt(`${input}`, radix);
  return isNaN(number) ? undefined : number;
};
