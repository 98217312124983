import React from 'react';
import { LoadingOverlay } from '@common/components';
import { isInstanceStatusCompleted } from '@features/workflows';
import { useIntegrationModalQuery } from '@generated/graphql-code-generator';
import { Modal } from '@voleer/ui-kit';
import { Box, Button, Heading, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import {
  ConfigureCustomWorkflowIntegration,
  IntegrationModalHeader,
} from './components';

export type IntegrationModalProps = {
  integrationId: string;
  onClose: () => void;
};

/**
 * Renders a modal to display details for an existing Integration.
 */
export const IntegrationModal: React.FC<IntegrationModalProps> = ({
  integrationId,
  onClose,
}) => {
  const [t] = useTranslation(
    'features/integrations/components/IntegrationModal'
  );

  const { data, ...query } = useIntegrationModalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      integrationId,
    },
  });

  const integration = data?.integration;

  const loading = !data && query.loading;

  const renderContent = () => {
    if (!integration) {
      return null;
    }

    if (integration.__typename === 'CustomWorkflowIntegration') {
      const instanceStatus = integration?.candidateInstance?.status;
      if (instanceStatus && !isInstanceStatusCompleted(instanceStatus)) {
        return (
          <ConfigureCustomWorkflowIntegration integrationId={integrationId} />
        );
      }
    }

    return (
      <Box align="center" fill={true} justify="center" pad="medium">
        <Heading level="2">{t('success.heading')}</Heading>
        <Paragraph>{t('success.body')}</Paragraph>
      </Box>
    );
  };

  return (
    <Modal
      onClickOutside={onClose}
      onEsc={onClose}
      position="right"
      width="xlarge"
    >
      <LoadingOverlay label={t('loading.label')} loading={loading}>
        <IntegrationModalHeader
          integrationId={integrationId}
          onClose={onClose}
        />
        <Modal.Body fill={true} overflow="auto">
          {renderContent()}
        </Modal.Body>
        <Modal.Footer pad="medium">
          <Button
            data-testid="integration-modal__buttons--close"
            label={t('buttons.close.label')}
            onClick={onClose}
          />
        </Modal.Footer>
      </LoadingOverlay>
    </Modal>
  );
};
