import { useState } from 'react';
import { MutationHookOptions } from '@apollo/client';
import {
  ScheduledJob,
  useCancelScheduledJobModalQuery,
  useCancelScheduledJobModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { CancelScheduledJobModalProps } from './CancelScheduledJobModal';

type ScheduledJobParam = Pick<ScheduledJob, 'id'>;

type UseCancelScheduledJobModalOptions = {
  /**
   * The ID of the Workspace where the scheduled job is being canceled.
   */
  workspaceId: string;

  /**
   * Queries to refetch after cancelling the scheduled job.
   */
  refetchQueries?: MutationHookOptions['refetchQueries'];
};

export type UseCancelScheduledJobModalResult = {
  /**
   * Opens the confirmation modal for the given scheduled job.
   */
  open: (scheduledJob: ScheduledJobParam) => void;

  /**
   * Closes the confirmation modal.
   */
  close: () => void;

  /**
   * Default props for passing to the modal component.
   */
  props: CancelScheduledJobModalProps;
};

/**
 * Hook which provides default data, state, and props for the cancel scheduled
 * job modal.
 *
 * ```typescript
 * const { open, props: modalProps } = useCancelScheduledJobModal({ workspaceId });
 *
 * return <div>
 *   <Button label="Cancel Scheduled Job" onClick={() => open(scheduledJob)} />
 *   <CancelScheduledJobModal {...modalProps} />
 * </div>
 * ```
 */
export const useCancelScheduledJobModal = ({
  workspaceId,
  refetchQueries,
}: UseCancelScheduledJobModalOptions): UseCancelScheduledJobModalResult => {
  const [scheduledJobId, setScheduledJobId] = useState<string | undefined>();

  const { data } = useCancelScheduledJobModalQuery({
    skip: !scheduledJobId,
    variables: {
      // Ignore potentially undefined scheduledJobId since the query will be
      // skipped if scheduledJobId is undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scheduledJobId: scheduledJobId!,
    },
  });

  const scheduledJob = data?.scheduledJob;

  const [cancelSchedule, cancelScheduleMutation] =
    useCancelScheduledJobModalSubmitMutation({
      refetchQueries,
      errorPolicy: 'all',
    });

  const onConfirm: CancelScheduledJobModalProps['onConfirm'] = async () => {
    if (!scheduledJobId) {
      return;
    }

    const result = await cancelSchedule({
      variables: {
        workspaceId,
        scheduledJobId,
      },
    });

    // Don't automatically close the modal if there were errors so that the user
    // can see the error message in the modal
    if (!result.errors?.length) {
      setScheduledJobId(undefined);
    }
  };

  const onCancel = () => setScheduledJobId(undefined);

  const open: UseCancelScheduledJobModalResult['open'] = scheduledJob => {
    setScheduledJobId(scheduledJob.id);
  };

  const close: UseCancelScheduledJobModalResult['close'] = () => {
    setScheduledJobId(undefined);
  };

  const error = cancelScheduleMutation.error;

  return {
    open,
    close,
    props: {
      scheduledJob,
      error,
      open: !!scheduledJobId,
      onCancel,
      onConfirm,
    },
  };
};
