import { ShapeValidator, ValidatableShape } from '.';

type FieldErrors = Record<string, string>;

/**
 * Converts a shape validator into a validator function suitable for validating
 * Formik forms.
 */
export const formikValidator =
  <TShape extends ValidatableShape>(validator: ShapeValidator<TShape>) =>
  async (shape: TShape): Promise<FieldErrors | void> => {
    const result = await validator(shape);

    const fieldErrors = Object.keys(result).reduce((acc, key) => {
      const value = result[key];
      if (typeof value === 'undefined') {
        return acc;
      }

      if (!Array.isArray(value)) {
        throw new Error(
          `Found nested shape at property \`${key}\`. Nested shapes are not supported for Formik validators.`
        );
      }

      // Add only the first error message since Formik expects one message per
      // field instead of multiple
      if (value.length) {
        acc[key] = value[0];
      }

      return acc;
    }, {} as FieldErrors);

    // Formik validators expect to have a string-keyed dictionary of field names
    // to error messages returned when validation fails and undefined returned
    // when validation succeeds
    if (Object.keys(fieldErrors).length) {
      return fieldErrors;
    }
  };
