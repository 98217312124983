import React from 'react';
import { PackageMarkdown } from '@common/components';
import { urlFor } from '@common/utils';
import { AddIntegrationModalQuery } from '@generated/graphql-code-generator';
import { Box, Button, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';

type QueryIntegrationMetadata = NonNullable<
  NonNullable<
    NonNullable<AddIntegrationModalQuery['integrationMetadatas']>['items']
  >[number]
>;

export type TrialExpiredIntegrationDetailsProps = {
  integrationMetadata: QueryIntegrationMetadata;
  onClose: () => void;
};

/**
 * Handles the trial expired state in the AddIntegrationModal.
 */
export const TrialExpiredIntegrationDetails: React.FC<TrialExpiredIntegrationDetailsProps> =
  ({ integrationMetadata, onClose }) => {
    const [t] = useTranslation(
      'features/integrations/components/TrialExpiredIntegrationModal'
    );
    return (
      <Box overflow={{ vertical: 'auto' }}>
        <Box
          data-testid="trial-expired-integration-details__contact-sales"
          flex={{ shrink: 0 }}
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Paragraph>{t('trial-expired.message')}</Paragraph>
          <Box
            direction="row"
            fill="horizontal"
            flex={{ shrink: 0 }}
            gap="small"
            justify="end"
          >
            <Button
              data-testid="trial-expired-integration-details__button--close"
              label={t('buttons.close.label')}
              onClick={onClose}
            />
            <Button
              data-testid="trial-expired-integration-details__button--contact-sales"
              href={urlFor('contactSales')()}
              label={t('buttons.contact-sales.label')}
              primary={true}
              target="_blank"
            />
          </Box>
        </Box>
        {!!integrationMetadata?.readme && (
          <Box border="top" pad="medium">
            <PackageMarkdown content={integrationMetadata.readme} />
          </Box>
        )}
      </Box>
    );
  };
