import React from 'react';
import {
  PackageVersionRow,
  ReadmeElement,
  ShareLibraryItemButton,
  WorkspaceSelector,
  useWorkspaceSelector,
} from '@common/components';
import { useAccountStatus, useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  useLibraryDashboardPageCreateMutation,
  useLibraryDashboardPageQuery,
} from '@generated/graphql-code-generator';
import { useModalState } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Button, Heading } from 'grommet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LibraryItemPageRouteParams } from '..';
import {
  DataSetSelector,
  LaunchButton,
  LibraryItemPageLayout,
  StickyContainer,
  useDataSetSelector,
} from '../components';
import { AddDashboardModal, AddDashboardModalProps } from './components';

type LibraryDashboardPageProps = Pick<
  LibraryItemPageRouteParams,
  'name' | 'packageName' | 'packageVersion' | 'publisherName'
>;

/**
 * Renders the page for a Dashboard item in the library.
 */
export const LibraryDashboardPage: React.FC<LibraryDashboardPageProps> = ({
  name,
  packageName,
  packageVersion,
  publisherName,
}) => {
  const [t] = useTranslation('pages/LibraryDashboardPage');

  const { 'tenant-ui-polling-configuration': pollingConfiguration } =
    useTypedFlags();

  const history = useHistory();

  const { tenantMember } = useTenantContext();

  const { error, data, ...query } = useLibraryDashboardPageQuery({
    pollInterval: pollingConfiguration?.publicLibraryList,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        names: [name],
        packageNames: [packageName],
        publisherNames: [publisherName],
        packageVersions:
          typeof packageVersion !== 'undefined' ? [packageVersion] : undefined,
      },
    },
  });

  const dashboardMetadata = data?.dashboardMetadatas?.items?.[0];
  const loading = !data && query.loading;

  const { workspace, props: workspaceSelectorProps } = useWorkspaceSelector();

  const { trialExpired } = useAccountStatus();

  const dataSchemaMetadata = dashboardMetadata?.dataSchemaMetadata;
  const dataSetMetadata = dataSchemaMetadata?.compatibleDataSetTemplate;

  const { dataSet, props: dataSetSelectorProps } = useDataSetSelector({
    dataSchemaMetadataId: dataSchemaMetadata?.id,
    compatibleDataSetMetadata: dataSetMetadata || undefined,
    workspace,
  });

  const [createDashboard] = useLibraryDashboardPageCreateMutation({
    onCompleted: data => {
      const createdDashboard = data.createDashboard;
      if (!createdDashboard) {
        return;
      }

      history.push(
        urlFor('dashboard')({
          dashboardId: createdDashboard.id,
          workspaceId: createdDashboard.workspaceId,
        })
      );
    },
  });

  const {
    props: addDashboardModalProps,
    open: openAddDashboardModal,
    close: closeAddDashboardModal,
  } = useModalState();

  const onSubmit: AddDashboardModalProps['onSubmit'] = async values => {
    if (
      !workspace?.id ||
      !tenantMember?.id ||
      !dashboardMetadata?.id ||
      !dataSet?.id
    ) {
      return;
    }

    await createDashboard({
      variables: {
        input: {
          displayName: values.displayName,
          description: values.description,
          workspaceId: workspace.id,
          createdById: tenantMember.id,
          dashboardMetadataId: dashboardMetadata.id,
          dataSetTemplateConfigurationId: dataSet.id,
        },
      },
    });
  };

  const layout = (children?: React.ReactNode) => {
    return (
      <LibraryItemPageLayout
        children={children}
        error={error}
        libraryItem={dashboardMetadata}
        loading={loading}
        subtitle={
          dashboardMetadata?.packageMetadata && (
            <PackageVersionRow
              color="dark-2"
              publishedOn={parseISO(
                dashboardMetadata.packageMetadata.publishedOn
              )}
              size="small"
              tenantDisplayName={
                dashboardMetadata.packageMetadata.publisher.displayName
              }
              tenantName={dashboardMetadata.packageMetadata.publisher.name}
              version={dashboardMetadata.packageMetadata.version}
            />
          )
        }
      />
    );
  };

  if (!dashboardMetadata) {
    return layout();
  }

  return layout(
    <Box align="center" fill="vertical" pad="medium">
      <Box
        background="white"
        elevation="small"
        flex={{ shrink: 0 }}
        margin={{ bottom: 'medium' }}
        pad="small"
        round="xsmall"
        width="100%"
      >
        <Box
          alignSelf="center"
          direction="row"
          gap="small"
          justify="between"
          pad="small"
          width="xxlarge"
        >
          <Box pad="small" width="50%">
            <ReadmeElement
              description={dashboardMetadata.description}
              readme={dashboardMetadata.readme}
            />
          </Box>

          <Box gap="small" pad="small" width="50%">
            <StickyContainer>
              <Box
                alignSelf="end"
                background="white"
                border={{ color: 'light-4' }}
                flex={{ shrink: 0 }}
                gap="small"
                pad="medium"
                round="xsmall"
                width="400px"
              >
                <Heading level="4" margin={{ bottom: 'small', top: 'none' }}>
                  {t('right-column.add-dashboard-section.heading')}
                </Heading>

                <WorkspaceSelector
                  data-testid="workspace-selector"
                  {...workspaceSelectorProps}
                  disabled={trialExpired}
                />

                <DataSetSelector
                  data-testid="dataset-selector"
                  {...dataSetSelectorProps}
                  disabled={trialExpired}
                />
                {trialExpired ? (
                  <Button
                    data-testid="library-dashboard-page__button--contact-sales"
                    href={urlFor('contactSales')()}
                    label={t(
                      'right-column.add-dashboard-section.contact-sales'
                    )}
                    primary={true}
                    target="_blank"
                  />
                ) : (
                  <LaunchButton
                    data-testid="launch-button"
                    disabled={!workspace || !dataSet}
                    label={t(
                      'right-column.add-dashboard-section.create-button'
                    )}
                    onClick={() => openAddDashboardModal()}
                  />
                )}

                <Box alignSelf="end">
                  <ShareLibraryItemButton
                    itemDisplayName={dashboardMetadata.displayName}
                    itemName={dashboardMetadata.name}
                    packageName={dashboardMetadata.packageMetadata.name}
                    publisherName={
                      dashboardMetadata.packageMetadata.publisher.name
                    }
                    type={dashboardMetadata.libraryItemType}
                  />
                </Box>
              </Box>
            </StickyContainer>
          </Box>
          {workspace?.id && (
            <AddDashboardModal
              {...addDashboardModalProps}
              data-testid="add-dashboard-modal"
              error={error}
              onCancel={closeAddDashboardModal}
              onSubmit={onSubmit}
              workspaceId={workspace.id}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
