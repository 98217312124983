import { useMemo } from 'react';
import {
  WorkflowInstanceViewQuery,
  useWorkflowInstanceViewQuery,
} from '@generated/graphql-code-generator';
import { compact } from 'lodash';
import { useLocation } from 'react-router';
import {
  isEndedInstanceStatus,
  shouldShowConfigurationSteps,
  sortSteps,
} from '../../utils';

export const CURRENT_STEP_PARAM = 'currentStepId';

export const getQueryParams = (params: URLSearchParams) => {
  const currentStepId = params.get(CURRENT_STEP_PARAM) || undefined;
  return { currentStepId };
};

type UseStepListOptions = {
  instanceId: string | undefined;
};

type QueryInstance = NonNullable<WorkflowInstanceViewQuery['instance']>;
type QuerySteps = NonNullable<NonNullable<QueryInstance>['steps']>['items'];
type QueryStep = NonNullable<QuerySteps>[number];

type UseStepListResult = {
  loading: boolean;
  selectedStep?: QueryStep;
  steps: QueryStep[];
};

/**
 * Hook to provide information about the steps in the WorkflowInstanceView
 * components.
 */
export const useStepList = ({
  instanceId,
}: UseStepListOptions): UseStepListResult => {
  const location = useLocation();

  const { data, ...query } = useWorkflowInstanceViewQuery({
    skip: !instanceId,
    variables: {
      // Query will be skipped if instanceId is missing
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      instanceId: instanceId!,
    },
  });

  const { currentStepId } = getQueryParams(
    new URLSearchParams(location.search)
  );

  const instance = data?.instance;
  const templateConfiguration = instance?.templateConfiguration;
  const configurationInstance = templateConfiguration?.configurationInstance;

  const steps = useMemo(() => {
    const exposureLevel = templateConfiguration?.exposureLevel;
    const instanceStepsItems = instance?.steps?.items;
    const configurationStepsItems = configurationInstance?.steps?.items;

    const instanceSteps = instanceStepsItems || [];

    // Hide configuration steps if needed
    const configurationSteps = shouldShowConfigurationSteps(exposureLevel)
      ? configurationStepsItems || []
      : [];

    // Include both configuration steps and instance steps in the steps list
    const items = compact([...configurationSteps, ...instanceSteps]);

    return sortSteps(items);
  }, [
    configurationInstance?.steps?.items,
    instance?.steps?.items,
    templateConfiguration?.exposureLevel,
  ]);

  const selectedStep = useMemo(() => {
    if (!instance) {
      return undefined;
    }

    if (currentStepId) {
      return steps.find(step => step?.id === currentStepId);
    }

    if (isEndedInstanceStatus(instance.status)) {
      return undefined;
    }

    return steps[0];
  }, [currentStepId, instance, steps]);

  const loading = !steps && query.loading;

  return {
    loading,
    selectedStep,
    steps,
  };
};
