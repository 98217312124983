import { useMemo } from 'react';
import { format as formatTimeZone, utcToZonedTime } from 'date-fns-tz';
import { memoize, sortBy, uniqBy } from 'lodash';
import { listTimeZones } from 'timezone-support';

/**
 * Type of an option for the `Select` dropdown in the `TimezoneField` component.
 */
export type TimezoneOption = {
  id: string;
  label: string;
};

type UseTimezoneOptionsResult = {
  timezoneOptions: TimezoneOption[];
  timezoneOptionsMap: Record<string, TimezoneOption>;
};

/**
 * Formats the list of formatted timezone options for the `Select`
 * dropdown in the `TimezoneField` component.
 */
const getTimezoneOptionsMap = memoize(
  () => {
    return listTimeZones().reduce((acc, ianaTimeZone) => {
      try {
        const zonedTime = utcToZonedTime(new Date(), ianaTimeZone);
        const timeZoneName = formatTimeZone(zonedTime, 'zzzz', {
          timeZone: ianaTimeZone,
        }); // Pacific Standard Time
        const timeZoneAbbreviation = formatTimeZone(zonedTime, 'zzz', {
          timeZone: ianaTimeZone,
        }); // PST
        const offset = formatTimeZone(zonedTime, 'XXX', {
          timeZone: ianaTimeZone,
        }); // -08:00
        const isianaTimeZoneUTC = ianaTimeZone === 'Etc/UTC';

        if (
          (offset !== 'Z' && !timeZoneName.startsWith('GMT')) ||
          isianaTimeZoneUTC
        ) {
          // Exclude GMT offset for UTC timezone label
          const labelOffset = isianaTimeZoneUTC ? '' : offset;
          // Exclude abbreviations that are repeats of the offsets, like GMT-7
          const labelAbbreviation = timeZoneAbbreviation.startsWith('GMT')
            ? ''
            : ` - ${timeZoneAbbreviation}`;

          acc[ianaTimeZone] = {
            id: ianaTimeZone,
            label: `(GMT${labelOffset}) ${timeZoneName}${labelAbbreviation}`, // (GMT-08:00) Pacific Standard Time - PST
          };
        }
      } catch (error) {
        // HACK: Swallow "RangeError: Invalid time zone specified" errors
        if (
          !(
            error instanceof RangeError &&
            error.message.includes('Invalid time zone')
          )
        ) {
          throw error;
        }
      }

      return acc;
    }, {} as Record<string, TimezoneOption>);
  },
  // Base the cache key on the current date so we
  // get new options if DST changes but avoid re-computing
  // the options multiple times within the same day
  () => formatTimeZone(new Date(), 'yyyy-MM-dd')
);

/**
 * Hook that provides the list of formatted timezone options for the `Select`
 * dropdown in the `TimezoneField` component.
 */
export const useTimezoneOptions = (): UseTimezoneOptionsResult => {
  const timezoneOptionsMap = useMemo(() => {
    return getTimezoneOptionsMap();
  }, []);

  const timezoneOptions = useMemo(() => {
    return sortBy(uniqBy(Object.values(timezoneOptionsMap), 'label'), 'label');
  }, [timezoneOptionsMap]);

  return {
    timezoneOptions,
    timezoneOptionsMap,
  };
};
