import React, { useContext, useMemo, useState } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useDateFormat, useLoadMoreQuery } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  NotificationRecipientStatus,
  NotificationsDropButtonDocument,
  NotificationsDropButtonNotificationRecipientFragment,
  NotificationsHistoryTabDocument,
  useNotificationsDropButtonDismissAllNotificationsMutation,
  useNotificationsDropButtonDismissNotificationMutation,
  useNotificationsDropButtonQuery,
} from '@generated/graphql-code-generator';
import { IconBell, IconX } from '@voleer/icons';
import { PropsOf } from '@voleer/types';
import {
  Anchor,
  DropButton,
  GhostButton,
  Link,
  VoleerTheme,
} from '@voleer/ui-kit';
import { formatDistance, parseISO } from 'date-fns';
import {
  Box,
  BoxProps,
  Heading,
  InfiniteScroll,
  Text,
  ThemeContext,
} from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from 'styled-components';

// Fix a bug where the DropButton badge does not sit
// on top of the bell icon.
const BellIcon = styled(IconBell)`
  margin: -24px;
`;

const NotificationContent = styled(Link)`
  &:hover,
  &:focus {
    .notifications-drop-button__notification-heading {
      text-decoration: underline;
    }
  }
`;

const LoadingSkeleton = () => {
  return (
    <Box margin={{ bottom: 'medium' }}>
      <Skeleton height="16px" width="20ch" />
      <Skeleton height="32px" width="30ch" />
    </Box>
  );
};

const NotificationSubjectText = styled(Text)`
  line-height: 16px;
`;

const BadgeContainerComponent: React.FC<BoxProps & { notifications?: number }> =
  props => <Box {...props} />;
const BadgeContainer = styled(BadgeContainerComponent)`
  // Use display property instead of conditionally rendering the entire Box
  // to prevent a bug where the Drop gets re-positioned when the list of
  // notifications becomes empty after dismissing a notification.
  ${props => (!props.notifications ? 'display: none;' : '')}

  &:focus {
    box-shadow: none;
  }
`;

/**
 * Renders a drop button for viewing and managing notifications.
 */
export const NotificationsDropButton: React.FC<PropsOf<typeof DropButton>> =
  props => {
    const [t] = useTranslation(['components/AppLayout']);

    const theme = useContext<VoleerTheme>(ThemeContext);

    const [open, setOpen] = useState(false);

    const [format, { DateTimeFormat }] = useDateFormat();

    const { 'tenant-ui-polling-configuration': pollingConfiguration } =
      useTypedFlags();
    const { data, fetchMore, loading } = useLoadMoreQuery(
      useNotificationsDropButtonQuery,
      {
        fetchPolicy: 'cache-and-network',
        getPageInfo: data => data?.notificationRecipients?.pageInfo,
        pollInterval: pollingConfiguration?.notificationsTray,
        variables: { first: 1000 },
      }
    );
    const notificationRecipients: NotificationsDropButtonNotificationRecipientFragment[] =
      useMemo(() => {
        return compact(data?.notificationRecipients?.edges).map(
          edge => edge.node
        );
      }, [data]);
    const totalNotificationRecipients =
      data?.notificationRecipients?.totalCount;
    const notificationRecipientsPageInfo =
      data?.notificationRecipients?.pageInfo;
    const loadMoreNotificationRecipients = useMemo(() => {
      if (!notificationRecipientsPageInfo?.hasNextPage) {
        return undefined;
      }

      return async () => {
        await fetchMore();
      };
    }, [fetchMore, notificationRecipientsPageInfo]);

    const [dismissNotification] =
      useNotificationsDropButtonDismissNotificationMutation({
        refetchQueries: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getOperationName(NotificationsDropButtonDocument)!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,
          getOperationName(NotificationsHistoryTabDocument)!,
        ],
      });
    const markNotificationAsRead = async (notificationRecipientId: string) => {
      await dismissNotification({
        variables: {
          input: {
            id: notificationRecipientId,
            data: {
              status: NotificationRecipientStatus.Read,
            },
          },
        },
      });
    };

    const [dismissAllNotifications] =
      useNotificationsDropButtonDismissAllNotificationsMutation({
        refetchQueries: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getOperationName(NotificationsDropButtonDocument)!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,
          getOperationName(NotificationsHistoryTabDocument)!,
        ],
      });

    const toggleOpen = () => {
      setOpen(!open);
    };

    const renderNotifications = () => {
      if (!data && loading) {
        return (
          <Box data-testid="notifications-drop-button__loading-skeleton">
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </Box>
        );
      }

      return (
        <>
          {!notificationRecipients.length && (
            <Heading
              data-testid="notifications-drop-button__no-notifications"
              level="4"
              margin={{ vertical: 'large' }}
              textAlign="center"
            >
              {t('notifications-tray.no-notifications')}
            </Heading>
          )}

          {!!notificationRecipients.length && (
            <Box
              height={{ max: '300px' }}
              margin={{ bottom: 'medium' }}
              overflow="auto"
            >
              <InfiniteScroll
                items={notificationRecipients}
                onMore={loadMoreNotificationRecipients}
                renderMarker={LoadingSkeleton}
              >
                {(
                  recipient: NotificationsDropButtonNotificationRecipientFragment
                ) => {
                  const instanceId = recipient?.notification?.instance?.id;
                  const instanceName =
                    recipient?.notification?.instance?.displayName;
                  const workspaceId = recipient?.notification?.workspace?.id;
                  const workspaceName =
                    recipient?.notification?.workspace?.displayName;
                  const disabled = !instanceId || !workspaceId;

                  return (
                    <Box
                      align="center"
                      direction="row"
                      flex={{ shrink: 0 }}
                      justify="between"
                      key={recipient.id}
                      margin={{ bottom: 'small' }}
                    >
                      <NotificationContent
                        data-testid="notifications-drop-button__notification-content"
                        disabled={disabled}
                        onClick={() => {
                          toggleOpen();
                          markNotificationAsRead(recipient.id);
                        }}
                        to={
                          disabled
                            ? ''
                            : urlFor('workflowInstance')({
                                workflowInstanceId: instanceId,
                                workspaceId,
                              })
                        }
                        variation="unstyled"
                      >
                        <Box>
                          <NotificationSubjectText
                            className="notifications-drop-button__notification-heading"
                            data-testid="notifications-drop-button__notification-heading"
                            weight="bold"
                          >
                            {recipient.notification.subject}
                          </NotificationSubjectText>

                          <Text
                            data-testid="notifications-drop-button__notification-triggered-by"
                            size="small"
                          >
                            {instanceName && workspaceName
                              ? `${instanceName}, ${workspaceName}`
                              : '-'}
                          </Text>

                          <Text
                            color="dark-3"
                            data-testid="notifications-drop-button__notification-time-triggered"
                            size="small"
                          >
                            {format(
                              parseISO(recipient.createdOn),
                              DateTimeFormat.AbbreviatedHumanized
                            )}
                            &nbsp; &#8226; &nbsp;
                            {formatDistance(
                              parseISO(recipient.createdOn),
                              new Date(),
                              { addSuffix: true }
                            )}
                          </Text>
                        </Box>
                      </NotificationContent>

                      <Box flex={{ shrink: 0 }} margin={{ vertical: 'auto' }}>
                        <GhostButton
                          data-testid="notifications-drop-button__dismiss-notification"
                          icon={<IconX size="32px" />}
                          onClick={event => {
                            // Prevent a click on this dismiss `Button` from causing a page refresh.
                            // https://developer.mozilla.org/en-US/docs/Web/API/Event/preventDefault
                            event.preventDefault();

                            // Prevent a click on this dismiss `Button` from navigating the user to the run instance.
                            // https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation
                            event.stopPropagation();

                            markNotificationAsRead(recipient.id);
                          }}
                        />
                      </Box>
                    </Box>
                  );
                }}
              </InfiniteScroll>
            </Box>
          )}
        </>
      );
    };

    return (
      <DropButton
        badge={
          <BadgeContainer
            align="center"
            background="light-2"
            data-testid="notifications-drop-button__badge"
            height="24px"
            justify="center"
            notifications={totalNotificationRecipients ?? 0}
            onClick={toggleOpen}
            round="full"
            width="24px"
          >
            <Box
              align="center"
              background="status-error"
              height="18px"
              justify="center"
              round="full"
              width="18px"
            >
              <Text color="white" size="12px">
                {!!totalNotificationRecipients &&
                totalNotificationRecipients > 9
                  ? '9+'
                  : totalNotificationRecipients}
              </Text>
            </Box>
          </BadgeContainer>
        }
        data-testid="notifications-drop-button"
        dropProps={{
          onClickOutside: toggleOpen,
          round: '8px',
        }}
        label={<BellIcon size="48px" />}
        onClick={toggleOpen}
        open={open}
        plain={true}
        {...props}
      >
        <Box
          pad={{ horizontal: 'medium', vertical: 'small' }}
          round="large"
          width="500px"
        >
          <Box direction="row">
            <Heading level="3" margin={{ right: 'auto' }}>
              {t('notifications-tray.heading')}
            </Heading>

            <Box align="center" direction="row" gap="small">
              <Anchor
                data-testid="notifications-drop-button__link--dismiss-all"
                onClick={async () => {
                  await dismissAllNotifications({
                    variables: {
                      input: {
                        status: NotificationRecipientStatus.Read,
                        updatedBefore: new Date().toUTCString(),
                      },
                    },
                  });
                }}
                variation="primary"
              >
                {t('notifications-tray.links.dismiss-all')}
              </Anchor>
              <Link
                data-testid="notifications-drop-button__link--settings"
                to={urlFor('notificationsSettings')()}
                variation="primary"
              >
                {t('notifications-tray.links.settings')}
              </Link>
            </Box>
          </Box>

          <SkeletonTheme
            color={normalizeColor('light-4', theme)}
            highlightColor={normalizeColor('light-3', theme)}
          >
            {renderNotifications()}
          </SkeletonTheme>

          <Box direction="row" justify="center">
            <Link
              data-testid="notifications-drop-button__link--history"
              to={urlFor('notificationsHistory')()}
              variation="primary"
            >
              {t('notifications-tray.links.history')}
            </Link>
          </Box>
        </Box>
      </DropButton>
    );
  };
