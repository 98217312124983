import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { AppLayout } from '@common/components';
import { useAccountStatus, useTenantContext } from '@common/hooks';
import {
  DeleteTemplateConfigurationModal,
  useDeleteTemplateConfigurationModal,
} from '@features/workflows';
import {
  ConfigurationData,
  TemplateConfiguration,
  TemplateConfigurationPageDocument,
  Workspace,
  useCancelCandidateTemplateConfigurationDataMutation,
  useLaunchTemplateConfigurationMutation,
  useResetTemplateConfigurationDataMutation,
} from '@generated/graphql-code-generator';
import { LoadingButton } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { SUMMARY_TAB_QUERY_PARAM_VALUE } from '../../TemplateConfigurationPage';

type TemplateConfigurationPageToolbarProps = Readonly<{
  templateConfiguration?:
    | (Pick<
        TemplateConfiguration,
        'createdOn' | 'description' | 'displayName' | 'id' | 'status'
      > & {
        // TODO(2411): Refactor TemplateConfigurationsQuery/Mutation and DataSetsQuery/Mutation to reduce code duplication
        candidateData?: Pick<ConfigurationData, 'id'> | null;
        data?: Pick<ConfigurationData, 'id'> | null;
      })
    | null;
  workspace?: Pick<Workspace, 'displayName' | 'id'> | null;
  workspaceId: string;
}>;

/**
 * Renders the page content toolbar for the `TemplateConfigurationPage`.
 */
export const TemplateConfigurationPageToolbar: React.FC<TemplateConfigurationPageToolbarProps> =
  ({ templateConfiguration, workspace, workspaceId }) => {
    const [t] = useTranslation('pages/TemplateConfigurationPage');

    const { tenantMember } = useTenantContext();
    const { disableWriteOperation } = useAccountStatus();

    const [
      cancelCandidateTemplateConfigurationData,
      cancelCandidateTemplateConfigurationMutation,
    ] = useCancelCandidateTemplateConfigurationDataMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(TemplateConfigurationPageDocument)!],
    });

    const {
      open: openDeleteTemplateConfigurationModal,
      props: deleteTemplateConfigurationModalProps,
    } = useDeleteTemplateConfigurationModal({
      workspaceId,
    });

    const [launchTemplateConfiguration, launchTemplateConfigurationMutation] =
      useLaunchTemplateConfigurationMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        refetchQueries: [getOperationName(TemplateConfigurationPageDocument)!],
      });

    const [
      resetTemplateConfigurationData,
      resetTemplateConfigurationDataMutation,
    ] = useResetTemplateConfigurationDataMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(TemplateConfigurationPageDocument)!],
    });

    const [tabParam, setTabParam] = useQueryParam('tab', StringParam);

    const hasConfiguration = !!templateConfiguration?.data;
    const isInEditMode = !!templateConfiguration?.candidateData;

    const onLaunchClick = async () => {
      if (!tenantMember || !workspace || !templateConfiguration) {
        return;
      }

      await launchTemplateConfiguration({
        variables: {
          workspaceId: workspace.id,
          templateConfigurationId: templateConfiguration.id,
          launchedById: tenantMember.id,
        },
      });

      if (tabParam !== SUMMARY_TAB_QUERY_PARAM_VALUE) {
        setTabParam(SUMMARY_TAB_QUERY_PARAM_VALUE);
      }
    };

    const onReconfigureClick = async () => {
      if (!tenantMember || !workspace || !templateConfiguration) {
        return;
      }

      await resetTemplateConfigurationData({
        variables: {
          workspaceId: workspace.id,
          templateConfigurationId: templateConfiguration.id,
          resetById: tenantMember.id,
        },
      });
    };

    const onCancelReconfigureClick = async () => {
      if (
        !workspace ||
        !templateConfiguration ||
        !templateConfiguration.candidateData
      ) {
        return;
      }

      await cancelCandidateTemplateConfigurationData({
        variables: {
          workspaceId: workspace.id,
          templateConfigurationId: templateConfiguration.id,
          candidateConfigurationDataId: templateConfiguration.candidateData.id,
        },
      });
    };

    const onDeleteClick = () => {
      if (!tenantMember || !workspace || !templateConfiguration) {
        return;
      }
      openDeleteTemplateConfigurationModal(templateConfiguration.id);
    };

    return (
      <AppLayout.Toolbar>
        {!isInEditMode && (
          <LoadingButton
            data-testid="template-configuration-page-toolbar__btn--launch"
            disabled={
              !tenantMember ||
              !workspace ||
              !templateConfiguration ||
              disableWriteOperation
            }
            label={t('buttons.launch.label')}
            loading={launchTemplateConfigurationMutation.loading}
            onClick={onLaunchClick}
            primary={true}
            size="small"
          />
        )}

        {hasConfiguration && !isInEditMode && (
          <LoadingButton
            data-testid="template-configuration-page-toolbar__btn--reconfigure"
            disabled={
              !tenantMember ||
              !workspace ||
              !templateConfiguration ||
              disableWriteOperation
            }
            label={t('buttons.reconfigure.label')}
            loading={resetTemplateConfigurationDataMutation.loading}
            onClick={onReconfigureClick}
            size="small"
          />
        )}

        {!isInEditMode && (
          <>
            <LoadingButton
              disabled={!tenantMember || !workspace || !templateConfiguration}
              label={t('buttons.delete.label')}
              loading={launchTemplateConfigurationMutation.loading}
              onClick={onDeleteClick}
              size="small"
            />
            <DeleteTemplateConfigurationModal
              {...deleteTemplateConfigurationModalProps}
            />
          </>
        )}

        {hasConfiguration && isInEditMode && (
          <LoadingButton
            label={t('buttons.cancel-reconfiguration.label')}
            loading={cancelCandidateTemplateConfigurationMutation.loading}
            onClick={onCancelReconfigureClick}
            size="small"
          />
        )}
      </AppLayout.Toolbar>
    );
  };
