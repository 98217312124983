import { useMemo, useState } from 'react';
import { useWorkspaceSelectorQuery } from '@generated/graphql-code-generator';
import { compact, sortBy } from 'lodash';
import { WorkspaceSelectorOption, WorkspaceSelectorProps } from './';

type UseWorkspaceSelectorOptions = {
  /**
   * Flag for pre-selecting a workspace if there is only one that can be selected.
   */
  preSelectOnlyWorkspace?: boolean;
};

type UseWorkspaceSelectorResult = {
  /**
   * Props to pass to the `WorkspaceSelector` component.
   */
  props: WorkspaceSelectorProps;

  /**
   * The selected workspace.
   */
  workspace?: WorkspaceSelectorOption;
};

/**
 * Hook which provides data for the `WorkspaceSelector` component.
 * ```typescript
 * const workspaceSelectorProps = useWorkspaceSelector()
 *
 * return <form>
 *   <WorkspaceSelector {...workspaceSelectorProps} />
 * </form>
 * ```
 */
export const useWorkspaceSelector = ({
  preSelectOnlyWorkspace = true,
}: UseWorkspaceSelectorOptions = {}): UseWorkspaceSelectorResult => {
  const [selectedWorkspace, setWorkspace] = useState<WorkspaceSelectorOption>();

  const { data: workspacesData, loading: workspacesLoading } =
    useWorkspaceSelectorQuery({
      fetchPolicy: 'cache-and-network',
    });

  const workspacesDataItems = workspacesData?.workspaces?.items;
  const workspaces = useMemo(() => {
    return sortBy(compact(workspacesDataItems), ws =>
      ws.displayName.toLocaleLowerCase()
    );
  }, [workspacesDataItems]);

  const workspace = useMemo(() => {
    // If a workspace has been selected, return it
    if (selectedWorkspace) {
      return selectedWorkspace;
    }

    // If there is only one possible workspace that could be selected, use that
    if (preSelectOnlyWorkspace && workspaces?.length === 1) {
      return workspaces[0];
    }

    return undefined;
  }, [preSelectOnlyWorkspace, selectedWorkspace, workspaces]);

  return {
    props: {
      loading: workspacesLoading,
      options: workspaces,
      setWorkspace,
      workspace,
    },
    workspace,
  };
};
