import React from 'react';
import { FormSelectFragment } from '@generated/graphql-code-generator';
import { useField, useFormikContext } from 'formik';
import { FormField, RadioButtonGroup } from 'grommet';
import { InitialValues } from '.';

type SelectFieldProps = {
  definition: FormSelectFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form radio button group.
 */
export const SelectRadioGroupField = React.forwardRef<
  HTMLDivElement,
  SelectFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta] = useField<string>(definition.name);

  const initialOptions = definition.options;

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  const options = initialOptions.map(option => {
    return {
      id: `${field.name}-${option.id}`,
      label: option.label,
      value: option.id,
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === field.value) {
      formik.setFieldValue(field.name, '');
    } else {
      formik.setFieldValue(field.name, event.target.value);
    }
  };

  return (
    <FormField error={error} label={definition.label}>
      <RadioButtonGroup
        {...field}
        disabled={disabled || formik.isSubmitting}
        onChange={handleChange}
        options={options}
        pad={{ top: 'small', bottom: 'small', left: 'small' }}
        placeholder={definition.placeholder ?? undefined}
        ref={ref}
        value={field.value || ''}
      />
    </FormField>
  );
});
