import { AxiosInstance } from 'axios';

const DEFAULT_NAME = 'validation-logs';

type SearchParamProps = {
  axiosInstance: AxiosInstance;
  workspaceId: string;
  validationId: string;
};

/**
 * Open the browser download dialog box to download the validaton logs of the given form submission.
 *
 * @param props.workspaceId Id of the workspace
 * @param props.validationId Id of the validation for the form
 */
export const downloadValidationLogs = async (props: SearchParamProps) => {
  const { axiosInstance, workspaceId, validationId } = props;
  const searchParams = new URLSearchParams({
    workspaceId,
    validationId,
  });
  const sanitizedName = DEFAULT_NAME;

  // Download the file
  const response = await axiosInstance.get(
    `/download/validationLogs?${searchParams}`
  );

  // Prompt the download browser window
  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${sanitizedName}.txt`);
  document.body.appendChild(link);
  link.click();
};
