import React from 'react';
import { TemplateConfigurationStatus } from '@generated/graphql-code-generator';
import { Badge, BadgeProps, BadgeStatus } from '@voleer/ui-kit';
import { useTranslation } from 'react-i18next';

const statusMapping: Record<TemplateConfigurationStatus, BadgeStatus> = {
  [TemplateConfigurationStatus.Ready]: BadgeStatus.ok,
  [TemplateConfigurationStatus.Pending]: BadgeStatus.info,
  [TemplateConfigurationStatus.Unknown]: BadgeStatus.unknown,
  [TemplateConfigurationStatus.Provisioning]: BadgeStatus.info,
  [TemplateConfigurationStatus.Invalid]: BadgeStatus.error,
  [TemplateConfigurationStatus.Deleting]: BadgeStatus.info,
  [TemplateConfigurationStatus.Deleted]: BadgeStatus.error,
};

const i18nMapping: Record<TemplateConfigurationStatus, string> = {
  [TemplateConfigurationStatus.Ready]: 'status-ready',
  [TemplateConfigurationStatus.Pending]: 'status-pending',
  [TemplateConfigurationStatus.Unknown]: 'status-unknown',
  [TemplateConfigurationStatus.Provisioning]: 'status-provisioning',
  [TemplateConfigurationStatus.Invalid]: 'status-invalid',
  [TemplateConfigurationStatus.Deleting]: 'status-deleting',
  [TemplateConfigurationStatus.Deleted]: 'status-deleted',
};

type TemplateConfigurationStatusBadgeProps = Omit<
  BadgeProps,
  'label' | 'status'
> & {
  status: TemplateConfigurationStatus;
};

/**
 * Renders a badge to display the given Template Configuration status.
 */
export const TemplateConfigurationStatusBadge: React.FC<TemplateConfigurationStatusBadgeProps> =
  ({ status, ...props }) => {
    const [t] = useTranslation(
      'features/workflows/components/TemplateConfigurationStatusBadge'
    );
    const label = t(i18nMapping[status]);
    return <Badge {...props} label={label} status={statusMapping[status]} />;
  };
