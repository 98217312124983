import { useEffect, useRef } from 'react';

/**
 * Provides the value from a previous render.
 *
 * On the first render the value will be undefined since there was no previous
 * value.
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
