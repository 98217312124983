import React, { Suspense, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import {
  AppLoading,
  PingPolling,
  RedirectRoute,
  TenantContextProvider,
} from '@common/components';
import { useAccountStatus } from '@common/hooks';
import { getUserManager } from '@common/utils';
import { ChargeBeeInstanceContextProvider } from '@features/chargebee';
import {
  LaunchDarklyUserIdentifier,
  useTypedFlags,
} from '@features/launch-darkly';
import { PendoInitialization } from '@features/pendo-initialization';
import { AccountStatus } from '@generated/graphql-code-generator';
import { Marketo } from '@voleer/marketo';
import { Authenticated, OidcProvider } from '@voleer/react-oidc-client';
import { VoleerGlobalStyles, voleer2 } from '@voleer/ui-kit';
import { Grommet } from 'grommet';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import {
  Route,
  RouteComponentProps,
  Router,
  Switch,
  withRouter,
} from 'react-router';
import { QueryParamProvider } from 'use-query-params';
import { getAppConfig } from './app-config';
import { getApolloClient } from './graphql/client';
import {
  DashboardPage,
  DataSetPage,
  DeactivatedPage,
  Error404Page,
  HomePage,
  IntegrationsPage,
  LibraryItemByIdPage,
  LibraryItemPage,
  LibraryPage,
  NotificationsPage,
  ScheduledJobPage,
  SignInCallbackPage,
  TemplateConfigurationPage,
  TenantSettingsPage,
  UserSettingsPage,
  WorkflowInstancePage,
  WorkspacePage,
  WorkspacesPage,
} from './pages';

type AppProps = RouteComponentProps;

/**
 * Custom provider component to augment behavior of ApolloProvider.
 */
export const ApolloProviders: React.FC = ({ children }) => {
  const client = getApolloClient();
  return <ApolloProvider children={children} client={client} />;
};

const userManager = getUserManager();

const Routes: React.FC = () => {
  const { accountStatus } = useAccountStatus();
  const { notifications } = useTypedFlags();

  // Do not allow the user to access the app if their tenant is deactivated or paused
  if (
    accountStatus === AccountStatus.Deactivated ||
    accountStatus === AccountStatus.Paused
  ) {
    return (
      <Switch>
        <Route component={DeactivatedPage} />;
      </Switch>
    );
  }

  return (
    <Switch>
      <Route component={HomePage} exact={true} path="/" />
      <Route component={LibraryPage} exact={true} path="/library" />
      <Route
        component={LibraryItemByIdPage}
        exact={true}
        path="/library/:itemId"
      />
      <Route
        component={LibraryItemPage}
        exact={true}
        path={[
          '/library/:publisherName/:packageName/:name',
          '/library/:type/:publisherName/:packageName/:name',
          '/library/:type/:publisherName/:packageName/:name/v/:packageVersion',
        ]}
      />
      {notifications && (
        <Route
          component={NotificationsPage}
          exact={true}
          path="/notifications"
        />
      )}
      <Route component={UserSettingsPage} exact={true} path="/user-settings" />
      <Route
        component={TenantSettingsPage}
        exact={true}
        path="/tenant-settings"
      />
      <Route component={WorkspacesPage} exact={true} path="/workspaces" />
      <Route
        component={WorkspacePage}
        exact={true}
        path="/workspaces/:workspaceId"
      />
      <Route
        component={DataSetPage}
        exact={true}
        path="/workspaces/:workspaceId/datasets/:dataSetId"
      />
      <Route
        component={TemplateConfigurationPage}
        path="/workspaces/:workspaceId/templateConfigurations/:templateConfigurationId"
      />
      <Route
        component={WorkflowInstancePage}
        path="/workspaces/:workspaceId/runs/:workflowInstanceId"
      />
      <Route
        component={ScheduledJobPage}
        path="/workspaces/:workspaceId/scheduledJobs/:scheduledJobId"
      />
      <Route component={IntegrationsPage} exact={true} path="/integrations" />
      <Route
        component={DashboardPage}
        path="/workspaces/:workspaceId/dashboards/:dashboardId"
      />
      {/* Redirect all "/public-library" routes to "/library" routes URL segment to preserve
          backwards compatablity with previous vertions.
      */}
      <RedirectRoute path="/public-library" to="/library" />
      {/* Redirect "user-settings/notifications" to "/user-settings?tab=notifications" URL segment
          to proof any changes, if the "notifications tab" eventually becomes its own page.

          TODO (4454): Remove this redirect when the package markdown dependency is updated.
          https://dev.azure.com/bittitan/Voleer/_workitems/edit/4454
      */}
      <RedirectRoute
        path="/user-settings/notifications"
        to="/user-settings?tab=notifications"
      />
      {/* Redirect "/user-settings?tab=notifications" to "/notifications", in case users
          bookmarked the old notifications area.

          TODO (4454): Remove this redirect when the package markdown dependency is updated.
          https://dev.azure.com/bittitan/Voleer/_workitems/edit/4454
      */}
      {notifications && (
        <RedirectRoute
          path="/user-settings?tab=notifications"
          to="/notifications"
        />
      )}
      <Route component={Error404Page} />
    </Switch>
  );
};

const AppComponent: React.FC<AppProps> = ({ history }) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, [setIsClient]);

  // Server-side rendering loads initial HTML, JS, and CSS. Until those resources
  // are loaded in, this React app renders a loading spinner. This prevents us from
  // having to implement GraphQL queries in SSR mode.
  if (isClient) {
    return (
      <OidcProvider manager={userManager}>
        <ApolloProviders>
          <Grommet style={{ height: '100%', overflow: 'auto' }} theme={voleer2}>
            <VoleerGlobalStyles />
            <Marketo />
            <Suspense fallback={<AppLoading />}>
              <Router history={history}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Switch>
                    <Route
                      component={SignInCallbackPage}
                      exact={true}
                      path="/signin-callback.html"
                    />
                    <Authenticated unauthenticated={<AppLoading />}>
                      <TenantContextProvider>
                        <ChargeBeeInstanceContextProvider>
                          <PingPolling />
                          <LaunchDarklyUserIdentifier />
                          <PendoInitialization />
                          <Routes />
                        </ChargeBeeInstanceContextProvider>
                      </TenantContextProvider>
                    </Authenticated>
                  </Switch>
                </QueryParamProvider>
              </Router>
            </Suspense>
          </Grommet>
        </ApolloProviders>
      </OidcProvider>
    );
  }

  return (
    <Grommet style={{ height: '100%', overflow: 'auto' }} theme={voleer2}>
      <VoleerGlobalStyles />
      <AppLoading />
    </Grommet>
  );
};

export const App = withLDProvider({
  clientSideID: getAppConfig().LAUNCH_DARKLY_CLIENT_SIDE_ID,
  reactOptions: {
    // Setting useCamelCaseFlagKeys to true will break Code References
    // See: https://docs.launchdarkly.com/integrations/git-code-references#using-ld-find-code-refs-with-the-react-sdk
    useCamelCaseFlagKeys: false,
  },
  options: {
    // Keep all attributes private to avoid saving personally identifiable
    // information in LaunchDarkly
    allAttributesPrivate: true,
  },
})(withRouter(AppComponent));

export default App;
