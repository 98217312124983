import React, { useState } from 'react';
import {
  FormEmailInputFragment,
  FormNumericInputFragment,
  FormTextInputFragment,
  FormUrlInputFragment,
} from '@generated/graphql-code-generator';
import { Anchor } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import { Box, FormField, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import { isMaskableField } from '../utils';
import { InitialValues, LabelMarkdown } from '.';

type TextInputFieldProps = {
  definition:
    | FormEmailInputFragment
    | FormNumericInputFragment
    | FormTextInputFragment
    | FormUrlInputFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form text input field.
 */
export const TextInputField = React.forwardRef<
  HTMLInputElement,
  TextInputFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta] = useField<string>(definition.name);
  const [t] = useTranslation('features/workflows/components/TextInputField');
  const [unmasked, setUnmasked] = useState(false);
  const toggleMask = () => setUnmasked(!unmasked);

  // Use password input if the input should be masked, otherwise fall back to
  // the default input type by making it undefined
  const inputType = () => {
    if (isMaskableField(definition)) {
      return definition.mask && !unmasked ? 'password' : undefined;
    }
    return undefined;
  };

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  // Renders the form field label
  const renderLabel = () => {
    // For unmasked inputs, just return the label text
    if (!isMaskableField(definition) || definition.mask === false) {
      return <LabelMarkdown content={definition.label} />;
    }

    // Render a label with show/hide buttons for masked inputs
    return (
      <Box direction="row" justify="between">
        <div>
          <LabelMarkdown content={definition.label} />
        </div>
        {!disabled && (
          <Anchor onClick={toggleMask} variation="primary">
            {unmasked ? t('masked-text-hide') : t('masked-text-show')}
          </Anchor>
        )}
      </Box>
    );
  };

  return (
    <FormField error={error} label={renderLabel()}>
      <TextInput
        {...field}
        // autoComplete is used in this case to prevent browsers from filling
        // workflow forms. "new-password" is used when the input is masked.
        // "off" should work for every other case.
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
        autoComplete={inputType() === 'password' ? 'new-password' : 'off'}
        disabled={disabled || formik.isSubmitting}
        placeholder={definition.placeholder || undefined}
        ref={ref}
        type={inputType()}
        value={field.value || ''}
      />
    </FormField>
  );
});
