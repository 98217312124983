import React from 'react';
import { useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { TenantMemberRole } from '@generated/graphql-code-generator';
import { useOidc } from '@voleer/react-oidc-client';
import { Anchor } from '@voleer/ui-kit';
import { Box, Button, Heading, Image, Paragraph, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import VEE_IMG_SRC from './images/vee.svg';
import VOLEER_LOGO_IMG_SRC from './images/voleer-logo.svg';

const VEE_DIMENSIONS = '250px';
const VOLEER_LOGO_HEIGHT = '30px';

/**
 * Renders the inner container of the `Navbar` component.
 */
const NavbarInner = styled(Box)`
  ${props => {
    return `
    // Increase height on small screens
    @media screen and (max-width: ${props.theme.global.breakpoints.xsmall.value}px) {
      height: 50px;
    }
      
    height: 39px; // Ensures consistent sizing w/ marketing site
    max-width: ${props.theme.global.breakpoints.medium.value}px;
    width: 100%;
    `;
  }}
`;

/**
 * Renders the navigation bar at the top of the page.
 */
const Navbar: React.FC = () => {
  const [t] = useTranslation('pages/DeactivatedPage');

  const { manager } = useOidc();
  const signOut = async () => await manager.signoutRedirect();

  return (
    <Box
      align="center"
      background="white"
      margin={{ bottom: 'large' }}
      pad={{ horizontal: '4vw', vertical: '1.2vw' }}
      width="100vw"
    >
      <NavbarInner align="center" direction="row">
        <Image
          alt={t('navbar.voleer-img-alt-text')}
          height={VOLEER_LOGO_HEIGHT}
          margin={{ right: 'auto' }}
          src={VOLEER_LOGO_IMG_SRC}
        />

        <Button onClick={signOut} plain={true}>
          <Text color="brand" size="xlarge">
            {t('navbar.sign-out')}
          </Text>
        </Button>
      </NavbarInner>
    </Box>
  );
};

const InfoText = styled(Paragraph)`
  max-width: 500px;
`;

/**
 * Renders a page for when a tenant's subscription has been deactivated.
 */
export const DeactivatedPage: React.FC = () => {
  const [t] = useTranslation('pages/DeactivatedPage');

  const { tenantMember } = useTenantContext();
  const isAdmin = tenantMember?.role === TenantMemberRole.Admin;

  return (
    <>
      <Navbar />
      <Box align="center" justify="center" margin={{ top: 'xlarge' }}>
        <Image
          alt={t('vee-img-alt-text')}
          height={VEE_DIMENSIONS}
          src={VEE_IMG_SRC}
          width={VEE_DIMENSIONS}
        />

        <Heading
          level="3"
          margin={{ bottom: 'small', top: 'large' }}
          textAlign="center"
        >
          {t('heading')}
        </Heading>
        <InfoText
          data-testid="deactivated-page__info-text"
          margin={{ bottom: 'medium' }}
          textAlign="center"
        >
          {t(isAdmin ? 'info-text.admin' : 'info-text.non-admin')}
        </InfoText>

        {isAdmin && (
          <Anchor
            data-testid="deactivated-page__contact-sales-btn"
            href={urlFor('contactSales')()}
            rel="noopener noreferrer"
            target="_blank"
            variation="primary"
          >
            <Button
              label={<Text weight="bold">{t('contact-sales')}</Text>}
              primary={true}
            />
          </Anchor>
        )}
      </Box>
    </>
  );
};
