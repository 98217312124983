import React, { useMemo, useState } from 'react';
import { useTypedFlags } from '@features/launch-darkly';
import {
  FormIntegrationFragment,
  useIntegrationFieldOptionsQuery,
} from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { IconPlus } from '@voleer/icons';
import { Icon, castRef } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import { Box, FormField, Select, Text } from 'grommet';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AddIntegrationModal, IntegrationIcon } from '../../../integrations';
import { InitialValues, LabelMarkdown } from '.';

const CONFIGURE_NEW_INTEGRATION_OPTION = 'configure-new-integration-option';

type IntegrationFieldProps = {
  definition: FormIntegrationFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form integration field.
 */
export const IntegrationField = React.forwardRef<
  HTMLElement,
  IntegrationFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const { 'tenant-ui-polling-configuration': pollingConfiguration } =
    useTypedFlags();
  const [t] = useTranslation('features/workflows/components/IntegrationField');
  const [field, fieldMeta, fieldHelpers] = useField<string>(definition.name);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const integrationReferences = useMemo(() => {
    if (!definition.integrationReferences) {
      return undefined;
    }

    return definition.integrationReferences.map(integrationReference =>
      omit(integrationReference, '__typename')
    );
  }, [definition.integrationReferences]);

  // Poll for the available options in case the option from the
  // initialOptions has been deleted after the form has completed the initial rendering
  const { data, ...query } = useIntegrationFieldOptionsQuery({
    skip: !integrationReferences,
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfiguration?.integrationFormComponent,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      integrationReferences: integrationReferences!,
    },
  });

  const [addIntegrationMetadataIds, setAddIntegrationMetadataIds] = useState<
    string[] | undefined
  >();

  const onChange = (event: GrommetSelectEvent<typeof options[number]>) => {
    if (!event.value || event.value.id === CONFIGURE_NEW_INTEGRATION_OPTION) {
      event.preventDefault();
      return;
    }

    fieldHelpers.setValue(event.value.id);
  };

  const onSearch = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const resetSearchTerm = () => {
    setSearchTerm('');
  };

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  const initialOptions = useMemo(() => {
    const items = data?.integrations?.items;
    if (!items) {
      return [];
    }

    return items.map(integration => {
      return {
        ...integration,
        label: integration.displayName,
      };
    });
  }, [data?.integrations?.items]);

  const selectedOption = initialOptions.find(
    option => option?.id === field.value
  );

  const options = useMemo(() => {
    const newIntegrationOption = {
      id: CONFIGURE_NEW_INTEGRATION_OPTION,
      label: t('new-integration-option.label'),
    };

    if (!searchTerm) {
      return [newIntegrationOption, ...initialOptions];
    }
    return [
      newIntegrationOption,
      ...initialOptions.filter(
        option =>
          option?.label &&
          option?.label
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
      ),
    ];
  }, [searchTerm, initialOptions, t]);

  return (
    <>
      <FormField
        error={error}
        label={<LabelMarkdown content={definition.label} />}
      >
        <Select
          {...field}
          clear={
            definition.required ? false : { position: 'top', label: t('clear') }
          }
          disabled={disabled || formik.isSubmitting}
          dropHeight="medium"
          dropProps={{
            stretch: 'align',
          }}
          labelKey="label"
          onChange={onChange}
          onClose={resetSearchTerm}
          onSearch={onSearch}
          options={options}
          placeholder={t('placeholder')}
          ref={castRef(ref)}
          value={
            selectedOption ?? {
              id: 'empty-option',
              label: '',
            }
          }
          valueKey="id"
        >
          {option => (
            <Box align="start" width="xxlarge">
              {option.label === t('new-integration-option.label') ? (
                <Box
                  align="center"
                  direction="row"
                  gap="small"
                  onClick={() => {
                    setAddIntegrationMetadataIds(
                      data?.matchingIntegrationMetadatas.map(m => m.id)
                    );
                  }}
                  pad="small"
                  width="100%"
                >
                  <Box flex={{ shrink: 0 }}>
                    <Icon icon={IconPlus} size="36px" />
                  </Box>
                  <Text title={option.label}>{option.label}</Text>
                </Box>
              ) : (
                <Box
                  align="center"
                  background={
                    selectedOption?.id === option.id ? 'neutral-1' : ''
                  }
                  direction="row"
                  gap="small"
                  pad="small"
                  round="xxsmall"
                  width="100%"
                >
                  <Box flex={{ shrink: 0 }}>
                    <IntegrationIcon
                      size="36px"
                      type={option.integrationMetadata.type}
                    />
                  </Box>
                  <Box direction="column">
                    <Text
                      size="large"
                      title={option.displayName}
                      truncate={true}
                      weight="bold"
                    >
                      {option.displayName}
                    </Text>
                    <Text
                      title={option.description ?? undefined}
                      truncate={true}
                    >
                      {option.description}
                    </Text>
                    <Text
                      title={option.integrationMetadata.displayName}
                      truncate={true}
                    >
                      {option.integrationMetadata.displayName}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Select>
      </FormField>

      {!!addIntegrationMetadataIds && (
        <AddIntegrationModal
          integrationMetadataIds={addIntegrationMetadataIds}
          onClose={() => setAddIntegrationMetadataIds(undefined)}
          onCompleted={async ({ id }) => {
            await query.refetch();
            fieldHelpers.setValue(id);
          }}
        />
      )}
    </>
  );
});
