import React, { useMemo } from 'react';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { useField } from 'formik';
import { Box, FormField, Select } from 'grommet';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../../components';
import {
  ScheduledJobFormValues,
  TemplateConfigurationProp,
} from '../../interface';

type Option = TemplateConfigurationProp;

export type TemplateConfigurationFieldProps = {
  options?: Array<Option | null> | null;
};

/**
 * Renders a field for allowing the user to select a template configuration for
 * the scheduled job.
 */
export const TemplateConfigurationField: React.FC<TemplateConfigurationFieldProps> =
  ({ options: originalOptions }) => {
    const [t] = useTranslation(
      'features/scheduled-jobs/components/ScheduledJobForm'
    );

    const [field, fieldMeta, fieldHelpers] = useField<
      ScheduledJobFormValues['templateConfiguration']
    >('templateConfiguration');

    const options = useMemo(() => compact(originalOptions), [originalOptions]);

    const onChange = (event: GrommetSelectEvent<Option>) => {
      fieldHelpers.setValue(event.value);
    };

    return (
      <>
        <Box>
          <FormLabel>{t('fields.template-configuration.label')}</FormLabel>
        </Box>
        <Box>
          <FormField error={fieldMeta.error} margin="none">
            <Select
              {...field}
              labelKey="displayName"
              onChange={onChange}
              options={options}
              value={field.value}
              valueKey="id"
            />
          </FormField>
        </Box>
      </>
    );
  };
