import React from 'react';
import { PageContent } from '@common/components';
import {
  ChangePasswordFormValues,
  ChangePasswordReturnCode,
  UserProfile,
} from '@features/users';
import {
  useChangePasswordMutation,
  useGetCurrentUserTenantMemberQuery,
} from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Box } from 'grommet';

const API_PASSWORD_MISMATCH_CODE = 'PasswordMismatch';

/**
 * Renders a form for the user to make adjustments to their profile.
 */
export const UserProfileTab: React.FC = () => {
  const { loading, error, data } = useGetCurrentUserTenantMemberQuery();
  const [changePassword] = useChangePasswordMutation();

  const tenantMember = data?.me;

  const onChangePassword: FormikSubmitFn<
    ChangePasswordFormValues,
    ChangePasswordReturnCode
  > = async values => {
    try {
      const result = await changePassword({
        variables: {
          input: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          },
        },
      });

      const isSuccessful = result.data?.changePassword.isSuccessful;
      if (!isSuccessful) {
        const errorCode = result.data?.changePassword.errors?.[0].code;
        if (errorCode === API_PASSWORD_MISMATCH_CODE) {
          return ChangePasswordReturnCode.PasswordMismatch;
        }

        // Handle unknown or missing error code
        return ChangePasswordReturnCode.UnknownError;
      }
      return ChangePasswordReturnCode.Success;
    } catch (error) {
      // Handle transport layer errors
      return ChangePasswordReturnCode.UnknownError;
    }
  };

  return (
    <PageContent error={error} loading={loading}>
      <Box>
        {tenantMember && (
          <Box align="center" pad="medium">
            <UserProfile
              onChangePassword={onChangePassword}
              userEmailAddress={tenantMember.emailAddress}
              userName={
                tenantMember?.lastName
                  ? `${tenantMember.firstName} ${tenantMember.lastName}`
                  : tenantMember?.emailAddress
              }
              userRole={tenantMember.role}
            />
          </Box>
        )}
      </Box>
    </PageContent>
  );
};
