import { isPromise } from '@voleer/types';
import { ValidatorPredicate } from '.';

/**
 * Negates the return value of a predicate function.
 *
 * ```javascript
 * const isNotEmail = not(validatorJs.isEmail);
 * isNotEmail('notemail'); // => true
 * isNotEmail('bob@bob.com'); // => false
 * ```
 *
 * It also works with async predicates. Assuming `isUsernameUnique` is async:
 *
 * ```javascript
 * const isUsernameNotUnique = not(isUsernameUnique);
 * await isUsernameUnique('unique-username'); // => true
 * await isUsernameNotUnique('unique-username'); // => false
 * ```
 *
 * @param predicate
 */
export const not =
  <TValue, TArgs extends unknown[]>(
    predicate: ValidatorPredicate<TValue, TArgs>
  ): ValidatorPredicate<TValue, TArgs> =>
  (value, ...args): Promise<boolean> | boolean => {
    const result = predicate(value, ...args);
    if (isPromise<boolean>(result)) {
      return result.then(v => !v);
    }
    return !result;
  };

/**
 * Builds a predicate that returns true if the field value
 * matches any of the other provided predicates.
 *
 * ```typescript
 * const isEmptyOrNumber = or(isNumber, isEmpty);
 * isEmptyOrNumber("")   // true
 * isEmptyOrNumber("10") // true
 * isEmptyOrNumber("example.") // false
 * ```
 */
export const or = <TValue>(...predicates: ValidatorPredicate<TValue, []>[]) => {
  return (value: TValue) => predicates.some(predicate => predicate(value));
};
