import React from 'react';
import { FormTextAreaFragment } from '@generated/graphql-code-generator';
import { useField, useFormikContext } from 'formik';
import { FormField, TextArea } from 'grommet';
import { InitialValues, LabelMarkdown } from '.';

type TextAreaFieldProps = {
  definition: FormTextAreaFragment;
  disabled?: boolean;
};

const DEFAULT_ROWS = 5;
const DEFAULT_COLS = undefined; // leave undefined to fill available width by default

/**
 * Renders a workflow form textarea field.
 */
export const TextAreaField = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta] = useField<string>(definition.name);

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  return (
    <FormField
      error={error}
      label={<LabelMarkdown content={definition.label} />}
    >
      <TextArea
        {...field}
        cols={definition.cols || DEFAULT_COLS}
        disabled={disabled || formik.isSubmitting}
        placeholder={definition.placeholder || undefined}
        ref={ref}
        rows={definition.rows || DEFAULT_ROWS}
        value={field.value || ''}
      />
    </FormField>
  );
});
