import React, { useMemo } from 'react';
import {
  TimezoneOption,
  useFuseState,
  useTimezoneOptions,
} from '@common/hooks';
import { tryFormatDate, tryParseDate } from '@common/utils';
import { FormDateInputFragment } from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { Area, DatePicker, DatePickerProps, Icon } from '@voleer/ui-kit';
import { useField, useFormikContext } from 'formik';
import { FormField, Select, SelectProps, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaCalendarDay } from 'react-icons/fa';
import { WORKFLOW_FORM_DATE_FORMAT } from '../../utils';
import { DateInputValue } from '../interface';
import { InitialValues, LabelMarkdown } from '.';

type DateInputFieldProps = {
  definition: FormDateInputFragment;
  disabled?: boolean;
};

/**
 * Renders a workflow form text input field.
 */
export const DateInputField = React.forwardRef<
  HTMLDivElement,
  DateInputFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [t] = useTranslation('features/workflows/forms');
  const [field, fieldMeta, fieldHelpers] = useField<DateInputValue>(
    definition.name
  );
  const { timezoneOptions, timezoneOptionsMap } = useTimezoneOptions();
  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  const fieldValue: DateInputValue = useMemo(() => field.value, [field.value]);

  const setDateInputFieldValue = (inputValue: DateInputValue) => {
    fieldHelpers.setValue(inputValue);
  };

  const selectedTimezoneOption = useMemo(
    () =>
      typeof fieldValue.timezone === 'string'
        ? timezoneOptionsMap[fieldValue.timezone]
        : undefined,
    [timezoneOptionsMap, fieldValue]
  );

  const options = useMemo(() => {
    const filteredOptions = timezoneOptions.filter(
      option => option.id !== selectedTimezoneOption?.id
    );

    // Shift the currently selected option to the top of the list
    return selectedTimezoneOption
      ? [selectedTimezoneOption, ...filteredOptions]
      : filteredOptions;
  }, [selectedTimezoneOption, timezoneOptions]);

  const { setSearchTerm, searchResults } = useFuseState({
    items: options,
    fuseOptions: {
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true,
      keys: ['label'],
    },
  });

  const onSearch: SelectProps['onSearch'] = search => {
    setSearchTerm(search);
  };

  const onTimezoneChange = (event: GrommetSelectEvent<TimezoneOption>) => {
    if (!event.value?.id) {
      return;
    }

    // Formik expects event.target.value to contain the new value but Grommet
    // puts it in event.value: https://v2.grommet.io/select#onChange
    event.target.value = event.value.id;

    // Clear the search term to reset search results
    setSearchTerm('');

    setDateInputFieldValue({
      value: field.value.value,
      timezone: event.target.value,
      userTimezone: field.value.userTimezone,
    });
    return;
  };

  const onDateSelect: DatePickerProps['onChange'] = date => {
    const formatted = tryFormatDate(date, WORKFLOW_FORM_DATE_FORMAT);
    formatted
      ? setDateInputFieldValue({
          value: formatted,
          userTimezone: field.value.userTimezone,
          timezone: field.value.timezone,
        })
      : setDateInputFieldValue({
          value: '',
          userTimezone: field.value.userTimezone,
          timezone: field.value.timezone,
        });
  };

  const date = useMemo(
    () =>
      fieldValue.value
        ? tryParseDate(fieldValue.value, WORKFLOW_FORM_DATE_FORMAT)
        : null,
    [fieldValue]
  );

  return (
    <FormField
      error={error}
      label={<LabelMarkdown content={definition.label} />}
    >
      <Area
        disabled={disabled || formik.isSubmitting}
        onBlur={() => fieldHelpers.setTouched(true)}
      >
        <Area margin={{ bottom: 'xsmall' }} ref={ref}>
          <DatePicker
            customInput={
              <TextInput
                {...field}
                data-testid="form-date-input__text-input"
                icon={<Icon icon={FaCalendarDay} />}
                name={field.name}
                reverse={true}
                value={field.value.value}
              />
            }
            data-testid="form-date-input__date-picker"
            dateFormat={WORKFLOW_FORM_DATE_FORMAT}
            onChange={onDateSelect}
            placeholderText={t('date-input-field.text-placeholder')}
            selected={date}
            showPopperArrow={false}
          />
        </Area>
        {definition.includeTimezone && (
          <Select
            data-testid="form-date-input__timezone-select"
            dropHeight="small"
            labelKey="label"
            onChange={onTimezoneChange}
            onSearch={onSearch}
            options={searchResults}
            placeholder={t('date-input-field.select-timezone')}
            value={selectedTimezoneOption}
            valueKey="id"
          />
        )}
      </Area>
    </FormField>
  );
});
