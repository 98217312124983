import React from 'react';
import { Text } from 'grommet';
import styled, { css } from 'styled-components';

const StyledText = styled(Text)`
  ${({ theme }) => {
    return css`
      ~ .details-section__title {
        margin-top: ${theme.global.edgeSize.medium};
      }
    `;
  }}
`;

/**
 * Renders a styled title for the associated detail.
 *
 * Implementation Example:
 * <DetailsSectionTitle>Name</DetailsSectionTitle>
 * <DetailsSectionValue>Import from Zendesk</DetailsSectionValue>
 *
 * Rendered Example:
 * Name
 * Import from Zendesk
 */
export const DetailsSectionTitle: React.FC = ({ children }) => {
  return (
    <StyledText
      className="details-section__title"
      margin="none"
      weight="normal"
    >
      {children}
    </StyledText>
  );
};
