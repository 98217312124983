import React from 'react';
import { PackageVersionRow } from '@common/components';
import { useAccountStatus } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  AccountStatus,
  LibraryItemMetadataType,
} from '@generated/graphql-code-generator';
import { Link } from '@voleer/ui-kit';
import { Box, Button, Heading, Paragraph, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';
import { LibraryItemCardIcon } from './components';

export type LibraryItemCardProps = Readonly<{
  description?: string;
  displayName?: string;
  name: string;
  packageName: string;
  publishedOn?: Date;
  publisherDisplayName: string;
  publisherName: string;
  type: LibraryItemMetadataType;
  version?: string;
  accountStatus?: AccountStatus;
  solutions?: string[];
  solutionName?: string | null;
  hasTenantAccess: boolean;
}>;

/**
 * Card for displaying information about an item in the library.
 */
export const LibraryItemCard: React.FC<LibraryItemCardProps> = ({
  solutionName,
  hasTenantAccess,
  description,
  displayName,
  packageName,
  publisherDisplayName,
  publisherName,
  name,
  type,
}) => {
  displayName = displayName || name;

  const { 'launch-control': launchControl } = useTypedFlags();
  const [t] = useTranslation('components/LibraryItemCard');
  const { accountStatus } = useAccountStatus();

  // Ignore unknown types since there is nothing we can do with them
  if (type === LibraryItemMetadataType.Unknown) {
    return null;
  }

  const titleI18nKeys: Record<typeof type, string> = {
    [LibraryItemMetadataType.Automation]: 'link-titles.automation',
    [LibraryItemMetadataType.DataSet]: 'link-titles.dataset',
    [LibraryItemMetadataType.Dashboard]: 'link-titles.dashboard',
  };
  const titleI18nId = titleI18nKeys[type] || 'link-titles.automation';

  const qualifiedName = `${publisherName}/${packageName}/${name}`;

  const renderLaunchControlBanner = () => {
    if (hasTenantAccess) {
      return (
        <Button
          data-testid="library-item-card__button--run-now"
          label={t('buttons.run-now.label')}
          primary={true}
        />
      );
    }

    return (
      <>
        <Box alignSelf="end" flex={{ grow: 0 }}>
          <Button
            data-testid="library-item-card__button--contact-sales"
            label={t('buttons.contact-sales.label')}
            onClick={event => {
              event.preventDefault();
              window.open(urlFor('contactSales')());
            }}
            primary={true}
          />
        </Box>
        {accountStatus === AccountStatus.Active && !!solutionName && (
          <Box pad={{ left: '10px' }}>
            <Text size="xsmall" style={{ lineHeight: '14px' }}>
              {t('available-message', { solutionName })}
            </Text>
          </Box>
        )}
      </>
    );
  };

  return (
    <Link
      data-testid="library-item-card__link"
      data-voleer-id="library-item"
      data-voleer-template-name={qualifiedName}
      data-voleer-template-type={type}
      title={t(titleI18nId, { displayName })}
      to={urlFor('libraryItem')({
        type,
        publisherName,
        packageName,
        name,
      })}
      variation="unstyled"
    >
      <Box
        background="white"
        direction="column"
        elevation="small"
        height="252px"
        margin="2px"
        pad="medium"
        round="xsmall"
      >
        <Box>
          <Box
            data-testid="library-item-card__heading"
            direction="row"
            gap="small"
            margin={{ bottom: 'xxsmall' }}
          >
            <Box flex={{ grow: 1, shrink: 1 }}>
              <Heading
                color="black"
                level="4"
                margin="none"
                title={displayName}
              >
                <Truncate ellipsis="..." lines={2}>
                  {displayName}
                </Truncate>
              </Heading>
            </Box>

            <Box flex={{ shrink: 0 }}>
              <LibraryItemCardIcon type={type} />
            </Box>
          </Box>

          <PackageVersionRow
            color="dark-3"
            size="small"
            tenantDisplayName={publisherDisplayName}
            tenantName={publisherName}
          />
        </Box>

        <Box basis="0" flex={{ grow: 1 }} justify="between" overflow="hidden">
          <Paragraph color="black" size="large">
            <Truncate ellipsis="..." lines={4}>
              {description}
            </Truncate>
          </Paragraph>
          {launchControl && (
            <Box align="center" direction="row">
              {renderLaunchControlBanner()}
            </Box>
          )}
        </Box>
      </Box>
    </Link>
  );
};
