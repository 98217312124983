import React, { Ref } from 'react';
import {
  FormMultiSelectDisplayType,
  FormSelectDisplayType,
} from '@generated/graphql-code-generator';
import { UnreachableCaseError, ensureUnreachable } from '@voleer/types';
import { castRef } from '@voleer/ui-kit';
import { FastField } from 'formik';
import { FormComponentFragment, fastFieldShouldUpdate } from '..';
import {
  CheckboxField,
  Columns,
  DateInputField,
  DateTimeInputField,
  FileInputField,
  IntegrationField,
  Link,
  MultiSelectCheckboxField,
  MultiSelectField,
  Paragraph,
  SelectField,
  SelectRadioGroupField,
  TextAreaField,
  TextInputField,
  TimeInputField,
} from '../components';

export type RenderFormComponentRefHandler = (
  definition: FormComponentFragment
) => Ref<HTMLElement>;

type RenderFormComponentProps = Readonly<{
  dataRequestId?: string;
  instanceId?: string;
  workspaceId?: string;
  definition: FormComponentFragment;
  disabled?: boolean;
  formFieldRefHandler: RenderFormComponentRefHandler;
}>;

/**
 * Renders a component for a workflow form.
 */
export const RenderFormComponent: React.FC<RenderFormComponentProps> = ({
  dataRequestId,
  definition,
  disabled,
  formFieldRefHandler,
  instanceId,
  workspaceId,
}) => {
  const ref = formFieldRefHandler?.(definition);

  /**
   * Renders the form component based on its type.
   */
  const renderField = () => {
    switch (definition.__typename) {
      case 'FormTextInput':
      case 'FormNumericInput':
      case 'FormEmailInput':
      case 'FormUrlInput':
        return (
          <TextInputField
            data-testid="form-component"
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      case 'FormTextArea':
        return (
          <TextAreaField
            data-testid="form-component"
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      case 'FormDateInput':
        return (
          <DateInputField
            data-testid="form-component"
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      case 'FormFileInput':
        return (
          <FileInputField
            data-testid="form-component"
            dataRequestId={dataRequestId}
            definition={definition}
            disabled={disabled}
            instanceId={instanceId}
            ref={castRef(ref)}
            workspaceId={workspaceId}
          />
        );
      case 'FormTimeInput':
        return (
          <TimeInputField
            data-testid="form-component"
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      case 'FormDateTimeInput':
        return (
          <DateTimeInputField
            data-testid="form-component"
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      case 'FormSelect':
        switch (definition.selectDisplayType) {
          case FormSelectDisplayType.Radio: {
            return (
              <SelectRadioGroupField
                data-testid="form-component"
                definition={definition}
                disabled={disabled}
                ref={castRef(ref)}
              />
            );
          }
          case FormSelectDisplayType.Dropdown: {
            return (
              <SelectField
                data-testid="form-component"
                definition={definition}
                disabled={disabled}
                ref={castRef(ref)}
              />
            );
          }
          default: {
            ensureUnreachable(definition.selectDisplayType);
            return null;
          }
        }
      case 'FormMultiSelect':
        switch (definition.multiSelectDisplayType) {
          case FormMultiSelectDisplayType.Checkbox:
            return (
              <MultiSelectCheckboxField
                data-testid="form-component"
                definition={definition}
                disabled={disabled}
                ref={castRef(ref)}
              />
            );
          case FormMultiSelectDisplayType.Dropdown:
            return (
              <MultiSelectField
                data-testid="form-component"
                definition={definition}
                disabled={disabled}
                ref={castRef(ref)}
              />
            );
          default:
            ensureUnreachable(definition.multiSelectDisplayType);
            return null;
        }
      case 'FormCheckbox':
        return (
          <CheckboxField
            data-testid="form-component"
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      case 'FormColumns':
        return (
          <Columns
            definition={definition}
            disabled={disabled}
            formFieldRefHandler={formFieldRefHandler}
          />
        );
      case 'FormLink':
        return <Link definition={definition} />;
      case 'FormParagraph':
        return <Paragraph definition={definition} />;
      case 'FormIntegration':
        return (
          <IntegrationField
            definition={definition}
            disabled={disabled}
            ref={castRef(ref)}
          />
        );
      default:
        throw new UnreachableCaseError(definition);
    }
  };

  return (
    <FastField
      disabled={disabled}
      name={definition.name}
      shouldUpdate={fastFieldShouldUpdate}
    >
      {() => renderField()}
    </FastField>
  );
};
