import React from 'react';
import { PageContent } from '@common/components';
import { useWorkflowInstanceViewQuery } from '@generated/graphql-code-generator';
import { Area } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { FormDataRequestDetails, InstanceEmptyState, StepDetails } from '..';
import { isEndedInstanceStatus } from '../../utils';
import { InstanceSummary, StepList } from './components';
import { useStepList } from './use-step-list';

type StepTabContentProps = {
  instanceId: string;
};

/**
 * Renders the content section for the steps tab.
 */
export const StepTabContent: React.FC<StepTabContentProps> = ({
  instanceId,
}) => {
  const { data, ...query } = useWorkflowInstanceViewQuery({
    variables: { instanceId },
  });

  const { selectedStep } = useStepList({ instanceId });

  const instance = data?.instance;

  // Loading and error state is handled in WorkflowInstanceView
  if (!instance) {
    return null;
  }

  // If there is a data request to display for the step, display it
  if (selectedStep?.dataRequest) {
    return (
      <Area flex={{ shrink: 0 }} margin="medium" paper={true}>
        <FormDataRequestDetails
          onCompleted={() => query.refetch()}
          stepId={selectedStep.id}
        />
      </Area>
    );
  }

  // If a current step is selected, display its details
  if (selectedStep) {
    return (
      <Area flex={{ shrink: 0 }} margin="medium" paper={true}>
        <StepDetails stepId={selectedStep.id} />
      </Area>
    );
  }

  // Display the summary if no step is selected and the instance has finished
  if (instance && isEndedInstanceStatus(instance.status)) {
    return <InstanceSummary instanceId={instanceId} />;
  }

  // Otherwise show empty state when no step is selected
  return <InstanceEmptyState instance={instance} />;
};

export type WorkflowInstanceViewProps = {
  instanceId?: string;
};

/**
 * Renders the Workflow instance view.
 */
export const WorkflowInstanceView: React.FC<WorkflowInstanceViewProps> = ({
  instanceId,
}) => {
  const { data, error, ...query } = useWorkflowInstanceViewQuery({
    skip: !instanceId,
    variables: {
      // Query will be skipped if the instanceId hasn't been provided yet
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      instanceId: instanceId!,
    },
  });

  const instance = data?.instance;

  const loading = !data && query.loading;

  const layout = (children?: React.ReactNode) => {
    return (
      <PageContent empty={!instance} error={error} loading={loading}>
        <Box
          border={{ color: 'neutral-1', side: 'top' }}
          direction="row"
          fill={true}
          overflow="hidden"
        >
          {children}
        </Box>
      </PageContent>
    );
  };

  if (loading || !instanceId) {
    return layout();
  }

  if (!instance) {
    return layout();
  }

  return layout(
    <>
      <Box
        border={{ side: 'right', color: 'neutral-1' }}
        flex={{ shrink: 0 }}
        overflow="auto"
        width="400px"
      >
        <StepList instanceId={instanceId} />
      </Box>
      <Box
        data-testid="workflow-instance-view__step-content"
        fill="horizontal"
        overflow="auto"
      >
        <StepTabContent instanceId={instanceId} />
      </Box>
    </>
  );
};
