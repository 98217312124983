import React from 'react';
import {
  ConfigurationPageSubtitle,
  InstanceStatusBadge,
  getRunStatus,
} from '@features/workflows';
import {
  DataSet,
  InstanceStatus,
  Maybe,
  TemplateMetadata,
  WorkflowInstanceViewInstanceFragment,
} from '@generated/graphql-code-generator';

/**
 * Prop types for the `Subtitle` component.
 */
type SubtitleProps = Readonly<{
  instance: WorkflowInstanceViewInstanceFragment | null | undefined;
  templateConfiguration:
    | {
        configurationInstance?: Maybe<{
          status: InstanceStatus;
        }>;
        dataSet?: Pick<DataSet, 'displayName' | 'id' | 'workspaceId'> | null;
        templateMetadata?: Pick<TemplateMetadata, 'type'> | null;
      }
    | null
    | undefined;
}>;

/**
 * Renders the page content subtitle in the `WorkflowInstancePage`.
 */
export const Subtitle: React.FC<SubtitleProps> = ({
  instance,
  templateConfiguration,
}) => {
  if (!instance) return null;

  // If there is no templateMetadata associated with the instance then it
  // generally means that the instance was started using `voleer
  // start-workflow:local` or another way where there is no published
  // TemplateMetadata associated with the instance, so we show the instance
  // displayName in that case instead.
  const metadataDisplayName =
    instance.templateMetadata?.displayName || instance.displayName || undefined;

  return (
    <ConfigurationPageSubtitle
      dataSet={templateConfiguration?.dataSet}
      margin={{ bottom: 'small' }}
      metadataDisplayName={metadataDisplayName}
      statusBadge={
        <InstanceStatusBadge
          status={getRunStatus(
            instance.status,
            templateConfiguration?.configurationInstance?.status
          )}
        />
      }
      version={instance?.templateMetadata?.packageMetadata?.version}
    />
  );
};
