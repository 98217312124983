import React from 'react';
import {
  FormMultiSelectFragment,
  FormSelectOption,
} from '@generated/graphql-code-generator';
import { useField, useFormikContext } from 'formik';
import { Box, CheckBox, FormField, Text } from 'grommet';
import { InitialValues, LabelMarkdown } from '.';

type MultiSelectFieldProps = {
  definition: FormMultiSelectFragment;
  disabled?: boolean;
};

type Option = Pick<FormSelectOption, 'id' | 'label'>;

/**
 * Renders a workflow form multi checkbox field.
 */
export const MultiSelectCheckboxField = React.forwardRef<
  HTMLInputElement,
  MultiSelectFieldProps
>(({ definition, disabled }, ref) => {
  const formik = useFormikContext<InitialValues>();
  const [field, fieldMeta, fieldHelpers] = useField<string[] | undefined>(
    definition.name
  );

  // Errors should only be displayed if the field has been touched
  const error = fieldMeta.touched ? fieldMeta.error : undefined;

  const handleOnCheck = (option: Option) => {
    if (isOptionChecked(option)) {
      const filteredSelectedOptionIds = selectedOptionIds.filter(
        selectedOptionId => {
          return option.id !== selectedOptionId;
        }
      );
      fieldHelpers.setValue(filteredSelectedOptionIds);
    } else {
      fieldHelpers.setValue([option.id, ...selectedOptionIds]);
    }
  };

  const selectedOptionIds: string[] = field.value ? field.value : [];

  const isOptionChecked = (option: Option): boolean => {
    return !!field.value?.includes(option.id);
  };

  return (
    <FormField
      error={error}
      label={<LabelMarkdown content={definition.label} />}
    >
      <Box gap="xxsmall" pad="small">
        {definition.options.map(option => {
          return (
            <CheckBox
              {...field}
              checked={isOptionChecked(option)}
              disabled={disabled || formik.isSubmitting}
              key={option.id}
              label={
                <Box>
                  <Text title={option.label ? option.label : option.id}>
                    {option.label ? option.label : option.id}
                  </Text>
                </Box>
              }
              name={option.id}
              onChange={() => handleOnCheck(option)}
              ref={ref}
            />
          );
        })}
      </Box>
    </FormField>
  );
});
