import React from 'react';
import { TextAvatar } from '@common/components';
import { useDateFormat } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useWorkflowTranslations } from '@features/workflows';
import { Link } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { InstanceStatusBadge } from '..';
import { InstanceProp } from './interface';

export type InstanceListItemProps = {
  workspaceId: string;
  instance: InstanceProp;
};

/**
 * Displays information about an Instance in a list.
 */
export const InstanceListItem: React.FC<InstanceListItemProps> = ({
  instance,
  workspaceId,
}) => {
  const [t] = useTranslation('features/workflows/components/InstanceListItem');
  const { getLaunchedByName } = useWorkflowTranslations();

  const [format, { DateTimeFormat }] = useDateFormat();

  const launchedOnText = format(
    parseISO(instance.createdOn),
    DateTimeFormat.AbbreviatedHumanized
  );

  const completedOnText =
    instance.completedOn &&
    format(parseISO(instance.completedOn), DateTimeFormat.AbbreviatedHumanized);

  const launchedBy = instance.launchedBy;
  const launchedByName = getLaunchedByName(launchedBy);
  const launchedByUserRemoved =
    launchedBy?.__typename === 'TenantMember' && !launchedBy.isActive;

  const renderAvatar = launchedByName && (
    <TextAvatar
      fontSize="10px"
      name={launchedByName}
      size="large"
      tooltip={
        launchedByUserRemoved
          ? t('user-removed', { userName: launchedByName })
          : launchedByName
      }
    />
  );

  return (
    <Link
      to={urlFor('workflowInstance')({
        workspaceId,
        workflowInstanceId: instance.id,
      })}
      variation="unstyled"
    >
      <Box
        gap="xxsmall"
        hoverIndicator="active"
        onClick={() => null}
        pad={{ horizontal: 'medium', vertical: 'small' }}
      >
        <Box align="center" direction="row" gap="xsmall">
          <Text truncate={true}>
            {instance.displayName || t('missing-name')}
          </Text>
          <InstanceStatusBadge
            margin={{ left: 'auto' }}
            status={instance.status}
          />
        </Box>
        <Box align="center" direction="row" gap="xsmall">
          {renderAvatar}
          <Text color="dark-2" size="small">
            {completedOnText
              ? t('completed-on', { completedOn: completedOnText })
              : t('launched-on', { launchedOn: launchedOnText })}
          </Text>
        </Box>
      </Box>
    </Link>
  );
};
