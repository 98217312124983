import { useMemo, useState } from 'react';
import { FuseOptions } from 'fuse.js';
import { useFuseSearch } from '..';

export type UseFuseStateResult<T> = {
  /**
   * Changes the search term, filtering the results to only those that match.
   */
  setSearchTerm: (searchTerm: string) => void;

  /**
   * The current search term being used to filter the results.
   */
  searchTerm?: string;

  /**
   * The search results matching the search term. If the search term is not
   * provided then all results will be returned instead.
   */
  searchResults: T[];
};

type UseFuseStateOptions<T> = {
  /**
   * The items to search.
   */
  items: T[];

  /**
   * Optional search term to initialize with. To change the searchTerm call the
   * returned `setSearchTerm` function.
   */
  initialSearchTerm?: string;

  /**
   * Options for Fuse.js.
   */
  fuseOptions: FuseOptions<T>;
};

/**
 * Hook for tracking search state using in-memory filtering via Fuse.js.
 *
 * ```typescript
 * const { searchTerm, setSearchTerm, searchResults } = useFuseState({
 *    items: myItemsToSearch,
 *    fuseOptions: {
 *      // ...
 *    },
 *  });
 *
 *  // ... SNIP ...
 *
 *  <input value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
 *
 *  // ... SNIP ...
 *
 *  <div>
 *    {searchResults.map(item => (
 *      <div>Item: {item.someProperty}</div>
 *    ))}
 *  </div>
 * ```
 */
export const useFuseState = <T>({
  items,
  initialSearchTerm,
  fuseOptions,
}: UseFuseStateOptions<T>): UseFuseStateResult<T> => {
  const [search] = useFuseSearch(items, fuseOptions);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const searchResults = useMemo(() => {
    if (!searchTerm) {
      return items;
    }
    return search(searchTerm);
  }, [items, search, searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
    searchResults,
  };
};
