import React, { useMemo } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  AppLayout,
  EmptyStateImage,
  PageContent,
  RouteTab,
  RouteTabs,
  TextInputHeading,
  useTextInputHeading,
} from '@common/components';
import { useDateFormat, useLoadMoreQuery } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  ConfigurationPageSubtitle,
  ConfigureWorkflow,
  DataSetStatusBadge,
  isDataSetDeleted,
  useWorkflowTranslations,
} from '@features/workflows';
import {
  DataSetPageDocument,
  DataSetStatus,
  useDataSetPageQuery,
  useDataSetRefreshRunsQuery,
  useFindWorkspaceQuery,
  useRenameDataSetMutation,
} from '@generated/graphql-code-generator';
import { ensureUnreachable } from '@voleer/types';
import { parseISO } from 'date-fns';
import { Box } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect, useRouteMatch } from 'react-router';
import { DataSetPageToolbar, SummaryTab } from './components';
import EMPTY_DATASET_IMAGE from './images/empty-dataset.svg';
import RUBIX_CUBE_LAUNCHING_IMG_SRC from './images/rubix-cube-launching.gif';

export type DataSetPageRouteParams = {
  dataSetId: string;
  workspaceId: string;
};

const RECENT_REFRESHES_TABLE_PAGE_SIZE = 10;

/**
 * Renders the page for a dataset configuration.
 */
export const DataSetPage: React.FC = () => {
  const match = useRouteMatch<DataSetPageRouteParams>();

  const [t] = useTranslation('pages/DataSetPage');

  const { getTenantMemberDisplayName } = useWorkflowTranslations();

  const [format, { DateFormat }] = useDateFormat();

  const { dataSetId, workspaceId } = match.params;

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const workspaceQuery = useFindWorkspaceQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: workspaceId },
  });

  const dataSetQuery = useDataSetPageQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfig?.instanceStepsAndDataRequests,
    variables: {
      dataSetId,
    },
  });

  const { fetchMore, ...dataSetRefreshRunsQuery } = useLoadMoreQuery(
    useDataSetRefreshRunsQuery,
    {
      fetchPolicy: 'cache-and-network',
      getPageInfo: data => data?.dataSet?.refreshRuns?.pageInfo,
      pollInterval: pollingConfig?.instanceStepsAndDataRequests,
      variables: {
        dataSetId,
        first: RECENT_REFRESHES_TABLE_PAGE_SIZE,
        input: {
          workspaceId,
        },
      },
    }
  );

  const [renameDataSet, renameDataSetMutation] = useRenameDataSetMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(DataSetPageDocument)!],
  });

  // Keep track of queries that impact the page's overall loading/error state
  const queries = [workspaceQuery, dataSetQuery, dataSetRefreshRunsQuery];

  const loading = queries.some(query => !query.data && query.loading);
  const error = queries.find(query => query.error)?.error;

  const workspace = workspaceQuery.data?.workspace;
  const dataSet = dataSetQuery.data?.dataSet;

  // Set values for the dataset meta
  const dataSetCreatedBy = getTenantMemberDisplayName(dataSet?.createdBy);
  const dataSetDateCreated = dataSet?.createdOn ?? '';
  const dataSetTemplateMetadata = dataSet?.templateMetadata;
  const dataSetSchedule = dataSet?.currentSchedule;
  const dataSetTemplateDisplayName = dataSetTemplateMetadata?.displayName ?? '';
  const dataSetVersion =
    dataSetTemplateMetadata?.packageMetadata?.version ?? '';

  const dataSetRecentRefreshes = useMemo(
    () =>
      dataSetRefreshRunsQuery.data?.dataSet?.refreshRuns?.edges?.map(
        edge => edge?.node ?? null
      ),
    [dataSetRefreshRunsQuery.data?.dataSet?.refreshRuns?.edges]
  );
  const dataSetRecentRefreshesPageInfo =
    dataSetRefreshRunsQuery.data?.dataSet?.refreshRuns?.pageInfo;

  const candidateInstance = dataSet?.candidateData?.instance;

  const { textInputHeadingProps } = useTextInputHeading({
    initialValue: dataSet?.displayName,
    onSave: async (value: string) => {
      if (!dataSet?.id || !workspace?.id) {
        return;
      }

      await renameDataSet({
        variables: {
          displayName: value,
          id: dataSet.id,
          workspaceId: workspace.id,
        },
      });
    },
  });

  const onLoadMoreRecentRefreshes = useMemo(() => {
    if (!dataSetRecentRefreshesPageInfo?.hasNextPage) {
      return undefined;
    }

    return async () => {
      await fetchMore();
    };
  }, [fetchMore, dataSetRecentRefreshesPageInfo]);

  const title =
    dataSet && !isDataSetDeleted(dataSet.status) ? (
      <TextInputHeading
        {...textInputHeadingProps}
        disabled={renameDataSetMutation.loading}
      />
    ) : (
      dataSet?.displayName
    );
  const subtitle = dataSet && (
    <ConfigurationPageSubtitle
      metadataDisplayName={dataSetTemplateDisplayName}
      statusBadge={
        <DataSetStatusBadge
          instanceStatus={dataSet.configurationInstance?.status}
          status={dataSet.status}
        />
      }
      version={dataSetVersion}
    />
  );

  const layout = (children?: React.ReactNode) => (
    <AppLayout
      breadcrumbs={[
        {
          title: t('breadcrumbs.workspaces'),
          to: urlFor('workspaces')(),
        },
        {
          title: workspace?.displayName || '',
          to: urlFor('workspace')({ workspaceId }),
        },
        {
          title: t('breadcrumbs.datasets'),
          to: urlFor('workspaceDataSets')({ workspaceId }),
        },
      ]}
      description={dataSet?.description || undefined}
      subtitle={subtitle}
      title={title}
      toolbar={<DataSetPageToolbar dataSet={dataSet} workspace={workspace} />}
    >
      <PageContent
        children={children}
        empty={{
          body: t('empty.body'),
          empty: !dataSet,
          heading: t('empty.heading'),
        }}
        error={error}
        loading={loading}
        loadingLabel={t('loading')}
      />
    </AppLayout>
  );

  if (!dataSet || loading || error) {
    // Render layout with empty state
    return layout();
  }

  // Redirect if we still don't have a workspace after loading
  if (!workspace) {
    return <Redirect to={urlFor('workspaces')()} />;
  }

  // Reconfigure Workflow
  if (!!dataSet?.candidateData && candidateInstance) {
    const {
      workspaceId: candidateWorkspaceId,
      id: candidateWorkflowInstanceId,
    } = candidateInstance;
    if (candidateWorkspaceId && candidateWorkflowInstanceId) {
      return layout(
        <ConfigureWorkflow workflowInstanceId={candidateWorkflowInstanceId} />
      );
    }
  }

  switch (dataSet.status) {
    case DataSetStatus.Pending:
      // Initial Workflow Configuration
      return layout(
        <ConfigureWorkflow
          workflowInstanceId={dataSet?.configurationInstance?.id}
        />
      );

    case DataSetStatus.Ready:
      return layout(
        <>
          <RouteTabs justify="start">
            <RouteTab queryParamValue="summary" title={t('tabs.summary')}>
              <SummaryTab
                createdBy={dataSetCreatedBy ?? ''}
                dataSetId={dataSetId}
                dateCreated={format(
                  parseISO(dataSetDateCreated),
                  DateFormat.Long
                )}
                instanceName={dataSet.displayName}
                onLoadMoreRecentRefreshes={onLoadMoreRecentRefreshes}
                recentRefreshes={dataSetRecentRefreshes}
                schedule={dataSetSchedule}
                templateDisplayName={dataSetTemplateDisplayName}
                templateId={dataSetTemplateMetadata?.id}
                version={dataSetVersion}
                workspaceId={workspaceId}
              />
            </RouteTab>

            {/* Don't render the configuration tab if there's no configuration */}
            {!!dataSet?.data && (
              <RouteTab
                queryParamValue="configuration"
                title={t('tabs.configuration')}
              >
                {/* Using negative top margin to overlap the borders */}
                <Box margin={{ top: '-1px' }}>
                  <ConfigureWorkflow
                    workflowInstanceId={dataSet?.configurationInstance?.id}
                  />
                </Box>
              </RouteTab>
            )}
          </RouteTabs>
        </>
      );

    case DataSetStatus.Provisioning:
      return layout(
        <EmptyStateImage
          src={RUBIX_CUBE_LAUNCHING_IMG_SRC}
          text={t('provisioning.text')}
        />
      );

    case DataSetStatus.Invalid:
      return layout(<PageContent error={t('invalid.text')} />);

    case DataSetStatus.Unknown:
      return layout();

    case DataSetStatus.Deleted:
    case DataSetStatus.Deleting:
      return layout(
        <EmptyStateImage src={EMPTY_DATASET_IMAGE} text={t('deleted.text')} />
      );

    default:
      ensureUnreachable(dataSet.status);
      return layout();
  }
};
