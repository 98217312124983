import React, { useState } from 'react';
import { LoadingOverlay } from '@common/components';
import {
  ScheduledJob,
  TemplateConfiguration,
  TemplateMetadata,
} from '@generated/graphql-code-generator';
import { isPromise } from '@voleer/types';
import { Alert, ErrorButton, Modal, ModalProps } from '@voleer/ui-kit';
import { Box, Button, FormField, Grid, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import { ScheduledJobScheduleDetails, ScheduledJobStatusBadge } from '..';

type TemplateMetadataProp = Pick<TemplateMetadata, 'displayName'>;

type TemplateConfigurationProp = Pick<TemplateConfiguration, 'displayName'> & {
  templateMetadata?: TemplateMetadataProp | null;
};

type ScheduledJobProp = Pick<
  ScheduledJob,
  | 'endTime'
  | 'id'
  | 'maxRecurrenceCount'
  | 'pattern'
  | 'startTime'
  | 'status'
  | 'timezoneName'
> & {
  templateConfiguration: TemplateConfigurationProp;
};

export type CancelScheduledJobModalProps = Pick<ModalProps, 'open'> & {
  /**
   * The scheduled job being canceled.
   *
   * If omitted then the modal will be rendered in "loading" state until a
   * scheduled job is provided.
   */
  scheduledJob?: ScheduledJobProp | null;

  /**
   * Error to display in the modal.
   */
  error?: Error | string;

  /**
   * Callback called when user confirms.
   */
  onConfirm: () => Promise<void> | void;

  /**
   * Callback called when the user clicks cancel.
   */
  onCancel: () => void;
};

/**
 * Modal to confirm cancellation of a scheduled job.
 */
export const CancelScheduledJobModal: React.FC<CancelScheduledJobModalProps> =
  ({ scheduledJob, open, error, onConfirm: originalOnConfirm, onCancel }) => {
    const [t] = useTranslation(
      'features/scheduled-jobs/components/CancelScheduledJobModal'
    );

    const [processing, setProcessing] = useState(false);

    const onConfirm = async () => {
      if (!scheduledJob) {
        return;
      }

      const result = originalOnConfirm();

      if (isPromise(result)) {
        setProcessing(true);
        await result;
        setProcessing(false);
      }
    };

    const renderBody = () => {
      if (!scheduledJob) {
        return null;
      }

      const templateConfiguration = scheduledJob.templateConfiguration;
      const templateName = templateConfiguration.templateMetadata?.displayName;

      const errorMessage = error instanceof Error ? error.message : error;

      return (
        <Box gap="small">
          {errorMessage && (
            <Alert icon={true} status="error">
              {errorMessage}
            </Alert>
          )}
          <Box>
            <Paragraph>{t('message')}</Paragraph>
          </Box>
          <Box>
            <Grid
              columns={['1/2', '1/2']}
              gap="small"
              pad={{ horizontal: 'small' }}
            >
              <Box>
                <FormField label={t('info.template.label')}>
                  <Box align="start" direction="row">
                    {templateName}
                  </Box>
                </FormField>
              </Box>
              <Box>
                <FormField label={t('info.template-configuration.label')}>
                  <Box align="start" direction="row">
                    {templateConfiguration.displayName}
                  </Box>
                </FormField>
              </Box>
              <Box>
                <FormField label={t('info.schedule.label')}>
                  <Box align="start" direction="row">
                    <ScheduledJobScheduleDetails scheduledJob={scheduledJob} />
                  </Box>
                </FormField>
              </Box>
              <Box>
                <FormField label={t('info.status.label')}>
                  <Box align="start" direction="row">
                    <ScheduledJobStatusBadge status={scheduledJob.status} />
                  </Box>
                </FormField>
              </Box>
            </Grid>
          </Box>
        </Box>
      );
    };

    return (
      <Modal
        onClickOutside={onCancel}
        onEsc={onCancel}
        onPressEnter={onConfirm}
        open={!!open}
        width="medium"
      >
        <Modal.Header>{t('title')}</Modal.Header>
        <Modal.Body>
          <LoadingOverlay loading={!scheduledJob}>
            {renderBody()}
          </LoadingOverlay>
        </Modal.Body>
        <Modal.Footer>
          <Button label={t('buttons.cancel.label')} onClick={onCancel} />
          <ErrorButton
            disabled={!scheduledJob}
            label={t('buttons.confirm.label')}
            loading={processing}
            onClick={onConfirm}
          />
        </Modal.Footer>
      </Modal>
    );
  };
