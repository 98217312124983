import React from 'react';
import { ErrorButton, Modal } from '@voleer/ui-kit';
import { Button, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';

type DeleteFileConfirmationProps = {
  /**
   * Name of the file to be deleted.
   */
  fileName: string;

  /**
   * A callback to handle when the user clicks cancel.
   */
  onCancel: () => Promise<void> | void;

  /**
   * A callback to handle file deletion.
   */
  onDelete: () => Promise<void> | void;
};

/**
 * Displays the delete file confirmation modal.
 */
export const DeleteFileConfirmation: React.FC<DeleteFileConfirmationProps> = ({
  fileName,
  onCancel,
  onDelete,
}) => {
  const [t] = useTranslation('pages/WorkspacePage');

  return (
    <Modal onClickOutside={onCancel} onEsc={onCancel}>
      <Modal.Header>{t('files-tab.delete-confirmation.title')}</Modal.Header>
      <Modal.Body>
        <Paragraph>
          {t('files-tab.delete-confirmation.message', { fileName })}
        </Paragraph>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="delete-file-confirmation__button--cancel"
          label={t('files-tab.delete-confirmation.cancel')}
          onClick={onCancel}
        />
        <ErrorButton
          data-testid="delete-file-confirmation__button--delete"
          label={t('files-tab.delete-confirmation.delete')}
          onClick={() => {
            onDelete();
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
