import { useMemo, useRef } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { usePolling } from '@voleer/react-hooks';
import {
  UseLoadMoreQueryHook,
  UseLoadMoreQueryOptions,
  UseLoadMoreQueryResult,
  UseLoadMoreQueryVariables,
} from './interface';

/**
 * Decorates the given query hook to provide support for "load more" pagination
 * using a relay-style GraphQL connection.
 *
 * # Usage
 *
 * First configure your ApolloClient cache by making sure that the relevant
 * fields have been configured to use relay-style pagination in the cache's
 * `typePolicies`:
 *
 * ```typescript
 * const client = new ApolloClient({
 *   cache: new InMemoryCache({
 *     typePolicies: {
 *       Query: {
 *         fields: {
 *           someRecords: relayStylePagination(),
 *         },
 *       },
 *     },
 *   }),
 * });
 * ```
 *
 * Then in your graphql document, include the after and first parameters that
 * are passed to the API. Include edges instead of items, since relayStylePagination
 * only works with edges. Include pageInfo so that this hook knows whether to load
 * more items or not.
 *
 * See: https://relay.dev/graphql/connections.htm
 * See: https://www.apollographql.com/docs/react/v2/data/pagination/#relay-style-cursor-pagination
 *
 * Example:
 *
 * ```graphql
 * query someRecords($first: Int, $after: String) {
 *   recordsApi(first: $first, after: $after) {
 *     edges {
 *       cursor
 *       node {
 *         # fields to query for in each record
 *       }
 *     }
 *     pageInfo {
 *       endCursor
 *       hasNextPage
 *     }
 *   }
 * }
 * ```
 *
 * Then in your component:
 *
 * ```typescript
 * const SomeComponent = () => {
 *   const { data, fetchMore } = useLoadMoreQuery(useSomeRecordsQuery, {
 *     variables: {
 *       // The `first` variable will be used as the default page size
 *       first: 20,
 *     },
 *
 *     // Provide a callback to extract `PageInfo` from the query result data
 *     getPageInfo: data => data?.someRecords?.pageInfo,
 *   });
 *
 *   const onLoadMore = () => {
 *     fetchMore(); // Fetch the next page of results
 *   }
 *
 *   return (
 *     <div>
 *       <div>
 *         {data?.someRecords?.edges?.map(edge => (
 *           <div>{edge?.node?.someProperty}</div>
 *         ))}
 *       </div>
 *       <div>
 *         <button onClick={onLoadMore}>Load More</button>
 *       </div>
 *     </div>
 *   );
 * }
 * ```
 */
export const useLoadMoreQuery = <
  TQuery,
  TVariables extends UseLoadMoreQueryVariables,
  TData
>(
  query: UseLoadMoreQueryHook<TQuery, TVariables, TData>,
  options: UseLoadMoreQueryOptions<TQuery, TVariables, TData>
): UseLoadMoreQueryResult<TData, TVariables> => {
  const {
    pollInterval,
    getPageInfo,
    pageSize = options.variables?.first,
    ...apolloOptions
  } = options;

  const variables = useMemo(
    () => options.variables,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options.variables)]
  );

  const loadedCount = useRef(variables?.first || 0);

  const queryResult = query({
    // Use cache-and network by default, unless overridden by the caller
    fetchPolicy: 'cache-and-network',
    ...apolloOptions,
    notifyOnNetworkStatusChange: true,
    variables,

    // For subsequent requests we want to rely only on the cache to determine
    // what items the list contains. Otherwise we end up doing an extra query
    // after every `fetchMore`.
    nextFetchPolicy: 'cache-only',
  });

  const fetchingMore = useRef(false);

  // Handle polling manually since Apollo client pagination and polling are not
  // meant to work together.
  // See: https://github.com/apollographql/apollo-client/issues/1121
  const poll = async () => {
    // Skip polling if the query is currently loading
    // Fixes: https://dev.azure.com/bittitan/Voleer/_workitems/edit/2632
    if (queryResult.loading || fetchingMore.current) {
      return;
    }

    const refetchVariables = {
      ...queryResult.variables,
      first: loadedCount.current,
    } as TVariables;

    delete refetchVariables.after;
    await queryResult.refetch(refetchVariables);
  };

  // Poll all currently loaded items periodically if pollInterval was provided
  usePolling(pollInterval, async () => {
    await poll();
  });

  const result: UseLoadMoreQueryResult<TData, TVariables> = {
    ...queryResult,
    poll,
    fetchMore: async () => {
      const pageInfo = getPageInfo(queryResult.data);
      if (!pageInfo) {
        throw new Error('Missing PageInfo');
      }

      let fetchMoreResult: ApolloQueryResult<TData>;
      try {
        fetchingMore.current = true;

        fetchMoreResult = await queryResult.fetchMore({
          variables: {
            ...queryResult.variables,
            after: pageInfo.endCursor,
            first: pageSize,
          },
        });
      } finally {
        fetchingMore.current = false;
      }

      loadedCount.current = loadedCount.current + (pageSize || 0);

      return fetchMoreResult;
    },
  };

  return result;
};
