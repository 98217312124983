import { useCallback, useMemo } from 'react';
import { isPresent } from '@voleer/validators';
import Fuse, { FuseOptions } from 'fuse.js';

type UseFuseSearchFn<T> = Fuse<T, FuseOptions<T>>['search'];

export type UseFuseSearchResult<T> = [
  UseFuseSearchFn<T>,
  {
    /**
     * The fuse instance.
     */
    fuse: Fuse<T, FuseOptions<T>>;
  }
];

/**
 * Hook for managing a Fuse.js instance for searching the given array of items.
 *
 * ```javascript
 * const [search] = useFuseSearch(itemsArray, fuseOptions);
 * const result = search('the search term');
 * ```
 *
 * @param items
 * @param options
 */
export const useFuseSearch = <T>(
  items: T[],
  options: FuseOptions<T> = {}
): UseFuseSearchResult<T> => {
  const optionsKey = JSON.stringify(options);

  // Memoize Fuse instances
  const fuse = useMemo(
    () => new Fuse(items, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      items,
      optionsKey, // Cache on options key instead to avoid memoization by reference
    ]
  );

  // Custom search logic to handle empty search pattern
  const search: typeof fuse.search = useCallback(
    (pattern, ...rest) => {
      if (!isPresent(pattern)) {
        return items;
      }
      return fuse.search(pattern.trim(), ...rest);
    },
    [fuse, items]
  );

  return [search, { fuse }];
};
