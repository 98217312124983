import React from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert, LoadingButton, Modal } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormField, Text, TextArea, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type AddDataSetFormValues = {
  description: string;
  displayName: string;
};

type AddDataSetModalProps = Readonly<{
  closeModal: () => void;
  error?: Error | string;
  onSubmit: FormikSubmitFn<AddDataSetFormValues>;
  open: boolean;
  workspaceName?: string;
}>;

/**
 * Modal form for adding a DataSet.
 */
export const AddDataSetModal: React.FC<AddDataSetModalProps> = ({
  closeModal,
  error,
  onSubmit,
  open,
  workspaceName,
}) => {
  const [t] = useTranslation('pages/LibraryDataSetPage');

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(
      t('add-dataset-modal.form.validation.name-required')
    ),
  });

  const initialValues: AddDataSetFormValues = {
    description: '',
    displayName: '',
  };

  return (
    <Modal onClickOutside={closeModal} onEsc={closeModal} open={open}>
      <Modal.Header>{t('add-dataset-modal.heading')}</Modal.Header>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValidating }) => {
          const formProcessing = isSubmitting || isValidating;

          return (
            <Form>
              <Modal.Body>
                <Text margin={{ bottom: 'medium' }}>
                  {t('add-dataset-modal.instructions', { workspaceName })}
                </Text>

                {error && (
                  <Alert margin={{ bottom: 'small' }} status="error">
                    {t('add-dataset-modal.form.error', { error })}
                  </Alert>
                )}

                <Field name="displayName">
                  {(fieldProps: FieldProps<string>) => (
                    <FormField
                      error={fieldProps.meta.error}
                      label={t('add-dataset-modal.form.labels.name')}
                    >
                      <TextInput
                        {...fieldProps.field}
                        autoFocus={true}
                        disabled={formProcessing}
                      />
                    </FormField>
                  )}
                </Field>

                <Field name="description">
                  {(fieldProps: FieldProps<string>) => (
                    <FormField
                      label={t('add-dataset-modal.form.labels.description')}
                    >
                      <TextArea
                        {...fieldProps.field}
                        disabled={formProcessing}
                        fill={true}
                        resize={false}
                        style={{ maxWidth: 'none' }}
                      />
                    </FormField>
                  )}
                </Field>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  disabled={formProcessing}
                  label={t('add-dataset-modal.form.buttons.cancel')}
                  onClick={closeModal}
                />
                <LoadingButton
                  disabled={formProcessing}
                  label={t('add-dataset-modal.form.buttons.continue')}
                  loading={formProcessing}
                  primary={true}
                  type="submit"
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
