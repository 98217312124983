import React from 'react';
import { LoadingOverlay } from '@common/components';
import { useDateFormat } from '@common/hooks';
import {
  DataSetStatus,
  Maybe,
  TemplateConfiguration,
  TenantMember,
} from '@generated/graphql-code-generator';
import { Alert, ErrorButton, Modal, ModalProps } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import {
  Box,
  Button,
  FormField,
  Heading,
  Paragraph,
  Text,
  TextInput,
} from 'grommet';
import { useTranslation } from 'react-i18next';
import { DataSetStatusBadge } from '../DataSetStatusBadge';

export type DeleteDataSetModalProps = Pick<ModalProps, 'open'> & {
  /**
   * The value for the confirmation text input.
   */
  confirmationText: string;

  /**
   * Dataset author display name
   */
  createdBy?: Pick<TenantMember, 'firstName' | 'lastName'> | null;

  /**
   * Dataset created on date
   */
  createdOn?: string | null;

  /**
   * Dataset dependencies
   */
  dependentTemplates?:
    | Maybe<Pick<TemplateConfiguration, 'displayName' | 'id'>>[]
    | null;

  /**
   * Dataset description.
   */
  description?: string | null;

  /**
   * Dataset display name.
   */
  displayName?: string | null;

  /**
   * Error to display in the modal.
   */
  error?: Error;

  /**
   * Flag for if the modal should be in the loading state.
   */
  loading: boolean;

  /**
   * Callback called when user confirms.
   */
  onConfirm: () => void;

  /**
   * Handler for changes in the confirmation text input.
   */
  onChangeConfirmationText: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Callback called when the modal is closed.
   */
  onClose: () => void;

  /**
   * Flag for whether the modal is open or not.
   */
  open: boolean;

  /**
   * Dataset status
   */
  status?: DataSetStatus | null;

  /**
   * Flag for if the delete action is processing.
   */
  submitting: boolean;
};

/**
 * Modal to confirm deleting a DataSet and its dependencies.
 */
export const DeleteDataSetModal: React.FC<DeleteDataSetModalProps> = ({
  confirmationText,
  createdBy,
  createdOn,
  dependentTemplates,
  description,
  displayName,
  error,
  loading,
  onChangeConfirmationText,
  onClose,
  onConfirm,
  open,
  status,
  submitting,
}) => {
  const [t] = useTranslation(
    'features/workflows/components/DeleteDataSetModal'
  );

  const [formatDate, { DateFormat }] = useDateFormat();

  const renderTemplateRow = (
    template: Pick<TemplateConfiguration, 'displayName' | 'id'>
  ) => {
    return (
      <Box
        align="center"
        alignSelf="start"
        direction="row"
        flex={false}
        key={template.id}
        wrap={false}
      >
        <Box fill="horizontal" overflow={{ horizontal: 'auto' }}>
          <Text data-testid={template.id}>
            {template.displayName ? template.displayName : template.id}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderBody = () => {
    const errorMessage = error instanceof Error ? error.message : error;

    return (
      <>
        {errorMessage && (
          <Alert
            data-testid="delete-data-set-modal__alert--error"
            icon={true}
            margin={{ bottom: 'medium' }}
            status="error"
          >
            {errorMessage}
          </Alert>
        )}
        <Paragraph margin={{ top: '0' }}>{t('message')}</Paragraph>
        <Box direction="column">
          <Heading level="5">{displayName}</Heading>
          <Paragraph>{description}</Paragraph>
          {!!createdBy && !!createdOn && !!status && (
            <Box align="center" direction="row" gap="small" justify="between">
              <Box direction="column">
                <Heading level="5">{t('info.created-by')}</Heading>
                <Paragraph margin="0">{`${createdBy.firstName} ${createdBy.lastName}`}</Paragraph>
              </Box>
              <Box direction="column">
                <Heading level="5">{t('info.created-on')}</Heading>
                <Paragraph margin="0">
                  {formatDate(parseISO(createdOn), DateFormat.Long)}
                </Paragraph>
              </Box>
              <Box direction="column">
                <Heading level="5">{t('info.current-status')}</Heading>
                <Box>
                  <DataSetStatusBadge status={status} />
                </Box>
              </Box>
            </Box>
          )}
          {!!dependentTemplates && dependentTemplates.length > 0 && (
            <Box direction="row" fill="horizontal" pad={{ top: 'small' }}>
              <Box>
                <Heading level="5">{t('info.dependencies')}</Heading>
                <Box
                  align="start"
                  border={{ side: 'all', style: 'solid' }}
                  overflow="hidden"
                  pad="xsmall"
                  round="xsmall"
                >
                  <Box overflow={{ vertical: 'auto' }}>
                    {dependentTemplates.map(template => {
                      return template ? renderTemplateRow(template) : null;
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box>
          <FormField
            label={<Text size="small">{t('confirmation-label')}</Text>}
            margin={{ bottom: '0', top: 'large' }}
          >
            <TextInput
              autoFocus={true}
              data-testid="delete-data-set-modal__input--confirm"
              disabled={submitting}
              name="delete-data-set-modal__input--confirm"
              onChange={onChangeConfirmationText}
              value={confirmationText}
            />
          </FormField>
        </Box>
      </>
    );
  };

  return (
    <Modal
      onClickOutside={onClose}
      onEsc={onClose}
      onPressEnter={onConfirm}
      open={open}
      width="medium"
    >
      <Modal.Header>{t('title')}</Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>{renderBody()}</LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="delete-data-set-modal__btn--close"
          label={t('buttons.cancel.label')}
          onClick={onClose}
        />
        <ErrorButton
          data-testid="delete-data-set-modal__btn--confirm"
          disabled={loading || confirmationText !== t('confirmation-key')}
          label={t('buttons.confirm.label')}
          loading={submitting}
          onClick={onConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};
