import React, { useEffect, useRef } from 'react';
import { PageContent } from '@common/components';
import { useTenantContext } from '@common/hooks';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import {
  TenantFragment,
  TenantMemberFragment,
  useTenantHasExternalIdpQuery,
} from '@generated/graphql-code-generator';
import { Area } from '@voleer/ui-kit';
import { Box, BoxProps, Button, Heading, Image, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import { getTenantAppConfig } from '../../../../app-config';
import AZURE_ACTIVE_DIR_IMG_SRC from './images/azure-active-directory.svg';
import VOLEER_IMG_SRC from './images/voleer.svg';

type AuthTabRowProps = {
  iconPath: string;
  title: string;
  description: string;
  showDivider?: boolean;
  rowActionButton?: { label: string; onClick: () => void };
};

type AuthTabRowListProps = {
  ssoEnabled: boolean;
  tenant: TenantFragment;
  tenantMember: TenantMemberFragment;
  ssoQueryRefreshCallback: () => void;
};

/**
 * Renders each of the individual rows for the Authentication settings.
 */
const AuthTabRow: React.FC<AuthTabRowProps> = props => {
  const pad: BoxProps['pad'] = !props.showDivider
    ? undefined
    : {
        top: 'medium',
      };

  const border: BoxProps['border'] = !props.showDivider
    ? undefined
    : {
        side: 'top',
        color: 'border-default',
      };

  return (
    <Box border={border} direction="row" pad={pad}>
      <Box direction="column" margin="small" pad="small">
        <Image src={props.iconPath} width="50" />
      </Box>
      <Box direction="column" fill={true} margin="small">
        <Heading level="4" margin="none">
          {props.title}
        </Heading>
        <Paragraph margin={{ top: 'xsmall', bottom: 'xsmall' }}>
          {props.description}
        </Paragraph>
        <Box align="start" margin={{ top: 'small' }}>
          {props.rowActionButton && (
            <Button
              label={props.rowActionButton.label}
              onClick={props.rowActionButton.onClick}
              primary={true}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

/**
 * Determines what content to show in the TenantAuthTab based on props.
 */
const AuthTabRowList: React.FC<AuthTabRowListProps> = props => {
  const [t] = useTranslation('pages/TenantSettingsPage');

  const voleerAuthDescription = t('authentication-tab.voleer-description');
  const azureAuthDescription = t(
    'authentication-tab.azure-active-dir-description',
    { displayName: props.tenant.displayName }
  );
  const ssoActionButtonLabel = t('authentication-tab.enable-sso-label');

  const ssoNotEnabledContent: AuthTabRowProps[] = [
    {
      iconPath: VOLEER_IMG_SRC,
      title: t('authentication-tab.images.voleer.title'),
      description: voleerAuthDescription,
    },
    {
      iconPath: AZURE_ACTIVE_DIR_IMG_SRC,
      title: t('authentication-tab.images.azure-active-directory.title'),
      description: azureAuthDescription,
      rowActionButton: {
        label: ssoActionButtonLabel,
        onClick: () => {
          props.ssoQueryRefreshCallback();
        },
      },
    },
  ];

  const ssoEnabledContent: AuthTabRowProps[] = [
    {
      iconPath: AZURE_ACTIVE_DIR_IMG_SRC,
      title: t('authentication-tab.images.azure-active-directory.title'),
      description: props.tenantMember.emailAddress,
    },
  ];

  if (props.ssoEnabled) {
    return (
      <Box>
        {ssoEnabledContent.map((rowProps, index) => (
          <AuthTabRow
            key={rowProps.title}
            showDivider={index !== 0}
            {...rowProps}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {ssoNotEnabledContent.map((rowProps, index) => (
        <AuthTabRow
          key={rowProps.title}
          showDivider={index !== 0}
          {...rowProps}
        />
      ))}
    </Box>
  );
};

/**
 * Component for showing the tenant authentication management tab.
 */
export const TenantAuthTab: React.FC = () => {
  const { tenant, tenantMember } = useTenantContext();
  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();
  const tenantHasExternalIdpQuery = useTenantHasExternalIdpQuery({
    pollInterval: pollingConfig?.tenantSettingsAuthentication,
    fetchPolicy: 'cache-and-network',
  });
  const [t] = useTranslation('pages/TenantSettingsPage');
  const tenantAppConfig = getTenantAppConfig(tenant ? tenant.name : '');
  const POP_UP_PARAMS = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=-100,top=-100`;
  const POP_UP_URL = urlFor('enableTenantSso')(
    tenantAppConfig.TENANT_OPEN_ID_AUTHORITY
  );
  const timerRef = useRef<number>();
  const tenantIdp = tenantHasExternalIdpQuery.data?.tenantIdp;
  const loading =
    !tenantHasExternalIdpQuery.data && tenantHasExternalIdpQuery.loading;

  const ssoQueryRefreshCallback = () => {
    const ssoWindow = window.open(POP_UP_URL, 'SSO', POP_UP_PARAMS);
    if (ssoWindow) {
      timerRef.current = setInterval(() => {
        if (ssoWindow.closed) {
          clearInterval(timerRef.current);
          tenantHasExternalIdpQuery.refetch();
        }
      }, 100);
    }
  };

  useEffect(() => {
    clearInterval(timerRef.current);
  });

  return (
    <PageContent
      error={tenantHasExternalIdpQuery.error?.message}
      loading={loading}
      loadingLabel={t('authentication-tab.loading')}
    >
      <Box align="center" pad="medium">
        <Area flex={{ shrink: 0 }} pad="medium" paper={true} width="large">
          <Heading level="3" margin={{ top: 'none' }}>
            {t('authentication-tab.current-auth-title')}
          </Heading>
          <Box>
            {tenantIdp && tenant && tenantMember && (
              <AuthTabRowList
                ssoEnabled={tenantIdp.externalProviderEnabled}
                ssoQueryRefreshCallback={ssoQueryRefreshCallback}
                tenant={tenant}
                tenantMember={tenantMember}
              />
            )}
          </Box>
        </Area>
      </Box>
    </PageContent>
  );
};
