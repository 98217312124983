import React from 'react';
import {
  AddIntegrationModalQuery,
  Integration,
  useAddIntegrationModalCreateCustomWorkflowIntegrationMutation,
} from '@generated/graphql-code-generator';
import { AddIntegrationModalForm, AddIntegrationModalFormProps } from '..';
import { IntegrationModal } from '../../..';

type QueryIntegrationMetadata = NonNullable<
  NonNullable<
    NonNullable<AddIntegrationModalQuery['integrationMetadatas']>['items']
  >[number]
>;

export type AddCustomWorkflowIntegrationProps = {
  integrationMetadata: QueryIntegrationMetadata;
  onClose: () => void;
  onCompleted: (integration: Pick<Integration, 'id'>) => void;
};

/**
 * Handles adding a custom workflow integration in the AddIntegrationModal.
 */
export const AddCustomWorkflowIntegration: React.FC<AddCustomWorkflowIntegrationProps> =
  ({ integrationMetadata, onClose, onCompleted }) => {
    const [createCustomWorkflowIntegration, { data }] =
      useAddIntegrationModalCreateCustomWorkflowIntegrationMutation({
        onCompleted: data => {
          const integration =
            data?.createCustomWorkflowIntegration?.integration;
          if (onCompleted && integration) {
            onCompleted(integration);
          }
        },
      });

    const integration = data?.createCustomWorkflowIntegration?.integration;

    const onSubmit: AddIntegrationModalFormProps['onSubmit'] = async ({
      description,
      displayName,
    }) => {
      await createCustomWorkflowIntegration({
        variables: {
          description,
          displayName,
          integrationMetadataId: integrationMetadata.id,
        },
      });
    };

    if (integration) {
      return (
        <IntegrationModal integrationId={integration.id} onClose={onClose} />
      );
    }

    return (
      <AddIntegrationModalForm
        integrationMetadata={integrationMetadata}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );
  };
