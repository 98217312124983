import { ArgsOf } from '@voleer/types';
import { format as dateFnsFormat, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DateFormat, DateTimeFormat } from './date-formats';

type FormatOptions = ArgsOf<typeof dateFnsFormat>[2];

type Format = DateFormat | DateTimeFormat;

type FormatFn = (date: Date, format: Format, options?: FormatOptions) => string;

type GetFormatFn = (date: Date, format: Format) => string;

type UseDateFormatResponse = [
  /**
   * Function that formats a date.
   */
  FormatFn,

  {
    /**
     * Enum containing formats for representing a datetime.
     */
    DateTimeFormat: typeof DateTimeFormat;

    /**
     * Enum containing formats for representing a date.
     */
    DateFormat: typeof DateFormat;

    /**
     * Gets the appropriate format string for the given date and format enum.
     */
    getFormat: GetFormatFn;
  }
];

/**
 * Hook that returns a function for formatting a date value using
 * internationalized date formats.
 *
 * ```typescript
 * const [format, { DateTimeFormat }] = useDateFormat();
 *
 * const date = new Date();
 *
 * format(date, DateTimeFormat.Long); // returns date formatted as a string using `DateTimeFormat.Long` format
 * ```
 */
export const useDateFormat = (): UseDateFormatResponse => {
  const [t] = useTranslation('date-formats');

  const getFormat: GetFormatFn = (date, format) => {
    return t(getTranslationKey(date, format));
  };

  const formatFn: FormatFn = (date, format, options) => {
    const translationKey = getTranslationKey(date, format);
    const translatedFormat = getFormat(date, format);

    // If the date-formats translations are not loaded, just return the
    // translation key
    if (translatedFormat === translationKey) {
      return translationKey;
    }

    return dateFnsFormat(date, translatedFormat, options);
  };

  return [
    formatFn,
    {
      DateTimeFormat,
      DateFormat,
      getFormat,
    },
  ];
};

/**
 * Gets the appropriate format translation key for the given date and format.
 *
 * When using relative formats the resulting key is variable depending on the
 * given date.
 *
 * @param date
 * @param format
 */
const getTranslationKey = (date: Date, format: Format): string => {
  if (format === DateTimeFormat.RelativeAbbreviated) {
    return isToday(date) ? `${format}.today` : `${format}.other`;
  }
  return format;
};
