import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Overwrite } from 'utility-types';

export type DatePickerProps = Overwrite<
  ReactDatePickerProps,
  {
    onChange: (date: Date | null) => void;
  }
>;

/**
 * Renders a DatePicker -a customized ReactDatePicker from the 'react-datepicker' library.
 */
export const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>(
  (props, ref) => {
    return (
      <ReactDatePicker
        {...props}
        popperClassName="voleer-datepicker"
        ref={ref}
      />
    );
  }
);
