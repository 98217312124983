import { DependencyList, EffectCallback, useEffect } from 'react';
import { usePreviousUntil } from '@voleer/react-hooks';
import { useFormikContext } from 'formik';

/**
 * Run an effect each time an invalid Formik form has been submitted.
 */
export const useAfterInvalidSubmitEffect = (
  effect: EffectCallback,
  deps: DependencyList = []
) => {
  const formik = useFormikContext();

  // Only count submits after the form had been submitted and is invalid
  const [invalidSubmitCount] = usePreviousUntil(formik.submitCount, {
    condition: !formik.isSubmitting && !formik.isValidating,
  });

  useEffect(
    () => {
      // Skip if the form has not yet been submitted
      if (!invalidSubmitCount) {
        return;
      }

      // Skip if the form is currently submitting
      if (formik.isSubmitting) {
        return;
      }

      // Skip if the form is currently validating
      if (formik.isValidating) {
        return;
      }

      // Skip if the form is currently valid
      if (formik.isValid) {
        return;
      }

      return effect();
    },
    // Ignore other deps since we only want to run this effect after each submit
    // or when the provided deps change
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps.concat([invalidSubmitCount])
  );
};
