import { tryFormatDate, tryParseISO } from '@common/utils';
import {
  FormDateInputFragment,
  FormDateTimeInputFragment,
  FormEmailInputFragment,
  FormFileInputFragment,
  FormIntegrationFragment,
  FormMultiSelectFragment,
  FormNumericInputFragment,
  FormSelectFragment,
  FormTextAreaFragment,
  FormTextInputCaseType,
  FormTextInputFragment,
  FormTimeInputFragment,
  FormUrlInputFragment,
} from '@generated/graphql-code-generator';
import { ensureUnreachable } from '@voleer/types';
import {
  ShapeValidatorSchema,
  Validator,
  composeValidators,
  containsSubstrings,
  createShapeValidator,
  createValidator,
  formikValidator,
  isDate,
  isDateAfter,
  isDateBefore,
  isGreaterThanOrEqualTo,
  isLessThanOrEqualTo,
  isNonEmptyArray,
  isNullorUndefined,
  isPresent,
  lengthGreaterThanOrEqualTo,
  lengthLessThanOrEqualTo,
  matchesRegex,
  or,
} from '@voleer/validators';
import { constant } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  isAfter,
  isBefore,
  isEmail,
  isEmpty,
  isFloat,
  isInt,
  isLowercase,
  isNumeric,
  isURL,
  isUppercase,
} from 'validator';
import { WORKFLOW_FORM_DATETIME_FORMAT } from '../utils';
import { DateInputValue, FormFragment, TimeInputValue, fieldsOf } from '.';

/**
 * Creates a Formik validator function for the given form.
 */
export const useValidatorFor = (form: FormFragment) => {
  const [t] = useTranslation('features/workflows/components/Validation');

  /**
   * Returns a Validator for the EmailInput field.
   */
  const emailFieldValidator = (
    formField: FormEmailInputFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }
    validationArray.push(
      createValidator(or(isEmail, isEmpty), t('email-type-message'))
    );
    if (formField.allowedDomains && formField.allowedDomains.length > 0) {
      validationArray.push(
        createValidator(
          or(containsSubstrings(formField.allowedDomains), isEmpty),
          t('email-invalid-message', {
            allowedURLs: formField.allowedDomains.join(', '),
          })
        )
      );
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the FileInput field.
   */
  const fileFieldValidator = (
    formField: FormFileInputFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }

    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the URLInput field.
   */
  const urlFieldValidator = (
    formField: FormUrlInputFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }
    validationArray.push(
      createValidator(or(isURL, isEmpty), t('url-type-message'))
    );
    if (formField.allowedDomains && formField.allowedDomains.length > 0) {
      validationArray.push(
        createValidator(
          or(containsSubstrings(formField.allowedDomains), isEmpty),
          t('url-invalid-message', {
            allowedURLs: formField.allowedDomains.join(', '),
          })
        )
      );
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the NumericInput field.
   */
  const numericFieldValidator = (
    formField: FormNumericInputFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }
    validationArray.push(
      createValidator(or(isNumeric, isEmpty), t('numeric-type-message'))
    );
    if (formField.decimal) {
      validationArray.push(
        createValidator(or(isFloat, isEmpty), t('numeric-decimal-message'))
      );
    } else {
      validationArray.push(
        createValidator(or(isInt, isEmpty), t('numeric-integer-message'))
      );
    }
    if (typeof formField.minValue === 'number') {
      validationArray.push(
        createValidator(
          or(isGreaterThanOrEqualTo(formField.minValue), isEmpty),
          t('numeric-greater-than-message', { minValue: formField.minValue })
        )
      );
    }
    if (typeof formField.maxValue === 'number') {
      validationArray.push(
        createValidator(
          or(isLessThanOrEqualTo(formField.maxValue), isEmpty),
          t('numeric-less-than-message', { maxValue: formField.maxValue })
        )
      );
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a validator for if the regex provided by the user is invalid.
   */
  const fieldRegexValidator = (regex: string) => {
    try {
      new RegExp(regex);
    } catch (e) {
      return createValidator(constant(false), `${e}`);
    }
    // Message intentionally left empty since it will never occur
    return createValidator(constant(true), '');
  };

  /**
   * Returns a Validator for the TextInput field.
   */
  const textInputFieldValidator = (
    formField: FormTextInputFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.regex) {
      validationArray.push(fieldRegexValidator(formField.regex));
    }
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }
    if (formField.regex) {
      validationArray.push(
        createValidator(
          or(matchesRegex(formField.regex), isEmpty),
          formField.validationMessage || t('text-not-expected-pattern')
        )
      );
    }
    if (formField.case === FormTextInputCaseType.Lower) {
      validationArray.push(
        createValidator(isLowercase, t('text-lowercase-message'))
      );
    }
    if (formField.case === FormTextInputCaseType.Upper) {
      validationArray.push(
        createValidator(isUppercase, t('text-uppercase-message'))
      );
    }
    if (typeof formField.minLength === 'number') {
      validationArray.push(
        createValidator(
          or(lengthGreaterThanOrEqualTo(formField.minLength), isEmpty),
          t('text-too-short-message', { minLength: formField.minLength })
        )
      );
    }
    if (typeof formField.maxLength === 'number') {
      validationArray.push(
        createValidator(
          or(lengthLessThanOrEqualTo(formField.maxLength), isEmpty),
          t('text-too-long-message', { maxLength: formField.maxLength })
        )
      );
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the FormMultiSelect field.
   */
  const multiSelectFieldValidator = (
    formField: FormMultiSelectFragment
  ): Validator<string[]> => {
    const validationArray: Validator<string[]>[] = [];
    if (formField.required) {
      validationArray.push(
        createValidator(isNonEmptyArray, t('required-message'))
      );
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the select field.
   */
  const selectFieldValidator = (
    formField: FormIntegrationFragment | FormSelectFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the text area field.
   */
  const textAreaFieldValidator = (
    formField: FormTextAreaFragment
  ): Validator<string> => {
    const validationArray: Validator<string>[] = [];
    if (formField.required) {
      validationArray.push(createValidator(isPresent, t('required-message')));
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the date input field.
   */
  const dateInputFieldValidator = (
    formField: FormDateInputFragment
  ): Validator<DateInputValue> => {
    const validationArray: Validator<DateInputValue>[] = [];
    if (formField.required) {
      validationArray.push(
        createValidator(value => isPresent(value.value), t('required-message'))
      );
    }
    validationArray.push(
      createValidator(
        or(
          value => isDate(value.value),
          value => isEmpty(value.value)
        ),
        t('date-type-message')
      )
    );
    if (formField.required && formField.includeTimezone) {
      validationArray.push(
        createValidator(
          value => isPresent(value.timezone),
          t('timezone-required-message')
        )
      );
    }
    if (formField.minDate) {
      validationArray.push(
        createValidator(
          or(
            value => isAfter(value.value, formField.minDate || undefined),
            value => isEmpty(value.value)
          ),
          t('input-before-min-date', { minDate: formField.minDate })
        )
      );
    }
    if (formField.maxDate) {
      validationArray.push(
        createValidator(
          or(
            value => isBefore(value.value, formField.maxDate || undefined),
            value => isEmpty(value.value)
          ),
          t('input-after-max-date', {
            textVal: formField.value,
            maxDate: formField.maxDate,
          })
        )
      );
    }
    return composeValidators(validationArray);
  };

  /**
   * Returns a Validator for the time input field.
   */
  const timeInputFieldValidator = (
    formField: FormTimeInputFragment
  ): Validator<TimeInputValue> => {
    const TIME_REGEX_PATTERN = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const validationArray: Validator<TimeInputValue>[] = [];
    if (formField.required) {
      validationArray.push(
        createValidator(value => isPresent(value.value), t('required-message'))
      );
    }
    validationArray.push(
      createValidator(
        or(
          value => matchesRegex(TIME_REGEX_PATTERN)(value.value),
          value => isEmpty(value.value)
        ),
        t('time-type-message')
      )
    );

    return composeValidators(validationArray);
  };

  const dateTimeInputFieldValidator = (
    formField: FormDateTimeInputFragment
  ): Validator<Date> => {
    const validationArray: Validator<Date>[] = [];
    if (formField.required) {
      validationArray.push(
        createValidator(
          value => !isNullorUndefined(value),
          t('required-message')
        )
      );
    }
    if (formField.minDateTime) {
      const minDateTime = tryParseISO(formField.minDateTime);
      if (minDateTime) {
        validationArray.push(
          createValidator(
            or(isDateAfter(minDateTime), isNullorUndefined),
            t('input-after-min-date', {
              minDate: tryFormatDate(
                minDateTime,
                WORKFLOW_FORM_DATETIME_FORMAT
              ),
            })
          )
        );
      }
    }
    if (formField.maxDateTime) {
      const maxDateTime = tryParseISO(formField.maxDateTime);
      if (maxDateTime) {
        validationArray.push(
          createValidator(
            or(isDateBefore(maxDateTime), isNullorUndefined),
            t('input-before-max-date', {
              maxDate: tryFormatDate(
                maxDateTime,
                WORKFLOW_FORM_DATETIME_FORMAT
              ),
            })
          )
        );
      }
    }
    return composeValidators(validationArray);
  };

  const schema = fieldsOf(form).reduce((acc, field) => {
    switch (field.__typename) {
      case 'FormNumericInput':
        acc[field.name] = numericFieldValidator(field);
        return acc;
      case 'FormEmailInput':
        acc[field.name] = emailFieldValidator(field);
        return acc;
      case 'FormFileInput':
        acc[field.name] = fileFieldValidator(field);
        return acc;
      case 'FormUrlInput':
        acc[field.name] = urlFieldValidator(field);
        return acc;
      case 'FormTextInput':
        acc[field.name] = textInputFieldValidator(field);
        return acc;
      case 'FormMultiSelect':
        acc[field.name] = multiSelectFieldValidator(field);
        return acc;
      case 'FormSelect':
      case 'FormIntegration':
        acc[field.name] = selectFieldValidator(field);
        return acc;
      case 'FormTextArea':
        acc[field.name] = textAreaFieldValidator(field);
        return acc;
      case 'FormDateInput':
        acc[field.name] = dateInputFieldValidator(field);
        return acc;
      case 'FormTimeInput':
        acc[field.name] = timeInputFieldValidator(field);
        return acc;
      case 'FormDateTimeInput':
        acc[field.name] = dateTimeInputFieldValidator(field);
        return acc;
      case 'FormCheckbox':
        return acc;
      default:
        ensureUnreachable(field);
        return acc;
    }
  }, {} as ShapeValidatorSchema);
  return formikValidator(createShapeValidator(schema));
};
