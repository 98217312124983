import React, { useMemo } from 'react';
import { AppLayout } from '@common/components';
import { urlFor } from '@common/utils';
import {
  CancelRunModal,
  canCancelRun,
  isSaveableTemplateConfiguration,
  isTemplateConfigurationDeleted,
  useCancelRunModal,
} from '@features/workflows';
import {
  TemplateConfiguration,
  TemplateMetadata,
  TemplateMetadataType,
  WorkflowInstanceViewInstanceFragment,
} from '@generated/graphql-code-generator';
import { ensureUnreachable } from '@voleer/types';
import { Link } from '@voleer/ui-kit';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Prop types for the `Toolbar` component.
 */
type ToolbarProps = Readonly<{
  instance: WorkflowInstanceViewInstanceFragment | null | undefined;
  templateConfiguration:
    | (Pick<TemplateConfiguration, 'exposureLevel' | 'id' | 'status'> & {
        templateMetadata?: Pick<TemplateMetadata, 'type'> | null;
      })
    | null
    | undefined;
  workflowInstanceId: string;
  workspaceId: string;
}>;

/**
 * Renders the page content toolbar in the `WorkflowInstancePage`.
 */
export const Toolbar: React.FC<ToolbarProps> = ({
  instance,
  templateConfiguration,
  workflowInstanceId,
  workspaceId,
}) => {
  const [t] = useTranslation('pages/WorkflowInstancePage');

  const { openCancelRunModal, cancelRunModalProps } = useCancelRunModal();

  // Determine UI elements based on the template metadata type
  const { configurationUrl, viewConfigurationButtonLabel } = useMemo(() => {
    let configurationUrl = '';
    let viewConfigurationButtonLabel = '';

    if (
      !!templateConfiguration?.templateMetadata?.type &&
      !!templateConfiguration?.id &&
      !!workspaceId &&
      !isTemplateConfigurationDeleted(templateConfiguration.status)
    ) {
      switch (templateConfiguration.templateMetadata.type) {
        case TemplateMetadataType.Automation:
          configurationUrl = urlFor('templateConfiguration')({
            templateConfigurationId: templateConfiguration.id,
            workspaceId,
          });
          viewConfigurationButtonLabel = t(
            'toolbar.view-btn.view-template-configuration'
          );
          break;

        case TemplateMetadataType.DataSet:
          configurationUrl = urlFor('dataSet')({
            dataSetId: templateConfiguration.id,
            workspaceId,
          });
          viewConfigurationButtonLabel = t('toolbar.view-btn.view-dataset');
          break;

        case TemplateMetadataType.Unknown:
          break;

        default:
          ensureUnreachable(templateConfiguration.templateMetadata.type);
          break;
      }
    }

    return {
      configurationUrl,
      viewConfigurationButtonLabel,
    };
  }, [
    t,
    templateConfiguration?.id,
    templateConfiguration?.templateMetadata?.type,
    templateConfiguration?.status,
    workspaceId,
  ]);

  return (
    <AppLayout.Toolbar>
      {!isSaveableTemplateConfiguration(templateConfiguration) &&
        !!viewConfigurationButtonLabel &&
        !!configurationUrl && (
          <Link to={configurationUrl} variation="unstyled">
            <Button
              label={viewConfigurationButtonLabel}
              primary={true}
              size="small"
            />
          </Link>
        )}

      {canCancelRun(instance?.status) && (
        <>
          <Button
            label={t('cancel-run-button')}
            onClick={() =>
              openCancelRunModal({
                workspaceId,
                instanceId: workflowInstanceId,
              })
            }
            size="small"
          />
          <CancelRunModal {...cancelRunModalProps} />
        </>
      )}
    </AppLayout.Toolbar>
  );
};
