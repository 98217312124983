import React from 'react';
import { PropsOf } from '@voleer/types';
import { Modal, ModalProps } from '@voleer/ui-kit';
import { Box, Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { ScheduledJobForm, ScheduledJobFormProps } from '..';

type TemplateConfigurationFieldProps = PropsOf<
  typeof ScheduledJobForm.TemplateConfigurationField
>;

export type AddScheduledJobModalProps = Pick<ModalProps, 'open'> & {
  /**
   * List of template configuration options for the user to select from.
   */
  templateConfigurationOptions: TemplateConfigurationFieldProps['options'];

  /**
   * Callback called when the form is submitted.
   */
  onSubmit: ScheduledJobFormProps['onSubmit'];

  /**
   * Callback called when the user clicks cancel.
   */
  onCancel: () => void;
};

/**
 * Modal form for adding a scheduled job.
 */
export const AddScheduledJobModal: React.FC<AddScheduledJobModalProps> = ({
  open,
  onSubmit,
  onCancel,
  templateConfigurationOptions,
}) => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/AddScheduledJobModal'
  );

  return (
    <Modal onClickOutside={onCancel} onEsc={onCancel} open={open}>
      <Modal.Header>{t('title')}</Modal.Header>
      <Box fill={true} overflow="auto">
        <ScheduledJobForm onSubmit={onSubmit}>
          <Modal.Body pad={{ top: 'medium' }}>
            <ScheduledJobForm.Fields>
              <ScheduledJobForm.TemplateConfigurationField
                options={templateConfigurationOptions}
              />
              <ScheduledJobForm.TimezoneField />
              <ScheduledJobForm.StartDateField />
              <ScheduledJobForm.ScheduleFields />
              <ScheduledJobForm.EndTypeField />
            </ScheduledJobForm.Fields>
          </Modal.Body>
          <Modal.Footer>
            <Button label={t('buttons.cancel.label')} onClick={onCancel} />
            <ScheduledJobForm.SubmitButton label={t('buttons.submit.label')} />
          </Modal.Footer>
        </ScheduledJobForm>
      </Box>
    </Modal>
  );
};
