import React from 'react';
import {
  AppLayout,
  PageContent,
  RouteTab,
  RouteTabs,
} from '@common/components';
import { useTranslation } from 'react-i18next';
import {
  NotificationsHistoryTab,
  NotificationsSettingsTab,
} from './components';

/**
 * Renders the page for managing notifications and view notification history.
 */
export const NotificationsPage: React.FC = () => {
  const [t] = useTranslation('pages/NotificationsPage');

  return (
    <AppLayout title={t('page-title')}>
      <PageContent>
        <RouteTabs justify="start">
          <RouteTab queryParamValue="settings" title={t('tab-names.settings')}>
            <NotificationsSettingsTab />
          </RouteTab>
          <RouteTab queryParamValue="history" title={t('tab-names.history')}>
            <NotificationsHistoryTab />
          </RouteTab>
        </RouteTabs>
      </PageContent>
    </AppLayout>
  );
};
