import React, { useContext } from 'react';
import {
  ChargeBeePortalQuery,
  useChargeBeePortalLazyQuery,
} from '@generated/graphql-code-generator';
import { Button, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { ChargeBeeInstanceContext } from '../ChargeBeeInstanceContextProvider';

/**
 * Renders a button used to open a Chargebee portal session.
 */
export const ChargeBeePortalSessionButton: React.FC = () => {
  const [t] = useTranslation(
    'features/chargebee/components/ChargeBeePortalSessionButton'
  );

  const { chargeBeeInstance } = useContext(ChargeBeeInstanceContext);

  const onCompleteChargeBeePortalQuery = (data: ChargeBeePortalQuery) => {
    if (!chargeBeeInstance) {
      return;
    }

    chargeBeeInstance.setPortalSession(() => {
      // Chargebee requires that setPortalSession return a promise that eventually resolves to the portal session object.
      // https://www.chargebee.com/checkout-portal-docs/api-portal.html#passing-portal-session-object-to-chargebee-instance
      return Promise.resolve(data.getChargeBeeSession);
    });

    const portalSession = chargeBeeInstance.createChargebeePortal();
    portalSession.open({
      close() {
        // Log out of the portal session when the user closes the portal.
        chargeBeeInstance.logout();
      },
    });
  };

  const [chargeBeePortalQuery, { loading: chargeBeePortalQueryLoading }] =
    useChargeBeePortalLazyQuery({
      onCompleted: onCompleteChargeBeePortalQuery,
      // Fetch a new ChargeBee portal session object every time `chargeBeePortalQuery` is called.
      fetchPolicy: 'network-only',
    });

  return (
    <Button
      disabled={chargeBeePortalQueryLoading}
      label={<Text weight="bold">{t('label')}</Text>}
      onClick={() => {
        chargeBeePortalQuery();
      }}
      primary={true}
    />
  );
};
