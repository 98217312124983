import { AxiosInstance } from 'axios';
import sanitize from 'sanitize-filename';

const DEFAULT_NAME = 'logs';

type SearchParamProps = {
  axiosInstance: AxiosInstance;
  workspaceId: string;
  instanceId: string;
  stepId: string;
  filename?: string;
};
/**
 * Open the browser download dialog box to download the logs of the given step.
 *
 * @param props.workspaceId Id of the workspace
 * @param props.instanceId Id of the instance for the step
 * @param props.stepId Id of the step to download the logs from
 * @param props.filename Name of the file that will be downloaded, "logs" if left empty
 */
export const downloadStepLogs = async (props: SearchParamProps) => {
  const { axiosInstance, workspaceId, instanceId, stepId, filename } = props;
  const searchParams = new URLSearchParams({
    workspaceId,
    instanceId,
    stepId,
  });
  const sanitizedName = filename ? sanitize(filename) : DEFAULT_NAME;

  // Download the file
  const response = await axiosInstance.get(
    `/download/stepLogs?${searchParams}`
  );

  // Prompt the download browser window
  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${sanitizedName}.txt`);
  document.body.appendChild(link);
  link.click();
};
