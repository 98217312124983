import React from 'react';
import { parseCronExpression } from '@voleer/cron-parse';
import { PropsOf } from '@voleer/types';
import { Icon } from '@voleer/ui-kit';
import { format } from 'date-fns';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { BsArrowRepeat, BsAt } from 'react-icons/bs';
import { FaCalendarAlt, FaRegClock } from 'react-icons/fa';
import {
  toDailySchedule,
  toHourlySchedule,
  toMinutesSchedule,
  toMonthlySchedule,
  toWeeklySchedule,
} from '../../utils';
import { WeekSchedule } from './components/WeekSchedule';
import { ScheduledJobProp } from './interface';

const ScheduleContainer: React.FC<PropsOf<typeof Box>> = props => (
  <Box
    align="center"
    background="light-2"
    direction="row"
    pad={{ horizontal: 'xsmall' }}
    round="25px"
    {...props}
  />
);

type AtHoursProps = PropsOf<typeof Box> & {
  date: Date;
};

/**
 * Renders hours portion of scheduled job schedule
 */
const AtHours: React.FC<AtHoursProps> = ({ date, ...boxProps }) => {
  return (
    <Box align="center" direction="row" {...boxProps}>
      <Icon icon={BsAt} />
      <Text>{format(date, 'p')}</Text>
    </Box>
  );
};

const DayOfMonth: React.FC<{ date: Date }> = ({ date }) => {
  return (
    <Box align="center" direction="row">
      <Icon icon={FaCalendarAlt} />
      <Text margin={{ left: 'xsmall' }}>{format(date, 'do')}</Text>
    </Box>
  );
};

export type ScheduledJobScheduledProps = {
  scheduledJob: ScheduledJobProp;
};

/**
 * Renders detailed scheduled jobs schedule
 */
export const ScheduledJobSchedule: React.FC<ScheduledJobScheduledProps> = ({
  scheduledJob,
}) => {
  const [t] = useTranslation(
    'features/scheduled-jobs/components/ScheduledJobSchedule'
  );
  if (!scheduledJob.pattern) {
    return <div>-</div>;
  }

  const parsed = parseCronExpression(scheduledJob.pattern);

  if (parsed) {
    const minutesSchedule = toMinutesSchedule(parsed);
    if (minutesSchedule) {
      return (
        <ScheduleContainer>
          <Icon icon={BsArrowRepeat} />
          <Text margin={{ left: 'xsmall' }}>
            {t('minute', { count: minutesSchedule.minutes })}
          </Text>
        </ScheduleContainer>
      );
    }

    const hourlySchedule = toHourlySchedule(parsed);
    if (hourlySchedule) {
      return (
        <ScheduleContainer>
          <Icon icon={BsArrowRepeat} />
          <Text margin={{ left: 'xsmall' }}>
            {t('hour', { count: hourlySchedule.hours })}
          </Text>
        </ScheduleContainer>
      );
    }

    const weeklySchedule = toWeeklySchedule(parsed);
    if (weeklySchedule) {
      const date = new Date(
        2020,
        1,
        1,
        weeklySchedule.hour,
        weeklySchedule.minute
      );
      return (
        <ScheduleContainer>
          <WeekSchedule activeDays={weeklySchedule.dayOfWeek} />
          <AtHours date={date} />
        </ScheduleContainer>
      );
    }

    const monthlySchedule = toMonthlySchedule(parsed);
    if (monthlySchedule) {
      const date = new Date(
        2020,
        1,
        monthlySchedule.dayOfMonth,
        monthlySchedule.hour,
        monthlySchedule.minute
      );
      return (
        <ScheduleContainer>
          <DayOfMonth date={date} />
          <AtHours date={date} margin={{ left: 'xxsmall' }} />
        </ScheduleContainer>
      );
    }

    const dailySchedule = toDailySchedule(parsed);
    if (dailySchedule) {
      const date = new Date(
        2020,
        1,
        1,
        dailySchedule.hour,
        dailySchedule.minute
      );
      return (
        <ScheduleContainer>
          <Icon icon={FaRegClock} />
          <Text margin={{ left: 'xsmall' }}>{format(date, 'p')}</Text>
        </ScheduleContainer>
      );
    }
  }

  return <div>{scheduledJob.pattern}</div>;
};
