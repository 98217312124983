import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: string;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  Long: any;
  ULong: any;
  Uri: any;
};

/** Account status to differentiate activity states for the Account. */
export enum AccountStatus {
  /** Default value. */
  Unknown = 'UNKNOWN',
  /** The Tenant account is currently in the trial period. */
  Trial = 'TRIAL',
  /** The account is active. */
  Active = 'ACTIVE',
  /** Trial has expired. */
  TrialExpired = 'TRIAL_EXPIRED',
  /** Subscription has been paused. */
  Paused = 'PAUSED',
  /** Subscription has been canceled. */
  Canceled = 'CANCELED',
  /** Tenant has been deactivated (can still be re-activated). */
  Deactivated = 'DEACTIVATED',
  /** Most tenant information has been removed; only tombstone remains for records. */
  Deprovisioned = 'DEPROVISIONED'
}

/** The input values defining an internal Package Permission to add */
export type AddInternalPackagePermissionInput = {
  /** The name or note to display regarding the package and version scope combination of this permission. */
  displayName: Scalars['String'];
  /** The version range this permission represents. */
  versionScope: Scalars['String'];
  /** The package name referenced by this request, used to derive other relevant data about the permission. */
  packageName: Scalars['String'];
  /** The target of the sharing process */
  sharingTarget: InternalSharingTarget;
};

/** Defines some values fed back to a user who shares a package internally. */
export type AddInternalPackagePermissionResponse = {
  __typename: 'AddInternalPackagePermissionResponse';
  /** The package name shared. */
  highestSharedPackageName: Scalars['String'];
  /** The most recent date of publish of the highest currently shared package. */
  highestSharedPackagePublishDate: Scalars['DateTime'];
  /** The highest version of currently published packages that will be shared. */
  highestSharedPackageVersion: Scalars['String'];
  /** The version range being shared. */
  versionScope: Scalars['String'];
};

/** The input values defining a Package Permission to add */
export type AddPackagePermissionInput = {
  /** The name or note to display regarding the package and version scope combination of this permission. */
  displayName: Scalars['String'];
  /** The version range this permission represents. */
  versionScope: Scalars['String'];
  /** The package name referenced by this request, used to derive other relevant data about the permission. */
  packageName: Scalars['String'];
};

/** Defines some values fed back to a user who creates a package permission. */
export type AddPackagePermissionResponse = {
  __typename: 'AddPackagePermissionResponse';
  /** The package name shared. */
  highestSharedPackageName: Scalars['String'];
  /** The most recent date of publish of the highest currently shared package. */
  highestSharedPackagePublishDate: Scalars['DateTime'];
  /** The highest version of currently published packages that will be shared. */
  highestSharedPackageVersion: Scalars['String'];
  /** The invitation code to be shared with the recipient of this permission. Unused if the target was internal. */
  invitationCode: Scalars['String'];
  /** The version range being shared. */
  versionScope: Scalars['String'];
};

/** Add tenant Member input. */
export type AddTenantMemberInput = {
  /** First name of the user to add. */
  firstName: Scalars['String'];
  /** Last name of the user to add. */
  lastName: Scalars['String'];
  /** Email address of the user to add. */
  emailAddress: Scalars['String'];
  /** Tenant role of the user to add. */
  role: TenantMemberRole;
};

/** Add tenant member result. */
export type AddTenantMemberResult = {
  __typename: 'AddTenantMemberResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<TenantsError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
  /** The tenant member that was created. */
  tenantMember?: Maybe<TenantMember>;
};

/** Describes input for calling AdminSetPackagePermission graph method. */
export type AdminSetPackagePermissionInput = {
  /** The name or note to display regarding the package and version scope combination of this permission. */
  displayName: Scalars['String'];
  /** The version range this permission represents. */
  versionScope: Scalars['String'];
  /** The package name referenced by this request, used to derive other relevant data about the permission. */
  packageName: Scalars['String'];
  /** The tenant name of the owner of the referenced package. */
  packageOwnerTenantName: Scalars['String'];
  /** The response to establish for the permission. */
  audienceResponse: AudienceResponseState;
  /** Whether the request should replace existing permissions for the given tenant to the given package. */
  exclusive: Scalars['Boolean'];
  /** The name of the tenant whose permissions are being configured. */
  targetTenantName: Scalars['String'];
};

/** The response returned by the AdminSetPackagePermission graphql request */
export type AdminSetPackagePermissionResponse = {
  __typename: 'AdminSetPackagePermissionResponse';
  /** The reply to the sharing handshake. */
  audienceResponse: AudienceResponseState;
  /** The package name shared. */
  highestSharedPackageName: Scalars['String'];
  /** The most recent date of publish of the highest currently shared package. */
  highestSharedPackagePublishDate: Scalars['DateTime'];
  /** The highest version of currently published packages that will be shared. */
  highestSharedPackageVersion: Scalars['String'];
  /** The invitation code to be shared with the recipient of this permission. */
  invitationCode: Scalars['String'];
  /** The version range being shared. */
  versionScope: Scalars['String'];
};

/** Input for a request to analyze the manifests. */
export type AnalyzeTemplateInput = {
  /** The json-serialized package manifest */
  packageManifest: Scalars['String'];
  /** The json-serialized template manifest */
  templateManifest: Scalars['String'];
  /** The json-serialized workflow manifest */
  workflowManifest: Scalars['String'];
  /** The json-serialized configuration manifest */
  configurationManifest?: Maybe<Scalars['String']>;
};

/** Result for a request to analyze the template. */
export type AnalyzeTemplateResponse = {
  __typename: 'AnalyzeTemplateResponse';
  /** The configuration diagram url */
  configurationDiagramUrl?: Maybe<Scalars['String']>;
  /** The list of diagnostics from analyzing the manifest */
  diagnostics: Array<Diagnostic>;
  /** The workflow diagram url */
  workflowDiagramUrl?: Maybe<Scalars['String']>;
};

/** The current response state for a . */
export enum AudienceResponseState {
  /** The value is unknown - this is an error condition if present in the Database. */
  Unknown = 'UNKNOWN',
  /** There is no response from the recipient yet. */
  None = 'NONE',
  /** The request was rejected, no sharing is taking place. */
  Rejected = 'REJECTED',
  /** The request was approved, sharing is now possible. */
  Approved = 'APPROVED'
}

/** Defines a contract for different entity types that can be group together in a query result. */
export type AutomationMetadata = LibraryItem & {
  __typename: 'AutomationMetadata';
  /** The ChargeBee item id that this library item is included in */
  chargeBeeItemId?: Maybe<Scalars['String']>;
  /** The data schema dependencies that this template requires in order to run successfully */
  dataSchemaDependencies?: Maybe<DataSchemaMetadataConnection>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** Whether this library item is accessible to the tenant */
  hasTenantAccess: Scalars['Boolean'];
  /** The id */
  id: Scalars['ID'];
  /** The template type */
  libraryItemType: LibraryItemMetadataType;
  /** The name of the template, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the template belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the template */
  readme?: Maybe<Scalars['String']>;
  /** The solution name that this library item is included in */
  solutionName?: Maybe<Scalars['String']>;
  /** The solutions that this library item is under */
  solutions: Array<Scalars['String']>;
  /** The technologies that this library item is under */
  technologies: Array<Scalars['String']>;
};


/** Defines a contract for different entity types that can be group together in a query result. */
export type AutomationMetadataDataSchemaDependenciesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

/** A connection from an object to a list of objects of type `AutomationMetadata`. */
export type AutomationMetadataConnection = {
  __typename: 'AutomationMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<AutomationMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<AutomationMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `AutomationMetadata`. */
export type AutomationMetadataEdge = {
  __typename: 'AutomationMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AutomationMetadata>;
};

/** Input for automation by publishers, package names, package versions, and automation names. */
export type AutomationMetadatasRetrieveInput = {
  /** Filter by publisher names. */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names. */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions. If no versions are specified, the latest viable versions matching other criteria or returned. */
  packageVersions?: Maybe<Array<Scalars['String']>>;
  /** Filter by automation names. */
  names?: Maybe<Array<Scalars['String']>>;
};

/** Metadata for a template. */
export type AutomationTemplate = TemplateMetadata & {
  __typename: 'AutomationTemplate';
  /** The data schema dependencies that this template requires in order to run successfully */
  dataSchemaDependencies?: Maybe<DataSchemaMetadataConnection>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The name of the template, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the template belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the template */
  readme?: Maybe<Scalars['String']>;
  /** The template type */
  type: TemplateMetadataType;
};


/** Metadata for a template. */
export type AutomationTemplateDataSchemaDependenciesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type CancelCandidateDataSetConfigurationDataInput = {
  /** The id of the workspace the dataset configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the dataset */
  dataSetId: Scalars['ID'];
  /** The id of candidate dataset configuration data to cancel */
  candidateConfigurationDataId: Scalars['ID'];
};

export type CancelCandidateTemplateConfigurationDataInput = {
  /** The id of the workspace the template configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the template configuration */
  templateConfigurationId: Scalars['ID'];
  /** The id of candidate template configuration data to cancel */
  candidateConfigurationDataId: Scalars['ID'];
};

/** The input to cancel a custom workflow candidate configuration. */
export type CancelCustomWorkflowIntegrationConfigurationInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Contains the payload of canceling a custom workflow integration candidate configuration. */
export type CancelCustomWorkflowIntegrationConfigurationPayload = {
  __typename: 'CancelCustomWorkflowIntegrationConfigurationPayload';
  /** The custom workflow integration with its candidate configuration canceled. */
  integration?: Maybe<CustomWorkflowIntegration>;
};

export type CancelScheduledJobInput = {
  /** The id of the workspace the template configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the scheduled job to cancel */
  scheduledJobId: Scalars['ID'];
};

export type CancelWorkflowInput = {
  /** The id of the workspace the workflow belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the instance */
  instanceId: Scalars['ID'];
};

/** Change password input. */
export type ChangePasswordInput = {
  /** The new password. */
  newPassword: Scalars['String'];
  /** The current password. */
  currentPassword: Scalars['String'];
};

/** Represents an authentication . */
export type ChangePasswordResult = {
  __typename: 'ChangePasswordResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<IdentityError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
};

export type ChargeBeePortalSessionObject = {
  __typename: 'ChargeBeePortalSessionObject';
  /** Unique URL for accessing the customer portal. Once accessed, this cannot be reused. */
  access_url: Scalars['String'];
  /** Indicates the current status of the portal session. */
  created_at: Scalars['Float'];
  /** Identifier of the customer. */
  customer_id: Scalars['ID'];
  /** Timestamp indicating when the access URL will expire. Once expired, the URL cannot be used to login into the portal. */
  expires_at: Scalars['Float'];
  /** Unique identifier for the portal session. */
  id: Scalars['ID'];
  /** Object type. Defaults to 'portal_session'. */
  object: Scalars['String'];
  /** Indicates the current status of the portal session. */
  status: Scalars['String'];
  /** Unique pre-authenticated portal session token to access. */
  token: Scalars['String'];
};

/** Check normalized name availability. */
export type CheckNormalizedNameAvailability = {
  __typename: 'CheckNormalizedNameAvailability';
  /** Is the Normalized name available. */
  isNormalizedNameAvailable: Scalars['Boolean'];
};

export type Claim = {
  __typename: 'Claim';
  type: Scalars['String'];
  value: Scalars['String'];
};

export type CompleteDataRequestInput = {
  /** The data request id */
  dataRequestId: Scalars['ID'];
  /** The list of data response values */
  values: Array<DataResponseValue>;
};

export type ConfigurationData = {
  __typename: 'ConfigurationData';
  /** The created on date */
  createdOn: Scalars['DateTime'];
  /** The id */
  id: Scalars['ID'];
  /** The instance used to retrieve the configuration data */
  instance?: Maybe<Instance>;
};

/** User email confirmation input. */
export type ConfirmEmailInput = {
  /** Email verification code for the specified user. */
  verificationCode: Scalars['String'];
  /** The User id. */
  userId: Scalars['String'];
};

/** Email confirmation output. */
export type ConfirmEmailResult = {
  __typename: 'ConfirmEmailResult';
  /** Id of the user that got its email confirmed */
  userId: Scalars['String'];
};

export type ConvertOnDemandTemplateConfigurationInput = {
  /** The id of the workspace the template configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the template configuration to covert */
  templateConfigurationId: Scalars['ID'];
  /** The optional display name to give the converted template configuration */
  displayName?: Maybe<Scalars['String']>;
};

/** Contains the parameters to create an integration of custom workflow type. */
export type CreateCustomWorkflowIntegrationInput = {
  /** The display name. */
  displayName: Scalars['String'];
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
};

/** Contains the payload of creating a custom workflow integration. */
export type CreateCustomWorkflowIntegrationPayload = {
  __typename: 'CreateCustomWorkflowIntegrationPayload';
  /** The created custom workflow integration. */
  integration?: Maybe<CustomWorkflowIntegration>;
};

export type CreateDashboardDirectInput = {
  /** The id of the workspace to create the dashboard in */
  workspaceId: Scalars['ID'];
  /** The dashboard metadata id */
  dashboardMetadataId: Scalars['ID'];
  /** The display name */
  displayName: Scalars['String'];
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The id of the entity creating the dashboard */
  createdById: Scalars['ID'];
  /** The id of the of the template configuration that owns the DataSet the dashboard is dependent on */
  dataSetTemplateConfigurationId: Scalars['ID'];
  /** The file contents as base64 string. */
  fileContent: Scalars['String'];
};

export type CreateDashboardDirectPayload = {
  __typename: 'CreateDashboardDirectPayload';
  /** Payload result */
  dashboard?: Maybe<Dashboard>;
};

export type CreateDashboardInput = {
  /** The id of the workspace to create the dashboard in */
  workspaceId: Scalars['ID'];
  /** The dashboard metadata id */
  dashboardMetadataId: Scalars['ID'];
  /** The display name */
  displayName: Scalars['String'];
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The id of the entity creating the dashboard */
  createdById: Scalars['ID'];
  /** The id of the of the template configuration that owns the DataSet the dashboard is dependent on */
  dataSetTemplateConfigurationId: Scalars['ID'];
};

export type CreateDataSetDirectInput = {
  /** The id of the workspace to create the dataset in */
  workspaceId: Scalars['ID'];
  /** The display name */
  displayName: Scalars['String'];
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The id of the entity creating the dataset */
  createdById: Scalars['ID'];
  /** The json-serialized package manifest */
  packageManifest: Scalars['String'];
  /** The json-serialized template manifest */
  templateManifest: Scalars['String'];
  /** The json-serialized workflow manifest */
  workflowManifest: Scalars['String'];
  /** The data json schema manifest */
  dataSchemaManifest: Scalars['String'];
  /** The json-serialized configuration manifest */
  configurationManifest?: Maybe<Scalars['String']>;
  /** The package patch archive content in base64 */
  packagePatchContent?: Maybe<Scalars['String']>;
  /** The json-serialized workflow runtime options */
  workflowRuntimeOptions?: Maybe<Scalars['String']>;
};

export type CreateDataSetDirectPayload = {
  __typename: 'CreateDataSetDirectPayload';
  /** The configuration diagram url */
  configurationDiagramUrl?: Maybe<Scalars['String']>;
  /** The created dataset */
  dataSet?: Maybe<DataSet>;
  /** The list of diagnostics from analyzing the manifests */
  diagnostics?: Maybe<Array<ManifestDiagnostic>>;
  /** The workflow diagram url */
  workflowDiagramUrl?: Maybe<Scalars['String']>;
};

export type CreateDataSetInput = {
  /** The id of the workspace to create the dataset in */
  workspaceId: Scalars['ID'];
  /** The template metadata id */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The display name */
  displayName: Scalars['String'];
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The id of the entity creating the dataset */
  createdById: Scalars['ID'];
};

export type CreateDataSetZeroRowsPolicyInput = {
  /** The id of the dataset's workspace */
  workspaceId: Scalars['ID'];
  /** The id of the dataset */
  dataSetId: Scalars['ID'];
};

/** The input to create an OAuth2 integration session. */
export type CreateOAuth2IntegrationSessionInput = {
  /** The display name. */
  displayName: Scalars['String'];
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The optional id linking the integration with a specific instance. */
  instanceId?: Maybe<Scalars['ID']>;
  /** The id of the workspace the optional instance linked to the integration belongs to. */
  workspaceId?: Maybe<Scalars['ID']>;
};

/** The result for the mutation to create an OAuth2 integration session. */
export type CreateOAuth2IntegrationSessionResult = {
  __typename: 'CreateOAuth2IntegrationSessionResult';
  /** The integration id. */
  integrationId: Scalars['ID'];
  /** The session id. */
  sessionId: Scalars['String'];
};

/** Create notification subscription input. */
export type CreateRunNotificationSubscriptionInput = {
  /** The type of notification being subscribed to. */
  runNotificationType: RunNotificationType;
  /** The intial status state of the new subscription. */
  status: NotificationSubscriptionStatus;
  /** The workspace id. */
  workspaceId?: Maybe<Scalars['ID']>;
  /** The template configuration id. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The list of notifications channels enabled for the subscription. */
  channelTypes?: Maybe<Array<Maybe<NotificationChannelTypes>>>;
};

/** Contains the payload of creating a run notification subscription. */
export type CreateRunNotificationSubscriptionPayload = {
  __typename: 'CreateRunNotificationSubscriptionPayload';
  /** The created run notification subscription. */
  notificationSubscription?: Maybe<RunNotificationSubscription>;
};

export type CreateScheduledJobInput = {
  /** The id of the workspace the template configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The id template configuration */
  templateConfigurationId: Scalars['ID'];
  /** The optional recurrence pattern in cron format */
  pattern?: Maybe<Scalars['String']>;
  /** The timezone name */
  timezoneName: Scalars['String'];
  /** The optional local start time */
  startTime?: Maybe<Scalars['DateTimeOffset']>;
  /** The optional local end time */
  endTime?: Maybe<Scalars['DateTimeOffset']>;
  /** The optional max recurrence count */
  maxRecurrenceCount?: Maybe<Scalars['Int']>;
};

export type CreateTemplateConfigurationInput = {
  /** The id of the workspace to create the template configuration in */
  workspaceId: Scalars['ID'];
  /** The template metadata id */
  templateMetadataId: Scalars['ID'];
  /** The display name */
  displayName: Scalars['String'];
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The id of the entity creating the template configuration */
  createdById: Scalars['ID'];
};

/** Graphql return object to the create tenant mutation. */
export type CreateTenantResult = {
  __typename: 'CreateTenantResult';
  /** Return Url updated to use the soon to be created tenant domain tenant ui */
  returnUrl: Scalars['String'];
  /** Id of the tenant that was created */
  tenantId: Scalars['String'];
  /** Id of the first member of the tenant that was created */
  userId: Scalars['String'];
};

/** Input for the create workspace mutation. */
export type CreateWorkspaceInput = {
  /** The display name. */
  displayName: Scalars['String'];
  /** The location to create the workspace in. If excluded, defaults to the tenant's location. */
  location?: Maybe<Scalars['String']>;
};

/** Represents the integration of custom workflow type. */
export type CustomWorkflowIntegration = Integration & {
  __typename: 'CustomWorkflowIntegration';
  candidateInstance?: Maybe<Instance>;
  /** The candidate instance id. */
  candidateInstanceId?: Maybe<Scalars['ID']>;
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration display name. */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  instance?: Maybe<Instance>;
  /** The instance id. */
  instanceId?: Maybe<Scalars['ID']>;
  /** The instance's workspace id. */
  instanceWorkspaceId: Scalars['ID'];
  integrationMetadata?: Maybe<IntegrationMetadata>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The integration status. */
  status: IntegrationStatus;
};

export type Dashboard = {
  __typename: 'Dashboard';
  /** The id of the user that created the dashboard */
  createdById: Scalars['ID'];
  /** The created on date */
  createdOn: Scalars['DateTime'];
  dashboardMetadata?: Maybe<DashboardMetadata>;
  /** The dashboard metadata id */
  dashboardMetadataId?: Maybe<Scalars['ID']>;
  dashboardReport?: Maybe<DashboardReport>;
  dataSet?: Maybe<DataSet>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** Whether it has been deleted */
  isDeleted: Scalars['Boolean'];
  /** The status */
  status: DashboardStatus;
  /** The workspace id */
  workspaceId: Scalars['ID'];
};

/** A connection from an object to a list of objects of type `Dashboard`. */
export type DashboardConnection = {
  __typename: 'DashboardConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DashboardEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<Dashboard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Dashboard`. */
export type DashboardEdge = {
  __typename: 'DashboardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Dashboard>;
};

export enum DashboardExportFileFormat {
  Unknown = 'UNKNOWN',
  Pdf = 'PDF',
  Pptx = 'PPTX'
}

export type DashboardExportOperation = {
  __typename: 'DashboardExportOperation';
  /** The unique id of the dashboard */
  dashboardId: Scalars['ID'];
  /** The unique id of the dashboard export operation */
  exportId: Scalars['String'];
  /** The percentage of complete for the export operation */
  percentComplete: Scalars['Int'];
  /** The status of the export operation */
  status: DashboardExportStatus;
};

export type DashboardExportRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the dashboad */
  dashboardId: Scalars['ID'];
  /** The id of the export operation */
  exportId: Scalars['String'];
};

export enum DashboardExportStatus {
  Unknown = 'UNKNOWN',
  NotStarted = 'NOT_STARTED',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}

/** Defines a contract for different entity types that can be group together in a query result. */
export type DashboardMetadata = LibraryItem & {
  __typename: 'DashboardMetadata';
  /** The ChargeBee item id that this library item is included in */
  chargeBeeItemId?: Maybe<Scalars['String']>;
  /** The metadata of the data schema for this data set. */
  dataSchemaMetadata: DataSchemaMetadata;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** Whether this library item is accessible to the tenant */
  hasTenantAccess: Scalars['Boolean'];
  /** The id */
  id: Scalars['ID'];
  /** The template type */
  libraryItemType: LibraryItemMetadataType;
  /** The name of the template, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the template belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the template */
  readme?: Maybe<Scalars['String']>;
  /** The solution name that this library item is included in */
  solutionName?: Maybe<Scalars['String']>;
  /** The solutions that this library item is under */
  solutions: Array<Scalars['String']>;
  /** The technologies that this library item is under */
  technologies: Array<Scalars['String']>;
};

/** A connection from an object to a list of objects of type `DashboardMetadata`. */
export type DashboardMetadataConnection = {
  __typename: 'DashboardMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DashboardMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<DashboardMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `DashboardMetadata`. */
export type DashboardMetadataEdge = {
  __typename: 'DashboardMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DashboardMetadata>;
};

/** Input for dashboard by publishers, package names, package versions, and dashboard names. */
export type DashboardMetadatasRetrieveInput = {
  /** Filter by publisher names. */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names. */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions. If no versions are specified, the latest viable versions matching other criteria or returned. */
  packageVersions?: Maybe<Array<Scalars['String']>>;
  /** Filter by dashboard names. */
  names?: Maybe<Array<Scalars['String']>>;
};

export type DashboardPatchRequest = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  data: EditDashboard;
};

export enum DashboardRefreshStatus {
  Unspecified = 'UNSPECIFIED',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  WaitingForImport = 'WAITING_FOR_IMPORT'
}

export type DashboardReport = {
  __typename: 'DashboardReport';
  /** The access token for dashboard's embeddable visualization */
  embedToken: Scalars['String'];
  /** The URL to the dashboard's embeddable visualization */
  embedUrl: Scalars['String'];
  /** The unique id of the dashboard report service record. */
  id: Scalars['ID'];
  /** The date the latest report data refresh completed. */
  refreshEndTime?: Maybe<Scalars['DateTime']>;
  /** The date the latest report data refresh was started. */
  refreshStartTime?: Maybe<Scalars['DateTime']>;
  /** The current status of the dashboard's embedded visualization dataset refresh. */
  refreshStatus: DashboardRefreshStatus;
  /** The unique id of the dashboard's embeddable visualization */
  reportId: Scalars['ID'];
  /** The message associated with the dashboard's embedded visualization refresh status */
  statusMessage?: Maybe<Scalars['String']>;
};

export enum DashboardStatus {
  Unknown = 'UNKNOWN',
  Pending = 'PENDING',
  Ready = 'READY',
  Provisioning = 'PROVISIONING',
  ProvisioningFailed = 'PROVISIONING_FAILED',
  Deleting = 'DELETING',
  Deleted = 'DELETED'
}

export type DashboardsRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the dashboard metadata the dashboard was created for */
  dashboardMetadataId?: Maybe<Scalars['ID']>;
  /** The statuses of the dashboard */
  statuses?: Maybe<Array<DashboardStatus>>;
};

export type DataRequest = {
  dataValidation?: Maybe<DataValidation>;
  dataValidations?: Maybe<DataValidationConnection>;
  /** The id */
  id: Scalars['ID'];
  /** The data request status */
  status: DataRequestStatus;
  /** The related step */
  step: Step;
  /** The type */
  type: DataRequestType;
};


export type DataRequestDataValidationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DataValidationRetrieveInput;
};

/** A connection from an object to a list of objects of type `DataRequest`. */
export type DataRequestConnection = {
  __typename: 'DataRequestConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DataRequestEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<FormDataRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `DataRequest`. */
export type DataRequestEdge = {
  __typename: 'DataRequestEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FormDataRequest>;
};

export type DataRequestRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The ids */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The step ids */
  stepIds?: Maybe<Array<Scalars['ID']>>;
  /** The request types */
  types?: Maybe<Array<DataRequestType>>;
  /** The statuses */
  statuses?: Maybe<Array<DataRequestStatus>>;
  /** The instance ids */
  instanceIds?: Maybe<Array<Scalars['ID']>>;
};

export enum DataRequestStatus {
  Unknown = 'UNKNOWN',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Validating = 'VALIDATING',
  Failed = 'FAILED',
  Canceled = 'CANCELED'
}

export enum DataRequestType {
  Unknown = 'UNKNOWN',
  Form = 'FORM'
}

export type DataResponseValue = {
  /** The unique name of the data request component */
  name: Scalars['ID'];
  /** The value */
  value?: Maybe<Scalars['String']>;
};

/** Metadata for a template's data schema. */
export type DataSchemaMetadata = {
  __typename: 'DataSchemaMetadata';
  compatibleDataSetTemplate?: Maybe<DataSetMetadata>;
  /** The id */
  id: Scalars['ID'];
  /** The package name */
  packageName: Scalars['ID'];
  /** The publisher (tenant) who published this package */
  publisher: Publisher;
  /** The name of the template, unique within the package */
  templateName: Scalars['String'];
  /** The data schema version */
  version: Scalars['String'];
};

/** A connection from an object to a list of objects of type `DataSchemaMetadata`. */
export type DataSchemaMetadataConnection = {
  __typename: 'DataSchemaMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DataSchemaMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<DataSchemaMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `DataSchemaMetadata`. */
export type DataSchemaMetadataEdge = {
  __typename: 'DataSchemaMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataSchemaMetadata>;
};

export type DataSet = {
  __typename: 'DataSet';
  candidateData?: Maybe<ConfigurationData>;
  configurationInstance?: Maybe<Instance>;
  /** The data set connection string */
  connectionString?: Maybe<Scalars['String']>;
  createdBy?: Maybe<TenantMember>;
  /** The id of the user that created the DataSet configuration */
  createdById: Scalars['ID'];
  /** Type of the entity that created the DataSet configuration */
  createdByType: TemplateConfigurationCreatedByType;
  /** The created on date */
  createdOn: Scalars['DateTime'];
  currentSchedule?: Maybe<ScheduledJob>;
  data?: Maybe<ConfigurationData>;
  dependentTemplateConfigurations?: Maybe<TemplateConfigurationConnection>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  highestPatchTemplateMetadataId?: Maybe<Scalars['ID']>;
  /** The id */
  id: Scalars['ID'];
  /** Whether it has been deleted */
  isDeleted: Scalars['Boolean'];
  lastRefreshRun?: Maybe<Instance>;
  /** The data set read-only connection string */
  readOnlyConnectionString?: Maybe<Scalars['String']>;
  refreshRuns?: Maybe<InstanceConnection>;
  /** The status */
  status: DataSetStatus;
  templateMetadata?: Maybe<AutomationTemplate | DataSetTemplate>;
  /** The template metadata id */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The workspace id */
  workspaceId: Scalars['ID'];
};


export type DataSetDependentTemplateConfigurationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type DataSetRefreshRunsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: InstanceRetrieveInput;
};

/** A connection from an object to a list of objects of type `DataSet`. */
export type DataSetConnection = {
  __typename: 'DataSetConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DataSetEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<DataSet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `DataSet`. */
export type DataSetEdge = {
  __typename: 'DataSetEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataSet>;
};

/** Defines a contract for different entity types that can be group together in a query result. */
export type DataSetMetadata = LibraryItem & {
  __typename: 'DataSetMetadata';
  /** The ChargeBee item id that this library item is included in */
  chargeBeeItemId?: Maybe<Scalars['String']>;
  /** The metadata of the data schema for this data set. */
  dataSchemaMetadata: DataSchemaMetadata;
  /** The default schedule pattern */
  defaultSchedulePattern?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** Whether this library item is accessible to the tenant */
  hasTenantAccess: Scalars['Boolean'];
  /** The id */
  id: Scalars['ID'];
  /** The template type */
  libraryItemType: LibraryItemMetadataType;
  /** The name of the template, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the template belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the template */
  readme?: Maybe<Scalars['String']>;
  /** The solution name that this library item is included in */
  solutionName?: Maybe<Scalars['String']>;
  /** The solutions that this library item is under */
  solutions: Array<Scalars['String']>;
  /** The technologies that this library item is under */
  technologies: Array<Scalars['String']>;
};

/** A connection from an object to a list of objects of type `DataSetMetadata`. */
export type DataSetMetadataConnection = {
  __typename: 'DataSetMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DataSetMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<DataSetMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `DataSetMetadata`. */
export type DataSetMetadataEdge = {
  __typename: 'DataSetMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataSetMetadata>;
};

/** Input for dataset by publishers, package names, package versions, and dataset names. */
export type DataSetMetadatasRetrieveInput = {
  /** Filter by publisher names. */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names. */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions. If no versions are specified, the latest viable versions matching other criteria or returned. */
  packageVersions?: Maybe<Array<Scalars['String']>>;
  /** Filter by dataset names. */
  names?: Maybe<Array<Scalars['String']>>;
};

export type DataSetPatchRequest = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  data: EditDataSet;
};

export enum DataSetStatus {
  Unknown = 'UNKNOWN',
  Pending = 'PENDING',
  Ready = 'READY',
  Provisioning = 'PROVISIONING',
  Invalid = 'INVALID',
  Deleting = 'DELETING',
  Deleted = 'DELETED'
}

/** Metadata for a template. */
export type DataSetTemplate = TemplateMetadata & {
  __typename: 'DataSetTemplate';
  /** The metadata of the data schema for this data set template. */
  dataSchemaMetadata: DataSchemaMetadata;
  /** The default schedule pattern */
  defaultSchedulePattern?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The name of the template, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the template belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the template */
  readme?: Maybe<Scalars['String']>;
  /** The template type */
  type: TemplateMetadataType;
};

export type DataSetsRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the template metadata the datasets were created for */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The ids of the datasets */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The statuses of the datasets */
  statuses?: Maybe<Array<DataSetStatus>>;
  /** The ids of the data schema metadata of the datasets */
  dataSchemaMetadataIds?: Maybe<Array<Scalars['ID']>>;
  /** The list of display names. */
  displayNames?: Maybe<Array<Scalars['String']>>;
};

export type DataValidation = {
  __typename: 'DataValidation';
  /** The created date */
  createdOn: Scalars['DateTime'];
  /** Whether or not there are logs associated with the validation */
  hasLogs: Scalars['Boolean'];
  /** The id */
  id: Scalars['ID'];
  /** The status */
  status: ValidationStatus;
  validationMessages?: Maybe<ValidationMessageConnection>;
};


export type DataValidationValidationMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

/** A connection from an object to a list of objects of type `DataValidation`. */
export type DataValidationConnection = {
  __typename: 'DataValidationConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<DataValidationEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<DataValidation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `DataValidation`. */
export type DataValidationEdge = {
  __typename: 'DataValidationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataValidation>;
};

export type DataValidationRetrieveInput = {
  /** The list of validation statuses to filter by */
  statuses?: Maybe<Array<ValidationStatus>>;
};



export type DeleteDashboardInput = {
  /** The id of the dashboard's workspace */
  workspaceId: Scalars['ID'];
  /** The id of the dashboard */
  dashboardId: Scalars['ID'];
};

export type DeleteDataSetInput = {
  /** The id of the dataset's workspace */
  workspaceId: Scalars['ID'];
  /** The id of the dataset */
  dataSetId: Scalars['ID'];
};

export type DeleteDataSetZeroRowsPolicyInput = {
  /** The id of the dataset's workspace */
  workspaceId: Scalars['ID'];
  /** The id of the dataset */
  dataSetId: Scalars['ID'];
};

export type DeleteFileInput = {
  /** The id of the workspace the file belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the file */
  fileId: Scalars['ID'];
};

export type DeleteGlobalDataInput = {
  /** The data access level for the file */
  dataAccessLevel: GlobalDataAccessLevelType;
  /** The name of the publisher */
  publisher: Scalars['String'];
  /** The name of the package */
  package?: Maybe<Scalars['String']>;
  /** The name of the template */
  template?: Maybe<Scalars['String']>;
  /** Specifies the path of the file to remove */
  path: Scalars['String'];
};

export type DeleteGlobalDataPayload = {
  __typename: 'DeleteGlobalDataPayload';
  /** The id of the file descriptor that was deleted. */
  fileId?: Maybe<Scalars['String']>;
};

/** Delete integration input. */
export type DeleteIntegrationInput = {
  /** Id of the integration to delete. */
  id: Scalars['ID'];
};

/** Delete notification subscription input. */
export type DeleteNotificationSubscriptionInput = {
  /** Id of the subscription to delete. */
  id: Scalars['ID'];
};

/** Contains the payload of deleting a notification subscription. */
export type DeleteNotificationSubscriptionPayload = {
  __typename: 'DeleteNotificationSubscriptionPayload';
  /** The id of the deleted notification subscription. */
  id?: Maybe<Scalars['ID']>;
};

export type DeleteTemplateConfigurationInput = {
  /** The id of the template configuration's workspace */
  workspaceId: Scalars['ID'];
  /** The id of the template configuration */
  templateConfigurationId: Scalars['ID'];
};

/** Delete tenant Member input. */
export type DeleteTenantMemberInput = {
  /** Id of the tenant member to delete. */
  id: Scalars['String'];
};

/** Delete Workspace input. */
export type DeleteWorkspaceInput = {
  /** Id of the workspace to delete. */
  id: Scalars['ID'];
};

/** Partial class for Protobuf to add more implementations. */
export type Diagnostic = {
  __typename: 'Diagnostic';
  /** The diagnostic code */
  code: Scalars['String'];
  /** The line number */
  lineNumber: Scalars['Int'];
  /** The line position */
  linePosition: Scalars['Int'];
  /** The diagnostic message */
  message: Scalars['String'];
  /** The path in package directory */
  path: Scalars['String'];
  /** The diagnostic severity */
  severity: DiagnosticSeverity;
};

/** Diagnostic severity indicates the impact of a diagnostic. */
export enum DiagnosticSeverity {
  /** Unspecified. */
  Unspecified = 'UNSPECIFIED',
  /** Info indicates normal conditions. */
  Info = 'INFO',
  /** Warning indicates the subject would still work but it can be done better. */
  Warning = 'WARNING',
  /** Error indicates the subject is in an errorneous state. */
  Error = 'ERROR'
}

export type EditDashboard = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
};

export type EditDataSet = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
};

export type EditInstance = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
};

/** Represents an instantiable class of  required for patch request deserialization. */
export type EditIntegration = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
};

/** Represents notifications that are assigned to a given . */
export type EditNotificationRecipient = {
  /** The status of this notification */
  status: NotificationRecipientStatus;
};

/** Contains the payload of an edited notification recipient. */
export type EditNotificationRecipientPayload = {
  __typename: 'EditNotificationRecipientPayload';
  /** The edited notification recipient. */
  notificationRecipient?: Maybe<NotificationRecipient>;
};

/** Payload for the edit run subscription mutation. */
export type EditRunNotificationSubscriptionInput = {
  /** The type of notification being subscribed to. */
  runNotificationType?: Maybe<RunNotificationType>;
  /** The subscription status. */
  status?: Maybe<NotificationSubscriptionStatus>;
  /** The workspace. */
  workspace?: Maybe<WorkspaceInput>;
  /** The template configuration id. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The list of notifications channels enabled for the subscription. */
  notificationChannels?: Maybe<Array<Maybe<NotificationChannelTypes>>>;
};

/** Contains the payload of an edited run notification subscription. */
export type EditRunNotificationSubscriptionPayload = {
  __typename: 'EditRunNotificationSubscriptionPayload';
  /** The edited run notification subscription. */
  notificationSubscription?: Maybe<RunNotificationSubscription>;
};

export type EditTemplateConfiguration = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
};

/** Edit tenant Member input. */
export type EditTenantMemberInput = {
  /** Id of the tenant member to edit. */
  id: Scalars['ID'];
  /** New first name of the tenant member. */
  firstName?: Maybe<Scalars['String']>;
  /** New last name of the tenant member. */
  lastName?: Maybe<Scalars['String']>;
  /** New email address of the tenant member. */
  emailAddress?: Maybe<Scalars['String']>;
  /** New role for the tenant member. */
  role?: Maybe<TenantMemberRole>;
  /** Whether or not the edited tenant member should be sent run summary email notifications. */
  sendRunSummaryViaEmail?: Maybe<Scalars['Boolean']>;
};

/** Edit tenant member result. */
export type EditTenantMemberResult = {
  __typename: 'EditTenantMemberResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<TenantsError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
  /** The tenant member that was edited. */
  tenantMember?: Maybe<TenantMember>;
};

/** Input for the edit workspace mutation. */
export type EditWorkspaceInput = {
  /** Gets or sets the workspace id. */
  workspaceId: Scalars['ID'];
  /** The display name. */
  displayName: Scalars['String'];
};

export type ExecuteDataSetInput = {
  /** The id of the dataset workspace */
  workspaceId: Scalars['ID'];
  /** The id of the dataset */
  dataSetId: Scalars['ID'];
};

export type ExecuteDataSetPayload = {
  __typename: 'ExecuteDataSetPayload';
  /** The dataset */
  dataSet?: Maybe<DataSet>;
  /** The created instance */
  instance?: Maybe<Instance>;
};

export type ExecuteTemplateConfigurationDirectInput = {
  /** The id of the workspace to run the workflow under */
  workspaceId: Scalars['ID'];
  /** The id of the entity starting the workflow. */
  launchedById: Scalars['ID'];
  /** The json-serialized package manifest */
  packageManifest: Scalars['String'];
  /** The json-serialized template manifest */
  templateManifest: Scalars['String'];
  /** The json-serialized workflow manifest */
  workflowManifest: Scalars['String'];
  /** The json-serialized configuration manifest */
  configurationManifest?: Maybe<Scalars['String']>;
  /** The json-serialized workflow runtime options */
  workflowRuntimeOptions?: Maybe<Scalars['String']>;
  /** The json-serialized map of inputs to the workflow */
  inputs?: Maybe<Scalars['String']>;
  /** The package patch archive content in base64 */
  packagePatchContent?: Maybe<Scalars['String']>;
  /** The optional id of an existing template configuration. If provided, its configuration data will be re-used in the new workflow. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The optional id of an existing data set that this workflow depends on. If provided, the data set connection string will be used in the new workflow. */
  dataSetId?: Maybe<Scalars['ID']>;
};

export type ExecuteTemplateConfigurationDirectPayload = {
  __typename: 'ExecuteTemplateConfigurationDirectPayload';
  /** The configuration diagram url */
  configurationDiagramUrl?: Maybe<Scalars['String']>;
  /** The list of diagnostics from analyzing the manifests */
  diagnostics?: Maybe<Array<ManifestDiagnostic>>;
  /** The created instance */
  instance?: Maybe<Instance>;
  /** The workflow diagram url */
  workflowDiagramUrl?: Maybe<Scalars['String']>;
};

export type ExecuteTemplateConfigurationInput = {
  /** The id of the workspace to run the workflow under */
  workspaceId: Scalars['ID'];
  /** The optional id of the template configuration to execute */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The id of the entity starting the workflow. */
  launchedById: Scalars['ID'];
  /** The id of the template metadata to start the workflow from when not providing a template configuration id */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The id of the dataset that this template configuration uses */
  dataSetId?: Maybe<Scalars['ID']>;
};

export type FileDescriptor = FileSystemDescriptorInterface & {
  __typename: 'FileDescriptor';
  /** The download uri */
  downloadUri: Scalars['Uri'];
  /** The full path of the file */
  fullPath: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The name */
  name: Scalars['String'];
  /** The parent folder */
  parentFolder?: Maybe<FolderDescriptor>;
  /** The size in bytes */
  size: Scalars['ULong'];
  /** The last modified date */
  updatedOn: Scalars['DateTime'];
  /** The workspace id */
  workspaceId: Scalars['ID'];
};

export type FileSystemDescriptorInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The path under which to retrieve files. If unspecified, the root will be retrieved. */
  path?: Maybe<Scalars['String']>;
};

export type FileSystemDescriptorInterface = {
  /** The full path */
  fullPath: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The name */
  name: Scalars['String'];
  /** The parent folder */
  parentFolder?: Maybe<FolderDescriptor>;
  /** The size in bytes */
  size: Scalars['ULong'];
  /** The last modified date */
  updatedOn: Scalars['DateTime'];
  /** The workspace id */
  workspaceId: Scalars['ID'];
};

/** A connection from an object to a list of objects of type `FileSystemDescriptorInterface`. */
export type FileSystemDescriptorInterfaceConnection = {
  __typename: 'FileSystemDescriptorInterfaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<FileSystemDescriptorInterfaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<FileDescriptor | FolderDescriptor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `FileSystemDescriptorInterface`. */
export type FileSystemDescriptorInterfaceEdge = {
  __typename: 'FileSystemDescriptorInterfaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FileDescriptor | FolderDescriptor>;
};

export type FileSystemDescriptorRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The path under which to retrieve files. If unspecified, files from the root path will be retrieved. */
  path?: Maybe<Scalars['String']>;
};

export type FolderDescriptor = FileSystemDescriptorInterface & {
  __typename: 'FolderDescriptor';
  /** The full path of the folder */
  fullPath: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The items contained within this folder, including files and other folders */
  items?: Maybe<FileSystemDescriptorInterfaceConnection>;
  /** The name */
  name: Scalars['String'];
  /** The parent folder */
  parentFolder?: Maybe<FolderDescriptor>;
  /** The size in bytes */
  size: Scalars['ULong'];
  /** The last modified date */
  updatedOn: Scalars['DateTime'];
  /** The workspace id */
  workspaceId: Scalars['ID'];
};


export type FolderDescriptorItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type Form = {
  __typename: 'Form';
  /** The components */
  components: Array<FormCheckbox | FormColumns | FormDateInput | FormDateTimeInput | FormEmailInput | FormFileInput | FormIntegration | FormLink | FormMultiSelect | FormNumericInput | FormOAuth2Connection | FormParagraph | FormSelect | FormTextArea | FormTextInput | FormTimeInput | FormUrlInput>;
  /** The name */
  name: Scalars['String'];
  /** The submit label */
  submitLabel?: Maybe<Scalars['String']>;
};

export type FormCheckbox = FormComponentInterface & {
  __typename: 'FormCheckbox';
  /** Whether or not the checkbox is checked by default */
  defaultChecked: Scalars['Boolean'];
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormColumn = {
  __typename: 'FormColumn';
  /** The components of the column */
  components: Array<FormCheckbox | FormColumns | FormDateInput | FormDateTimeInput | FormEmailInput | FormFileInput | FormIntegration | FormLink | FormMultiSelect | FormNumericInput | FormOAuth2Connection | FormParagraph | FormSelect | FormTextArea | FormTextInput | FormTimeInput | FormUrlInput>;
  /** The name */
  name: Scalars['String'];
};

export type FormColumns = FormComponentInterface & {
  __typename: 'FormColumns';
  /** The columns */
  columns: Array<FormColumn>;
  /** The name */
  name: Scalars['String'];
  /** The type */
  type: FormComponentType;
};

export type FormComponentInterface = {
  /** The name */
  name: Scalars['String'];
  /** The type */
  type: FormComponentType;
};

export enum FormComponentType {
  None = 'NONE',
  TextInput = 'TEXT_INPUT',
  TextArea = 'TEXT_AREA',
  Select = 'SELECT',
  Columns = 'COLUMNS',
  Link = 'LINK',
  Paragraph = 'PARAGRAPH',
  Checkbox = 'CHECKBOX',
  MultiSelect = 'MULTI_SELECT',
  NumericInput = 'NUMERIC_INPUT',
  EmailInput = 'EMAIL_INPUT',
  UrlInput = 'URL_INPUT',
  DateInput = 'DATE_INPUT',
  TimeInput = 'TIME_INPUT',
  DateTimeInput = 'DATE_TIME_INPUT',
  Integration = 'INTEGRATION',
  FileInput = 'FILE_INPUT',
  OAuth_2Connection = 'O_AUTH_2_CONNECTION'
}

export type FormDataRequest = DataRequest & {
  __typename: 'FormDataRequest';
  dataValidation?: Maybe<DataValidation>;
  dataValidations?: Maybe<DataValidationConnection>;
  /** The form */
  form: Form;
  /** The id */
  id: Scalars['ID'];
  /** The status */
  status: DataRequestStatus;
  /** The related step */
  step: Step;
  /** The type */
  type: DataRequestType;
};


export type FormDataRequestDataValidationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DataValidationRetrieveInput;
};

export type FormDateInput = FormComponentInterface & {
  __typename: 'FormDateInput';
  /** The default date to display in the input */
  defaultValue?: Maybe<Scalars['String']>;
  /** Whether or not the component should request a timezone alongside the date */
  includeTimezone: Scalars['Boolean'];
  /** The label */
  label: Scalars['String'];
  /** The optional maximum allowed date */
  maxDate?: Maybe<Scalars['String']>;
  /** The optional minimum allowed date */
  minDate?: Maybe<Scalars['String']>;
  /** The name */
  name: Scalars['String'];
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any. Represented as a serialized string of the struct */
  value?: Maybe<Scalars['String']>;
};

export type FormDateTimeInput = FormComponentInterface & {
  __typename: 'FormDateTimeInput';
  /** The default datetime to display in the input */
  defaultValue?: Maybe<Scalars['String']>;
  /** Whether the input should request the timezone */
  includeTimezone: Scalars['Boolean'];
  /** The label */
  label: Scalars['String'];
  /** The optional maximum allowed date time */
  maxDateTime?: Maybe<Scalars['String']>;
  /** The optional minimum allowed date time */
  minDateTime?: Maybe<Scalars['String']>;
  /** The name */
  name: Scalars['String'];
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any. Represented as a serialized string of the struct */
  value?: Maybe<Scalars['String']>;
};

export type FormEmailInput = FormComponentInterface & {
  __typename: 'FormEmailInput';
  /** The optional list of allowed domains */
  allowedDomains: Array<Scalars['String']>;
  /** The default value to display in the email input */
  defaultValue?: Maybe<Scalars['String']>;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormFileInput = FormComponentInterface & {
  __typename: 'FormFileInput';
  /** The optional Voleer uri representing a file that should be selected by default if no other is supplied */
  defaultValue?: Maybe<Scalars['String']>;
  /** The label */
  label: Scalars['String'];
  /** The maximum allowed file size, in bytes. 0 for unlimited. */
  maxFileSizeInBytes: Scalars['Long'];
  /** The name */
  name: Scalars['String'];
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormIntegration = FormComponentInterface & {
  __typename: 'FormIntegration';
  /** The integration references */
  integrationReferences?: Maybe<Array<FormIntegrationReference>>;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormIntegrationReference = {
  __typename: 'FormIntegrationReference';
  /** The integration name. */
  integration: Scalars['String'];
  /** The fully-qualified package name. */
  package: Scalars['String'];
};

export type FormLink = FormComponentInterface & {
  __typename: 'FormLink';
  /** The link value */
  href: Scalars['String'];
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The type */
  type: FormComponentType;
};

export type FormMultiSelect = FormComponentInterface & {
  __typename: 'FormMultiSelect';
  /** The ids of the default selected options */
  defaultValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The display type */
  displayType: FormMultiSelectDisplayType;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The list of options */
  options: Array<FormSelectOption>;
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export enum FormMultiSelectDisplayType {
  Dropdown = 'DROPDOWN',
  Checkbox = 'CHECKBOX'
}

export type FormNumericInput = FormComponentInterface & {
  __typename: 'FormNumericInput';
  /** Whether or not the value is allowed to be a decimal */
  decimal: Scalars['Boolean'];
  /** The default value to display in the numeric input */
  defaultValue?: Maybe<Scalars['Float']>;
  /** The label */
  label: Scalars['String'];
  /** The optional maximum allowed value */
  maxValue?: Maybe<Scalars['Float']>;
  /** The optional minimum allowed value */
  minValue?: Maybe<Scalars['Float']>;
  /** The name */
  name: Scalars['String'];
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormOAuth2Connection = FormComponentInterface & {
  __typename: 'FormOAuth2Connection';
  /** The integration references */
  integrationReferences: Array<FormIntegrationReference>;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormParagraph = FormComponentInterface & {
  __typename: 'FormParagraph';
  /** The content in markdown */
  content: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The type */
  type: FormComponentType;
};

export type FormSelect = FormComponentInterface & {
  __typename: 'FormSelect';
  /** The id of the default selected option */
  defaultValue?: Maybe<Scalars['String']>;
  /** The display type */
  displayType: FormSelectDisplayType;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The list of options */
  options: Array<FormSelectOption>;
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export enum FormSelectDisplayType {
  Dropdown = 'DROPDOWN',
  Radio = 'RADIO'
}

export type FormSelectOption = {
  __typename: 'FormSelectOption';
  /** The id */
  id: Scalars['ID'];
  /** The label */
  label?: Maybe<Scalars['String']>;
};

export type FormTextArea = FormComponentInterface & {
  __typename: 'FormTextArea';
  /** The number of columns */
  cols?: Maybe<Scalars['Int']>;
  /** The default value of the text area */
  defaultValue?: Maybe<Scalars['String']>;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The number of rows */
  rows?: Maybe<Scalars['Int']>;
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export type FormTextInput = FormComponentInterface & {
  __typename: 'FormTextInput';
  /** The type of casing to enforce */
  case: FormTextInputCaseType;
  /** The default value to display in the text input */
  defaultValue?: Maybe<Scalars['String']>;
  /** The label */
  label: Scalars['String'];
  /** True if the field should be masked, otherwise false */
  mask: Scalars['Boolean'];
  /** The optional maximum allowed length of the text */
  maxLength?: Maybe<Scalars['Int']>;
  /** The optional minimum allowed length of the text */
  minLength?: Maybe<Scalars['Int']>;
  /** The name */
  name: Scalars['String'];
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** The optional regular expression to match input against */
  regex?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The optional message to display if the input fails validating the regex */
  validationMessage?: Maybe<Scalars['String']>;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

export enum FormTextInputCaseType {
  Any = 'ANY',
  Upper = 'UPPER',
  Lower = 'LOWER'
}

export type FormTimeInput = FormComponentInterface & {
  __typename: 'FormTimeInput';
  /** The default time to display in the input */
  defaultValue?: Maybe<Scalars['String']>;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any. Represented as a serialized string of the struct */
  value?: Maybe<Scalars['String']>;
};

export type FormUrlInput = FormComponentInterface & {
  __typename: 'FormUrlInput';
  /** The optional list of allowed domains */
  allowedDomains: Array<Scalars['String']>;
  /** The default value to display in the url input */
  defaultValue?: Maybe<Scalars['String']>;
  /** The label */
  label: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** True if the field is required, otherwise false */
  required: Scalars['Boolean'];
  /** The type */
  type: FormComponentType;
  /** The value previously saved for this component, if any */
  value?: Maybe<Scalars['String']>;
};

/** Input for retrieving multiple  by name. */
export type GetIntegrationMetadatasByNameInput = {
  /** Integration references. */
  integrationReferences: Array<IntegrationReference>;
};

export enum GlobalDataAccessLevelType {
  /** Only viewable by the template that created the data */
  Template = 'TEMPLATE',
  /** Only viewable by tasks in the package that created the data */
  Package = 'PACKAGE',
  /** Only viewable by the publisher that created the data */
  Publisher = 'PUBLISHER'
}

export type IdentityError = {
  __typename: 'IdentityError';
  /** Error code. */
  code: Scalars['String'];
  /** Error description. */
  description: Scalars['String'];
};

export type Instance = {
  __typename: 'Instance';
  /** The completed on date */
  completedOn?: Maybe<Scalars['DateTime']>;
  configurationData?: Maybe<ConfigurationData>;
  /** The created on date */
  createdOn: Scalars['DateTime'];
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The id */
  id: Scalars['ID'];
  /** Whether it has been deleted */
  isDeleted: Scalars['Boolean'];
  launchedBy?: Maybe<InstanceLaunchedBy>;
  /** Id of the entity that launched the instance. */
  launchedById?: Maybe<Scalars['ID']>;
  /** Type of the entity that launched the instance. */
  launchedByType: LaunchedByType;
  runSummary?: Maybe<Scalars['String']>;
  /** The started on date */
  startedOn?: Maybe<Scalars['DateTime']>;
  /** The status */
  status: InstanceStatus;
  steps?: Maybe<StepConnection>;
  templateConfiguration?: Maybe<TemplateConfiguration>;
  templateMetadata?: Maybe<AutomationTemplate | DataSetTemplate>;
  /** The template metadata id. Will only be present if the instance was created from a published template. */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The type of the instance */
  type: InstanceType;
  /** The updated on date */
  updatedOn: Scalars['DateTime'];
  /** The workspace id */
  workspaceId: Scalars['ID'];
};


export type InstanceStepsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: StepsForInstanceRetrieveInput;
};

/** A connection from an object to a list of objects of type `Instance`. */
export type InstanceConnection = {
  __typename: 'InstanceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<InstanceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<Instance>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Instance`. */
export type InstanceEdge = {
  __typename: 'InstanceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Instance>;
};

export type InstanceLaunchedBy = DataSet | ScheduledJob | TenantMember;

export type InstancePatchRequest = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  data: EditInstance;
};

export type InstanceRetrieveInput = {
  /** The ids of the instances to retrieve */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the template metadata the instance was started for */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The id of the template configuration of the instance */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The statuses of the instance */
  statuses?: Maybe<Array<InstanceStatus>>;
  /** The minimum UpdatedOn date */
  updatedOnAfter?: Maybe<Scalars['DateTime']>;
  /** The launched by type */
  launchedByTypes?: Maybe<Array<LaunchedByType>>;
  /** The launched by id */
  launchedById?: Maybe<Scalars['ID']>;
};

export enum InstanceStatus {
  Unknown = 'UNKNOWN',
  Scheduled = 'SCHEDULED',
  Running = 'RUNNING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Cancelling = 'CANCELLING',
  TimedOut = 'TIMED_OUT'
}

export enum InstanceType {
  Execution = 'EXECUTION',
  Configuration = 'CONFIGURATION',
  Integration = 'INTEGRATION'
}

/** Represents an integration instance. */
export type Integration = {
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration display name. */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  integrationMetadata?: Maybe<IntegrationMetadata>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The integration status. */
  status: IntegrationStatus;
};

/** The specification for an integration configuration. */
export type IntegrationConfigurationInterface = {
  /** The id */
  id: Scalars['ID'];
};

/** A connection from an object to a list of objects of type `IntegrationInterface`. */
export type IntegrationInterfaceConnection = {
  __typename: 'IntegrationInterfaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<IntegrationInterfaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<CustomWorkflowIntegration | OAuth2Integration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `IntegrationInterface`. */
export type IntegrationInterfaceEdge = {
  __typename: 'IntegrationInterfaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: CustomWorkflowIntegration | OAuth2Integration;
};

/**
 * Metadata representing a definition of an integration that can be used to substantiate connections of
 *             different types.
 */
export type IntegrationMetadata = {
  __typename: 'IntegrationMetadata';
  /** The configuration */
  configuration?: Maybe<OAuth2Configuration>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The unique name of the integration */
  name: Scalars['ID'];
  /** The metadata of the package the integration belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the integration */
  readme?: Maybe<Scalars['String']>;
  /** The type */
  type: IntegrationType;
};

/** A connection from an object to a list of objects of type `IntegrationMetadata`. */
export type IntegrationMetadataConnection = {
  __typename: 'IntegrationMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<IntegrationMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<IntegrationMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `IntegrationMetadata`. */
export type IntegrationMetadataEdge = {
  __typename: 'IntegrationMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationMetadata>;
};

/** Input for retrieveing a connection of integration metadata. */
export type IntegrationMetadataRetrieveInput = {
  /** Filter by integration ids. */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** Filter by integration names. */
  names?: Maybe<Array<Scalars['String']>>;
  /** Filter by publisher names. */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names. */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions. If no versions are specified, the latest viable versions matching other criteria or returned. */
  packageVersions?: Maybe<Array<Scalars['String']>>;
};

export type IntegrationPatchRequest = {
  id: Scalars['ID'];
  data: EditIntegration;
};

/** IntegrationReference describes a reference to a specific integration definition. */
export type IntegrationReference = {
  /** The fully-qualified package name. */
  package: Scalars['String'];
  /** The integration name. */
  integration: Scalars['String'];
};

/** Statuses of the . */
export enum IntegrationStatus {
  /** Unknown. */
  Unknown = 'UNKNOWN',
  /** Pending. */
  Pending = 'PENDING',
  /** Active. */
  Active = 'ACTIVE',
  /** Failed. */
  Failed = 'FAILED'
}

/** Types of . */
export enum IntegrationType {
  /** None. */
  None = 'NONE',
  /** OAuth2. */
  OAuth_2 = 'O_AUTH_2',
  /** Custom workflow. */
  CustomWorkflow = 'CUSTOM_WORKFLOW'
}

/** Input for retrieving s. */
export type IntegrationsRetrieveInput = {
  /** The optional object detailing the integration definition to match on */
  matchingIntegrations?: Maybe<MatchingIntegrationsInput>;
  /** The optional list of integration statuses to filter by */
  statuses?: Maybe<Array<IntegrationStatus>>;
};

/** Describes the type of internal sharing target specified. */
export enum InternalSharingTarget {
  /** Default value, unset */
  Unknown = 'UNKNOWN',
  /** A reference to the Public Library, or Community Library for global sharing. */
  PublicLibrary = 'PUBLIC_LIBRARY'
}

/** DTO to expose as GraphQl result. */
export type LaunchControlItemMappings = {
  __typename: 'LaunchControlItemMappings';
  /** ChargeBee plan. */
  chargeBeeSaleItem: Scalars['String'];
  /** List of templates. */
  templates: Array<Scalars['String']>;
};

export enum LaunchedByType {
  Unknown = 'UNKNOWN',
  TenantMember = 'TENANT_MEMBER',
  ScheduledJob = 'SCHEDULED_JOB',
  DataSetConfiguration = 'DATA_SET_CONFIGURATION',
  CustomWorkflowIntegrationSchedule = 'CUSTOM_WORKFLOW_INTEGRATION_SCHEDULE'
}

/** Contains available library filtering data from all library items. */
export type LibraryFilteringMetadata = {
  __typename: 'LibraryFilteringMetadata';
  /** The available solutions for library items to filter. */
  availableSolutions: Array<Scalars['String']>;
  /** The available technologies for library items to filter. */
  availableTechnologies: Array<Scalars['String']>;
};

/** Defines a contract for different entity types that can be group together in a query result. */
export type LibraryItem = {
  /** The ChargeBee item id that this library item is included in */
  chargeBeeItemId?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** Whether this library item is accessible to the tenant */
  hasTenantAccess: Scalars['Boolean'];
  /** The id */
  id: Scalars['ID'];
  /** The library item type */
  libraryItemType: LibraryItemMetadataType;
  /** The name of the library item, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the library item belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the library item */
  readme?: Maybe<Scalars['String']>;
  /** The solution name that this library item is included in */
  solutionName?: Maybe<Scalars['String']>;
  /** The solutions that this library item is categorized under */
  solutions: Array<Scalars['String']>;
  /** The technologies that this library item is categorized under */
  technologies: Array<Scalars['String']>;
};

/** A connection from an object to a list of objects of type `LibraryItem`. */
export type LibraryItemConnection = {
  __typename: 'LibraryItemConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<LibraryItemEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<AutomationMetadata | DashboardMetadata | DataSetMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `LibraryItem`. */
export type LibraryItemEdge = {
  __typename: 'LibraryItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AutomationMetadata | DashboardMetadata | DataSetMetadata>;
};

/** Defines the type of a . */
export enum LibraryItemMetadataType {
  /** Unknown. */
  Unknown = 'UNKNOWN',
  /** Automation. */
  Automation = 'AUTOMATION',
  /** DataSet. */
  DataSet = 'DATA_SET',
  /** Dashboard */
  Dashboard = 'DASHBOARD'
}

/** Input for requesting library items. */
export type LibraryItemsRetrieveInput = {
  /** Filter by ids */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** Filter by publisher names */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions */
  packageVersions?: Maybe<Array<Scalars['String']>>;
  /** Filter by libary item types */
  types?: Maybe<Array<LibraryItemMetadataType>>;
  /** Filter by item names */
  itemNames?: Maybe<Array<Scalars['String']>>;
};

export type ListGlobalDataInput = {
  /** The data access level for the file */
  dataAccessLevel: GlobalDataAccessLevelType;
  /** The name of the publisher */
  publisher: Scalars['String'];
  /** The name of the package */
  package?: Maybe<Scalars['String']>;
  /** The name of the template */
  template?: Maybe<Scalars['String']>;
  /** Specifies the path to filter from */
  path?: Maybe<Scalars['String']>;
};

export type ListGlobalDataPath = {
  __typename: 'ListGlobalDataPath';
  /** The name of the package linked to the object */
  package?: Maybe<Scalars['String']>;
  /** The name of the template linked to the object */
  template?: Maybe<Scalars['String']>;
  /** The voleer uri for the global data object */
  uri: Scalars['String'];
};

export type ListGlobalDataPayload = {
  __typename: 'ListGlobalDataPayload';
  /** Returns a list of uris. */
  paths: Array<ListGlobalDataPath>;
};


export type ManifestDiagnostic = {
  __typename: 'ManifestDiagnostic';
  /** The diagnostic code */
  code: Scalars['String'];
  /** The line number */
  lineNumber: Scalars['Int'];
  /** The line position */
  linePosition: Scalars['Int'];
  /** The diagnostic message */
  message: Scalars['String'];
  /** The path in package directory */
  path: Scalars['String'];
  /** The diagnostic severity */
  severity: ManifestDiagnosticSeverity;
};

export enum ManifestDiagnosticSeverity {
  Unspecified = 'UNSPECIFIED',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

/** Input to retrieve s matching specific parameters. */
export type MatchingIntegrationsInput = {
  /** The integration references. */
  integrationReferences: Array<IntegrationReference>;
};

export type Mutation = {
  __typename: 'Mutation';
  changePassword: ChangePasswordResult;
  setPassword: SetPasswordResult;
  addTenantMember: AddTenantMemberResult;
  cancelCustomWorkflowIntegrationConfiguration: CancelCustomWorkflowIntegrationConfigurationPayload;
  confirmEmail?: Maybe<ConfirmEmailResult>;
  createCustomWorkflowIntegration: CreateCustomWorkflowIntegrationPayload;
  createOAuth2Integration: CreateOAuth2IntegrationSessionResult;
  createRunNotificationSubscription: CreateRunNotificationSubscriptionPayload;
  createTenant?: Maybe<CreateTenantResult>;
  createWorkspace?: Maybe<Workspace>;
  deleteIntegration: Scalars['ID'];
  deleteNotificationSubscription: DeleteNotificationSubscriptionPayload;
  deleteTenantMember: TenantsApiResult;
  deleteWorkspace: TenantsApiResult;
  editIntegration: CustomWorkflowIntegration | OAuth2Integration;
  editNotificationRecipient: EditNotificationRecipientPayload;
  editRunNotificationSubscription: EditRunNotificationSubscriptionPayload;
  editTenantMember: EditTenantMemberResult;
  editWorkspace?: Maybe<Workspace>;
  ping: TenantsApiResult;
  resendEmailInvitation: TenantsApiResult;
  resetCustomWorkflowIntegration: ResetCustomWorkflowIntegrationPayload;
  resetOAuth2Integration: CreateOAuth2IntegrationSessionResult;
  sendEmailConfirmationCode?: Maybe<SendEmailConfirmationCodeResult>;
  setNotificationRecipientStatusByDate: SetNotificationRecipientStatusByDateInputPayload;
  triggerIntegrationRefresh: TriggerIntegrationRefreshPayload;
  addInternalPackagePermission: AddInternalPackagePermissionResponse;
  addPackagePermission: AddPackagePermissionResponse;
  adminSetPackagePermission: AdminSetPackagePermissionResponse;
  analyzeTemplate: AnalyzeTemplateResponse;
  publishPackage: PackageMetadata;
  requestPackagePermissionRefresh?: Maybe<RequestPackagePermissionRefreshResponse>;
  revokeInternalPackagePermission: RevokeInternalPackagePermissionResponse;
  updateHandshake: UpdateHandshakeResponse;
  deleteFile?: Maybe<Scalars['String']>;
  deleteGlobalData: DeleteGlobalDataPayload;
  setGlobalData: SetGlobalDataPayload;
  cancelCandidateDataSetConfigurationData?: Maybe<DataSet>;
  cancelCandidateTemplateConfigurationData?: Maybe<TemplateConfiguration>;
  cancelSchedule?: Maybe<ScheduledJob>;
  cancelWorkflow?: Maybe<Instance>;
  completeDataRequest?: Maybe<FormDataRequest>;
  convertOnDemandTemplateConfiguration?: Maybe<TemplateConfiguration>;
  createDashboard?: Maybe<Dashboard>;
  createDashboardDirect?: Maybe<CreateDashboardDirectPayload>;
  createDataSet?: Maybe<DataSet>;
  createDataSetDirect?: Maybe<CreateDataSetDirectPayload>;
  createDataSetZeroRowsPolicy: DataSet;
  createSchedule?: Maybe<ScheduledJob>;
  createTemplateConfiguration?: Maybe<TemplateConfiguration>;
  deleteDashboard?: Maybe<Dashboard>;
  deleteDataSet?: Maybe<DataSet>;
  deleteDataSetZeroRowsPolicy: DataSet;
  deleteTemplateConfiguration?: Maybe<TemplateConfiguration>;
  editDashboard: Dashboard;
  editDataSet?: Maybe<DataSet>;
  editInstance?: Maybe<Instance>;
  editTemplateConfiguration?: Maybe<TemplateConfiguration>;
  executeDataSet?: Maybe<ExecuteDataSetPayload>;
  executeTemplateConfiguration?: Maybe<Instance>;
  executeTemplateConfigurationDirect?: Maybe<ExecuteTemplateConfigurationDirectPayload>;
  replaceDataSetSchedule?: Maybe<DataSet>;
  resetDataSetConfigurationData?: Maybe<DataSet>;
  resetTemplateConfigurationData?: Maybe<TemplateConfiguration>;
  startDashboardExport: DashboardExportOperation;
  startDataSetSchemaMigrationDirect?: Maybe<DataSet>;
  toggleDataSetScheduleActivity?: Maybe<ScheduledJob>;
  toggleScheduleActivity?: Maybe<ScheduledJob>;
  updateSchedule?: Maybe<ScheduledJob>;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationSetPasswordArgs = {
  setPasswordInput?: Maybe<SetPasswordInput>;
};


export type MutationAddTenantMemberArgs = {
  input: AddTenantMemberInput;
};


export type MutationCancelCustomWorkflowIntegrationConfigurationArgs = {
  input: CancelCustomWorkflowIntegrationConfigurationInput;
};


export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type MutationCreateCustomWorkflowIntegrationArgs = {
  input: CreateCustomWorkflowIntegrationInput;
};


export type MutationCreateOAuth2IntegrationArgs = {
  input: CreateOAuth2IntegrationSessionInput;
};


export type MutationCreateRunNotificationSubscriptionArgs = {
  input: CreateRunNotificationSubscriptionInput;
};


export type MutationCreateTenantArgs = {
  tenant: TenantInput;
  tenantUser: TenantUserInput;
  returnUrl?: Maybe<Scalars['String']>;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput;
};


export type MutationDeleteNotificationSubscriptionArgs = {
  input: DeleteNotificationSubscriptionInput;
};


export type MutationDeleteTenantMemberArgs = {
  input: DeleteTenantMemberInput;
};


export type MutationDeleteWorkspaceArgs = {
  input: DeleteWorkspaceInput;
};


export type MutationEditIntegrationArgs = {
  input: IntegrationPatchRequest;
};


export type MutationEditNotificationRecipientArgs = {
  input: NotificationRecipientPatchRequest;
};


export type MutationEditRunNotificationSubscriptionArgs = {
  input: RunNotificationSubscriptionPatchRequest;
};


export type MutationEditTenantMemberArgs = {
  input: EditTenantMemberInput;
};


export type MutationEditWorkspaceArgs = {
  input: EditWorkspaceInput;
};


export type MutationResendEmailInvitationArgs = {
  input: ResendEmailInvitationInput;
};


export type MutationResetCustomWorkflowIntegrationArgs = {
  input: ResetCustomWorkflowIntegrationInput;
};


export type MutationResetOAuth2IntegrationArgs = {
  input: ResetOAuth2IntegrationInput;
};


export type MutationSendEmailConfirmationCodeArgs = {
  input: SendEmailConfirmationCodeInput;
};


export type MutationSetNotificationRecipientStatusByDateArgs = {
  input: SetNotificationRecipientStatusByDateInput;
};


export type MutationTriggerIntegrationRefreshArgs = {
  input: TriggerIntegrationRefreshInput;
};


export type MutationAddInternalPackagePermissionArgs = {
  input: AddInternalPackagePermissionInput;
};


export type MutationAddPackagePermissionArgs = {
  input: AddPackagePermissionInput;
};


export type MutationAdminSetPackagePermissionArgs = {
  input: AdminSetPackagePermissionInput;
};


export type MutationAnalyzeTemplateArgs = {
  input: AnalyzeTemplateInput;
};


export type MutationPublishPackageArgs = {
  input: PublishPackageInput;
};


export type MutationRevokeInternalPackagePermissionArgs = {
  input: RevokeInternalPackagePermissionInput;
};


export type MutationUpdateHandshakeArgs = {
  input: UpdateHandshakeInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteGlobalDataArgs = {
  input: DeleteGlobalDataInput;
};


export type MutationSetGlobalDataArgs = {
  input: SetGlobalDataInput;
};


export type MutationCancelCandidateDataSetConfigurationDataArgs = {
  input: CancelCandidateDataSetConfigurationDataInput;
};


export type MutationCancelCandidateTemplateConfigurationDataArgs = {
  input: CancelCandidateTemplateConfigurationDataInput;
};


export type MutationCancelScheduleArgs = {
  input: CancelScheduledJobInput;
};


export type MutationCancelWorkflowArgs = {
  input: CancelWorkflowInput;
};


export type MutationCompleteDataRequestArgs = {
  input: CompleteDataRequestInput;
};


export type MutationConvertOnDemandTemplateConfigurationArgs = {
  input: ConvertOnDemandTemplateConfigurationInput;
};


export type MutationCreateDashboardArgs = {
  input: CreateDashboardInput;
};


export type MutationCreateDashboardDirectArgs = {
  input: CreateDashboardDirectInput;
};


export type MutationCreateDataSetArgs = {
  input: CreateDataSetInput;
};


export type MutationCreateDataSetDirectArgs = {
  input: CreateDataSetDirectInput;
};


export type MutationCreateDataSetZeroRowsPolicyArgs = {
  input: CreateDataSetZeroRowsPolicyInput;
};


export type MutationCreateScheduleArgs = {
  input: CreateScheduledJobInput;
};


export type MutationCreateTemplateConfigurationArgs = {
  input: CreateTemplateConfigurationInput;
};


export type MutationDeleteDashboardArgs = {
  input: DeleteDashboardInput;
};


export type MutationDeleteDataSetArgs = {
  input: DeleteDataSetInput;
};


export type MutationDeleteDataSetZeroRowsPolicyArgs = {
  input: DeleteDataSetZeroRowsPolicyInput;
};


export type MutationDeleteTemplateConfigurationArgs = {
  input: DeleteTemplateConfigurationInput;
};


export type MutationEditDashboardArgs = {
  input: DashboardPatchRequest;
};


export type MutationEditDataSetArgs = {
  input: DataSetPatchRequest;
};


export type MutationEditInstanceArgs = {
  input: InstancePatchRequest;
};


export type MutationEditTemplateConfigurationArgs = {
  input: TemplateConfigurationPatchRequest;
};


export type MutationExecuteDataSetArgs = {
  input: ExecuteDataSetInput;
};


export type MutationExecuteTemplateConfigurationArgs = {
  input: ExecuteTemplateConfigurationInput;
};


export type MutationExecuteTemplateConfigurationDirectArgs = {
  input: ExecuteTemplateConfigurationDirectInput;
};


export type MutationReplaceDataSetScheduleArgs = {
  input: ReplaceDataSetScheduleInput;
};


export type MutationResetDataSetConfigurationDataArgs = {
  input: ResetDataSetConfigurationDataInput;
};


export type MutationResetTemplateConfigurationDataArgs = {
  input: ResetTemplateConfigurationDataInput;
};


export type MutationStartDashboardExportArgs = {
  input: StartDashboardExportInput;
};


export type MutationStartDataSetSchemaMigrationDirectArgs = {
  input: StartDataSetSchemaMigrationDirectInput;
};


export type MutationToggleDataSetScheduleActivityArgs = {
  input: ToggleDataSetScheduleActivityInput;
};


export type MutationToggleScheduleActivityArgs = {
  input: ToggleScheduledJobActivityInput;
};


export type MutationUpdateScheduleArgs = {
  input: UpdateScheduledJobInput;
};

/** Represents the base functionality for notifications. */
export type Notification = {
  /** The created on date. */
  createdOn: Scalars['DateTime'];
  /** The id. */
  id: Scalars['ID'];
  /** The notification message. */
  message?: Maybe<Scalars['String']>;
  /** The notification display name. */
  subject: Scalars['String'];
};

/** Represents the notification channel types. */
export enum NotificationChannelTypes {
  /** None. */
  None = 'NONE',
  /** Email. */
  Email = 'EMAIL',
  /** VoleerUi. */
  VoleerUi = 'VOLEER_UI'
}

/** Represents notifications that are assigned to a given . */
export type NotificationRecipient = {
  __typename: 'NotificationRecipient';
  /** The created on date. */
  createdOn: Scalars['DateTime'];
  /** The id. */
  id: Scalars['ID'];
  /** The notification. */
  notification: RunNotification;
  /** The notification subscription. */
  notificationSubscription?: Maybe<RunNotificationSubscription>;
  /** The status. */
  status: NotificationRecipientStatus;
};

/** A connection from an object to a list of objects of type `NotificationRecipient`. */
export type NotificationRecipientConnection = {
  __typename: 'NotificationRecipientConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationRecipientEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<NotificationRecipient>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `NotificationRecipient`. */
export type NotificationRecipientEdge = {
  __typename: 'NotificationRecipientEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NotificationRecipient;
};

export type NotificationRecipientPatchRequest = {
  id: Scalars['ID'];
  data: EditNotificationRecipient;
};

/** Represents the status of a notification sent to a tenant member. */
export enum NotificationRecipientStatus {
  /** None. */
  None = 'NONE',
  /** Unread. */
  Unread = 'UNREAD',
  /** Read. */
  Read = 'READ'
}

/** Input used to query s. */
export type NotificationRecipientsRetrieveInput = {
  /** The ids of the notification recipients */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The statuses of the notification recipients */
  statuses?: Maybe<Array<NotificationRecipientStatus>>;
  /** The types of the notification recipients */
  types?: Maybe<Array<NotificationType>>;
  /** The types of the notification channels */
  channelTypes?: Maybe<Array<NotificationChannelTypes>>;
};

/** Represents the base functionality of a notification subscription. */
export type NotificationSubscription = {
  /** The notification channel types enabled for this subscription. */
  channelTypes: Array<NotificationChannelTypes>;
  /** The id. */
  id: Scalars['ID'];
  /** The notification subscription status. */
  status: NotificationSubscriptionStatus;
};

/** A connection from an object to a list of objects of type `NotificationSubscriptionInterface`. */
export type NotificationSubscriptionInterfaceConnection = {
  __typename: 'NotificationSubscriptionInterfaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationSubscriptionInterfaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<RunNotificationSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `NotificationSubscriptionInterface`. */
export type NotificationSubscriptionInterfaceEdge = {
  __typename: 'NotificationSubscriptionInterfaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RunNotificationSubscription;
};

/** Statuses of the . */
export enum NotificationSubscriptionStatus {
  /** None. */
  None = 'NONE',
  /** Active. */
  Active = 'ACTIVE',
  /** Paused. */
  Paused = 'PAUSED'
}

/** Input used to query s. */
export type NotificationSubscriptionsRetrieveInput = {
  /** The ids of the notification subscriptions */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The statuses of the notification subscriptions */
  statuses?: Maybe<Array<NotificationSubscriptionStatus>>;
  /** The types of the notification subscriptions */
  types?: Maybe<Array<NotificationType>>;
};

/**
 * Represents a discrimator for different type of
 *             and  implementations.
 */
export enum NotificationType {
  /** None. */
  None = 'NONE',
  /** Run. */
  Run = 'RUN'
}

/** Configuration for  of type . */
export type OAuth2Configuration = IntegrationConfigurationInterface & {
  __typename: 'OAuth2Configuration';
  /** The authorization url */
  authorizationUrl?: Maybe<Scalars['String']>;
  /** The client id */
  clientId?: Maybe<Scalars['String']>;
  /** The id */
  id: Scalars['ID'];
  /** The integration metadata this configuration belongs to */
  integrationMetadata: IntegrationMetadata;
  /** The provider type */
  providerType: OAuth2ProviderType;
  /** The scopes */
  scopes?: Maybe<Scalars['String']>;
};

/** Represents an integration of type OAuth2. */
export type OAuth2Integration = Integration & {
  __typename: 'OAuth2Integration';
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration display name. */
  displayName: Scalars['String'];
  hasSession: Scalars['Boolean'];
  /** The id. */
  id: Scalars['ID'];
  integrationMetadata?: Maybe<IntegrationMetadata>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The integration status. */
  status: IntegrationStatus;
};


/** Represents an integration of type OAuth2. */
export type OAuth2IntegrationHasSessionArgs = {
  sessionId: Scalars['String'];
};

/** Input to retrieve an OAuth2 . */
export type OAuth2IntegrationRetrieveInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
  /** The session id. */
  sessionId?: Maybe<Scalars['String']>;
};

/** Provider types for s. */
export enum OAuth2ProviderType {
  /** None. */
  None = 'NONE',
  /** Generic. */
  Generic = 'GENERIC'
}

/** Metadata for a package. */
export type PackageMetadata = {
  __typename: 'PackageMetadata';
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The id of the package */
  id: Scalars['ID'];
  /** The unique name of the package */
  name: Scalars['ID'];
  /** The date when the package was published */
  publishedOn: Scalars['DateTime'];
  /** The publisher (tenant) who published this package */
  publisher: Publisher;
  /** The publish status of the package */
  publishStatus: PackagePublishStatus;
  /** The readme of the package */
  readme?: Maybe<Scalars['String']>;
  /** The id of the tenant where the package is published */
  tenantId: Scalars['ID'];
  /** The version of the package */
  version: Scalars['String'];
};

/** A connection from an object to a list of objects of type `PackageMetadata`. */
export type PackageMetadataConnection = {
  __typename: 'PackageMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<PackageMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<PackageMetadata>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `PackageMetadata`. */
export type PackageMetadataEdge = {
  __typename: 'PackageMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PackageMetadata>;
};

/** Input for retrieving package metadata. */
export type PackageMetadataRetrieveInput = {
  /** Filter by package ids */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** Filter by publisher names */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions */
  packageVersions?: Maybe<Array<Scalars['String']>>;
};

/** Defines the display model for a flattened model of the data from the database. */
export type PackagePermissionItem = {
  __typename: 'PackagePermissionItem';
  /** The date that the invitation was created. */
  createdDate: Scalars['DateTime'];
  /** The name or note to display regarding the package and version scope combination of this permission. */
  displayName: Scalars['String'];
  /** The package name referenced by this permission. */
  packageName: Scalars['String'];
  /** The date that the invitation was accepted, or null if that has not transpired. */
  responseDate?: Maybe<Scalars['DateTime']>;
  /** The name of the sharing target, such as 'Community Library', the display name of a tenant, or empty if there is no reply yet. */
  sharedWithDisplayName: Scalars['String'];
  /** The version range this permission represents. */
  versionScope: Scalars['String'];
};

/** A connection from an object to a list of objects of type `PackagePermissionItem`. */
export type PackagePermissionItemConnection = {
  __typename: 'PackagePermissionItemConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<PackagePermissionItemEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<PackagePermissionItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `PackagePermissionItem`. */
export type PackagePermissionItemEdge = {
  __typename: 'PackagePermissionItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PackagePermissionItem>;
};

/** The stage at which a package is published. */
export enum PackagePublishStatus {
  /** None. */
  None = 'NONE',
  /** Pending image creation. */
  PendingImage = 'PENDING_IMAGE',
  /** Successfully published. */
  Published = 'PUBLISHED',
  /** Failed. */
  Failed = 'FAILED'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Input for publishing a package. */
export type PublishPackageInput = {
  /** The url of the Git repository containing the package */
  gitRepoUrl: Scalars['String'];
  /** The path to the package within the repository. Defaults to root if empty */
  path?: Maybe<Scalars['String']>;
  /** Whether or not the publish should be forced through. If true, any existing packages with the same name and version will be overwritten */
  force?: Maybe<Scalars['Boolean']>;
  /** The name of the branch of the repository to publish. Defaults to 'master'. */
  branchName?: Maybe<Scalars['String']>;
  /** The optional json-serialized file containing additional package values */
  packageValuesJson?: Maybe<Scalars['String']>;
};

/** Represents a tenant entry record in a response. */
export type Publisher = {
  __typename: 'Publisher';
  /** Publisher Display Name */
  displayName: Scalars['String'];
  /** Publisher ID */
  id: Scalars['ID'];
  /** Unique Publisher Name */
  name: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  currentSystemUser?: Maybe<SystemUser>;
  tenantIdp?: Maybe<TenantIdpModel>;
  checkNormalizedNameAvailability: CheckNormalizedNameAvailability;
  checkTenantNameExistence: Scalars['Boolean'];
  getChargeBeeSession: ChargeBeePortalSessionObject;
  integration: CustomWorkflowIntegration | OAuth2Integration;
  integrations?: Maybe<IntegrationInterfaceConnection>;
  launchControlItemsToTemplatesMappings: Array<LaunchControlItemMappings>;
  me?: Maybe<TenantMember>;
  notificationRecipient?: Maybe<NotificationRecipient>;
  notificationRecipients?: Maybe<NotificationRecipientConnection>;
  notificationSubscription?: Maybe<RunNotificationSubscription>;
  notificationSubscriptions?: Maybe<NotificationSubscriptionInterfaceConnection>;
  oAuth2Integration?: Maybe<OAuth2Integration>;
  tenantMembers: Array<TenantMember>;
  tenants: Array<Tenant>;
  workspace?: Maybe<Workspace>;
  workspaces?: Maybe<WorkspaceConnection>;
  automationMetadata?: Maybe<AutomationMetadata>;
  automationMetadatas?: Maybe<AutomationMetadataConnection>;
  dashboardMetadata?: Maybe<DashboardMetadata>;
  dashboardMetadatas?: Maybe<DashboardMetadataConnection>;
  dataSetMetadata?: Maybe<DataSetMetadata>;
  dataSetMetadatas?: Maybe<DataSetMetadataConnection>;
  highestPatchTemplate?: Maybe<AutomationTemplate | DataSetTemplate>;
  integrationMetadata?: Maybe<IntegrationMetadata>;
  integrationMetadataByName?: Maybe<IntegrationMetadata>;
  integrationMetadatas?: Maybe<IntegrationMetadataConnection>;
  integrationMetadatasByName: Array<IntegrationMetadata>;
  latestTemplate?: Maybe<AutomationTemplate | DataSetTemplate>;
  libraryFilteringMetadata?: Maybe<LibraryFilteringMetadata>;
  libraryItems?: Maybe<LibraryItemConnection>;
  package?: Maybe<PackageMetadata>;
  packagePermissions?: Maybe<PackagePermissionItemConnection>;
  packageVersions?: Maybe<PackageMetadataConnection>;
  template?: Maybe<AutomationTemplate | DataSetTemplate>;
  templates?: Maybe<TemplateMetadataConnection>;
  fileDescriptor?: Maybe<FileDescriptor | FolderDescriptor>;
  fileDescriptors?: Maybe<FileSystemDescriptorInterfaceConnection>;
  listGlobalData: ListGlobalDataPayload;
  dashboard?: Maybe<Dashboard>;
  dashboardExportOperation: DashboardExportOperation;
  dashboards?: Maybe<DashboardConnection>;
  dataRequests?: Maybe<DataRequestConnection>;
  dataSet?: Maybe<DataSet>;
  dataSets?: Maybe<DataSetConnection>;
  instance?: Maybe<Instance>;
  instances?: Maybe<InstanceConnection>;
  scheduledJob?: Maybe<ScheduledJob>;
  scheduledJobs?: Maybe<ScheduledJobConnection>;
  step?: Maybe<Step>;
  steps?: Maybe<StepConnection>;
  templateConfiguration?: Maybe<TemplateConfiguration>;
  templateConfigurations?: Maybe<TemplateConfigurationConnection>;
};


export type QueryCheckNormalizedNameAvailabilityArgs = {
  input: Scalars['String'];
};


export type QueryCheckTenantNameExistenceArgs = {
  input: Scalars['String'];
};


export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryIntegrationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: IntegrationsRetrieveInput;
};


export type QueryNotificationRecipientArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationRecipientsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: NotificationRecipientsRetrieveInput;
};


export type QueryNotificationSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: NotificationSubscriptionsRetrieveInput;
};


export type QueryOAuth2IntegrationArgs = {
  input: OAuth2IntegrationRetrieveInput;
};


export type QueryTenantMembersArgs = {
  input: TenantMembersRetrieveInput;
};


export type QueryWorkspaceArgs = {
  id: Scalars['ID'];
};


export type QueryWorkspacesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: WorkspaceRetrieveInput;
};


export type QueryAutomationMetadataArgs = {
  id: Scalars['ID'];
};


export type QueryAutomationMetadatasArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: AutomationMetadatasRetrieveInput;
};


export type QueryDashboardMetadataArgs = {
  id: Scalars['ID'];
};


export type QueryDashboardMetadatasArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DashboardMetadatasRetrieveInput;
};


export type QueryDataSetMetadataArgs = {
  id: Scalars['ID'];
};


export type QueryDataSetMetadatasArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DataSetMetadatasRetrieveInput;
};


export type QueryHighestPatchTemplateArgs = {
  input: TemplateMetadataRetrieveHighestPatchVersionInput;
};


export type QueryIntegrationMetadataArgs = {
  id: Scalars['ID'];
};


export type QueryIntegrationMetadataByNameArgs = {
  package: Scalars['String'];
  integrationName: Scalars['String'];
};


export type QueryIntegrationMetadatasArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: IntegrationMetadataRetrieveInput;
};


export type QueryIntegrationMetadatasByNameArgs = {
  input: GetIntegrationMetadatasByNameInput;
};


export type QueryLatestTemplateArgs = {
  input: TemplateMetadataRetrieveLatestInput;
};


export type QueryLibraryItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: LibraryItemsRetrieveInput;
};


export type QueryPackageArgs = {
  id: Scalars['ID'];
};


export type QueryPackagePermissionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryPackageVersionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: PackageMetadataRetrieveInput;
};


export type QueryTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: TemplateMetadataRetrieveInput;
};


export type QueryFileDescriptorArgs = {
  input: FileSystemDescriptorInput;
};


export type QueryFileDescriptorsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: FileSystemDescriptorRetrieveInput;
};


export type QueryListGlobalDataArgs = {
  input: ListGlobalDataInput;
};


export type QueryDashboardArgs = {
  id: Scalars['ID'];
};


export type QueryDashboardExportOperationArgs = {
  input: DashboardExportRetrieveInput;
};


export type QueryDashboardsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DashboardsRetrieveInput;
};


export type QueryDataRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DataRequestRetrieveInput;
};


export type QueryDataSetArgs = {
  id: Scalars['ID'];
};


export type QueryDataSetsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: DataSetsRetrieveInput;
};


export type QueryInstanceArgs = {
  id: Scalars['ID'];
};


export type QueryInstancesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: InstanceRetrieveInput;
};


export type QueryScheduledJobArgs = {
  id: Scalars['ID'];
};


export type QueryScheduledJobsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: ScheduledJobsRetrieveInput;
};


export type QueryStepArgs = {
  id: Scalars['ID'];
};


export type QueryStepsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: StepRetrieveInput;
};


export type QueryTemplateConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryTemplateConfigurationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: TemplateConfigurationsRetrieveInput;
};

/** A message containing information about a region info. */
export type RegionInfo = {
  __typename: 'RegionInfo';
  /** The display name */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  /** The short name */
  shortName: Scalars['String'];
};

export type ReplaceDataSetScheduleInput = {
  /** The id of the workspace the data set belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the data set to replace the schedule */
  dataSetId: Scalars['ID'];
  /** The recurrence pattern in cron format */
  pattern: Scalars['String'];
  /** The timezone name */
  timezoneName: Scalars['String'];
};

/** Defines some values fed back to a user who shares a package internally. */
export type RequestPackagePermissionRefreshResponse = {
  __typename: 'RequestPackagePermissionRefreshResponse';
  /** Whether or not the request was successfully sent. */
  succeeded: Scalars['Boolean'];
};

/** Resend invitation email input. */
export type ResendEmailInvitationInput = {
  /** Id of the tenant member to resend an invitation to. */
  tenantMemberId: Scalars['String'];
};

/** The input to reset an OAuth2 integration session. */
export type ResetCustomWorkflowIntegrationInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Contains the payload of resetting a custom workflow integration. */
export type ResetCustomWorkflowIntegrationPayload = {
  __typename: 'ResetCustomWorkflowIntegrationPayload';
  /** The reset custom workflow integration. */
  integration?: Maybe<CustomWorkflowIntegration>;
};

export type ResetDataSetConfigurationDataInput = {
  /** The id of the workspace the dataset belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the dataset */
  dataSetId: Scalars['ID'];
  /** The id of the person performing the reset */
  resetById: Scalars['ID'];
};

/** The input to reset an OAuth2 integration session. */
export type ResetOAuth2IntegrationInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

export type ResetTemplateConfigurationDataInput = {
  /** The id of the workspace the template configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the template configuration */
  templateConfigurationId: Scalars['ID'];
  /** The id of the person performing the reset */
  resetById: Scalars['ID'];
};

/** The input values defining an internal Package Permission to add */
export type RevokeInternalPackagePermissionInput = {
  /** The package name referenced by this request, used to derive other relevant data about the permission. */
  packageName: Scalars['String'];
  /** The target of the sharing process */
  sharingTarget: InternalSharingTarget;
};

/** The response for revoking an internal package permission. */
export type RevokeInternalPackagePermissionResponse = {
  __typename: 'RevokeInternalPackagePermissionResponse';
  /** The number of shares revoked. */
  authorizationsRevoked: Scalars['Int'];
  /** The name of the package being shared. */
  sharedPackageName: Scalars['String'];
};

/** Represents a  of type Run. */
export type RunNotification = Notification & {
  __typename: 'RunNotification';
  /** The created on date. */
  createdOn: Scalars['DateTime'];
  /** The id. */
  id: Scalars['ID'];
  instance?: Maybe<Instance>;
  /** The id of the instance related to this notification. */
  instanceId: Scalars['ID'];
  /** The notification message. */
  message?: Maybe<Scalars['String']>;
  /** The type of notification event that matched this notification to a subscription. */
  runNotificationType: RunNotificationType;
  /** The run status that triggered the notification. */
  runStatus: RunStatus;
  /** The id of the step related to this notification. */
  stepId?: Maybe<Scalars['ID']>;
  /** The notification subscription display name. */
  subject: Scalars['String'];
  /** The workspace associated with the instance run. */
  workspace?: Maybe<Workspace>;
};

/** Represents a NotificationSubscription of type Run. */
export type RunNotificationSubscription = NotificationSubscription & {
  __typename: 'RunNotificationSubscription';
  /** The notification channel types enabled for this subscription. */
  channelTypes: Array<NotificationChannelTypes>;
  /** The id. */
  id: Scalars['ID'];
  /** The type of run notification. */
  runNotificationType: RunNotificationType;
  /** The notification subscription status. */
  status: NotificationSubscriptionStatus;
  templateConfiguration?: Maybe<TemplateConfiguration>;
  /** The optional template configuration id the subscription watches. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The workspace if one is associated with this subscription. */
  workspace?: Maybe<Workspace>;
};

export type RunNotificationSubscriptionPatchRequest = {
  id: Scalars['ID'];
  data: EditRunNotificationSubscriptionInput;
};

/** Represents the notification types for instance runs. */
export enum RunNotificationType {
  /** None. */
  None = 'NONE',
  /** WaitingForInput. */
  WaitingForInput = 'WAITING_FOR_INPUT',
  /** Completed. */
  Completed = 'COMPLETED',
  /** Failed. */
  Failed = 'FAILED'
}

/** The status of the instance run that triggered the notification. */
export enum RunStatus {
  /** Unknown. */
  Unknown = 'UNKNOWN',
  /** Scheduled. */
  Scheduled = 'SCHEDULED',
  /** Running. */
  Running = 'RUNNING',
  /** Completed successfully. */
  Completed = 'COMPLETED',
  /** Completed with errors. */
  Failed = 'FAILED',
  /** End user or API requested cancellation. */
  Canceled = 'CANCELED',
  /** Pending; actively being scheduled. */
  Pending = 'PENDING',
  /** End user or API requested cancellation and it is currently being cancelled. */
  Cancelling = 'CANCELLING',
  /** Occurs when the instance takes longer than the allocated time to finish or if any of its steps have timed out. */
  TimedOut = 'TIMED_OUT',
  /** A step in the run is awaiting input. */
  WaitingForInput = 'WAITING_FOR_INPUT'
}

/** Sale item type. */
export enum SaleItemType {
  /** Default value. */
  Unknown = 'UNKNOWN',
  /** Plan sale item type. */
  Plan = 'PLAN',
  /** Addon sale item plan. */
  Addon = 'ADDON'
}

export type ScheduledJob = {
  __typename: 'ScheduledJob';
  createdBy?: Maybe<TenantMember>;
  /** Id of the tenant member who created the record */
  createdById?: Maybe<Scalars['String']>;
  /** The created on date */
  createdOn: Scalars['DateTime'];
  /** The end time */
  endTime?: Maybe<Scalars['DateTime']>;
  /** The id */
  id: Scalars['ID'];
  instances?: Maybe<InstanceConnection>;
  /** Whether it has been deleted */
  isDeleted: Scalars['Boolean'];
  lastStartedInstance?: Maybe<Instance>;
  /** The max recurrence count */
  maxRecurrenceCount?: Maybe<Scalars['Int']>;
  /** The recurrence pattern in cron format */
  pattern?: Maybe<Scalars['String']>;
  /** The date when the schedule was last updated */
  scheduleUpdatedOn?: Maybe<Scalars['DateTimeOffset']>;
  /** The start time */
  startTime?: Maybe<Scalars['DateTime']>;
  /** The status */
  status: ScheduledJobStatus;
  /** The template configuration */
  templateConfiguration: TemplateConfiguration;
  /** The timezone name */
  timezoneName: Scalars['String'];
};


export type ScheduledJobInstancesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: ScheduledJobInstancesRetrieveInput;
};

/** A connection from an object to a list of objects of type `ScheduledJob`. */
export type ScheduledJobConnection = {
  __typename: 'ScheduledJobConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ScheduledJobEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<ScheduledJob>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `ScheduledJob`. */
export type ScheduledJobEdge = {
  __typename: 'ScheduledJobEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduledJob>;
};

export type ScheduledJobInstancesRetrieveInput = {
  /** The statuses of the instance */
  statuses?: Maybe<Array<InstanceStatus>>;
  /** The minimum UpdatedOn date */
  updatedOnAfter?: Maybe<Scalars['DateTime']>;
  /** The launched by type */
  launchedByTypes?: Maybe<Array<LaunchedByType>>;
  /** The launched by id */
  launchedById?: Maybe<Scalars['ID']>;
};

export enum ScheduledJobStatus {
  Unknown = 'UNKNOWN',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Terminated = 'TERMINATED',
  Canceled = 'CANCELED'
}

export type ScheduledJobsRetrieveInput = {
  /** The ids of the scheduled jobs */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the template configuration that created the schedules */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The statuses of the schedules */
  statuses?: Maybe<Array<ScheduledJobStatus>>;
  /** The template configuration types to filter by */
  templateConfigurationTypes?: Maybe<Array<TemplateConfigurationType>>;
};

/** Send email confirmation code input. */
export type SendEmailConfirmationCodeInput = {
  /** Id of the user to send the email to. */
  userId: Scalars['String'];
  /** Return url to redirect after the user verifies email. */
  returnUrl?: Maybe<Scalars['String']>;
};

/** Send email confirmation code result. */
export type SendEmailConfirmationCodeResult = {
  __typename: 'SendEmailConfirmationCodeResult';
  /** Id of the user for whom the confirmation code was created. */
  userId: Scalars['String'];
};

export type SetGlobalDataInput = {
  /** The data access level for the file */
  dataAccessLevel: GlobalDataAccessLevelType;
  /** The name of the publisher */
  publisher: Scalars['String'];
  /** The name of the package */
  package?: Maybe<Scalars['String']>;
  /** The name of the template */
  template?: Maybe<Scalars['String']>;
  /** The file contents as base64 string */
  data: Scalars['String'];
  /** Specifies whether the existing file be overwritten */
  overwrite?: Maybe<Scalars['Boolean']>;
  /** Specifies the path where the file should be uploaded */
  path: Scalars['String'];
};

export type SetGlobalDataPayload = {
  __typename: 'SetGlobalDataPayload';
  /** The uri that is built to set the global data */
  voleerUri?: Maybe<Scalars['String']>;
};

/** Set notification recipient status input. */
export type SetNotificationRecipientStatusByDateInput = {
  /** The status to set. */
  status: NotificationRecipientStatus;
  /** The date time that is greater than the last updated date time of the target recipients. */
  updatedBefore: Scalars['DateTime'];
};

/** Contains the payload of setting the status of a notification recipients by date. */
export type SetNotificationRecipientStatusByDateInputPayload = {
  __typename: 'SetNotificationRecipientStatusByDateInputPayload';
  /** The number of notification recipients updated. */
  numberOfRecipientsUpdated: Scalars['Int'];
};

/** Set password input. */
export type SetPasswordInput = {
  /** Password reset code. */
  resetCode: Scalars['String'];
  /** New password. */
  password: Scalars['String'];
  /** New password confirmation. */
  confirmPassword: Scalars['String'];
  /** User's id. */
  userId: Scalars['String'];
};

/** Represents an authentication . */
export type SetPasswordResult = {
  __typename: 'SetPasswordResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<IdentityError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
};

export type StartDashboardExportInput = {
  /** The id of the dashboard's workspace */
  workspaceId: Scalars['ID'];
  /** The id of the dashboard */
  dashboardId: Scalars['ID'];
  /** The captured state of the embedded dashboard use to specify the state that should be exported */
  bookmarkState?: Maybe<Scalars['String']>;
  /** The desired file format for the export */
  format: DashboardExportFileFormat;
  /** Indicates if hidden pages should be included in the export */
  includeHiddenPages?: Maybe<Scalars['Boolean']>;
  /** The locale (LCID) that defines the language formatting of the export */
  locale?: Maybe<Scalars['String']>;
  /** The unique names of the pages to include in the export */
  pageNames?: Maybe<Array<Scalars['String']>>;
};

export type StartDataSetSchemaMigrationDirectInput = {
  /** The id of the workspace to create the dataset in */
  workspaceId: Scalars['ID'];
  /** The data json schema manifest */
  dataSchemaManifest: Scalars['String'];
  /** The dataset id */
  dataSetId: Scalars['ID'];
};

export type StatusMessage = {
  __typename: 'StatusMessage';
  /** The message content */
  content: Scalars['String'];
  /** The created utc date */
  createdUtc: Scalars['DateTime'];
  /** The level of the message */
  level: StatusMessageLevel;
};

/** A connection from an object to a list of objects of type `StatusMessage`. */
export type StatusMessageConnection = {
  __typename: 'StatusMessageConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<StatusMessageEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<StatusMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `StatusMessage`. */
export type StatusMessageEdge = {
  __typename: 'StatusMessageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StatusMessage>;
};

export enum StatusMessageLevel {
  Unknown = 'UNKNOWN',
  Error = 'ERROR',
  Warning = 'WARNING',
  Information = 'INFORMATION'
}

export type Step = {
  __typename: 'Step';
  /** The completed on date */
  completedOn?: Maybe<Scalars['DateTime']>;
  /** The created on date */
  createdOn: Scalars['DateTime'];
  dataRequest?: Maybe<FormDataRequest>;
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** Does the step have logs to download */
  hasLogs: Scalars['Boolean'];
  /** The id */
  id: Scalars['ID'];
  /** The instance the step belongs to */
  instance: Instance;
  /** The display name */
  name: Scalars['String'];
  /** The started on date */
  startedOn?: Maybe<Scalars['DateTime']>;
  /** The status */
  status: StepStatus;
  statusMessages?: Maybe<StatusMessageConnection>;
  taskSummary?: Maybe<Scalars['String']>;
};


export type StepStatusMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

/** A connection from an object to a list of objects of type `Step`. */
export type StepConnection = {
  __typename: 'StepConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<StepEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<Step>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Step`. */
export type StepEdge = {
  __typename: 'StepEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Step>;
};

export type StepRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the instance the step belongs to */
  instanceId?: Maybe<Scalars['ID']>;
  /** The statuses of the step */
  statuses?: Maybe<Array<StepStatus>>;
};

export enum StepStatus {
  Unknown = 'UNKNOWN',
  Scheduled = 'SCHEDULED',
  WaitingForInput = 'WAITING_FOR_INPUT',
  Running = 'RUNNING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED'
}

export type StepsForInstanceRetrieveInput = {
  /** The statuses of the step */
  statuses?: Maybe<Array<StepStatus>>;
};

/** Adds profile data for application users by adding properties to the ApplicationUser class. */
export type SystemUser = {
  __typename: 'SystemUser';
  claims?: Maybe<Array<Maybe<Claim>>>;
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  id: Scalars['String'];
  phoneNumberConfirmed: Scalars['Boolean'];
  userName: Scalars['String'];
};

export type TemplateConfiguration = {
  __typename: 'TemplateConfiguration';
  candidateData?: Maybe<ConfigurationData>;
  configurationInstance?: Maybe<Instance>;
  createdBy?: Maybe<TenantMember>;
  /** Id of the entity that created the template configuration. */
  createdById: Scalars['ID'];
  /** Type of the entity that created the template configuration. */
  createdByType: TemplateConfigurationCreatedByType;
  /** The created on date */
  createdOn: Scalars['DateTime'];
  data?: Maybe<ConfigurationData>;
  dataSet?: Maybe<DataSet>;
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The exposure level */
  exposureLevel: TemplateConfigurationExposureLevel;
  highestPatchTemplateMetadataId?: Maybe<Scalars['ID']>;
  /** The id */
  id: Scalars['ID'];
  instances?: Maybe<InstanceConnection>;
  /** Whether it has been deleted */
  isDeleted: Scalars['Boolean'];
  lastStartedInstance?: Maybe<Instance>;
  scheduledJobs?: Maybe<ScheduledJobConnection>;
  /** The status */
  status: TemplateConfigurationStatus;
  templateMetadata?: Maybe<AutomationTemplate | DataSetTemplate>;
  /** The template metadata id */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The workspace id */
  workspaceId: Scalars['ID'];
};


export type TemplateConfigurationInstancesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: TemplateConfigurationInstancesRetrieveInput;
};


export type TemplateConfigurationScheduledJobsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  input: TemplateConfigurationScheduledJobsRetrieveInput;
};

/** A connection from an object to a list of objects of type `TemplateConfiguration`. */
export type TemplateConfigurationConnection = {
  __typename: 'TemplateConfigurationConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<TemplateConfigurationEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<TemplateConfiguration>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

export enum TemplateConfigurationCreatedByType {
  Unknown = 'UNKNOWN',
  TenantMember = 'TENANT_MEMBER'
}

/** An edge in a connection from an object to another object of type `TemplateConfiguration`. */
export type TemplateConfigurationEdge = {
  __typename: 'TemplateConfigurationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TemplateConfiguration>;
};

export enum TemplateConfigurationExposureLevel {
  Unknown = 'UNKNOWN',
  Standalone = 'STANDALONE',
  OnDemand = 'ON_DEMAND'
}

export type TemplateConfigurationInstancesRetrieveInput = {
  /** The statuses of the instance */
  statuses?: Maybe<Array<InstanceStatus>>;
  /** The minimum UpdatedOn date */
  updatedOnAfter?: Maybe<Scalars['DateTime']>;
  /** The launched by type */
  launchedByTypes?: Maybe<Array<LaunchedByType>>;
  /** The launched by id */
  launchedById?: Maybe<Scalars['ID']>;
};

export type TemplateConfigurationPatchRequest = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  data: EditTemplateConfiguration;
};

export type TemplateConfigurationScheduledJobsRetrieveInput = {
  /** The statuses of the schedules */
  statuses?: Maybe<Array<ScheduledJobStatus>>;
};

export enum TemplateConfigurationStatus {
  Unknown = 'UNKNOWN',
  Pending = 'PENDING',
  Ready = 'READY',
  Provisioning = 'PROVISIONING',
  Invalid = 'INVALID',
  Deleting = 'DELETING',
  Deleted = 'DELETED'
}

export enum TemplateConfigurationType {
  Unknown = 'UNKNOWN',
  Automation = 'AUTOMATION',
  DataSet = 'DATA_SET'
}

export type TemplateConfigurationsRetrieveInput = {
  /** The workspace id */
  workspaceId: Scalars['ID'];
  /** The id of the template metadata the template configurations were created for */
  templateMetadataId?: Maybe<Scalars['ID']>;
  /** The ids of the template configurations */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The statuses of the template configurations */
  statuses?: Maybe<Array<TemplateConfigurationStatus>>;
  /** The list of display names */
  displayNames?: Maybe<Array<Scalars['String']>>;
};

/** Metadata for a template. */
export type TemplateMetadata = {
  /** The description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName: Scalars['String'];
  /** The id */
  id: Scalars['ID'];
  /** The name of the template, unique within the package */
  name: Scalars['String'];
  /** The metadata of the package the template belongs to */
  packageMetadata: PackageMetadata;
  /** The readme of the template */
  readme?: Maybe<Scalars['String']>;
  /** The template type */
  type: TemplateMetadataType;
};

/** A connection from an object to a list of objects of type `TemplateMetadata`. */
export type TemplateMetadataConnection = {
  __typename: 'TemplateMetadataConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<TemplateMetadataEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<AutomationTemplate | DataSetTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `TemplateMetadata`. */
export type TemplateMetadataEdge = {
  __typename: 'TemplateMetadataEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AutomationTemplate | DataSetTemplate>;
};

/** Input for requesting the template metadata with highest patch version. */
export type TemplateMetadataRetrieveHighestPatchVersionInput = {
  /** The  id. */
  templateMetadataId: Scalars['ID'];
};

/** Input for requesting template metadata. */
export type TemplateMetadataRetrieveInput = {
  /** Filter by template metadata ids */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** Filter by publisher names */
  publisherNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by template names */
  names?: Maybe<Array<Scalars['String']>>;
  /** Filter by package names */
  packageNames?: Maybe<Array<Scalars['String']>>;
  /** Filter by package versions */
  packageVersions?: Maybe<Array<Scalars['String']>>;
  /** Filter by template types */
  types?: Maybe<Array<TemplateMetadataType>>;
};

/** Input for requesting the latest viable template metadata version. */
export type TemplateMetadataRetrieveLatestInput = {
  /** The name of the package publisher. */
  publisherName: Scalars['String'];
  /** The name of the package. */
  packageName: Scalars['String'];
  /** The name of the template. */
  templateName: Scalars['String'];
};

/** Defines the type of a . */
export enum TemplateMetadataType {
  /** Unknown. */
  Unknown = 'UNKNOWN',
  /** Automation. */
  Automation = 'AUTOMATION',
  /** DataSet. */
  DataSet = 'DATA_SET'
}

/** Represents a tenant. */
export type Tenant = {
  __typename: 'Tenant';
  /** The account standing status */
  accountStatus: AccountStatus;
  /** Tenant display name */
  displayName: Scalars['String'];
  /** The Tenant Id */
  id: Scalars['ID'];
  /** Tenant normalized name */
  name: Scalars['String'];
  /** The next status date. */
  nextStatusDate?: Maybe<Scalars['DateTime']>;
  /** The name of the plan */
  planName: Scalars['String'];
  /** The collection of plans, addons etc */
  tenantSaleItems: Array<TenantSaleItem>;
};

/** A model defining the tenant identity provider. */
export type TenantIdpModel = {
  __typename: 'TenantIdpModel';
  /** If true, the tenant uses an external identity provider, otherwise it uses Voleer */
  externalProviderEnabled: Scalars['Boolean'];
  /** The configured name of the identity provider, also called providerId. Not user friendly. */
  providerName: Scalars['String'];
};

/** Input representing a tenant. */
export type TenantInput = {
  /** Tenant name. */
  name: Scalars['String'];
  /** Tenant display name. */
  displayName: Scalars['String'];
};

/** Represents a link between a user and a . */
export type TenantMember = {
  __typename: 'TenantMember';
  /** Tenant member email address */
  emailAddress: Scalars['String'];
  /** Tenant member first name */
  firstName: Scalars['String'];
  /** Tenant member Id */
  id: Scalars['ID'];
  /** Is the Tenant member active */
  isActive: Scalars['Boolean'];
  /** Tenant member last name */
  lastName: Scalars['String'];
  /** Last date at which the tenant member was seen */
  lastSeenOn?: Maybe<Scalars['DateTime']>;
  /** Tenant member role */
  role: TenantMemberRole;
  /** Whether or not the tenant member will be sent run summary email notifications. */
  sendRunSummaryViaEmail: Scalars['Boolean'];
  /** Id of the SystemUser associated with this tenant member */
  systemUserId?: Maybe<Scalars['String']>;
  /** Tenant of this tenant member */
  tenant: Tenant;
};

/** Tenant Member role. */
export enum TenantMemberRole {
  /** Default value. */
  Unknown = 'UNKNOWN',
  /** Administrator role. */
  Admin = 'ADMIN',
  /** User role. */
  User = 'USER'
}

/** Input for retrieving tenant members. */
export type TenantMembersRetrieveInput = {
  /** Ids of the tenant member to retrieve. */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** System user ids of the tenant members to retrieve. */
  systemUserIds?: Maybe<Array<Scalars['String']>>;
  /** Filter on active or inactive users. */
  isActive?: Maybe<Scalars['Boolean']>;
};

/** Represents tenants sales item. */
export type TenantSaleItem = {
  __typename: 'TenantSaleItem';
  /** ChargeBee internal plan or addon Id */
  chargeBeeItemId: Scalars['String'];
  /** Tenant sales item display name */
  displayName: Scalars['String'];
  /** The Tenant sales item Id */
  id: Scalars['ID'];
  /** The Tenant sales item type Id */
  itemType: SaleItemType;
  /** The quantity of the item */
  quantity?: Maybe<Scalars['Int']>;
  /** Tenant sales item last updated date */
  updatedOn?: Maybe<Scalars['DateTime']>;
};

/** GraphQL tenant user. */
export type TenantUserInput = {
  /** First name of the first member of the tenant */
  firstName: Scalars['String'];
  /** Last name of the first member of the tenant */
  lastName: Scalars['String'];
  /** Email address of the first member of the tenant */
  emailAddress: Scalars['String'];
  /** Password used to access the first member of the tenant */
  password: Scalars['String'];
};

/** Represents a . */
export type TenantsApiResult = {
  __typename: 'TenantsApiResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<TenantsError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
};

/** Encapsulates an error from the tenants subsystem. */
export type TenantsError = {
  __typename: 'TenantsError';
  /** Error code. */
  code: Scalars['String'];
  /** Error description. */
  description: Scalars['String'];
};

export type ToggleDataSetScheduleActivityInput = {
  /** The id of the workspace the data set belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the data set schedule to toggle */
  scheduleId: Scalars['ID'];
};

export type ToggleScheduledJobActivityInput = {
  /** The id of the workspace the scheduled job belongs to */
  workspaceId: Scalars['ID'];
  /** The id of the scheduled job to toggle */
  scheduleId: Scalars['ID'];
};

/** The input to trigger an integration refresh. */
export type TriggerIntegrationRefreshInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Contains the payload of triggering an integration schedule refresh operation. */
export type TriggerIntegrationRefreshPayload = {
  __typename: 'TriggerIntegrationRefreshPayload';
  /** The integration which has its refresh triggered. */
  integration?: Maybe<CustomWorkflowIntegration | OAuth2Integration>;
};


/** The input values for answering a package invitation. */
export type UpdateHandshakeInput = {
  /** The sharable single-use code used to activate this package for another tenant. */
  invitationCode: Scalars['String'];
  /** The reply to the sharing handshake. */
  audienceResponse: AudienceResponseState;
};

/** The response values for answering a package invitation. */
export type UpdateHandshakeResponse = {
  __typename: 'UpdateHandshakeResponse';
  /** The highest currently available package version. */
  latestAvailableVersion: Scalars['String'];
  /** The publish date of the most recent shared package. */
  latestPublishDate: Scalars['DateTime'];
  /** The name of the package being shared. */
  sharedPackageName: Scalars['String'];
  /** The version range being shared. */
  sharedVersionScope: Scalars['String'];
};

export type UpdateScheduledJobInput = {
  /** The id of the scheduled job to update */
  scheduledJobId: Scalars['ID'];
  /** The id of the workspace the template configuration belongs to */
  workspaceId: Scalars['ID'];
  /** The optional recurrence pattern in cron format */
  pattern?: Maybe<Scalars['String']>;
  /** The timezone name */
  timezoneName: Scalars['String'];
  /** The optional local start time */
  startTime?: Maybe<Scalars['DateTimeOffset']>;
  /** The optional local end time */
  endTime?: Maybe<Scalars['DateTimeOffset']>;
  /** The optional max recurrence count */
  maxRecurrenceCount?: Maybe<Scalars['Int']>;
};


export type ValidationMessage = {
  __typename: 'ValidationMessage';
  /** The message content */
  content: Scalars['String'];
  /** The created utc date */
  createdUtc: Scalars['DateTime'];
  /** The level of the message */
  level: ValidationMessageLevel;
};

/** A connection from an object to a list of objects of type `ValidationMessage`. */
export type ValidationMessageConnection = {
  __typename: 'ValidationMessageConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ValidationMessageEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `ValidationMessage`. */
export type ValidationMessageEdge = {
  __typename: 'ValidationMessageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ValidationMessage>;
};

export enum ValidationMessageLevel {
  Unknown = 'UNKNOWN',
  Error = 'ERROR',
  Warning = 'WARNING',
  Information = 'INFORMATION'
}

export enum ValidationStatus {
  Unknown = 'UNKNOWN',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}

/** Represents a workspace. */
export type Workspace = {
  __typename: 'Workspace';
  /** The display name */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  /** The information about the region the workspace is in */
  regionInfo: RegionInfo;
  /** The tenant. */
  tenant: Tenant;
};

/** A connection from an object to a list of objects of type `Workspace`. */
export type WorkspaceConnection = {
  __typename: 'WorkspaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<WorkspaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<Workspace>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Workspace`. */
export type WorkspaceEdge = {
  __typename: 'WorkspaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Workspace>;
};

/** Represents a workspace. */
export type WorkspaceInput = {
  /** The workspace id. */
  id: Scalars['ID'];
};

/** Input for retrieving workspaces. */
export type WorkspaceRetrieveInput = {
  /** Display names to filter by. */
  displayNames?: Maybe<Array<Scalars['String']>>;
};

export type TemplateConfigurationSelectorQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  templateMetadataId?: Maybe<Scalars['ID']>;
}>;


export type TemplateConfigurationSelectorQuery = { templateConfigurations?: Maybe<{ items?: Maybe<Array<Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>>>> }> };

export type WorkspaceSelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceSelectorQuery = { workspaces?: Maybe<{ items?: Maybe<Array<Maybe<Pick<Workspace, 'id' | 'displayName'>>>> }> };

export type NotificationsDropButtonQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type NotificationsDropButtonQuery = { notificationRecipients?: Maybe<(
    Pick<NotificationRecipientConnection, 'totalCount'>
    & { edges?: Maybe<Array<Maybe<(
      Pick<NotificationRecipientEdge, 'cursor'>
      & { node: NotificationsDropButtonNotificationRecipientFragment }
    )>>>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> }
  )> };

export type NotificationsDropButtonDismissNotificationMutationVariables = Exact<{
  input: NotificationRecipientPatchRequest;
}>;


export type NotificationsDropButtonDismissNotificationMutation = { editNotificationRecipient: { notificationRecipient?: Maybe<NotificationsDropButtonNotificationRecipientFragment> } };

export type NotificationsDropButtonDismissAllNotificationsMutationVariables = Exact<{
  input: SetNotificationRecipientStatusByDateInput;
}>;


export type NotificationsDropButtonDismissAllNotificationsMutation = { setNotificationRecipientStatusByDate: Pick<SetNotificationRecipientStatusByDateInputPayload, 'numberOfRecipientsUpdated'> };

export type NotificationsDropButtonNotificationRecipientFragment = (
  Pick<NotificationRecipient, 'id' | 'createdOn' | 'status'>
  & { notification: (
    Pick<RunNotification, 'id' | 'subject'>
    & { instance?: Maybe<Pick<Instance, 'id' | 'displayName'>>, workspace?: Maybe<Pick<Workspace, 'id' | 'displayName'>> }
  ) }
);

export type ChargeBeePortalQueryVariables = Exact<{ [key: string]: never; }>;


export type ChargeBeePortalQuery = { getChargeBeeSession: Pick<ChargeBeePortalSessionObject, 'access_url' | 'created_at' | 'customer_id' | 'expires_at' | 'id' | 'object' | 'status' | 'token'> };

export type DeleteDashboardModalQueryVariables = Exact<{
  dashboardId: Scalars['ID'];
}>;


export type DeleteDashboardModalQuery = { dashboard?: Maybe<Pick<Dashboard, 'description' | 'displayName' | 'id' | 'workspaceId'>> };

export type DeleteDashboardModalSubmitMutationVariables = Exact<{
  dashboardId: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type DeleteDashboardModalSubmitMutation = { deleteDashboard?: Maybe<Pick<Dashboard, 'id' | 'isDeleted' | 'workspaceId'>> };

export type AddIntegrationModalQueryVariables = Exact<{
  integrationMetadataIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AddIntegrationModalQuery = { integrationMetadatas?: Maybe<{ items?: Maybe<Array<Maybe<AddIntegrationModalIntegrationMetadataFragment>>> }> };

export type AddIntegrationModalOAuth2ConnectionStatusQueryVariables = Exact<{
  integrationId: Scalars['ID'];
  sessionId: Scalars['String'];
}>;


export type AddIntegrationModalOAuth2ConnectionStatusQuery = { oAuth2Integration?: Maybe<Pick<OAuth2Integration, 'id' | 'status'>> };

export type AddIntegrationModalConnectOAuth2IntegrationMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  integrationMetadataId: Scalars['ID'];
}>;


export type AddIntegrationModalConnectOAuth2IntegrationMutation = { createOAuth2Integration: Pick<CreateOAuth2IntegrationSessionResult, 'integrationId' | 'sessionId'> };

export type AddIntegrationModalCreateCustomWorkflowIntegrationMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  integrationMetadataId: Scalars['ID'];
}>;


export type AddIntegrationModalCreateCustomWorkflowIntegrationMutation = { createCustomWorkflowIntegration: { integration?: Maybe<Pick<CustomWorkflowIntegration, 'id'>> } };

export type AddIntegrationModalIntegrationMetadataFragment = (
  { __typename: 'IntegrationMetadata' }
  & Pick<IntegrationMetadata, 'id' | 'description' | 'displayName' | 'readme' | 'type'>
  & { packageMetadata: (
    Pick<PackageMetadata, 'id' | 'publishedOn' | 'version'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
  & IntegrationMetadataInfoRowIntegrationMetadataFragment
);

export type DeleteIntegrationModalQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type DeleteIntegrationModalQuery = { integration: Pick<CustomWorkflowIntegration, 'id' | 'displayName'> | Pick<OAuth2Integration, 'id' | 'displayName'> };

export type DeleteIntegrationModalDeleteMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type DeleteIntegrationModalDeleteMutation = Pick<Mutation, 'deleteIntegration'>;

export type IntegrationMetadataInfoRowQueryVariables = Exact<{
  integrationMetadataId: Scalars['ID'];
}>;


export type IntegrationMetadataInfoRowQuery = { integrationMetadata?: Maybe<IntegrationMetadataInfoRowIntegrationMetadataFragment> };

export type IntegrationMetadataInfoRowIntegrationMetadataFragment = (
  Pick<IntegrationMetadata, 'id' | 'displayName' | 'type'>
  & { packageMetadata: (
    Pick<PackageMetadata, 'id' | 'version' | 'publishedOn'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

export type ConfigureCustomWorkflowIntegrationQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type ConfigureCustomWorkflowIntegrationQuery = { integration: (
    { __typename: 'CustomWorkflowIntegration' }
    & Pick<CustomWorkflowIntegration, 'id'>
    & ConfigureCustomWorkflowIntegrationFragment
  ) | (
    { __typename: 'OAuth2Integration' }
    & Pick<OAuth2Integration, 'id'>
  ) };

export type ConfigureCustomWorkflowIntegrationFragment = (
  { __typename: 'CustomWorkflowIntegration' }
  & Pick<CustomWorkflowIntegration, 'id' | 'description' | 'displayName' | 'status'>
  & { candidateInstance?: Maybe<(
    Pick<Instance, 'id' | 'status'>
    & { steps?: Maybe<{ items?: Maybe<Array<Maybe<(
        { dataRequest?: Maybe<FormDataRequestDetailsDataRequestFragment> }
        & StepDetailsStepFragment
      )>>> }> }
  )>, integrationMetadata?: Maybe<ConfigureCustomWorkflowIntegrationMetadataFragment> }
);

export type ConfigureCustomWorkflowIntegrationMetadataFragment = (
  { __typename: 'IntegrationMetadata' }
  & Pick<IntegrationMetadata, 'id' | 'description' | 'displayName' | 'readme' | 'type'>
  & { packageMetadata: (
    Pick<PackageMetadata, 'id' | 'publishedOn' | 'version'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

export type IntegrationModalQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type IntegrationModalQuery = { integration: IntegrationModalIntegration_CustomWorkflowIntegration_Fragment | IntegrationModalIntegration_OAuth2Integration_Fragment };

export type IntegrationModalResetOAuth2IntegrationMutationVariables = Exact<{
  input: ResetOAuth2IntegrationInput;
}>;


export type IntegrationModalResetOAuth2IntegrationMutation = { resetOAuth2Integration: Pick<CreateOAuth2IntegrationSessionResult, 'sessionId'> };

export type IntegrationModalResetCustomWorkflowIntegrationMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type IntegrationModalResetCustomWorkflowIntegrationMutation = { resetCustomWorkflowIntegration: { integration?: Maybe<IntegrationModalIntegration_CustomWorkflowIntegration_Fragment> } };

export type IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation = { cancelCustomWorkflowIntegrationConfiguration: { integration?: Maybe<IntegrationModalIntegration_CustomWorkflowIntegration_Fragment> } };

export type IntegrationModalEditIntegrationInstanceMutationVariables = Exact<{
  input: IntegrationPatchRequest;
}>;


export type IntegrationModalEditIntegrationInstanceMutation = { editIntegration: Pick<CustomWorkflowIntegration, 'id' | 'description' | 'displayName'> | Pick<OAuth2Integration, 'id' | 'description' | 'displayName'> };

type IntegrationModalIntegration_CustomWorkflowIntegration_Fragment = (
  { __typename: 'CustomWorkflowIntegration' }
  & Pick<CustomWorkflowIntegration, 'id' | 'description' | 'displayName' | 'status'>
  & { candidateInstance?: Maybe<Pick<Instance, 'id' | 'status'>>, instance?: Maybe<Pick<Instance, 'id'>>, integrationMetadata?: Maybe<(
    Pick<IntegrationMetadata, 'id' | 'description' | 'displayName' | 'type'>
    & { packageMetadata: (
      Pick<PackageMetadata, 'id' | 'publishedOn' | 'version'>
      & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
    ) }
  )> }
);

type IntegrationModalIntegration_OAuth2Integration_Fragment = (
  { __typename: 'OAuth2Integration' }
  & Pick<OAuth2Integration, 'id' | 'description' | 'displayName' | 'status'>
  & { integrationMetadata?: Maybe<(
    Pick<IntegrationMetadata, 'id' | 'description' | 'displayName' | 'type'>
    & { packageMetadata: (
      Pick<PackageMetadata, 'id' | 'publishedOn' | 'version'>
      & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
    ) }
  )> }
);

export type IntegrationModalIntegrationFragment = IntegrationModalIntegration_CustomWorkflowIntegration_Fragment | IntegrationModalIntegration_OAuth2Integration_Fragment;

export type GetLaunchDarklyUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLaunchDarklyUserInfoQuery = { tenantMember?: Maybe<(
    Pick<TenantMember, 'id' | 'emailAddress' | 'firstName' | 'lastName'>
    & { tenant: Pick<Tenant, 'id' | 'name'> }
  )> };

export type AddScheduledJobModalQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type AddScheduledJobModalQuery = { templateConfigurationOptions?: Maybe<{ items?: Maybe<Array<Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>>>> }> };

export type AddScheduledJobModalSubmitMutationVariables = Exact<{
  input: CreateScheduledJobInput;
}>;


export type AddScheduledJobModalSubmitMutation = { createSchedule?: Maybe<(
    Pick<ScheduledJob, 'id' | 'pattern' | 'startTime' | 'endTime' | 'timezoneName'>
    & { templateConfiguration: Pick<TemplateConfiguration, 'id' | 'displayName'> }
  )> };

export type CancelScheduledJobModalQueryVariables = Exact<{
  scheduledJobId: Scalars['ID'];
}>;


export type CancelScheduledJobModalQuery = { scheduledJob?: Maybe<(
    Pick<ScheduledJob, 'id' | 'startTime' | 'endTime' | 'pattern' | 'status' | 'timezoneName'>
    & { templateConfiguration: (
      Pick<TemplateConfiguration, 'id' | 'displayName'>
      & { templateMetadata?: Maybe<Pick<AutomationTemplate, 'id' | 'displayName'> | Pick<DataSetTemplate, 'id' | 'displayName'>> }
    ) }
  )> };

export type CancelScheduledJobModalSubmitMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  scheduledJobId: Scalars['ID'];
}>;


export type CancelScheduledJobModalSubmitMutation = { cancelSchedule?: Maybe<Pick<ScheduledJob, 'id' | 'status'>> };

export type ChangeScheduledJobModalQueryVariables = Exact<{
  scheduledJobId: Scalars['ID'];
}>;


export type ChangeScheduledJobModalQuery = { scheduledJob?: Maybe<(
    Pick<ScheduledJob, 'id' | 'startTime' | 'endTime' | 'pattern' | 'status' | 'timezoneName' | 'maxRecurrenceCount'>
    & { templateConfiguration: Pick<TemplateConfiguration, 'id' | 'displayName'> }
  )> };

export type ChangeScheduledJobModalSubmitMutationVariables = Exact<{
  input: UpdateScheduledJobInput;
}>;


export type ChangeScheduledJobModalSubmitMutation = { updateSchedule?: Maybe<Pick<ScheduledJob, 'id' | 'startTime' | 'endTime' | 'pattern' | 'status' | 'timezoneName' | 'maxRecurrenceCount'>> };

export type ScheduledJobScheduleToggleMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  scheduleId: Scalars['ID'];
}>;


export type ScheduledJobScheduleToggleMutation = { toggleScheduleActivity?: Maybe<Pick<ScheduledJob, 'id' | 'status'>> };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { changePassword: (
    Pick<ChangePasswordResult, 'isSuccessful'>
    & { errors: Array<Pick<IdentityError, 'description' | 'code'>> }
  ) };

export type CancelRunModalMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  instanceId: Scalars['ID'];
}>;


export type CancelRunModalMutation = { cancelWorkflow?: Maybe<Pick<Instance, 'id' | 'status'>> };

export type ConnectionStringModalQueryVariables = Exact<{
  dataSetId: Scalars['ID'];
}>;


export type ConnectionStringModalQuery = { dataSet?: Maybe<Pick<DataSet, 'connectionString' | 'displayName' | 'id' | 'readOnlyConnectionString'>> };

export type DeleteDataSetModalQueryVariables = Exact<{
  dataSetId: Scalars['ID'];
}>;


export type DeleteDataSetModalQuery = { dataSet?: Maybe<(
    Pick<DataSet, 'description' | 'displayName' | 'id' | 'status' | 'workspaceId' | 'createdOn'>
    & { createdBy?: Maybe<Pick<TenantMember, 'id' | 'firstName' | 'lastName'>>, dependentTemplateConfigurations?: Maybe<{ items?: Maybe<Array<Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>>>> }> }
  )> };

export type DeleteDataSetModalSubmitMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  dataSetId: Scalars['ID'];
}>;


export type DeleteDataSetModalSubmitMutation = { deleteDataSet?: Maybe<Pick<DataSet, 'id' | 'status'>> };

export type DeleteTemplateConfigurationModalQueryVariables = Exact<{
  templateConfigurationId: Scalars['ID'];
}>;


export type DeleteTemplateConfigurationModalQuery = { templateConfiguration?: Maybe<(
    Pick<TemplateConfiguration, 'id' | 'displayName'>
    & { instances?: Maybe<{ items?: Maybe<Array<Maybe<Pick<Instance, 'id' | 'updatedOn'>>>> }>, scheduledJobs?: Maybe<{ items?: Maybe<Array<Maybe<Pick<ScheduledJob, 'id' | 'pattern'>>>> }> }
  )> };

export type DeleteTemplateConfigurationModalSubmitMutationVariables = Exact<{
  input: DeleteTemplateConfigurationInput;
}>;


export type DeleteTemplateConfigurationModalSubmitMutation = { deleteTemplateConfiguration?: Maybe<Pick<TemplateConfiguration, 'id' | 'status'>> };

export type DeleteWorkspaceModalQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type DeleteWorkspaceModalQuery = { workspace?: Maybe<Pick<Workspace, 'displayName' | 'id'>> };

export type DeleteWorkspaceModalSubmitMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type DeleteWorkspaceModalSubmitMutation = { deleteWorkspace: (
    Pick<TenantsApiResult, 'isSuccessful'>
    & { errors: Array<Pick<TenantsError, 'code' | 'description'>> }
  ) };

export type FormDataRequestDetailsQueryVariables = Exact<{
  stepId: Scalars['ID'];
}>;


export type FormDataRequestDetailsQuery = { step?: Maybe<(
    Pick<Step, 'id' | 'displayName' | 'name' | 'status'>
    & { dataRequest?: Maybe<FormDataRequestDetailsDataRequestFragment>, instance: Pick<Instance, 'id' | 'status' | 'workspaceId'> }
  )> };

export type FormDataRequestDetailsCompleteDataRequestMutationVariables = Exact<{
  input: CompleteDataRequestInput;
}>;


export type FormDataRequestDetailsCompleteDataRequestMutation = { completeDataRequest?: Maybe<FormDataRequestDetailsDataRequestFragment> };

export type FormDataRequestDetailsDataRequestFragment = (
  { __typename: 'FormDataRequest' }
  & Pick<FormDataRequest, 'id' | 'type' | 'status'>
  & { dataValidation?: Maybe<(
    Pick<DataValidation, 'id' | 'createdOn' | 'hasLogs' | 'status'>
    & { validationMessages?: Maybe<(
      Pick<ValidationMessageConnection, 'totalCount'>
      & { items?: Maybe<Array<Maybe<Pick<ValidationMessage, 'createdUtc' | 'level' | 'content'>>>> }
    )> }
  )>, form: WorkflowFormFragment }
);

export type WorkflowFormFragment = (
  { __typename: 'Form' }
  & Pick<Form, 'name' | 'submitLabel'>
  & { components: Array<FormCheckboxFragment | FormColumnsFragment | FormDateInputFragment | FormDateTimeInputFragment | FormEmailInputFragment | FormFileInputFragment | FormIntegrationFragment | FormLinkFragment | FormMultiSelectFragment | FormNumericInputFragment | FormParagraphFragment | FormSelectFragment | FormTextAreaFragment | FormTextInputFragment | FormTimeInputFragment | FormUrlInputFragment> }
);

export type FormTextInputFragment = (
  { __typename: 'FormTextInput' }
  & Pick<FormTextInput, 'name' | 'type' | 'label' | 'value' | 'defaultValue' | 'required' | 'placeholder' | 'mask' | 'case' | 'maxLength' | 'minLength' | 'regex' | 'validationMessage'>
);

export type FormTimeInputFragment = (
  { __typename: 'FormTimeInput' }
  & Pick<FormTimeInput, 'name' | 'defaultValue' | 'label' | 'required' | 'type' | 'value'>
);

export type FormEmailInputFragment = (
  { __typename: 'FormEmailInput' }
  & Pick<FormEmailInput, 'name' | 'type' | 'label' | 'value' | 'defaultValue' | 'required' | 'placeholder' | 'allowedDomains'>
);

export type FormFileInputFragment = (
  { __typename: 'FormFileInput' }
  & Pick<FormFileInput, 'defaultValue' | 'label' | 'maxFileSizeInBytes' | 'name' | 'required' | 'type' | 'value'>
);

export type FormUrlInputFragment = (
  { __typename: 'FormUrlInput' }
  & Pick<FormUrlInput, 'name' | 'type' | 'label' | 'value' | 'defaultValue' | 'required' | 'placeholder' | 'allowedDomains'>
);

export type FormNumericInputFragment = (
  { __typename: 'FormNumericInput' }
  & Pick<FormNumericInput, 'name' | 'type' | 'label' | 'value' | 'required' | 'placeholder' | 'decimal' | 'maxValue' | 'minValue'>
  & { defaultNumericValue: FormNumericInput['defaultValue'] }
);

export type FormTextAreaFragment = (
  { __typename: 'FormTextArea' }
  & Pick<FormTextArea, 'name' | 'type' | 'label' | 'value' | 'defaultValue' | 'required' | 'placeholder' | 'rows' | 'cols'>
);

export type FormSelectFragment = (
  { __typename: 'FormSelect' }
  & Pick<FormSelect, 'name' | 'type' | 'label' | 'value' | 'defaultValue' | 'required' | 'placeholder'>
  & { selectDisplayType: FormSelect['displayType'] }
  & { options: Array<FormSelectOptionFragment> }
);

export type FormMultiSelectFragment = (
  { __typename: 'FormMultiSelect' }
  & Pick<FormMultiSelect, 'name' | 'type' | 'label' | 'value' | 'required' | 'placeholder'>
  & { defaultValues: FormMultiSelect['defaultValue'], multiSelectDisplayType: FormMultiSelect['displayType'] }
  & { options: Array<FormSelectOptionFragment> }
);

export type FormSelectOptionFragment = Pick<FormSelectOption, 'id' | 'label'>;

export type FormCheckboxFragment = (
  { __typename: 'FormCheckbox' }
  & Pick<FormCheckbox, 'name' | 'type' | 'value' | 'label' | 'defaultChecked'>
);

export type FormDateInputFragment = (
  { __typename: 'FormDateInput' }
  & Pick<FormDateInput, 'name' | 'type' | 'value' | 'label' | 'defaultValue' | 'includeTimezone' | 'maxDate' | 'minDate' | 'required'>
);

export type FormDateTimeInputFragment = (
  { __typename: 'FormDateTimeInput' }
  & Pick<FormDateTimeInput, 'name' | 'type' | 'value' | 'label' | 'defaultValue' | 'includeTimezone' | 'maxDateTime' | 'minDateTime' | 'required'>
);

export type FormColumnsFragment = (
  { __typename: 'FormColumns' }
  & Pick<FormColumns, 'name' | 'type'>
  & { columns: Array<(
    Pick<FormColumn, 'name'>
    & { components: Array<FormCheckboxFragment | FormDateInputFragment | FormDateTimeInputFragment | FormEmailInputFragment | FormFileInputFragment | FormLinkFragment | FormMultiSelectFragment | FormNumericInputFragment | FormParagraphFragment | FormSelectFragment | FormTextAreaFragment | FormTextInputFragment | FormTimeInputFragment | FormUrlInputFragment> }
  )> }
);

export type FormLinkFragment = (
  { __typename: 'FormLink' }
  & Pick<FormLink, 'name' | 'type' | 'label' | 'href'>
);

export type FormParagraphFragment = (
  { __typename: 'FormParagraph' }
  & Pick<FormParagraph, 'name' | 'type' | 'content'>
);

export type FormIntegrationFragment = (
  { __typename: 'FormIntegration' }
  & Pick<FormIntegration, 'label' | 'name' | 'required' | 'type' | 'value'>
  & { integrationReferences?: Maybe<Array<Pick<FormIntegrationReference, 'integration' | 'package'>>> }
);

export type StepDetailsQueryVariables = Exact<{
  stepId: Scalars['ID'];
}>;


export type StepDetailsQuery = { step?: Maybe<StepDetailsStepFragment> };

export type StepDetailsStepFragment = (
  Pick<Step, 'id' | 'completedOn' | 'createdOn' | 'displayName' | 'hasLogs' | 'name' | 'startedOn' | 'status' | 'taskSummary'>
  & { instance: Pick<Instance, 'id' | 'workspaceId'>, statusMessages?: Maybe<{ items?: Maybe<Array<Maybe<Pick<StatusMessage, 'content' | 'createdUtc' | 'level'>>>> }> }
);

export type WorkflowInstanceViewQueryVariables = Exact<{
  instanceId: Scalars['ID'];
}>;


export type WorkflowInstanceViewQuery = { instance?: Maybe<WorkflowInstanceViewInstanceFragment> };

export type WorkflowInstanceViewSaveTemplateConfigurationMutationVariables = Exact<{
  input: ConvertOnDemandTemplateConfigurationInput;
}>;


export type WorkflowInstanceViewSaveTemplateConfigurationMutation = { convertOnDemandTemplateConfiguration?: Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>> };

export type WorkflowInstanceViewInstanceFragment = (
  Pick<Instance, 'id' | 'displayName' | 'startedOn' | 'completedOn' | 'createdOn' | 'updatedOn' | 'status' | 'runSummary' | 'workspaceId'>
  & { launchedBy?: Maybe<(
    { __typename: 'DataSet' }
    & Pick<DataSet, 'id'>
  ) | (
    { __typename: 'ScheduledJob' }
    & Pick<ScheduledJob, 'id'>
  ) | (
    { __typename: 'TenantMember' }
    & Pick<TenantMember, 'id' | 'firstName' | 'lastName' | 'isActive'>
  )>, steps?: Maybe<{ items?: Maybe<Array<Maybe<WorkflowInstanceViewStepFragment>>> }>, templateConfiguration?: Maybe<(
    Pick<TemplateConfiguration, 'id' | 'exposureLevel' | 'displayName' | 'status'>
    & { configurationInstance?: Maybe<(
      Pick<Instance, 'id' | 'completedOn' | 'status' | 'startedOn'>
      & { steps?: Maybe<{ items?: Maybe<Array<Maybe<WorkflowInstanceViewStepFragment>>> }> }
    )>, dataSet?: Maybe<Pick<DataSet, 'id' | 'displayName' | 'workspaceId'>>, templateMetadata?: Maybe<(
      { __typename: 'AutomationTemplate' }
      & Pick<AutomationTemplate, 'id' | 'type'>
    ) | (
      { __typename: 'DataSetTemplate' }
      & Pick<DataSetTemplate, 'id' | 'type'>
    )> }
  )>, templateMetadata?: Maybe<(
    Pick<AutomationTemplate, 'id' | 'displayName'>
    & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
  ) | (
    Pick<DataSetTemplate, 'id' | 'displayName'>
    & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
  )> }
);

export type WorkflowInstanceViewStepFragment = (
  { dataRequest?: Maybe<FormDataRequestDetailsDataRequestFragment> }
  & StepDetailsStepFragment
);

export type IntegrationFieldOptionsQueryVariables = Exact<{
  integrationReferences: Array<IntegrationReference> | IntegrationReference;
}>;


export type IntegrationFieldOptionsQuery = { integrations?: Maybe<{ items?: Maybe<Array<(
      { __typename: 'CustomWorkflowIntegration' }
      & Pick<CustomWorkflowIntegration, 'id' | 'displayName' | 'description'>
      & { integrationMetadata?: Maybe<(
        { __typename: 'IntegrationMetadata' }
        & Pick<IntegrationMetadata, 'type' | 'displayName'>
      )> }
    ) | (
      { __typename: 'OAuth2Integration' }
      & Pick<OAuth2Integration, 'id' | 'displayName' | 'description'>
      & { integrationMetadata?: Maybe<(
        { __typename: 'IntegrationMetadata' }
        & Pick<IntegrationMetadata, 'type' | 'displayName'>
      )> }
    )>> }>, matchingIntegrationMetadatas: Array<Pick<IntegrationMetadata, 'id' | 'name' | 'displayName'>> };

export type GetCurrentUserTenantMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserTenantMemberQuery = { me?: Maybe<CurrentTenantMemberFragment> };

export type FindTenantMembersQueryVariables = Exact<{
  input: TenantMembersRetrieveInput;
}>;


export type FindTenantMembersQuery = { tenantMembers: Array<TenantMemberFragment> };

export type FindTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindTenantsQuery = { tenants: Array<TenantFragment> };

export type TenantHasExternalIdpQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantHasExternalIdpQuery = { tenantIdp?: Maybe<Pick<TenantIdpModel, 'externalProviderEnabled'>> };

export type CreateUserMutationVariables = Exact<{
  input: AddTenantMemberInput;
}>;


export type CreateUserMutation = { addTenantMember: (
    Pick<AddTenantMemberResult, 'isSuccessful'>
    & { errors: Array<Pick<TenantsError, 'code'>>, tenantMember?: Maybe<TenantMemberFragment> }
  ) };

export type EditUserMutationVariables = Exact<{
  input: EditTenantMemberInput;
}>;


export type EditUserMutation = { editTenantMember: (
    Pick<EditTenantMemberResult, 'isSuccessful'>
    & { errors: Array<Pick<TenantsError, 'code'>>, tenantMember?: Maybe<TenantMemberFragment> }
  ) };

export type DeleteTenantMemberMutationVariables = Exact<{
  input: DeleteTenantMemberInput;
}>;


export type DeleteTenantMemberMutation = { deleteTenantMember: Pick<TenantsApiResult, 'isSuccessful'> };

export type ResendInvitationEmailMutationVariables = Exact<{
  input: ResendEmailInvitationInput;
}>;


export type ResendInvitationEmailMutation = { resendEmailInvitation: (
    Pick<TenantsApiResult, 'isSuccessful'>
    & { errors: Array<Pick<TenantsError, 'code'>> }
  ) };

export type PingMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutation = { ping: Pick<TenantsApiResult, 'isSuccessful'> };

export type CurrentTenantMemberFragment = (
  { tenant: TenantFragment }
  & TenantMemberFragment
);

export type TenantSaleItemFragment = Pick<TenantSaleItem, 'chargeBeeItemId' | 'displayName' | 'itemType' | 'quantity'>;

export type TenantFragment = (
  Pick<Tenant, 'id' | 'accountStatus' | 'displayName' | 'name' | 'planName' | 'nextStatusDate'>
  & { tenantSaleItems: Array<TenantSaleItemFragment> }
);

export type TenantMemberFragment = Pick<TenantMember, 'id' | 'firstName' | 'lastName' | 'emailAddress' | 'lastSeenOn' | 'role' | 'sendRunSummaryViaEmail' | 'systemUserId'>;

export type CreateWorkspaceMutationVariables = Exact<{
  workspace: CreateWorkspaceInput;
}>;


export type CreateWorkspaceMutation = { createWorkspace?: Maybe<Pick<Workspace, 'id'>> };

export type EditWorkspaceMutationVariables = Exact<{
  input: EditWorkspaceInput;
}>;


export type EditWorkspaceMutation = { editWorkspace?: Maybe<WorkspaceFragment> };

export type FindWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindWorkspacesQuery = { workspaces?: Maybe<{ items?: Maybe<Array<Maybe<WorkspaceFragment>>> }> };

export type FindWorkspaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindWorkspaceQuery = { workspace?: Maybe<WorkspaceFragment> };

export type WorkspaceFragment = Pick<Workspace, 'id' | 'displayName'>;

export type DashboardPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DashboardPageQuery = { dashboard?: Maybe<(
    Pick<Dashboard, 'id' | 'description' | 'displayName' | 'status' | 'workspaceId'>
    & { dataSet?: Maybe<Pick<DataSet, 'id' | 'displayName' | 'workspaceId'>>, dashboardMetadata?: Maybe<(
      Pick<DashboardMetadata, 'id' | 'displayName'>
      & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
    )>, dashboardReport?: Maybe<Pick<DashboardReport, 'id' | 'embedToken' | 'embedUrl' | 'reportId'>> }
  )> };

export type DataSetPageQueryVariables = Exact<{
  dataSetId: Scalars['ID'];
}>;


export type DataSetPageQuery = { dataSet?: Maybe<(
    Pick<DataSet, 'createdOn' | 'description' | 'displayName' | 'id' | 'status'>
    & { candidateData?: Maybe<(
      Pick<ConfigurationData, 'id'>
      & { instance?: Maybe<Pick<Instance, 'id' | 'workspaceId'>> }
    )>, configurationInstance?: Maybe<(
      Pick<Instance, 'id' | 'createdOn' | 'status'>
      & { steps?: Maybe<{ items?: Maybe<Array<Maybe<(
          Pick<Step, 'createdOn' | 'displayName' | 'id'>
          & { instance: Pick<Instance, 'id'>, statusMessages?: Maybe<{ items?: Maybe<Array<Maybe<Pick<StatusMessage, 'content' | 'createdUtc' | 'level'>>>> }> }
        )>>> }>, templateMetadata?: Maybe<(
        { __typename: 'AutomationTemplate' }
        & Pick<AutomationTemplate, 'id'>
        & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
      ) | (
        { __typename: 'DataSetTemplate' }
        & Pick<DataSetTemplate, 'id' | 'defaultSchedulePattern'>
        & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
      )> }
    )>, createdBy?: Maybe<Pick<TenantMember, 'id' | 'firstName' | 'lastName'>>, currentSchedule?: Maybe<Pick<ScheduledJob, 'id' | 'maxRecurrenceCount' | 'pattern' | 'status' | 'timezoneName'>>, data?: Maybe<Pick<ConfigurationData, 'id'>>, templateMetadata?: Maybe<(
      Pick<AutomationTemplate, 'id' | 'displayName'>
      & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
    ) | (
      Pick<DataSetTemplate, 'id' | 'displayName'>
      & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
    )> }
  )> };

export type DataSetRefreshRunsQueryVariables = Exact<{
  dataSetId: Scalars['ID'];
  input: InstanceRetrieveInput;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type DataSetRefreshRunsQuery = { dataSet?: Maybe<(
    Pick<DataSet, 'id'>
    & { refreshRuns?: Maybe<{ edges?: Maybe<Array<Maybe<(
        Pick<InstanceEdge, 'cursor'>
        & { node?: Maybe<Pick<Instance, 'completedOn' | 'id' | 'startedOn' | 'status' | 'updatedOn' | 'workspaceId'>> }
      )>>>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> }> }
  )> };

export type RenameDataSetMutationVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
}>;


export type RenameDataSetMutation = { editDataSet?: Maybe<Pick<DataSet, 'id'>> };

export type ReplaceDataSetScheduleMutationVariables = Exact<{
  input: ReplaceDataSetScheduleInput;
}>;


export type ReplaceDataSetScheduleMutation = { replaceDataSetSchedule?: Maybe<(
    Pick<DataSet, 'id'>
    & { currentSchedule?: Maybe<Pick<ScheduledJob, 'id' | 'maxRecurrenceCount' | 'pattern' | 'status' | 'timezoneName'>> }
  )> };

export type ToggleDataSetScheduleActivityMutationVariables = Exact<{
  input: ToggleDataSetScheduleActivityInput;
}>;


export type ToggleDataSetScheduleActivityMutation = { toggleDataSetScheduleActivity?: Maybe<Pick<ScheduledJob, 'id' | 'maxRecurrenceCount' | 'pattern' | 'status' | 'timezoneName'>> };

export type ResetDataSetConfigurationDataMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  dataSetId: Scalars['ID'];
  resetById: Scalars['ID'];
}>;


export type ResetDataSetConfigurationDataMutation = { resetDataSetConfigurationData?: Maybe<Pick<DataSet, 'id'>> };

export type CancelCandidateDataSetConfigurationDataMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  dataSetId: Scalars['ID'];
  candidateConfigurationDataId: Scalars['ID'];
}>;


export type CancelCandidateDataSetConfigurationDataMutation = { cancelCandidateDataSetConfigurationData?: Maybe<Pick<DataSet, 'id'>> };

export type RefreshDataSetMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  dataSetId: Scalars['ID'];
}>;


export type RefreshDataSetMutation = { executeDataSet?: Maybe<{ dataSet?: Maybe<Pick<DataSet, 'id'>> }> };

export type IntegrationsPageAvailableTabQueryVariables = Exact<{
  input: IntegrationMetadataRetrieveInput;
}>;


export type IntegrationsPageAvailableTabQuery = { integrationMetadatas?: Maybe<{ items?: Maybe<Array<Maybe<(
      Pick<IntegrationMetadata, 'id' | 'description' | 'displayName' | 'name' | 'type'>
      & { packageMetadata: (
        Pick<PackageMetadata, 'id' | 'name' | 'publishedOn' | 'version'>
        & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
      ) }
    )>>> }> };

export type IntegrationsPageConfiguredTabQueryVariables = Exact<{
  input: IntegrationsRetrieveInput;
}>;


export type IntegrationsPageConfiguredTabQuery = { integrations?: Maybe<{ items?: Maybe<Array<IntegrationCardIntegration_CustomWorkflowIntegration_Fragment | IntegrationCardIntegration_OAuth2Integration_Fragment>> }> };

export type IntegrationCardQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type IntegrationCardQuery = { integration: IntegrationCardIntegration_CustomWorkflowIntegration_Fragment | IntegrationCardIntegration_OAuth2Integration_Fragment };

type IntegrationCardIntegration_CustomWorkflowIntegration_Fragment = (
  { __typename: 'CustomWorkflowIntegration' }
  & Pick<CustomWorkflowIntegration, 'id' | 'description' | 'displayName' | 'status'>
  & { integrationMetadata?: Maybe<(
    Pick<IntegrationMetadata, 'id'>
    & IntegrationMetadataInfoRowIntegrationMetadataFragment
  )> }
);

type IntegrationCardIntegration_OAuth2Integration_Fragment = (
  { __typename: 'OAuth2Integration' }
  & Pick<OAuth2Integration, 'id' | 'description' | 'displayName' | 'status'>
  & { integrationMetadata?: Maybe<(
    Pick<IntegrationMetadata, 'id'>
    & IntegrationMetadataInfoRowIntegrationMetadataFragment
  )> }
);

export type IntegrationCardIntegrationFragment = IntegrationCardIntegration_CustomWorkflowIntegration_Fragment | IntegrationCardIntegration_OAuth2Integration_Fragment;

export type LibraryAutomationPageQueryVariables = Exact<{
  input: AutomationMetadatasRetrieveInput;
}>;


export type LibraryAutomationPageQuery = { automationMetadatas?: Maybe<{ items?: Maybe<Array<Maybe<LibraryAutomationPageItemFragment>>> }> };

export type LibraryAutomationPageItemFragment = (
  { __typename: 'AutomationMetadata' }
  & Pick<AutomationMetadata, 'chargeBeeItemId' | 'solutionName' | 'hasTenantAccess' | 'name' | 'id' | 'description' | 'displayName' | 'libraryItemType' | 'readme'>
  & { dataSchemaDependencies?: Maybe<{ items?: Maybe<Array<Maybe<(
      Pick<DataSchemaMetadata, 'id'>
      & { compatibleDataSetTemplate?: Maybe<Pick<DataSetMetadata, 'id' | 'displayName'>> }
    )>>> }>, packageMetadata: (
    { __typename: 'PackageMetadata' }
    & Pick<PackageMetadata, 'id' | 'name' | 'publishedOn' | 'version'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

export type LibraryAutomationPageLaunchMutationVariables = Exact<{
  dataSetId?: Maybe<Scalars['ID']>;
  launchedById: Scalars['ID'];
  templateConfigurationId?: Maybe<Scalars['ID']>;
  templateMetadataId: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type LibraryAutomationPageLaunchMutation = { executeTemplateConfiguration?: Maybe<Pick<Instance, 'id'>> };

export type LibraryDashboardPageQueryVariables = Exact<{
  input: DashboardMetadatasRetrieveInput;
}>;


export type LibraryDashboardPageQuery = { dashboardMetadatas?: Maybe<{ items?: Maybe<Array<Maybe<LibraryDashboardPageItemFragment>>> }> };

export type LibraryDashboardPageItemFragment = (
  { __typename: 'DashboardMetadata' }
  & Pick<DashboardMetadata, 'name' | 'id' | 'description' | 'displayName' | 'libraryItemType' | 'readme'>
  & { dataSchemaMetadata: (
    Pick<DataSchemaMetadata, 'id'>
    & { compatibleDataSetTemplate?: Maybe<Pick<DataSetMetadata, 'id' | 'displayName'>> }
  ), packageMetadata: (
    { __typename: 'PackageMetadata' }
    & Pick<PackageMetadata, 'id' | 'name' | 'publishedOn' | 'version'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

export type LibraryDashboardPageCreateMutationVariables = Exact<{
  input: CreateDashboardInput;
}>;


export type LibraryDashboardPageCreateMutation = { createDashboard?: Maybe<Pick<Dashboard, 'id' | 'dashboardMetadataId' | 'workspaceId'>> };

export type LibraryDataSetPageQueryVariables = Exact<{
  input: DataSetMetadatasRetrieveInput;
}>;


export type LibraryDataSetPageQuery = { dataSetMetadatas?: Maybe<{ items?: Maybe<Array<Maybe<LibraryDataSetPageItemFragment>>> }> };

export type LibraryDataSetPageItemFragment = (
  { __typename: 'DataSetMetadata' }
  & Pick<DataSetMetadata, 'chargeBeeItemId' | 'solutionName' | 'hasTenantAccess' | 'name' | 'id' | 'description' | 'displayName' | 'libraryItemType' | 'readme'>
  & { packageMetadata: (
    { __typename: 'PackageMetadata' }
    & Pick<PackageMetadata, 'id' | 'name' | 'publishedOn' | 'version'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

export type LibraryDataSetPageCreateDataSetMutationVariables = Exact<{
  input: CreateDataSetInput;
}>;


export type LibraryDataSetPageCreateDataSetMutation = { createDataSet?: Maybe<Pick<DataSet, 'id'>> };

export type LibraryItemByIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LibraryItemByIdPageQuery = { libraryItems?: Maybe<{ items?: Maybe<Array<Maybe<(
      { __typename: 'AutomationMetadata' }
      & Pick<AutomationMetadata, 'id' | 'libraryItemType' | 'name'>
      & { packageMetadata: (
        { __typename: 'PackageMetadata' }
        & Pick<PackageMetadata, 'id' | 'name' | 'version'>
        & { publisher: Pick<Publisher, 'id' | 'name'> }
      ) }
    ) | (
      { __typename: 'DashboardMetadata' }
      & Pick<DashboardMetadata, 'id' | 'libraryItemType' | 'name'>
      & { packageMetadata: (
        { __typename: 'PackageMetadata' }
        & Pick<PackageMetadata, 'id' | 'name' | 'version'>
        & { publisher: Pick<Publisher, 'id' | 'name'> }
      ) }
    ) | (
      { __typename: 'DataSetMetadata' }
      & Pick<DataSetMetadata, 'id' | 'libraryItemType' | 'name'>
      & { packageMetadata: (
        { __typename: 'PackageMetadata' }
        & Pick<PackageMetadata, 'id' | 'name' | 'version'>
        & { publisher: Pick<Publisher, 'id' | 'name'> }
      ) }
    )>>> }> };

export type LibraryItemRedirectPageQueryVariables = Exact<{
  input: LibraryItemsRetrieveInput;
}>;


export type LibraryItemRedirectPageQuery = { libraryItems?: Maybe<{ items?: Maybe<Array<Maybe<(
      Pick<AutomationMetadata, 'id' | 'libraryItemType' | 'name'>
      & { packageMetadata: (
        Pick<PackageMetadata, 'id' | 'name'>
        & { publisher: Pick<Publisher, 'id' | 'name'> }
      ) }
    ) | (
      Pick<DashboardMetadata, 'id' | 'libraryItemType' | 'name'>
      & { packageMetadata: (
        Pick<PackageMetadata, 'id' | 'name'>
        & { publisher: Pick<Publisher, 'id' | 'name'> }
      ) }
    ) | (
      Pick<DataSetMetadata, 'id' | 'libraryItemType' | 'name'>
      & { packageMetadata: (
        Pick<PackageMetadata, 'id' | 'name'>
        & { publisher: Pick<Publisher, 'id' | 'name'> }
      ) }
    )>>> }> };

export type DataSetSelectorQueryVariables = Exact<{
  dataSchemaMetadataId: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type DataSetSelectorQuery = { dataSets?: Maybe<{ items?: Maybe<Array<Maybe<Pick<DataSet, 'id' | 'displayName'>>>> }> };

export type LibraryPageQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  input: LibraryItemsRetrieveInput;
}>;


export type LibraryPageQuery = { libraryFilteringMetadata?: Maybe<Pick<LibraryFilteringMetadata, 'availableSolutions' | 'availableTechnologies'>>, libraryItems?: Maybe<{ edges?: Maybe<Array<Maybe<(
      Pick<LibraryItemEdge, 'cursor'>
      & { node?: Maybe<LibraryPageLibraryItem_AutomationMetadata_Fragment | LibraryPageLibraryItem_DashboardMetadata_Fragment | LibraryPageLibraryItem_DataSetMetadata_Fragment> }
    )>>>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> }> };

type LibraryPageLibraryItem_AutomationMetadata_Fragment = (
  Pick<AutomationMetadata, 'id' | 'name' | 'displayName' | 'description' | 'libraryItemType' | 'chargeBeeItemId' | 'solutionName' | 'hasTenantAccess' | 'solutions' | 'technologies'>
  & { packageMetadata: (
    Pick<PackageMetadata, 'id' | 'name' | 'displayName' | 'description' | 'publishedOn' | 'version' | 'tenantId'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

type LibraryPageLibraryItem_DashboardMetadata_Fragment = (
  Pick<DashboardMetadata, 'id' | 'name' | 'displayName' | 'description' | 'libraryItemType' | 'chargeBeeItemId' | 'solutionName' | 'hasTenantAccess' | 'solutions' | 'technologies'>
  & { packageMetadata: (
    Pick<PackageMetadata, 'id' | 'name' | 'displayName' | 'description' | 'publishedOn' | 'version' | 'tenantId'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

type LibraryPageLibraryItem_DataSetMetadata_Fragment = (
  Pick<DataSetMetadata, 'defaultSchedulePattern' | 'id' | 'name' | 'displayName' | 'description' | 'libraryItemType' | 'chargeBeeItemId' | 'solutionName' | 'hasTenantAccess' | 'solutions' | 'technologies'>
  & { packageMetadata: (
    Pick<PackageMetadata, 'id' | 'name' | 'displayName' | 'description' | 'publishedOn' | 'version' | 'tenantId'>
    & { publisher: Pick<Publisher, 'id' | 'displayName' | 'name'> }
  ) }
);

export type LibraryPageLibraryItemFragment = LibraryPageLibraryItem_AutomationMetadata_Fragment | LibraryPageLibraryItem_DashboardMetadata_Fragment | LibraryPageLibraryItem_DataSetMetadata_Fragment;

export type NotificationsHistoryTabQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type NotificationsHistoryTabQuery = { notificationRecipients?: Maybe<{ edges?: Maybe<Array<Maybe<(
      Pick<NotificationRecipientEdge, 'cursor'>
      & { node: NotificationsHistoryTabNotificationRecipientFragment }
    )>>>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> }> };

export type NotificationsHistoryTabToggleNotificationStatusMutationVariables = Exact<{
  input: NotificationRecipientPatchRequest;
}>;


export type NotificationsHistoryTabToggleNotificationStatusMutation = { editNotificationRecipient: { notificationRecipient?: Maybe<NotificationsHistoryTabNotificationRecipientFragment> } };

export type NotificationsHistoryTabNotificationRecipientFragment = (
  Pick<NotificationRecipient, 'id' | 'createdOn' | 'status'>
  & { notification: (
    Pick<RunNotification, 'id' | 'subject'>
    & { instance?: Maybe<Pick<Instance, 'id' | 'displayName'>>, workspace?: Maybe<Pick<Workspace, 'id' | 'displayName'>> }
  ) }
);

export type ActivitySummarySectionToggleRunSummaryViaEmailMutationVariables = Exact<{
  input: EditTenantMemberInput;
}>;


export type ActivitySummarySectionToggleRunSummaryViaEmailMutation = { editTenantMember: { tenantMember?: Maybe<CurrentTenantMemberFragment> } };

export type AddNotificationSubscriptionModalCreateSubscriptionMutationVariables = Exact<{
  input: CreateRunNotificationSubscriptionInput;
}>;


export type AddNotificationSubscriptionModalCreateSubscriptionMutation = { createRunNotificationSubscription: { notificationSubscription?: Maybe<Pick<RunNotificationSubscription, 'id'>> } };

export type NotificationSubscriptionModalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationSubscriptionModalQuery = { notificationSubscription?: Maybe<(
    { __typename: 'RunNotificationSubscription' }
    & Pick<RunNotificationSubscription, 'runNotificationType' | 'id' | 'channelTypes' | 'status'>
    & { templateConfiguration?: Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>>, workspace?: Maybe<Pick<Workspace, 'id' | 'displayName'>> }
  )> };

export type NotificationSubscriptionModalEditSubscriptionMutationVariables = Exact<{
  input: RunNotificationSubscriptionPatchRequest;
}>;


export type NotificationSubscriptionModalEditSubscriptionMutation = { editRunNotificationSubscription: { notificationSubscription?: Maybe<Pick<RunNotificationSubscription, 'id'>> } };

export type NotificationSubscriptionModalDeleteSubscriptionMutationVariables = Exact<{
  input: DeleteNotificationSubscriptionInput;
}>;


export type NotificationSubscriptionModalDeleteSubscriptionMutation = { deleteNotificationSubscription: Pick<DeleteNotificationSubscriptionPayload, 'id'> };

export type NotificationSubscriptionSectionQueryVariables = Exact<{
  input: NotificationSubscriptionsRetrieveInput;
}>;


export type NotificationSubscriptionSectionQuery = { notificationSubscriptions?: Maybe<{ items?: Maybe<Array<NotificationSubscriptionSectionNotificationSubscriptionFragment>> }> };

export type NotificationSubscriptionSectionToggleSubscriptionStatusMutationVariables = Exact<{
  input: RunNotificationSubscriptionPatchRequest;
}>;


export type NotificationSubscriptionSectionToggleSubscriptionStatusMutation = { editRunNotificationSubscription: { notificationSubscription?: Maybe<NotificationSubscriptionSectionNotificationSubscriptionFragment> } };

export type NotificationSubscriptionSectionNotificationSubscriptionFragment = (
  Pick<RunNotificationSubscription, 'runNotificationType' | 'id' | 'channelTypes' | 'status'>
  & { templateConfiguration?: Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>>, workspace?: Maybe<Pick<Workspace, 'id' | 'displayName'>> }
);

export type NotificationsSettingsTabQueryVariables = Exact<{
  input: NotificationSubscriptionsRetrieveInput;
}>;


export type NotificationsSettingsTabQuery = { notificationSubscriptions?: Maybe<{ items?: Maybe<Array<NotificationSubscriptionSectionNotificationSubscriptionFragment>> }> };

export type ScheduledJobPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ScheduledJobPageQuery = { scheduledJob?: Maybe<(
    Pick<ScheduledJob, 'id' | 'startTime' | 'endTime' | 'pattern' | 'status' | 'timezoneName' | 'maxRecurrenceCount' | 'createdOn'>
    & { createdBy?: Maybe<(
      { __typename: 'TenantMember' }
      & Pick<TenantMember, 'firstName' | 'lastName'>
    )>, recentInstances?: Maybe<{ items?: Maybe<Array<Maybe<(
        Pick<Instance, 'id' | 'workspaceId' | 'status' | 'displayName' | 'createdOn' | 'completedOn'>
        & { launchedBy?: Maybe<(
          { __typename: 'DataSet' }
          & Pick<DataSet, 'id'>
        ) | (
          { __typename: 'ScheduledJob' }
          & Pick<ScheduledJob, 'id'>
        ) | (
          { __typename: 'TenantMember' }
          & Pick<TenantMember, 'id' | 'firstName' | 'lastName' | 'isActive'>
        )> }
      )>>> }>, templateConfiguration: (
      Pick<TemplateConfiguration, 'id' | 'displayName'>
      & { templateMetadata?: Maybe<Pick<AutomationTemplate, 'id' | 'name' | 'displayName'> | Pick<DataSetTemplate, 'id' | 'name' | 'displayName'>> }
    ) }
  )> };

export type TemplateConfigurationPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TemplateConfigurationPageQuery = { templateConfiguration?: Maybe<(
    Pick<TemplateConfiguration, 'createdOn' | 'displayName' | 'exposureLevel' | 'id' | 'status'>
    & { configurationInstance?: Maybe<(
      Pick<Instance, 'createdOn' | 'displayName' | 'id'>
      & { launchedBy?: Maybe<(
        { __typename: 'DataSet' }
        & Pick<DataSet, 'id'>
      ) | (
        { __typename: 'ScheduledJob' }
        & Pick<ScheduledJob, 'id'>
      ) | (
        { __typename: 'TenantMember' }
        & Pick<TenantMember, 'firstName' | 'id' | 'lastName'>
      )> }
    )>, createdBy?: Maybe<(
      { __typename: 'TenantMember' }
      & Pick<TenantMember, 'firstName' | 'lastName'>
    )>, candidateData?: Maybe<(
      Pick<ConfigurationData, 'id'>
      & { instance?: Maybe<Pick<Instance, 'id' | 'workspaceId'>> }
    )>, data?: Maybe<Pick<ConfigurationData, 'id'>>, dataSet?: Maybe<Pick<DataSet, 'id' | 'displayName' | 'workspaceId'>>, recentInstances?: Maybe<{ items?: Maybe<Array<Maybe<(
        Pick<Instance, 'id' | 'workspaceId' | 'status' | 'displayName' | 'createdOn' | 'completedOn'>
        & { launchedBy?: Maybe<(
          { __typename: 'DataSet' }
          & Pick<DataSet, 'id'>
        ) | (
          { __typename: 'ScheduledJob' }
          & Pick<ScheduledJob, 'id'>
        ) | (
          { __typename: 'TenantMember' }
          & Pick<TenantMember, 'id' | 'firstName' | 'lastName' | 'isActive'>
        )> }
      )>>> }>, templateMetadata?: Maybe<(
      { __typename: 'AutomationTemplate' }
      & Pick<AutomationTemplate, 'id' | 'name' | 'displayName'>
      & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
    ) | (
      { __typename: 'DataSetTemplate' }
      & Pick<DataSetTemplate, 'id' | 'name' | 'displayName'>
      & { packageMetadata: Pick<PackageMetadata, 'id' | 'version'> }
    )> }
  )> };

export type RenameTemplateConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
}>;


export type RenameTemplateConfigurationMutation = { editTemplateConfiguration?: Maybe<Pick<TemplateConfiguration, 'id'>> };

export type LaunchTemplateConfigurationMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  templateConfigurationId: Scalars['ID'];
  launchedById: Scalars['ID'];
}>;


export type LaunchTemplateConfigurationMutation = { executeTemplateConfiguration?: Maybe<Pick<Instance, 'id'>> };

export type ResetTemplateConfigurationDataMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  templateConfigurationId: Scalars['ID'];
  resetById: Scalars['ID'];
}>;


export type ResetTemplateConfigurationDataMutation = { resetTemplateConfigurationData?: Maybe<Pick<TemplateConfiguration, 'id'>> };

export type CancelCandidateTemplateConfigurationDataMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  templateConfigurationId: Scalars['ID'];
  candidateConfigurationDataId: Scalars['ID'];
}>;


export type CancelCandidateTemplateConfigurationDataMutation = { cancelCandidateTemplateConfigurationData?: Maybe<Pick<TemplateConfiguration, 'id'>> };

export type RenameWorkflowInstanceMutationVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
}>;


export type RenameWorkflowInstanceMutation = { editInstance?: Maybe<Pick<Instance, 'displayName' | 'id'>> };

export type WorkspaceDashboardsTabQueryVariables = Exact<{
  input: DashboardsRetrieveInput;
}>;


export type WorkspaceDashboardsTabQuery = { dashboards?: Maybe<{ items?: Maybe<Array<Maybe<(
      Pick<Dashboard, 'id' | 'displayName' | 'status'>
      & { dashboardMetadata?: Maybe<Pick<DashboardMetadata, 'id' | 'displayName'>>, dataSet?: Maybe<(
        Pick<DataSet, 'id' | 'displayName' | 'workspaceId'>
        & { lastRefreshRun?: Maybe<Pick<Instance, 'id' | 'completedOn' | 'status' | 'workspaceId'>> }
      )> }
    )>>> }> };

export type WorkspaceDataSetsTabQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  statuses?: Maybe<Array<DataSetStatus> | DataSetStatus>;
}>;


export type WorkspaceDataSetsTabQuery = { dataSets?: Maybe<{ items?: Maybe<Array<Maybe<(
      Pick<DataSet, 'displayName' | 'id' | 'status' | 'workspaceId'>
      & { templateMetadata?: Maybe<Pick<AutomationTemplate, 'id' | 'displayName'> | Pick<DataSetTemplate, 'id' | 'displayName'>>, lastRefreshRun?: Maybe<Pick<Instance, 'id' | 'completedOn' | 'status' | 'workspaceId'>>, configurationInstance?: Maybe<Pick<Instance, 'status'>> }
    )>>> }> };

export type DeleteWorkspaceFileMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  fileId: Scalars['ID'];
}>;


export type DeleteWorkspaceFileMutation = Pick<Mutation, 'deleteFile'>;

export type WorkspaceFilesBrowserQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  path?: Maybe<Scalars['String']>;
}>;


export type WorkspaceFilesBrowserQuery = { fileDescriptor?: Maybe<WorkspaceFilesBrowserFileDescriptorFragment | WorkspaceFilesBrowserFolderDescriptorFragment> };

export type WorkspaceFilesBrowserFileDescriptorItemFragment = (
  { __typename: 'FileDescriptor' }
  & Pick<FileDescriptor, 'id' | 'name' | 'size' | 'updatedOn' | 'downloadUri'>
);

export type WorkspaceFilesBrowserFileDescriptorFragment = (
  { __typename: 'FileDescriptor' }
  & Pick<FileDescriptor, 'id' | 'name' | 'size' | 'updatedOn' | 'downloadUri'>
  & { parentFolder?: Maybe<WorkspaceFilesBrowserFolderDescriptorFragment> }
);

export type WorkspaceFilesBrowserFolderDescriptorItemFragment = (
  { __typename: 'FolderDescriptor' }
  & Pick<FolderDescriptor, 'id' | 'fullPath' | 'name' | 'size' | 'updatedOn'>
);

export type WorkspaceFilesBrowserFolderDescriptorFragment = (
  { __typename: 'FolderDescriptor' }
  & Pick<FolderDescriptor, 'name'>
  & { items?: Maybe<{ items?: Maybe<Array<Maybe<WorkspaceFilesBrowserFileDescriptorItemFragment | WorkspaceFilesBrowserFolderDescriptorItemFragment>>> }>, parentFolder?: Maybe<Pick<FolderDescriptor, 'id' | 'fullPath'>> }
);

export type WorkspacePageRunsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  launchedByTypes?: Maybe<Array<LaunchedByType> | LaunchedByType>;
}>;


export type WorkspacePageRunsQuery = { instances?: Maybe<{ pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>, edges?: Maybe<Array<Maybe<(
      Pick<InstanceEdge, 'cursor'>
      & { node?: Maybe<(
        Pick<Instance, 'id' | 'type' | 'displayName' | 'updatedOn' | 'status' | 'launchedByType'>
        & { launchedBy?: Maybe<(
          { __typename: 'DataSet' }
          & Pick<DataSet, 'id'>
          & { createdBy?: Maybe<Pick<TenantMember, 'firstName' | 'lastName'>> }
        ) | (
          { __typename: 'ScheduledJob' }
          & Pick<ScheduledJob, 'id'>
          & { createdBy?: Maybe<Pick<TenantMember, 'firstName' | 'lastName'>> }
        ) | (
          { __typename: 'TenantMember' }
          & Pick<TenantMember, 'id' | 'firstName' | 'lastName'>
        )>, templateConfiguration?: Maybe<(
          Pick<TemplateConfiguration, 'id' | 'displayName' | 'status' | 'exposureLevel'>
          & { configurationInstance?: Maybe<Pick<Instance, 'id' | 'status'>> }
        )>, templateMetadata?: Maybe<Pick<AutomationTemplate, 'id' | 'name' | 'displayName' | 'type'> | Pick<DataSetTemplate, 'id' | 'name' | 'displayName' | 'type'>> }
      )> }
    )>>> }> };

export type WorkspacePageTemplateConfigurationsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type WorkspacePageTemplateConfigurationsQuery = { templateConfigurations?: Maybe<{ items?: Maybe<Array<Maybe<(
      Pick<TemplateConfiguration, 'id' | 'displayName' | 'exposureLevel' | 'status' | 'workspaceId'>
      & { lastStartedInstance?: Maybe<Pick<Instance, 'id' | 'startedOn' | 'workspaceId'>>, templateMetadata?: Maybe<Pick<AutomationTemplate, 'id' | 'name' | 'displayName'> | Pick<DataSetTemplate, 'id' | 'name' | 'displayName'>> }
    )>>> }> };

export type WorkspacePageScheduledJobsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type WorkspacePageScheduledJobsQuery = { scheduledJobs?: Maybe<{ pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>, items?: Maybe<Array<Maybe<(
      Pick<ScheduledJob, 'id' | 'endTime' | 'pattern' | 'startTime' | 'status' | 'timezoneName'>
      & { lastStartedInstance?: Maybe<Pick<Instance, 'id' | 'startedOn' | 'workspaceId'>>, templateConfiguration: (
        Pick<TemplateConfiguration, 'id' | 'status' | 'displayName' | 'workspaceId'>
        & { templateMetadata?: Maybe<Pick<AutomationTemplate, 'id' | 'name' | 'displayName'> | Pick<DataSetTemplate, 'id' | 'name' | 'displayName'>> }
      ) }
    )>>> }> };

export type WorkspacePageRenameDataSetMutationVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
}>;


export type WorkspacePageRenameDataSetMutation = { editDataSet?: Maybe<Pick<DataSet, 'displayName' | 'id'>> };

export type WorkspacePageRenameTemplateConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
}>;


export type WorkspacePageRenameTemplateConfigurationMutation = { editTemplateConfiguration?: Maybe<Pick<TemplateConfiguration, 'displayName' | 'id'>> };

export type WorkspacePageSaveTemplateConfigurationMutationVariables = Exact<{
  input: ConvertOnDemandTemplateConfigurationInput;
}>;


export type WorkspacePageSaveTemplateConfigurationMutation = { convertOnDemandTemplateConfiguration?: Maybe<Pick<TemplateConfiguration, 'id' | 'displayName'>> };

export const NotificationsDropButtonNotificationRecipientFragmentDoc = gql`
    fragment NotificationsDropButtonNotificationRecipient on NotificationRecipient {
  id
  createdOn
  notification {
    id
    subject
    ... on RunNotification {
      instance {
        id
        displayName
      }
      workspace {
        id
        displayName
      }
    }
  }
  status
}
    `;
export const IntegrationMetadataInfoRowIntegrationMetadataFragmentDoc = gql`
    fragment IntegrationMetadataInfoRowIntegrationMetadata on IntegrationMetadata {
  id
  displayName
  packageMetadata {
    id
    version
    publishedOn
    publisher {
      id
      displayName
      name
    }
  }
  type
}
    `;
export const AddIntegrationModalIntegrationMetadataFragmentDoc = gql`
    fragment AddIntegrationModalIntegrationMetadata on IntegrationMetadata {
  __typename
  id
  description
  displayName
  packageMetadata {
    id
    publishedOn
    publisher {
      id
      displayName
      name
    }
    version
  }
  readme
  type
  ...IntegrationMetadataInfoRowIntegrationMetadata
}
    ${IntegrationMetadataInfoRowIntegrationMetadataFragmentDoc}`;
export const ConfigureCustomWorkflowIntegrationMetadataFragmentDoc = gql`
    fragment ConfigureCustomWorkflowIntegrationMetadata on IntegrationMetadata {
  __typename
  id
  description
  displayName
  packageMetadata {
    id
    publishedOn
    publisher {
      id
      displayName
      name
    }
    version
  }
  readme
  type
}
    `;
export const StepDetailsStepFragmentDoc = gql`
    fragment StepDetailsStep on Step {
  id
  completedOn
  createdOn
  displayName
  hasLogs
  name
  startedOn
  status
  taskSummary
  instance {
    id
    workspaceId
  }
  statusMessages {
    items {
      content
      createdUtc
      level
    }
  }
}
    `;
export const FormTextInputFragmentDoc = gql`
    fragment FormTextInput on FormTextInput {
  __typename
  name
  type
  label
  value
  defaultValue
  required
  placeholder
  mask
  case
  maxLength
  minLength
  regex
  validationMessage
}
    `;
export const FormNumericInputFragmentDoc = gql`
    fragment FormNumericInput on FormNumericInput {
  __typename
  name
  type
  label
  value
  defaultNumericValue: defaultValue
  required
  placeholder
  decimal
  maxValue
  minValue
}
    `;
export const FormEmailInputFragmentDoc = gql`
    fragment FormEmailInput on FormEmailInput {
  __typename
  name
  type
  label
  value
  defaultValue
  required
  placeholder
  allowedDomains
}
    `;
export const FormUrlInputFragmentDoc = gql`
    fragment FormUrlInput on FormUrlInput {
  __typename
  name
  type
  label
  value
  defaultValue
  required
  placeholder
  allowedDomains
}
    `;
export const FormTextAreaFragmentDoc = gql`
    fragment FormTextArea on FormTextArea {
  __typename
  name
  type
  label
  value
  defaultValue
  required
  placeholder
  rows
  cols
}
    `;
export const FormSelectOptionFragmentDoc = gql`
    fragment FormSelectOption on FormSelectOption {
  id
  label
}
    `;
export const FormSelectFragmentDoc = gql`
    fragment FormSelect on FormSelect {
  __typename
  name
  type
  label
  value
  defaultValue
  selectDisplayType: displayType
  required
  placeholder
  options {
    ...FormSelectOption
  }
}
    ${FormSelectOptionFragmentDoc}`;
export const FormLinkFragmentDoc = gql`
    fragment FormLink on FormLink {
  __typename
  name
  type
  label
  href
}
    `;
export const FormParagraphFragmentDoc = gql`
    fragment FormParagraph on FormParagraph {
  __typename
  name
  type
  content
}
    `;
export const FormCheckboxFragmentDoc = gql`
    fragment FormCheckbox on FormCheckbox {
  __typename
  name
  type
  value
  label
  defaultChecked
}
    `;
export const FormMultiSelectFragmentDoc = gql`
    fragment FormMultiSelect on FormMultiSelect {
  __typename
  name
  type
  label
  value
  defaultValues: defaultValue
  multiSelectDisplayType: displayType
  required
  placeholder
  options {
    ...FormSelectOption
  }
}
    ${FormSelectOptionFragmentDoc}`;
export const FormDateInputFragmentDoc = gql`
    fragment FormDateInput on FormDateInput {
  __typename
  name
  type
  value
  label
  defaultValue
  includeTimezone
  maxDate
  minDate
  required
}
    `;
export const FormTimeInputFragmentDoc = gql`
    fragment FormTimeInput on FormTimeInput {
  __typename
  name
  defaultValue
  label
  required
  type
  value
}
    `;
export const FormDateTimeInputFragmentDoc = gql`
    fragment FormDateTimeInput on FormDateTimeInput {
  __typename
  name
  type
  value
  label
  defaultValue
  includeTimezone
  maxDateTime
  minDateTime
  required
}
    `;
export const FormFileInputFragmentDoc = gql`
    fragment FormFileInput on FormFileInput {
  __typename
  defaultValue
  label
  maxFileSizeInBytes
  name
  required
  type
  value
}
    `;
export const FormColumnsFragmentDoc = gql`
    fragment FormColumns on FormColumns {
  __typename
  name
  type
  columns {
    name
    components {
      ...FormTextInput
      ...FormNumericInput
      ...FormEmailInput
      ...FormUrlInput
      ...FormTextArea
      ...FormSelect
      ...FormLink
      ...FormParagraph
      ...FormCheckbox
      ...FormMultiSelect
      ...FormDateInput
      ...FormTimeInput
      ...FormDateTimeInput
      ...FormFileInput
    }
  }
}
    ${FormTextInputFragmentDoc}
${FormNumericInputFragmentDoc}
${FormEmailInputFragmentDoc}
${FormUrlInputFragmentDoc}
${FormTextAreaFragmentDoc}
${FormSelectFragmentDoc}
${FormLinkFragmentDoc}
${FormParagraphFragmentDoc}
${FormCheckboxFragmentDoc}
${FormMultiSelectFragmentDoc}
${FormDateInputFragmentDoc}
${FormTimeInputFragmentDoc}
${FormDateTimeInputFragmentDoc}
${FormFileInputFragmentDoc}`;
export const FormIntegrationFragmentDoc = gql`
    fragment FormIntegration on FormIntegration {
  __typename
  integrationReferences {
    integration
    package
  }
  label
  name
  required
  type
  value
}
    `;
export const WorkflowFormFragmentDoc = gql`
    fragment WorkflowForm on Form {
  __typename
  name
  submitLabel
  components {
    ...FormTextInput
    ...FormNumericInput
    ...FormEmailInput
    ...FormUrlInput
    ...FormTextArea
    ...FormSelect
    ...FormColumns
    ...FormLink
    ...FormParagraph
    ...FormCheckbox
    ...FormMultiSelect
    ...FormIntegration
    ...FormDateInput
    ...FormTimeInput
    ...FormDateTimeInput
    ...FormFileInput
  }
}
    ${FormTextInputFragmentDoc}
${FormNumericInputFragmentDoc}
${FormEmailInputFragmentDoc}
${FormUrlInputFragmentDoc}
${FormTextAreaFragmentDoc}
${FormSelectFragmentDoc}
${FormColumnsFragmentDoc}
${FormLinkFragmentDoc}
${FormParagraphFragmentDoc}
${FormCheckboxFragmentDoc}
${FormMultiSelectFragmentDoc}
${FormIntegrationFragmentDoc}
${FormDateInputFragmentDoc}
${FormTimeInputFragmentDoc}
${FormDateTimeInputFragmentDoc}
${FormFileInputFragmentDoc}`;
export const FormDataRequestDetailsDataRequestFragmentDoc = gql`
    fragment FormDataRequestDetailsDataRequest on FormDataRequest {
  __typename
  id
  type
  status
  dataValidation {
    id
    createdOn
    hasLogs
    status
    validationMessages {
      totalCount
      items {
        createdUtc
        level
        content
      }
    }
  }
  form {
    ...WorkflowForm
  }
}
    ${WorkflowFormFragmentDoc}`;
export const ConfigureCustomWorkflowIntegrationFragmentDoc = gql`
    fragment ConfigureCustomWorkflowIntegration on CustomWorkflowIntegration {
  __typename
  id
  description
  displayName
  status
  integrationMetadata {
    ...ConfigureCustomWorkflowIntegrationMetadata
  }
  ... on CustomWorkflowIntegration {
    candidateInstance {
      id
      status
      steps(input: {}) {
        items {
          ...StepDetailsStep
          dataRequest {
            ...FormDataRequestDetailsDataRequest
          }
        }
      }
    }
  }
}
    ${ConfigureCustomWorkflowIntegrationMetadataFragmentDoc}
${StepDetailsStepFragmentDoc}
${FormDataRequestDetailsDataRequestFragmentDoc}`;
export const IntegrationModalIntegrationFragmentDoc = gql`
    fragment IntegrationModalIntegration on Integration {
  __typename
  id
  description
  displayName
  integrationMetadata {
    id
    description
    displayName
    packageMetadata {
      id
      publishedOn
      publisher {
        id
        displayName
        name
      }
      version
    }
    type
  }
  status
  ... on CustomWorkflowIntegration {
    candidateInstance {
      id
      status
    }
    instance {
      id
    }
  }
}
    `;
export const WorkflowInstanceViewStepFragmentDoc = gql`
    fragment WorkflowInstanceViewStep on Step {
  ...StepDetailsStep
  dataRequest {
    ...FormDataRequestDetailsDataRequest
  }
}
    ${StepDetailsStepFragmentDoc}
${FormDataRequestDetailsDataRequestFragmentDoc}`;
export const WorkflowInstanceViewInstanceFragmentDoc = gql`
    fragment WorkflowInstanceViewInstance on Instance {
  id
  displayName
  startedOn
  completedOn
  createdOn
  updatedOn
  status
  runSummary
  launchedBy {
    __typename
    ... on DataSet {
      id
    }
    ... on ScheduledJob {
      id
    }
    ... on TenantMember {
      id
      firstName
      lastName
      isActive
    }
  }
  steps(input: {}) {
    items {
      ...WorkflowInstanceViewStep
    }
  }
  templateConfiguration {
    id
    exposureLevel
    configurationInstance {
      id
      completedOn
      status
      startedOn
      steps(input: {}) {
        items {
          ...WorkflowInstanceViewStep
        }
      }
    }
    dataSet {
      id
      displayName
      workspaceId
    }
    displayName
    status
    templateMetadata {
      __typename
      id
      type
    }
  }
  templateMetadata {
    id
    displayName
    packageMetadata {
      id
      version
    }
  }
  workspaceId
}
    ${WorkflowInstanceViewStepFragmentDoc}`;
export const TenantMemberFragmentDoc = gql`
    fragment TenantMember on TenantMember {
  id
  firstName
  lastName
  emailAddress
  lastSeenOn
  role
  sendRunSummaryViaEmail
  systemUserId
}
    `;
export const TenantSaleItemFragmentDoc = gql`
    fragment TenantSaleItem on TenantSaleItem {
  chargeBeeItemId
  displayName
  itemType
  quantity
}
    `;
export const TenantFragmentDoc = gql`
    fragment Tenant on Tenant {
  id
  accountStatus
  displayName
  name
  planName
  nextStatusDate
  tenantSaleItems {
    ...TenantSaleItem
  }
}
    ${TenantSaleItemFragmentDoc}`;
export const CurrentTenantMemberFragmentDoc = gql`
    fragment CurrentTenantMember on TenantMember {
  ...TenantMember
  tenant {
    ...Tenant
  }
}
    ${TenantMemberFragmentDoc}
${TenantFragmentDoc}`;
export const WorkspaceFragmentDoc = gql`
    fragment Workspace on Workspace {
  id
  displayName
}
    `;
export const IntegrationCardIntegrationFragmentDoc = gql`
    fragment IntegrationCardIntegration on Integration {
  __typename
  id
  description
  displayName
  status
  integrationMetadata {
    id
    ...IntegrationMetadataInfoRowIntegrationMetadata
  }
}
    ${IntegrationMetadataInfoRowIntegrationMetadataFragmentDoc}`;
export const LibraryAutomationPageItemFragmentDoc = gql`
    fragment LibraryAutomationPageItem on AutomationMetadata {
  __typename
  chargeBeeItemId
  solutionName
  hasTenantAccess
  name
  id
  dataSchemaDependencies {
    items {
      id
      compatibleDataSetTemplate {
        id
        displayName
      }
    }
  }
  description
  displayName
  libraryItemType
  packageMetadata {
    __typename
    id
    name
    publishedOn
    publisher {
      id
      displayName
      name
    }
    version
  }
  readme
}
    `;
export const LibraryDashboardPageItemFragmentDoc = gql`
    fragment LibraryDashboardPageItem on DashboardMetadata {
  __typename
  name
  id
  dataSchemaMetadata {
    id
    compatibleDataSetTemplate {
      id
      displayName
    }
  }
  description
  displayName
  libraryItemType
  packageMetadata {
    __typename
    id
    name
    publishedOn
    publisher {
      id
      displayName
      name
    }
    version
  }
  readme
}
    `;
export const LibraryDataSetPageItemFragmentDoc = gql`
    fragment LibraryDataSetPageItem on DataSetMetadata {
  __typename
  chargeBeeItemId
  solutionName
  hasTenantAccess
  name
  id
  description
  displayName
  libraryItemType
  packageMetadata {
    __typename
    id
    name
    publishedOn
    publisher {
      id
      displayName
      name
    }
    version
  }
  readme
}
    `;
export const LibraryPageLibraryItemFragmentDoc = gql`
    fragment LibraryPageLibraryItem on LibraryItem {
  id
  name
  displayName
  description
  libraryItemType
  chargeBeeItemId
  solutionName
  hasTenantAccess
  solutions
  technologies
  packageMetadata {
    id
    name
    displayName
    description
    publishedOn
    publisher {
      id
      displayName
      name
    }
    version
    tenantId
  }
  ... on DataSetMetadata {
    defaultSchedulePattern
  }
}
    `;
export const NotificationsHistoryTabNotificationRecipientFragmentDoc = gql`
    fragment NotificationsHistoryTabNotificationRecipient on NotificationRecipient {
  id
  createdOn
  notification {
    id
    subject
    ... on RunNotification {
      instance {
        id
        displayName
      }
      workspace {
        id
        displayName
      }
    }
  }
  status
}
    `;
export const NotificationSubscriptionSectionNotificationSubscriptionFragmentDoc = gql`
    fragment NotificationSubscriptionSectionNotificationSubscription on NotificationSubscription {
  id
  channelTypes
  status
  ... on RunNotificationSubscription {
    runNotificationType
    templateConfiguration {
      id
      displayName
    }
    workspace {
      id
      displayName
    }
  }
}
    `;
export const WorkspaceFilesBrowserFileDescriptorItemFragmentDoc = gql`
    fragment WorkspaceFilesBrowserFileDescriptorItem on FileDescriptor {
  __typename
  id
  name
  size
  updatedOn
  downloadUri
}
    `;
export const WorkspaceFilesBrowserFolderDescriptorItemFragmentDoc = gql`
    fragment WorkspaceFilesBrowserFolderDescriptorItem on FolderDescriptor {
  __typename
  id
  fullPath
  name
  size
  updatedOn
}
    `;
export const WorkspaceFilesBrowserFolderDescriptorFragmentDoc = gql`
    fragment WorkspaceFilesBrowserFolderDescriptor on FolderDescriptor {
  __typename
  items {
    items {
      ...WorkspaceFilesBrowserFileDescriptorItem
      ...WorkspaceFilesBrowserFolderDescriptorItem
    }
  }
  name
  parentFolder {
    id
    fullPath
  }
}
    ${WorkspaceFilesBrowserFileDescriptorItemFragmentDoc}
${WorkspaceFilesBrowserFolderDescriptorItemFragmentDoc}`;
export const WorkspaceFilesBrowserFileDescriptorFragmentDoc = gql`
    fragment WorkspaceFilesBrowserFileDescriptor on FileDescriptor {
  __typename
  id
  name
  size
  updatedOn
  downloadUri
  parentFolder {
    ...WorkspaceFilesBrowserFolderDescriptor
  }
}
    ${WorkspaceFilesBrowserFolderDescriptorFragmentDoc}`;
export const TemplateConfigurationSelectorDocument = gql`
    query TemplateConfigurationSelector($workspaceId: ID!, $templateMetadataId: ID) {
  templateConfigurations(
    input: {workspaceId: $workspaceId, templateMetadataId: $templateMetadataId, statuses: [READY]}
  ) {
    items {
      id
      displayName
    }
  }
}
    `;

/**
 * __useTemplateConfigurationSelectorQuery__
 *
 * To run a query within a React component, call `useTemplateConfigurationSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateConfigurationSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateConfigurationSelectorQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      templateMetadataId: // value for 'templateMetadataId'
 *   },
 * });
 */
export function useTemplateConfigurationSelectorQuery(baseOptions: Apollo.QueryHookOptions<TemplateConfigurationSelectorQuery, TemplateConfigurationSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateConfigurationSelectorQuery, TemplateConfigurationSelectorQueryVariables>(TemplateConfigurationSelectorDocument, options);
      }
export function useTemplateConfigurationSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateConfigurationSelectorQuery, TemplateConfigurationSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateConfigurationSelectorQuery, TemplateConfigurationSelectorQueryVariables>(TemplateConfigurationSelectorDocument, options);
        }
export type TemplateConfigurationSelectorQueryHookResult = ReturnType<typeof useTemplateConfigurationSelectorQuery>;
export type TemplateConfigurationSelectorLazyQueryHookResult = ReturnType<typeof useTemplateConfigurationSelectorLazyQuery>;
export type TemplateConfigurationSelectorQueryResult = Apollo.QueryResult<TemplateConfigurationSelectorQuery, TemplateConfigurationSelectorQueryVariables>;
export const WorkspaceSelectorDocument = gql`
    query WorkspaceSelector {
  workspaces(input: {}) {
    items {
      id
      displayName
    }
  }
}
    `;

/**
 * __useWorkspaceSelectorQuery__
 *
 * To run a query within a React component, call `useWorkspaceSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceSelectorQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceSelectorQuery, WorkspaceSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceSelectorQuery, WorkspaceSelectorQueryVariables>(WorkspaceSelectorDocument, options);
      }
export function useWorkspaceSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceSelectorQuery, WorkspaceSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceSelectorQuery, WorkspaceSelectorQueryVariables>(WorkspaceSelectorDocument, options);
        }
export type WorkspaceSelectorQueryHookResult = ReturnType<typeof useWorkspaceSelectorQuery>;
export type WorkspaceSelectorLazyQueryHookResult = ReturnType<typeof useWorkspaceSelectorLazyQuery>;
export type WorkspaceSelectorQueryResult = Apollo.QueryResult<WorkspaceSelectorQuery, WorkspaceSelectorQueryVariables>;
export const NotificationsDropButtonDocument = gql`
    query NotificationsDropButton($first: Int, $after: String) {
  notificationRecipients(
    first: $first
    after: $after
    input: {channelTypes: [VOLEER_UI], ids: null, statuses: [UNREAD], types: null}
  ) {
    edges {
      cursor
      node {
        ...NotificationsDropButtonNotificationRecipient
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    ${NotificationsDropButtonNotificationRecipientFragmentDoc}`;

/**
 * __useNotificationsDropButtonQuery__
 *
 * To run a query within a React component, call `useNotificationsDropButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsDropButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsDropButtonQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotificationsDropButtonQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsDropButtonQuery, NotificationsDropButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsDropButtonQuery, NotificationsDropButtonQueryVariables>(NotificationsDropButtonDocument, options);
      }
export function useNotificationsDropButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsDropButtonQuery, NotificationsDropButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsDropButtonQuery, NotificationsDropButtonQueryVariables>(NotificationsDropButtonDocument, options);
        }
export type NotificationsDropButtonQueryHookResult = ReturnType<typeof useNotificationsDropButtonQuery>;
export type NotificationsDropButtonLazyQueryHookResult = ReturnType<typeof useNotificationsDropButtonLazyQuery>;
export type NotificationsDropButtonQueryResult = Apollo.QueryResult<NotificationsDropButtonQuery, NotificationsDropButtonQueryVariables>;
export const NotificationsDropButtonDismissNotificationDocument = gql`
    mutation NotificationsDropButtonDismissNotification($input: NotificationRecipientPatchRequest!) {
  editNotificationRecipient(input: $input) {
    notificationRecipient {
      ...NotificationsDropButtonNotificationRecipient
    }
  }
}
    ${NotificationsDropButtonNotificationRecipientFragmentDoc}`;
export type NotificationsDropButtonDismissNotificationMutationFn = Apollo.MutationFunction<NotificationsDropButtonDismissNotificationMutation, NotificationsDropButtonDismissNotificationMutationVariables>;

/**
 * __useNotificationsDropButtonDismissNotificationMutation__
 *
 * To run a mutation, you first call `useNotificationsDropButtonDismissNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsDropButtonDismissNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsDropButtonDismissNotificationMutation, { data, loading, error }] = useNotificationsDropButtonDismissNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsDropButtonDismissNotificationMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsDropButtonDismissNotificationMutation, NotificationsDropButtonDismissNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsDropButtonDismissNotificationMutation, NotificationsDropButtonDismissNotificationMutationVariables>(NotificationsDropButtonDismissNotificationDocument, options);
      }
export type NotificationsDropButtonDismissNotificationMutationHookResult = ReturnType<typeof useNotificationsDropButtonDismissNotificationMutation>;
export type NotificationsDropButtonDismissNotificationMutationResult = Apollo.MutationResult<NotificationsDropButtonDismissNotificationMutation>;
export type NotificationsDropButtonDismissNotificationMutationOptions = Apollo.BaseMutationOptions<NotificationsDropButtonDismissNotificationMutation, NotificationsDropButtonDismissNotificationMutationVariables>;
export const NotificationsDropButtonDismissAllNotificationsDocument = gql`
    mutation NotificationsDropButtonDismissAllNotifications($input: SetNotificationRecipientStatusByDateInput!) {
  setNotificationRecipientStatusByDate(input: $input) {
    numberOfRecipientsUpdated
  }
}
    `;
export type NotificationsDropButtonDismissAllNotificationsMutationFn = Apollo.MutationFunction<NotificationsDropButtonDismissAllNotificationsMutation, NotificationsDropButtonDismissAllNotificationsMutationVariables>;

/**
 * __useNotificationsDropButtonDismissAllNotificationsMutation__
 *
 * To run a mutation, you first call `useNotificationsDropButtonDismissAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsDropButtonDismissAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsDropButtonDismissAllNotificationsMutation, { data, loading, error }] = useNotificationsDropButtonDismissAllNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsDropButtonDismissAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsDropButtonDismissAllNotificationsMutation, NotificationsDropButtonDismissAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsDropButtonDismissAllNotificationsMutation, NotificationsDropButtonDismissAllNotificationsMutationVariables>(NotificationsDropButtonDismissAllNotificationsDocument, options);
      }
export type NotificationsDropButtonDismissAllNotificationsMutationHookResult = ReturnType<typeof useNotificationsDropButtonDismissAllNotificationsMutation>;
export type NotificationsDropButtonDismissAllNotificationsMutationResult = Apollo.MutationResult<NotificationsDropButtonDismissAllNotificationsMutation>;
export type NotificationsDropButtonDismissAllNotificationsMutationOptions = Apollo.BaseMutationOptions<NotificationsDropButtonDismissAllNotificationsMutation, NotificationsDropButtonDismissAllNotificationsMutationVariables>;
export const ChargeBeePortalDocument = gql`
    query ChargeBeePortal {
  getChargeBeeSession {
    access_url
    created_at
    customer_id
    expires_at
    id
    object
    status
    token
  }
}
    `;

/**
 * __useChargeBeePortalQuery__
 *
 * To run a query within a React component, call `useChargeBeePortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeBeePortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeBeePortalQuery({
 *   variables: {
 *   },
 * });
 */
export function useChargeBeePortalQuery(baseOptions?: Apollo.QueryHookOptions<ChargeBeePortalQuery, ChargeBeePortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargeBeePortalQuery, ChargeBeePortalQueryVariables>(ChargeBeePortalDocument, options);
      }
export function useChargeBeePortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargeBeePortalQuery, ChargeBeePortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargeBeePortalQuery, ChargeBeePortalQueryVariables>(ChargeBeePortalDocument, options);
        }
export type ChargeBeePortalQueryHookResult = ReturnType<typeof useChargeBeePortalQuery>;
export type ChargeBeePortalLazyQueryHookResult = ReturnType<typeof useChargeBeePortalLazyQuery>;
export type ChargeBeePortalQueryResult = Apollo.QueryResult<ChargeBeePortalQuery, ChargeBeePortalQueryVariables>;
export const DeleteDashboardModalDocument = gql`
    query DeleteDashboardModal($dashboardId: ID!) {
  dashboard(id: $dashboardId) {
    description
    displayName
    id
    workspaceId
  }
}
    `;

/**
 * __useDeleteDashboardModalQuery__
 *
 * To run a query within a React component, call `useDeleteDashboardModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteDashboardModalQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useDeleteDashboardModalQuery(baseOptions: Apollo.QueryHookOptions<DeleteDashboardModalQuery, DeleteDashboardModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteDashboardModalQuery, DeleteDashboardModalQueryVariables>(DeleteDashboardModalDocument, options);
      }
export function useDeleteDashboardModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteDashboardModalQuery, DeleteDashboardModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteDashboardModalQuery, DeleteDashboardModalQueryVariables>(DeleteDashboardModalDocument, options);
        }
export type DeleteDashboardModalQueryHookResult = ReturnType<typeof useDeleteDashboardModalQuery>;
export type DeleteDashboardModalLazyQueryHookResult = ReturnType<typeof useDeleteDashboardModalLazyQuery>;
export type DeleteDashboardModalQueryResult = Apollo.QueryResult<DeleteDashboardModalQuery, DeleteDashboardModalQueryVariables>;
export const DeleteDashboardModalSubmitDocument = gql`
    mutation DeleteDashboardModalSubmit($dashboardId: ID!, $workspaceId: ID!) {
  deleteDashboard(input: {dashboardId: $dashboardId, workspaceId: $workspaceId}) {
    id
    isDeleted
    workspaceId
  }
}
    `;
export type DeleteDashboardModalSubmitMutationFn = Apollo.MutationFunction<DeleteDashboardModalSubmitMutation, DeleteDashboardModalSubmitMutationVariables>;

/**
 * __useDeleteDashboardModalSubmitMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardModalSubmitMutation, { data, loading, error }] = useDeleteDashboardModalSubmitMutation({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteDashboardModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardModalSubmitMutation, DeleteDashboardModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardModalSubmitMutation, DeleteDashboardModalSubmitMutationVariables>(DeleteDashboardModalSubmitDocument, options);
      }
export type DeleteDashboardModalSubmitMutationHookResult = ReturnType<typeof useDeleteDashboardModalSubmitMutation>;
export type DeleteDashboardModalSubmitMutationResult = Apollo.MutationResult<DeleteDashboardModalSubmitMutation>;
export type DeleteDashboardModalSubmitMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardModalSubmitMutation, DeleteDashboardModalSubmitMutationVariables>;
export const AddIntegrationModalDocument = gql`
    query AddIntegrationModal($integrationMetadataIds: [ID!]!) {
  integrationMetadatas(input: {ids: $integrationMetadataIds}) {
    items {
      ...AddIntegrationModalIntegrationMetadata
    }
  }
}
    ${AddIntegrationModalIntegrationMetadataFragmentDoc}`;

/**
 * __useAddIntegrationModalQuery__
 *
 * To run a query within a React component, call `useAddIntegrationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddIntegrationModalQuery({
 *   variables: {
 *      integrationMetadataIds: // value for 'integrationMetadataIds'
 *   },
 * });
 */
export function useAddIntegrationModalQuery(baseOptions: Apollo.QueryHookOptions<AddIntegrationModalQuery, AddIntegrationModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddIntegrationModalQuery, AddIntegrationModalQueryVariables>(AddIntegrationModalDocument, options);
      }
export function useAddIntegrationModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddIntegrationModalQuery, AddIntegrationModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddIntegrationModalQuery, AddIntegrationModalQueryVariables>(AddIntegrationModalDocument, options);
        }
export type AddIntegrationModalQueryHookResult = ReturnType<typeof useAddIntegrationModalQuery>;
export type AddIntegrationModalLazyQueryHookResult = ReturnType<typeof useAddIntegrationModalLazyQuery>;
export type AddIntegrationModalQueryResult = Apollo.QueryResult<AddIntegrationModalQuery, AddIntegrationModalQueryVariables>;
export const AddIntegrationModalOAuth2ConnectionStatusDocument = gql`
    query AddIntegrationModalOAuth2ConnectionStatus($integrationId: ID!, $sessionId: String!) {
  oAuth2Integration(input: {integrationId: $integrationId, sessionId: $sessionId}) {
    id
    status
  }
}
    `;

/**
 * __useAddIntegrationModalOAuth2ConnectionStatusQuery__
 *
 * To run a query within a React component, call `useAddIntegrationModalOAuth2ConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationModalOAuth2ConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddIntegrationModalOAuth2ConnectionStatusQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddIntegrationModalOAuth2ConnectionStatusQuery(baseOptions: Apollo.QueryHookOptions<AddIntegrationModalOAuth2ConnectionStatusQuery, AddIntegrationModalOAuth2ConnectionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddIntegrationModalOAuth2ConnectionStatusQuery, AddIntegrationModalOAuth2ConnectionStatusQueryVariables>(AddIntegrationModalOAuth2ConnectionStatusDocument, options);
      }
export function useAddIntegrationModalOAuth2ConnectionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddIntegrationModalOAuth2ConnectionStatusQuery, AddIntegrationModalOAuth2ConnectionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddIntegrationModalOAuth2ConnectionStatusQuery, AddIntegrationModalOAuth2ConnectionStatusQueryVariables>(AddIntegrationModalOAuth2ConnectionStatusDocument, options);
        }
export type AddIntegrationModalOAuth2ConnectionStatusQueryHookResult = ReturnType<typeof useAddIntegrationModalOAuth2ConnectionStatusQuery>;
export type AddIntegrationModalOAuth2ConnectionStatusLazyQueryHookResult = ReturnType<typeof useAddIntegrationModalOAuth2ConnectionStatusLazyQuery>;
export type AddIntegrationModalOAuth2ConnectionStatusQueryResult = Apollo.QueryResult<AddIntegrationModalOAuth2ConnectionStatusQuery, AddIntegrationModalOAuth2ConnectionStatusQueryVariables>;
export const AddIntegrationModalConnectOAuth2IntegrationDocument = gql`
    mutation AddIntegrationModalConnectOAuth2Integration($description: String, $displayName: String!, $integrationMetadataId: ID!) {
  createOAuth2Integration(
    input: {description: $description, displayName: $displayName, integrationMetadataId: $integrationMetadataId}
  ) {
    integrationId
    sessionId
  }
}
    `;
export type AddIntegrationModalConnectOAuth2IntegrationMutationFn = Apollo.MutationFunction<AddIntegrationModalConnectOAuth2IntegrationMutation, AddIntegrationModalConnectOAuth2IntegrationMutationVariables>;

/**
 * __useAddIntegrationModalConnectOAuth2IntegrationMutation__
 *
 * To run a mutation, you first call `useAddIntegrationModalConnectOAuth2IntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationModalConnectOAuth2IntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationModalConnectOAuth2IntegrationMutation, { data, loading, error }] = useAddIntegrationModalConnectOAuth2IntegrationMutation({
 *   variables: {
 *      description: // value for 'description'
 *      displayName: // value for 'displayName'
 *      integrationMetadataId: // value for 'integrationMetadataId'
 *   },
 * });
 */
export function useAddIntegrationModalConnectOAuth2IntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddIntegrationModalConnectOAuth2IntegrationMutation, AddIntegrationModalConnectOAuth2IntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIntegrationModalConnectOAuth2IntegrationMutation, AddIntegrationModalConnectOAuth2IntegrationMutationVariables>(AddIntegrationModalConnectOAuth2IntegrationDocument, options);
      }
export type AddIntegrationModalConnectOAuth2IntegrationMutationHookResult = ReturnType<typeof useAddIntegrationModalConnectOAuth2IntegrationMutation>;
export type AddIntegrationModalConnectOAuth2IntegrationMutationResult = Apollo.MutationResult<AddIntegrationModalConnectOAuth2IntegrationMutation>;
export type AddIntegrationModalConnectOAuth2IntegrationMutationOptions = Apollo.BaseMutationOptions<AddIntegrationModalConnectOAuth2IntegrationMutation, AddIntegrationModalConnectOAuth2IntegrationMutationVariables>;
export const AddIntegrationModalCreateCustomWorkflowIntegrationDocument = gql`
    mutation AddIntegrationModalCreateCustomWorkflowIntegration($description: String, $displayName: String!, $integrationMetadataId: ID!) {
  createCustomWorkflowIntegration(
    input: {description: $description, displayName: $displayName, integrationMetadataId: $integrationMetadataId}
  ) {
    integration {
      id
    }
  }
}
    `;
export type AddIntegrationModalCreateCustomWorkflowIntegrationMutationFn = Apollo.MutationFunction<AddIntegrationModalCreateCustomWorkflowIntegrationMutation, AddIntegrationModalCreateCustomWorkflowIntegrationMutationVariables>;

/**
 * __useAddIntegrationModalCreateCustomWorkflowIntegrationMutation__
 *
 * To run a mutation, you first call `useAddIntegrationModalCreateCustomWorkflowIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationModalCreateCustomWorkflowIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationModalCreateCustomWorkflowIntegrationMutation, { data, loading, error }] = useAddIntegrationModalCreateCustomWorkflowIntegrationMutation({
 *   variables: {
 *      description: // value for 'description'
 *      displayName: // value for 'displayName'
 *      integrationMetadataId: // value for 'integrationMetadataId'
 *   },
 * });
 */
export function useAddIntegrationModalCreateCustomWorkflowIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddIntegrationModalCreateCustomWorkflowIntegrationMutation, AddIntegrationModalCreateCustomWorkflowIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIntegrationModalCreateCustomWorkflowIntegrationMutation, AddIntegrationModalCreateCustomWorkflowIntegrationMutationVariables>(AddIntegrationModalCreateCustomWorkflowIntegrationDocument, options);
      }
export type AddIntegrationModalCreateCustomWorkflowIntegrationMutationHookResult = ReturnType<typeof useAddIntegrationModalCreateCustomWorkflowIntegrationMutation>;
export type AddIntegrationModalCreateCustomWorkflowIntegrationMutationResult = Apollo.MutationResult<AddIntegrationModalCreateCustomWorkflowIntegrationMutation>;
export type AddIntegrationModalCreateCustomWorkflowIntegrationMutationOptions = Apollo.BaseMutationOptions<AddIntegrationModalCreateCustomWorkflowIntegrationMutation, AddIntegrationModalCreateCustomWorkflowIntegrationMutationVariables>;
export const DeleteIntegrationModalDocument = gql`
    query DeleteIntegrationModal($integrationId: ID!) {
  integration(id: $integrationId) {
    id
    displayName
  }
}
    `;

/**
 * __useDeleteIntegrationModalQuery__
 *
 * To run a query within a React component, call `useDeleteIntegrationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteIntegrationModalQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDeleteIntegrationModalQuery(baseOptions: Apollo.QueryHookOptions<DeleteIntegrationModalQuery, DeleteIntegrationModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteIntegrationModalQuery, DeleteIntegrationModalQueryVariables>(DeleteIntegrationModalDocument, options);
      }
export function useDeleteIntegrationModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteIntegrationModalQuery, DeleteIntegrationModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteIntegrationModalQuery, DeleteIntegrationModalQueryVariables>(DeleteIntegrationModalDocument, options);
        }
export type DeleteIntegrationModalQueryHookResult = ReturnType<typeof useDeleteIntegrationModalQuery>;
export type DeleteIntegrationModalLazyQueryHookResult = ReturnType<typeof useDeleteIntegrationModalLazyQuery>;
export type DeleteIntegrationModalQueryResult = Apollo.QueryResult<DeleteIntegrationModalQuery, DeleteIntegrationModalQueryVariables>;
export const DeleteIntegrationModalDeleteDocument = gql`
    mutation DeleteIntegrationModalDelete($integrationId: ID!) {
  deleteIntegration(input: {id: $integrationId})
}
    `;
export type DeleteIntegrationModalDeleteMutationFn = Apollo.MutationFunction<DeleteIntegrationModalDeleteMutation, DeleteIntegrationModalDeleteMutationVariables>;

/**
 * __useDeleteIntegrationModalDeleteMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationModalDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationModalDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationModalDeleteMutation, { data, loading, error }] = useDeleteIntegrationModalDeleteMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDeleteIntegrationModalDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIntegrationModalDeleteMutation, DeleteIntegrationModalDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIntegrationModalDeleteMutation, DeleteIntegrationModalDeleteMutationVariables>(DeleteIntegrationModalDeleteDocument, options);
      }
export type DeleteIntegrationModalDeleteMutationHookResult = ReturnType<typeof useDeleteIntegrationModalDeleteMutation>;
export type DeleteIntegrationModalDeleteMutationResult = Apollo.MutationResult<DeleteIntegrationModalDeleteMutation>;
export type DeleteIntegrationModalDeleteMutationOptions = Apollo.BaseMutationOptions<DeleteIntegrationModalDeleteMutation, DeleteIntegrationModalDeleteMutationVariables>;
export const IntegrationMetadataInfoRowDocument = gql`
    query IntegrationMetadataInfoRow($integrationMetadataId: ID!) {
  integrationMetadata(id: $integrationMetadataId) {
    ...IntegrationMetadataInfoRowIntegrationMetadata
  }
}
    ${IntegrationMetadataInfoRowIntegrationMetadataFragmentDoc}`;

/**
 * __useIntegrationMetadataInfoRowQuery__
 *
 * To run a query within a React component, call `useIntegrationMetadataInfoRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationMetadataInfoRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationMetadataInfoRowQuery({
 *   variables: {
 *      integrationMetadataId: // value for 'integrationMetadataId'
 *   },
 * });
 */
export function useIntegrationMetadataInfoRowQuery(baseOptions: Apollo.QueryHookOptions<IntegrationMetadataInfoRowQuery, IntegrationMetadataInfoRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationMetadataInfoRowQuery, IntegrationMetadataInfoRowQueryVariables>(IntegrationMetadataInfoRowDocument, options);
      }
export function useIntegrationMetadataInfoRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationMetadataInfoRowQuery, IntegrationMetadataInfoRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationMetadataInfoRowQuery, IntegrationMetadataInfoRowQueryVariables>(IntegrationMetadataInfoRowDocument, options);
        }
export type IntegrationMetadataInfoRowQueryHookResult = ReturnType<typeof useIntegrationMetadataInfoRowQuery>;
export type IntegrationMetadataInfoRowLazyQueryHookResult = ReturnType<typeof useIntegrationMetadataInfoRowLazyQuery>;
export type IntegrationMetadataInfoRowQueryResult = Apollo.QueryResult<IntegrationMetadataInfoRowQuery, IntegrationMetadataInfoRowQueryVariables>;
export const ConfigureCustomWorkflowIntegrationDocument = gql`
    query ConfigureCustomWorkflowIntegration($integrationId: ID!) {
  integration(id: $integrationId) {
    __typename
    id
    ...ConfigureCustomWorkflowIntegration
  }
}
    ${ConfigureCustomWorkflowIntegrationFragmentDoc}`;

/**
 * __useConfigureCustomWorkflowIntegrationQuery__
 *
 * To run a query within a React component, call `useConfigureCustomWorkflowIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigureCustomWorkflowIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigureCustomWorkflowIntegrationQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useConfigureCustomWorkflowIntegrationQuery(baseOptions: Apollo.QueryHookOptions<ConfigureCustomWorkflowIntegrationQuery, ConfigureCustomWorkflowIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigureCustomWorkflowIntegrationQuery, ConfigureCustomWorkflowIntegrationQueryVariables>(ConfigureCustomWorkflowIntegrationDocument, options);
      }
export function useConfigureCustomWorkflowIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigureCustomWorkflowIntegrationQuery, ConfigureCustomWorkflowIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigureCustomWorkflowIntegrationQuery, ConfigureCustomWorkflowIntegrationQueryVariables>(ConfigureCustomWorkflowIntegrationDocument, options);
        }
export type ConfigureCustomWorkflowIntegrationQueryHookResult = ReturnType<typeof useConfigureCustomWorkflowIntegrationQuery>;
export type ConfigureCustomWorkflowIntegrationLazyQueryHookResult = ReturnType<typeof useConfigureCustomWorkflowIntegrationLazyQuery>;
export type ConfigureCustomWorkflowIntegrationQueryResult = Apollo.QueryResult<ConfigureCustomWorkflowIntegrationQuery, ConfigureCustomWorkflowIntegrationQueryVariables>;
export const IntegrationModalDocument = gql`
    query IntegrationModal($integrationId: ID!) {
  integration(id: $integrationId) {
    ...IntegrationModalIntegration
  }
}
    ${IntegrationModalIntegrationFragmentDoc}`;

/**
 * __useIntegrationModalQuery__
 *
 * To run a query within a React component, call `useIntegrationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationModalQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useIntegrationModalQuery(baseOptions: Apollo.QueryHookOptions<IntegrationModalQuery, IntegrationModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationModalQuery, IntegrationModalQueryVariables>(IntegrationModalDocument, options);
      }
export function useIntegrationModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationModalQuery, IntegrationModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationModalQuery, IntegrationModalQueryVariables>(IntegrationModalDocument, options);
        }
export type IntegrationModalQueryHookResult = ReturnType<typeof useIntegrationModalQuery>;
export type IntegrationModalLazyQueryHookResult = ReturnType<typeof useIntegrationModalLazyQuery>;
export type IntegrationModalQueryResult = Apollo.QueryResult<IntegrationModalQuery, IntegrationModalQueryVariables>;
export const IntegrationModalResetOAuth2IntegrationDocument = gql`
    mutation IntegrationModalResetOAuth2Integration($input: ResetOAuth2IntegrationInput!) {
  resetOAuth2Integration(input: $input) {
    sessionId
  }
}
    `;
export type IntegrationModalResetOAuth2IntegrationMutationFn = Apollo.MutationFunction<IntegrationModalResetOAuth2IntegrationMutation, IntegrationModalResetOAuth2IntegrationMutationVariables>;

/**
 * __useIntegrationModalResetOAuth2IntegrationMutation__
 *
 * To run a mutation, you first call `useIntegrationModalResetOAuth2IntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationModalResetOAuth2IntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationModalResetOAuth2IntegrationMutation, { data, loading, error }] = useIntegrationModalResetOAuth2IntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntegrationModalResetOAuth2IntegrationMutation(baseOptions?: Apollo.MutationHookOptions<IntegrationModalResetOAuth2IntegrationMutation, IntegrationModalResetOAuth2IntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IntegrationModalResetOAuth2IntegrationMutation, IntegrationModalResetOAuth2IntegrationMutationVariables>(IntegrationModalResetOAuth2IntegrationDocument, options);
      }
export type IntegrationModalResetOAuth2IntegrationMutationHookResult = ReturnType<typeof useIntegrationModalResetOAuth2IntegrationMutation>;
export type IntegrationModalResetOAuth2IntegrationMutationResult = Apollo.MutationResult<IntegrationModalResetOAuth2IntegrationMutation>;
export type IntegrationModalResetOAuth2IntegrationMutationOptions = Apollo.BaseMutationOptions<IntegrationModalResetOAuth2IntegrationMutation, IntegrationModalResetOAuth2IntegrationMutationVariables>;
export const IntegrationModalResetCustomWorkflowIntegrationDocument = gql`
    mutation IntegrationModalResetCustomWorkflowIntegration($integrationId: ID!) {
  resetCustomWorkflowIntegration(input: {integrationId: $integrationId}) {
    integration {
      ...IntegrationModalIntegration
    }
  }
}
    ${IntegrationModalIntegrationFragmentDoc}`;
export type IntegrationModalResetCustomWorkflowIntegrationMutationFn = Apollo.MutationFunction<IntegrationModalResetCustomWorkflowIntegrationMutation, IntegrationModalResetCustomWorkflowIntegrationMutationVariables>;

/**
 * __useIntegrationModalResetCustomWorkflowIntegrationMutation__
 *
 * To run a mutation, you first call `useIntegrationModalResetCustomWorkflowIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationModalResetCustomWorkflowIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationModalResetCustomWorkflowIntegrationMutation, { data, loading, error }] = useIntegrationModalResetCustomWorkflowIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useIntegrationModalResetCustomWorkflowIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<IntegrationModalResetCustomWorkflowIntegrationMutation, IntegrationModalResetCustomWorkflowIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IntegrationModalResetCustomWorkflowIntegrationMutation, IntegrationModalResetCustomWorkflowIntegrationMutationVariables>(IntegrationModalResetCustomWorkflowIntegrationDocument, options);
      }
export type IntegrationModalResetCustomWorkflowIntegrationMutationHookResult = ReturnType<typeof useIntegrationModalResetCustomWorkflowIntegrationMutation>;
export type IntegrationModalResetCustomWorkflowIntegrationMutationResult = Apollo.MutationResult<IntegrationModalResetCustomWorkflowIntegrationMutation>;
export type IntegrationModalResetCustomWorkflowIntegrationMutationOptions = Apollo.BaseMutationOptions<IntegrationModalResetCustomWorkflowIntegrationMutation, IntegrationModalResetCustomWorkflowIntegrationMutationVariables>;
export const IntegrationModalCancelCustomWorkflowIntegrationConfigurationDocument = gql`
    mutation IntegrationModalCancelCustomWorkflowIntegrationConfiguration($integrationId: ID!) {
  cancelCustomWorkflowIntegrationConfiguration(
    input: {integrationId: $integrationId}
  ) {
    integration {
      ...IntegrationModalIntegration
    }
  }
}
    ${IntegrationModalIntegrationFragmentDoc}`;
export type IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationFn = Apollo.MutationFunction<IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation, IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationVariables>;

/**
 * __useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation__
 *
 * To run a mutation, you first call `useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationModalCancelCustomWorkflowIntegrationConfigurationMutation, { data, loading, error }] = useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation, IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation, IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationVariables>(IntegrationModalCancelCustomWorkflowIntegrationConfigurationDocument, options);
      }
export type IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationHookResult = ReturnType<typeof useIntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation>;
export type IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationResult = Apollo.MutationResult<IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation>;
export type IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationOptions = Apollo.BaseMutationOptions<IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutation, IntegrationModalCancelCustomWorkflowIntegrationConfigurationMutationVariables>;
export const IntegrationModalEditIntegrationInstanceDocument = gql`
    mutation IntegrationModalEditIntegrationInstance($input: IntegrationPatchRequest!) {
  editIntegration(input: $input) {
    id
    description
    displayName
  }
}
    `;
export type IntegrationModalEditIntegrationInstanceMutationFn = Apollo.MutationFunction<IntegrationModalEditIntegrationInstanceMutation, IntegrationModalEditIntegrationInstanceMutationVariables>;

/**
 * __useIntegrationModalEditIntegrationInstanceMutation__
 *
 * To run a mutation, you first call `useIntegrationModalEditIntegrationInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationModalEditIntegrationInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationModalEditIntegrationInstanceMutation, { data, loading, error }] = useIntegrationModalEditIntegrationInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntegrationModalEditIntegrationInstanceMutation(baseOptions?: Apollo.MutationHookOptions<IntegrationModalEditIntegrationInstanceMutation, IntegrationModalEditIntegrationInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IntegrationModalEditIntegrationInstanceMutation, IntegrationModalEditIntegrationInstanceMutationVariables>(IntegrationModalEditIntegrationInstanceDocument, options);
      }
export type IntegrationModalEditIntegrationInstanceMutationHookResult = ReturnType<typeof useIntegrationModalEditIntegrationInstanceMutation>;
export type IntegrationModalEditIntegrationInstanceMutationResult = Apollo.MutationResult<IntegrationModalEditIntegrationInstanceMutation>;
export type IntegrationModalEditIntegrationInstanceMutationOptions = Apollo.BaseMutationOptions<IntegrationModalEditIntegrationInstanceMutation, IntegrationModalEditIntegrationInstanceMutationVariables>;
export const GetLaunchDarklyUserInfoDocument = gql`
    query GetLaunchDarklyUserInfo {
  tenantMember: me {
    id
    emailAddress
    firstName
    lastName
    tenant {
      id
      name
    }
  }
}
    `;

/**
 * __useGetLaunchDarklyUserInfoQuery__
 *
 * To run a query within a React component, call `useGetLaunchDarklyUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLaunchDarklyUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLaunchDarklyUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLaunchDarklyUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetLaunchDarklyUserInfoQuery, GetLaunchDarklyUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLaunchDarklyUserInfoQuery, GetLaunchDarklyUserInfoQueryVariables>(GetLaunchDarklyUserInfoDocument, options);
      }
export function useGetLaunchDarklyUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLaunchDarklyUserInfoQuery, GetLaunchDarklyUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLaunchDarklyUserInfoQuery, GetLaunchDarklyUserInfoQueryVariables>(GetLaunchDarklyUserInfoDocument, options);
        }
export type GetLaunchDarklyUserInfoQueryHookResult = ReturnType<typeof useGetLaunchDarklyUserInfoQuery>;
export type GetLaunchDarklyUserInfoLazyQueryHookResult = ReturnType<typeof useGetLaunchDarklyUserInfoLazyQuery>;
export type GetLaunchDarklyUserInfoQueryResult = Apollo.QueryResult<GetLaunchDarklyUserInfoQuery, GetLaunchDarklyUserInfoQueryVariables>;
export const AddScheduledJobModalDocument = gql`
    query AddScheduledJobModal($workspaceId: ID!) {
  templateConfigurationOptions: templateConfigurations(
    input: {workspaceId: $workspaceId}
  ) {
    items {
      id
      displayName
    }
  }
}
    `;

/**
 * __useAddScheduledJobModalQuery__
 *
 * To run a query within a React component, call `useAddScheduledJobModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddScheduledJobModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddScheduledJobModalQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useAddScheduledJobModalQuery(baseOptions: Apollo.QueryHookOptions<AddScheduledJobModalQuery, AddScheduledJobModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddScheduledJobModalQuery, AddScheduledJobModalQueryVariables>(AddScheduledJobModalDocument, options);
      }
export function useAddScheduledJobModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddScheduledJobModalQuery, AddScheduledJobModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddScheduledJobModalQuery, AddScheduledJobModalQueryVariables>(AddScheduledJobModalDocument, options);
        }
export type AddScheduledJobModalQueryHookResult = ReturnType<typeof useAddScheduledJobModalQuery>;
export type AddScheduledJobModalLazyQueryHookResult = ReturnType<typeof useAddScheduledJobModalLazyQuery>;
export type AddScheduledJobModalQueryResult = Apollo.QueryResult<AddScheduledJobModalQuery, AddScheduledJobModalQueryVariables>;
export const AddScheduledJobModalSubmitDocument = gql`
    mutation AddScheduledJobModalSubmit($input: CreateScheduledJobInput!) {
  createSchedule(input: $input) {
    id
    pattern
    startTime
    endTime
    timezoneName
    templateConfiguration {
      id
      displayName
    }
  }
}
    `;
export type AddScheduledJobModalSubmitMutationFn = Apollo.MutationFunction<AddScheduledJobModalSubmitMutation, AddScheduledJobModalSubmitMutationVariables>;

/**
 * __useAddScheduledJobModalSubmitMutation__
 *
 * To run a mutation, you first call `useAddScheduledJobModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScheduledJobModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScheduledJobModalSubmitMutation, { data, loading, error }] = useAddScheduledJobModalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddScheduledJobModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<AddScheduledJobModalSubmitMutation, AddScheduledJobModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddScheduledJobModalSubmitMutation, AddScheduledJobModalSubmitMutationVariables>(AddScheduledJobModalSubmitDocument, options);
      }
export type AddScheduledJobModalSubmitMutationHookResult = ReturnType<typeof useAddScheduledJobModalSubmitMutation>;
export type AddScheduledJobModalSubmitMutationResult = Apollo.MutationResult<AddScheduledJobModalSubmitMutation>;
export type AddScheduledJobModalSubmitMutationOptions = Apollo.BaseMutationOptions<AddScheduledJobModalSubmitMutation, AddScheduledJobModalSubmitMutationVariables>;
export const CancelScheduledJobModalDocument = gql`
    query CancelScheduledJobModal($scheduledJobId: ID!) {
  scheduledJob(id: $scheduledJobId) {
    id
    startTime
    endTime
    pattern
    status
    timezoneName
    templateConfiguration {
      id
      displayName
      templateMetadata {
        id
        displayName
      }
    }
  }
}
    `;

/**
 * __useCancelScheduledJobModalQuery__
 *
 * To run a query within a React component, call `useCancelScheduledJobModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledJobModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelScheduledJobModalQuery({
 *   variables: {
 *      scheduledJobId: // value for 'scheduledJobId'
 *   },
 * });
 */
export function useCancelScheduledJobModalQuery(baseOptions: Apollo.QueryHookOptions<CancelScheduledJobModalQuery, CancelScheduledJobModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CancelScheduledJobModalQuery, CancelScheduledJobModalQueryVariables>(CancelScheduledJobModalDocument, options);
      }
export function useCancelScheduledJobModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CancelScheduledJobModalQuery, CancelScheduledJobModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CancelScheduledJobModalQuery, CancelScheduledJobModalQueryVariables>(CancelScheduledJobModalDocument, options);
        }
export type CancelScheduledJobModalQueryHookResult = ReturnType<typeof useCancelScheduledJobModalQuery>;
export type CancelScheduledJobModalLazyQueryHookResult = ReturnType<typeof useCancelScheduledJobModalLazyQuery>;
export type CancelScheduledJobModalQueryResult = Apollo.QueryResult<CancelScheduledJobModalQuery, CancelScheduledJobModalQueryVariables>;
export const CancelScheduledJobModalSubmitDocument = gql`
    mutation CancelScheduledJobModalSubmit($workspaceId: ID!, $scheduledJobId: ID!) {
  cancelSchedule(
    input: {workspaceId: $workspaceId, scheduledJobId: $scheduledJobId}
  ) {
    id
    status
  }
}
    `;
export type CancelScheduledJobModalSubmitMutationFn = Apollo.MutationFunction<CancelScheduledJobModalSubmitMutation, CancelScheduledJobModalSubmitMutationVariables>;

/**
 * __useCancelScheduledJobModalSubmitMutation__
 *
 * To run a mutation, you first call `useCancelScheduledJobModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledJobModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledJobModalSubmitMutation, { data, loading, error }] = useCancelScheduledJobModalSubmitMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      scheduledJobId: // value for 'scheduledJobId'
 *   },
 * });
 */
export function useCancelScheduledJobModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<CancelScheduledJobModalSubmitMutation, CancelScheduledJobModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelScheduledJobModalSubmitMutation, CancelScheduledJobModalSubmitMutationVariables>(CancelScheduledJobModalSubmitDocument, options);
      }
export type CancelScheduledJobModalSubmitMutationHookResult = ReturnType<typeof useCancelScheduledJobModalSubmitMutation>;
export type CancelScheduledJobModalSubmitMutationResult = Apollo.MutationResult<CancelScheduledJobModalSubmitMutation>;
export type CancelScheduledJobModalSubmitMutationOptions = Apollo.BaseMutationOptions<CancelScheduledJobModalSubmitMutation, CancelScheduledJobModalSubmitMutationVariables>;
export const ChangeScheduledJobModalDocument = gql`
    query ChangeScheduledJobModal($scheduledJobId: ID!) {
  scheduledJob(id: $scheduledJobId) {
    id
    startTime
    endTime
    pattern
    status
    timezoneName
    maxRecurrenceCount
    templateConfiguration {
      id
      displayName
    }
  }
}
    `;

/**
 * __useChangeScheduledJobModalQuery__
 *
 * To run a query within a React component, call `useChangeScheduledJobModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeScheduledJobModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeScheduledJobModalQuery({
 *   variables: {
 *      scheduledJobId: // value for 'scheduledJobId'
 *   },
 * });
 */
export function useChangeScheduledJobModalQuery(baseOptions: Apollo.QueryHookOptions<ChangeScheduledJobModalQuery, ChangeScheduledJobModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChangeScheduledJobModalQuery, ChangeScheduledJobModalQueryVariables>(ChangeScheduledJobModalDocument, options);
      }
export function useChangeScheduledJobModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChangeScheduledJobModalQuery, ChangeScheduledJobModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChangeScheduledJobModalQuery, ChangeScheduledJobModalQueryVariables>(ChangeScheduledJobModalDocument, options);
        }
export type ChangeScheduledJobModalQueryHookResult = ReturnType<typeof useChangeScheduledJobModalQuery>;
export type ChangeScheduledJobModalLazyQueryHookResult = ReturnType<typeof useChangeScheduledJobModalLazyQuery>;
export type ChangeScheduledJobModalQueryResult = Apollo.QueryResult<ChangeScheduledJobModalQuery, ChangeScheduledJobModalQueryVariables>;
export const ChangeScheduledJobModalSubmitDocument = gql`
    mutation ChangeScheduledJobModalSubmit($input: UpdateScheduledJobInput!) {
  updateSchedule(input: $input) {
    id
    startTime
    endTime
    pattern
    status
    timezoneName
    maxRecurrenceCount
  }
}
    `;
export type ChangeScheduledJobModalSubmitMutationFn = Apollo.MutationFunction<ChangeScheduledJobModalSubmitMutation, ChangeScheduledJobModalSubmitMutationVariables>;

/**
 * __useChangeScheduledJobModalSubmitMutation__
 *
 * To run a mutation, you first call `useChangeScheduledJobModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeScheduledJobModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeScheduledJobModalSubmitMutation, { data, loading, error }] = useChangeScheduledJobModalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeScheduledJobModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<ChangeScheduledJobModalSubmitMutation, ChangeScheduledJobModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeScheduledJobModalSubmitMutation, ChangeScheduledJobModalSubmitMutationVariables>(ChangeScheduledJobModalSubmitDocument, options);
      }
export type ChangeScheduledJobModalSubmitMutationHookResult = ReturnType<typeof useChangeScheduledJobModalSubmitMutation>;
export type ChangeScheduledJobModalSubmitMutationResult = Apollo.MutationResult<ChangeScheduledJobModalSubmitMutation>;
export type ChangeScheduledJobModalSubmitMutationOptions = Apollo.BaseMutationOptions<ChangeScheduledJobModalSubmitMutation, ChangeScheduledJobModalSubmitMutationVariables>;
export const ScheduledJobScheduleToggleDocument = gql`
    mutation ScheduledJobScheduleToggle($workspaceId: ID!, $scheduleId: ID!) {
  toggleScheduleActivity(
    input: {workspaceId: $workspaceId, scheduleId: $scheduleId}
  ) {
    id
    status
  }
}
    `;
export type ScheduledJobScheduleToggleMutationFn = Apollo.MutationFunction<ScheduledJobScheduleToggleMutation, ScheduledJobScheduleToggleMutationVariables>;

/**
 * __useScheduledJobScheduleToggleMutation__
 *
 * To run a mutation, you first call `useScheduledJobScheduleToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduledJobScheduleToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduledJobScheduleToggleMutation, { data, loading, error }] = useScheduledJobScheduleToggleMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useScheduledJobScheduleToggleMutation(baseOptions?: Apollo.MutationHookOptions<ScheduledJobScheduleToggleMutation, ScheduledJobScheduleToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduledJobScheduleToggleMutation, ScheduledJobScheduleToggleMutationVariables>(ScheduledJobScheduleToggleDocument, options);
      }
export type ScheduledJobScheduleToggleMutationHookResult = ReturnType<typeof useScheduledJobScheduleToggleMutation>;
export type ScheduledJobScheduleToggleMutationResult = Apollo.MutationResult<ScheduledJobScheduleToggleMutation>;
export type ScheduledJobScheduleToggleMutationOptions = Apollo.BaseMutationOptions<ScheduledJobScheduleToggleMutation, ScheduledJobScheduleToggleMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    isSuccessful
    errors {
      description
      code
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CancelRunModalDocument = gql`
    mutation CancelRunModal($workspaceId: ID!, $instanceId: ID!) {
  cancelWorkflow(input: {workspaceId: $workspaceId, instanceId: $instanceId}) {
    id
    status
  }
}
    `;
export type CancelRunModalMutationFn = Apollo.MutationFunction<CancelRunModalMutation, CancelRunModalMutationVariables>;

/**
 * __useCancelRunModalMutation__
 *
 * To run a mutation, you first call `useCancelRunModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRunModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRunModalMutation, { data, loading, error }] = useCancelRunModalMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useCancelRunModalMutation(baseOptions?: Apollo.MutationHookOptions<CancelRunModalMutation, CancelRunModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRunModalMutation, CancelRunModalMutationVariables>(CancelRunModalDocument, options);
      }
export type CancelRunModalMutationHookResult = ReturnType<typeof useCancelRunModalMutation>;
export type CancelRunModalMutationResult = Apollo.MutationResult<CancelRunModalMutation>;
export type CancelRunModalMutationOptions = Apollo.BaseMutationOptions<CancelRunModalMutation, CancelRunModalMutationVariables>;
export const ConnectionStringModalDocument = gql`
    query ConnectionStringModal($dataSetId: ID!) {
  dataSet(id: $dataSetId) {
    connectionString
    displayName
    id
    readOnlyConnectionString
  }
}
    `;

/**
 * __useConnectionStringModalQuery__
 *
 * To run a query within a React component, call `useConnectionStringModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionStringModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionStringModalQuery({
 *   variables: {
 *      dataSetId: // value for 'dataSetId'
 *   },
 * });
 */
export function useConnectionStringModalQuery(baseOptions: Apollo.QueryHookOptions<ConnectionStringModalQuery, ConnectionStringModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionStringModalQuery, ConnectionStringModalQueryVariables>(ConnectionStringModalDocument, options);
      }
export function useConnectionStringModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionStringModalQuery, ConnectionStringModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionStringModalQuery, ConnectionStringModalQueryVariables>(ConnectionStringModalDocument, options);
        }
export type ConnectionStringModalQueryHookResult = ReturnType<typeof useConnectionStringModalQuery>;
export type ConnectionStringModalLazyQueryHookResult = ReturnType<typeof useConnectionStringModalLazyQuery>;
export type ConnectionStringModalQueryResult = Apollo.QueryResult<ConnectionStringModalQuery, ConnectionStringModalQueryVariables>;
export const DeleteDataSetModalDocument = gql`
    query DeleteDataSetModal($dataSetId: ID!) {
  dataSet(id: $dataSetId) {
    description
    displayName
    id
    status
    workspaceId
    createdBy {
      id
      firstName
      lastName
    }
    dependentTemplateConfigurations {
      items {
        id
        displayName
      }
    }
    createdOn
  }
}
    `;

/**
 * __useDeleteDataSetModalQuery__
 *
 * To run a query within a React component, call `useDeleteDataSetModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSetModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteDataSetModalQuery({
 *   variables: {
 *      dataSetId: // value for 'dataSetId'
 *   },
 * });
 */
export function useDeleteDataSetModalQuery(baseOptions: Apollo.QueryHookOptions<DeleteDataSetModalQuery, DeleteDataSetModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteDataSetModalQuery, DeleteDataSetModalQueryVariables>(DeleteDataSetModalDocument, options);
      }
export function useDeleteDataSetModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteDataSetModalQuery, DeleteDataSetModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteDataSetModalQuery, DeleteDataSetModalQueryVariables>(DeleteDataSetModalDocument, options);
        }
export type DeleteDataSetModalQueryHookResult = ReturnType<typeof useDeleteDataSetModalQuery>;
export type DeleteDataSetModalLazyQueryHookResult = ReturnType<typeof useDeleteDataSetModalLazyQuery>;
export type DeleteDataSetModalQueryResult = Apollo.QueryResult<DeleteDataSetModalQuery, DeleteDataSetModalQueryVariables>;
export const DeleteDataSetModalSubmitDocument = gql`
    mutation DeleteDataSetModalSubmit($workspaceId: ID!, $dataSetId: ID!) {
  deleteDataSet(input: {workspaceId: $workspaceId, dataSetId: $dataSetId}) {
    id
    status
  }
}
    `;
export type DeleteDataSetModalSubmitMutationFn = Apollo.MutationFunction<DeleteDataSetModalSubmitMutation, DeleteDataSetModalSubmitMutationVariables>;

/**
 * __useDeleteDataSetModalSubmitMutation__
 *
 * To run a mutation, you first call `useDeleteDataSetModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSetModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSetModalSubmitMutation, { data, loading, error }] = useDeleteDataSetModalSubmitMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      dataSetId: // value for 'dataSetId'
 *   },
 * });
 */
export function useDeleteDataSetModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataSetModalSubmitMutation, DeleteDataSetModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataSetModalSubmitMutation, DeleteDataSetModalSubmitMutationVariables>(DeleteDataSetModalSubmitDocument, options);
      }
export type DeleteDataSetModalSubmitMutationHookResult = ReturnType<typeof useDeleteDataSetModalSubmitMutation>;
export type DeleteDataSetModalSubmitMutationResult = Apollo.MutationResult<DeleteDataSetModalSubmitMutation>;
export type DeleteDataSetModalSubmitMutationOptions = Apollo.BaseMutationOptions<DeleteDataSetModalSubmitMutation, DeleteDataSetModalSubmitMutationVariables>;
export const DeleteTemplateConfigurationModalDocument = gql`
    query DeleteTemplateConfigurationModal($templateConfigurationId: ID!) {
  templateConfiguration(id: $templateConfigurationId) {
    id
    displayName
    instances(input: {statuses: [RUNNING]}) {
      items {
        id
        updatedOn
      }
    }
    scheduledJobs(input: {statuses: [ACTIVE]}) {
      items {
        id
        pattern
      }
    }
  }
}
    `;

/**
 * __useDeleteTemplateConfigurationModalQuery__
 *
 * To run a query within a React component, call `useDeleteTemplateConfigurationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateConfigurationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteTemplateConfigurationModalQuery({
 *   variables: {
 *      templateConfigurationId: // value for 'templateConfigurationId'
 *   },
 * });
 */
export function useDeleteTemplateConfigurationModalQuery(baseOptions: Apollo.QueryHookOptions<DeleteTemplateConfigurationModalQuery, DeleteTemplateConfigurationModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteTemplateConfigurationModalQuery, DeleteTemplateConfigurationModalQueryVariables>(DeleteTemplateConfigurationModalDocument, options);
      }
export function useDeleteTemplateConfigurationModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteTemplateConfigurationModalQuery, DeleteTemplateConfigurationModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteTemplateConfigurationModalQuery, DeleteTemplateConfigurationModalQueryVariables>(DeleteTemplateConfigurationModalDocument, options);
        }
export type DeleteTemplateConfigurationModalQueryHookResult = ReturnType<typeof useDeleteTemplateConfigurationModalQuery>;
export type DeleteTemplateConfigurationModalLazyQueryHookResult = ReturnType<typeof useDeleteTemplateConfigurationModalLazyQuery>;
export type DeleteTemplateConfigurationModalQueryResult = Apollo.QueryResult<DeleteTemplateConfigurationModalQuery, DeleteTemplateConfigurationModalQueryVariables>;
export const DeleteTemplateConfigurationModalSubmitDocument = gql`
    mutation DeleteTemplateConfigurationModalSubmit($input: DeleteTemplateConfigurationInput!) {
  deleteTemplateConfiguration(input: $input) {
    id
    status
  }
}
    `;
export type DeleteTemplateConfigurationModalSubmitMutationFn = Apollo.MutationFunction<DeleteTemplateConfigurationModalSubmitMutation, DeleteTemplateConfigurationModalSubmitMutationVariables>;

/**
 * __useDeleteTemplateConfigurationModalSubmitMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateConfigurationModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateConfigurationModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateConfigurationModalSubmitMutation, { data, loading, error }] = useDeleteTemplateConfigurationModalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateConfigurationModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateConfigurationModalSubmitMutation, DeleteTemplateConfigurationModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateConfigurationModalSubmitMutation, DeleteTemplateConfigurationModalSubmitMutationVariables>(DeleteTemplateConfigurationModalSubmitDocument, options);
      }
export type DeleteTemplateConfigurationModalSubmitMutationHookResult = ReturnType<typeof useDeleteTemplateConfigurationModalSubmitMutation>;
export type DeleteTemplateConfigurationModalSubmitMutationResult = Apollo.MutationResult<DeleteTemplateConfigurationModalSubmitMutation>;
export type DeleteTemplateConfigurationModalSubmitMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateConfigurationModalSubmitMutation, DeleteTemplateConfigurationModalSubmitMutationVariables>;
export const DeleteWorkspaceModalDocument = gql`
    query DeleteWorkspaceModal($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    displayName
    id
  }
}
    `;

/**
 * __useDeleteWorkspaceModalQuery__
 *
 * To run a query within a React component, call `useDeleteWorkspaceModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteWorkspaceModalQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteWorkspaceModalQuery(baseOptions: Apollo.QueryHookOptions<DeleteWorkspaceModalQuery, DeleteWorkspaceModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteWorkspaceModalQuery, DeleteWorkspaceModalQueryVariables>(DeleteWorkspaceModalDocument, options);
      }
export function useDeleteWorkspaceModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteWorkspaceModalQuery, DeleteWorkspaceModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteWorkspaceModalQuery, DeleteWorkspaceModalQueryVariables>(DeleteWorkspaceModalDocument, options);
        }
export type DeleteWorkspaceModalQueryHookResult = ReturnType<typeof useDeleteWorkspaceModalQuery>;
export type DeleteWorkspaceModalLazyQueryHookResult = ReturnType<typeof useDeleteWorkspaceModalLazyQuery>;
export type DeleteWorkspaceModalQueryResult = Apollo.QueryResult<DeleteWorkspaceModalQuery, DeleteWorkspaceModalQueryVariables>;
export const DeleteWorkspaceModalSubmitDocument = gql`
    mutation DeleteWorkspaceModalSubmit($workspaceId: ID!) {
  deleteWorkspace(input: {id: $workspaceId}) {
    errors {
      code
      description
    }
    isSuccessful
  }
}
    `;
export type DeleteWorkspaceModalSubmitMutationFn = Apollo.MutationFunction<DeleteWorkspaceModalSubmitMutation, DeleteWorkspaceModalSubmitMutationVariables>;

/**
 * __useDeleteWorkspaceModalSubmitMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceModalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceModalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceModalSubmitMutation, { data, loading, error }] = useDeleteWorkspaceModalSubmitMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteWorkspaceModalSubmitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceModalSubmitMutation, DeleteWorkspaceModalSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceModalSubmitMutation, DeleteWorkspaceModalSubmitMutationVariables>(DeleteWorkspaceModalSubmitDocument, options);
      }
export type DeleteWorkspaceModalSubmitMutationHookResult = ReturnType<typeof useDeleteWorkspaceModalSubmitMutation>;
export type DeleteWorkspaceModalSubmitMutationResult = Apollo.MutationResult<DeleteWorkspaceModalSubmitMutation>;
export type DeleteWorkspaceModalSubmitMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceModalSubmitMutation, DeleteWorkspaceModalSubmitMutationVariables>;
export const FormDataRequestDetailsDocument = gql`
    query FormDataRequestDetails($stepId: ID!) {
  step(id: $stepId) {
    id
    displayName
    name
    status
    dataRequest {
      ...FormDataRequestDetailsDataRequest
    }
    instance {
      id
      status
      workspaceId
    }
  }
}
    ${FormDataRequestDetailsDataRequestFragmentDoc}`;

/**
 * __useFormDataRequestDetailsQuery__
 *
 * To run a query within a React component, call `useFormDataRequestDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormDataRequestDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormDataRequestDetailsQuery({
 *   variables: {
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useFormDataRequestDetailsQuery(baseOptions: Apollo.QueryHookOptions<FormDataRequestDetailsQuery, FormDataRequestDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormDataRequestDetailsQuery, FormDataRequestDetailsQueryVariables>(FormDataRequestDetailsDocument, options);
      }
export function useFormDataRequestDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormDataRequestDetailsQuery, FormDataRequestDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormDataRequestDetailsQuery, FormDataRequestDetailsQueryVariables>(FormDataRequestDetailsDocument, options);
        }
export type FormDataRequestDetailsQueryHookResult = ReturnType<typeof useFormDataRequestDetailsQuery>;
export type FormDataRequestDetailsLazyQueryHookResult = ReturnType<typeof useFormDataRequestDetailsLazyQuery>;
export type FormDataRequestDetailsQueryResult = Apollo.QueryResult<FormDataRequestDetailsQuery, FormDataRequestDetailsQueryVariables>;
export const FormDataRequestDetailsCompleteDataRequestDocument = gql`
    mutation FormDataRequestDetailsCompleteDataRequest($input: CompleteDataRequestInput!) {
  completeDataRequest(input: $input) {
    ...FormDataRequestDetailsDataRequest
  }
}
    ${FormDataRequestDetailsDataRequestFragmentDoc}`;
export type FormDataRequestDetailsCompleteDataRequestMutationFn = Apollo.MutationFunction<FormDataRequestDetailsCompleteDataRequestMutation, FormDataRequestDetailsCompleteDataRequestMutationVariables>;

/**
 * __useFormDataRequestDetailsCompleteDataRequestMutation__
 *
 * To run a mutation, you first call `useFormDataRequestDetailsCompleteDataRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormDataRequestDetailsCompleteDataRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formDataRequestDetailsCompleteDataRequestMutation, { data, loading, error }] = useFormDataRequestDetailsCompleteDataRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormDataRequestDetailsCompleteDataRequestMutation(baseOptions?: Apollo.MutationHookOptions<FormDataRequestDetailsCompleteDataRequestMutation, FormDataRequestDetailsCompleteDataRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FormDataRequestDetailsCompleteDataRequestMutation, FormDataRequestDetailsCompleteDataRequestMutationVariables>(FormDataRequestDetailsCompleteDataRequestDocument, options);
      }
export type FormDataRequestDetailsCompleteDataRequestMutationHookResult = ReturnType<typeof useFormDataRequestDetailsCompleteDataRequestMutation>;
export type FormDataRequestDetailsCompleteDataRequestMutationResult = Apollo.MutationResult<FormDataRequestDetailsCompleteDataRequestMutation>;
export type FormDataRequestDetailsCompleteDataRequestMutationOptions = Apollo.BaseMutationOptions<FormDataRequestDetailsCompleteDataRequestMutation, FormDataRequestDetailsCompleteDataRequestMutationVariables>;
export const StepDetailsDocument = gql`
    query StepDetails($stepId: ID!) {
  step(id: $stepId) {
    ...StepDetailsStep
  }
}
    ${StepDetailsStepFragmentDoc}`;

/**
 * __useStepDetailsQuery__
 *
 * To run a query within a React component, call `useStepDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepDetailsQuery({
 *   variables: {
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useStepDetailsQuery(baseOptions: Apollo.QueryHookOptions<StepDetailsQuery, StepDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepDetailsQuery, StepDetailsQueryVariables>(StepDetailsDocument, options);
      }
export function useStepDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepDetailsQuery, StepDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepDetailsQuery, StepDetailsQueryVariables>(StepDetailsDocument, options);
        }
export type StepDetailsQueryHookResult = ReturnType<typeof useStepDetailsQuery>;
export type StepDetailsLazyQueryHookResult = ReturnType<typeof useStepDetailsLazyQuery>;
export type StepDetailsQueryResult = Apollo.QueryResult<StepDetailsQuery, StepDetailsQueryVariables>;
export const WorkflowInstanceViewDocument = gql`
    query WorkflowInstanceView($instanceId: ID!) {
  instance(id: $instanceId) {
    ...WorkflowInstanceViewInstance
  }
}
    ${WorkflowInstanceViewInstanceFragmentDoc}`;

/**
 * __useWorkflowInstanceViewQuery__
 *
 * To run a query within a React component, call `useWorkflowInstanceViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowInstanceViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowInstanceViewQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useWorkflowInstanceViewQuery(baseOptions: Apollo.QueryHookOptions<WorkflowInstanceViewQuery, WorkflowInstanceViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowInstanceViewQuery, WorkflowInstanceViewQueryVariables>(WorkflowInstanceViewDocument, options);
      }
export function useWorkflowInstanceViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowInstanceViewQuery, WorkflowInstanceViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowInstanceViewQuery, WorkflowInstanceViewQueryVariables>(WorkflowInstanceViewDocument, options);
        }
export type WorkflowInstanceViewQueryHookResult = ReturnType<typeof useWorkflowInstanceViewQuery>;
export type WorkflowInstanceViewLazyQueryHookResult = ReturnType<typeof useWorkflowInstanceViewLazyQuery>;
export type WorkflowInstanceViewQueryResult = Apollo.QueryResult<WorkflowInstanceViewQuery, WorkflowInstanceViewQueryVariables>;
export const WorkflowInstanceViewSaveTemplateConfigurationDocument = gql`
    mutation WorkflowInstanceViewSaveTemplateConfiguration($input: ConvertOnDemandTemplateConfigurationInput!) {
  convertOnDemandTemplateConfiguration(input: $input) {
    id
    displayName
  }
}
    `;
export type WorkflowInstanceViewSaveTemplateConfigurationMutationFn = Apollo.MutationFunction<WorkflowInstanceViewSaveTemplateConfigurationMutation, WorkflowInstanceViewSaveTemplateConfigurationMutationVariables>;

/**
 * __useWorkflowInstanceViewSaveTemplateConfigurationMutation__
 *
 * To run a mutation, you first call `useWorkflowInstanceViewSaveTemplateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkflowInstanceViewSaveTemplateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workflowInstanceViewSaveTemplateConfigurationMutation, { data, loading, error }] = useWorkflowInstanceViewSaveTemplateConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkflowInstanceViewSaveTemplateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<WorkflowInstanceViewSaveTemplateConfigurationMutation, WorkflowInstanceViewSaveTemplateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkflowInstanceViewSaveTemplateConfigurationMutation, WorkflowInstanceViewSaveTemplateConfigurationMutationVariables>(WorkflowInstanceViewSaveTemplateConfigurationDocument, options);
      }
export type WorkflowInstanceViewSaveTemplateConfigurationMutationHookResult = ReturnType<typeof useWorkflowInstanceViewSaveTemplateConfigurationMutation>;
export type WorkflowInstanceViewSaveTemplateConfigurationMutationResult = Apollo.MutationResult<WorkflowInstanceViewSaveTemplateConfigurationMutation>;
export type WorkflowInstanceViewSaveTemplateConfigurationMutationOptions = Apollo.BaseMutationOptions<WorkflowInstanceViewSaveTemplateConfigurationMutation, WorkflowInstanceViewSaveTemplateConfigurationMutationVariables>;
export const IntegrationFieldOptionsDocument = gql`
    query IntegrationFieldOptions($integrationReferences: [IntegrationReference!]!) {
  integrations(
    input: {matchingIntegrations: {integrationReferences: $integrationReferences}, statuses: [ACTIVE]}
  ) {
    items {
      id
      displayName
      description
      integrationMetadata {
        type
        displayName
        __typename
      }
      __typename
    }
  }
  matchingIntegrationMetadatas: integrationMetadatasByName(
    input: {integrationReferences: $integrationReferences}
  ) {
    id
    name
    displayName
  }
}
    `;

/**
 * __useIntegrationFieldOptionsQuery__
 *
 * To run a query within a React component, call `useIntegrationFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationFieldOptionsQuery({
 *   variables: {
 *      integrationReferences: // value for 'integrationReferences'
 *   },
 * });
 */
export function useIntegrationFieldOptionsQuery(baseOptions: Apollo.QueryHookOptions<IntegrationFieldOptionsQuery, IntegrationFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationFieldOptionsQuery, IntegrationFieldOptionsQueryVariables>(IntegrationFieldOptionsDocument, options);
      }
export function useIntegrationFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationFieldOptionsQuery, IntegrationFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationFieldOptionsQuery, IntegrationFieldOptionsQueryVariables>(IntegrationFieldOptionsDocument, options);
        }
export type IntegrationFieldOptionsQueryHookResult = ReturnType<typeof useIntegrationFieldOptionsQuery>;
export type IntegrationFieldOptionsLazyQueryHookResult = ReturnType<typeof useIntegrationFieldOptionsLazyQuery>;
export type IntegrationFieldOptionsQueryResult = Apollo.QueryResult<IntegrationFieldOptionsQuery, IntegrationFieldOptionsQueryVariables>;
export const GetCurrentUserTenantMemberDocument = gql`
    query GetCurrentUserTenantMember {
  me {
    ...CurrentTenantMember
  }
}
    ${CurrentTenantMemberFragmentDoc}`;

/**
 * __useGetCurrentUserTenantMemberQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserTenantMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserTenantMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserTenantMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserTenantMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserTenantMemberQuery, GetCurrentUserTenantMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserTenantMemberQuery, GetCurrentUserTenantMemberQueryVariables>(GetCurrentUserTenantMemberDocument, options);
      }
export function useGetCurrentUserTenantMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserTenantMemberQuery, GetCurrentUserTenantMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserTenantMemberQuery, GetCurrentUserTenantMemberQueryVariables>(GetCurrentUserTenantMemberDocument, options);
        }
export type GetCurrentUserTenantMemberQueryHookResult = ReturnType<typeof useGetCurrentUserTenantMemberQuery>;
export type GetCurrentUserTenantMemberLazyQueryHookResult = ReturnType<typeof useGetCurrentUserTenantMemberLazyQuery>;
export type GetCurrentUserTenantMemberQueryResult = Apollo.QueryResult<GetCurrentUserTenantMemberQuery, GetCurrentUserTenantMemberQueryVariables>;
export const FindTenantMembersDocument = gql`
    query FindTenantMembers($input: TenantMembersRetrieveInput!) {
  tenantMembers(input: $input) {
    ...TenantMember
  }
}
    ${TenantMemberFragmentDoc}`;

/**
 * __useFindTenantMembersQuery__
 *
 * To run a query within a React component, call `useFindTenantMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTenantMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTenantMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindTenantMembersQuery(baseOptions: Apollo.QueryHookOptions<FindTenantMembersQuery, FindTenantMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTenantMembersQuery, FindTenantMembersQueryVariables>(FindTenantMembersDocument, options);
      }
export function useFindTenantMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTenantMembersQuery, FindTenantMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTenantMembersQuery, FindTenantMembersQueryVariables>(FindTenantMembersDocument, options);
        }
export type FindTenantMembersQueryHookResult = ReturnType<typeof useFindTenantMembersQuery>;
export type FindTenantMembersLazyQueryHookResult = ReturnType<typeof useFindTenantMembersLazyQuery>;
export type FindTenantMembersQueryResult = Apollo.QueryResult<FindTenantMembersQuery, FindTenantMembersQueryVariables>;
export const FindTenantsDocument = gql`
    query FindTenants {
  tenants {
    ...Tenant
  }
}
    ${TenantFragmentDoc}`;

/**
 * __useFindTenantsQuery__
 *
 * To run a query within a React component, call `useFindTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindTenantsQuery(baseOptions?: Apollo.QueryHookOptions<FindTenantsQuery, FindTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTenantsQuery, FindTenantsQueryVariables>(FindTenantsDocument, options);
      }
export function useFindTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTenantsQuery, FindTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTenantsQuery, FindTenantsQueryVariables>(FindTenantsDocument, options);
        }
export type FindTenantsQueryHookResult = ReturnType<typeof useFindTenantsQuery>;
export type FindTenantsLazyQueryHookResult = ReturnType<typeof useFindTenantsLazyQuery>;
export type FindTenantsQueryResult = Apollo.QueryResult<FindTenantsQuery, FindTenantsQueryVariables>;
export const TenantHasExternalIdpDocument = gql`
    query TenantHasExternalIdp {
  tenantIdp {
    externalProviderEnabled
  }
}
    `;

/**
 * __useTenantHasExternalIdpQuery__
 *
 * To run a query within a React component, call `useTenantHasExternalIdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantHasExternalIdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantHasExternalIdpQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantHasExternalIdpQuery(baseOptions?: Apollo.QueryHookOptions<TenantHasExternalIdpQuery, TenantHasExternalIdpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantHasExternalIdpQuery, TenantHasExternalIdpQueryVariables>(TenantHasExternalIdpDocument, options);
      }
export function useTenantHasExternalIdpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantHasExternalIdpQuery, TenantHasExternalIdpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantHasExternalIdpQuery, TenantHasExternalIdpQueryVariables>(TenantHasExternalIdpDocument, options);
        }
export type TenantHasExternalIdpQueryHookResult = ReturnType<typeof useTenantHasExternalIdpQuery>;
export type TenantHasExternalIdpLazyQueryHookResult = ReturnType<typeof useTenantHasExternalIdpLazyQuery>;
export type TenantHasExternalIdpQueryResult = Apollo.QueryResult<TenantHasExternalIdpQuery, TenantHasExternalIdpQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: AddTenantMemberInput!) {
  addTenantMember(input: $input) {
    isSuccessful
    errors {
      code
    }
    tenantMember {
      ...TenantMember
    }
  }
}
    ${TenantMemberFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($input: EditTenantMemberInput!) {
  editTenantMember(input: $input) {
    isSuccessful
    errors {
      code
    }
    tenantMember {
      ...TenantMember
    }
  }
}
    ${TenantMemberFragmentDoc}`;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const DeleteTenantMemberDocument = gql`
    mutation DeleteTenantMember($input: DeleteTenantMemberInput!) {
  deleteTenantMember(input: $input) {
    isSuccessful
  }
}
    `;
export type DeleteTenantMemberMutationFn = Apollo.MutationFunction<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>;

/**
 * __useDeleteTenantMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMemberMutation, { data, loading, error }] = useDeleteTenantMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTenantMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>(DeleteTenantMemberDocument, options);
      }
export type DeleteTenantMemberMutationHookResult = ReturnType<typeof useDeleteTenantMemberMutation>;
export type DeleteTenantMemberMutationResult = Apollo.MutationResult<DeleteTenantMemberMutation>;
export type DeleteTenantMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>;
export const ResendInvitationEmailDocument = gql`
    mutation ResendInvitationEmail($input: ResendEmailInvitationInput!) {
  resendEmailInvitation(input: $input) {
    isSuccessful
    errors {
      code
    }
  }
}
    `;
export type ResendInvitationEmailMutationFn = Apollo.MutationFunction<ResendInvitationEmailMutation, ResendInvitationEmailMutationVariables>;

/**
 * __useResendInvitationEmailMutation__
 *
 * To run a mutation, you first call `useResendInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationEmailMutation, { data, loading, error }] = useResendInvitationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendInvitationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationEmailMutation, ResendInvitationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationEmailMutation, ResendInvitationEmailMutationVariables>(ResendInvitationEmailDocument, options);
      }
export type ResendInvitationEmailMutationHookResult = ReturnType<typeof useResendInvitationEmailMutation>;
export type ResendInvitationEmailMutationResult = Apollo.MutationResult<ResendInvitationEmailMutation>;
export type ResendInvitationEmailMutationOptions = Apollo.BaseMutationOptions<ResendInvitationEmailMutation, ResendInvitationEmailMutationVariables>;
export const PingDocument = gql`
    mutation Ping {
  ping {
    isSuccessful
  }
}
    `;
export type PingMutationFn = Apollo.MutationFunction<PingMutation, PingMutationVariables>;

/**
 * __usePingMutation__
 *
 * To run a mutation, you first call `usePingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pingMutation, { data, loading, error }] = usePingMutation({
 *   variables: {
 *   },
 * });
 */
export function usePingMutation(baseOptions?: Apollo.MutationHookOptions<PingMutation, PingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PingMutation, PingMutationVariables>(PingDocument, options);
      }
export type PingMutationHookResult = ReturnType<typeof usePingMutation>;
export type PingMutationResult = Apollo.MutationResult<PingMutation>;
export type PingMutationOptions = Apollo.BaseMutationOptions<PingMutation, PingMutationVariables>;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($workspace: CreateWorkspaceInput!) {
  createWorkspace(input: $workspace) {
    id
  }
}
    `;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const EditWorkspaceDocument = gql`
    mutation EditWorkspace($input: EditWorkspaceInput!) {
  editWorkspace(input: $input) {
    ...Workspace
  }
}
    ${WorkspaceFragmentDoc}`;
export type EditWorkspaceMutationFn = Apollo.MutationFunction<EditWorkspaceMutation, EditWorkspaceMutationVariables>;

/**
 * __useEditWorkspaceMutation__
 *
 * To run a mutation, you first call `useEditWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkspaceMutation, { data, loading, error }] = useEditWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<EditWorkspaceMutation, EditWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWorkspaceMutation, EditWorkspaceMutationVariables>(EditWorkspaceDocument, options);
      }
export type EditWorkspaceMutationHookResult = ReturnType<typeof useEditWorkspaceMutation>;
export type EditWorkspaceMutationResult = Apollo.MutationResult<EditWorkspaceMutation>;
export type EditWorkspaceMutationOptions = Apollo.BaseMutationOptions<EditWorkspaceMutation, EditWorkspaceMutationVariables>;
export const FindWorkspacesDocument = gql`
    query FindWorkspaces {
  workspaces(input: {}) {
    items {
      ...Workspace
    }
  }
}
    ${WorkspaceFragmentDoc}`;

/**
 * __useFindWorkspacesQuery__
 *
 * To run a query within a React component, call `useFindWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<FindWorkspacesQuery, FindWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindWorkspacesQuery, FindWorkspacesQueryVariables>(FindWorkspacesDocument, options);
      }
export function useFindWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindWorkspacesQuery, FindWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindWorkspacesQuery, FindWorkspacesQueryVariables>(FindWorkspacesDocument, options);
        }
export type FindWorkspacesQueryHookResult = ReturnType<typeof useFindWorkspacesQuery>;
export type FindWorkspacesLazyQueryHookResult = ReturnType<typeof useFindWorkspacesLazyQuery>;
export type FindWorkspacesQueryResult = Apollo.QueryResult<FindWorkspacesQuery, FindWorkspacesQueryVariables>;
export const FindWorkspaceDocument = gql`
    query FindWorkspace($id: ID!) {
  workspace(id: $id) {
    ...Workspace
  }
}
    ${WorkspaceFragmentDoc}`;

/**
 * __useFindWorkspaceQuery__
 *
 * To run a query within a React component, call `useFindWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<FindWorkspaceQuery, FindWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindWorkspaceQuery, FindWorkspaceQueryVariables>(FindWorkspaceDocument, options);
      }
export function useFindWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindWorkspaceQuery, FindWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindWorkspaceQuery, FindWorkspaceQueryVariables>(FindWorkspaceDocument, options);
        }
export type FindWorkspaceQueryHookResult = ReturnType<typeof useFindWorkspaceQuery>;
export type FindWorkspaceLazyQueryHookResult = ReturnType<typeof useFindWorkspaceLazyQuery>;
export type FindWorkspaceQueryResult = Apollo.QueryResult<FindWorkspaceQuery, FindWorkspaceQueryVariables>;
export const DashboardPageDocument = gql`
    query DashboardPage($id: ID!) {
  dashboard(id: $id) {
    id
    dataSet {
      id
      displayName
      workspaceId
    }
    dashboardMetadata {
      id
      displayName
      packageMetadata {
        id
        version
      }
    }
    dashboardReport {
      id
      embedToken
      embedUrl
      reportId
    }
    description
    displayName
    status
    workspaceId
  }
}
    `;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDashboardPageQuery(baseOptions: Apollo.QueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
      }
export function useDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
        }
export type DashboardPageQueryHookResult = ReturnType<typeof useDashboardPageQuery>;
export type DashboardPageLazyQueryHookResult = ReturnType<typeof useDashboardPageLazyQuery>;
export type DashboardPageQueryResult = Apollo.QueryResult<DashboardPageQuery, DashboardPageQueryVariables>;
export const DataSetPageDocument = gql`
    query DataSetPage($dataSetId: ID!) {
  dataSet(id: $dataSetId) {
    candidateData {
      id
      instance {
        id
        workspaceId
      }
    }
    configurationInstance {
      id
      createdOn
      status
      steps(input: {}) {
        items {
          createdOn
          displayName
          id
          instance {
            id
          }
          statusMessages {
            items {
              content
              createdUtc
              level
            }
          }
        }
      }
      templateMetadata {
        __typename
        id
        ... on DataSetTemplate {
          id
          defaultSchedulePattern
        }
        packageMetadata {
          id
          version
        }
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    createdOn
    currentSchedule {
      id
      maxRecurrenceCount
      pattern
      status
      timezoneName
    }
    data {
      id
    }
    description
    displayName
    id
    status
    templateMetadata {
      id
      displayName
      packageMetadata {
        id
        version
      }
    }
  }
}
    `;

/**
 * __useDataSetPageQuery__
 *
 * To run a query within a React component, call `useDataSetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSetPageQuery({
 *   variables: {
 *      dataSetId: // value for 'dataSetId'
 *   },
 * });
 */
export function useDataSetPageQuery(baseOptions: Apollo.QueryHookOptions<DataSetPageQuery, DataSetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSetPageQuery, DataSetPageQueryVariables>(DataSetPageDocument, options);
      }
export function useDataSetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSetPageQuery, DataSetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSetPageQuery, DataSetPageQueryVariables>(DataSetPageDocument, options);
        }
export type DataSetPageQueryHookResult = ReturnType<typeof useDataSetPageQuery>;
export type DataSetPageLazyQueryHookResult = ReturnType<typeof useDataSetPageLazyQuery>;
export type DataSetPageQueryResult = Apollo.QueryResult<DataSetPageQuery, DataSetPageQueryVariables>;
export const DataSetRefreshRunsDocument = gql`
    query DataSetRefreshRuns($dataSetId: ID!, $input: InstanceRetrieveInput!, $first: Int, $after: String) {
  dataSet(id: $dataSetId) {
    id
    refreshRuns(after: $after, first: $first, input: $input) {
      edges {
        cursor
        node {
          completedOn
          id
          startedOn
          status
          updatedOn
          workspaceId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    `;

/**
 * __useDataSetRefreshRunsQuery__
 *
 * To run a query within a React component, call `useDataSetRefreshRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSetRefreshRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSetRefreshRunsQuery({
 *   variables: {
 *      dataSetId: // value for 'dataSetId'
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDataSetRefreshRunsQuery(baseOptions: Apollo.QueryHookOptions<DataSetRefreshRunsQuery, DataSetRefreshRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSetRefreshRunsQuery, DataSetRefreshRunsQueryVariables>(DataSetRefreshRunsDocument, options);
      }
export function useDataSetRefreshRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSetRefreshRunsQuery, DataSetRefreshRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSetRefreshRunsQuery, DataSetRefreshRunsQueryVariables>(DataSetRefreshRunsDocument, options);
        }
export type DataSetRefreshRunsQueryHookResult = ReturnType<typeof useDataSetRefreshRunsQuery>;
export type DataSetRefreshRunsLazyQueryHookResult = ReturnType<typeof useDataSetRefreshRunsLazyQuery>;
export type DataSetRefreshRunsQueryResult = Apollo.QueryResult<DataSetRefreshRunsQuery, DataSetRefreshRunsQueryVariables>;
export const RenameDataSetDocument = gql`
    mutation RenameDataSet($id: ID!, $workspaceId: ID!, $displayName: String) {
  editDataSet(
    input: {id: $id, workspaceId: $workspaceId, data: {displayName: $displayName}}
  ) {
    id
  }
}
    `;
export type RenameDataSetMutationFn = Apollo.MutationFunction<RenameDataSetMutation, RenameDataSetMutationVariables>;

/**
 * __useRenameDataSetMutation__
 *
 * To run a mutation, you first call `useRenameDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDataSetMutation, { data, loading, error }] = useRenameDataSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useRenameDataSetMutation(baseOptions?: Apollo.MutationHookOptions<RenameDataSetMutation, RenameDataSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameDataSetMutation, RenameDataSetMutationVariables>(RenameDataSetDocument, options);
      }
export type RenameDataSetMutationHookResult = ReturnType<typeof useRenameDataSetMutation>;
export type RenameDataSetMutationResult = Apollo.MutationResult<RenameDataSetMutation>;
export type RenameDataSetMutationOptions = Apollo.BaseMutationOptions<RenameDataSetMutation, RenameDataSetMutationVariables>;
export const ReplaceDataSetScheduleDocument = gql`
    mutation ReplaceDataSetSchedule($input: ReplaceDataSetScheduleInput!) {
  replaceDataSetSchedule(input: $input) {
    id
    currentSchedule {
      id
      maxRecurrenceCount
      pattern
      status
      timezoneName
    }
  }
}
    `;
export type ReplaceDataSetScheduleMutationFn = Apollo.MutationFunction<ReplaceDataSetScheduleMutation, ReplaceDataSetScheduleMutationVariables>;

/**
 * __useReplaceDataSetScheduleMutation__
 *
 * To run a mutation, you first call `useReplaceDataSetScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceDataSetScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceDataSetScheduleMutation, { data, loading, error }] = useReplaceDataSetScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceDataSetScheduleMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceDataSetScheduleMutation, ReplaceDataSetScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceDataSetScheduleMutation, ReplaceDataSetScheduleMutationVariables>(ReplaceDataSetScheduleDocument, options);
      }
export type ReplaceDataSetScheduleMutationHookResult = ReturnType<typeof useReplaceDataSetScheduleMutation>;
export type ReplaceDataSetScheduleMutationResult = Apollo.MutationResult<ReplaceDataSetScheduleMutation>;
export type ReplaceDataSetScheduleMutationOptions = Apollo.BaseMutationOptions<ReplaceDataSetScheduleMutation, ReplaceDataSetScheduleMutationVariables>;
export const ToggleDataSetScheduleActivityDocument = gql`
    mutation ToggleDataSetScheduleActivity($input: ToggleDataSetScheduleActivityInput!) {
  toggleDataSetScheduleActivity(input: $input) {
    id
    maxRecurrenceCount
    pattern
    status
    timezoneName
  }
}
    `;
export type ToggleDataSetScheduleActivityMutationFn = Apollo.MutationFunction<ToggleDataSetScheduleActivityMutation, ToggleDataSetScheduleActivityMutationVariables>;

/**
 * __useToggleDataSetScheduleActivityMutation__
 *
 * To run a mutation, you first call `useToggleDataSetScheduleActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDataSetScheduleActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDataSetScheduleActivityMutation, { data, loading, error }] = useToggleDataSetScheduleActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleDataSetScheduleActivityMutation(baseOptions?: Apollo.MutationHookOptions<ToggleDataSetScheduleActivityMutation, ToggleDataSetScheduleActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleDataSetScheduleActivityMutation, ToggleDataSetScheduleActivityMutationVariables>(ToggleDataSetScheduleActivityDocument, options);
      }
export type ToggleDataSetScheduleActivityMutationHookResult = ReturnType<typeof useToggleDataSetScheduleActivityMutation>;
export type ToggleDataSetScheduleActivityMutationResult = Apollo.MutationResult<ToggleDataSetScheduleActivityMutation>;
export type ToggleDataSetScheduleActivityMutationOptions = Apollo.BaseMutationOptions<ToggleDataSetScheduleActivityMutation, ToggleDataSetScheduleActivityMutationVariables>;
export const ResetDataSetConfigurationDataDocument = gql`
    mutation ResetDataSetConfigurationData($workspaceId: ID!, $dataSetId: ID!, $resetById: ID!) {
  resetDataSetConfigurationData(
    input: {workspaceId: $workspaceId, dataSetId: $dataSetId, resetById: $resetById}
  ) {
    id
  }
}
    `;
export type ResetDataSetConfigurationDataMutationFn = Apollo.MutationFunction<ResetDataSetConfigurationDataMutation, ResetDataSetConfigurationDataMutationVariables>;

/**
 * __useResetDataSetConfigurationDataMutation__
 *
 * To run a mutation, you first call `useResetDataSetConfigurationDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDataSetConfigurationDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDataSetConfigurationDataMutation, { data, loading, error }] = useResetDataSetConfigurationDataMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      dataSetId: // value for 'dataSetId'
 *      resetById: // value for 'resetById'
 *   },
 * });
 */
export function useResetDataSetConfigurationDataMutation(baseOptions?: Apollo.MutationHookOptions<ResetDataSetConfigurationDataMutation, ResetDataSetConfigurationDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetDataSetConfigurationDataMutation, ResetDataSetConfigurationDataMutationVariables>(ResetDataSetConfigurationDataDocument, options);
      }
export type ResetDataSetConfigurationDataMutationHookResult = ReturnType<typeof useResetDataSetConfigurationDataMutation>;
export type ResetDataSetConfigurationDataMutationResult = Apollo.MutationResult<ResetDataSetConfigurationDataMutation>;
export type ResetDataSetConfigurationDataMutationOptions = Apollo.BaseMutationOptions<ResetDataSetConfigurationDataMutation, ResetDataSetConfigurationDataMutationVariables>;
export const CancelCandidateDataSetConfigurationDataDocument = gql`
    mutation CancelCandidateDataSetConfigurationData($workspaceId: ID!, $dataSetId: ID!, $candidateConfigurationDataId: ID!) {
  cancelCandidateDataSetConfigurationData(
    input: {workspaceId: $workspaceId, dataSetId: $dataSetId, candidateConfigurationDataId: $candidateConfigurationDataId}
  ) {
    id
  }
}
    `;
export type CancelCandidateDataSetConfigurationDataMutationFn = Apollo.MutationFunction<CancelCandidateDataSetConfigurationDataMutation, CancelCandidateDataSetConfigurationDataMutationVariables>;

/**
 * __useCancelCandidateDataSetConfigurationDataMutation__
 *
 * To run a mutation, you first call `useCancelCandidateDataSetConfigurationDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCandidateDataSetConfigurationDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCandidateDataSetConfigurationDataMutation, { data, loading, error }] = useCancelCandidateDataSetConfigurationDataMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      dataSetId: // value for 'dataSetId'
 *      candidateConfigurationDataId: // value for 'candidateConfigurationDataId'
 *   },
 * });
 */
export function useCancelCandidateDataSetConfigurationDataMutation(baseOptions?: Apollo.MutationHookOptions<CancelCandidateDataSetConfigurationDataMutation, CancelCandidateDataSetConfigurationDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCandidateDataSetConfigurationDataMutation, CancelCandidateDataSetConfigurationDataMutationVariables>(CancelCandidateDataSetConfigurationDataDocument, options);
      }
export type CancelCandidateDataSetConfigurationDataMutationHookResult = ReturnType<typeof useCancelCandidateDataSetConfigurationDataMutation>;
export type CancelCandidateDataSetConfigurationDataMutationResult = Apollo.MutationResult<CancelCandidateDataSetConfigurationDataMutation>;
export type CancelCandidateDataSetConfigurationDataMutationOptions = Apollo.BaseMutationOptions<CancelCandidateDataSetConfigurationDataMutation, CancelCandidateDataSetConfigurationDataMutationVariables>;
export const RefreshDataSetDocument = gql`
    mutation RefreshDataSet($workspaceId: ID!, $dataSetId: ID!) {
  executeDataSet(input: {workspaceId: $workspaceId, dataSetId: $dataSetId}) {
    dataSet {
      id
    }
  }
}
    `;
export type RefreshDataSetMutationFn = Apollo.MutationFunction<RefreshDataSetMutation, RefreshDataSetMutationVariables>;

/**
 * __useRefreshDataSetMutation__
 *
 * To run a mutation, you first call `useRefreshDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshDataSetMutation, { data, loading, error }] = useRefreshDataSetMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      dataSetId: // value for 'dataSetId'
 *   },
 * });
 */
export function useRefreshDataSetMutation(baseOptions?: Apollo.MutationHookOptions<RefreshDataSetMutation, RefreshDataSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshDataSetMutation, RefreshDataSetMutationVariables>(RefreshDataSetDocument, options);
      }
export type RefreshDataSetMutationHookResult = ReturnType<typeof useRefreshDataSetMutation>;
export type RefreshDataSetMutationResult = Apollo.MutationResult<RefreshDataSetMutation>;
export type RefreshDataSetMutationOptions = Apollo.BaseMutationOptions<RefreshDataSetMutation, RefreshDataSetMutationVariables>;
export const IntegrationsPageAvailableTabDocument = gql`
    query IntegrationsPageAvailableTab($input: IntegrationMetadataRetrieveInput!) {
  integrationMetadatas(after: null, first: null, input: $input) {
    items {
      id
      description
      displayName
      name
      packageMetadata {
        id
        name
        publishedOn
        publisher {
          id
          displayName
          name
        }
        version
      }
      type
    }
  }
}
    `;

/**
 * __useIntegrationsPageAvailableTabQuery__
 *
 * To run a query within a React component, call `useIntegrationsPageAvailableTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsPageAvailableTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsPageAvailableTabQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntegrationsPageAvailableTabQuery(baseOptions: Apollo.QueryHookOptions<IntegrationsPageAvailableTabQuery, IntegrationsPageAvailableTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsPageAvailableTabQuery, IntegrationsPageAvailableTabQueryVariables>(IntegrationsPageAvailableTabDocument, options);
      }
export function useIntegrationsPageAvailableTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsPageAvailableTabQuery, IntegrationsPageAvailableTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsPageAvailableTabQuery, IntegrationsPageAvailableTabQueryVariables>(IntegrationsPageAvailableTabDocument, options);
        }
export type IntegrationsPageAvailableTabQueryHookResult = ReturnType<typeof useIntegrationsPageAvailableTabQuery>;
export type IntegrationsPageAvailableTabLazyQueryHookResult = ReturnType<typeof useIntegrationsPageAvailableTabLazyQuery>;
export type IntegrationsPageAvailableTabQueryResult = Apollo.QueryResult<IntegrationsPageAvailableTabQuery, IntegrationsPageAvailableTabQueryVariables>;
export const IntegrationsPageConfiguredTabDocument = gql`
    query IntegrationsPageConfiguredTab($input: IntegrationsRetrieveInput!) {
  integrations(input: $input) {
    items {
      ...IntegrationCardIntegration
    }
  }
}
    ${IntegrationCardIntegrationFragmentDoc}`;

/**
 * __useIntegrationsPageConfiguredTabQuery__
 *
 * To run a query within a React component, call `useIntegrationsPageConfiguredTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsPageConfiguredTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsPageConfiguredTabQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntegrationsPageConfiguredTabQuery(baseOptions: Apollo.QueryHookOptions<IntegrationsPageConfiguredTabQuery, IntegrationsPageConfiguredTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsPageConfiguredTabQuery, IntegrationsPageConfiguredTabQueryVariables>(IntegrationsPageConfiguredTabDocument, options);
      }
export function useIntegrationsPageConfiguredTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsPageConfiguredTabQuery, IntegrationsPageConfiguredTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsPageConfiguredTabQuery, IntegrationsPageConfiguredTabQueryVariables>(IntegrationsPageConfiguredTabDocument, options);
        }
export type IntegrationsPageConfiguredTabQueryHookResult = ReturnType<typeof useIntegrationsPageConfiguredTabQuery>;
export type IntegrationsPageConfiguredTabLazyQueryHookResult = ReturnType<typeof useIntegrationsPageConfiguredTabLazyQuery>;
export type IntegrationsPageConfiguredTabQueryResult = Apollo.QueryResult<IntegrationsPageConfiguredTabQuery, IntegrationsPageConfiguredTabQueryVariables>;
export const IntegrationCardDocument = gql`
    query IntegrationCard($integrationId: ID!) {
  integration(id: $integrationId) {
    ...IntegrationCardIntegration
  }
}
    ${IntegrationCardIntegrationFragmentDoc}`;

/**
 * __useIntegrationCardQuery__
 *
 * To run a query within a React component, call `useIntegrationCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationCardQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useIntegrationCardQuery(baseOptions: Apollo.QueryHookOptions<IntegrationCardQuery, IntegrationCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationCardQuery, IntegrationCardQueryVariables>(IntegrationCardDocument, options);
      }
export function useIntegrationCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationCardQuery, IntegrationCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationCardQuery, IntegrationCardQueryVariables>(IntegrationCardDocument, options);
        }
export type IntegrationCardQueryHookResult = ReturnType<typeof useIntegrationCardQuery>;
export type IntegrationCardLazyQueryHookResult = ReturnType<typeof useIntegrationCardLazyQuery>;
export type IntegrationCardQueryResult = Apollo.QueryResult<IntegrationCardQuery, IntegrationCardQueryVariables>;
export const LibraryAutomationPageDocument = gql`
    query LibraryAutomationPage($input: AutomationMetadatasRetrieveInput!) {
  automationMetadatas(input: $input) {
    items {
      ...LibraryAutomationPageItem
    }
  }
}
    ${LibraryAutomationPageItemFragmentDoc}`;

/**
 * __useLibraryAutomationPageQuery__
 *
 * To run a query within a React component, call `useLibraryAutomationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryAutomationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryAutomationPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryAutomationPageQuery(baseOptions: Apollo.QueryHookOptions<LibraryAutomationPageQuery, LibraryAutomationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryAutomationPageQuery, LibraryAutomationPageQueryVariables>(LibraryAutomationPageDocument, options);
      }
export function useLibraryAutomationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryAutomationPageQuery, LibraryAutomationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryAutomationPageQuery, LibraryAutomationPageQueryVariables>(LibraryAutomationPageDocument, options);
        }
export type LibraryAutomationPageQueryHookResult = ReturnType<typeof useLibraryAutomationPageQuery>;
export type LibraryAutomationPageLazyQueryHookResult = ReturnType<typeof useLibraryAutomationPageLazyQuery>;
export type LibraryAutomationPageQueryResult = Apollo.QueryResult<LibraryAutomationPageQuery, LibraryAutomationPageQueryVariables>;
export const LibraryAutomationPageLaunchDocument = gql`
    mutation LibraryAutomationPageLaunch($dataSetId: ID, $launchedById: ID!, $templateConfigurationId: ID, $templateMetadataId: ID!, $workspaceId: ID!) {
  executeTemplateConfiguration(
    input: {dataSetId: $dataSetId, launchedById: $launchedById, templateConfigurationId: $templateConfigurationId, templateMetadataId: $templateMetadataId, workspaceId: $workspaceId}
  ) {
    id
  }
}
    `;
export type LibraryAutomationPageLaunchMutationFn = Apollo.MutationFunction<LibraryAutomationPageLaunchMutation, LibraryAutomationPageLaunchMutationVariables>;

/**
 * __useLibraryAutomationPageLaunchMutation__
 *
 * To run a mutation, you first call `useLibraryAutomationPageLaunchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLibraryAutomationPageLaunchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [libraryAutomationPageLaunchMutation, { data, loading, error }] = useLibraryAutomationPageLaunchMutation({
 *   variables: {
 *      dataSetId: // value for 'dataSetId'
 *      launchedById: // value for 'launchedById'
 *      templateConfigurationId: // value for 'templateConfigurationId'
 *      templateMetadataId: // value for 'templateMetadataId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useLibraryAutomationPageLaunchMutation(baseOptions?: Apollo.MutationHookOptions<LibraryAutomationPageLaunchMutation, LibraryAutomationPageLaunchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LibraryAutomationPageLaunchMutation, LibraryAutomationPageLaunchMutationVariables>(LibraryAutomationPageLaunchDocument, options);
      }
export type LibraryAutomationPageLaunchMutationHookResult = ReturnType<typeof useLibraryAutomationPageLaunchMutation>;
export type LibraryAutomationPageLaunchMutationResult = Apollo.MutationResult<LibraryAutomationPageLaunchMutation>;
export type LibraryAutomationPageLaunchMutationOptions = Apollo.BaseMutationOptions<LibraryAutomationPageLaunchMutation, LibraryAutomationPageLaunchMutationVariables>;
export const LibraryDashboardPageDocument = gql`
    query LibraryDashboardPage($input: DashboardMetadatasRetrieveInput!) {
  dashboardMetadatas(input: $input) {
    items {
      ...LibraryDashboardPageItem
    }
  }
}
    ${LibraryDashboardPageItemFragmentDoc}`;

/**
 * __useLibraryDashboardPageQuery__
 *
 * To run a query within a React component, call `useLibraryDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryDashboardPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryDashboardPageQuery(baseOptions: Apollo.QueryHookOptions<LibraryDashboardPageQuery, LibraryDashboardPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryDashboardPageQuery, LibraryDashboardPageQueryVariables>(LibraryDashboardPageDocument, options);
      }
export function useLibraryDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryDashboardPageQuery, LibraryDashboardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryDashboardPageQuery, LibraryDashboardPageQueryVariables>(LibraryDashboardPageDocument, options);
        }
export type LibraryDashboardPageQueryHookResult = ReturnType<typeof useLibraryDashboardPageQuery>;
export type LibraryDashboardPageLazyQueryHookResult = ReturnType<typeof useLibraryDashboardPageLazyQuery>;
export type LibraryDashboardPageQueryResult = Apollo.QueryResult<LibraryDashboardPageQuery, LibraryDashboardPageQueryVariables>;
export const LibraryDashboardPageCreateDocument = gql`
    mutation LibraryDashboardPageCreate($input: CreateDashboardInput!) {
  createDashboard(input: $input) {
    id
    dashboardMetadataId
    workspaceId
  }
}
    `;
export type LibraryDashboardPageCreateMutationFn = Apollo.MutationFunction<LibraryDashboardPageCreateMutation, LibraryDashboardPageCreateMutationVariables>;

/**
 * __useLibraryDashboardPageCreateMutation__
 *
 * To run a mutation, you first call `useLibraryDashboardPageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLibraryDashboardPageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [libraryDashboardPageCreateMutation, { data, loading, error }] = useLibraryDashboardPageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryDashboardPageCreateMutation(baseOptions?: Apollo.MutationHookOptions<LibraryDashboardPageCreateMutation, LibraryDashboardPageCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LibraryDashboardPageCreateMutation, LibraryDashboardPageCreateMutationVariables>(LibraryDashboardPageCreateDocument, options);
      }
export type LibraryDashboardPageCreateMutationHookResult = ReturnType<typeof useLibraryDashboardPageCreateMutation>;
export type LibraryDashboardPageCreateMutationResult = Apollo.MutationResult<LibraryDashboardPageCreateMutation>;
export type LibraryDashboardPageCreateMutationOptions = Apollo.BaseMutationOptions<LibraryDashboardPageCreateMutation, LibraryDashboardPageCreateMutationVariables>;
export const LibraryDataSetPageDocument = gql`
    query LibraryDataSetPage($input: DataSetMetadatasRetrieveInput!) {
  dataSetMetadatas(input: $input) {
    items {
      ...LibraryDataSetPageItem
    }
  }
}
    ${LibraryDataSetPageItemFragmentDoc}`;

/**
 * __useLibraryDataSetPageQuery__
 *
 * To run a query within a React component, call `useLibraryDataSetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryDataSetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryDataSetPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryDataSetPageQuery(baseOptions: Apollo.QueryHookOptions<LibraryDataSetPageQuery, LibraryDataSetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryDataSetPageQuery, LibraryDataSetPageQueryVariables>(LibraryDataSetPageDocument, options);
      }
export function useLibraryDataSetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryDataSetPageQuery, LibraryDataSetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryDataSetPageQuery, LibraryDataSetPageQueryVariables>(LibraryDataSetPageDocument, options);
        }
export type LibraryDataSetPageQueryHookResult = ReturnType<typeof useLibraryDataSetPageQuery>;
export type LibraryDataSetPageLazyQueryHookResult = ReturnType<typeof useLibraryDataSetPageLazyQuery>;
export type LibraryDataSetPageQueryResult = Apollo.QueryResult<LibraryDataSetPageQuery, LibraryDataSetPageQueryVariables>;
export const LibraryDataSetPageCreateDataSetDocument = gql`
    mutation LibraryDataSetPageCreateDataSet($input: CreateDataSetInput!) {
  createDataSet(input: $input) {
    id
  }
}
    `;
export type LibraryDataSetPageCreateDataSetMutationFn = Apollo.MutationFunction<LibraryDataSetPageCreateDataSetMutation, LibraryDataSetPageCreateDataSetMutationVariables>;

/**
 * __useLibraryDataSetPageCreateDataSetMutation__
 *
 * To run a mutation, you first call `useLibraryDataSetPageCreateDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLibraryDataSetPageCreateDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [libraryDataSetPageCreateDataSetMutation, { data, loading, error }] = useLibraryDataSetPageCreateDataSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryDataSetPageCreateDataSetMutation(baseOptions?: Apollo.MutationHookOptions<LibraryDataSetPageCreateDataSetMutation, LibraryDataSetPageCreateDataSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LibraryDataSetPageCreateDataSetMutation, LibraryDataSetPageCreateDataSetMutationVariables>(LibraryDataSetPageCreateDataSetDocument, options);
      }
export type LibraryDataSetPageCreateDataSetMutationHookResult = ReturnType<typeof useLibraryDataSetPageCreateDataSetMutation>;
export type LibraryDataSetPageCreateDataSetMutationResult = Apollo.MutationResult<LibraryDataSetPageCreateDataSetMutation>;
export type LibraryDataSetPageCreateDataSetMutationOptions = Apollo.BaseMutationOptions<LibraryDataSetPageCreateDataSetMutation, LibraryDataSetPageCreateDataSetMutationVariables>;
export const LibraryItemByIdPageDocument = gql`
    query LibraryItemByIdPage($id: ID!) {
  libraryItems(input: {ids: [$id]}) {
    items {
      __typename
      id
      libraryItemType
      name
      packageMetadata {
        __typename
        id
        name
        publisher {
          id
          name
        }
        version
      }
    }
  }
}
    `;

/**
 * __useLibraryItemByIdPageQuery__
 *
 * To run a query within a React component, call `useLibraryItemByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryItemByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryItemByIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLibraryItemByIdPageQuery(baseOptions: Apollo.QueryHookOptions<LibraryItemByIdPageQuery, LibraryItemByIdPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryItemByIdPageQuery, LibraryItemByIdPageQueryVariables>(LibraryItemByIdPageDocument, options);
      }
export function useLibraryItemByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryItemByIdPageQuery, LibraryItemByIdPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryItemByIdPageQuery, LibraryItemByIdPageQueryVariables>(LibraryItemByIdPageDocument, options);
        }
export type LibraryItemByIdPageQueryHookResult = ReturnType<typeof useLibraryItemByIdPageQuery>;
export type LibraryItemByIdPageLazyQueryHookResult = ReturnType<typeof useLibraryItemByIdPageLazyQuery>;
export type LibraryItemByIdPageQueryResult = Apollo.QueryResult<LibraryItemByIdPageQuery, LibraryItemByIdPageQueryVariables>;
export const LibraryItemRedirectPageDocument = gql`
    query LibraryItemRedirectPage($input: LibraryItemsRetrieveInput!) {
  libraryItems(input: $input) {
    items {
      id
      libraryItemType
      name
      packageMetadata {
        id
        name
        publisher {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useLibraryItemRedirectPageQuery__
 *
 * To run a query within a React component, call `useLibraryItemRedirectPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryItemRedirectPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryItemRedirectPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryItemRedirectPageQuery(baseOptions: Apollo.QueryHookOptions<LibraryItemRedirectPageQuery, LibraryItemRedirectPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryItemRedirectPageQuery, LibraryItemRedirectPageQueryVariables>(LibraryItemRedirectPageDocument, options);
      }
export function useLibraryItemRedirectPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryItemRedirectPageQuery, LibraryItemRedirectPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryItemRedirectPageQuery, LibraryItemRedirectPageQueryVariables>(LibraryItemRedirectPageDocument, options);
        }
export type LibraryItemRedirectPageQueryHookResult = ReturnType<typeof useLibraryItemRedirectPageQuery>;
export type LibraryItemRedirectPageLazyQueryHookResult = ReturnType<typeof useLibraryItemRedirectPageLazyQuery>;
export type LibraryItemRedirectPageQueryResult = Apollo.QueryResult<LibraryItemRedirectPageQuery, LibraryItemRedirectPageQueryVariables>;
export const DataSetSelectorDocument = gql`
    query DataSetSelector($dataSchemaMetadataId: ID!, $workspaceId: ID!) {
  dataSets(
    input: {dataSchemaMetadataIds: [$dataSchemaMetadataId], statuses: [READY], workspaceId: $workspaceId}
  ) {
    items {
      id
      displayName
    }
  }
}
    `;

/**
 * __useDataSetSelectorQuery__
 *
 * To run a query within a React component, call `useDataSetSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSetSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSetSelectorQuery({
 *   variables: {
 *      dataSchemaMetadataId: // value for 'dataSchemaMetadataId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDataSetSelectorQuery(baseOptions: Apollo.QueryHookOptions<DataSetSelectorQuery, DataSetSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataSetSelectorQuery, DataSetSelectorQueryVariables>(DataSetSelectorDocument, options);
      }
export function useDataSetSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSetSelectorQuery, DataSetSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataSetSelectorQuery, DataSetSelectorQueryVariables>(DataSetSelectorDocument, options);
        }
export type DataSetSelectorQueryHookResult = ReturnType<typeof useDataSetSelectorQuery>;
export type DataSetSelectorLazyQueryHookResult = ReturnType<typeof useDataSetSelectorLazyQuery>;
export type DataSetSelectorQueryResult = Apollo.QueryResult<DataSetSelectorQuery, DataSetSelectorQueryVariables>;
export const LibraryPageDocument = gql`
    query LibraryPage($first: Int, $after: String, $input: LibraryItemsRetrieveInput!) {
  libraryFilteringMetadata {
    availableSolutions
    availableTechnologies
  }
  libraryItems(first: $first, after: $after, input: $input) {
    edges {
      cursor
      node {
        ...LibraryPageLibraryItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${LibraryPageLibraryItemFragmentDoc}`;

/**
 * __useLibraryPageQuery__
 *
 * To run a query within a React component, call `useLibraryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryPageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibraryPageQuery(baseOptions: Apollo.QueryHookOptions<LibraryPageQuery, LibraryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryPageQuery, LibraryPageQueryVariables>(LibraryPageDocument, options);
      }
export function useLibraryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryPageQuery, LibraryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryPageQuery, LibraryPageQueryVariables>(LibraryPageDocument, options);
        }
export type LibraryPageQueryHookResult = ReturnType<typeof useLibraryPageQuery>;
export type LibraryPageLazyQueryHookResult = ReturnType<typeof useLibraryPageLazyQuery>;
export type LibraryPageQueryResult = Apollo.QueryResult<LibraryPageQuery, LibraryPageQueryVariables>;
export const NotificationsHistoryTabDocument = gql`
    query NotificationsHistoryTab($first: Int, $after: String) {
  notificationRecipients(
    first: $first
    after: $after
    input: {channelTypes: null, ids: null, statuses: null, types: null}
  ) {
    edges {
      cursor
      node {
        ...NotificationsHistoryTabNotificationRecipient
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${NotificationsHistoryTabNotificationRecipientFragmentDoc}`;

/**
 * __useNotificationsHistoryTabQuery__
 *
 * To run a query within a React component, call `useNotificationsHistoryTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsHistoryTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsHistoryTabQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotificationsHistoryTabQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsHistoryTabQuery, NotificationsHistoryTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsHistoryTabQuery, NotificationsHistoryTabQueryVariables>(NotificationsHistoryTabDocument, options);
      }
export function useNotificationsHistoryTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsHistoryTabQuery, NotificationsHistoryTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsHistoryTabQuery, NotificationsHistoryTabQueryVariables>(NotificationsHistoryTabDocument, options);
        }
export type NotificationsHistoryTabQueryHookResult = ReturnType<typeof useNotificationsHistoryTabQuery>;
export type NotificationsHistoryTabLazyQueryHookResult = ReturnType<typeof useNotificationsHistoryTabLazyQuery>;
export type NotificationsHistoryTabQueryResult = Apollo.QueryResult<NotificationsHistoryTabQuery, NotificationsHistoryTabQueryVariables>;
export const NotificationsHistoryTabToggleNotificationStatusDocument = gql`
    mutation NotificationsHistoryTabToggleNotificationStatus($input: NotificationRecipientPatchRequest!) {
  editNotificationRecipient(input: $input) {
    notificationRecipient {
      ...NotificationsHistoryTabNotificationRecipient
    }
  }
}
    ${NotificationsHistoryTabNotificationRecipientFragmentDoc}`;
export type NotificationsHistoryTabToggleNotificationStatusMutationFn = Apollo.MutationFunction<NotificationsHistoryTabToggleNotificationStatusMutation, NotificationsHistoryTabToggleNotificationStatusMutationVariables>;

/**
 * __useNotificationsHistoryTabToggleNotificationStatusMutation__
 *
 * To run a mutation, you first call `useNotificationsHistoryTabToggleNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsHistoryTabToggleNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsHistoryTabToggleNotificationStatusMutation, { data, loading, error }] = useNotificationsHistoryTabToggleNotificationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsHistoryTabToggleNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsHistoryTabToggleNotificationStatusMutation, NotificationsHistoryTabToggleNotificationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsHistoryTabToggleNotificationStatusMutation, NotificationsHistoryTabToggleNotificationStatusMutationVariables>(NotificationsHistoryTabToggleNotificationStatusDocument, options);
      }
export type NotificationsHistoryTabToggleNotificationStatusMutationHookResult = ReturnType<typeof useNotificationsHistoryTabToggleNotificationStatusMutation>;
export type NotificationsHistoryTabToggleNotificationStatusMutationResult = Apollo.MutationResult<NotificationsHistoryTabToggleNotificationStatusMutation>;
export type NotificationsHistoryTabToggleNotificationStatusMutationOptions = Apollo.BaseMutationOptions<NotificationsHistoryTabToggleNotificationStatusMutation, NotificationsHistoryTabToggleNotificationStatusMutationVariables>;
export const ActivitySummarySectionToggleRunSummaryViaEmailDocument = gql`
    mutation ActivitySummarySectionToggleRunSummaryViaEmail($input: EditTenantMemberInput!) {
  editTenantMember(input: $input) {
    tenantMember {
      ...CurrentTenantMember
    }
  }
}
    ${CurrentTenantMemberFragmentDoc}`;
export type ActivitySummarySectionToggleRunSummaryViaEmailMutationFn = Apollo.MutationFunction<ActivitySummarySectionToggleRunSummaryViaEmailMutation, ActivitySummarySectionToggleRunSummaryViaEmailMutationVariables>;

/**
 * __useActivitySummarySectionToggleRunSummaryViaEmailMutation__
 *
 * To run a mutation, you first call `useActivitySummarySectionToggleRunSummaryViaEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivitySummarySectionToggleRunSummaryViaEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activitySummarySectionToggleRunSummaryViaEmailMutation, { data, loading, error }] = useActivitySummarySectionToggleRunSummaryViaEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivitySummarySectionToggleRunSummaryViaEmailMutation(baseOptions?: Apollo.MutationHookOptions<ActivitySummarySectionToggleRunSummaryViaEmailMutation, ActivitySummarySectionToggleRunSummaryViaEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivitySummarySectionToggleRunSummaryViaEmailMutation, ActivitySummarySectionToggleRunSummaryViaEmailMutationVariables>(ActivitySummarySectionToggleRunSummaryViaEmailDocument, options);
      }
export type ActivitySummarySectionToggleRunSummaryViaEmailMutationHookResult = ReturnType<typeof useActivitySummarySectionToggleRunSummaryViaEmailMutation>;
export type ActivitySummarySectionToggleRunSummaryViaEmailMutationResult = Apollo.MutationResult<ActivitySummarySectionToggleRunSummaryViaEmailMutation>;
export type ActivitySummarySectionToggleRunSummaryViaEmailMutationOptions = Apollo.BaseMutationOptions<ActivitySummarySectionToggleRunSummaryViaEmailMutation, ActivitySummarySectionToggleRunSummaryViaEmailMutationVariables>;
export const AddNotificationSubscriptionModalCreateSubscriptionDocument = gql`
    mutation AddNotificationSubscriptionModalCreateSubscription($input: CreateRunNotificationSubscriptionInput!) {
  createRunNotificationSubscription(input: $input) {
    notificationSubscription {
      id
    }
  }
}
    `;
export type AddNotificationSubscriptionModalCreateSubscriptionMutationFn = Apollo.MutationFunction<AddNotificationSubscriptionModalCreateSubscriptionMutation, AddNotificationSubscriptionModalCreateSubscriptionMutationVariables>;

/**
 * __useAddNotificationSubscriptionModalCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddNotificationSubscriptionModalCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationSubscriptionModalCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationSubscriptionModalCreateSubscriptionMutation, { data, loading, error }] = useAddNotificationSubscriptionModalCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNotificationSubscriptionModalCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationSubscriptionModalCreateSubscriptionMutation, AddNotificationSubscriptionModalCreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNotificationSubscriptionModalCreateSubscriptionMutation, AddNotificationSubscriptionModalCreateSubscriptionMutationVariables>(AddNotificationSubscriptionModalCreateSubscriptionDocument, options);
      }
export type AddNotificationSubscriptionModalCreateSubscriptionMutationHookResult = ReturnType<typeof useAddNotificationSubscriptionModalCreateSubscriptionMutation>;
export type AddNotificationSubscriptionModalCreateSubscriptionMutationResult = Apollo.MutationResult<AddNotificationSubscriptionModalCreateSubscriptionMutation>;
export type AddNotificationSubscriptionModalCreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<AddNotificationSubscriptionModalCreateSubscriptionMutation, AddNotificationSubscriptionModalCreateSubscriptionMutationVariables>;
export const NotificationSubscriptionModalDocument = gql`
    query NotificationSubscriptionModal($id: ID!) {
  notificationSubscription(id: $id) {
    __typename
    id
    channelTypes
    status
    ... on RunNotificationSubscription {
      runNotificationType
      templateConfiguration {
        id
        displayName
      }
      workspace {
        id
        displayName
      }
    }
  }
}
    `;

/**
 * __useNotificationSubscriptionModalQuery__
 *
 * To run a query within a React component, call `useNotificationSubscriptionModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSubscriptionModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationSubscriptionModalQuery(baseOptions: Apollo.QueryHookOptions<NotificationSubscriptionModalQuery, NotificationSubscriptionModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationSubscriptionModalQuery, NotificationSubscriptionModalQueryVariables>(NotificationSubscriptionModalDocument, options);
      }
export function useNotificationSubscriptionModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationSubscriptionModalQuery, NotificationSubscriptionModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationSubscriptionModalQuery, NotificationSubscriptionModalQueryVariables>(NotificationSubscriptionModalDocument, options);
        }
export type NotificationSubscriptionModalQueryHookResult = ReturnType<typeof useNotificationSubscriptionModalQuery>;
export type NotificationSubscriptionModalLazyQueryHookResult = ReturnType<typeof useNotificationSubscriptionModalLazyQuery>;
export type NotificationSubscriptionModalQueryResult = Apollo.QueryResult<NotificationSubscriptionModalQuery, NotificationSubscriptionModalQueryVariables>;
export const NotificationSubscriptionModalEditSubscriptionDocument = gql`
    mutation NotificationSubscriptionModalEditSubscription($input: RunNotificationSubscriptionPatchRequest!) {
  editRunNotificationSubscription(input: $input) {
    notificationSubscription {
      id
    }
  }
}
    `;
export type NotificationSubscriptionModalEditSubscriptionMutationFn = Apollo.MutationFunction<NotificationSubscriptionModalEditSubscriptionMutation, NotificationSubscriptionModalEditSubscriptionMutationVariables>;

/**
 * __useNotificationSubscriptionModalEditSubscriptionMutation__
 *
 * To run a mutation, you first call `useNotificationSubscriptionModalEditSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionModalEditSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSubscriptionModalEditSubscriptionMutation, { data, loading, error }] = useNotificationSubscriptionModalEditSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationSubscriptionModalEditSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<NotificationSubscriptionModalEditSubscriptionMutation, NotificationSubscriptionModalEditSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationSubscriptionModalEditSubscriptionMutation, NotificationSubscriptionModalEditSubscriptionMutationVariables>(NotificationSubscriptionModalEditSubscriptionDocument, options);
      }
export type NotificationSubscriptionModalEditSubscriptionMutationHookResult = ReturnType<typeof useNotificationSubscriptionModalEditSubscriptionMutation>;
export type NotificationSubscriptionModalEditSubscriptionMutationResult = Apollo.MutationResult<NotificationSubscriptionModalEditSubscriptionMutation>;
export type NotificationSubscriptionModalEditSubscriptionMutationOptions = Apollo.BaseMutationOptions<NotificationSubscriptionModalEditSubscriptionMutation, NotificationSubscriptionModalEditSubscriptionMutationVariables>;
export const NotificationSubscriptionModalDeleteSubscriptionDocument = gql`
    mutation NotificationSubscriptionModalDeleteSubscription($input: DeleteNotificationSubscriptionInput!) {
  deleteNotificationSubscription(input: $input) {
    id
  }
}
    `;
export type NotificationSubscriptionModalDeleteSubscriptionMutationFn = Apollo.MutationFunction<NotificationSubscriptionModalDeleteSubscriptionMutation, NotificationSubscriptionModalDeleteSubscriptionMutationVariables>;

/**
 * __useNotificationSubscriptionModalDeleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useNotificationSubscriptionModalDeleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionModalDeleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSubscriptionModalDeleteSubscriptionMutation, { data, loading, error }] = useNotificationSubscriptionModalDeleteSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationSubscriptionModalDeleteSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<NotificationSubscriptionModalDeleteSubscriptionMutation, NotificationSubscriptionModalDeleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationSubscriptionModalDeleteSubscriptionMutation, NotificationSubscriptionModalDeleteSubscriptionMutationVariables>(NotificationSubscriptionModalDeleteSubscriptionDocument, options);
      }
export type NotificationSubscriptionModalDeleteSubscriptionMutationHookResult = ReturnType<typeof useNotificationSubscriptionModalDeleteSubscriptionMutation>;
export type NotificationSubscriptionModalDeleteSubscriptionMutationResult = Apollo.MutationResult<NotificationSubscriptionModalDeleteSubscriptionMutation>;
export type NotificationSubscriptionModalDeleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<NotificationSubscriptionModalDeleteSubscriptionMutation, NotificationSubscriptionModalDeleteSubscriptionMutationVariables>;
export const NotificationSubscriptionSectionDocument = gql`
    query NotificationSubscriptionSection($input: NotificationSubscriptionsRetrieveInput!) {
  notificationSubscriptions(input: $input) {
    items {
      ...NotificationSubscriptionSectionNotificationSubscription
    }
  }
}
    ${NotificationSubscriptionSectionNotificationSubscriptionFragmentDoc}`;

/**
 * __useNotificationSubscriptionSectionQuery__
 *
 * To run a query within a React component, call `useNotificationSubscriptionSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSubscriptionSectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationSubscriptionSectionQuery(baseOptions: Apollo.QueryHookOptions<NotificationSubscriptionSectionQuery, NotificationSubscriptionSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationSubscriptionSectionQuery, NotificationSubscriptionSectionQueryVariables>(NotificationSubscriptionSectionDocument, options);
      }
export function useNotificationSubscriptionSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationSubscriptionSectionQuery, NotificationSubscriptionSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationSubscriptionSectionQuery, NotificationSubscriptionSectionQueryVariables>(NotificationSubscriptionSectionDocument, options);
        }
export type NotificationSubscriptionSectionQueryHookResult = ReturnType<typeof useNotificationSubscriptionSectionQuery>;
export type NotificationSubscriptionSectionLazyQueryHookResult = ReturnType<typeof useNotificationSubscriptionSectionLazyQuery>;
export type NotificationSubscriptionSectionQueryResult = Apollo.QueryResult<NotificationSubscriptionSectionQuery, NotificationSubscriptionSectionQueryVariables>;
export const NotificationSubscriptionSectionToggleSubscriptionStatusDocument = gql`
    mutation NotificationSubscriptionSectionToggleSubscriptionStatus($input: RunNotificationSubscriptionPatchRequest!) {
  editRunNotificationSubscription(input: $input) {
    notificationSubscription {
      ...NotificationSubscriptionSectionNotificationSubscription
    }
  }
}
    ${NotificationSubscriptionSectionNotificationSubscriptionFragmentDoc}`;
export type NotificationSubscriptionSectionToggleSubscriptionStatusMutationFn = Apollo.MutationFunction<NotificationSubscriptionSectionToggleSubscriptionStatusMutation, NotificationSubscriptionSectionToggleSubscriptionStatusMutationVariables>;

/**
 * __useNotificationSubscriptionSectionToggleSubscriptionStatusMutation__
 *
 * To run a mutation, you first call `useNotificationSubscriptionSectionToggleSubscriptionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionSectionToggleSubscriptionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSubscriptionSectionToggleSubscriptionStatusMutation, { data, loading, error }] = useNotificationSubscriptionSectionToggleSubscriptionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationSubscriptionSectionToggleSubscriptionStatusMutation(baseOptions?: Apollo.MutationHookOptions<NotificationSubscriptionSectionToggleSubscriptionStatusMutation, NotificationSubscriptionSectionToggleSubscriptionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationSubscriptionSectionToggleSubscriptionStatusMutation, NotificationSubscriptionSectionToggleSubscriptionStatusMutationVariables>(NotificationSubscriptionSectionToggleSubscriptionStatusDocument, options);
      }
export type NotificationSubscriptionSectionToggleSubscriptionStatusMutationHookResult = ReturnType<typeof useNotificationSubscriptionSectionToggleSubscriptionStatusMutation>;
export type NotificationSubscriptionSectionToggleSubscriptionStatusMutationResult = Apollo.MutationResult<NotificationSubscriptionSectionToggleSubscriptionStatusMutation>;
export type NotificationSubscriptionSectionToggleSubscriptionStatusMutationOptions = Apollo.BaseMutationOptions<NotificationSubscriptionSectionToggleSubscriptionStatusMutation, NotificationSubscriptionSectionToggleSubscriptionStatusMutationVariables>;
export const NotificationsSettingsTabDocument = gql`
    query NotificationsSettingsTab($input: NotificationSubscriptionsRetrieveInput!) {
  notificationSubscriptions(input: $input) {
    items {
      ...NotificationSubscriptionSectionNotificationSubscription
    }
  }
}
    ${NotificationSubscriptionSectionNotificationSubscriptionFragmentDoc}`;

/**
 * __useNotificationsSettingsTabQuery__
 *
 * To run a query within a React component, call `useNotificationsSettingsTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSettingsTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSettingsTabQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsSettingsTabQuery(baseOptions: Apollo.QueryHookOptions<NotificationsSettingsTabQuery, NotificationsSettingsTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsSettingsTabQuery, NotificationsSettingsTabQueryVariables>(NotificationsSettingsTabDocument, options);
      }
export function useNotificationsSettingsTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsSettingsTabQuery, NotificationsSettingsTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsSettingsTabQuery, NotificationsSettingsTabQueryVariables>(NotificationsSettingsTabDocument, options);
        }
export type NotificationsSettingsTabQueryHookResult = ReturnType<typeof useNotificationsSettingsTabQuery>;
export type NotificationsSettingsTabLazyQueryHookResult = ReturnType<typeof useNotificationsSettingsTabLazyQuery>;
export type NotificationsSettingsTabQueryResult = Apollo.QueryResult<NotificationsSettingsTabQuery, NotificationsSettingsTabQueryVariables>;
export const ScheduledJobPageDocument = gql`
    query ScheduledJobPage($id: ID!) {
  scheduledJob(id: $id) {
    id
    startTime
    endTime
    pattern
    status
    timezoneName
    maxRecurrenceCount
    createdOn
    createdBy {
      __typename
      firstName
      lastName
    }
    recentInstances: instances(first: 5, input: {}) {
      items {
        id
        workspaceId
        status
        displayName
        createdOn
        completedOn
        launchedBy {
          __typename
          ... on DataSet {
            id
          }
          ... on ScheduledJob {
            id
          }
          ... on TenantMember {
            id
            firstName
            lastName
            isActive
          }
        }
      }
    }
    templateConfiguration {
      id
      displayName
      templateMetadata {
        id
        name
        displayName
      }
    }
  }
}
    `;

/**
 * __useScheduledJobPageQuery__
 *
 * To run a query within a React component, call `useScheduledJobPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledJobPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledJobPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduledJobPageQuery(baseOptions: Apollo.QueryHookOptions<ScheduledJobPageQuery, ScheduledJobPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledJobPageQuery, ScheduledJobPageQueryVariables>(ScheduledJobPageDocument, options);
      }
export function useScheduledJobPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledJobPageQuery, ScheduledJobPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledJobPageQuery, ScheduledJobPageQueryVariables>(ScheduledJobPageDocument, options);
        }
export type ScheduledJobPageQueryHookResult = ReturnType<typeof useScheduledJobPageQuery>;
export type ScheduledJobPageLazyQueryHookResult = ReturnType<typeof useScheduledJobPageLazyQuery>;
export type ScheduledJobPageQueryResult = Apollo.QueryResult<ScheduledJobPageQuery, ScheduledJobPageQueryVariables>;
export const TemplateConfigurationPageDocument = gql`
    query TemplateConfigurationPage($id: ID!) {
  templateConfiguration(id: $id) {
    configurationInstance {
      createdOn
      displayName
      id
      launchedBy {
        __typename
        ... on DataSet {
          id
        }
        ... on ScheduledJob {
          id
        }
        ... on TenantMember {
          firstName
          id
          lastName
        }
      }
    }
    createdBy {
      __typename
      firstName
      lastName
    }
    createdOn
    candidateData {
      id
      instance {
        id
        workspaceId
      }
    }
    data {
      id
    }
    dataSet {
      id
      displayName
      workspaceId
    }
    displayName
    exposureLevel
    id
    recentInstances: instances(first: 5, input: {}) {
      items {
        id
        workspaceId
        status
        displayName
        createdOn
        completedOn
        launchedBy {
          __typename
          ... on DataSet {
            id
          }
          ... on ScheduledJob {
            id
          }
          ... on TenantMember {
            id
            firstName
            lastName
            isActive
          }
        }
      }
    }
    status
    templateMetadata {
      __typename
      id
      name
      displayName
      packageMetadata {
        id
        version
      }
    }
  }
}
    `;

/**
 * __useTemplateConfigurationPageQuery__
 *
 * To run a query within a React component, call `useTemplateConfigurationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateConfigurationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateConfigurationPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateConfigurationPageQuery(baseOptions: Apollo.QueryHookOptions<TemplateConfigurationPageQuery, TemplateConfigurationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateConfigurationPageQuery, TemplateConfigurationPageQueryVariables>(TemplateConfigurationPageDocument, options);
      }
export function useTemplateConfigurationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateConfigurationPageQuery, TemplateConfigurationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateConfigurationPageQuery, TemplateConfigurationPageQueryVariables>(TemplateConfigurationPageDocument, options);
        }
export type TemplateConfigurationPageQueryHookResult = ReturnType<typeof useTemplateConfigurationPageQuery>;
export type TemplateConfigurationPageLazyQueryHookResult = ReturnType<typeof useTemplateConfigurationPageLazyQuery>;
export type TemplateConfigurationPageQueryResult = Apollo.QueryResult<TemplateConfigurationPageQuery, TemplateConfigurationPageQueryVariables>;
export const RenameTemplateConfigurationDocument = gql`
    mutation RenameTemplateConfiguration($id: ID!, $workspaceId: ID!, $displayName: String) {
  editTemplateConfiguration(
    input: {id: $id, workspaceId: $workspaceId, data: {displayName: $displayName}}
  ) {
    id
  }
}
    `;
export type RenameTemplateConfigurationMutationFn = Apollo.MutationFunction<RenameTemplateConfigurationMutation, RenameTemplateConfigurationMutationVariables>;

/**
 * __useRenameTemplateConfigurationMutation__
 *
 * To run a mutation, you first call `useRenameTemplateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameTemplateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameTemplateConfigurationMutation, { data, loading, error }] = useRenameTemplateConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useRenameTemplateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<RenameTemplateConfigurationMutation, RenameTemplateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameTemplateConfigurationMutation, RenameTemplateConfigurationMutationVariables>(RenameTemplateConfigurationDocument, options);
      }
export type RenameTemplateConfigurationMutationHookResult = ReturnType<typeof useRenameTemplateConfigurationMutation>;
export type RenameTemplateConfigurationMutationResult = Apollo.MutationResult<RenameTemplateConfigurationMutation>;
export type RenameTemplateConfigurationMutationOptions = Apollo.BaseMutationOptions<RenameTemplateConfigurationMutation, RenameTemplateConfigurationMutationVariables>;
export const LaunchTemplateConfigurationDocument = gql`
    mutation LaunchTemplateConfiguration($workspaceId: ID!, $templateConfigurationId: ID!, $launchedById: ID!) {
  executeTemplateConfiguration(
    input: {workspaceId: $workspaceId, templateConfigurationId: $templateConfigurationId, launchedById: $launchedById}
  ) {
    id
  }
}
    `;
export type LaunchTemplateConfigurationMutationFn = Apollo.MutationFunction<LaunchTemplateConfigurationMutation, LaunchTemplateConfigurationMutationVariables>;

/**
 * __useLaunchTemplateConfigurationMutation__
 *
 * To run a mutation, you first call `useLaunchTemplateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchTemplateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchTemplateConfigurationMutation, { data, loading, error }] = useLaunchTemplateConfigurationMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      templateConfigurationId: // value for 'templateConfigurationId'
 *      launchedById: // value for 'launchedById'
 *   },
 * });
 */
export function useLaunchTemplateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<LaunchTemplateConfigurationMutation, LaunchTemplateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LaunchTemplateConfigurationMutation, LaunchTemplateConfigurationMutationVariables>(LaunchTemplateConfigurationDocument, options);
      }
export type LaunchTemplateConfigurationMutationHookResult = ReturnType<typeof useLaunchTemplateConfigurationMutation>;
export type LaunchTemplateConfigurationMutationResult = Apollo.MutationResult<LaunchTemplateConfigurationMutation>;
export type LaunchTemplateConfigurationMutationOptions = Apollo.BaseMutationOptions<LaunchTemplateConfigurationMutation, LaunchTemplateConfigurationMutationVariables>;
export const ResetTemplateConfigurationDataDocument = gql`
    mutation ResetTemplateConfigurationData($workspaceId: ID!, $templateConfigurationId: ID!, $resetById: ID!) {
  resetTemplateConfigurationData(
    input: {workspaceId: $workspaceId, templateConfigurationId: $templateConfigurationId, resetById: $resetById}
  ) {
    id
  }
}
    `;
export type ResetTemplateConfigurationDataMutationFn = Apollo.MutationFunction<ResetTemplateConfigurationDataMutation, ResetTemplateConfigurationDataMutationVariables>;

/**
 * __useResetTemplateConfigurationDataMutation__
 *
 * To run a mutation, you first call `useResetTemplateConfigurationDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTemplateConfigurationDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTemplateConfigurationDataMutation, { data, loading, error }] = useResetTemplateConfigurationDataMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      templateConfigurationId: // value for 'templateConfigurationId'
 *      resetById: // value for 'resetById'
 *   },
 * });
 */
export function useResetTemplateConfigurationDataMutation(baseOptions?: Apollo.MutationHookOptions<ResetTemplateConfigurationDataMutation, ResetTemplateConfigurationDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTemplateConfigurationDataMutation, ResetTemplateConfigurationDataMutationVariables>(ResetTemplateConfigurationDataDocument, options);
      }
export type ResetTemplateConfigurationDataMutationHookResult = ReturnType<typeof useResetTemplateConfigurationDataMutation>;
export type ResetTemplateConfigurationDataMutationResult = Apollo.MutationResult<ResetTemplateConfigurationDataMutation>;
export type ResetTemplateConfigurationDataMutationOptions = Apollo.BaseMutationOptions<ResetTemplateConfigurationDataMutation, ResetTemplateConfigurationDataMutationVariables>;
export const CancelCandidateTemplateConfigurationDataDocument = gql`
    mutation CancelCandidateTemplateConfigurationData($workspaceId: ID!, $templateConfigurationId: ID!, $candidateConfigurationDataId: ID!) {
  cancelCandidateTemplateConfigurationData(
    input: {workspaceId: $workspaceId, templateConfigurationId: $templateConfigurationId, candidateConfigurationDataId: $candidateConfigurationDataId}
  ) {
    id
  }
}
    `;
export type CancelCandidateTemplateConfigurationDataMutationFn = Apollo.MutationFunction<CancelCandidateTemplateConfigurationDataMutation, CancelCandidateTemplateConfigurationDataMutationVariables>;

/**
 * __useCancelCandidateTemplateConfigurationDataMutation__
 *
 * To run a mutation, you first call `useCancelCandidateTemplateConfigurationDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCandidateTemplateConfigurationDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCandidateTemplateConfigurationDataMutation, { data, loading, error }] = useCancelCandidateTemplateConfigurationDataMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      templateConfigurationId: // value for 'templateConfigurationId'
 *      candidateConfigurationDataId: // value for 'candidateConfigurationDataId'
 *   },
 * });
 */
export function useCancelCandidateTemplateConfigurationDataMutation(baseOptions?: Apollo.MutationHookOptions<CancelCandidateTemplateConfigurationDataMutation, CancelCandidateTemplateConfigurationDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCandidateTemplateConfigurationDataMutation, CancelCandidateTemplateConfigurationDataMutationVariables>(CancelCandidateTemplateConfigurationDataDocument, options);
      }
export type CancelCandidateTemplateConfigurationDataMutationHookResult = ReturnType<typeof useCancelCandidateTemplateConfigurationDataMutation>;
export type CancelCandidateTemplateConfigurationDataMutationResult = Apollo.MutationResult<CancelCandidateTemplateConfigurationDataMutation>;
export type CancelCandidateTemplateConfigurationDataMutationOptions = Apollo.BaseMutationOptions<CancelCandidateTemplateConfigurationDataMutation, CancelCandidateTemplateConfigurationDataMutationVariables>;
export const RenameWorkflowInstanceDocument = gql`
    mutation RenameWorkflowInstance($id: ID!, $workspaceId: ID!, $displayName: String) {
  editInstance(
    input: {id: $id, workspaceId: $workspaceId, data: {displayName: $displayName}}
  ) {
    displayName
    id
  }
}
    `;
export type RenameWorkflowInstanceMutationFn = Apollo.MutationFunction<RenameWorkflowInstanceMutation, RenameWorkflowInstanceMutationVariables>;

/**
 * __useRenameWorkflowInstanceMutation__
 *
 * To run a mutation, you first call `useRenameWorkflowInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameWorkflowInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameWorkflowInstanceMutation, { data, loading, error }] = useRenameWorkflowInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useRenameWorkflowInstanceMutation(baseOptions?: Apollo.MutationHookOptions<RenameWorkflowInstanceMutation, RenameWorkflowInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameWorkflowInstanceMutation, RenameWorkflowInstanceMutationVariables>(RenameWorkflowInstanceDocument, options);
      }
export type RenameWorkflowInstanceMutationHookResult = ReturnType<typeof useRenameWorkflowInstanceMutation>;
export type RenameWorkflowInstanceMutationResult = Apollo.MutationResult<RenameWorkflowInstanceMutation>;
export type RenameWorkflowInstanceMutationOptions = Apollo.BaseMutationOptions<RenameWorkflowInstanceMutation, RenameWorkflowInstanceMutationVariables>;
export const WorkspaceDashboardsTabDocument = gql`
    query WorkspaceDashboardsTab($input: DashboardsRetrieveInput!) {
  dashboards(input: $input) {
    items {
      id
      displayName
      status
      dashboardMetadata {
        id
        displayName
      }
      dataSet {
        id
        displayName
        workspaceId
        lastRefreshRun {
          id
          completedOn
          status
          workspaceId
        }
      }
    }
  }
}
    `;

/**
 * __useWorkspaceDashboardsTabQuery__
 *
 * To run a query within a React component, call `useWorkspaceDashboardsTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceDashboardsTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceDashboardsTabQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkspaceDashboardsTabQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceDashboardsTabQuery, WorkspaceDashboardsTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceDashboardsTabQuery, WorkspaceDashboardsTabQueryVariables>(WorkspaceDashboardsTabDocument, options);
      }
export function useWorkspaceDashboardsTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceDashboardsTabQuery, WorkspaceDashboardsTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceDashboardsTabQuery, WorkspaceDashboardsTabQueryVariables>(WorkspaceDashboardsTabDocument, options);
        }
export type WorkspaceDashboardsTabQueryHookResult = ReturnType<typeof useWorkspaceDashboardsTabQuery>;
export type WorkspaceDashboardsTabLazyQueryHookResult = ReturnType<typeof useWorkspaceDashboardsTabLazyQuery>;
export type WorkspaceDashboardsTabQueryResult = Apollo.QueryResult<WorkspaceDashboardsTabQuery, WorkspaceDashboardsTabQueryVariables>;
export const WorkspaceDataSetsTabDocument = gql`
    query WorkspaceDataSetsTab($workspaceId: ID!, $statuses: [DataSetStatus!]) {
  dataSets(input: {workspaceId: $workspaceId, statuses: $statuses}) {
    items {
      templateMetadata {
        id
        displayName
      }
      displayName
      id
      lastRefreshRun {
        id
        completedOn
        status
        workspaceId
      }
      configurationInstance {
        status
      }
      status
      workspaceId
    }
  }
}
    `;

/**
 * __useWorkspaceDataSetsTabQuery__
 *
 * To run a query within a React component, call `useWorkspaceDataSetsTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceDataSetsTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceDataSetsTabQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useWorkspaceDataSetsTabQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceDataSetsTabQuery, WorkspaceDataSetsTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceDataSetsTabQuery, WorkspaceDataSetsTabQueryVariables>(WorkspaceDataSetsTabDocument, options);
      }
export function useWorkspaceDataSetsTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceDataSetsTabQuery, WorkspaceDataSetsTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceDataSetsTabQuery, WorkspaceDataSetsTabQueryVariables>(WorkspaceDataSetsTabDocument, options);
        }
export type WorkspaceDataSetsTabQueryHookResult = ReturnType<typeof useWorkspaceDataSetsTabQuery>;
export type WorkspaceDataSetsTabLazyQueryHookResult = ReturnType<typeof useWorkspaceDataSetsTabLazyQuery>;
export type WorkspaceDataSetsTabQueryResult = Apollo.QueryResult<WorkspaceDataSetsTabQuery, WorkspaceDataSetsTabQueryVariables>;
export const DeleteWorkspaceFileDocument = gql`
    mutation DeleteWorkspaceFile($workspaceId: ID!, $fileId: ID!) {
  deleteFile(input: {workspaceId: $workspaceId, fileId: $fileId})
}
    `;
export type DeleteWorkspaceFileMutationFn = Apollo.MutationFunction<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>;

/**
 * __useDeleteWorkspaceFileMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceFileMutation, { data, loading, error }] = useDeleteWorkspaceFileMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteWorkspaceFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>(DeleteWorkspaceFileDocument, options);
      }
export type DeleteWorkspaceFileMutationHookResult = ReturnType<typeof useDeleteWorkspaceFileMutation>;
export type DeleteWorkspaceFileMutationResult = Apollo.MutationResult<DeleteWorkspaceFileMutation>;
export type DeleteWorkspaceFileMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceFileMutation, DeleteWorkspaceFileMutationVariables>;
export const WorkspaceFilesBrowserDocument = gql`
    query WorkspaceFilesBrowser($workspaceId: ID!, $path: String) {
  fileDescriptor(input: {workspaceId: $workspaceId, path: $path}) {
    ...WorkspaceFilesBrowserFileDescriptor
    ...WorkspaceFilesBrowserFolderDescriptor
  }
}
    ${WorkspaceFilesBrowserFileDescriptorFragmentDoc}
${WorkspaceFilesBrowserFolderDescriptorFragmentDoc}`;

/**
 * __useWorkspaceFilesBrowserQuery__
 *
 * To run a query within a React component, call `useWorkspaceFilesBrowserQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceFilesBrowserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceFilesBrowserQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useWorkspaceFilesBrowserQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceFilesBrowserQuery, WorkspaceFilesBrowserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceFilesBrowserQuery, WorkspaceFilesBrowserQueryVariables>(WorkspaceFilesBrowserDocument, options);
      }
export function useWorkspaceFilesBrowserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceFilesBrowserQuery, WorkspaceFilesBrowserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceFilesBrowserQuery, WorkspaceFilesBrowserQueryVariables>(WorkspaceFilesBrowserDocument, options);
        }
export type WorkspaceFilesBrowserQueryHookResult = ReturnType<typeof useWorkspaceFilesBrowserQuery>;
export type WorkspaceFilesBrowserLazyQueryHookResult = ReturnType<typeof useWorkspaceFilesBrowserLazyQuery>;
export type WorkspaceFilesBrowserQueryResult = Apollo.QueryResult<WorkspaceFilesBrowserQuery, WorkspaceFilesBrowserQueryVariables>;
export const WorkspacePageRunsDocument = gql`
    query WorkspacePageRuns($workspaceId: ID!, $after: String, $first: Int, $launchedByTypes: [LaunchedByType!]) {
  instances(
    after: $after
    first: $first
    input: {workspaceId: $workspaceId, launchedByTypes: $launchedByTypes}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        type
        displayName
        updatedOn
        status
        launchedByType
        launchedBy {
          __typename
          ... on DataSet {
            id
            createdBy {
              firstName
              lastName
            }
          }
          ... on ScheduledJob {
            id
            createdBy {
              firstName
              lastName
            }
          }
          ... on TenantMember {
            id
            firstName
            lastName
          }
        }
        templateConfiguration {
          id
          displayName
          status
          exposureLevel
          configurationInstance {
            id
            status
          }
        }
        templateMetadata {
          id
          name
          displayName
          type
        }
      }
    }
  }
}
    `;

/**
 * __useWorkspacePageRunsQuery__
 *
 * To run a query within a React component, call `useWorkspacePageRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacePageRunsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      launchedByTypes: // value for 'launchedByTypes'
 *   },
 * });
 */
export function useWorkspacePageRunsQuery(baseOptions: Apollo.QueryHookOptions<WorkspacePageRunsQuery, WorkspacePageRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacePageRunsQuery, WorkspacePageRunsQueryVariables>(WorkspacePageRunsDocument, options);
      }
export function useWorkspacePageRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacePageRunsQuery, WorkspacePageRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacePageRunsQuery, WorkspacePageRunsQueryVariables>(WorkspacePageRunsDocument, options);
        }
export type WorkspacePageRunsQueryHookResult = ReturnType<typeof useWorkspacePageRunsQuery>;
export type WorkspacePageRunsLazyQueryHookResult = ReturnType<typeof useWorkspacePageRunsLazyQuery>;
export type WorkspacePageRunsQueryResult = Apollo.QueryResult<WorkspacePageRunsQuery, WorkspacePageRunsQueryVariables>;
export const WorkspacePageTemplateConfigurationsDocument = gql`
    query WorkspacePageTemplateConfigurations($workspaceId: ID!) {
  templateConfigurations(input: {workspaceId: $workspaceId}) {
    items {
      id
      displayName
      exposureLevel
      lastStartedInstance {
        id
        startedOn
        workspaceId
      }
      status
      templateMetadata {
        id
        name
        displayName
      }
      workspaceId
    }
  }
}
    `;

/**
 * __useWorkspacePageTemplateConfigurationsQuery__
 *
 * To run a query within a React component, call `useWorkspacePageTemplateConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageTemplateConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacePageTemplateConfigurationsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspacePageTemplateConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<WorkspacePageTemplateConfigurationsQuery, WorkspacePageTemplateConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacePageTemplateConfigurationsQuery, WorkspacePageTemplateConfigurationsQueryVariables>(WorkspacePageTemplateConfigurationsDocument, options);
      }
export function useWorkspacePageTemplateConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacePageTemplateConfigurationsQuery, WorkspacePageTemplateConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacePageTemplateConfigurationsQuery, WorkspacePageTemplateConfigurationsQueryVariables>(WorkspacePageTemplateConfigurationsDocument, options);
        }
export type WorkspacePageTemplateConfigurationsQueryHookResult = ReturnType<typeof useWorkspacePageTemplateConfigurationsQuery>;
export type WorkspacePageTemplateConfigurationsLazyQueryHookResult = ReturnType<typeof useWorkspacePageTemplateConfigurationsLazyQuery>;
export type WorkspacePageTemplateConfigurationsQueryResult = Apollo.QueryResult<WorkspacePageTemplateConfigurationsQuery, WorkspacePageTemplateConfigurationsQueryVariables>;
export const WorkspacePageScheduledJobsDocument = gql`
    query WorkspacePageScheduledJobs($workspaceId: ID!, $after: String, $first: Int) {
  scheduledJobs(
    after: $after
    first: $first
    input: {workspaceId: $workspaceId, templateConfigurationTypes: [AUTOMATION]}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    items {
      id
      endTime
      lastStartedInstance {
        id
        startedOn
        workspaceId
      }
      pattern
      startTime
      status
      templateConfiguration {
        id
        status
        displayName
        templateMetadata {
          id
          name
          displayName
        }
        workspaceId
      }
      timezoneName
    }
  }
}
    `;

/**
 * __useWorkspacePageScheduledJobsQuery__
 *
 * To run a query within a React component, call `useWorkspacePageScheduledJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageScheduledJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacePageScheduledJobsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useWorkspacePageScheduledJobsQuery(baseOptions: Apollo.QueryHookOptions<WorkspacePageScheduledJobsQuery, WorkspacePageScheduledJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacePageScheduledJobsQuery, WorkspacePageScheduledJobsQueryVariables>(WorkspacePageScheduledJobsDocument, options);
      }
export function useWorkspacePageScheduledJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacePageScheduledJobsQuery, WorkspacePageScheduledJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacePageScheduledJobsQuery, WorkspacePageScheduledJobsQueryVariables>(WorkspacePageScheduledJobsDocument, options);
        }
export type WorkspacePageScheduledJobsQueryHookResult = ReturnType<typeof useWorkspacePageScheduledJobsQuery>;
export type WorkspacePageScheduledJobsLazyQueryHookResult = ReturnType<typeof useWorkspacePageScheduledJobsLazyQuery>;
export type WorkspacePageScheduledJobsQueryResult = Apollo.QueryResult<WorkspacePageScheduledJobsQuery, WorkspacePageScheduledJobsQueryVariables>;
export const WorkspacePageRenameDataSetDocument = gql`
    mutation WorkspacePageRenameDataSet($id: ID!, $workspaceId: ID!, $displayName: String) {
  editDataSet(
    input: {id: $id, workspaceId: $workspaceId, data: {displayName: $displayName}}
  ) {
    displayName
    id
  }
}
    `;
export type WorkspacePageRenameDataSetMutationFn = Apollo.MutationFunction<WorkspacePageRenameDataSetMutation, WorkspacePageRenameDataSetMutationVariables>;

/**
 * __useWorkspacePageRenameDataSetMutation__
 *
 * To run a mutation, you first call `useWorkspacePageRenameDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageRenameDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePageRenameDataSetMutation, { data, loading, error }] = useWorkspacePageRenameDataSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useWorkspacePageRenameDataSetMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePageRenameDataSetMutation, WorkspacePageRenameDataSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePageRenameDataSetMutation, WorkspacePageRenameDataSetMutationVariables>(WorkspacePageRenameDataSetDocument, options);
      }
export type WorkspacePageRenameDataSetMutationHookResult = ReturnType<typeof useWorkspacePageRenameDataSetMutation>;
export type WorkspacePageRenameDataSetMutationResult = Apollo.MutationResult<WorkspacePageRenameDataSetMutation>;
export type WorkspacePageRenameDataSetMutationOptions = Apollo.BaseMutationOptions<WorkspacePageRenameDataSetMutation, WorkspacePageRenameDataSetMutationVariables>;
export const WorkspacePageRenameTemplateConfigurationDocument = gql`
    mutation WorkspacePageRenameTemplateConfiguration($id: ID!, $workspaceId: ID!, $displayName: String) {
  editTemplateConfiguration(
    input: {id: $id, workspaceId: $workspaceId, data: {displayName: $displayName}}
  ) {
    displayName
    id
  }
}
    `;
export type WorkspacePageRenameTemplateConfigurationMutationFn = Apollo.MutationFunction<WorkspacePageRenameTemplateConfigurationMutation, WorkspacePageRenameTemplateConfigurationMutationVariables>;

/**
 * __useWorkspacePageRenameTemplateConfigurationMutation__
 *
 * To run a mutation, you first call `useWorkspacePageRenameTemplateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageRenameTemplateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePageRenameTemplateConfigurationMutation, { data, loading, error }] = useWorkspacePageRenameTemplateConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useWorkspacePageRenameTemplateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePageRenameTemplateConfigurationMutation, WorkspacePageRenameTemplateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePageRenameTemplateConfigurationMutation, WorkspacePageRenameTemplateConfigurationMutationVariables>(WorkspacePageRenameTemplateConfigurationDocument, options);
      }
export type WorkspacePageRenameTemplateConfigurationMutationHookResult = ReturnType<typeof useWorkspacePageRenameTemplateConfigurationMutation>;
export type WorkspacePageRenameTemplateConfigurationMutationResult = Apollo.MutationResult<WorkspacePageRenameTemplateConfigurationMutation>;
export type WorkspacePageRenameTemplateConfigurationMutationOptions = Apollo.BaseMutationOptions<WorkspacePageRenameTemplateConfigurationMutation, WorkspacePageRenameTemplateConfigurationMutationVariables>;
export const WorkspacePageSaveTemplateConfigurationDocument = gql`
    mutation WorkspacePageSaveTemplateConfiguration($input: ConvertOnDemandTemplateConfigurationInput!) {
  convertOnDemandTemplateConfiguration(input: $input) {
    id
    displayName
  }
}
    `;
export type WorkspacePageSaveTemplateConfigurationMutationFn = Apollo.MutationFunction<WorkspacePageSaveTemplateConfigurationMutation, WorkspacePageSaveTemplateConfigurationMutationVariables>;

/**
 * __useWorkspacePageSaveTemplateConfigurationMutation__
 *
 * To run a mutation, you first call `useWorkspacePageSaveTemplateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePageSaveTemplateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePageSaveTemplateConfigurationMutation, { data, loading, error }] = useWorkspacePageSaveTemplateConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkspacePageSaveTemplateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePageSaveTemplateConfigurationMutation, WorkspacePageSaveTemplateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePageSaveTemplateConfigurationMutation, WorkspacePageSaveTemplateConfigurationMutationVariables>(WorkspacePageSaveTemplateConfigurationDocument, options);
      }
export type WorkspacePageSaveTemplateConfigurationMutationHookResult = ReturnType<typeof useWorkspacePageSaveTemplateConfigurationMutation>;
export type WorkspacePageSaveTemplateConfigurationMutationResult = Apollo.MutationResult<WorkspacePageSaveTemplateConfigurationMutation>;
export type WorkspacePageSaveTemplateConfigurationMutationOptions = Apollo.BaseMutationOptions<WorkspacePageSaveTemplateConfigurationMutation, WorkspacePageSaveTemplateConfigurationMutationVariables>;