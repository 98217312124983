import { memoize } from 'lodash';
import { UserManager } from 'oidc-client';
import { getTenantAppConfig } from '../../app-config';

/**
 * Returns the OIDC UserManager instance for the app.
 * https://github.com/IdentityModel/oidc-client-js/wiki#usermanager
 */
export const getUserManager = memoize((): UserManager => {
  const appConfig = getTenantAppConfig(window.tenantName);
  const tenantName = appConfig.TENANT_NAME;
  const authorityUri = appConfig.TENANT_OPEN_ID_AUTHORITY;
  const tenantUiUri = appConfig.TENANT_UI_URL;

  return new UserManager({
    authority: authorityUri,
    client_id: `tenant-ui-${tenantName}`,
    redirect_uri: `${tenantUiUri}/signin-callback.html`,
    silent_redirect_uri: `${tenantUiUri}/silent-renew.html`,
    post_logout_redirect_uri: `${tenantUiUri}/`,
    response_type: 'id_token token',
    scope: 'ark/complete openid',

    filterProtocolClaims: true,
    loadUserInfo: true,

    // The number of seconds before an access token is to expire to raise the
    // accessTokenExpiring event.
    accessTokenExpiringNotificationTime: 60, // 60 seconds

    // The window of time (in seconds) to allow the current time to deviate
    // when validating id_token's iat, nbf, and exp values.
    clockSkew: 600, // 10 min
  });
});
