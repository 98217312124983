import {
  detectCurrentTimezone,
  tryFormatZonedDate,
  tryParseISO,
} from '@common/utils';
import { parseCronExpression } from '@voleer/cron-parse';
import {
  toDailySchedule,
  toHourlySchedule,
  toMinutesSchedule,
  toMonthlySchedule,
  toWeeklySchedule,
} from '../../utils';
import {
  EndTypeValue,
  ScheduleType,
  ScheduledJobFormValues,
  ScheduledJobProp,
} from './interface';
import { SCHEDULED_JOB_FORM_DATE_FORMAT, formatTimeValue } from './utils';

/**
 * Returns the relevant end type for the given scheduled job.
 */
const initialEndTypeFor = (value?: ScheduledJobProp): EndTypeValue => {
  if (typeof value?.maxRecurrenceCount === 'number') {
    return 'afterTimes';
  }

  if (typeof value?.endTime === 'string') {
    return 'afterDate';
  }

  return 'never';
};

/**
 * Returns the relevant initial form field values for the given scheduled job.
 */
export const initialValuesFor = (
  value?: ScheduledJobProp
): ScheduledJobFormValues => {
  const timezoneName = value?.timezoneName || detectCurrentTimezone();

  const parsedStartTime = tryParseISO(
    value?.startTime || new Date().toISOString()
  );
  const parsedEndTime = tryParseISO(value?.endTime);

  const startDate = tryFormatZonedDate(
    timezoneName,
    SCHEDULED_JOB_FORM_DATE_FORMAT,
    parsedStartTime
  );

  const endDate = tryFormatZonedDate(
    timezoneName,
    SCHEDULED_JOB_FORM_DATE_FORMAT,
    parsedEndTime
  );

  // Base values to use unless overridden below
  const baseValues: ScheduledJobFormValues = {
    type: ScheduleType.weeks,
    templateConfiguration: value?.templateConfiguration,
    startDate: startDate ?? '',
    atTime: '12:00 AM',
    daysOfWeek: [1, 2, 3, 4, 5],
    hourlyInterval: '1',
    minutelyInterval: '30',
    dayOfMonth: '1',
    months: [],
    timezoneName,
    endType: initialEndTypeFor(value),
    endDate: endDate ?? '',
    maxRecurrenceCount: String(value?.maxRecurrenceCount ?? '10'),
  };

  // Default values when no pattern is present
  if (typeof value?.pattern !== 'string') {
    return {
      ...baseValues,
      type: ScheduleType.weeks,
    };
  }

  const cronExpression = parseCronExpression(value.pattern);

  // Handle by-minute schedules
  const minutelyExpression = toMinutesSchedule(cronExpression);
  if (minutelyExpression) {
    return {
      ...baseValues,
      type: ScheduleType.minutes,
      minutelyInterval: `${minutelyExpression.minutes}`,
    };
  }

  // Handle hourly schedules
  const hourlyExpression = toHourlySchedule(cronExpression);
  if (hourlyExpression) {
    return {
      ...baseValues,
      type: ScheduleType.hours,
      hourlyInterval: `${hourlyExpression.hours}`,
    };
  }

  // Handle daily schedules
  const dailyExpression = toDailySchedule(cronExpression);
  if (dailyExpression) {
    return {
      ...baseValues,
      type: ScheduleType.days,
      atTime: formatTimeValue({
        hour: dailyExpression.hour,
        minute: dailyExpression.minute,
      }),
    };
  }

  // Handle weekly schedules
  const weeklyExpression = toWeeklySchedule(cronExpression);
  if (weeklyExpression) {
    return {
      ...baseValues,
      type: ScheduleType.weeks,
      atTime: formatTimeValue({
        hour: weeklyExpression.hour,
        minute: weeklyExpression.minute,
      }),
      daysOfWeek: weeklyExpression.dayOfWeek || [],
    };
  }

  // Handle monthly schedules
  const monthlyExpression = toMonthlySchedule(cronExpression);
  if (monthlyExpression) {
    return {
      ...baseValues,
      type: ScheduleType.months,
      atTime: formatTimeValue({
        hour: monthlyExpression.hour,
        minute: monthlyExpression.minute,
      }),
      months: monthlyExpression.month,
      dayOfMonth: monthlyExpression.dayOfMonth.toString(),
    };
  }

  // Unknown expression type
  return baseValues;
};
