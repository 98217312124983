import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useAccountStatus, useDateFormat } from '@common/hooks';
import { formatDuration, tryParseISO, urlFor } from '@common/utils';
import {
  InstanceStatus,
  TemplateConfigurationExposureLevel,
  TemplateMetadataType,
  WorkflowInstanceViewDocument,
  useWorkflowInstanceViewQuery,
  useWorkflowInstanceViewSaveTemplateConfigurationMutation,
} from '@generated/graphql-code-generator';
import { Anchor, Link, useModalState } from '@voleer/ui-kit';
import { Box, Button, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa';
import {
  SaveTemplateConfigurationModal,
  SaveTemplateConfigurationModalProps,
  StatusMessageMarkdown,
} from '../../..';
import { isSaveableTemplateConfiguration } from '../../../../utils';
import { InstanceStatusMessages } from './InstanceStatusMessages';

type InstanceSummaryHeadingProps = {
  instanceName?: string | null | undefined;
  instanceStatus: InstanceStatus;
};

const InstanceSummaryHeading: React.FC<InstanceSummaryHeadingProps> = ({
  instanceName,
  instanceStatus,
}) => {
  const [t] = useTranslation(
    'features/workflows/components/WorkflowInstanceView'
  );

  let headingText: string;
  let headingColor: string;
  let headingBody: string;

  if (instanceStatus === InstanceStatus.Completed) {
    headingColor = 'status-ok';
    headingText = t('instance-summary.completed.heading');
    headingBody = t('instance-summary.completed.body', { instanceName });
  } else if (instanceStatus === InstanceStatus.Failed) {
    headingColor = 'status-error';
    headingText = t('instance-summary.failed.heading');
    headingBody = t('instance-summary.failed.body', { instanceName });
  } else if (instanceStatus === InstanceStatus.TimedOut) {
    headingColor = 'status-error';
    headingText = t('instance-summary.timed-out.heading');
    headingBody = t('instance-summary.timed-out.body', { instanceName });
  } else if (instanceStatus === InstanceStatus.Cancelling) {
    headingColor = 'dark-2';
    headingText = t('instance-summary.cancelling.heading');
    headingBody = t('instance-summary.cancelling.body', { instanceName });
  } else if (instanceStatus === InstanceStatus.Canceled) {
    headingColor = 'dark-2';
    headingText = t('instance-summary.canceled.heading');
    headingBody = t('instance-summary.canceled.body', { instanceName });
  } else {
    // Don't render anything if state not supported
    return null;
  }

  return (
    <>
      <Heading color={headingColor} margin={{ bottom: 'medium' }} size="medium">
        {headingText}
      </Heading>
      <Text color="dark-2" size="large">
        {headingBody}
      </Text>
    </>
  );
};

type InstanceSummaryProps = {
  instanceId: string;
};

/**
 * Renders the instance completed empty state.
 */
export const InstanceSummary: React.FC<InstanceSummaryProps> = ({
  instanceId,
}) => {
  const [format, { DateFormat }] = useDateFormat();

  const [t] = useTranslation(
    'features/workflows/components/WorkflowInstanceView'
  );

  const { data, ...query } = useWorkflowInstanceViewQuery({
    variables: { instanceId },
  });

  const loading = !data && query.loading;

  const [saveTemplateConfiguration] =
    useWorkflowInstanceViewSaveTemplateConfigurationMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(WorkflowInstanceViewDocument)!],
    });
  const { disableWriteOperation } = useAccountStatus();
  const instance = data?.instance;
  const templateConfiguration = instance?.templateConfiguration;
  const configurationInstance = templateConfiguration?.configurationInstance;

  const startedOn = tryParseISO(
    templateConfiguration?.exposureLevel ===
      TemplateConfigurationExposureLevel.OnDemand
      ? configurationInstance?.startedOn ?? instance?.startedOn
      : instance?.startedOn
  );

  const completedOn = tryParseISO(instance?.completedOn);

  const formatDate = (date: Date) => {
    return format(date, DateFormat.Abbreviated);
  };

  const saveConfigurationModal = useModalState();

  const onSubmitSaveConfiguration: SaveTemplateConfigurationModalProps['onSubmit'] =
    async ({ displayName }) => {
      if (!instance || !templateConfiguration) {
        return;
      }

      const workspaceId = instance.workspaceId;
      const templateConfigurationId = templateConfiguration.id;

      const result = await saveTemplateConfiguration({
        variables: {
          input: {
            displayName,
            workspaceId,
            templateConfigurationId,
          },
        },
      });

      if (!result.errors?.length) {
        saveConfigurationModal.close();
      }
    };

  // Loading and error states are handled in WorkflowInstanceView
  if (loading || !instance) {
    return null;
  }

  return (
    <Box flex={{ shrink: 0 }} gap="small" pad="medium">
      <Box
        background="white"
        elevation="small"
        flex={{ shrink: 0 }}
        round="xsmall"
      >
        <Box align="center" overflow="auto" pad="medium">
          <InstanceSummaryHeading
            instanceName={instance.displayName}
            instanceStatus={instance.status}
          />

          {startedOn && completedOn && (
            <Box
              data-testid="instance-summary__dates-and-duration"
              direction="row"
              flex={{ shrink: 0 }}
              gap="medium"
            >
              <Box align="center" margin={{ top: 'medium' }}>
                <Text color="dark-2" margin="small" size="medium">
                  {t('instance-summary.date')}
                </Text>
                <Text data-testid="instance-summary__dates" size="xxlarge">
                  {formatDate(startedOn)}
                  {' - '}
                  {formatDate(completedOn)}
                </Text>
              </Box>

              <Box align="center" margin={{ top: 'medium' }}>
                <Text color="dark-2" margin="small" size="medium">
                  {t('instance-summary.duration')}
                </Text>
                <Box height="100%" justify="center">
                  <Text data-testid="instance-summary__duration" size="xxlarge">
                    {formatDuration(startedOn, completedOn)}
                  </Text>
                </Box>
              </Box>
            </Box>
          )}

          <Box pad={{ vertical: 'medium' }}>
            {instance.status === InstanceStatus.Failed && (
              <Anchor
                data-testid="troubleshoot-link"
                href={urlFor('instanceTroubleshoot')()}
                rel="noopener noreferrer"
                target="_blank"
                variation="unstyled"
              >
                <Button
                  icon={<FaQuestionCircle />}
                  label={t('instance-summary.buttons.troubleshoot')}
                />
              </Anchor>
            )}
            {instance.status === InstanceStatus.Completed && (
              <Box direction="row" gap="medium">
                <Link
                  data-testid="library-link"
                  to={urlFor('library')()}
                  variation="unstyled"
                >
                  <Button label={t('instance-summary.buttons.discover-more')} />
                </Link>
                {templateConfiguration?.templateMetadata?.type ===
                  TemplateMetadataType.Automation &&
                  isSaveableTemplateConfiguration(templateConfiguration) && (
                    <Button
                      disabled={disableWriteOperation}
                      label={t('instance-summary.buttons.save-configuration')}
                      onClick={saveConfigurationModal.open}
                      primary={true}
                    />
                  )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {!!data.instance?.runSummary && (
        <Box
          background="white"
          elevation="small"
          flex={{ shrink: 0 }}
          round="xsmall"
        >
          <Box
            data-testid="instance-summary__run_summary"
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <StatusMessageMarkdown content={data.instance.runSummary} />
          </Box>
        </Box>
      )}
      <Box
        background="white"
        elevation="small"
        flex={{ shrink: 0 }}
        round="xsmall"
      >
        <Box overflow="auto" pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Heading level="5">
            {t('instance-summary.status-messages.heading')}
          </Heading>
          <InstanceStatusMessages instanceId={instance.id} />
        </Box>
      </Box>
      {instance.status === InstanceStatus.Completed &&
        templateConfiguration && (
          <SaveTemplateConfigurationModal
            onCancel={saveConfigurationModal.close}
            onSubmit={onSubmitSaveConfiguration}
            templateConfiguration={templateConfiguration}
            {...saveConfigurationModal.props}
          />
        )}
    </Box>
  );
};
