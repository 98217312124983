import { ApolloError } from '@apollo/client';
import { useTypedFlags } from '@features/launch-darkly';
import {
  WorkflowInstanceViewQuery,
  useWorkflowInstanceViewQuery,
} from '@generated/graphql-code-generator';
import { WorkflowInstanceViewProps } from './WorkflowInstanceView';

type UseWorkflowInstanceViewResult = {
  /**
   * The WorkflowInstanceView instance.
   */
  instance?: WorkflowInstanceViewQuery['instance'];

  /**
   * An error from one of the queries.
   */
  error: ApolloError | undefined;

  /**
   * If all queries inside this hook have fetched data or not.
   */
  loading: boolean;

  /**
   * Props to pass to the WorkflowInstanceView component.
   */
  props: WorkflowInstanceViewProps;
};

type UseWorkflowInstanceViewParams = Readonly<{
  workflowInstanceId?: string;
}>;

/**
 * Hook providing data for the WorkflowInstanceView component.
 */
export const useWorkflowInstanceView = ({
  workflowInstanceId,
}: UseWorkflowInstanceViewParams): UseWorkflowInstanceViewResult => {
  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const { data, error, ...query } = useWorkflowInstanceViewQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfig?.instanceStepsAndDataRequests,
    skip: !workflowInstanceId,
    variables: {
      // Skipping this query if no workflowInstanceId, so ignoring undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      instanceId: workflowInstanceId!,
    },
  });

  const instance = data?.instance;

  const loading = !data && query.loading;

  return {
    error,
    instance,
    loading,
    props: {
      instanceId: workflowInstanceId,
    },
  };
};
