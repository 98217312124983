import React from 'react';
import { PropsOf } from '@voleer/types';
import {
  Alert,
  AlertStatus,
  MarkdownAdmonitionDirectiveName,
  SafeMarkdown,
  SafeMarkdownProps,
} from '@voleer/ui-kit';
import { Box, Heading, Image, Meter, Stack, Text } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled, { css } from 'styled-components';

export type PackageMarkdownProps = PropsOf<typeof SafeMarkdown>;

/**
 * Custom component for rendering an `<a>` with `target="_blank"` for URL
 * links.
 */
const PackageMarkdownAnchor: React.FC<JSX.IntrinsicElements['a']> = props => {
  // Notes:
  // Markdown [links] cannot set a target so always open in a new window.
  // HTML anchors can set a target attribute. We only allow _self or _blank.
  // Links to document named anchors href="#something" always use "_self"
  // Links to external named anchors href="http://site.com/#named" use "_blank"

  // Default to open links in new tab
  let target = props.target && props.target === '_self' ? '_self' : '_blank';

  // The sanitization process adds a prefix to all `id` and `name` attributes,
  // so when linking to anchors using name or id we need to add the same prefix
  // to the href
  let href = props.href;
  if (href?.indexOf('#') === 0) {
    href = href.replace(/^#/, `#${SafeMarkdown.clobberPrefix || ''}`);
    target = '_self';
  }

  // Add rel attribute for _blank
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  return <a {...props} href={href} rel={rel} target={target} />;
};

/**
 * Custom component for rendering a `<table>` and its child elements with
 * custom styling.
 */
const PackageMarkdownTable = styled.table`
  border: 1px solid ${props => normalizeColor('border-default', props.theme)};
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  width: 100%;

  thead {
    background-color: ${props => normalizeColor('background', props.theme)};
    margin: 0;
    td,
    th {
      border-bottom: 1px solid
        ${props => normalizeColor('border-default', props.theme)};
      font-weight: bold;
      text-align: left;
    }
  }

  td,
  th {
    padding: 8px;
  }
`;

/**
 * Custom component for rendering a Markdown code block with custom styling.
 */
const PackageMarkdownCodeBlock = styled.pre`
  background-color: ${props => normalizeColor('background', props.theme)};
  border: 1px solid ${props => normalizeColor('border-default', props.theme)};
  padding: 16px;
  border-radius: 4px;
  overflow-y: auto;
`;

/**
 * Custom component for rendering Markdown blockquotes with custom styling.
 */
const PackageMarkdownBlockquote = styled.blockquote`
  background-color: ${props => normalizeColor('background', props.theme)};
  border: 1px solid ${props => normalizeColor('border-default', props.theme)};
  border-left: 8px solid
    ${props => normalizeColor('border-default', props.theme)};
  padding: 0 16px;
  margin-left: 0;
  margin-right: 0;

  // Styling for nested blockquotes
  > blockquote {
    margin: 8px;
  }
`;

/**
 * Custom component for styling `<img>`s in package markdown content.
 */
const PackageMarkdownImage = styled(Image)`
  /* Ensure that large images shrink to fit their container */
  max-width: 100%;
`;

/**
 * Overrides the default list style type on LI element to none.
 */
const PackageMarkdownCheckbox = styled.li`
  ${props => {
    if (props.className?.includes('task-list-item')) {
      return css`
        list-style-type: none;
      `;
    }
    return '';
  }}
`;

/**
 * Renders a progress bar based on a progress bar directive in markdown.
 */
const PackageMarkdownProgressBar: React.FC<JSX.IntrinsicElements['div']> =
  props => {
    const thickness = props['data-thin'] === 'true' ? 'small' : 'medium';

    return (
      <Box align="center">
        <Stack anchor="center">
          <Meter
            color="dark-green"
            size="large"
            thickness={thickness}
            type="bar"
            value={Number(props['data-value'])}
          />
          <Box align="center" direction="row" pad={{ bottom: 'xsmall' }}>
            <Text size="small" weight="bold">
              {props['data-label']}
            </Text>
          </Box>
        </Stack>
      </Box>
    );
  };

/**
 * The names of directives that represent markdown admonitions.
 */
const defaultAdmonitionTitles: Record<MarkdownAdmonitionDirectiveName, string> =
  {
    note: 'Note',
    warning: 'Warning',
    danger: 'Attention',
  };

const PackageMarkdownAdmonition: React.FC<JSX.IntrinsicElements['div']> =
  props => {
    let statusType: AlertStatus;

    switch (props['data-type']) {
      case 'note': {
        statusType = 'info';
        break;
      }
      case 'warning': {
        statusType = 'warning';
        break;
      }
      case 'danger': {
        statusType = 'error';
        break;
      }
      default: {
        return <div />;
      }
    }

    const dataTitle = props['data-title'];
    const title: string =
      typeof dataTitle === 'string'
        ? dataTitle.trim()
        : defaultAdmonitionTitles[props['data-type']];

    return (
      <Alert
        collapsible={props['data-collapsible']}
        icon={true}
        margin={{ vertical: 'small' }}
        status={statusType}
        title={title}
      >
        {props.children}
      </Alert>
    );
  };

/**
 * Delegates to the appropriate component to handle a `div` element in the
 * markdown.
 */
const PackageMarkdownDiv = (props: JSX.IntrinsicElements['div']) => {
  switch (props['data-component']) {
    case 'progress': {
      return <PackageMarkdownProgressBar {...props} />;
    }
    case 'admonition': {
      return <PackageMarkdownAdmonition {...props} />;
    }
    default: {
      return <div {...props} />;
    }
  }
};

type HeadingProps = PropsOf<typeof Heading>;

/**
 * Given the level of a header tag, returns grommet component for the header tag.
 */
const PackageMarkdownHeading =
  (headingLevel: HeadingProps['level']) => (props: PropsOf<typeof Heading>) => {
    return <Heading level={headingLevel} {...props} />;
  };

const sanitizeSchema: SafeMarkdownProps['sanitizeSchema'] = {
  ...SafeMarkdown.defaultSanitizeSchema,
  attributes: {
    ...SafeMarkdown.defaultSanitizeSchema.attributes,

    img: [
      ...(SafeMarkdown.defaultSanitizeSchema.attributes?.img ?? []),
      'style', // Allow `style` on img tags in markdown
    ],

    span: [
      ...(SafeMarkdown.defaultSanitizeSchema.attributes?.span ?? []),
      'style', // Allow `style` on span tags in markdown
    ],
  },
};

/**
 * Renders markdown text using custom rules for displaying markdown for Voleer
 * packages.
 */
export const PackageMarkdown: React.FC<PackageMarkdownProps> = ({
  content,
  components,
  ...props
}) => {
  return (
    <SafeMarkdown
      components={{
        table: PackageMarkdownTable,
        a: PackageMarkdownAnchor,
        img: PackageMarkdownImage,
        pre: PackageMarkdownCodeBlock,
        blockquote: PackageMarkdownBlockquote,
        li: PackageMarkdownCheckbox,
        div: PackageMarkdownDiv,
        h1: PackageMarkdownHeading(1),
        h2: PackageMarkdownHeading(2),
        h3: PackageMarkdownHeading(3),
        h4: PackageMarkdownHeading(4),
        h5: PackageMarkdownHeading(5),
        h6: PackageMarkdownHeading(6),
        ...components,
      }}
      content={content}
      sanitizeSchema={sanitizeSchema}
      {...props}
    />
  );
};
