import React, { useContext } from 'react';
import { VoleerTextSize, VoleerTheme } from '@voleer/ui-kit';
import { isPresent } from '@voleer/validators';
import { Box, Text } from 'grommet';
import { ThemeContext } from 'styled-components';

export type TextAvatarProps = {
  name: string;
  size?: string;
  fontSize?: VoleerTextSize | string;
  color?: string;
  tooltip?: string;
};

const unicodeWord = /^\p{L}+$/u;
const isUnicode = (word: string) => unicodeWord.test(word);

const SATURATION = '80%';
const LIGHTNESS = '28%';

const stringToHslColor = (str: string) => {
  const hash = str.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  let hue = hash % 360;

  // Rotate the hue to be a positive number. Negative hues are fine in CSS but
  // don't seem to play well with Grommet's colorIsDark utility, which returns
  // undefined if the hue is negative.
  if (hue < 0) {
    hue = hue + 360;
  }

  return `hsl(${hue}, ${SATURATION}, ${LIGHTNESS})`;
};

/**
 * Renders a text-based avatar using the given name to extract 2 initials.
 *
 * Example:
 * "Silicon Pacific" will render "SC"
 * "Voleer" will render "V"
 */
export const TextAvatar: React.FC<TextAvatarProps> = ({
  name,
  size = 'medium',
  color,
  fontSize = 'small',
  tooltip,
}) => {
  const theme = useContext<VoleerTheme>(ThemeContext);

  const initials = name
    .trim()
    .split(/\s/)
    .filter(isPresent)
    .map(word => word.charAt(0))
    .filter(isUnicode)
    .slice(0, 2)
    .join('')
    .toUpperCase();

  const backgroundColor = color || stringToHslColor(name);

  const themeSize = theme?.icon?.size?.[size];

  return (
    <Box
      background={backgroundColor}
      flex={{ shrink: 0 }}
      height={themeSize || size}
      justify="center"
      round="xxsmall"
      title={tooltip}
      width={themeSize || size}
    >
      <Text size={fontSize} textAlign="center" weight="bold">
        {initials}
      </Text>
    </Box>
  );
};
