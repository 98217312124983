import React from 'react';
import { Box, Image, Text } from 'grommet';
import styled, { css } from 'styled-components';

/**
 * Prop types for the `EmptyStateImage` component.
 */
export type EmptyStateImageProps = {
  /**
   * The img src.
   */
  src: string;

  /**
   * The message displayed underneath the image.
   */
  text: string;

  /**
   * The message displayed underneath the `text`.
   */
  subText?: string;
};

const StyledImage = styled(Image)`
  ${props => css`
    border-radius: ${props.theme.global.edgeSize.xsmall};
    width: 256px;
  `}
`;

/**
 * Renders an empty state image with the given text underneath.
 */
export const EmptyStateImage: React.FC<EmptyStateImageProps> = props => {
  const { src, text, subText } = props;
  return (
    <Box align="center" fill="vertical" justify="center" pad="medium">
      <StyledImage src={src} />

      <Text margin={{ vertical: 'small' }} size="xlarge" textAlign="center">
        {text}
      </Text>

      {subText && (
        <Text color="dark-2" size="large" textAlign="center">
          {subText}
        </Text>
      )}
    </Box>
  );
};
