import React from 'react';
import { TextAvatar } from '@common/components';
import { useDateFormat } from '@common/hooks';
import { useWorkflowTranslations } from '@features/workflows';
import {
  DataSet,
  ScheduledJob,
  TenantMember,
} from '@generated/graphql-code-generator';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

type LaunchedByProp =
  | Pick<DataSet, '__typename' | 'id'>
  | Pick<ScheduledJob, '__typename' | 'id'>
  | Pick<TenantMember, '__typename' | 'firstName' | 'isActive' | 'lastName'>;

export type InstanceLaunchedOnProps = {
  launchedBy?: LaunchedByProp | null;
  launchedOn: Date;
};

/**
 * Component that render the information about when the instance was launched as
 * well as the entity who launched it if provided.
 */
export const InstanceLaunchedOn: React.FC<InstanceLaunchedOnProps> = ({
  launchedBy,
  launchedOn,
}) => {
  const [format, { DateTimeFormat }] = useDateFormat();

  const [t] = useTranslation(
    'features/workflows/components/InstanceLaunchedOn'
  );
  const { getLaunchedByName } = useWorkflowTranslations();

  const formattedLaunchedOn = format(
    launchedOn,
    DateTimeFormat.AbbreviatedHumanized
  );

  const userName = getLaunchedByName(launchedBy);

  const isUserRemoved =
    launchedBy?.__typename === 'TenantMember' && !launchedBy.isActive;

  const isLaunchedByScheduledJob =
    launchedBy?.__typename === 'DataSet' ||
    launchedBy?.__typename === 'ScheduledJob';

  return (
    <Box align="center" direction="row" gap="xsmall">
      {!!userName && !isLaunchedByScheduledJob && (
        <TextAvatar
          fontSize="10px"
          name={userName}
          size="large"
          tooltip={isUserRemoved ? t('user-removed', { userName }) : userName}
        />
      )}

      <Text color="active-text" size="small">
        {!!userName &&
          !isLaunchedByScheduledJob &&
          t('launched-on.with-username', {
            launchedOn: formattedLaunchedOn,
            userName,
          })}

        {(!userName || isLaunchedByScheduledJob) &&
          t('launched-on.without-username', {
            launchedOn: formattedLaunchedOn,
          })}
      </Text>
    </Box>
  );
};
