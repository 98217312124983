import React, { useMemo } from 'react';
import { AppLayout } from '@common/components';
import { urlFor } from '@common/utils';
import {
  InstanceLaunchedOn,
  isSaveableTemplateConfiguration,
  isTemplateConfigurationDeleted,
} from '@features/workflows';
import {
  TemplateConfiguration,
  TemplateMetadata,
  TemplateMetadataType,
  WorkflowInstanceViewInstanceFragment,
} from '@generated/graphql-code-generator';
import { ensureUnreachable } from '@voleer/types';
import { Link } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Text } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Prop types for the `Subheading` component.
 */
type SubheadingProps = Readonly<{
  instance: WorkflowInstanceViewInstanceFragment | null | undefined;
  templateConfiguration:
    | (Pick<
        TemplateConfiguration,
        'displayName' | 'exposureLevel' | 'id' | 'status'
      > & {
        templateMetadata?: Pick<TemplateMetadata, 'type'> | null;
      })
    | null
    | undefined;
  workspaceId: string;
}>;

/**
 * Renders the page content subheading in the `WorkflowInstancePage`.
 */
export const Subheading: React.FC<SubheadingProps> = ({
  instance,
  templateConfiguration,
  workspaceId,
}) => {
  const [t] = useTranslation('pages/WorkflowInstancePage');

  // Determine UI elements based on the template metadata type
  const { viewConfigurationLink } = useMemo(() => {
    let configurationUrl = '';
    let viewConfigurationLink = null;

    if (
      !!templateConfiguration?.templateMetadata?.type &&
      !!templateConfiguration?.id &&
      !!workspaceId
    ) {
      switch (templateConfiguration.templateMetadata.type) {
        case TemplateMetadataType.Automation:
          configurationUrl = urlFor('templateConfiguration')({
            templateConfigurationId: templateConfiguration.id,
            workspaceId,
          });
          viewConfigurationLink = !isSaveableTemplateConfiguration(
            templateConfiguration
          ) && (
            <Trans i18nKey="subheading.view-template-configuration" t={t}>
              <>Template Configuration: </>
              {isTemplateConfigurationDeleted(templateConfiguration.status) ? (
                <Text data-testid="subheading__config-text--automation">
                  {{
                    templateConfigurationDisplayName:
                      templateConfiguration.displayName,
                  }}
                </Text>
              ) : (
                <Link
                  data-testid="subheading__config-link--automation"
                  to={configurationUrl}
                  variation="unstyled"
                >
                  {{
                    templateConfigurationDisplayName:
                      templateConfiguration.displayName,
                  }}
                </Link>
              )}
              )
            </Trans>
          );
          break;

        case TemplateMetadataType.DataSet:
          configurationUrl = urlFor('dataSet')({
            dataSetId: templateConfiguration.id,
            workspaceId,
          });
          viewConfigurationLink = (
            <Trans i18nKey="subheading.view-dataset" t={t}>
              <>Dataset: </>
              {isTemplateConfigurationDeleted(templateConfiguration.status) ? (
                <Text data-testid="subheading__config-text--dataset">
                  {{
                    templateConfigurationDisplayName:
                      templateConfiguration.displayName,
                  }}
                </Text>
              ) : (
                <Link
                  data-testid="subheading__config-link--dataset"
                  to={configurationUrl}
                  variation="unstyled"
                >
                  {{
                    dataSetDisplayName: templateConfiguration.displayName,
                  }}
                </Link>
              )}
              )
            </Trans>
          );
          break;

        case TemplateMetadataType.Unknown:
          break;

        default:
          ensureUnreachable(templateConfiguration.templateMetadata.type);
          break;
      }
    }

    return {
      configurationUrl,
      viewConfigurationLink,
    };
  }, [t, templateConfiguration, workspaceId]);

  if (!instance) return null;

  return (
    <AppLayout.Subheading>
      <InstanceLaunchedOn
        launchedBy={instance.launchedBy}
        launchedOn={parseISO(instance.createdOn)}
      />

      {!!viewConfigurationLink && (
        <Text color="active-text" margin={{ left: 'auto' }}>
          {viewConfigurationLink}
        </Text>
      )}
    </AppLayout.Subheading>
  );
};
