import React from 'react';
import { TextAvatar } from '@common/components';
import { urlFor } from '@common/utils';
import { useTypedFlags } from '@features/launch-darkly';
import { TenantMember } from '@generated/graphql-code-generator';
import { useOidc } from '@voleer/react-oidc-client';
import { DropButton, DropButtonItem, Link } from '@voleer/ui-kit';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';

export type UserBladeProps = {
  tenantMember: Pick<
    TenantMember,
    'emailAddress' | 'firstName' | 'lastName' | 'role'
  >;
};

/**
 * Renders a Blade with users information that allow access to the user settings
 * when clicked on.
 *
 * It is meant to be used in the LeftNavigation component.
 */
export const UserBlade: React.FC<UserBladeProps> = props => {
  const [t] = useTranslation('components/UserBlade');

  const { notifications } = useTypedFlags();

  const { tenantMember } = props;

  const userName = tenantMember.lastName
    ? `${tenantMember.firstName} ${tenantMember.lastName}`
    : tenantMember.emailAddress;
  const { manager } = useOidc();
  const signOut = async () => await manager.signoutRedirect();

  // Display the User role on the blade
  const userRole = t(`user-role.${tenantMember.role.toLowerCase()}`);

  return (
    <Box border={{ side: 'top', color: 'dark-2' }} pad={{ vertical: 'small' }}>
      <DropButton
        dropAlign={{
          top: 'top',
          left: 'right',
        }}
        dropProps={{
          plain: true,
        }}
        label={
          <Box
            align="center"
            direction="row"
            gap="small"
            pad={{ horizontal: 'small' }}
          >
            <Box
              flex={{ grow: 0 }}
              justify="center"
              margin={{ vertical: 'xxsmall' }}
            >
              <TextAvatar
                fontSize="medium"
                name={userName}
                size="xlarge"
                tooltip={userName}
              />
            </Box>
            <Box align="start" flex={{ grow: 1 }} justify="center">
              <Text color="white" truncate={true}>
                {userName}
              </Text>
              <Text color="light-5" margin={{ top: '-2px' }} size="small">
                {userRole}
              </Text>
            </Box>
            <Text color="dark-2" margin={{ horizontal: 'xsmall' }}>
              <FaCaretRight />
            </Text>
          </Box>
        }
        plain={true}
      >
        <Box
          background="white"
          elevation="small"
          flex={{ shrink: 0 }}
          overflow="visible"
          round="xsmall"
        >
          <Link to={urlFor('userSettings')()} variation="unstyled">
            <DropButtonItem label={t('user-settings')} />
          </Link>
          {notifications && (
            <Link to={urlFor('notifications')()} variation="unstyled">
              <DropButtonItem label={t('notifications')} />
            </Link>
          )}
          <Link to={urlFor('tenantSettings')()} variation="unstyled">
            <DropButtonItem label={t('tenant-settings')} />
          </Link>
          <DropButtonItem
            border={{ side: 'top', color: 'border-default' }}
            label={<Text color="status-error">{t('sign-out')}</Text>}
            onClick={signOut}
          />
        </Box>
      </DropButton>
    </Box>
  );
};
