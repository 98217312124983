import { useState } from 'react';
import { MutationHookOptions } from '@apollo/client';
import {
  useAddScheduledJobModalQuery,
  useAddScheduledJobModalSubmitMutation,
} from '@generated/graphql-code-generator';
import { ScheduledJobFormProps } from '..';
import { AddScheduledJobModalProps } from './AddScheduledJobModal';

type UseAddScheduledJobModalOptions = {
  /**
   * The ID of the Workspace where the scheduled job is being added.
   */
  workspaceId: string;

  /**
   * Queries to refetch after saving the scheduled job.
   */
  refetchQueries?: MutationHookOptions['refetchQueries'];
};

type UseAddScheduledJobModalResult = {
  /**
   * Controls the open state of the modal.
   */
  setOpen: (open: boolean) => void;

  /**
   * Default props for passing to the modal component.
   */
  props: AddScheduledJobModalProps;
};

/**
 * Hook which provides default data, state, and props for the add scheduled job
 * modal.
 *
 * ```typescript
 * const { setOpen, props: modalProps } = useAddScheduledJobModal({ workspaceId });
 *
 * return <div>
 *   <Button label="Add Scheduled Job" onClick={() => setOpen(true)} />
 *   <AddScheduledJobModal {...modalProps} />
 * </div>
 * ```
 */
export const useAddScheduledJobModal = ({
  workspaceId,
  refetchQueries,
}: UseAddScheduledJobModalOptions): UseAddScheduledJobModalResult => {
  const [open, setOpen] = useState(false);

  const { data } = useAddScheduledJobModalQuery({
    skip: !open,
    variables: {
      workspaceId,
    },
  });

  const templateConfigurationOptions =
    data?.templateConfigurationOptions?.items;

  const [createSchedule] = useAddScheduledJobModalSubmitMutation({
    refetchQueries,
  });

  const onSubmit: ScheduledJobFormProps['onSubmit'] = async value => {
    const {
      templateConfiguration: { id: templateConfigurationId },
      endTime,
      pattern,
      startTime,
      timezoneName,
      maxRecurrenceCount,
    } = value;

    await createSchedule({
      variables: {
        input: {
          workspaceId,
          templateConfigurationId,
          startTime,
          endTime,
          pattern,
          timezoneName,
          maxRecurrenceCount,
        },
      },
    });

    setOpen(false);
  };

  const onCancel = () => setOpen(false);

  return {
    setOpen,
    props: {
      templateConfigurationOptions,
      onCancel,
      open,
      onSubmit,
    },
  };
};
