import React from 'react';
import useCopy from '@react-hook/copy';
import { Box, Button, Text, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaClipboard } from 'react-icons/fa';
import styled from 'styled-components';

/**
 * Props for ClipBox Component
 */
export type ClipBoxProps = {
  /**
   * Text to display in textbox
   */
  text: string;

  /**
   * Icon to display in textbox (if desired)
   */
  icon?: React.ReactNode;

  /**
   * Is this a password? If so it will be obfuscated.
   */
  password?: boolean;
};

const CopyLinkInput = styled(TextInput)`
  border: none;
  border-radius: none;
`;

const ClipBoxButton = styled(Button)`
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

/**
 * Renders ClipBox component
 */
export const ClipBox = ({ text, icon, password }: ClipBoxProps) => {
  const [t] = useTranslation('components/ClipBox');
  const { copied, copy } = useCopy(text);

  return (
    <>
      <Box direction="row">
        <Box
          align="center"
          border={[{ side: 'left' }, { side: 'top' }, { side: 'bottom' }]}
          direction="row"
          round={{ corner: 'left', size: '5px' }}
          width="100%"
        >
          <CopyLinkInput
            data-testid="clipbox-textbox"
            icon={
              icon ? <Box data-testid="clipbox-icon">{icon}</Box> : undefined
            }
            onFocus={event => {
              // Select text on focus
              event.target.select();
            }}
            readOnly={true}
            type={password ? 'password' : 'text'}
            value={text}
          />
        </Box>
        <ClipBoxButton
          gap="small"
          icon={<FaClipboard />}
          label={t('copy-label')}
          onClick={copy}
          primary={true}
        />
      </Box>
      <Text color="status-ok" hidden={!copied} size="small">
        {t('copied')}
      </Text>
    </>
  );
};
