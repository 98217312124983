import { tryParseInt } from '@voleer/utils';

const STAR = '*';

/**
 * Represents the type of a field in a cron expression.
 */
enum CronFieldType {
  Minute = 'minute',
  Hour = 'hour',
  DayOfMonth = 'dayOfMonth',
  Month = 'month',
  DayOfWeek = 'dayOfWeek',
}

/**
 * Represents settings for each field type in a cron expression.
 */
type CronFieldSettings = {
  /**
   * The minimum range value allowed to be specified for the field.
   */
  min: number;

  /**
   * The maximum range value allowed to be specified for the field.
   */
  max: number;
};

/**
 * Settings for each field type in a cron expression.
 */
const CRON_FIELD_SETTINGS: Record<CronFieldType, CronFieldSettings> = {
  [CronFieldType.Minute]: { min: 0, max: 59 },
  [CronFieldType.Hour]: { min: 0, max: 23 },
  [CronFieldType.DayOfMonth]: { min: 1, max: 31 },
  [CronFieldType.Month]: { min: 1, max: 12 },
  [CronFieldType.DayOfWeek]: { min: 0, max: 6 },
};

/**
 * Represents an interval-based cron expression (i.e. one using `@every`).
 */
export type CronInterval = {
  type: 'interval';
  hours?: number;
  minutes?: number;
  seconds?: number;
};

/**
 * Represents an scheduled-based cron expression (i.e. one using `* * * * *`).
 */
export type CronSchedule = {
  type: 'schedule';
  minute: CronField[];
  hour: CronField[];
  dayOfMonth: CronField[];
  month: CronField[];
  dayOfWeek: CronField[];
};

/**
 * Represents a parsed cron expression.
 */
export type CronExpression = CronInterval | CronSchedule;

/**
 * Represents the type of a parsed cron expression.
 */
export type CronExpressionType = CronExpression['type'];

/**
 * Type guard to check if the given value represents a cron schedule.
 */
export const isCronSchedule = (value?: unknown): value is CronSchedule => {
  return !!value && (value as CronSchedule).type === 'schedule';
};

/**
 * Type guard to check if the given expression represents a cron interval.
 */
export const isCronInterval = (value?: unknown): value is CronInterval => {
  return !!value && (value as CronInterval).type === 'interval';
};

/**
 * Represents the "range" part of a field in a cron expression.
 *
 * In the expression a range could be any of:
 *
 * * An asterisk: "*"
 * * A single number: "1"
 * * A range delimited by a hyphen: "0-12"
 */
type CronFieldRange = number | typeof STAR | { start: number; end: number };

/**
 * Represents a single field in a cron expression following the syntax:
 * `{range}[/{step}]`. Fields will always have a range, but the step is
 * optional.
 */
export type CronField = {
  /**
   * Represents the "range" part of the field.
   */
  range: CronFieldRange;

  /**
   * Represents the "step" part of the field.
   */
  step?: number;
};

/**
 * Type guard to check if the given value is a `CronFieldRange`.
 */
const isCronFieldRange = (value?: unknown): value is CronFieldRange => {
  const maybeRange = value as CronFieldRange;

  // Numbers are valid ranges
  if (typeof maybeRange === 'number' && !isNaN(maybeRange)) {
    return true;
  }

  // The '*' character is a valid range
  if (maybeRange === STAR) {
    return true;
  }

  // Otherwise it must be an object specifying a start and end
  return (
    typeof maybeRange === 'object' &&
    typeof maybeRange.start === 'number' &&
    typeof maybeRange.end === 'number'
  );
};

/**
 * Checks whether the given cron range data is valid for the given field type.
 */
const isValidRange = (type: CronFieldType, range: CronFieldRange): boolean => {
  const { min, max } = CRON_FIELD_SETTINGS[type];

  if (range === STAR) {
    return true;
  }

  if (typeof range === 'number') {
    return range >= min && range <= max;
  }

  return range.start >= min && range.end <= max;
};

/**
 * Checks whether the given cron field data is valid for the given field type.
 */
const isValidField = (type: CronFieldType, field: CronField): boolean => {
  const { range, step } = field;

  if (!isValidRange(type, range)) {
    return false;
  }

  if (typeof step === 'undefined') {
    return true;
  }

  return step > 0;
};

/**
 * Parses the "range" part of a field into a `CronFieldRange`.
 */
const parseCronRange = (
  type: CronFieldType,
  value: string
): CronFieldRange | undefined => {
  const { min, max } = CRON_FIELD_SETTINGS[type];

  // If the value is "*" then we can just preserve it
  if (value === STAR) {
    return STAR;
  }

  const [startPart, endPart] = value.split('-').map(s => s.trim());

  // If there is no end specified then the range is just a single digit
  if (!endPart) {
    const result = tryParseInt(startPart);
    if (typeof result !== 'number' || result < min || result > max) {
      return undefined;
    }
    return result;
  }

  const start = tryParseInt(startPart);
  const end = tryParseInt(endPart);

  // Return `undefined` when the range is invalid
  if (typeof start !== 'number' || typeof end !== 'number') {
    return undefined;
  }
  if (start < min || end > max) {
    return undefined;
  }

  return { start, end };
};

/**
 * Parses a single field in a cron schedule.
 */
const parseCronField = (
  type: CronFieldType,
  value: string
): CronField | undefined => {
  const [rangePart, stepPart] = value.split('/', 2);

  const range = parseCronRange(type, rangePart);
  if (!isCronFieldRange(range)) {
    return undefined;
  }

  const step = tryParseInt(stepPart);
  if (stepPart?.trim().length && typeof step !== 'number') {
    return undefined;
  }

  return { range, step };
};

/**
 * Parses an array of fields (separated by commas) in a cron schedule.
 */
const parseCronFields = (
  type: CronFieldType,
  value: string
): CronField[] | undefined => {
  const parts = value.split(',');

  const acc: CronField[] = [];

  for (const part of parts) {
    const field = parseCronField(type, part);
    if (!field) {
      return undefined;
    }
    acc.push(field);
  }

  return acc;
};

// RegExp for parsing the parts of a cron interval string
const PARSE_INTERVAL_PATTERN_REGEXP = /^(\d+[hms])?(\d+[hms])?(\d+[hms]){1}$/g;

// RegExp for parsing the unit parts of a cron interval
const PARSE_INTERVAL_UNIT_REGEXP = /^(\d+)([hms])$/g;

/**
 * Parses a cron interval string to data representing the interval.
 */
const parseCronInterval = (value: string): CronInterval | undefined => {
  const parts = value.trim().split('@every');
  if (parts.length !== 2) {
    return undefined;
  }

  const pattern = parts[1].trim();

  PARSE_INTERVAL_PATTERN_REGEXP.lastIndex = 0;
  if (!PARSE_INTERVAL_PATTERN_REGEXP.test(pattern)) {
    return undefined;
  }

  PARSE_INTERVAL_PATTERN_REGEXP.lastIndex = 0;
  const parsed = PARSE_INTERVAL_PATTERN_REGEXP.exec(pattern)?.slice(1);
  if (!parsed || parsed.length !== 3) {
    return undefined;
  }

  const result: CronInterval = { type: 'interval' };
  for (const part of parsed) {
    if (typeof part !== 'string') {
      continue;
    }

    PARSE_INTERVAL_UNIT_REGEXP.lastIndex = 0;
    const parsedPart = PARSE_INTERVAL_UNIT_REGEXP.exec(part)?.slice(1);
    if (!parsedPart || parsedPart.length !== 2) {
      return undefined;
    }

    const [valueText, unitText] = parsedPart;

    if (unitText === 'h') {
      if (typeof result.hours === 'number') {
        return undefined;
      }
      result.hours = tryParseInt(valueText);
    }
    if (unitText === 'm') {
      if (typeof result.minutes === 'number') {
        return undefined;
      }
      result.minutes = tryParseInt(valueText);
    }
    if (unitText === 's') {
      if (typeof result.seconds === 'number') {
        return undefined;
      }
      result.seconds = tryParseInt(valueText);
    }
  }

  if (
    typeof result.hours !== 'number' &&
    typeof result.minutes !== 'number' &&
    typeof result.seconds !== 'number'
  ) {
    return undefined;
  }

  return result;
};

/**
 * Parses a cron schedule string to data representing the schedule.
 */
const parseCronSchedule = (value: string): CronSchedule | undefined => {
  const parts = value.trim().split(/\s+/);
  if (parts.length !== 5) {
    return undefined;
  }

  const entries: Array<[CronFieldType, string]> = [
    [CronFieldType.Minute, parts[0]],
    [CronFieldType.Hour, parts[1]],
    [CronFieldType.DayOfMonth, parts[2]],
    [CronFieldType.Month, parts[3]],
    [CronFieldType.DayOfWeek, parts[4]],
  ];

  const acc: CronSchedule = { type: 'schedule' } as CronSchedule;
  for (const [type, part] of entries) {
    if (!part) {
      return undefined;
    }

    const field = parseCronFields(type, part);
    if (!field) {
      return undefined;
    }

    acc[type] = field;
  }

  return acc;
};

/**
 * Formats an array of cron fields.
 */
const formatCronFields = (
  type: CronFieldType,
  fields: CronField[]
): string | undefined => {
  const acc: string[] = [];

  for (const field of fields) {
    if (!isValidField(type, field)) {
      return undefined;
    }

    const { range, step } = field;

    let rangePart: string;
    if (range === STAR) {
      rangePart = STAR;
    } else if (typeof range === 'number') {
      rangePart = String(range);
    } else {
      rangePart = `${range.start}-${range.end}`;
    }

    const stepPart = step ? [String(step)] : [];

    const result = [rangePart, ...stepPart].join('/');
    acc.push(result);
  }

  return acc.join(',');
};

/**
 * Converts cron interval data to a cron string.
 */
const formatCronInterval = (interval: CronInterval): string | undefined => {
  const parts = [];
  if (typeof interval.hours === 'number') {
    parts.push(`${interval.hours}h`);
  }

  if (typeof interval.minutes === 'number') {
    parts.push(`${interval.minutes}m`);
  }

  if (typeof interval.seconds === 'number') {
    parts.push(`${interval.seconds}s`);
  }

  if (!parts.length) {
    return undefined;
  }

  return `@every ${parts.join('')}`;
};

/**
 * Converts cron schedule data to a cron string.
 */
const formatCronSchedule = (schedule: CronSchedule) => {
  const entries = [
    [CronFieldType.Minute, schedule.minute],
    [CronFieldType.Hour, schedule.hour],
    [CronFieldType.DayOfMonth, schedule.dayOfMonth],
    [CronFieldType.Month, schedule.month],
    [CronFieldType.DayOfWeek, schedule.dayOfWeek],
  ] as const;

  const acc: string[] = [];

  for (const [type, fields] of entries) {
    if (!fields.length) {
      return undefined;
    }

    const formatted = formatCronFields(type, fields);
    if (typeof formatted !== 'string') {
      return undefined;
    }

    acc.push(formatted);
  }

  return acc.join(' ');
};

/**
 * Parses a cron expression string to data representing the expression.
 *
 * Returns `undefined` if the given value does not represent a cron expression
 * or is invalid.
 */
export const parseCronExpression = (
  value: string
): CronExpression | undefined => {
  value = value.trim();

  if (value.startsWith('@every')) {
    return parseCronInterval(value);
  }

  return parseCronSchedule(value);
};

/**
 * Formats the given cron expression data to a cron string.
 *
 * Returns `undefined` if the given expression data does not represent a cron
 * expression or is invalid.
 */
export const formatCronExpression = (
  expression: CronExpression
): string | undefined => {
  if (isCronInterval(expression)) {
    return formatCronInterval(expression);
  }

  if (isCronSchedule(expression)) {
    return formatCronSchedule(expression);
  }

  return undefined;
};
