import React from 'react';
import { Redirect, Route, RouteProps, generatePath } from 'react-router-dom';

export type RedirectRouteProps = RouteProps & {
  to: string;
};

/**
 * Renders a `Route` that redirects from an old path to a new one.
 *
 * ```typescript
 * <Switch>
 *   <Route path="/new/:id/location" component={MyRoute} />
 *   <RedirectRoute path="/old/:id/location" to="/new/:id/location" />
 * </Switch>
 * ```
 */
export const RedirectRoute: React.FC<RedirectRouteProps> = ({
  to,
  ...props
}) => (
  <Route exact={true} {...props}>
    {({ match }) => {
      if (!match) {
        return null;
      }
      const newPath = generatePath(to, match.params);
      return <Redirect to={newPath} />;
    }}
  </Route>
);
