import React from 'react';
import { ensureUnreachable } from '@voleer/types';
import { Box, Text } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { NavLink, NavLinkProps, matchPath } from 'react-router-dom';
import styled from 'styled-components';

/**
 * The type that is used by `LeftNavigationLink` to conditionally render a
 * `NavLink` for app links and an `a` for external links.
 */
type LeftNavigationLinkType = 'app' | 'external';

/**
 * Prop types for the `LeftNavigationLink` component.
 */
type LeftNavigationLinkProps = NavLinkProps & {
  /**
   * The icon displayed next to the label on the link.
   */
  icon: React.ReactNode;

  /**
   * The text displayed on the link.
   */
  label: string;

  /**
   * Whether or not the link opens a new tab
   */
  openNewTab?: boolean;

  /**
   * The type of link. For app links, renders a `NavLink` for the react-router.
   * For external links, renders an `a` that's styled to look like a `NavLink`.
   *
   * @default 'app'
   */
  type?: LeftNavigationLinkType;
};

const LEFT_NAV_BUTTON_HEIGHT = '40px';

const AppLinkComponent = styled(NavLink)`
  ${props => {
    const isActive = matchPath(location.pathname, {
      exact: props.exact,
      path: props.to.toString(),
      strict: props.strict,
    });

    return `background-color: ${normalizeColor(
      isActive ? 'netural-4' : 'neutral-5',
      props.theme
    )};
    color: ${normalizeColor('white', props.theme)};
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: ${normalizeColor('neutral-4', props.theme)};
    }
    `;
  }}
`;

const ExternalLinkComponent = styled.a`
  ${props => {
    return `
      color: ${normalizeColor('white', props.theme)};
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: ${normalizeColor('neutral-4', props.theme)};
      }
    `;
  }}
`;

/**
 * Renders a navigation link in the left sidebar of the application layout.
 */
export const LeftNavigationLink: React.FC<LeftNavigationLinkProps> = ({
  icon,
  label,
  openNewTab,
  to,
  type = 'app',
  ...navLinkProps
}) => {
  const linkContent = (
    <Box
      align="center"
      direction="row"
      height={LEFT_NAV_BUTTON_HEIGHT}
      pad={{ vertical: 'xsmall' }}
    >
      <Box margin={{ left: '22px', right: '18px' }}>{icon}</Box>
      <Box>
        <Text>{label}</Text>
      </Box>
    </Box>
  );

  switch (type) {
    case 'app':
      return (
        <AppLinkComponent
          data-testid="left-navigation-link--app"
          rel={openNewTab ? 'noopener noreferrer' : undefined}
          target={openNewTab ? '_blank' : undefined}
          to={to.toString()}
          {...navLinkProps}
        >
          {linkContent}
        </AppLinkComponent>
      );

    case 'external':
      return (
        <ExternalLinkComponent
          data-testid="left-navigation-link--external"
          href={to.toString()}
          rel={openNewTab ? 'noopener noreferrer' : undefined}
          target={openNewTab ? '_blank' : undefined}
        >
          {linkContent}
        </ExternalLinkComponent>
      );

    default:
      ensureUnreachable(type);
      return null;
  }
};
