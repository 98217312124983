import * as semver from 'semver';
import { isEmpty } from 'validator';
import { not } from '.';

/**
 * Returns true if the string is `null` or `undefined`, otherwise returns
 * false.
 *
 * @param value
 */
export const isNullOrUndefined = (value?: unknown) => {
  return typeof value === 'undefined' || value === null;
};

/**
 * Returns false if the string is `null` or `undefined`, otherwise returns
 * true.
 *
 * @param value
 */
export const isNotNullOrUndefined = not(isNullOrUndefined);

/**
 * Returns true if the string is a valid semantic version string, otherwise
 * returns false.
 *
 * @param value
 */
export const isSemver = (value: string) =>
  typeof semver.valid(value) === 'string';

/**
 * Returns true if the string is able to construct a date string, otherwise
 * returns false.
 *
 * @param value
 */
export const isDate = (value: string) => {
  const dateVal = new Date(value);
  return dateVal.getDate() ? true : false;
};

/**
 * Returns false when the string is `null`, `undefined` or whitespace-only,
 * otherwise returns true.
 *
 * @param value
 */
export const isPresent = (value?: string | null): value is string => {
  if (typeof value !== 'string') {
    return false;
  }
  return !isEmpty(value, {
    ignore_whitespace: true,
  });
};

type NonEmptyArray<T> = [T, ...T[]];

/**
 * Returns true if the value is an array with at least one element, otherwise
 * returns false.
 */
export const isNonEmptyArray = <T>(
  value?: Array<T> | null
): value is NonEmptyArray<T> => {
  return Array.isArray(value) && value.length > 0;
};

/**
 * Builds a predicate that returns true if the value is numeric and it is
 * greater than the provided number.
 *
 * ```typescript
 * const isGreaterThanZero = isGreaterThan(0);
 * isGreaterThanZero('1'); // true
 * isGreaterThanZero('-1'); // false
 * ```
 */
export const isGreaterThan =
  (number: number) =>
  (value?: number | string | null): value is number | string => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    const parsed = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(parsed)) {
      return false;
    }

    return parsed > number;
  };

/**
 * Builds a predicate that returns true if the value is numeric and it is
 * greater than or equal to the provided number.
 *
 * ```typescript
 * const isGreaterThanOrEqualToZero = isGreaterThanOrEqualTo(0);
 * isGreaterThanOrEqualToZero('1'); // true
 * isGreaterThanOrEqualToZero('0'); // true
 * isGreaterThanOrEqualToZero('-1'); // false
 * ```
 */
export const isGreaterThanOrEqualTo =
  (number: number) =>
  (value?: number | string | null): value is number | string => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    const parsed = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(parsed)) {
      return false;
    }

    return parsed >= number;
  };

/**
 * Builds a predicate that returns true if the value string's length is
 * greater than or equal to the provided number.
 *
 * ```typescript
 *  const lengthGreaterThanOrEqualToTen = lengthGreaterThanOrEqualTo(10);
 *  lengthGreaterThanOrEqualToTen("Happy Robot"); // true
 *  lengthGreaterThanOrEqualToTen("Sad Robot"); // false
 * ```
 */
export const lengthGreaterThanOrEqualTo =
  (number: number) =>
  (value?: string | null): value is string => {
    if (typeof value !== 'string') {
      return false;
    }

    return value.length >= number;
  };

/**
 * Builds a predicate that returns true if the value is numeric and it is
 * less than the provided number.
 *
 * ```typescript
 * const isLessThanTen = isLessThan(10);
 * isLessThanTen('1'); // true
 * isLessThanTen('10'); // false
 * ```
 */
export const isLessThan =
  (number: number) =>
  (value?: number | string | null): value is number | string => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    const parsed = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(parsed)) {
      return false;
    }

    return parsed < number;
  };

/**
 * Builds a predicate that returns true if the value is numeric and it is
 * less than the provided number.
 *
 * ```typescript
 * const isLessThanOrEqualToTen = isLessThanOrEqualTo(10);
 * isLessThanOrEqualToTen('1'); // true
 * isLessThanOrEqualToTen('10'); // true
 * isLessThanOrEqualtoTen('11'); // false
 * ```
 */
export const isLessThanOrEqualTo =
  (number: number) =>
  (value?: number | string | null): value is number | string => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    const parsed = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(parsed)) {
      return false;
    }

    return parsed <= number;
  };

/**
 * Builds a predicate that returns true if the value string's length is
 * less than or equal to the provided number.
 *
 * ```typescript
 * const isLengthLessThanOrEqualToTen = lengthLessThanOrEqualTo(10);
 * isLengthLessThanOrEqualToTen('Sad Robot'); // true
 * isLengthLessThanOrEqualToTen('Happy Robot'); // false
 * ```
 */
export const lengthLessThanOrEqualTo =
  (number: number) =>
  (value?: string | null): value is string => {
    if (typeof value !== 'string') {
      return false;
    }

    return value.length <= number;
  };

/**
 * Builds a predicate that returns true if the value is a string and it
 * matches the given regex string pattern.
 *
 * ```typescript
 * matchesRegex('[0-9]+')("10"); // true
 * matchesRegex('[a-z]+')("10"); // false
 * ```
 */
export const matchesRegex =
  (regex: RegExp | string) =>
  (value?: string | null): value is string => {
    if (typeof value !== 'string') {
      return false;
    }
    const regexPattern = new RegExp(regex);
    return value.match(regexPattern) ? true : false;
  };

/**
 * Builds a predicate that returns true if any substring from an array
 * of substrings is present in the field value.
 *
 * ```typescript
 * const containsApplesOrOranges = containsSubstrings(['apples', 'oranges']);
 * containsApplesOrOranges("I have lots of apples!")   // true
 * containsApplesOrOranges("I've had a rough Sunday.") // false
 * ```
 */
export const containsSubstrings =
  (substrings: string[]) =>
  (value?: string | null): value is string => {
    return substrings.some(substring => value?.includes(substring));
  };

/**
 * Builds a predicate that returns true if the given value is null or undefined.
 *
 * ```typescript
 * const isNull = isNullorUndefined(null) //true
 * const isUndefined = isNullorUndefined(undefined) //true
 * const isStringNull = isNullorUndefined("Example") //false
 * ```
 */
export const isNullorUndefined = (value: unknown) => {
  return value === null || value === undefined;
};

/**
 * Builds a predicate that returns true if a field date value is
 * after a given date.
 *
 * ```typescript
 * const today = new Date();
 * const janFirst1970GMT = new Date(0);
 *
 * const isDateAfter = isDateAfter(today)(janFirst1970GMT) //true
 * ```
 */
export const isDateAfter =
  (afterDate: Date) =>
  (value?: Date | null): value is Date => {
    if (value) {
      return value.getTime() >= afterDate.getTime();
    }
    return false;
  };

/**
 *  Builds a predicate that returns true if a field date value is
 *  before a given date.
 *
 *```typescript
 * const today = new Date();
 * const janFirst1970GMT = new Date(0);
 *
 * const isDateBefore = isDateBefore(janFirst1970GMT)(today) //true
 * ```
 */
export const isDateBefore =
  (beforeDate: Date) =>
  (value?: Date | null): value is Date => {
    if (value) {
      return value.getTime() <= beforeDate.getTime();
    }
    return false;
  };
