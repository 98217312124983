import React, { FormEventHandler } from 'react';
import { PropsOf } from '@voleer/types';
import { GhostButton, Icon } from '@voleer/ui-kit';
import { Box, Button, Paragraph, Text, TextArea } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaPencilAlt, FaTimes } from 'react-icons/fa';

/**
 * Prop types for the `TextAreaDescription` component.
 */
export type TextAreaDescriptionProps = Readonly<
  PropsOf<typeof TextArea> & {
    /**
     * Whether or not the user is editing the text.
     */
    editing: boolean;

    /**
     * Callback for changing the value of the text area.
     */
    onChange: (
      event: Pick<React.ChangeEvent<HTMLTextAreaElement>, 'target'>
    ) => void;

    /**
     * Callback for canceling out of the text area.
     */
    onClickCancel: () => void;

    /**
     * Callback for clicking the description.
     */
    onClickDescription: () => void;

    /**
     * Callback for saving the new text.
     */
    onClickSave: (event: Pick<React.FormEvent, 'preventDefault'>) => void;

    /**
     * Whether or not the changes are being saved.
     */
    saving: boolean;
  }
>;

/**
 * Renders an inline-editable description.
 */
export const TextAreaDescription: React.FC<TextAreaDescriptionProps> =
  props => {
    const [t] = useTranslation('components/TextAreaDescription');

    const onSubmit: FormEventHandler = e => {
      // Prevent the submit event from bubbling up to any containing form elements
      e.stopPropagation();
      props.onClickSave(e);
    };

    if (!props.value && !props.editing) {
      return (
        <Box align="center" direction="row" margin={{ top: 'small' }}>
          <Text color="text-xweak" onClick={props.onClickDescription}>
            <em>{t('click-to-add')}</em>
          </Text>

          <Box margin={{ left: 'small' }}>
            <GhostButton
              icon={<Icon icon={FaPencilAlt} size="small" />}
              onClick={props.onClickDescription}
              title={t('edit')}
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box margin={{ top: 'small' }}>
        {!props.editing && (
          <Box align="center" direction="row">
            <Paragraph margin="none" onClick={props.onClickDescription}>
              {props.value}
            </Paragraph>
            <Box margin={{ left: 'small' }}>
              <GhostButton
                icon={<Icon icon={FaPencilAlt} size="small" />}
                onClick={props.onClickDescription}
                title={t('edit')}
              />
            </Box>
          </Box>
        )}

        {props.editing && (
          <form onSubmit={onSubmit}>
            <Box direction="row">
              <TextArea
                autoFocus={true}
                onChange={props.onChange}
                placeholder={t('placeholder')}
                value={props.value}
              />
              <Button
                data-testid="text-input-heading__action-btn--save"
                disabled={props.saving}
                icon={
                  <Icon
                    color={normalizeColor('white', props)}
                    icon={FaCheck}
                    size="medium"
                  />
                }
                margin={{ left: 'small' }}
                primary={true}
                title={t('save')}
                type="submit"
              />
              <Button
                data-testid="text-input-heading__action-btn--cancel"
                disabled={props.saving}
                icon={<Icon icon={FaTimes} size="medium" />}
                margin={{ left: 'small' }}
                onClick={props.onClickCancel}
                title={t('cancel')}
                type="button"
              />
            </Box>
          </form>
        )}
      </Box>
    );
  };
