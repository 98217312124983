import {
  FormCheckboxFragment,
  FormColumnsFragment,
  FormMultiSelectFragment,
  FormSelectFragment,
  FormTextAreaFragment,
  FormTextInputFragment,
} from '@generated/graphql-code-generator';
import { UnreachableCaseError, ensureUnreachable } from '@voleer/types';
import { FastFieldAttributes, getIn } from 'formik';
import { FormComponentFragment, FormFieldFragment, FormFragment } from '.';

/**
 * Checks if the given value is a workflow form.
 */
const isForm = (value: unknown): value is FormFragment => {
  if (!value) {
    return false;
  }
  const maybeFormFragment = value as FormFragment;
  return (
    maybeFormFragment.__typename === 'Form' &&
    Array.isArray(maybeFormFragment.components)
  );
};

/**
 * Checks if the given value is a workflow form field component.
 */
const isField = (value?: FormComponentFragment): value is FormFieldFragment => {
  if (!value || !value.__typename) {
    return false;
  }
  switch (value.__typename) {
    case 'FormCheckbox':
    case 'FormDateInput':
    case 'FormDateTimeInput':
    case 'FormEmailInput':
    case 'FormFileInput':
    case 'FormIntegration':
    case 'FormMultiSelect':
    case 'FormNumericInput':
    case 'FormSelect':
    case 'FormTextArea':
    case 'FormTextInput':
    case 'FormTimeInput':
    case 'FormUrlInput':
      return true;
    case 'FormColumns':
    case 'FormLink':
    case 'FormParagraph':
      return false;
    default:
      throw new UnreachableCaseError(value);
  }
};

/**
 * Checks if the given value is a form select component.
 */
export const isFormSelect = (
  value?: FormComponentFragment
): value is FormSelectFragment => {
  return value?.__typename === 'FormSelect';
};

/**
 * Checks if the given value is a form multi-select component.
 */
export const isFormMultiSelect = (
  value?: FormComponentFragment
): value is FormMultiSelectFragment => {
  return value?.__typename === 'FormMultiSelect';
};

/**
 * Checks if the given value is a form checkbox component.
 */
export const isFormCheckbox = (
  value?: FormComponentFragment
): value is FormCheckboxFragment => {
  return value?.__typename === 'FormCheckbox';
};

/**
 * Checks if the given value is a form input component.
 */
export const isTextInput = (
  value?: FormComponentFragment
): value is FormTextAreaFragment | FormTextInputFragment => {
  switch (value?.__typename) {
    case 'FormTextArea':
    case 'FormTextInput':
    case 'FormEmailInput':
    case 'FormNumericInput':
    case 'FormUrlInput':
      return true;
    default:
      return false;
  }
};

/**
 * Represents any `FormFieldFragment` with a `required` property.
 */
type RequirableFormFieldFragment = Extract<
  FormFieldFragment,
  { required: boolean }
>;

/**
 * Checks if the given form component can be made required.
 */
export const isRequireable = (
  value?: FormComponentFragment
): value is RequirableFormFieldFragment => {
  if (!value) {
    return false;
  }

  const maybeRequirable = value as RequirableFormFieldFragment;
  switch (maybeRequirable.__typename) {
    case 'FormDateInput':
    case 'FormDateTimeInput':
    case 'FormEmailInput':
    case 'FormFileInput':
    case 'FormIntegration':
    case 'FormMultiSelect':
    case 'FormNumericInput':
    case 'FormSelect':
    case 'FormTextArea':
    case 'FormTextInput':
    case 'FormTimeInput':
    case 'FormUrlInput':
      return true;
    default: {
      ensureUnreachable(maybeRequirable);
      return false;
    }
  }
};

/**
 * Represents any `FormFieldFragment` with a `mask` property.
 */
type MaskableFormFieldFragment = Extract<FormFieldFragment, { mask: boolean }>;

/**
 * Checks if the given value is a form field that has a `mask` property.
 */
export const isMaskableField = (
  field: FormFieldFragment
): field is MaskableFormFieldFragment => {
  return Object.prototype.hasOwnProperty.call(field, 'mask');
};

/**
 * Checks if the given value is a Columns definition.
 */
const isColumns = (
  value: FormComponentFragment
): value is FormColumnsFragment => {
  return value.__typename === 'FormColumns';
};

/**
 * Extracts all fields present in the form into a flat array.
 */
export const fieldsOf = (
  formOrComponent: FormComponentFragment | FormFragment
): FormFieldFragment[] => {
  // Find fields in a form
  if (isForm(formOrComponent)) {
    return formOrComponent.components.flatMap(fieldsOf);
  }

  // Find fields nested in columns
  if (isColumns(formOrComponent)) {
    return formOrComponent.columns.flatMap(column =>
      column.components.flatMap(fieldsOf)
    );
  }

  // If we found a field, return it
  if (isField(formOrComponent)) {
    return [formOrComponent];
  }

  // If we found a non-field component, skip it
  if (formOrComponent.__typename === 'FormLink') {
    return [];
  }
  if (formOrComponent.__typename === 'FormParagraph') {
    return [];
  }

  throw new UnreachableCaseError(formOrComponent);
};

/**
 * Custom comparer function for Formik `FastField` to ensure that it re-renders
 * when its props change.
 *
 * Currently `FastField` does not re-render when props passed to it change.
 * Passing this function to `FastField` as `shouldUpdate` tells `FastField` to
 * update when its props change.
 *
 * Note: This is a workaround for https://github.com/jaredpalmer/formik/issues/1188.
 * Once that issue is resolved we can remove this function.
 *
 * ```typescript
 * <FastField
 *   shouldUpdate={shouldUpdate}
 *   name="my-form-field"
 *   disabled={disabled} // Normally this would not trigger a re-render when changed
 * >
 *   // Children...
 * </FastField>
 * ```
 */
export const fastFieldShouldUpdate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nextProps: FastFieldAttributes<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentProps: FastFieldAttributes<any>
): boolean => {
  // Taken from https://github.com/jaredpalmer/formik/issues/1188#issuecomment-603463915
  return (
    nextProps.name !== currentProps.name ||
    nextProps.required !== currentProps.required ||
    nextProps.disabled !== currentProps.disabled ||
    nextProps.readOnly !== currentProps.readOnly ||
    nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
    Object.keys(nextProps).length !== Object.keys(currentProps).length ||
    getIn(nextProps.formik.values, currentProps.name) !==
      getIn(currentProps.formik.values, currentProps.name) ||
    getIn(nextProps.formik.errors, currentProps.name) !==
      getIn(currentProps.formik.errors, currentProps.name) ||
    getIn(nextProps.formik.touched, currentProps.name) !==
      getIn(currentProps.formik.touched, currentProps.name)
  );
};
