import React from 'react';
import { ClipBox } from '@common/components';
import { urlFor } from '@common/utils';
import { LibraryItemMetadataType } from '@generated/graphql-code-generator';
import { Modal, ModalProps } from '@voleer/ui-kit';
import { Box, Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { FaLink } from 'react-icons/fa';
import { getAppConfig } from '../../../../../app-config';

export type ShareLibraryItemModalProps = Pick<ModalProps, 'open'> & {
  /**
   * The display name of the item.
   */
  itemDisplayName?: string;

  /**
   * The name of the library item.
   */
  itemName: string;

  /**
   * Callback for closing the modal.
   */
  onClose: () => void;

  /**
   * The package name
   */
  packageName: string;

  /**
   * The publisher name
   */
  publisherName: string;

  /**
   * The type of the item being shared.
   */
  type: LibraryItemMetadataType;
};

/**
 * Modal for sharing an item from the library.
 */
export const ShareLibraryItemModal: React.FC<ShareLibraryItemModalProps> = ({
  onClose,
  open,
  packageName,
  publisherName,
  itemDisplayName,
  itemName,
  type,
}) => {
  const [t] = useTranslation('components/ShareLibraryItemModal');

  const displayName = itemDisplayName || itemName;

  const libraryItemUrl = urlFor('libraryItem')({
    packageName,
    publisherName,
    name: itemName,
    type,
  });

  const shareUrl = `${getAppConfig().AUTH_UI_URL}/select-site/?n=${encodeURI(
    libraryItemUrl
  )}`;

  return (
    <Modal onClickOutside={onClose} onEsc={onClose} open={open}>
      <Modal.Header>{t('heading', { displayName })}</Modal.Header>
      <Box fill={true} overflow="auto">
        <Modal.Body>
          <Box pad={{ bottom: 'small' }}>{t('body', { displayName })}</Box>
          <ClipBox icon={<FaLink />} text={shareUrl} />
        </Modal.Body>
        <Modal.Footer margin={{ top: 'large' }}>
          <Button
            data-testid="share-library-item-modal-close-button"
            label={t('close-button')}
            onClick={onClose}
          />
        </Modal.Footer>
      </Box>
    </Modal>
  );
};
