import React from 'react';
import { useDateFormat } from '@common/hooks';
import { formatDuration, urlFor } from '@common/utils';
import { InstanceStatusBadge } from '@features/workflows';
import { Instance, Maybe } from '@generated/graphql-code-generator';
import { PropsOf } from '@voleer/types';
import { Area, FancyTable, Link } from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Box, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Prop types for the `RecentRefreshesTable` component.
 */
export type RecentRefreshesTableProps = Readonly<{
  /**
   * Callback to handle loading more dataset refreshes, if applicable.
   *
   * If a callback is provided then the "Load More" section will display at the
   * end of the table, otherwise the "Load More" section will not be rendered.
   */
  onLoadMoreRecentRefreshes?: PropsOf<typeof FancyTable.LoadMore>['onLoadMore'];

  /**
   * Items for the recent-refreshes table.
   */
  recentRefreshes:
    | Maybe<
        Pick<
          Instance,
          | 'completedOn'
          | 'id'
          | 'startedOn'
          | 'status'
          | 'updatedOn'
          | 'workspaceId'
        >
      >[]
    | null
    | undefined;
}>;

/**
 * Renders the recent-refreshes table in the "Summary" tab of a dataset configuration.
 */
export const RecentRefreshesTable: React.FC<RecentRefreshesTableProps> = ({
  onLoadMoreRecentRefreshes,
  recentRefreshes,
}) => {
  const [t] = useTranslation('pages/DataSetPage');

  const [format, { DateTimeFormat }] = useDateFormat();

  return (
    <Box>
      <Heading level={3} size="medium">
        {t('recent-refreshes.title')}
      </Heading>

      {!recentRefreshes?.length && (
        <Area
          data-testid="recent-refreshes-table__empty-message"
          height="200px"
          paper={true}
        >
          <FancyTable.Empty fill={true}>
            <Text margin="auto" size="large">
              {t('recent-refreshes.no-data')}
            </Text>
          </FancyTable.Empty>
        </Area>
      )}

      {!!recentRefreshes?.length && (
        <FancyTable
          columns={[undefined, undefined, undefined]}
          data-testid="recent-refreshes-table__table"
          paper={true}
          rows={{ count: recentRefreshes.length }}
        >
          <FancyTable.Header>
            <FancyTable.Row>
              <FancyTable.HeaderCell>
                {t('recent-refreshes.table-headers.modified-on')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('recent-refreshes.table-headers.status')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('recent-refreshes.table-headers.duration')}
              </FancyTable.HeaderCell>
            </FancyTable.Row>
          </FancyTable.Header>

          <FancyTable.Body>
            {recentRefreshes.map(recentRefresh => {
              if (!recentRefresh) return null;

              const {
                completedOn,
                id,
                startedOn,
                status,
                updatedOn,
                workspaceId,
              } = recentRefresh;

              const formattedDuration =
                startedOn &&
                completedOn &&
                formatDuration(parseISO(startedOn), parseISO(completedOn));

              return (
                <FancyTable.Row key={id}>
                  <FancyTable.Cell>
                    <Link
                      data-testid="recent-refreshes-table__workflow-instance-link"
                      to={urlFor('workflowInstance')({
                        workflowInstanceId: id,
                        workspaceId,
                      })}
                      variation="primary"
                    >
                      {format(
                        parseISO(updatedOn),
                        DateTimeFormat.AbbreviatedHumanized
                      )}
                    </Link>
                  </FancyTable.Cell>

                  <FancyTable.Cell>
                    <InstanceStatusBadge
                      data-testid="recent-refreshes-table__workflow-instance-status"
                      status={status}
                    />
                  </FancyTable.Cell>

                  <FancyTable.Cell data-testid="recent-refreshes-table__workflow-instance-duration">
                    {formattedDuration}
                  </FancyTable.Cell>
                </FancyTable.Row>
              );
            })}

            {onLoadMoreRecentRefreshes && (
              <FancyTable.LoadMore
                label={t('recent-refreshes.load-more.label')}
                onLoadMore={onLoadMoreRecentRefreshes}
              />
            )}
          </FancyTable.Body>
        </FancyTable>
      )}
    </Box>
  );
};
