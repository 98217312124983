import React, { useState } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  WorkspaceFilesBrowserDocument,
  WorkspaceFilesBrowserFileDescriptorFragment,
  WorkspaceFragment,
  useDeleteWorkspaceFileMutation,
} from '@generated/graphql-code-generator';
import { Box } from 'grommet';
import { DeleteFileConfirmation, WorkspaceFilesBrowser } from './components';

type WorkspaceFilesTabProps = {
  workspace: WorkspaceFragment;
};

/**
 * Renders the Files tab in the Workspace view.
 */
export const WorkspaceFilesTab: React.FC<WorkspaceFilesTabProps> = ({
  workspace,
}) => {
  const [fileToDelete, setFileToDelete] =
    useState<WorkspaceFilesBrowserFileDescriptorFragment>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [deleteFile] = useDeleteWorkspaceFileMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    refetchQueries: [getOperationName(WorkspaceFilesBrowserDocument)!],
  });

  const handleOpenDeleteConfirmation = (
    file: WorkspaceFilesBrowserFileDescriptorFragment
  ) => {
    setShowDeleteDialog(true);
    setFileToDelete(file);
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteDialog(false);
  };

  const handleDeleteFile = () => {
    if (fileToDelete) {
      deleteFile({
        variables: { workspaceId: workspace.id, fileId: fileToDelete.id },
      });
    }
  };

  return (
    <Box fill={true} pad={{ horizontal: 'medium', top: 'medium' }}>
      <WorkspaceFilesBrowser
        onDeleteFile={handleOpenDeleteConfirmation}
        workspaceId={workspace.id}
      />
      {showDeleteDialog && fileToDelete && (
        <DeleteFileConfirmation
          fileName={fileToDelete.name}
          onCancel={handleCloseDeleteConfirmation}
          onDelete={handleDeleteFile}
        />
      )}
    </Box>
  );
};
