import React, { useMemo } from 'react';
import { LoadingOverlay } from '@common/components';
import { useFuseState } from '@common/hooks';
import { AddIntegrationModal, IntegrationIcon } from '@features/integrations';
import { useTypedFlags } from '@features/launch-darkly';
import { useIntegrationsPageAvailableTabQuery } from '@generated/graphql-code-generator';
import { Icon } from '@voleer/ui-kit';
import { Box, Grid, Heading, Paragraph, Text, TextInput } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { compact, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import Truncate from 'react-truncate';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

const SearchInput = styled(TextInput)`
  background-color: ${props => normalizeColor('white', props.theme)};
`;

/**
 * Renders the "Available" tab of the integrations page.
 */
export const AvailableTab: React.FC = () => {
  const [t] = useTranslation('pages/IntegrationsPage');

  const { 'tenant-ui-polling-configuration': pollingConfig } = useTypedFlags();

  const [integrationMetadataId, setIntegrationMetadataId] = useQueryParam(
    'integrationMetadataId',
    StringParam
  );

  const integrationMetadatasQuery = useIntegrationsPageAvailableTabQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollingConfig?.availableIntegrationsList,
    variables: {
      input: {
        names: null,
        publisherNames: null,
        packageNames: null,
        packageVersions: null,
      },
    },
  });

  const availableIntegrationsLoading = integrationMetadatasQuery.loading;
  const integrations =
    integrationMetadatasQuery.data?.integrationMetadatas?.items;
  const availableIntegrations = useMemo(() => {
    return orderBy(
      compact(integrations || []),
      integration => (integration.displayName || '').toLowerCase(),
      'asc'
    );
  }, [integrations]);

  const {
    searchResults: renderedIntegrations,
    searchTerm,
    setSearchTerm,
  } = useFuseState({
    fuseOptions: {
      keys: ['displayName', 'description'],
      matchAllTokens: true,
      shouldSort: false,
      threshold: 0.3,
      tokenize: true,
    },
    items: availableIntegrations,
  });
  const onChangeSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(event.target.value);

  return (
    <LoadingOverlay
      loading={!integrationMetadatasQuery.data && availableIntegrationsLoading}
    >
      <Box pad={{ horizontal: 'small', top: 'medium' }}>
        <SearchInput
          icon={<Icon icon={FaSearch} />}
          onChange={onChangeSearchTerm}
          placeholder={t('available-tab.search.input.placeholder')}
          value={searchTerm}
        />
      </Box>

      {!!availableIntegrations?.length && (
        <Grid
          columns="360px"
          gap="small"
          pad={{ horizontal: 'small', vertical: 'medium' }}
        >
          {renderedIntegrations.map(integration => {
            const { description, displayName, id, type } = integration;

            return (
              <Box
                background="white"
                elevation="small"
                height="252px"
                key={id}
                margin="2px"
                onClick={() => {
                  setIntegrationMetadataId(integration.id);
                }}
                pad="medium"
                role="button"
                round="xsmall"
                title={displayName}
              >
                <Box direction="row">
                  <Box flex={{ grow: 1, shrink: 1 }}>
                    <Heading
                      color="black"
                      level="4"
                      margin="none"
                      title={displayName}
                    >
                      <Truncate ellipsis="..." lines={2}>
                        {displayName}
                      </Truncate>
                    </Heading>
                  </Box>
                  <Box height="38px" round="small" width="38px">
                    <IntegrationIcon size="38px" type={type} />
                  </Box>
                </Box>
                {!!description && (
                  <Box>
                    <Paragraph color="black" size="large">
                      <Truncate ellipsis="..." lines={4}>
                        {description}
                      </Truncate>
                    </Paragraph>
                  </Box>
                )}
                <Box direction="row" margin={{ top: 'auto' }}>
                  <Box
                    border={{ color: 'brand', size: 'small' }}
                    pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    round="xsmall"
                  >
                    <Text color="brand" weight={600}>
                      {t('available-tab.learn-more')}
                    </Text>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Grid>
      )}

      {!renderedIntegrations?.length && !!searchTerm && (
        <>
          <Heading
            level="3"
            margin={{ bottom: 'small', top: 'large' }}
            textAlign="center"
          >
            {t('available-tab.search.no-results.heading')}
          </Heading>
          <Paragraph margin="0" textAlign="center">
            {t('available-tab.search.no-results.help')}
          </Paragraph>
        </>
      )}

      {integrationMetadataId && (
        <AddIntegrationModal
          integrationMetadataIds={[integrationMetadataId]}
          onClose={() => setIntegrationMetadataId(undefined)}
        />
      )}
    </LoadingOverlay>
  );
};
