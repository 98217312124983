import {
  ScheduledJob,
  TemplateConfiguration,
} from '@generated/graphql-code-generator';

export type TemplateConfigurationProp = Pick<
  TemplateConfiguration,
  'displayName' | 'id'
>;

/**
 * Represents a scheduled job value that can be passed into the form.
 */
export type ScheduledJobProp = Pick<
  ScheduledJob,
  'endTime' | 'maxRecurrenceCount' | 'pattern' | 'startTime' | 'timezoneName'
> & {
  templateConfiguration: TemplateConfigurationProp;
};

/**
 * Type of schedule being defined in the form.
 */
export enum ScheduleType {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
  months = 'months',
}

/**
 * Type of end criteria for the schedule.
 */
export type EndTypeValue = 'afterDate' | 'afterTimes' | 'never';

/**
 * Represents the form field values in the scheduled job form.
 */
export type ScheduledJobFormValues = {
  /**
   * The type of schedule being created in the form.
   */
  type: ScheduleType;

  /**
   * The timezone to use for the schedule.
   */
  timezoneName: string;

  /**
   * The template configuration selected for the schedule.
   */
  templateConfiguration?: TemplateConfigurationProp;

  /**
   * The date when the scheduled should start.
   */
  startDate: string;

  /**
   * The days of the week (0-6) to use in the resulting cron pattern.
   */
  daysOfWeek: number[];

  /**
   * The time (hour and minute) to use in the resulting cron pattern.
   */
  atTime: string;

  /**
   * The hourly interval to be used in the hourly interval cron pattern.
   */
  hourlyInterval: string;

  /**
   * The minutely interval to be used in the minutely interval cron pattern.
   */
  minutelyInterval: string;

  /**
   * The months (1-12) to use in the resulting cron pattern.
   */
  months: number[];

  /**
   * The day of the month (1-31) to to use in the resulting cron pattern.
   */
  dayOfMonth: string;

  /**
   * Type determining how/when the schedule should end.
   */
  endType: EndTypeValue;

  /**
   * Date when the schedule should stop recurring.
   */
  endDate: string;

  /**
   * Maximum number of times the schedule should recur.
   */
  maxRecurrenceCount: string;
};
