import React from 'react';
import { TemplateConfiguration } from '@generated/graphql-code-generator';
import { GrommetSelectEvent } from '@voleer/form-utils';
import { FormField, FormFieldProps, Select, SelectProps } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Represents a Template Configuration option in the `TemplateConfigurationSelector` component.
 */
export type TemplateConfigurationSelectorOption = Pick<
  TemplateConfiguration,
  'displayName' | 'id'
>;

/**
 * Prop types for the `TemplateConfigurationSelector` component.
 */
export type TemplateConfigurationSelectorProps = Readonly<
  SelectProps & {
    formFieldProps?: FormFieldProps & { 'data-testid'?: string };

    /**
     * Whether or not the rendered Select is in a loading state
     */
    loading: boolean;

    /**
     * Callback that is invoked at the end of the Select onChange handler
     */
    onAfterChange?: (
      event: GrommetSelectEvent<TemplateConfigurationSelectorOption>
    ) => void;

    /**
     * The options to be displayed in the rendered Select
     */
    options: TemplateConfigurationSelectorOption[];

    /**
     * Callback for setting the selected template configuration
     */
    setTemplateConfiguration: (
      templateConfiguration?: TemplateConfigurationSelectorOption
    ) => void;

    /**
     * The selected template configuration
     */
    templateConfiguration: TemplateConfigurationSelectorOption | undefined;
  }
>;

/**
 * Renders a select dropdown with the user's template configurations as options.
 */
export const TemplateConfigurationSelector: React.FC<TemplateConfigurationSelectorProps> =
  ({
    disabled,
    formFieldProps,
    loading,
    onAfterChange,
    options = [],
    setTemplateConfiguration,
    templateConfiguration,
    ...props
  }) => {
    const [t] = useTranslation(
      'pages/LibraryItemPage/TemplateConfigurationSelector'
    );

    const onChange = (
      event: GrommetSelectEvent<TemplateConfigurationSelectorOption>
    ) => {
      setTemplateConfiguration(event.value);
      onAfterChange && onAfterChange(event);
    };

    return (
      <FormField
        data-testid="template-configurations-form-field"
        label={t('field-label')}
        {...formFieldProps}
      >
        <Select
          data-testid="template-configuration-selector__select"
          disabled={loading || disabled}
          labelKey="displayName"
          onChange={onChange}
          options={options}
          placeholder={
            loading ? t('loading-template-configurations') : t('placeholder')
          }
          value={loading ? undefined : templateConfiguration}
          valueKey="id"
          {...props}
        />
      </FormField>
    );
  };
