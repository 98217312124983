import { tryParseZonedDate } from '@common/utils';
import { CronField, formatCronExpression } from '@voleer/cron-parse';
import { ensureUnreachable } from '@voleer/types';
import { tryParseInt } from '@voleer/utils';
import {
  ScheduleType,
  ScheduledJobFormValues,
  ScheduledJobProp,
} from './interface';
import { SCHEDULED_JOB_FORM_DATE_FORMAT, parseTimeValue } from './utils';

/**
 * Extracts scheduled job data related to end criteria from the scheduled form
 * values.
 */
const getEndValues = (formValues?: ScheduledJobFormValues) => {
  if (!formValues?.endType) {
    return undefined;
  }

  const timezoneName = formValues.timezoneName;

  const baseValues = {
    endTime: undefined,
    maxRecurrenceCount: undefined,
  };

  switch (formValues.endType) {
    case 'afterDate': {
      const parsedEndTime = tryParseZonedDate(
        timezoneName,
        SCHEDULED_JOB_FORM_DATE_FORMAT,
        formValues.endDate
      );
      return {
        ...baseValues,
        endTime: parsedEndTime?.toISOString(),
      };
    }
    case 'afterTimes': {
      return {
        ...baseValues,
        maxRecurrenceCount: tryParseInt(formValues.maxRecurrenceCount),
      };
    }
    case 'never': {
      return {
        ...baseValues,
      };
    }
    default: {
      ensureUnreachable(formValues.endType);
      return {
        ...baseValues,
      };
    }
  }
};

/**
 * Extracts scheduled job data that is common to all schedule types from the
 * scheduled form values.
 */
const getCommonValues = (formValues: ScheduledJobFormValues) => {
  const timezoneName = formValues.timezoneName;

  const parsedStartDate = tryParseZonedDate(
    timezoneName,
    SCHEDULED_JOB_FORM_DATE_FORMAT,
    formValues.startDate
  );

  const templateConfiguration = formValues.templateConfiguration;
  if (!templateConfiguration) {
    // Just throw since we expect form validations to cover this case
    throw new Error('Missing template configuration');
  }

  return {
    ...getEndValues(formValues),
    templateConfiguration,
    timezoneName,
    startTime: parsedStartDate?.toISOString(),
  };
};

/**
 * Converts form values for a monthly schedule to scheduled job data.
 */
const convertMonthlyFormValues = (
  formValues: ScheduledJobFormValues
): ScheduledJobProp | undefined => {
  if (!formValues.months.length) {
    return undefined;
  }

  const dayOfMonth = tryParseInt(formValues.dayOfMonth);
  if (!dayOfMonth) {
    return undefined;
  }

  const time = parseTimeValue(formValues.atTime);
  if (!time) {
    return undefined;
  }

  const { hour, minute } = time;

  const pattern = formatCronExpression({
    type: 'schedule',
    hour: [{ range: hour }],
    minute: [{ range: minute }],
    dayOfMonth: [{ range: dayOfMonth }],
    month: formValues.months.map<CronField>(month => ({ range: month })),
    dayOfWeek: [{ range: '*' }],
  });

  return {
    ...getCommonValues(formValues),
    pattern,
  };
};

/**
 * Converts form values for a weekly schedule to scheduled job data.
 */
const convertWeeklyFormValues = (
  formValues: ScheduledJobFormValues
): ScheduledJobProp | undefined => {
  if (!formValues.daysOfWeek.length) {
    return undefined;
  }

  const time = parseTimeValue(formValues.atTime);
  if (!time) {
    return undefined;
  }

  const { hour, minute } = time;

  const pattern = formatCronExpression({
    type: 'schedule',
    hour: [{ range: hour }],
    minute: [{ range: minute }],
    month: [{ range: '*' }],
    dayOfMonth: [{ range: '*' }],
    dayOfWeek: formValues.daysOfWeek.map<CronField>(dow => ({ range: dow })),
  });

  return {
    ...getCommonValues(formValues),
    pattern,
  };
};

/**
 * Converts form values for a daily schedule to scheduled job data.
 */
const convertDailyFormValues = (
  formValues: ScheduledJobFormValues
): ScheduledJobProp | undefined => {
  const time = parseTimeValue(formValues.atTime);
  if (!time) {
    return undefined;
  }

  const pattern = formatCronExpression({
    type: 'schedule',
    dayOfWeek: [{ range: '*' }],
    dayOfMonth: [{ range: '*' }],
    month: [{ range: '*' }],
    hour: [{ range: time.hour }],
    minute: [{ range: time.minute }],
  });

  return {
    ...getCommonValues(formValues),
    pattern,
  };
};

/**
 * Converts form values for a hourly schedule to scheduled job data.
 */
const convertHourlyFormValues = (
  formValues: ScheduledJobFormValues
): ScheduledJobProp | undefined => {
  const interval = tryParseInt(formValues.hourlyInterval);
  if (typeof interval !== 'number') {
    return undefined;
  }

  const pattern = formatCronExpression({
    type: 'interval',
    hours: interval,
  });

  return {
    ...getCommonValues(formValues),
    pattern,
  };
};

/**
 * Converts form values for a by-minute schedule to scheduled job data.
 */
const convertMinutesFormValues = (
  formValues: ScheduledJobFormValues
): ScheduledJobProp | undefined => {
  const interval = tryParseInt(formValues.minutelyInterval);
  if (typeof interval !== 'number') {
    return undefined;
  }

  const pattern = formatCronExpression({
    type: 'interval',
    minutes: interval,
  });

  return {
    ...getCommonValues(formValues),
    pattern,
  };
};

/**
 * Converts form values to scheduled job data.
 */
export const convertFormValues = (
  formValues: ScheduledJobFormValues
): ScheduledJobProp | undefined => {
  switch (formValues.type) {
    case ScheduleType.months: {
      return convertMonthlyFormValues(formValues);
    }
    case ScheduleType.weeks: {
      return convertWeeklyFormValues(formValues);
    }
    case ScheduleType.days: {
      return convertDailyFormValues(formValues);
    }
    case ScheduleType.hours: {
      return convertHourlyFormValues(formValues);
    }
    case ScheduleType.minutes: {
      return convertMinutesFormValues(formValues);
    }
    default: {
      ensureUnreachable(formValues.type);
      return getCommonValues(formValues);
    }
  }
};
