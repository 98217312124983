import { isBrowser, isTest } from '@common/utils';
import { throwErrors } from '@voleer/io-ts';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

export const AppConfigT = t.type(
  {
    USE_GRAPHQL_PLAYGROUND: t.union([t.literal('true'), t.literal('false')]),

    AUTH_API_URL: t.string,
    AUTH_API_VERSION: t.string,

    TENANTS_API_PUBLIC_URL: t.string,
    TENANTS_API_URL: t.string,
    TENANTS_API_VERSION: t.string,

    PACKAGES_API_URL: t.string,
    PACKAGES_API_VERSION: t.string,

    WORKFLOWS_API_URL: t.string,
    WORKFLOWS_API_VERSION: t.string,

    DATA_EXCHANGE_API_PUBLIC_URL: t.string,
    DATA_EXCHANGE_API_URL: t.string,
    DATA_EXCHANGE_API_VERSION: t.string,

    TENANT_OPEN_ID_AUTHORITY: t.string,
    OPEN_ID_AUTHORITY: t.string,
    TENANT_UI_URL: t.string,
    AUTH_UI_URL: t.string,
    ROOT_DOMAIN: t.string,

    LAUNCH_DARKLY_CLIENT_SIDE_ID: t.string,
    CHARGE_BEE_SITE_ID: t.union([t.string, t.undefined]),
    GOOGLE_TAG_MANAGER_ID: t.union([t.string, t.undefined]),
    DATADOG_CLIENT_TOKEN: t.union([t.string, t.undefined]),
    PENDO_API_KEY: t.union([t.string, t.undefined]),
    PENDO_SUB_ID: t.union([t.string, t.undefined]),
  },
  'AppConfig'
);

export type AppConfig = t.TypeOf<typeof AppConfigT>;

/**
 * Returns the application config to use in a web browser.
 */
const getClientConfig = (): AppConfig => {
  const decoded = AppConfigT.decode({
    USE_GRAPHQL_PLAYGROUND: window.env.USE_GRAPHQL_PLAYGROUND,

    AUTH_API_URL: window.env.AUTH_API_URL,
    AUTH_API_VERSION: window.env.AUTH_API_VERSION,

    TENANTS_API_PUBLIC_URL: window.env.TENANTS_API_PUBLIC_URL,
    TENANTS_API_URL: window.env.TENANTS_API_URL,
    TENANTS_API_VERSION: window.env.TENANTS_API_VERSION,

    PACKAGES_API_URL: window.env.PACKAGES_API_URL,
    PACKAGES_API_VERSION: window.env.PACKAGES_API_VERSION,

    WORKFLOWS_API_URL: window.env.WORKFLOWS_API_URL,
    WORKFLOWS_API_VERSION: window.env.WORKFLOWS_API_VERSION,

    DATA_EXCHANGE_API_PUBLIC_URL: window.env.DATA_EXCHANGE_API_PUBLIC_URL,
    DATA_EXCHANGE_API_URL: window.env.DATA_EXCHANGE_API_URL,
    DATA_EXCHANGE_API_VERSION: window.env.DATA_EXCHANGE_API_VERSION,

    TENANT_OPEN_ID_AUTHORITY: window.env.TENANT_OPEN_ID_AUTHORITY,
    OPEN_ID_AUTHORITY: window.env.OPEN_ID_AUTHORITY,
    TENANT_UI_URL: window.env.TENANT_UI_URL,
    AUTH_UI_URL: window.env.AUTH_UI_URL,
    ROOT_DOMAIN: window.env.ROOT_DOMAIN,

    LAUNCH_DARKLY_CLIENT_SIDE_ID: window.env.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    CHARGE_BEE_SITE_ID: window.env.CHARGE_BEE_SITE_ID,
    GOOGLE_TAG_MANAGER_ID: window.env.GOOGLE_TAG_MANAGER_ID,
    DATADOG_CLIENT_TOKEN: window.env.DATADOG_CLIENT_TOKEN,
    PENDO_API_KEY: window.env.PENDO_API_KEY,
    PENDO_SUB_ID: window.env.PENDO_SUB_ID,
  });

  return pipe(decoded, E.getOrElseW(throwErrors));
};

/**
 * Returns the application config to use on the server.
 */
const getServerConfig = (): AppConfig => {
  const decoded = AppConfigT.decode({
    USE_GRAPHQL_PLAYGROUND:
      process.env.ARK_USE_GRAPHQL_PLAYGROUND ||
      process.env.RAZZLE_USE_GRAPHQL_PLAYGROUND,

    AUTH_API_URL:
      process.env.ARK_AUTH_API_URL || process.env.RAZZLE_AUTH_API_URL,
    AUTH_API_VERSION:
      process.env.ARK_AUTH_API_VERSION || process.env.RAZZLE_AUTH_API_VERSION,

    TENANTS_API_PUBLIC_URL:
      process.env.ARK_TENANTS_API_PUBLIC_URL ||
      process.env.RAZZLE_TENANTS_API_PUBLIC_URL,
    TENANTS_API_URL:
      process.env.ARK_TENANTS_API_URL || process.env.RAZZLE_TENANTS_API_URL,
    TENANTS_API_VERSION:
      process.env.ARK_TENANTS_API_VERSION ||
      process.env.RAZZLE_TENANTS_API_VERSION,

    PACKAGES_API_URL:
      process.env.ARK_PACKAGES_API_URL || process.env.RAZZLE_PACKAGES_API_URL,
    PACKAGES_API_VERSION:
      process.env.ARK_PACKAGES_API_VERSION ||
      process.env.RAZZLE_PACKAGES_API_VERSION,

    WORKFLOWS_API_URL:
      process.env.ARK_WORKFLOWS_API_URL || process.env.RAZZLE_WORKFLOWS_API_URL,
    WORKFLOWS_API_VERSION:
      process.env.ARK_WORKFLOWS_API_VERSION ||
      process.env.RAZZLE_WORKFLOWS_API_VERSION,

    DATA_EXCHANGE_API_PUBLIC_URL:
      process.env.ARK_DATA_EXCHANGE_API_PUBLIC_URL ||
      process.env.RAZZLE_DATA_EXCHANGE_API_PUBLIC_URL,
    DATA_EXCHANGE_API_URL:
      process.env.ARK_DATA_EXCHANGE_API_URL ||
      process.env.RAZZLE_DATA_EXCHANGE_API_URL,
    DATA_EXCHANGE_API_VERSION:
      process.env.ARK_DATA_EXCHANGE_API_VERSION ||
      process.env.RAZZLE_DATA_EXCHANGE_API_VERSION,

    TENANT_OPEN_ID_AUTHORITY:
      process.env.ARK_TENANT_OPEN_ID_AUTHORITY ||
      process.env.RAZZLE_TENANT_OPEN_ID_AUTHORITY,
    OPEN_ID_AUTHORITY:
      process.env.ARK_OPEN_ID_AUTHORITY || process.env.RAZZLE_OPEN_ID_AUTHORITY,
    AUTH_UI_URL: process.env.ARK_AUTH_UI_URL || process.env.RAZZLE_AUTH_UI_URL,
    TENANT_UI_URL:
      process.env.ARK_TENANT_UI_URL || process.env.RAZZLE_TENANT_UI_URL,
    ROOT_DOMAIN: process.env.ARK_ROOT_DOMAIN || process.env.RAZZLE_ROOT_DOMAIN,

    LAUNCH_DARKLY_CLIENT_SIDE_ID:
      process.env.ARK_LAUNCH_DARKLY_CLIENT_SIDE_ID ||
      process.env.RAZZLE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    CHARGE_BEE_SITE_ID:
      process.env.ARK_CHARGE_BEE_SITE_ID ||
      process.env.RAZZLE_CHARGE_BEE_SITE_ID,
    GOOGLE_TAG_MANAGER_ID:
      process.env.ARK_GOOGLE_TAG_MANAGER_ID ||
      process.env.RAZZLE_GOOGLE_TAG_MANAGER_ID,
    DATADOG_CLIENT_TOKEN:
      process.env.ARK_DATADOG_CLIENT_TOKEN ||
      process.env.RAZZLE_DATADOG_CLIENT_TOKEN,
    PENDO_API_KEY:
      process.env.ARK_PENDO_API_KEY || process.env.RAZZLE_PENDO_API_KEY,
    PENDO_SUB_ID:
      process.env.ARK_PENDO_SUB_ID || process.env.RAZZLE_PENDO_SUB_ID,
  });

  return pipe(decoded, E.getOrElseW(throwErrors));
};

/**
 * Retrieves the current application configuration.
 */
export const getAppConfig = () => {
  if (isTest()) {
    return getServerConfig(); // Just re-use the .env config in test mode for now
  } else if (
    typeof window !== 'undefined' &&
    window &&
    window.env &&
    isBrowser()
  ) {
    return getClientConfig();
  }

  return getServerConfig();
};

type TenantAppConfig = AppConfig & {
  TENANT_NAME: string;
};

/**
 * Retrieves the current application configuration customized to the given
 * tenant name.
 */
export const getTenantAppConfig = (tenantName: string): TenantAppConfig => {
  const baseAppConfig = getAppConfig();
  const tenantAppConfig = Object.keys(baseAppConfig).reduce(
    (acc, key) => {
      if (typeof baseAppConfig[key] === 'string') {
        acc[key] = baseAppConfig[key].replace('{tenantName}', tenantName);
      } else {
        acc[key] = baseAppConfig[key];
      }
      return acc;
    },
    { TENANT_NAME: tenantName } as TenantAppConfig
  );
  return tenantAppConfig;
};
