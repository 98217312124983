import React, { useContext } from 'react';
import {
  AppLayout,
  PageContent,
  RouteTab,
  RouteTabs,
} from '@common/components';
import { urlFor } from '@common/utils';
import { Anchor, Area } from '@voleer/ui-kit';
import {
  Box,
  Grid,
  GridProps,
  Heading,
  Image,
  Paragraph,
  ResponsiveContext,
} from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import { AvailableTab, ConfiguredTab } from './components';
import VEE_IMG_SRC from './images/vee.svg';

/**
 * Renders the page for managing integrations.
 */
export const IntegrationsPage: React.FC = () => {
  const [t] = useTranslation('pages/IntegrationsPage');

  const viewportSize = useContext(ResponsiveContext);
  const defaultColumns = ['auto', 'flex'];
  const columns: Record<string, GridProps['columns']> = {
    small: ['auto'],
    medium: ['auto'],
  };

  return (
    <AppLayout title={t('page-title')}>
      <PageContent>
        <Box align="center" flex={{ shrink: 0 }}>
          <Box
            align="center"
            direction="row"
            justify="between"
            pad={{ horizontal: 'small' }}
            width="xxlarge"
          >
            <Area
              direction="row"
              elevation={undefined}
              flex={{ shrink: 0 }}
              pad={{ vertical: 'small' }}
              paper={true}
            >
              <Grid columns={columns[viewportSize] || defaultColumns}>
                <Image
                  alt="Vee"
                  height="150px"
                  margin={{
                    horizontal: viewportSize === 'large' ? 'small' : 'auto',
                    vertical: 'auto',
                  }}
                  src={VEE_IMG_SRC}
                />

                <Box
                  justify="center"
                  pad={
                    viewportSize === 'large'
                      ? 'small'
                      : { horizontal: 'medium', top: 'medium' }
                  }
                >
                  <Heading level="5" margin="0">
                    {t('page-banner.heading')}
                  </Heading>
                  <Paragraph margin={{ bottom: '0', top: 'small' }}>
                    <Trans
                      components={{
                        'community-link': (
                          <Anchor
                            href={urlFor('voleerCommunity')()}
                            variation="primary"
                          />
                        ),
                      }}
                      i18nKey="page-banner.help-text"
                      ns="pages/IntegrationsPage"
                    />
                  </Paragraph>
                </Box>
              </Grid>
            </Area>
          </Box>
          <Box width="xxlarge">
            <RouteTabs justify="start" margin={{ top: 'medium' }}>
              <RouteTab
                queryParamValue="available"
                title={t('tab-names.available')}
              >
                <AvailableTab />
              </RouteTab>
              <RouteTab
                queryParamValue="configured"
                title={t('tab-names.configured')}
              >
                <ConfiguredTab />
              </RouteTab>
            </RouteTabs>
          </Box>
        </Box>
      </PageContent>
    </AppLayout>
  );
};
