import {
  CronExpression,
  isCronInterval,
  isCronSchedule,
} from '@voleer/cron-parse';

export type MonthlySchedule = {
  hour: number;
  minute: number;
  dayOfMonth: number;
  month: number[];
};

/**
 * Extracts just the information relevant to monthly schedules from a cron
 * expression.
 *
 * Returns `undefined` if the expression is invalid or does not represent a
 * monthly interval.
 */
export const toMonthlySchedule = (
  expression?: CronExpression
): MonthlySchedule | undefined => {
  if (!isCronSchedule(expression)) {
    return undefined;
  }

  const { minute, hour, dayOfMonth, month, dayOfWeek } = expression;

  if (
    !month.length ||
    month.some(
      m => typeof m.range !== 'number' || typeof m.step !== 'undefined'
    )
  ) {
    return undefined;
  }

  if (
    dayOfWeek.length !== 1 ||
    dayOfWeek[0].range !== '*' ||
    typeof expression.dayOfWeek[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    dayOfMonth.length !== 1 ||
    typeof expression.dayOfMonth[0].range !== 'number'
  ) {
    return undefined;
  }

  if (
    hour.length !== 1 ||
    typeof hour[0].range !== 'number' ||
    typeof hour[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    minute.length !== 1 ||
    typeof minute[0].range !== 'number' ||
    typeof minute[0].step !== 'undefined'
  ) {
    return undefined;
  }

  const monthNumbers = month.reduce((acc, { range }) => {
    if (typeof range === 'number') {
      acc.push(range);
    }
    return acc;
  }, [] as number[]);

  return {
    hour: hour[0].range,
    minute: minute[0].range,
    month: monthNumbers,
    dayOfMonth: expression.dayOfMonth[0].range,
  };
};

export type WeeklySchedule = {
  hour: number;
  minute: number;
  dayOfWeek: number[];
};

/**
 * Extracts just the information relevant to weekly schedules from a cron
 * expression.
 *
 * Returns `undefined` if the expression is invalid or does not represent an
 * weekly schedule.
 */
export const toWeeklySchedule = (
  expression?: CronExpression
): WeeklySchedule | undefined => {
  if (!isCronSchedule(expression)) {
    return undefined;
  }

  const { minute, hour, dayOfMonth, month } = expression;

  if (
    dayOfMonth.length !== 1 ||
    dayOfMonth[0].range !== '*' ||
    typeof dayOfMonth[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    month.length !== 1 ||
    month[0].range !== '*' ||
    typeof month[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    hour.length !== 1 ||
    typeof hour[0].range !== 'number' ||
    typeof hour[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    minute.length !== 1 ||
    typeof minute[0].range !== 'number' ||
    typeof minute[0].step !== 'undefined'
  ) {
    return undefined;
  }

  const dayOfWeek = expression.dayOfWeek
    .map(d => d.range)
    .filter((n): n is number => typeof n === 'number');

  if (!dayOfWeek.length) {
    return undefined;
  }

  return {
    hour: hour[0].range,
    minute: minute[0].range,
    dayOfWeek,
  };
};

export type DailySchedule = {
  hour: number;
  minute: number;
};

/**
 * Extracts just the information relevant to daily schedules from a cron
 * expression.
 *
 * Returns `undefined` if the expression is invalid or does not represent an
 * daily schedule.
 */
export const toDailySchedule = (
  expression?: CronExpression
): DailySchedule | undefined => {
  if (!isCronSchedule(expression)) {
    return undefined;
  }

  const { minute, hour, dayOfMonth, month, dayOfWeek } = expression;

  if (
    dayOfWeek.length !== 1 ||
    dayOfWeek[0].range !== '*' ||
    typeof dayOfWeek[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    dayOfMonth.length !== 1 ||
    dayOfMonth[0].range !== '*' ||
    typeof dayOfMonth[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    month.length !== 1 ||
    month[0].range !== '*' ||
    typeof month[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    hour.length !== 1 ||
    typeof hour[0].range !== 'number' ||
    typeof hour[0].step !== 'undefined'
  ) {
    return undefined;
  }

  if (
    minute.length !== 1 ||
    typeof minute[0].range !== 'number' ||
    typeof minute[0].step !== 'undefined'
  ) {
    return undefined;
  }

  return {
    hour: hour[0].range,
    minute: minute[0].range,
  };
};

export type HourlySchedule = {
  hours: number;
};

/**
 * Extracts just the information relevant to hourly schedules from a cron
 * expression.
 *
 * Returns `undefined` if the expression is invalid or does not represent an
 * hourly interval.
 */
export const toHourlySchedule = (
  expression?: CronExpression
): HourlySchedule | undefined => {
  if (!isCronInterval(expression)) {
    return undefined;
  }

  // Hourly expressions should only contain hours
  if (
    typeof expression.hours !== 'number' ||
    typeof expression.minutes !== 'undefined' ||
    typeof expression.seconds !== 'undefined'
  ) {
    return undefined;
  }

  return { hours: expression.hours };
};

export type MinutesSchedule = {
  minutes: number;
};

/**
 * Extracts just the information relevant to by-minute schedules from a cron
 * expression.
 *
 * Returns `undefined` if the expression is invalid or does not represent a
 * by-minute interval.
 */
export const toMinutesSchedule = (
  expression?: CronExpression
): MinutesSchedule | undefined => {
  if (!isCronInterval(expression)) {
    return undefined;
  }

  // Minutes expressions should only contain minutes
  if (
    typeof expression.minutes !== 'number' ||
    typeof expression.hours !== 'undefined' ||
    typeof expression.seconds !== 'undefined'
  ) {
    return undefined;
  }

  return { minutes: expression.minutes };
};
