import React from 'react';
import { useAccountStatus, useDateFormat } from '@common/hooks';
import { urlFor } from '@common/utils';
import {
  RenameModal,
  TemplateConfigurationStatusBadge,
  useRenameModal,
} from '@features/workflows';
import {
  TemplateConfigurationStatus,
  Workspace,
  useWorkspacePageRenameTemplateConfigurationMutation,
} from '@generated/graphql-code-generator';
import {
  DropButton,
  DropButtonItem,
  FancyTable,
  FancyTableProps,
  Icon,
  Link,
} from '@voleer/ui-kit';
import { parseISO } from 'date-fns';
import { Button, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';
import {
  DeleteTemplateConfigurationModal,
  useDeleteTemplateConfigurationModal,
} from '../DeleteTemplateConfigurationModal';
import { TemplateConfigurationProp } from './interface';

/**
 * Prop types for the `TemplateConfigurationsTable` component.
 */
export type TemplateConfigurationsTableProps = FancyTableProps & {
  /**
   * Callback which is called when the user chooses to launch a given Template
   * Configuration.
   */
  onLaunch: (
    templateConfiguration: TemplateConfigurationProp
  ) => Promise<void> | void;

  /**
   * Callback which is called when the user types into the table's search input.
   */
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;

  /**
   * The current search term.
   */
  searchTerm?: string;

  /**
   * The instances to display in the table.
   */
  templateConfigurations: Array<TemplateConfigurationProp | null>;

  /**
   * The workspace that the template configurations are contained in.
   */
  workspace: Pick<Workspace, 'id'>;
};

/**
 * Renders the table for displaying Template Configurations in a Workspace.
 */
export const TemplateConfigurationsTable: React.FC<TemplateConfigurationsTableProps> =
  ({
    onLaunch,
    onSearch,
    searchTerm,
    templateConfigurations,
    workspace,
    ...fancyTableProps
  }) => {
    const [t] = useTranslation(
      'features/workflows/components/TemplateConfigurationsTable'
    );

    const { disableWriteOperation } = useAccountStatus();

    const [renameTemplateConfiguration, renameTemplateConfigurationMutation] =
      useWorkspacePageRenameTemplateConfigurationMutation();
    const { openRenameModal, renameModalProps } = useRenameModal({
      error: renameTemplateConfigurationMutation?.error?.message,
      renameMutation: renameTemplateConfiguration,
      typeName: t('rename-modal.type-name'),
      workspaceId: workspace.id,
    });

    const {
      open: openDeleteTemplateConfigurationModal,
      props: deleteTemplateConfigurationModalProps,
    } = useDeleteTemplateConfigurationModal({
      workspaceId: workspace.id,
    });

    const [format, { DateTimeFormat }] = useDateFormat();

    const renderBody = () => {
      if (searchTerm && !templateConfigurations.length) {
        return (
          <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
            <Heading
              level="3"
              margin={{ bottom: 'small', top: '0' }}
              textAlign="center"
            >
              {t('no-results')}
            </Heading>
          </FancyTable.Empty>
        );
      }

      if (!templateConfigurations.length) {
        return (
          <FancyTable.Empty margin={{ vertical: 'xlarge' }}>
            <Heading
              level="3"
              margin={{ bottom: 'small', top: '0' }}
              textAlign="center"
            >
              {t('empty')}
            </Heading>
          </FancyTable.Empty>
        );
      }

      return templateConfigurations.map(templateConfiguration => {
        if (
          !templateConfiguration ||
          templateConfiguration.status === TemplateConfigurationStatus.Deleting
        ) {
          return null;
        }

        const templateConfigurationUrl = urlFor('templateConfiguration')({
          templateConfigurationId: templateConfiguration.id,
          workspaceId: templateConfiguration.workspaceId,
        });

        const templateMetadata = templateConfiguration.templateMetadata;
        const lastStartedInstance = templateConfiguration.lastStartedInstance;

        return (
          <FancyTable.Row data-testid="row" key={templateConfiguration.id}>
            <FancyTable.Cell data-testid="cell__display-name">
              <Text truncate={true}>
                {templateConfiguration?.displayName ? (
                  <Link
                    title={templateConfiguration.displayName}
                    to={templateConfigurationUrl}
                    variation="primary"
                  >
                    {templateConfiguration.displayName}
                  </Link>
                ) : (
                  '-'
                )}
              </Text>
            </FancyTable.Cell>

            <FancyTable.Cell data-testid="cell__status">
              <TemplateConfigurationStatusBadge
                status={templateConfiguration.status}
              />
            </FancyTable.Cell>

            <FancyTable.Cell data-testid="cell__template">
              <Text truncate={true}>
                {templateMetadata ? templateMetadata.displayName : '-'}
              </Text>
            </FancyTable.Cell>

            <FancyTable.Cell data-testid="cell__last-run">
              <Text truncate={true}>
                {!!lastStartedInstance && !!lastStartedInstance.startedOn ? (
                  <>
                    {format(
                      parseISO(lastStartedInstance.startedOn),
                      DateTimeFormat.AbbreviatedHumanized
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Text>
            </FancyTable.Cell>
            <FancyTable.Cell data-testid="cell__launch-btn">
              <Button
                data-testid="cell__actions--launch"
                disabled={disableWriteOperation}
                label={t('actions.items.launch.label')}
                onClick={() => {
                  onLaunch(templateConfiguration);
                }}
                size="small"
              />
            </FancyTable.Cell>

            <FancyTable.Cell data-testid="cell__actions">
              <DropButton
                data-testid="actions-button"
                icon={<Icon icon={MdMoreHoriz} />}
                title={t('actions.title')}
                variation="ghost"
              >
                <Link to={templateConfigurationUrl} variation="primary">
                  <DropButtonItem label={t('actions.items.view.label')} />
                </Link>

                <DropButtonItem
                  label={t('actions.items.rename.label')}
                  onClick={() => {
                    if (
                      !templateConfiguration?.id ||
                      !templateConfiguration?.displayName
                    )
                      return;

                    openRenameModal(
                      templateConfiguration.id,
                      templateConfiguration.displayName
                    );
                  }}
                />

                <DropButtonItem
                  label={t('actions.items.delete.label')}
                  onClick={() => {
                    openDeleteTemplateConfigurationModal(
                      templateConfiguration.id
                    );
                  }}
                />
              </DropButton>
            </FancyTable.Cell>
          </FancyTable.Row>
        );
      });
    };

    return (
      <>
        <FancyTable
          columns={[
            { width: '20%' },
            undefined,
            undefined,
            { width: '25%' },
            { width: '10%', justify: 'end' },
            { width: '50px', justify: 'end' },
          ]}
          fill="horizontal"
          paper={true}
          rows={{ count: templateConfigurations.length }}
          {...fancyTableProps}
        >
          <FancyTable.Toolbar>
            <FancyTable.Search
              onChange={onSearch}
              placeholder={t('search.placeholder')}
              value={searchTerm}
            />
          </FancyTable.Toolbar>
          <FancyTable.Header>
            <FancyTable.Row>
              <FancyTable.HeaderCell>
                {t('headings.name')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('headings.status')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('headings.template')}
              </FancyTable.HeaderCell>
              <FancyTable.HeaderCell>
                {t('headings.last-run')}
              </FancyTable.HeaderCell>

              <FancyTable.HeaderCell />

              <FancyTable.HeaderCell>
                {t('headings.actions')}
              </FancyTable.HeaderCell>
            </FancyTable.Row>
          </FancyTable.Header>
          <FancyTable.Body>{renderBody()}</FancyTable.Body>
        </FancyTable>

        <RenameModal {...renameModalProps} />
        <DeleteTemplateConfigurationModal
          {...deleteTemplateConfigurationModalProps}
        />
      </>
    );
  };
