import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useTenantContext } from '@common/hooks';
import {
  GetCurrentUserTenantMemberDocument,
  useActivitySummarySectionToggleRunSummaryViaEmailMutation,
} from '@generated/graphql-code-generator';
import { Box, CheckBox, Heading, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';

/**
 * Renders the "Activity Summary" section of the `NotificationsSettingsTab` component.
 * Allows the user to toggle the run summary via email on/off.
 */
export const ActivitySummarySection: React.FC = () => {
  const [t] = useTranslation('pages/NotificationsPage');

  const { tenantMember } = useTenantContext();
  const [toggleRunSummaryViaEmail] =
    useActivitySummarySectionToggleRunSummaryViaEmailMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refetchQueries: [getOperationName(GetCurrentUserTenantMemberDocument)!],
    });

  const onChangeSendRunSummaryViaEmail = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (tenantMember) {
      await toggleRunSummaryViaEmail({
        variables: {
          input: {
            id: tenantMember.id,
            sendRunSummaryViaEmail: !!event.target.checked,
          },
        },
      });
    }
  };

  return (
    <Box
      align="center"
      background="white"
      direction="row"
      elevation="xsmall"
      justify="between"
      pad="medium"
      round="xsmall"
    >
      <Box>
        <Heading
          data-testid="activity-summary-section__title"
          level="4"
          margin="0"
        >
          {t('settings-tab.activity-summary.title')}
        </Heading>
        <Paragraph
          data-testid="activity-summary-section__subtitle"
          margin={{ bottom: '0', top: 'xsmall' }}
        >
          {t('settings-tab.activity-summary.subtitle')}
        </Paragraph>
      </Box>
      <CheckBox
        checked={tenantMember?.sendRunSummaryViaEmail}
        data-testid="activity-summary-section__checkbox--run-summary"
        onChange={onChangeSendRunSummaryViaEmail}
        toggle={true}
      />
    </Box>
  );
};
