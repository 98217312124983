import React from 'react';
import { Box, Button, Heading, Layer, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

type DeleteTenantMemberConfirmationProps = {
  userFullName: string;
  tenantDisplayName: string;
  onClose: () => void;
  confirmDeleteClick: () => void;
};

/**
 * Display the tenant member deletion confirmation box.
 */
export const DeleteTenantMemberConfirmation: React.FC<DeleteTenantMemberConfirmationProps> =
  ({ userFullName, tenantDisplayName, onClose, confirmDeleteClick }) => {
    const [t] = useTranslation(
      'features/tenant/components/TenantMemberListItem'
    );

    return (
      <Layer>
        <Box pad="medium" width="640px">
          <Heading level="3">
            {t('delete-user-confirmation.title', {
              userFullName,
              tenantDisplayName,
            })}
          </Heading>
          {t('delete-user-confirmation.content')}
          <Box direction="row" gap="xsmall" justify="end">
            <Button
              label={t('delete-user-confirmation.cancel')}
              onClick={onClose}
            />
            <Button
              color="status-error"
              label={
                <Text weight="bold">
                  {t('delete-user-confirmation.delete')}
                </Text>
              }
              onClick={confirmDeleteClick}
              primary={true}
            />
          </Box>
        </Box>
      </Layer>
    );
  };
