import React from 'react';
import { PackageMarkdown } from '@common/components';
import { AddIntegrationModalQuery } from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { LoadingButton } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  Box,
  Button,
  FormField,
  Paragraph,
  TextArea,
  TextInput,
} from 'grommet';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type AddIntegrationFormValues = {
  description: string;
  displayName: string;
};

type QueryIntegrationMetadata = NonNullable<
  NonNullable<
    NonNullable<AddIntegrationModalQuery['integrationMetadatas']>['items']
  >[number]
>;

export type AddIntegrationModalFormProps = {
  integrationMetadata: QueryIntegrationMetadata;
  onClose: () => void;
  onSubmit: FormikSubmitFn<AddIntegrationFormValues>;
};

/**
 * Renders the initial form for the AddIntegrationModal.
 */
export const AddIntegrationModalForm: React.FC<AddIntegrationModalFormProps> =
  ({ integrationMetadata, onClose, onSubmit }) => {
    const [t] = useTranslation(
      'features/integrations/components/AddIntegrationModal'
    );

    const initialValues: AddIntegrationFormValues = {
      description: '',
      displayName: '',
    };

    const validationSchema = Yup.object().shape({
      displayName: Yup.string().required(
        t('form-fields.display-name.required')
      ),
    });

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting, isValidating }) => {
          const formProcessing = isSubmitting || isValidating;

          return (
            <Box overflow={{ vertical: 'auto' }}>
              <Box
                flex={{ shrink: 0 }}
                pad={{ horizontal: 'medium', vertical: 'small' }}
              >
                <Form>
                  <Box flex={{ shrink: 0 }}>
                    <Paragraph>{t('form-instructions')}</Paragraph>

                    <Field name="displayName">
                      {(fieldProps: FieldProps<string>) => (
                        <FormField
                          error={fieldProps.meta.error}
                          label={t('form-fields.display-name.label')}
                        >
                          <TextInput
                            {...fieldProps.field}
                            autoFocus={true}
                            data-testid="add-integration-modal__input--display-name"
                            disabled={formProcessing}
                          />
                        </FormField>
                      )}
                    </Field>

                    <Field name="description">
                      {(fieldProps: FieldProps<string>) => (
                        <FormField
                          error={fieldProps.meta.error}
                          label={t('form-fields.description.label')}
                        >
                          <TextArea
                            {...fieldProps.field}
                            data-testid="add-integration-modal__input--description"
                            disabled={formProcessing}
                            resize={false}
                          />
                        </FormField>
                      )}
                    </Field>
                  </Box>

                  <Box
                    direction="row"
                    fill="horizontal"
                    flex={{ shrink: 0 }}
                    gap="small"
                    justify="end"
                  >
                    <Button
                      data-testid="add-integration-modal__button--close"
                      disabled={formProcessing}
                      label={t('buttons.close.label')}
                      onClick={onClose}
                    />
                    <LoadingButton
                      data-testid="add-integration-modal__button--submit"
                      disabled={!dirty}
                      label={t('buttons.submit.label')}
                      loading={formProcessing}
                      primary={true}
                      type="submit"
                    />
                  </Box>
                </Form>
              </Box>

              {!!integrationMetadata?.readme && (
                <Box border="top" pad="medium">
                  <PackageMarkdown content={integrationMetadata.readme} />
                </Box>
              )}
            </Box>
          );
        }}
      </Formik>
    );
  };
