import React from 'react';
import { LoadingOverlay } from '@common/components';
import {
  ScheduledJob,
  TemplateConfiguration,
} from '@generated/graphql-code-generator';
import { Alert, Modal, ModalProps } from '@voleer/ui-kit';
import { Box, Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import { ScheduledJobForm, ScheduledJobFormProps } from '..';

export type ScheduledJobProps = Pick<
  ScheduledJob,
  | 'endTime'
  | 'id'
  | 'maxRecurrenceCount'
  | 'pattern'
  | 'startTime'
  | 'timezoneName'
> & {
  templateConfiguration: Pick<TemplateConfiguration, 'displayName' | 'id'>;
};

export type ChangeScheduledJobModalProps = Pick<ModalProps, 'open'> & {
  /**
   * The scheduled job being changed.
   *
   * If omitted then the modal will be rendered in "loading" state until a
   * scheduled job is provided.
   */
  scheduledJob?: ScheduledJobProps | null;

  /**
   * Error to display in the modal.
   */
  error?: Error | string;

  /**
   * Callback called when the user clicks submit.
   */
  onSubmit: ScheduledJobFormProps['onSubmit'];

  /**
   * Callback called when the user clicks cancel.
   */
  onCancel: () => void;
};

/**
 * Modal form for changing an existing scheduled job.
 */
export const ChangeScheduledJobModal: React.FC<ChangeScheduledJobModalProps> =
  ({ scheduledJob, open, error, onCancel, onSubmit }) => {
    const [t] = useTranslation(
      'features/scheduled-jobs/components/ChangeScheduledJobModal'
    );

    const renderBody = () => {
      if (!scheduledJob) {
        return null;
      }

      const errorMessage = error instanceof Error ? error.message : error;

      return (
        <Box fill={true} overflow="auto">
          {errorMessage && (
            <Alert icon={true} status="error">
              {errorMessage}
            </Alert>
          )}

          <ScheduledJobForm onSubmit={onSubmit} value={scheduledJob}>
            <Modal.Body pad={{ top: 'medium' }}>
              <ScheduledJobForm.Fields>
                <ScheduledJobForm.TimezoneField />
                <ScheduledJobForm.StartDateField />
                <ScheduledJobForm.ScheduleFields />
                <ScheduledJobForm.EndTypeField />
              </ScheduledJobForm.Fields>
            </Modal.Body>
            <Modal.Footer>
              <Button label={t('buttons.cancel.label')} onClick={onCancel} />
              <ScheduledJobForm.SubmitButton
                label={t('buttons.submit.label')}
              />
            </Modal.Footer>
          </ScheduledJobForm>
        </Box>
      );
    };

    return (
      <Modal onClickOutside={onCancel} onEsc={onCancel} open={open}>
        <Modal.Header>{t('title')}</Modal.Header>
        <LoadingOverlay loading={!scheduledJob}>{renderBody()}</LoadingOverlay>
      </Modal>
    );
  };
