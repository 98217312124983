import { LibraryItemMetadataType } from '@generated/graphql-code-generator';
import { UnreachableCaseError } from '@voleer/types';
import { Overwrite } from 'utility-types';
import { getAppConfig } from '../../app-config';
import {
  DashboardPageRouteParams,
  DataSetPageRouteParams,
  LibraryItemByIdRouteParams,
  LibraryItemPageRouteParams,
  LibraryItemPageTypeUrlSegment,
  ScheduledJobRouteParams,
  TemplateConfigurationRouteParams,
  WorkflowInstancePageRouteParams,
  WorkspaceRouteParams,
} from '../../pages';

/**
 * Helper to generate react router routes for each page of the app.
 *
 * @param route
 */
export function urlFor<K extends keyof Urls>(route: K) {
  return urls[route];
}

/**
 * Returns a url with no '/' at the end.
 */
const urlScrubber = (url: string) => {
  if (url.charAt(url.length - 1) === '/') {
    return url.slice(0, -1);
  }
  return url;
};

type Urls = typeof urls;

type LibraryItemUrlParams = Overwrite<
  LibraryItemPageRouteParams,
  {
    type: LibraryItemMetadataType;
  }
>;

export const getLibraryItemTypeUrlSegment = (
  input: LibraryItemMetadataType
): LibraryItemPageTypeUrlSegment => {
  if (input === LibraryItemMetadataType.Unknown) {
    throw new Error(`Cannot generate url segment for type ${input}`);
  }

  switch (input) {
    case LibraryItemMetadataType.Automation: {
      return 'automation';
    }
    case LibraryItemMetadataType.DataSet: {
      return 'dataset';
    }
    case LibraryItemMetadataType.Dashboard: {
      return 'dashboard';
    }
    default: {
      throw new UnreachableCaseError(input);
    }
  }
};

const urls = {
  home: () => '/',
  integrations: () => '/integrations',
  library: () => '/library',
  libraryItem: (params: LibraryItemUrlParams) => {
    const { publisherName, packageName, name, packageVersion } = params;

    const type = getLibraryItemTypeUrlSegment(params.type);

    if (packageVersion) {
      return `/library/${type}/${publisherName}/${packageName}/${name}/v/${packageVersion}`;
    }

    return `/library/${type}/${publisherName}/${packageName}/${name}`;
  },
  libraryItemById: (params: LibraryItemByIdRouteParams) =>
    `/library/${params.itemId}`,
  userSettings: () => '/user-settings',
  workflowInstance: (params: WorkflowInstancePageRouteParams) =>
    `/workspaces/${params.workspaceId}/runs/${params.workflowInstanceId}`,
  workspaces: () => '/workspaces',
  workspace: (params: WorkspaceRouteParams) =>
    `/workspaces/${params.workspaceId}`,
  workspaceDataSets: (params: { workspaceId: string }) =>
    `/workspaces/${params.workspaceId}?tab=datasets`,
  workspaceRuns: (params: { workspaceId: string }) =>
    `/workspaces/${params.workspaceId}?tab=runs`,
  dashboards: (params: { workspaceId: string }) =>
    `/workspaces/${params.workspaceId}?tab=dashboards`,
  dashboard: (params: DashboardPageRouteParams) =>
    `/workspaces/${params.workspaceId}/dashboards/${params.dashboardId}`,
  dataSet: (params: DataSetPageRouteParams) =>
    `/workspaces/${params.workspaceId}/datasets/${params.dataSetId}`,
  templateConfiguration: (params: TemplateConfigurationRouteParams) =>
    `/workspaces/${params.workspaceId}/templateConfigurations/${params.templateConfigurationId}`,
  templateConfigurations: (params: { workspaceId: string }) =>
    `/workspaces/${params.workspaceId}?tab=template-configurations`,
  scheduledJobs: (params: { workspaceId: string }) =>
    `/workspaces/${params.workspaceId}?tab=scheduled-jobs`,
  scheduledJob: (params: ScheduledJobRouteParams) =>
    `/workspaces/${params.workspaceId}/scheduledJobs/${params.scheduledJobId}`,
  notifications: () => '/notifications',
  notificationsSettings: () => '/notifications?tab=settings',
  notificationsHistory: () => '/notifications?tab=history',
  notificationsOld: () => '/user-settings/notifications',
  tenantSettings: () => '/tenant-settings',
  userManagement: () => '/tenant-settings',
  enableTenantSso: (tenantUrl: string) =>
    `${urlScrubber(tenantUrl)}/Account/ExternalIdpInitialLogin?p=AzureAd`,

  authorizeOAuth2Session: ({ sessionId }: { sessionId: string }) => {
    const { TENANTS_API_PUBLIC_URL } = getAppConfig();
    return `${TENANTS_API_PUBLIC_URL}/OAuth2Integrations/Authorize?sessionId=${sessionId}`;
  },

  fileFormComponentUpload: () => {
    const { DATA_EXCHANGE_API_PUBLIC_URL } = getAppConfig();
    return `${DATA_EXCHANGE_API_PUBLIC_URL}/api/dataexchange/uploadForFormComponent`;
  },

  // External URLs
  pricing: () => 'https://voleer.com/pricing',
  contactSales: () => 'https://voleer.com/contact-sales',
  help: () => 'https://support.voleer.com/',
  instanceTroubleshoot: () =>
    'https://support.voleer.com/hc/en-us/articles/360060694674-Troubleshooting-failed-templates-and-tasks',
  voleerCommunity: () => 'https://community.voleer.com/',
};
