import type { MutableRefObject, Ref, RefCallback } from 'react';

type CastableRef<T> = MutableRefObject<T> | RefCallback<T> | null;

/**
 * Helper to downcast a forwarded ref of a compatible type to the expected
 * sub-type.
 *
 * The following does not work since `<button>` expects a
 * `Ref<HTMLButtonElement>` and `<div>` expects a `Ref<HTMLDivElement>`, but all
 * we can guarantee to the caller is that it will be a `Ref<HTMLElement>`.
 *
 * ```typescript
 * const Example = React.forwardRef<HTMLElement, ExampleProps>(
 *   ({ renderAsButton }, ref) => {
 *     if (renderAsButton) {
 *       return <button ref={ref}>Hello</button>;
 *     } else {
 *       return <div ref={ref}>Hello</div>;
 *     }
 *   }
 * );
 * ```
 *
 * This results in an error like:
 *
 * ```
 * Type '((instance: HTMLElement | null) => void) | MutableRefObject<HTMLElement | null> | null' is not assignable to type 'string | ((instance: HTMLButtonElement | null) => void) | RefObject<HTMLButtonElement> | null | undefined'.
 *   Type 'MutableRefObject<HTMLElement | null>' is not assignable to type 'string | ((instance: HTMLButtonElement | null) => void) | RefObject<HTMLButtonElement> | null | undefined'.
 *     Type 'MutableRefObject<HTMLElement | null>' is not assignable to type 'RefObject<HTMLButtonElement>'.
 *       Types of property 'current' are incompatible.
 *         Type 'HTMLElement | null' is not assignable to type 'HTMLButtonElement | null'.
 *           Type 'HTMLElement' is missing the following properties from type 'HTMLButtonElement': disabled, form, formAction, formEnctype, and 13 more.
 * ```
 *
 * We can fix it by using this helper to automatically downcast the ref from
 * `HTMLElement` to the particular sub-type needed:
 *
 * ```typescript
 * const Example = React.forwardRef<HTMLElement, ExampleProps>(
 *   ({ renderAsButton }, ref) => {
 *     if (renderAsButton) {
 *       return <button ref={castRef(ref)}>Hello</button>;
 *     } else {
 *       return <div ref={castRef(ref)}>Hello</div>;
 *     }
 *   }
 * );
 * ```
 */
export const castRef = <TFrom, TTo extends TFrom>(
  ref?: CastableRef<TFrom>
): Ref<TTo> | undefined => {
  if (!ref) {
    return undefined;
  }

  return (el: TTo) => {
    if (!ref) {
      return;
    }

    if (typeof ref === 'function') {
      ref(el);
    } else {
      ref.current = el;
    }
  };
};
