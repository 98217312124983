import { useRef } from 'react';
import { constant } from 'lodash';

type UseFirstOptions<T> = {
  condition: boolean | ((value: T) => boolean);
};

/**
 * Hook to keep the first value that was passed to the hook. Useful for
 * remembering the original value passed to a component.
 *
 * ```javascript
 * const MyComponent = ({ value }) => {
 *   const firstValue = useFirst(value);
 *
 *   // First render with value === "first value"
 *   console.log(firstValue); // "first value", the initial value is set
 *
 *   // Second render with value === "second value"
 *   console.log(firstValue); // "first value", the initial value is retained across renders
 * }
 * ```
 *
 * Optionally, a condition can be specified to keep only the first value where
 * the condition is true. Prior to the condition being true the hook will return
 * `undefined`.
 *
 * ```javascript
 * const MyComponent = ({ value }) => {
 *   const firstMatchingValue = useFirst(value, {
 *     condition: value === "second value"
 *   });
 *
 *   // First render with value === "first value"
 *   console.log(firstMatchingValue); // undefined, condition was false
 *
 *   // Second render with value === "second value"
 *   console.log(firstMatchingValue); // "second value", condition was true
 *
 *   // Third render with value === "third value"
 *   console.log(firstMatchingValue); // "second value", condition was false
 * }
 * ```
 */
export const useFirst = <T>(
  value: T,
  { condition }: UseFirstOptions<T> = { condition: true }
) => {
  const valueRef = useRef<T>();
  const hasValueRef = useRef(false);

  const conditionFn =
    typeof condition === 'function' ? condition : constant(condition);

  if (!hasValueRef.current && conditionFn(value)) {
    hasValueRef.current = true;
    valueRef.current = value;
  }

  return valueRef.current;
};
