import React from 'react';
import { useTenantContext } from '@common/hooks';
import { useTypedFlags } from '@features/launch-darkly';
import { TenantMemberRole } from '@generated/graphql-code-generator';
import { FormikSubmitFn } from '@voleer/form-utils';
import { GrommetSelectEvent } from '@voleer/form-utils/src';
import { UnreachableCaseError } from '@voleer/types';
import { Alert } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Box, Button, FormField, Select, TextInput } from 'grommet';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { FaExclamation } from 'react-icons/fa';
import * as Yup from 'yup';

const defaultValues = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  role: TenantMemberRole.User,
};

/**
 * EditTenantMember form values.
 */
export type EditTenantMemberFormValues = typeof defaultValues;

/**
 * Return codes supported by the EditTenantMember form.
 */
export enum EditTenantMemberReturnCode {
  EmailAlreadyTaken = 'EmailAlreadyTaken',
  UnknownError = 'UnknownError',
  Success = 'Success',
}

/**
 * Props for the EditTenantMemberForm component.
 */
export type EditTenantMemberFormProps = {
  values?: EditTenantMemberFormValues;
  submitLabel: React.ReactNode;
  submittingLabel?: React.ReactNode;
  onTenantMemberSave: FormikSubmitFn<
    EditTenantMemberFormValues,
    EditTenantMemberReturnCode
  >;
  onCancel: () => void;
};

const validationSchema = (t: typeof i18next.t) => {
  return Yup.object().shape({
    firstName: Yup.string().required(t('validation.first-name-required')),
    lastName: Yup.string().required(t('validation.last-name-required')),
    emailAddress: Yup.string()
      .required(t('validation.email-required'))
      .email(t('validation.email-invalid')),
  });
};

const roleOptions: TenantMemberRole[] = [
  TenantMemberRole.User,
  TenantMemberRole.Admin,
];

/**
 * Component for rendering a form that will enable the edition or creation of tenantMembers.
 */
export const EditTenantMemberForm: React.FC<EditTenantMemberFormProps> = ({
  values,
  submitLabel,
  submittingLabel,
  onTenantMemberSave,
  onCancel,
}) => {
  const [t] = useTranslation('features/tenant/components/EditTenantMemberForm');
  const { 'tenant-member-roles': tenantMemberRoles } = useTypedFlags();
  const tenantContext = useTenantContext();
  const isCurrentUser = tenantContext.tenantMember?.id === values?.id;
  const disableRoleSelection = isCurrentUser || !tenantMemberRoles;

  const onSubmit: FormikSubmitFn<EditTenantMemberFormValues> = async (
    submittedValues,
    actions
  ) => {
    const result = await onTenantMemberSave(submittedValues, actions);
    switch (result) {
      case EditTenantMemberReturnCode.EmailAlreadyTaken: {
        actions.setSubmitting(false);
        actions.setFieldError('emailAddress', t('errors.email-not-unique'));
        return;
      }
      case EditTenantMemberReturnCode.UnknownError: {
        actions.setSubmitting(false);
        actions.setStatus({
          serverError: t('errors.unknown-error'),
        });
        return;
      }
      case EditTenantMemberReturnCode.Success: {
        actions.resetForm();
        return;
      }
      default:
        throw new UnreachableCaseError(result);
    }
  };

  // We want to keep sending `Admin` when feature flag is off
  // but default to `User` when feature flag is on.
  const updatedDefaultValues = {
    ...defaultValues,
  };
  if (!tenantMemberRoles) {
    updatedDefaultValues.role = TenantMemberRole.Admin;
  }

  const formValues = values || updatedDefaultValues;
  return (
    <Formik
      initialValues={formValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema(t)}
    >
      {formProps => {
        return (
          <Form>
            {formProps.status && formProps.status.serverError ? (
              <Alert
                icon={FaExclamation}
                margin={{ bottom: 'medium' }}
                status="error"
              >
                {formProps.status.serverError}
              </Alert>
            ) : (
              <></>
            )}
            <Box direction="row" gap="small" height="150px">
              <Field name="firstName">
                {(fieldProps: FieldProps<string>) => {
                  return (
                    <FormField
                      error={
                        fieldProps.form.touched.firstName &&
                        fieldProps.form.errors.firstName
                      }
                      label={t('firstName')}
                    >
                      <TextInput
                        autoFocus={true}
                        disabled={formProps.isSubmitting}
                        {...fieldProps.field}
                      />
                    </FormField>
                  );
                }}
              </Field>
              <Field name="lastName">
                {(fieldProps: FieldProps<string>) => {
                  return (
                    <FormField
                      error={
                        fieldProps.form.touched.lastName &&
                        fieldProps.form.errors.lastName
                      }
                      label={t('lastName')}
                    >
                      <TextInput
                        disabled={formProps.isSubmitting}
                        {...fieldProps.field}
                      />
                    </FormField>
                  );
                }}
              </Field>
              <Field name="emailAddress">
                {(fieldProps: FieldProps<string>) => {
                  return (
                    <FormField
                      error={
                        fieldProps.form.touched.emailAddress &&
                        fieldProps.form.errors.emailAddress
                      }
                      label={t('email')}
                    >
                      <TextInput
                        disabled={formProps.isSubmitting}
                        {...fieldProps.field}
                      />
                    </FormField>
                  );
                }}
              </Field>
              <Field name="role">
                {(fieldProps: FieldProps<TenantMemberRole>) => {
                  const onChange = (
                    event: GrommetSelectEvent<TenantMemberRole>
                  ) => formProps.setFieldValue('role', event.value);
                  const buildLabelKey = (role: TenantMemberRole) => {
                    return t(`role-options.${role.toLowerCase()}`);
                  };
                  return (
                    <FormField
                      error={
                        fieldProps.form.touched.role &&
                        fieldProps.form.errors.role
                      }
                      label={t('role')}
                    >
                      <Select
                        {...fieldProps.field}
                        disabled={
                          disableRoleSelection || formProps.isSubmitting
                        }
                        labelKey={buildLabelKey}
                        onChange={onChange}
                        options={roleOptions}
                      />
                    </FormField>
                  );
                }}
              </Field>
            </Box>
            <Box direction="row" gap="small" justify="end">
              <Button
                disabled={formProps.isSubmitting}
                label={t('cancel')}
                onClick={onCancel}
              />
              <Button
                disabled={formProps.isSubmitting || !formProps.dirty}
                label={
                  submittingLabel && formProps.isSubmitting
                    ? submittingLabel
                    : submitLabel
                }
                primary={true}
                type="submit"
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
