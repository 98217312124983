import React from 'react';
import { getOperationName } from '@apollo/client/utilities';
import {
  DeleteWorkspaceModal,
  useDeleteWorkspaceModal,
} from '@features/workflows';
import {
  FindWorkspacesDocument,
  WorkspaceFragment,
} from '@generated/graphql-code-generator';
import { DropButton, DropButtonItem, Icon } from '@voleer/ui-kit';
import Fuse from 'fuse.js';
import { Box, BoxProps } from 'grommet';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';
import { WorkspaceListItem } from '../WorkspaceListItem';

export type WorkspaceListProps = {
  workspaces: WorkspaceFragment[];
  searchTerm?: string;
};

const searchWorkspaces = (
  workspaces: WorkspaceFragment[],
  searchTerm?: string
) => {
  if (!searchTerm) {
    return workspaces;
  }
  const options = {
    shouldSort: false,
    threshold: 0.3,
    tokenize: true,
    matchAllTokens: true,
    keys: ['displayName'],
  };
  const fuse = new Fuse(workspaces, options);
  return fuse.search(searchTerm);
};

const MessageContent: React.FC<BoxProps> = props => (
  <Box
    alignSelf="center"
    fill="vertical"
    justify="center"
    pad={{ vertical: 'small', horizontal: 'medium' }}
    {...props}
  />
);

/**
 * Component for displaying a list of workspaces on the Workspaces page.
 */
export const WorkspaceList: React.FC<WorkspaceListProps> = ({
  workspaces,
  searchTerm,
}) => {
  const [t] = useTranslation('pages/WorkspacesPage');

  const { deleteWorkspaceModalProps, openDeleteWorkspaceModal } =
    useDeleteWorkspaceModal({
      deleteMutationRefetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getOperationName(FindWorkspacesDocument)!,
      ],
    });

  if (workspaces.length === 0) {
    return <MessageContent>{t('no-data')}</MessageContent>;
  }

  const displayedWorkspaces = searchWorkspaces(workspaces, searchTerm);
  if (searchTerm && !displayedWorkspaces.length) {
    return <MessageContent>{t('no-results')}</MessageContent>;
  }

  return (
    <>
      <Box>
        {displayedWorkspaces.map(workspace => {
          const { id, displayName } = workspace;
          return (
            <Box
              direction="row"
              flex={{ shrink: 0 }}
              justify="between"
              key={`workspace-${id}`}
            >
              <Box fill="horizontal">
                <WorkspaceListItem displayName={displayName} id={id} />
              </Box>
              <DropButton
                data-testid="workspaces__list-action-drop-btn"
                icon={<Icon icon={MdMoreHoriz} />}
                title={t('workspaces-list.actions.drop-button-title')}
                variation="ghost"
              >
                <DropButtonItem
                  label={t('workspaces-list.actions.delete')}
                  onClick={() => {
                    openDeleteWorkspaceModal(workspace);
                  }}
                />
              </DropButton>
            </Box>
          );
        })}
      </Box>
      <DeleteWorkspaceModal {...deleteWorkspaceModalProps} />
    </>
  );
};
